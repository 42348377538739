import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import Map from "modules/landing/components/Map";
import { type LegalNeedsProps } from "./types";
import { STATE_CODES } from "resources/options/states";
import Icon, { IconType } from "components/Icons";

const cx = classNames.bind(styles);

const LegalNeeds = ({ partner }: LegalNeedsProps) => (
  <div className={cx("legal-needs", { national: partner === null })}>
    <div className={cx("landing__container-100", "legal-needs__content")}>
      <div className={cx("legal-needs__info")}>
        <h2>Manage your legal needs.</h2>
        <h2>Online, in an instant.</h2>
        <p>
          Online estate planning made easy with Aturna, and supported by your
          local attorney. Get started at{" "}
          <a href="https://aturna.legal">aturna.legal</a> and have peace of mind
          within minutes.
        </p>
      </div>
      {partner === null || partner.data.address.line1 === undefined ? (
        <div className={cx("legal-needs__map-container")}>
          <Map jurisdictions={STATE_CODES} />
        </div>
      ) : (
        <div className={cx("legal-needs__partner-details-container")}>
          <div className={cx("legal-needs__partner-details")}>
            <div className={cx("legal-needs__partner-details__heading")}>
              <h3>{partner.data.displayName}</h3>
              {partner.data.attorney !== undefined && (
                <h4>{partner.data.attorney}</h4>
              )}
            </div>
            <div className={cx("legal-needs__partner-details__body")}>
              <Icon type={IconType.ARROW_FAT} />
              <div>
                <p>{partner.data.address.line1}</p>
                {partner.data.address.line2 !== undefined && (
                  <p>{partner.data.address.line2}</p>
                )}
                {partner.data.address.state !== undefined &&
                  partner.data.address.zip !== undefined && (
                    <p>
                      {[
                        partner.data.address.state,
                        partner.data.address.zip,
                      ].join(" ")}
                    </p>
                  )}
                {partner.data.contact.workPhone !== undefined && (
                  <>
                    <a href={`tel:${partner.data.contact.workPhone}`}>
                      {partner.data.contact.workPhone}
                    </a>
                    <br />
                  </>
                )}
                {partner.data.contact.email !== undefined && (
                  <a href={`mailto:${partner.data.contact.email}`}>
                    {partner.data.contact.email}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default LegalNeeds;
