import React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import { WorkflowStatus } from "modules/api/types/WorkflowPayload";
import actionCategories from "../../actionCategories";

const cx = classNames.bind(styles);

type ClientDashboardProductsItemStageProps = {
  currentPage: string | undefined;
  complete: boolean | null;
  itemState: WorkflowStatus;
};

const ClientDashboardProductsItemStage: React.FunctionComponent<
  ClientDashboardProductsItemStageProps
> = ({ currentPage, complete, itemState }) => {
  const classNames = cx("client-dashboard-products-item-stage", {
    "client-dashboard-products-item-stage--valid":
      complete && actionCategories.ok.includes(itemState),
    "client-dashboard-products-item-stage--warning":
      complete && actionCategories.warn.includes(itemState),
    "client-dashboard-products-item-stage--error":
      complete && actionCategories.alert.includes(itemState),
  });

  return <div className={classNames}>{complete ? itemState : currentPage}</div>;
};

export default ClientDashboardProductsItemStage;
