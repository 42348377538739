import * as React from "react";
import classNames from "classnames/bind";

import HeaderLogo from "./HeaderLogo";
import Link from "components/Link";
import { ReactComponent as BackSVG } from "./Back.svg";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type SimpleHeaderProps = {
  alignLogo?: "left" | "center";
  onLogoClicked: () => any;
  backLabel?: string;
  onBack?: () => any;
};

const SimpleHeader: React.FunctionComponent<SimpleHeaderProps> = ({
  alignLogo = "center",
  onLogoClicked,
  backLabel,
  onBack,
}) => {
  return (
    <header className={cx("header")}>
      <div className={cx("header__container", "header__container__simple")}>
        <div className={cx("header__container__simple__section")}>
          {onBack ? (
            <Link className={cx("menu-link")} onClick={onBack}>
              <BackSVG aria-label="Back" /> <span>{backLabel || "Back"}</span>
            </Link>
          ) : null}
        </div>
        <div
          className={cx(
            "header__container__simple__section",
            `align-header-${alignLogo}`,
          )}
        >
          <HeaderLogo onClick={onLogoClicked} />
        </div>
        <div className={cx("header__container__simple__section")}></div>
      </div>
    </header>
  );
};

export default SimpleHeader;
