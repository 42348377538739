import Client from "modules/parties/types/Client";
import { getFullName } from "modules/parties/types/Party";

const CLIENT_FULL_NAME = /#{CLIENT_FULL_NAME}/g;
const SPOUSE_FULL_NAME = /#{SPOUSE_FULL_NAME}/g;

const interpolateClientFullName = (s: string, client: Client): string =>
  s.replace(CLIENT_FULL_NAME, getFullName(client));

const interpolateSpouseFullName = (s: string, spouse: Client): string =>
  s.replace(SPOUSE_FULL_NAME, getFullName(spouse));

const interpolate = (s: string, client: Client, spouse?: Client): string =>
  spouse
    ? interpolateSpouseFullName(interpolateClientFullName(s, client), spouse)
    : interpolateClientFullName(s, client);

export default interpolate;
