import { createAction, Dispatch } from "@reduxjs/toolkit";
import Workflow, {
  CLIENT_INITIATED_WORKFLOW,
  LAWYER_INITIATED_WORKFLOW,
  WorkflowID,
} from "./types/Workflow";
import { get, post, API } from "../api";
import { User } from "../auth/User";
import { parseWorkflow, parseWorkflowDetails } from "./helpers/parseWorkflow";
import WorkflowPayload, {
  LifecycleUpdate,
  WorkflowPayloadState,
} from "../api/types/WorkflowPayload";
import WorkflowDetailsResponseType from "modules/api/responseTypes/WorkflowDetailsResponseType";
import reportValidationErrors from "./helpers/reportValidationErrors";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import {
  ProductSelector,
  ProductSelectorPlan,
  ProductSelectorProducts,
  ProductSelectorType,
} from "modules/product/types/ProductSelector";
import logger from "logger";
import { NotificationType } from "components/Notification";
import { QuestionID } from "./types/Questionnaire";
import {
  callClonePlanAPI,
  callConfirmClientContactedAPI,
  callFinalizeQuestionnaireAPI,
  callSetAddonsAPI,
  callSetSigningOptionAPI,
} from "./endpoints/workflowSteps";
import Product from "modules/product/types/Product";
import Plan from "modules/product/types/Plan";
import SaveAnswersResponseType, {
  SaveAnswersResponsePayload,
} from "modules/api/responseTypes/SaveAnswersResponseType";
import { merge } from "lodash";
import { loginRequired, signupRequired } from "modules/auth/actions";
import { AuthenticationTrigger } from "modules/auth/loginCallback/LoginCallbackMeta";
import { openClientWorkflow } from "modules/admin/actions";
import { AnswersByQuestionnaireType, AnswersMap } from "./types/Answer";
import { BadRequestResponsePayload } from "modules/api/types/ErrorResponsePayload";
import { parseValidationErrors } from "modules/api/apiHelper";
import { getAdministeredPartnerIDs } from "../auth/helper";
import isBadRequestError from "utils/isBadRequestError";

export const workflowsLoadingStarted = createAction(
  "WORKFLOWS_LOADING_STARTED",
);
export const workflowsLoaded = createAction<Workflow[]>("WORKFLOWS_LOADED");
export const workflowsLoadingFailed = createAction<any>(
  "WORKFLOWS_LOADING_FAILED",
);
export const createWorkflowInitiated = createAction(
  "CREATE_WORKFLOW_INITIATED",
);
export const newWorkflowCreated = createAction<Workflow>(
  "NEW_WORKFLOW_CREATED",
);
export const createWorkflowFailed = createAction<any>("CREATE_WORKFLOW_FAILED");
export const fetchWorkflowStateInitiated = createAction(
  "FETCH_WORKFLOW_STATE_INITIATED",
);
export const workflowStateFetched = createAction("WORKFLOW_STATE_FETCHED");
export const fetchWorkflowDetailsInitiated = createAction<string>(
  "FETCH_WORKFLOW_DETAILS_INITIATED",
);
export const fetchWorkflowDetailsFailed = createAction<any>(
  "FETCH_WORKFLOW_DETAILS_FAILED",
);
export const workflowDetailsFetched = createAction<Workflow>(
  "WORKFLOW_DETAILS_FETCHED",
);
export const workflowNotAssigned = createAction<string>(
  "WORKFLOW_NOT_ASSIGNED",
);
export const saveAnswersInitiated = createAction<string>(
  "SAVE_ANSWERS_INITATED",
);
export type AnswersSavedActionPayload = {
  workflowId: string;
  answers: AnswersByQuestionnaireType;
};
export const answersSaved =
  createAction<AnswersSavedActionPayload>("ANSWERS_SAVED");
export const saveAnswersFailed = createAction<any>("SAVE_ANSWERS_FAILED");
export const invalidAnswers =
  createAction<Record<WorkflowID, Record<QuestionID, string[]>>>(
    "INVALID_ANSWERS",
  );
export const exitWorkflow = createAction("EXIT_WORKFLOW");
export const downloadingDocument = createAction<DownloadableDocumentDetails>(
  "DOWNLOADING_DOCUMENT",
);
export const cloneWorkflowStarted = createAction<Workflow>(
  "CLONE_WORKFLOW_STARTED",
);
export const cloneWorkflowFailed = createAction<any>("CLONE_WORKFLOW_FAILED");
export const workflowCloned = createAction<Workflow>("WORKFLOW_CLONED");
export const workflowLifecycleUpdated = createAction<LifecycleUpdate>(
  "WORKFLOW_LIFECYCLE_UPDATED",
);
export const changePage = createAction<{
  pageIdx: number;
  pageId?: string;
  pageTitle?: string;
  initialLoad?: boolean;
}>("CHANGE_PAGE");
export const interviewAnswersChanged = createAction<AnswersByQuestionnaireType>(
  "INTERVIEW_ANSWERS_CHANGED",
);

const downloadDocument = (docDetails: DownloadableDocumentDetails) => {
  const a = document.createElement("a");
  a.href = docDetails.signedUrl;
  a.download = docDetails.name;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

const callGetWorkflowsAPI = async (): Promise<Workflow[]> => {
  const response = await get(API.WORKFLOWS());
  return (response.data || []).map((wf: WorkflowPayload) => parseWorkflow(wf));
};

export const callWorkflowDetailsAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow | null> => {
  const response: WorkflowDetailsResponseType = await get(
    API.WORKFLOW({ workflowId }),
    { asUser },
  );
  if (!response.data) {
    return null;
  }
  return parseWorkflowDetails(response.data);
};

const callCreateWorkflowAPI = async (
  definitionId: string,
  productSelector: ProductSelector,
  state: string,
  creator: string,
  createdFrom?: string,
  advisorAnswers?: AnswersMap,
  addons?: string[],
  userId?: string,
): Promise<Workflow | null> => {
  const response = await post(API.WORKFLOWS(), {
    userId,
    definitionId,
    creator,
    selector: productSelector,
    coupledWorkflowId: createdFrom,
    initialAnswers: advisorAnswers,
    addons,
    userState: state,
  });

  return response.data ? parseWorkflow(response.data as WorkflowPayload) : null;
};

const callSaveAnswersAPI = async (
  workflowId: string,
  questionnaireId: string,
  answers: { [questionId: string]: any },
  asUser?: string,
  pageId?: string,
): Promise<SaveAnswersResponsePayload> => {
  const endpoint = pageId
    ? API.PAGE_ANSWERS({ workflowId, questionnaireId, pageId })
    : API.ANSWERS({ workflowId, questionnaireId });
  const response: SaveAnswersResponseType = await post(
    endpoint,
    { answers },
    { asUser },
  );
  return response.data;
};

export const fetchWorkflows =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(workflowsLoadingStarted());
    try {
      const workflows = await callGetWorkflowsAPI();

      dispatch(workflowsLoaded(workflows));
    } catch (e) {
      logger.notify(NotificationType.ERROR, "Loading workflows failed.", e);
      dispatch(workflowsLoadingFailed(e));
    }
  };

export const fetchWorkflowDetails =
  (
    workflowId: string,
    options?: {
      loadCoupled?: boolean;
      asUser?: string;
    },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchWorkflowDetailsInitiated(workflowId));

      const workflow = await callWorkflowDetailsAPI(
        workflowId,
        options?.asUser,
      );

      if (!workflow) {
        dispatch(workflowNotAssigned(workflowId));
        return;
      }

      if (options?.loadCoupled && workflow.state.coupledWorkflowId) {
        await fetchWorkflowDetails(workflow.state.coupledWorkflowId, {
          asUser: options?.asUser,
        })(dispatch);
      }
      dispatch(workflowDetailsFetched(workflow));
    } catch (err) {
      const e: any = err;
      if (e.response?.status === 404) {
        dispatch(workflowNotAssigned(workflowId));
        return;
      }
      logger.notify(
        NotificationType.ERROR,
        "Loading workflow details failed. Please, try again later",
        e,
      );
      dispatch(fetchWorkflowDetailsFailed(e));
      return;
    }
  };

export const openWorkflow =
  (
    user: User | null,
    partnerId: string | undefined,
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(openClientWorkflow({ user, workflow, pageIdx, pageId }));
  };

export const workflowStateChanged = createAction<{
  workflowId: string;
  state: WorkflowPayloadState;
}>("WORKFLOW_STATE_CHANGED");
export const fetchWorkflowStateFailed = createAction<any>(
  "FETCH_WORKFLOW_STATE_FAILED",
);
export const fetchWorkflowState =
  (workflowId: string, asUser?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchWorkflowStateInitiated());

      const workflow = await callWorkflowDetailsAPI(workflowId, asUser);

      if (!workflow) throw new Error("Workflow not available");

      dispatch(workflowStateChanged({ workflowId, state: workflow.state }));
      dispatch(workflowStateFetched());
    } catch (e) {
      logger.warn("Loading workflow state failed", e);
      dispatch(fetchWorkflowStateFailed(e));
      return;
    }
  };

export const saveAnswers =
  (
    workflowId: string,
    questionnaireId: string,
    answers: { [questionId: string]: any },
    asUser?: string,
    pageId?: string,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(saveAnswersInitiated(workflowId));
    try {
      const response = await callSaveAnswersAPI(
        workflowId,
        questionnaireId,
        answers,
        asUser,
        pageId,
      );
      const updatedAnswers = merge(
        { [questionnaireId]: answers },
        response.changedAnswers || {},
      );
      dispatch(answersSaved({ workflowId, answers: updatedAnswers }));
    } catch (err) {
      const e: any = err;
      if (e.response?.status === 400) {
        const responseData: BadRequestResponsePayload | undefined =
          e.response.data;
        const errors = parseValidationErrors(
          responseData?.additionalData?.details,
        );
        if (errors) {
          reportValidationErrors(errors, answers, e.response.data?.short);
          dispatch(invalidAnswers({ [workflowId]: errors }));
          throw e;
        }
      }

      logger.notify(
        NotificationType.ERROR,
        "Your answers couldn't be saved. Please, try again later",
        e,
      );
      dispatch(saveAnswersFailed(e));
      throw e;
    }
  };

export const downloadRenderedDocument =
  (documentDetails: DownloadableDocumentDetails) => (dispatch: Dispatch) => {
    dispatch(downloadingDocument(documentDetails));
    downloadDocument(documentDetails);
  };

export const cloneWorkflow =
  (workflowToClone: Workflow, asUser?: string | null) =>
  async (dispatch: Dispatch) => {
    dispatch(cloneWorkflowStarted(workflowToClone));
    try {
      const newWorkflow = await callClonePlanAPI(
        workflowToClone.id,
        asUser ? asUser : undefined,
      );
      if (!newWorkflow) {
        throw new Error("Invalid response from the server");
      }
      dispatch(workflowCloned(newWorkflow));
    } catch (e) {
      dispatch(cloneWorkflowFailed(e));
      logger.notify(
        NotificationType.ERROR,
        "A new workflow couldn't be created due to an error. Please, try again later",
        e,
      );
      throw e;
    }
  };

export const createNewWorkflow =
  (
    definitionId: string,
    selector: ProductSelector,
    state: string,
    creator: string,
    advisorAnswers?: AnswersMap,
    customAddons: string[] = [],
    userId?: string,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(createWorkflowInitiated());
    try {
      const newWorkflow = await callCreateWorkflowAPI(
        definitionId,
        selector,
        state,
        creator,
        undefined,
        advisorAnswers,
        customAddons,
        userId,
      );
      if (!newWorkflow) {
        throw new Error("Invalid response from the server");
      }
      dispatch(newWorkflowCreated(newWorkflow));
    } catch (e) {
      let errorMessage: string;

      if (isBadRequestError(e)) {
        errorMessage = `(${e.response?.data?.detail})`;
      } else {
        errorMessage = ". Please try again later";
      }

      logger.notify(
        NotificationType.ERROR,
        `Starting a new workflow failed ${errorMessage}`,
        e,
      );
      dispatch(createWorkflowFailed(e));
    }
  };

export const createNewWorkflowWithProduct =
  (
    user: User | null,
    definitionId: string,
    product: Product,
    productState: string,
    advisorAnswers?: AnswersMap,
    customAddons?: string[],
    userId?: string,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!user) {
      dispatch(
        signupRequired({
          action: AuthenticationTrigger.START_WORKFLOW_PRODUCT,
          preventLoginRedirect: true,
          definitionId,
          product,
          state: productState,
          advisorAnswers,
          customAddons,
        }),
      );
      return;
    }
    await createNewWorkflow(
      definitionId,
      {
        type: ProductSelectorType.PRODUCT,
        productIds: [product.id],
      } as ProductSelectorProducts,
      productState,
      getAdministeredPartnerIDs(user).length > 0
        ? LAWYER_INITIATED_WORKFLOW
        : CLIENT_INITIATED_WORKFLOW,
      advisorAnswers,
      customAddons,
      userId === user.idToken ? user.uid : userId,
    )(dispatch);
  };

export const createNewWorkflowWithPlan =
  (
    user: User | null,
    definitionId: string,
    plan: Plan,
    planState: string,
    advisorAnswers?: AnswersMap,
    customAddons?: string[],
    userId?: string,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!user) {
      dispatch(
        loginRequired({
          action: AuthenticationTrigger.START_WORKFLOW_PLAN,
          preventLoginRedirect: true,
          definitionId,
          plan,
          state: planState,
          advisorAnswers,
          customAddons,
        }),
      );
      return;
    }
    await createNewWorkflow(
      definitionId,
      {
        type: ProductSelectorType.PLAN,
        planId: plan.id,
      } as ProductSelectorPlan,
      planState,
      getAdministeredPartnerIDs(user).length > 0
        ? LAWYER_INITIATED_WORKFLOW
        : CLIENT_INITIATED_WORKFLOW,
      advisorAnswers,
      [...plan.enabledAddons, ...(customAddons || [])],
      userId === user.idToken ? user.uid : userId,
    )(dispatch);
  };

export const questionnaireFinalized = createAction<Workflow>(
  "QUESTIONNAIRE_FINALIZED",
);
export const finalizeQuestionnaireFailed = createAction<any>(
  "FINALIZE_QUESTIONNAIRE_FAILED",
);
export const finalizeQuestionnaire =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      // TODO DEMO code
      const updatedWorkflow = await callFinalizeQuestionnaireAPI(
        workflowId,
        asUser,
      );

      dispatch(questionnaireFinalized(updatedWorkflow));
    } catch (e) {
      dispatch(finalizeQuestionnaireFailed(e));
      throw e;
    }
  };

export const setSigningOption =
  (workflowId: string, supervisedSigning: boolean, asUser?: string) =>
  async (dispatch: Dispatch) => {
    const workflow = await callSetSigningOptionAPI(
      workflowId,
      supervisedSigning,
      asUser,
    );
    dispatch(workflowStateChanged({ workflowId, state: workflow.state }));
  };

export type WorkflowAddonsUpdateType = {
  workflowId: string;
  selectedAddons: string[];
};
export const workflowAddonsSaved = createAction<WorkflowAddonsUpdateType>(
  "WORKFLOW_ADDONS_SAVED",
);
export const setWorkflowAddons =
  (
    workflowId: string,
    workflowOwner: string | undefined,
    selectedAddons: string[],
  ) =>
  async (dispatch: Dispatch) => {
    const workflow = await callSetAddonsAPI(
      workflowId,
      workflowOwner,
      selectedAddons,
    );
    dispatch(workflowAddonsSaved({ workflowId, selectedAddons }));
    dispatch(workflowStateChanged({ workflowId, state: workflow.state }));
  };

export const confirmClientContactedPending = createAction(
  "CONFIRM_CLIENT_CONTACTED_PENDING",
);
export const clientContacted = createAction("CLIENT_CONTACTED");
export const confirmClientContactedFailed = createAction<any>(
  "CONFIRM_CLIENT_CONTACTED_FAILED",
);
export const confirmClientContacted =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(confirmClientContactedPending());
      await callConfirmClientContactedAPI(workflowId, asUser);
      logger.notify(
        NotificationType.SUCCESS,
        "The workflow was successfully updated.",
      );
      dispatch(clientContacted());
    } catch (e) {
      dispatch(confirmClientContactedFailed(e));
      logger.notify(
        NotificationType.ERROR,
        "Updating the workflow failed. Please, try again later.",
        e,
      );
      throw e;
    }
  };
