import ChildCondition from "./ChildCondition";
import { ClientCondition, SpouseCondition } from "./ClientDetailsCondition";
import ValueCondition from "./ValueCondition";
import WorkflowDetailsCondition from "./WorkflowDetailsCondition";

export type AllCondition = { all: Condition[] };
export const isAllCondition = (
  condition: Condition,
): condition is AllCondition => condition.hasOwnProperty("all");

export type AnyCondition = { any: Condition[] };
export const isAnyCondition = (
  condition: Condition,
): condition is AnyCondition => condition.hasOwnProperty("any");

export type NotCondition = { not: Condition };
export const isNotCondition = (
  condition: Condition,
): condition is NotCondition => condition.hasOwnProperty("not");

export type AddonCondition = { addon: string };
export const isAddonCondition = (
  condition: Condition,
): condition is AddonCondition => condition.hasOwnProperty("addon");

export type WorkflowCondition = { workflow: WorkflowDetailsCondition };
export const isWorkflowCondition = (
  condition: Condition,
): condition is WorkflowCondition =>
  condition.hasOwnProperty("workflow") &&
  Boolean((condition as WorkflowCondition).workflow.type);

export type AddonOfferCondition = {
  [questionnaireId: string]: { addonOffer: string };
};
export const isAddonOfferCondition = (
  condition: Condition,
): condition is AddonOfferCondition => {
  return Object.entries(condition)[0]?.[1]?.hasOwnProperty("addonOffer");
};

type Condition =
  | AllCondition
  | AnyCondition
  | NotCondition
  | AddonCondition
  | AddonOfferCondition
  | ClientCondition
  | SpouseCondition
  | ChildCondition
  | WorkflowCondition
  | ValueCondition;

export default Condition;
