import LoadingOverlay from "components/LoadingOverlay";
import LoginCallbackMeta from "modules/auth/loginCallback/LoginCallbackMeta";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { redirect } from "modules/navigation/helpers/navigator";
import { useLocation } from "react-router-dom";

import authProvider from "modules/auth/authProvider";
import { useSelector } from "redux/reducer";
import SignupScreen from "./SignupScreen";
import {
  getDefaultPartnerID,
  hasActiveRegistrations,
} from "modules/auth/helper";
import { partnerSelected } from "modules/partner/actions";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";

const SignupScreenContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    user,
    loginError,
    partner,
    state: userJurisdiction,
  } = useSelector(
    (state) => ({
      user: state.user,
      isSessionRehydrated: state.isSessionRehydrated,
      loginError: state.loginError,
      partner: state.partner,
      state: state.state,
    }),
    shallowEqual,
  );
  const partnerId = getFormattedPartnerId();

  const locationState = location.state as Record<string, any> | undefined;
  const previousLocation = locationState?.from;
  const preventRedirect = (locationState?.meta as LoginCallbackMeta | undefined)
    ?.preventLoginRedirect;
  const from =
    typeof locationState?.from === "string"
      ? locationState?.from
      : locationState?.from?.pathname;
  const isFromPartnerRoot =
    from?.replaceAll("/", "").toLocaleLowerCase() ===
    partnerId?.toLocaleLowerCase();

  const shouldRedirect = user !== null && !preventRedirect;

  useEffect(() => {
    if (shouldRedirect) {
      if (previousLocation && !isFromPartnerRoot) {
        redirect(dispatch, previousLocation, location.state as {});
        return;
      }

      if (partnerId) {
        redirect(dispatch, getRoute(ROUTE_TEMPLATE.WORKFLOW));
        return;
      }

      const defaultPartnerId = getDefaultPartnerID(user!);
      if (defaultPartnerId || hasActiveRegistrations(user)) {
        typeof defaultPartnerId === "string"
          ? dispatch(partnerSelected(defaultPartnerId))
          : redirect(dispatch, getRoute(ROUTE_TEMPLATE.MY_PARTNERS));
        return;
      }

      redirect(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));
    }
  }, [
    dispatch,
    isFromPartnerRoot,
    location.state,
    partnerId,
    previousLocation,
    shouldRedirect,
    user,
  ]);

  if (
    (partnerId && partnerId !== partner?.id) ||
    (user && preventRedirect && !loginError) ||
    shouldRedirect
  )
    return <LoadingOverlay />;

  return (
    <SignupScreen
      authProvider={authProvider}
      userJurisdiction={userJurisdiction || undefined}
    />
  );
};

export default SignupScreenContainer;
