import { Dispatch, Middleware } from "@reduxjs/toolkit";
import {
  newWorkflowCreated,
  workflowNotAssigned,
  exitWorkflow,
  workflowCloned,
  changePage,
  answersSaved,
  fetchWorkflowState,
} from "./actions";
import { NotificationType } from "components/Notification";
import {
  ROUTE_TEMPLATE,
  getWorkflowDetailsRoute,
} from "modules/navigation/routes";
import type { RootState } from "redux/reducer";
import { isAdmin } from "modules/auth/helper";
import logger from "logger";
import { keyBy, mapValues } from "lodash";
import { QuestionsMapByQuestionnaire } from "./types/Questionnaire";
import { parseStoredAnswers } from "./helpers/validation";
import { invalidActionError } from "modules/api/actions";
import { go, redirect } from "modules/navigation/helpers/navigator";
import {
  ASSEMBLE_DOCUMENTS_PAGE_ID,
  SUMMARY_PAGE_ID,
} from "./staticPages/pageIDs";
import { isAtLeast, isPaid } from "./helpers/lifecycle";
import { Step } from "modules/api/types/WorkflowPayload";
import { triggerWorkflowPayment } from "modules/payment/actions";
import getWorkflowID from "./helpers/getWorkflowID";
import { renderDocumentMissingAnswers } from "./documents/actions";

const collectQuestionsByQuestionnaire = (
  state: RootState,
  workflowId: string,
): QuestionsMapByQuestionnaire =>
  mapValues(
    keyBy(state.workflows[workflowId].questionnaires || [], "id"),
    (questionnaire) => questionnaire.questions,
  );

const handleAssembleDocumentsPageOpen = (
  dispatch: Dispatch,
  { user, workflows, partner }: RootState,
) => {
  const workflowID = getWorkflowID();
  const workflow = workflows[workflowID];
  if (!workflow) return;

  const admin = isAdmin(partner?.id, user);

  if (!isPaid(workflow.state.steps)) {
    const isSummaryConfirmed = isAtLeast(
      workflow.state.steps,
      Step.WaitingForCoupledConfirmSummary,
    );
    if (isSummaryConfirmed) {
      triggerWorkflowPayment(workflow)(dispatch);
    } else {
      admin
        ? logger.notify(
            NotificationType.ERROR,
            "Interview in progress",
            workflow.state.steps.current,
          )
        : logger.notify(
            NotificationType.ERROR,
            "Please, review and confirm your answers first",
            workflow.state.steps.current,
          );
      redirect(
        dispatch,
        getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID),
      );
    }
    return;
  }
};

const createWorkflowMiddleware: () => Middleware =
  () =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    const updateWorkflowState = (workflowId: string) => {
      const { user, partner, workflows }: RootState = getState();
      const workflow = workflows[workflowId];

      const asUser =
        isAdmin(partner?.id, user) && workflow ? workflow.userId : undefined;
      user && fetchWorkflowState(workflowId, asUser)(dispatch);
    };

    switch (action.type) {
      case workflowCloned.type: {
        logger.notify(
          NotificationType.SUCCESS,
          "Wise choice! We have initiated a plan for your spouse, too. You'll find it on your dashboard.",
        );
        break;
      }

      case newWorkflowCreated.type: {
        setTimeout(() => {
          // Redirect after the state is modified
          go(dispatch, getWorkflowDetailsRoute(action.payload.id));
        }, 0);
        break;
      }

      case workflowNotAssigned.type: {
        logger.notify(
          NotificationType.ERROR,
          "Requested resource is not available",
        );
        redirect(dispatch, ROUTE_TEMPLATE.WORKFLOW);
        break;
      }

      case exitWorkflow.type: {
        go(dispatch, ROUTE_TEMPLATE.WORKFLOW);
        break;
      }

      case renderDocumentMissingAnswers.type: {
        logger.notify(
          NotificationType.ERROR,
          `Document couldn't be rendered. The form is incomplete (${
            Object.keys(action.payload).length
          } missing answer${Object.keys(action.payload).length > 1 ? "s" : ""})`,
        );
        break;
      }

      case changePage.type: {
        window.location.hash = action.payload.pageId
          ? `pageId=${action.payload.pageId}`
          : `page=${action.payload.pageIdx + 1}`;

        if (
          action.payload.pageId === SUMMARY_PAGE_ID &&
          action.payload.initialLoad !== true
        ) {
          const workflowID = getWorkflowID();
          updateWorkflowState(workflowID);
        }

        if (action.payload.pageId === ASSEMBLE_DOCUMENTS_PAGE_ID) {
          handleAssembleDocumentsPageOpen(dispatch, getState());
        }

        break;
      }

      case answersSaved.type: {
        const questions = collectQuestionsByQuestionnaire(
          getState(),
          action.payload.workflowId,
        );
        action.payload.answers = parseStoredAnswers(
          questions,
          action.payload?.answers || {},
        );
        break;
      }

      case invalidActionError.type: {
        logger.notify(
          NotificationType.ERROR,
          "The action is not allowed due to your product's state",
          action.payload,
        );
        break;
      }
    }

    next(action);
  };

export default createWorkflowMiddleware;
