import React from "react";
import keyBy from "lodash/keyBy";
import Partner, { PartnerData } from "modules/parties/types/Partner";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  Question,
  QuestionID,
  QuestionnaireSection,
  QuestionsMap,
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import ActionStepLogin from "./ActionStepLogin";
import { TIMEZONES_OPTIONS } from "resources/options/timezones";
import { STATE_CODE_OPTIONS } from "resources/options/states";
import { AnswersMap } from "modules/workflow/types/Answer";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { addressLookup, addressSearch } from "modules/address/actions";
import { savePartner } from "modules/partner/actions";
import preprocessAnswers from "modules/workflow/helpers/preprocessAnswers";
import reportValidationErrors from "modules/workflow/helpers/reportValidationErrors";
import { useDispatch, shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";
import { isGlobalAdmin } from "../../../auth/helper";

const FORM_ID = "partnerConfigForm";

type Props = { partner: Partner; afterSave?: () => void };

const PartnerConfigurationForm: React.FC<Props> = ({ partner, afterSave }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state, shallowEqual);

  const {
    displayName,
    address,
    attorney,
    contact,
    enabled,
    drafting,
    medicaid,
    openingHours,
    timezone,
    jurisdiction,
  } = partner.data;

  const handleSavePartnerData = async (data: Partial<PartnerData>) => {
    if (!user) {
      logger.error("No user available");
      return;
    }

    try {
      await savePartner(data)(dispatch);
      afterSave && (await afterSave());
      logger.notify(NotificationType.SUCCESS, "Your data was saved");
    } catch (e) {
      logger.notify(
        NotificationType.ERROR,
        "Saving data failed. Please, review your information and try again later.",
        e,
      );
    }
  };

  const handleValidationErrors = (errors: object, answers: AnswersMap) =>
    reportValidationErrors(errors, answers);

  const handleAddressLookup = async (addressId: string) =>
    addressLookup(addressId)(dispatch);

  const handleAddressSearch = async (
    containerId?: string,
    searchTerm?: string,
  ) => addressSearch(containerId, searchTerm)(dispatch);

  const beforeSavePartnerConfig = (
    questions: QuestionsMap,
    answers: AnswersMap,
    changedAnswerIds: string[],
  ) => preprocessAnswers(partner!.id, questions, answers, changedAnswerIds);

  const handleFormSubmission = (values: Record<QuestionID, any>) => {
    const {
      companyName,
      address,
      attorney,
      enabled,
      drafting,
      medicaid,
      openingHours,
      contact_phone,
      contact_email,
      billing_email,
      contact_fax,
      timezone,
      jurisdiction,
    } = values;

    const data: Partial<PartnerData> = {
      displayName: companyName,
      address: address.address,
      attorney,
      enabled,
      drafting,
      medicaid,
      openingHours,
      contact: {
        workPhone: contact_phone,
        email: contact_email,
        billingEmail: billing_email,
        fax: contact_fax === "" ? null : contact_fax,
      },
      timezone,
      jurisdiction,
    };
    handleSavePartnerData(data);
  };

  const questions: Question[] = [
    {
      id: "companyName",
      data: {
        descriptions: [{ label: "Company Name" }],
        definition: { type: QuestionType.TEXT },
        required: true,
      },
    },
    {
      id: "attorney",
      data: {
        descriptions: [{ label: "Attorney Name" }],
        definition: { type: QuestionType.TEXT },
        required: true,
      },
    },

    {
      id: "contact_phone",
      data: {
        descriptions: [{ label: "Phone" }],

        definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
        required: true,
      },
    },

    {
      id: "contact_fax",
      data: {
        descriptions: [{ label: "Fax" }],

        definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
        required: false,
      },
    },

    {
      id: "contact_email",
      data: {
        descriptions: [{ label: "Email address" }],

        definition: {
          type: QuestionType.TEXT,
          subtype: TextFieldType.EMAIL,
          explainer:
            "This email will be public facing, and serve as the contact email on your website",
        },
        required: true,
      },
    },

    {
      id: "billing_email",
      data: {
        descriptions: [
          {
            label: "Billing Email address",
          },
        ],

        definition: {
          type: QuestionType.TEXT,
          subtype: TextFieldType.EMAIL,
          explainer:
            "Monthly receipts and other financial correspondence will be emailed here",
        },
        required: true,
      },
    },

    {
      id: "address",
      data: {
        descriptions: [{ label: "Address" }],
        definition: {
          type: QuestionType.ADDRESS,
          onAddressLookup: handleAddressLookup,
          onAddressSearch: handleAddressSearch,
        },
        required: true,
      },
    },

    {
      id: "timezone",
      data: {
        descriptions: [{ label: "Timezone" }],
        definition: {
          type: QuestionType.SELECT,
          values: TIMEZONES_OPTIONS.map(({ value, label }) => ({
            value,
            display: label,
          })),
          autocomplete: true,
          limits: { max: 1 },
        },
        required: true,
      },
    },

    {
      id: "jurisdiction",
      data: {
        descriptions: [{ label: "Supported jurisdictions" }],
        definition: {
          type: QuestionType.SELECT,
          values: STATE_CODE_OPTIONS,
        },
      },
    },
    {
      id: "openingHours",
      data: {
        descriptions: [{ label: "Opening Hours" }],
        definition: { type: QuestionType.LONG_TEXT },
        required: true,
      },
    },
    {
      id: "actionstep_login",
      data: {
        descriptions: [{ label: "" }],
        definition: {
          type: QuestionType.CONTENT,
          render: () => <ActionStepLogin partner={partner} />,
        },
      },
    },
    {
      id: "drafting",
      data: {
        descriptions: [
          {
            label: "Enable Drafting of Legal Documents In-Practice",
            description:
              "When on, your law firm will be able to initiate legal documents.",
          },
        ],
        definition: {
          type: QuestionType.FLAG,
        },
      },
    },
    {
      id: "medicaid",
      data: {
        descriptions: [{ label: "Enable Medicaid" }],
        definition: {
          type: QuestionType.FLAG,
        },
      },
    },
    {
      id: "enabled",
      data: {
        descriptions: [
          {
            label: "Make Law Firm Publicly Accessible",
            description:
              "When on, your law firm will be searchable and accessible for clients.",
          },
        ],
        definition: {
          type: QuestionType.FLAG,
        },
      },
    },
  ];

  const formValues = {
    [FORM_ID]: {
      companyName: displayName,
      attorney,
      address,
      enabled,
      drafting,
      medicaid,
      openingHours,
      contact_phone: contact?.workPhone,
      contact_fax: contact?.fax || "",
      contact_email: contact?.email,
      billing_email: contact?.billingEmail,
      jurisdiction,
      timezone,
    },
  };

  const formSections: QuestionnaireSection[] = [
    {
      caption: "Partner Details",
      questions: ["companyName", "attorney"],
    },
    {
      caption: "Address",
      questions: ["address", "openingHours"],
    },
    {
      caption: "Contact Information",
      questions: [
        "contact_email",
        "billing_email",
        "contact_phone",
        "contact_fax",
      ],
    },
    {
      caption: "ActionStep",
      questions: ["actionstep_login"],
    },
    {
      caption: "Jurisdiction",
      questions: ["jurisdiction"],
    },
    {
      caption: "Public Access",
      questions: ["enabled"],
    },
  ];

  const globalAdminFormSections: QuestionnaireSection[] = [
    ...formSections,
    {
      caption: "Drafting",
      questions: ["drafting"],
    },
    {
      caption: "Medicaid",
      questions: ["medicaid"],
    },
  ];

  return (
    <React.Fragment>
      <QuestionnaireForm
        answers={formValues}
        onSavePageAnswers={(_, values) => handleFormSubmission(values)}
        onValidationErrors={handleValidationErrors}
        questionsBySubquestionnaire={{ [FORM_ID]: keyBy(questions, "id") }}
        questionnairePages={[
          {
            id: `${FORM_ID}_page`,
            questionnaireId: FORM_ID,
            caption: "",
            sections: isGlobalAdmin(user)
              ? globalAdminFormSections
              : formSections,
          },
        ]}
        customFormProps={{ showSaveButton: true }}
        beforeSave={beforeSavePartnerConfig}
      />
    </React.Fragment>
  );
};

export default PartnerConfigurationForm;
