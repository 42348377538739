import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import Party from "modules/parties/types/Party";
import Addon, { AddonID } from "modules/product/types/Addon";
import { satisfiesCondition } from "modules/workflow/helpers/questionnaireSchemaParser";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import {
  ConditionalPageDescription,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";

const resolveConditionalPageDescriptions = (
  conditionalPageDescriptions: ConditionalPageDescription[],
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
  allowedAddons?: AddonID[],
): string | undefined =>
  conditionalPageDescriptions.find((conditionalPageDescription) =>
    satisfiesCondition(
      conditionalPageDescription.condition,
      allQuestions,
      answers,
      selectedAddons,
      clients,
      workflowState,
      allChildren,
      allowedAddons,
    ),
  )?.description;

export default resolveConditionalPageDescriptions;
