import React, { useMemo } from "react";
import type { MedicaidAnalysisProps } from "./types";
import CurrentProtection from "./CurrentProtection";
import AssetProtectionPlanImmediateProtection from "./AssetProtectionPlanImmediateProtection";

import AssetProtectionPlanAdditionalProtection from "./AssetPrtoectionPlanAdditionalProtection";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { go } from "modules/navigation/helpers/navigator";
import { useDispatch } from "react-redux";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { MedicaidLeadStatus } from "modules/medicaid/types";
import { updateMedicaidLeadStatus } from "modules/medicaid/actions";
import { useSelector } from "redux/reducer";
import { isAdmin } from "modules/auth/helper";
import { PartnerID } from "modules/parties/types/Partner";
import { useParams } from "react-router-dom";
import SelectorOption from "components/Selector/SelectorOption";
import { SelectorOptionMood } from "components/Selector/constants";
import {
  getCurrentlyProtected,
  getTotalAssetsProtected,
  getTotalProtectedOnceQualified,
} from "../helpers";
import currencyFormatter from "utils/currencyFormatter";
import Selector, { SelectorTitleStyle } from "components/Selector";
import SelectorFeatureList from "components/Selector/SelectorFeatures";
import SelectorFeature from "components/Selector/SelectorFeatures/SelectorFeature";

import { ReactComponent as WarningSVG } from "components/Icons/svg/warning.svg";
import Link from "components/Link";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import BackLink from "components/BackLink";

const cx = classNames.bind(styles);

const MedicaidAnalysis = ({
  medicaidLeadId,
  medicaidLeadStatus,
  calculation,
  partnerId,
}: MedicaidAnalysisProps) => {
  const dispatch = useDispatch();
  const { partnerId: urlPartnerId } = useParams<{ partnerId?: PartnerID }>();

  const user = useSelector((state) => state.user);

  const isPartnerAdmin = isAdmin(urlPartnerId?.toUpperCase(), user);

  const handleBackToMedicaidLeads = () => {
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEADS, { urlPartnerId }),
    );
  };

  const handleBackToSummary = () =>
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD, {
        medicaidLeadId,
      }),
    );

  const handleGetAssetProtectionPlan = () => {
    if (partnerId === undefined) {
      go(
        dispatch,
        getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD_MAP, { medicaidLeadId }),
      );
    } else {
      updateMedicaidLeadStatus(
        dispatch,
        medicaidLeadId,
        MedicaidLeadStatus.ReadyForPartner,
      ).then(() =>
        go(
          dispatch,
          getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD_FINISHED, { medicaidLeadId }),
        ),
      );
    }
  };

  const immediatelyProtectedWithPlan = useMemo(
    () => getTotalAssetsProtected(calculation),
    [calculation],
  );

  const prettifiedImmediatelyProtectedWithPlan = currencyFormatter.format(
    immediatelyProtectedWithPlan,
  );

  const currentlyProtectedWithoutPlan = useMemo(
    () => getCurrentlyProtected(calculation),
    [calculation],
  );

  const prettifiedCurrentlyProtectedWithoutPlan = currencyFormatter.format(
    currentlyProtectedWithoutPlan,
  );

  const totalProtectedOnceQualified = useMemo(
    () => getTotalProtectedOnceQualified(calculation),
    [calculation],
  );

  const prettifiedTotalProtectedOnceQualified = currencyFormatter.format(
    totalProtectedOnceQualified,
  );

  const showInquiryAction =
    !isPartnerAdmin &&
    medicaidLeadStatus === MedicaidLeadStatus.InterviewInProgress;

  return (
    <>
      <BackLink
        text={
          isPartnerAdmin
            ? "Back to Medicaid Leads"
            : "Back to Medicaid Workflow"
        }
        onClick={
          isPartnerAdmin ? handleBackToMedicaidLeads : handleBackToSummary
        }
      />
      <Selector
        title="Total Protected Assets"
        titleStyle={SelectorTitleStyle.WIDE}
      >
        <SelectorOption
          title="Without a plan"
          optionValue={prettifiedCurrentlyProtectedWithoutPlan}
          selected={false}
          optionValueMood={SelectorOptionMood.NEGATIVE}
          isPricingShown={true}
          shortTitle
          addClassNames={cx("without-plan")}
        >
          <CurrentProtection calculation={calculation} />
          <SelectorFeatureList>
            <SelectorFeature
              name="Exempt assets may not be exempt in the future, based on changes in law"
              id="problem1"
              Icon={WarningSVG}
            />
            <SelectorFeature
              name="No asset protection from lawsuits or other creditors"
              id="problem2"
              Icon={WarningSVG}
            />
            <SelectorFeature
              name="Significant assets still at risk of being spent on nursing home care"
              id="problem3"
              Icon={WarningSVG}
            />
          </SelectorFeatureList>
          {showInquiryAction && (
            <Link onClick={handleBackToSummary}>
              I don't want an asset protection plan
            </Link>
          )}
        </SelectorOption>
        <SelectorOption
          title="With a plan"
          optionValue={prettifiedTotalProtectedOnceQualified}
          selected={false}
          optionValueMood={SelectorOptionMood.POSITIVE}
          isPricingShown={true}
          recommended
          shortTitle
        >
          <>
            <AssetProtectionPlanImmediateProtection calculation={calculation} />
            <SelectorFeatureList recommended>
              <SelectorFeature
                name={`Immediately protect ${prettifiedImmediatelyProtectedWithPlan}`}
                id="benefit1"
              />
              <SelectorFeature
                name={`All assets protected if you stay healthy 5 years after completing your plan`}
                id="benefit2"
              />
            </SelectorFeatureList>
            <AssetProtectionPlanAdditionalProtection
              calculation={calculation}
            />
            {showInquiryAction && (
              <TabbedButton
                style={TabbedButtonType.PRIMARY}
                label="Get an Asset Protection Plan"
                onClick={handleGetAssetProtectionPlan}
              />
            )}
          </>
        </SelectorOption>
      </Selector>
    </>
  );
};

export default MedicaidAnalysis;
