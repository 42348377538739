import { createAction, Dispatch } from "@reduxjs/toolkit";
import { post, API, patch, del, put } from "../api";
import Party, {
  ChildRelationship,
  PartyToSave,
  PartyType,
  RelationshipType,
  WhoseChild,
} from "./types/Party";
import Client, { ClientType } from "./types/Client";
import parseRelatedParty from "./helpers/parseRelatedParty";
import {
  buildCreatePartyRequestPayload,
  buildUpdatePartyRequestPayload,
} from "./helpers/buildPartyRequestPayload";
import logger from "logger";
import {
  showChildDetailsModal,
  showGrandchildDetailsModal,
  showRelatedPartyDetailsModal,
} from "./components/PartyDetailsModal";
import DeletePartyResponseType, {
  DeletePartyResult,
} from "modules/api/responseTypes/DeletePartyResponseType";
import { answersSaved, invalidAnswers } from "modules/workflow/actions";
import { isRelatedAs } from "./helpers/getRelationshipToClient";
import { addressLookup, addressSearch } from "modules/address/actions";
import { NotificationType } from "components/Notification";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import { capitalize } from "lodash";
import Address from "./types/Address";
import { ErrorType } from "modules/api/types/ErrorResponsePayload";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { clientFields } from "./helpers/getClientQuestionnaire/constants";
import prefillChildRelationship from "./helpers/prefillChildRelationship";
import { LifecycleUpdate } from "modules/api/types/WorkflowPayload";
import SaveClientResponseType from "modules/api/responseTypes/SaveClientResponseType";
import SavePartyResponseType from "modules/api/responseTypes/SavePartyResponseType";
import axios from "axios";
import isBadRequestError from "utils/isBadRequestError";

export const addPartyStarted = createAction("ADD_PARTY_STARTED");
export const cancelAddParty = createAction("CANCEL_ADD_PARTY");
export const triggerAddParty =
  (
    clients: ClientsMap | undefined,
    workflowId: string,
    availableAddresses: Address[],
    asUser?: string,
  ) =>
  (dispatch: Dispatch): Promise<Party | undefined> =>
    new Promise((resolve) => {
      if (clients?.[ClientType.MAIN]) {
        dispatch(addPartyStarted());
        showRelatedPartyDetailsModal(
          "Add person",
          clients,
          async (party: Partial<PartyToSave>) => {
            const savedParty = await saveRelatedParty(
              workflowId,
              clients[ClientType.MAIN]!.clientId,
              party,
              asUser,
            )(dispatch);
            resolve(savedParty); // Notify the question
            return savedParty; // Notify the modal about success
          },
          undefined,
          (addressId: string) => addressLookup(addressId)(dispatch),
          (containerId?: string, searchTerm?: string) =>
            addressSearch(containerId, searchTerm)(dispatch),
          availableAddresses,
          () => dispatch(cancelAddParty()),
        );
      }
    });

export const addChildStarted = createAction("ADD_CHILD_STARTED");
export const cancelAddChild = createAction("CANCEL_ADD_CHILD");
export const triggerAddChild =
  (
    clients: ClientsMap | undefined,
    workflowId: string,
    availableAddresses: Address[],
    asUser?: string,
    formAnswers?: AnswersByQuestionnaireType,
  ) =>
  (dispatch: Dispatch): Promise<Party | undefined> =>
    new Promise((resolve) => {
      if (clients?.[ClientType.MAIN]) {
        dispatch(addChildStarted());
        showChildDetailsModal(
          "Add child",
          clients,
          async (party: Partial<PartyToSave>) => {
            if (!clients[ClientType.MAIN]!.isMarried) {
              party.relationshipToSave = {
                ...party.relationshipToSave,
                whoseChild: WhoseChild.MINE,
              } as ChildRelationship;
            }

            const savedParty = await saveRelatedParty(
              workflowId,
              clients[ClientType.MAIN]!.clientId,
              party,
              asUser,
            )(dispatch);
            resolve(savedParty); // Notify the question
            return savedParty; // Notify the modal about success
          },
          undefined,
          (addressId: string) => addressLookup(addressId)(dispatch),
          (containerId?: string, searchTerm?: string) =>
            addressSearch(containerId, searchTerm)(dispatch),
          availableAddresses,
          () => dispatch(cancelAddChild()),
          formAnswers,
        );
      }
    });

export const addGrandchildStarted = createAction("ADD_GRANDCHILD_STARTED");
export const cancelAddGrandchild = createAction("CANCEL_ADD_GRANDCHILD");
export const triggerAddGrandchild =
  (
    clients: ClientsMap | undefined,
    workflowId: string,
    parentId: string,
    availableAddresses: Address[],
    asUser?: string,
  ) =>
  (dispatch: Dispatch): Promise<Party | undefined> =>
    new Promise((resolve) => {
      if (clients?.[ClientType.MAIN]) {
        dispatch(addGrandchildStarted());
        showGrandchildDetailsModal(
          "Add grandchild",
          clients,
          async (party: Partial<Party>) => {
            const savedParty = await saveRelatedParty(
              workflowId,
              clients[ClientType.MAIN]!.clientId,
              {
                ...party,
                parentId,
                relationshipToSave: { type: RelationshipType.GRANDCHILD },
              },
              asUser,
            )(dispatch);
            resolve(savedParty); // Notify the question
            return savedParty; // Notify the modal about success
          },
          undefined,
          (addressId: string) => addressLookup(addressId)(dispatch),
          (containerId?: string, searchTerm?: string) =>
            addressSearch(containerId, searchTerm)(dispatch),
          availableAddresses,
          () => dispatch(cancelAddGrandchild()),
        );
      }
    });

export const editPartyStarted = createAction<Party>("EDIT_PARTY_STARTED");
export const cancelEditParty = createAction("EDIT_PARTY_CANCELLED");
const triggerEditRelatedPerson = (
  dispatch: Dispatch,
  clients: ClientsMap | undefined,
  workflowId: string,
  party: Party,
  availableAddresses: Address[],
  asUser?: string,
): Promise<Party | undefined> => {
  dispatch(editPartyStarted(party));
  return new Promise((resolve) => {
    showRelatedPartyDetailsModal(
      "Edit person",
      clients,
      (editedParty: Partial<PartyToSave>) => {
        const result = updateRelatedParty(
          clients?.[ClientType.MAIN]?.clientId,
          workflowId,
          party,
          editedParty,
          asUser,
        )(dispatch);
        resolve(result);
        return result;
      },
      party,
      (addressId: string) => addressLookup(addressId)(dispatch),
      (containerId?: string, searchTerm?: string) =>
        addressSearch(containerId, searchTerm)(dispatch),
      availableAddresses,
      () => dispatch(cancelEditParty()),
    );
  });
};

export const editChildStarted = createAction<Party>("EDIT_CHILD_STARTED");
export const cancelEditChild = createAction("EDIT_CHILD_CANCELLED");
export const triggerEditChild = (
  dispatch: Dispatch,
  clients: ClientsMap | undefined,
  workflowId: string,
  child: Party,
  availableAddresses: Address[],
  asUser?: string,
  formAnswers?: AnswersByQuestionnaireType,
  title?: string,
): Promise<Party | undefined> => {
  dispatch(editChildStarted(child));
  const client = clients?.[ClientType.MAIN];
  const childData: Party = prefillChildRelationship(
    child,
    client,
    clients?.[ClientType.SPOUSE],
  );

  return new Promise((resolve) => {
    showChildDetailsModal(
      title ? title : "Edit child",
      clients,
      async (editedChild: Partial<Party>) => {
        const result = updateChild(
          workflowId,
          client?.clientId,
          childData,
          editedChild,
          asUser,
        )(dispatch);
        resolve(result);
        return result;
      },
      childData,
      (addressId: string) => addressLookup(addressId)(dispatch),
      (containerId?: string, searchTerm?: string) =>
        addressSearch(containerId, searchTerm)(dispatch),
      availableAddresses,
      () => dispatch(cancelEditChild()),
      formAnswers,
    );
  });
};

export const editGrandhildStarted = createAction<Party>(
  "EDIT_GRANDCHILD_STARTED",
);
export const cancelEditGrandhild = createAction("EDIT_GRANDCHILD_CANCELLED");
export const triggerEditGrandchild = (
  dispatch: Dispatch,
  clients: ClientsMap | undefined,
  workflowId: string,
  grandchild: Party,
  availableAddresses: Address[],
  parentId?: string,
  asUser?: string,
): Promise<Party | undefined> => {
  dispatch(editGrandhildStarted(grandchild));
  return new Promise((resolve) => {
    showGrandchildDetailsModal(
      "Edit grandchild",
      clients,
      (editedGrandchild: Partial<Party>) => {
        const result = updateChild(
          workflowId,
          clients?.[ClientType.MAIN]?.clientId,
          grandchild,
          { ...editedGrandchild, parentId },
          asUser,
        )(dispatch);
        resolve(result);
        return result;
      },
      grandchild,
      (addressId: string) => addressLookup(addressId)(dispatch),
      (containerId?: string, searchTerm?: string) =>
        addressSearch(containerId, searchTerm)(dispatch),
      availableAddresses,
      () => dispatch(cancelEditGrandhild()),
    );
  });
};

export const triggerEditParty =
  (
    clients: ClientsMap | undefined,
    workflowId: string,
    party: Party,
    availableAddresses: Address[],
    asUser?: string,
    currentAnswers?: AnswersByQuestionnaireType,
  ) =>
  async (dispatch: Dispatch): Promise<Party | undefined> => {
    if (clients?.[ClientType.MAIN]) {
      if (
        isRelatedAs(party, clients[ClientType.MAIN], RelationshipType.CHILD)
      ) {
        return triggerEditChild(
          dispatch,
          clients,
          workflowId,
          party,
          availableAddresses,
          asUser,
          currentAnswers,
        );
      } else if (
        isRelatedAs(
          party,
          clients[ClientType.MAIN],
          RelationshipType.GRANDCHILD,
        )
      ) {
        return triggerEditGrandchild(
          dispatch,
          clients,
          workflowId,
          party,
          availableAddresses,
          party?.parentId,
          asUser,
        );
      } else {
        return triggerEditRelatedPerson(
          dispatch,
          clients,
          workflowId,
          party,
          availableAddresses,
          asUser,
        );
      }
    }
  };

const callAddPartyAPI = async (
  workflowId: string,
  payload: Record<string, any>,
  asUser?: string,
): Promise<{ party: Party; lifecycle?: LifecycleUpdate }> => {
  const response: SavePartyResponseType = await post(
    API.PARTIES({ workflowId }),
    payload,
    { asUser },
  );
  return (
    response.data && {
      lifecycle: response.data.lifecycle,
      party: parseRelatedParty(response.data),
    }
  );
};

const callDeletePartyAPI = async (
  workflowId: string,
  partyId: string,
  clientId: string,
  asUser?: string,
): Promise<DeletePartyResult> => {
  const deleteResponse: DeletePartyResponseType = await del(
    API.WORKFLOW_PARTY_RELATIONSHIP({ workflowId, partyId, clientId }),
    { asUser },
  );
  return deleteResponse.data;
};

const callUpdatePartyAPI = async (
  payload: Record<string, any>,
  asUser?: string,
): Promise<Party> => {
  const response: SavePartyResponseType = await patch(
    API.PARTY({ partyId: payload.partyId }),
    payload,
    { asUser },
  );
  return response.data && parseRelatedParty(response.data);
};

const callSaveClientAPI = async (
  workflowId: string,
  clientId: string | undefined,
  clientType: ClientType,
  client: Partial<Client>,
  asUser?: string,
): Promise<Client> => {
  const capitalizedType = capitalize(clientType);
  const response: SaveClientResponseType = clientId
    ? await patch(API.WORKFLOW_CLIENT({ workflowId, clientId }), client, {
        asUser,
      })
    : await put(
        API.WORKFLOW_CLIENTS({ workflowId, clientType: capitalizedType }),
        client,
        {
          asUser,
        },
      );
  const { lifecycle, ...clientData } = response.data;
  return clientData;
};

export const saveClientInitiated = createAction<{
  clientType: ClientType;
  client: Partial<Client>;
}>("SAVE_CLIENT_INITIATED");
export const clientSaved = createAction<{
  clientType: ClientType;
  client: Client;
  workflowId: string;
}>("CLIENT_SAVED");
export const saveClientFailed = createAction<any>("SAVE_CLIENT_FAILED");

export const saveClient =
  (
    workflowId: string,
    clientId: string | undefined,
    clientType: ClientType,
    client: Partial<Client>,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(saveClientInitiated({ clientType, client }));
    try {
      const clientData = await callSaveClientAPI(
        workflowId,
        clientId,
        clientType,
        client,
        asUser,
      );
      dispatch(clientSaved({ clientType, client: clientData, workflowId }));
    } catch (e) {
      if (isBadRequestError(e) && e.response?.status === 400) {
        logger.notify(
          NotificationType.ERROR,
          e.response.data?.short === ErrorType.ACTION_NOT_ALLOWED
            ? "Client information can't be changed anymore - the form has been finalized."
            : "The form is invalid. Please, review your answers and try again.",
          e,
        );
      } else {
        logger.notify(
          NotificationType.ERROR,
          "Your answers couldn't be saved. Please, try again later",
          e,
        );
      }

      // Try to parse missing field from the response
      const missingField = axios.isAxiosError(e)
        ? String(e.response?.data).match(
            /is missing required member '(.*)'/,
          )?.[1]
        : undefined;
      const missingFieldFormID: string | undefined =
        missingField && (clientFields as any)[missingField];
      if (missingFieldFormID) {
        dispatch(
          invalidAnswers({
            [workflowId]: {
              [missingFieldFormID]: ["This question is required"],
            },
          }),
        );
      }

      dispatch(saveClientFailed(e));
      throw e;
    }
  };

export const newPartyAssigned = createAction("NEW_PARTY_ASSIGNED");
export const saveRelatedPartyInitiated = createAction<Record<string, any>>(
  "SAVE_RELATED_PARTY_INITIALIZED",
);
export const relatedPartySaved = createAction<{
  party: Party;
  workflowId: string;
}>("RELATED_PARTY_SAVED");
export const saveRelatedPartyFailed = createAction<{
  party: Partial<Party>;
  error: any;
}>("SAVE_RELATED_PARTY_FAILED");
export const saveRelatedParty =
  (
    workflowId: string | undefined,
    clientId: string | undefined,
    party: Partial<PartyToSave>,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch) => {
    party.type = PartyType.PERSON;

    try {
      if (!workflowId || !clientId) {
        throw new Error(
          `Required properties missing (workflow ID: ${workflowId}, client ID: ${clientId})`,
        );
      }
      const payload = buildCreatePartyRequestPayload(party, clientId);
      dispatch(saveRelatedPartyInitiated(payload));

      const { party: savedParty } = await callAddPartyAPI(
        workflowId,
        payload,
        asUser,
      );
      dispatch(relatedPartySaved({ party: savedParty, workflowId }));
      return savedParty;
    } catch (error) {
      dispatch(saveRelatedPartyFailed({ party, error }));
      logger.notify(
        NotificationType.ERROR,
        "Saving person details failed. Please, try again later",
        error,
      );
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw error.response.data;
      }
    }
  };

const deletePerson = async (
  dispatch: Dispatch,
  workflowId: string,
  clientId: string,
  party: Party,
  asUser?: string,
): Promise<AnswersByQuestionnaireType> => {
  const { changedAnswers } = await callDeletePartyAPI(
    workflowId,
    party.partyId,
    clientId,
    asUser,
  );
  dispatch(answersSaved({ workflowId, answers: changedAnswers }));
  return changedAnswers;
};

export const deleteRelatedPartyInitiated = createAction<string>(
  "DELETE_RELATED_PARTY_INITIALIZED",
);
export const relatedPartyDeleted = createAction<{
  workflowId: string;
  party: Party;
}>("RELATED_PARTY_DELETED");
export const deleteRelatedPartyFailed = createAction<{
  partyId: string;
  error: any;
}>("DELETE_RELATED_PARTY_FAILED");
export const deleteRelatedParty =
  (
    workflowId: string | undefined,
    clientId: string | undefined,
    party: Party,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(deleteRelatedPartyInitiated(party.partyId));
    try {
      if (!workflowId || !clientId) {
        throw new Error(
          `Required properties missing (workflow ID: ${workflowId}, client ID: ${clientId})`,
        );
      }

      const affectedAnswers = await deletePerson(
        dispatch,
        workflowId,
        clientId,
        party,
        asUser,
      );
      dispatch(relatedPartyDeleted({ workflowId, party }));
      return affectedAnswers;
    } catch (error) {
      dispatch(deleteRelatedPartyFailed({ partyId: party.partyId, error }));
      logger.notify(
        NotificationType.ERROR,
        "Selected person couldn't be deleted. Please, try again later",
        error,
      );
    }
  };

export const updateRelatedPartyInitiated = createAction<Party>(
  "UPDATE_RELATED_PARTY_INITIALIZED",
);
export const updateRelatedPartyFailed = createAction<{
  party: Party;
  error: any;
}>("UPDATE_RELATED_PARTY_FAILED");
export const updateRelatedParty =
  (
    clientId: string | undefined,
    workflowId: string | undefined,
    originalParty: Party,
    changes: Partial<PartyToSave>,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch) => {
    const partyData: PartyToSave = {
      ...originalParty,
      ...changes,
    };

    try {
      if (!workflowId || !clientId) {
        throw new Error(
          `Required properties missing (workflow ID: ${workflowId}, client ID: ${clientId})`,
        );
      }
      const payload = buildUpdatePartyRequestPayload(
        partyData,
        workflowId,
        clientId,
      );
      dispatch(updateRelatedPartyInitiated(partyData));

      const updatedParty = await callUpdatePartyAPI(payload, asUser);
      dispatch(relatedPartySaved({ party: updatedParty, workflowId }));
      return updatedParty;
    } catch (error) {
      dispatch(updateRelatedPartyFailed({ party: partyData, error }));
      logger.notify(
        NotificationType.ERROR,
        "Person details couldn't be saved. Please, try again later",
        error,
      );
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw error.response.data;
      }
    }
  };

export const updateChildInitiated = createAction<Party>(
  "UPDATE_CHILD_INITIALIZED",
);
export const updateChildFailed = createAction<{ child: Party; error: any }>(
  "UPDATE_CHILD_FAILED",
);
export const updateChild =
  (
    workflowId: string | undefined,
    clientId: string | undefined,
    originalChild: Party,
    changes: Partial<PartyToSave>,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch) => {
    const { children, ...otherChanges } = changes;
    const childDetails: PartyToSave = {
      ...originalChild,
      ...otherChanges,
    };
    dispatch(updateChildInitiated(childDetails));

    try {
      if (!workflowId || !clientId) {
        throw new Error(
          `Required properties missing (workflow ID: ${workflowId}, clientId: ${clientId})`,
        );
      }
      const payload = buildUpdatePartyRequestPayload(
        { ...childDetails },
        workflowId,
        clientId,
      );
      if (!childDetails.deceased && childDetails.children?.length) {
        await Promise.all(
          childDetails.children.map((grandchild) =>
            deleteChild(workflowId, clientId, grandchild, asUser)(dispatch),
          ),
        );
      }
      const updatedChild = await callUpdatePartyAPI(payload, asUser);
      dispatch(relatedPartySaved({ party: updatedChild, workflowId }));
      return updatedChild;
    } catch (error) {
      dispatch(updateChildFailed({ child: childDetails, error }));
      logger.notify(
        NotificationType.ERROR,
        "Child details couldn't be saved. Please, try again later",
        error,
      );
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw error.response.data;
      }
    }
  };

export const deleteChildInitiated = createAction<Partial<Party>>(
  "DELETE_CHILD_INITIALIZED",
);
export const deleteChildFailed = createAction<{
  child?: Partial<Party>;
  error: any;
}>("DELETE_CHILD_FAILED");
export const deleteChild =
  (
    workflowId: string | undefined,
    clientId: string | undefined,
    child: Party,
    asUser?: string,
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    await Promise.all([
      ...(child.children || []).map((grandchild) =>
        deleteChild(workflowId, clientId, grandchild, asUser)(dispatch),
      ),
    ]);

    const isRelated = (child.relationships || []).find(
      (rel) => rel.clientId === clientId && rel.workflowId === workflowId,
    );
    if (!isRelated) return true;

    dispatch(deleteChildInitiated(child));
    try {
      if (!workflowId || !clientId) {
        throw new Error(
          `Required properties missing (workflow ID: ${workflowId}, client ID: ${clientId})`,
        );
      }
      await deletePerson(dispatch, workflowId, clientId, child, asUser);
      dispatch(relatedPartyDeleted({ workflowId, party: child }));
      return true; // Notify the modal about success
    } catch (error) {
      // TODO should happen in a transaction - delete may fail half-way through resulting in only some of the grandchildren deleted
      dispatch(deleteChildFailed({ child, error }));
      logger.notify(
        NotificationType.ERROR,
        "Selected child couldn't be deleted. Please, try again later",
        error,
      );
    }
  };
