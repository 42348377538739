import moment from "moment";
import React from "react";
import {
  Area,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  TooltipProps,
} from "recharts";
import { AreaChart, Text } from "recharts";
import { CartesianAxis } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { MEDICAID_QUALIFICATION_MONTHS } from "./constants";
import currencyFormatter from "utils/currencyFormatter";

import styles from "./styles.module.scss";

// Rechart
// https://recharts.org/en-US/api

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomTooltip: React.FC<
  TooltipProps<ValueType, NameType> & {
    totalAssets: number;
    unprotectableAssets: number;
  }
> = ({ active, payload, label, totalAssets, unprotectableAssets }) => {
  if (active && payload && payload.length) {
    const value = Array.isArray(payload[0].value)
      ? payload[0].value[0]
      : payload[0].value;

    const remainingAmount =
      typeof value === "string" ? Number.parseFloat(value) : value;

    const date = moment().add(label, "months").format("MMM YYYY");
    const endDate = moment()
      .add(MEDICAID_QUALIFICATION_MONTHS, "months")
      .format("MMM YYYY");

    if (remainingAmount === undefined) return null;
    return (
      <div className={styles.GraphTooltip}>
        <p className={styles.GraphTooltip__label}>
          {label < 0 || remainingAmount === totalAssets ? (
            "Today"
          ) : label === 0 ? (
            "At plan execution"
          ) : label > MEDICAID_QUALIFICATION_MONTHS ? (
            unprotectableAssets ? (
              "Unprotectable assets"
            ) : (
              `After ${endDate}`
            )
          ) : (
            <>
              <span>{date}</span>
            </>
          )}
        </p>

        {remainingAmount > 0 ? (
          <p className={styles.GraphTooltip__desc}>
            {label <= MEDICAID_QUALIFICATION_MONTHS ? <>At risk: </> : ""}
            <span className={styles.GraphTooltip__desc}>
              {Number.isFinite(remainingAmount)
                ? currencyFormatter.format(remainingAmount!)
                : "N/A"}
            </span>
          </p>
        ) : (
          <p className={styles.GraphTooltip__desc}>
            <span className={styles.GraphTooltip__desc}>
              All assets protected
            </span>
          </p>
        )}
      </div>
    );
  }

  return null;
};

type AssetProtectionChartProps = {
  protectedWithoutPlan: number;
  protectedOnSigning: number;
  protectedOnceQualified: number;
  totalAssets: number;
  fullMonthsToQualify: number;
  percentSaved: number;
  showTicks?: boolean;
};

const AssetProtectionChart = ({
  protectedWithoutPlan,
  protectedOnSigning,
  protectedOnceQualified,
  totalAssets,
  fullMonthsToQualify,
  percentSaved,
  showTicks = true,
}: AssetProtectionChartProps) => {
  const breakpoint = Math.max(
    0,
    Math.floor(MEDICAID_QUALIFICATION_MONTHS - fullMonthsToQualify),
  );

  const plotData = [
    {
      month: -10,
      protectedWithoutPlan: protectedWithoutPlan,
    },
    {
      month: 0,
      protectedBeforeBreakpoint: protectedOnSigning,
      protectedWithoutPlan: protectedWithoutPlan,
    },
    {
      month: breakpoint,
      protectedBeforeBreakpoint: protectedOnSigning,
      protectedAfterBreakpoint: protectedOnSigning,
    },
    {
      month: MEDICAID_QUALIFICATION_MONTHS,
      protectedAfterBreakpoint: protectedOnceQualified,
    },
  ];

  return (
    <div>
      <div className={styles["GraphWrapper"]}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={plotData}
            margin={{
              left: showTicks ? 50 : 0,
              right: 50,
              top: 20,
              bottom: 30,
            }}
          >
            <defs>
              <pattern
                id="stripes"
                patternUnits="userSpaceOnUse"
                width="10"
                height="10"
                patternTransform="rotate(45)"
              >
                <line
                  x1="0"
                  y="0"
                  x2="0"
                  y2="10"
                  stroke="#8200DC"
                  strokeWidth="2"
                  opacity={0.6}
                />
              </pattern>
            </defs>

            <Area
              type="stepAfter"
              dataKey="protectedWithoutPlan"
              fill="url(#stripes)"
              stroke="#8200DC"
              dot={false}
            />

            <Area
              type="monotone"
              dataKey="protectedBeforeBreakpoint"
              stroke="#8200DC"
              fill="url(#stripes)"
              dot={false}
            />

            <Area
              type="monotone"
              dataKey="protectedAfterBreakpoint"
              stroke="#8200DC"
              fill="url(#stripes)"
              dot={false}
            />

            <XAxis
              dataKey="month"
              ticks={[-10, 0, breakpoint, MEDICAID_QUALIFICATION_MONTHS]}
              tickFormatter={(value) => {
                return value < 0 || value > MEDICAID_QUALIFICATION_MONTHS
                  ? ""
                  : value === 0
                    ? "0"
                    : value;
              }}
              type="number"
              domain={["dataMin", "dataMax + 10"]}
              label={
                <Label
                  value="Month"
                  position="insideBottom"
                  fill="#333333"
                  offset={-5}
                />
              }
            />

            <YAxis
              ticks={
                showTicks
                  ? [
                      protectedWithoutPlan,
                      protectedOnSigning,
                      protectedOnceQualified,
                      totalAssets,
                    ]
                  : []
              }
              tickFormatter={(value) => {
                return currencyFormatter.format(value);
              }}
              tick={
                showTicks
                  ? (tick) => {
                      if (tick?.payload?.value === protectedWithoutPlan) {
                        return (
                          <Text
                            {...tick}
                            className={styles.Graph__Bottom__tick}
                          >
                            {currencyFormatter.format(tick?.payload?.value)}
                          </Text>
                        );
                      }
                      return (
                        <Text {...tick}>
                          {currencyFormatter.format(tick?.payload?.value)}
                        </Text>
                      );
                    }
                  : false
              }
              width={showTicks ? 60 : 15}
            />

            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              horizontalPoints={[200000]}
            >
              <CartesianAxis tick x={50} />
            </CartesianGrid>

            <ReferenceLine
              x={0}
              label={
                <Label
                  value="Plan Signed"
                  offset={5}
                  position="insideTopLeft"
                  fill="#8200DC"
                  width={100}
                />
              }
              stroke="#AAF08C"
              strokeWidth={2}
            />

            <ReferenceLine
              x={60}
              stroke="#AAF08C"
              label={
                <Label
                  value={`${
                    percentSaved < 100 ? "Maximum" : "All"
                  } assets protected`}
                  offset={10}
                  position="insideTopLeft"
                  fill="#8200DC"
                  width={100}
                />
              }
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AssetProtectionChart;
