import { createReducer } from "@reduxjs/toolkit";
import {
  agreementLoaded,
  loadingAgreementFailed,
  loadingAgreementStarted,
} from "./actions";

export const isLoadingAgreement = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(loadingAgreementStarted, () => true)
    .addCase(loadingAgreementFailed, () => false)
    .addCase(agreementLoaded, () => false),
);
