import React, { useCallback } from "react";
import classNames from "classnames/bind";
import Partner from "modules/parties/types/Partner";

import styles from "./styles.module.scss";
import Icon, { IconType } from "components/Icons";
import { getFullAddress } from "modules/parties/types/Address";
import Link from "components/Link";
const cx = classNames.bind(styles);

type PartnerDetailProps = {
  partner: Partner;
  onSelect: (partnerId: string) => void;
};

const PartnerDetail: React.FC<PartnerDetailProps> = ({ partner, onSelect }) => {
  const { id, data } = partner;

  const handleSelect = useCallback(() => onSelect(id), [id, onSelect]);

  return (
    <div className={cx("partner-detail")}>
      <header>
        <h1>{data.displayName}</h1>
      </header>
      <div className={cx("partner-detail__info", "partner-detail__address")}>
        <Icon
          className={cx("partner-detail__info__icon")}
          type={IconType.LOCATION}
        />
        <div>{getFullAddress(data.address)}</div>
      </div>
      <div className={cx("partner-detail__info", "partner-detail__contact")}>
        <Icon
          className={cx("partner-detail__info__icon")}
          type={IconType.LANDLINE}
        />
        <div>{data.contact.workPhone}</div>
      </div>
      <div className={cx("partner-detail__actions")}>
        <div
          className={cx(
            "partner-detail__action",
            "partner-detail__action__primary",
          )}
        >
          <Link onClick={handleSelect}>Select law firm</Link>
        </div>
        <div
          className={cx(
            "partner-detail__action",
            "partner-detail__action__secondary",
          )}
        >
          {data.location?.latitude !== undefined &&
          data.location.longitude !== undefined ? (
            <Link
              onClick={() =>
                window.open(
                  `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    [data.location!.latitude, data.location!.longitude].join(
                      ",",
                    ),
                  )}`,
                )
              }
            >
              Directions
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PartnerDetail;
