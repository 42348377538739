import { createAction, Dispatch } from "@reduxjs/toolkit";
import { User } from "../auth/User";
import { post, API } from "../api";
import logger, { TYPE } from "../../logger";
import RegistrationResponseType from "modules/api/responseTypes/RegistrationResponseType";
import moment from "moment";

export const updateProfileInitiated = createAction<User>(
  "UPDATE_PROFILE_INITIATED",
);
export const updateProfileFailed = createAction<any>("UPDATE_PROFILE_FAILED");
export const profileUpdated = createAction<User>("PROFILE_UPDATED");
export const onSaveUserProfile =
  (user: User | null, updates: User) => async (dispatch: Dispatch) => {
    const { phoneNumber, displayName, firstName, middleName, lastName, state } =
      updates;

    const timezone = moment.tz.guess();

    const requestData = {
      phoneNumber,
      displayName,
      firstName,
      middleName,
      lastName,
      timezone,
      state,
    };

    try {
      await post(API.USER_INFO(), requestData);

      dispatch(
        profileUpdated({
          ...user!,
          ...requestData,
        }),
      );
    } catch (e) {
      logger.notify(
        TYPE.ERROR,
        "Saving user profile failed. Please, try again later",
        e,
      );
      dispatch(updateProfileFailed(e));
      return;
    }
  };

export const stateSelectionSaved = createAction<string>(
  "STATE_SELECTION_SAVED",
);

export const saveStateFailed = createAction<any>("SAVE_STATE_FAILED");
export const saveUserState =
  (user: User, state: string) => async (dispatch: Dispatch) => {
    try {
      await post(API.USER_INFO(), { ...user, state });
      dispatch(stateSelectionSaved(state));
    } catch (e) {
      logger.notify(
        TYPE.ERROR,
        "Saving your selection failed. Please, try again later",
        e,
      );
      dispatch(saveStateFailed(e));
      return;
    }
  };

export const registrationCompleted = createAction<User>(
  "REGISTRATION_COMPLETED",
);
export const registrationFailed = createAction("REGISTRATION_FAILED");
export const onCompleteRegistration =
  (updatedUser: User) => async (dispatch: Dispatch) => {
    const creator = "CLIENT";
    const {
      email,
      phoneNumber,
      displayName,
      firstName,
      middleName,
      lastName,
      state,
    } = updatedUser;

    const timezone = moment.tz.guess();

    const requestData = {
      creator,
      email,
      phoneNumber,
      displayName,
      firstName,
      middleName,
      lastName,
      state,
      timezone,
    };

    try {
      const response: RegistrationResponseType = await post(
        API.REGISTER(),
        requestData,
      );
      const { activePartners, globalRoles } = response.data;

      const userDetails: User = {
        ...updatedUser,
        activePartners,
        globalRoles,
      };

      dispatch(registrationCompleted(userDetails));
    } catch (e) {
      logger.notify(
        TYPE.ERROR,
        "Registration failed! Please, try again later",
        e,
      );
      dispatch(registrationFailed());
      return;
    }
  };
