import * as React from "react";
import classNames from "classnames/bind";

import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type HeaderNavigationLinkProps = {
  label: string;
  isActive?: boolean;
  onClick: () => any;
};

export const HeaderNavigationLink: React.FunctionComponent<
  HeaderNavigationLinkProps
> = ({ label, isActive, onClick }) => {
  const className = cx("header-nav__link", {
    "header-nav__link--active": Boolean(isActive),
  });
  return (
    <li className={styles["header-nav__item"]}>
      <TabbedButton tabIndex={0} className={className} onClick={onClick}>
        {label}
      </TabbedButton>
    </li>
  );
};

const HeaderNavigation: React.FunctionComponent = ({ children }) => {
  return (
    <nav className={styles["header-nav"]}>
      <ul className={styles["header-nav__list"]}>{children}</ul>
    </nav>
  );
};

export default HeaderNavigation;
