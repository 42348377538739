import * as React from "react";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import Link from "components/Link";
import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";

import styles from "./styles.module.scss";

const getShortestExpiry = (
  renderedDocuments: DownloadableDocumentDetails[] | null,
): number | null => {
  let shortestExpiry: number | null = null;
  renderedDocuments?.forEach((doc) => {
    if (shortestExpiry === null || shortestExpiry > doc.expiresInSeconds)
      shortestExpiry = doc.expiresInSeconds;
  });
  return shortestExpiry;
};

type DownloadDocumentsListProps = {
  renderedDocuments: DownloadableDocumentDetails[] | null;
  onDownloadDocument: (docDetails: DownloadableDocumentDetails) => any;
  onExpire: () => void;
};

const DownloadDocumentsList: React.FC<DownloadDocumentsListProps> = ({
  renderedDocuments,
  onDownloadDocument,
  onExpire,
}) => {
  const [documentsExpired, setDocumentsExpired] = React.useState(false);

  React.useEffect(() => {
    let expirationTimeout: NodeJS.Timeout | null = null;
    const shortestExpiryInSecs = getShortestExpiry(renderedDocuments);

    if (shortestExpiryInSecs) {
      const expiryTimeInMs = shortestExpiryInSecs * 1000;
      expirationTimeout = setTimeout(() => {
        onExpire();
        setDocumentsExpired(true);
      }, expiryTimeInMs - 30000); // 30 second buffer to account for network latency;
    }

    return () => {
      expirationTimeout && clearTimeout(expirationTimeout);
    };
  }, [renderedDocuments, onExpire]);

  if (documentsExpired) return <LoadingSpinner size={SpinnerSize.SMALL} />;

  return renderedDocuments && renderedDocuments.length > 0 ? (
    <ul className={styles["documents-list"]}>
      {renderedDocuments.map((docDetails) => (
        <li key={docDetails.id}>
          <Link onClick={() => onDownloadDocument(docDetails)}>
            {docDetails.name}
          </Link>
        </li>
      ))}
    </ul>
  ) : (
    <p className={styles["documents-list--empty"]}>No documents available...</p>
  );
};

export default DownloadDocumentsList;
