import Tooltip from "components/Tooltip";
import React from "react";
import { STATES_MAP } from "resources/options/states";
import styles from "./styles.module.scss";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";

type StateSelectorHandleProps = {
  onTriggerStateSelect: () => void;
  selectedState: string;
};

const StateSelectorHandle: React.FC<StateSelectorHandleProps> = ({
  onTriggerStateSelect,
  selectedState,
}) => {
  const { isEditingWorkflow } = useSelector((state) => state, shallowEqual);

  return isEditingWorkflow ? (
    <Tooltip title={`Jurisdiction: ${STATES_MAP[selectedState]}`}>
      <div
        className={styles["state-select-handle-read-only"]}
        data-tid="header-state-select-handle"
      >
        <span>{selectedState}</span>
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      title={`Jurisdiction: ${STATES_MAP[selectedState]}, click to change`}
    >
      <div
        onClick={onTriggerStateSelect}
        className={styles["state-select-handle"]}
        data-tid="header-state-select-handle"
      >
        <span>{selectedState}</span>
      </div>
    </Tooltip>
  );
};

export default StateSelectorHandle;
