import React from "react";
import { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import { Dialog as MuiDialog } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

const dialogStyles = makeStyles({
  root: {
    minWidth: "320px",
  },
  paper: {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "0px",
    boxShadow: "none",
    margin: "20px",
    overflowY: "visible",
  },
  backdropRootClass: {
    backgroundColor: "rgba(7,8,39,0.4)",
    backdropFilter: "blur(1px)",
  },
});

const pageStyles = makeStyles({
  paper: {
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "0px",
    boxShadow: "none",
    width: "100%",
    maxWidth: "100% !important",
    height: "100%",
    margin: 0,
  },
  backdropRootClass: {
    display: "none",
  },
});

export enum DialogMode {
  DIALOG = "dialog",
  PAGE = "page",
}

type DialogProps = PropsWithChildren<{
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  mode?: DialogMode;
  onClose?: () => void;
  onExited: () => void;
  open: boolean;
  scroll?: "body" | "paper";
  TransitionComponent?: React.ForwardRefExoticComponent<
    TransitionProps & React.RefAttributes<unknown>
  >;
}>;

export default function Dialog(props: DialogProps) {
  const { onExited, mode = DialogMode.DIALOG, ...otherProps } = props;

  const { backdropRootClass, ...dialogClasses } =
    mode === DialogMode.PAGE ? pageStyles() : dialogStyles();

  const backdropProps = {
    classes: {
      root: backdropRootClass,
    },
  };

  return (
    <MuiDialog
      classes={dialogClasses}
      BackdropProps={backdropProps}
      TransitionProps={{ onExited }}
      {...otherProps}
    />
  );
}
