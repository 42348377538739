import React from "react";
import { ConsumerMedicaidLeaditemProps } from "./types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import Client, { ClientType } from "modules/parties/types/Client";
import { getFullName } from "modules/parties/types/Party";
import ConsumerMedicaidLeadItemActions from "./ConsumerMedicaidleadItemActions";
import getQuestionnaireFormInput from "screens/medicaidAnalysis/MedicaidLeadInterview/helpers";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";
import ConsumerMedicaidLeadItemStatus from "./ConsumerMedicaidLeadItemStatus";
import countItemsAcrossKeys from "modules/workflow/helpers/countItemsAcrossKeys";
import getPercentage from "utils/getPercentage";
import Icon, { IconType } from "components/Icons";
import Tooltip from "components/Tooltip";
import { STATES_MAP } from "resources/options/states";
import ConsumerMedicaidLeadItemQuestionsComplete from "./ConsumerMedicaidLeadItemQuestionsComplete";
import ConsumerMedicaidLeadItemDescription from "./ConsumerMedicaidLeadItemDescription";

const cx = classNames.bind(styles);

const ConsumerMedicaidLeadItem = ({ item }: ConsumerMedicaidLeaditemProps) => {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual,
  );
  const clientsMap: Record<string, Client> = {
    [ClientType.MAIN]: item.clients["Main"],
    [ClientType.SPOUSE]: item.clients["Spouse"],
  };
  const clientsFullName: string = [
    clientsMap[ClientType.MAIN] !== undefined &&
      getFullName(clientsMap[ClientType.MAIN]),
    clientsMap[ClientType.SPOUSE] !== undefined &&
      getFullName(clientsMap[ClientType.SPOUSE]),
  ]
    .filter(Boolean)
    .join(" & ");

  const { questionnaireProgress } = getQuestionnaireFormInput(
    item.questionnaire,
    item.questions,
    item.answers,
    {},
    clientsMap,
    user,
    () => () => Promise.resolve(),
    () => Promise.resolve(undefined),
    () => Promise.resolve(undefined),
    () => Promise.resolve(),
    () => {},
    [],
  );

  const answeredQuestionsCount =
    questionnaireProgress?.answeredQuestionsIDs !== undefined
      ? countItemsAcrossKeys(questionnaireProgress.answeredQuestionsIDs)
      : undefined;
  const totalQuestionsCount =
    questionnaireProgress?.allQuestionsIDs !== undefined
      ? countItemsAcrossKeys(questionnaireProgress.allQuestionsIDs)
      : undefined;

  const percentageComplete =
    answeredQuestionsCount !== undefined && totalQuestionsCount !== undefined
      ? getPercentage(answeredQuestionsCount, totalQuestionsCount)
      : undefined;

  return (
    <div
      className={cx("consumer-medicaid-lead-item")}
      key={item.medicaidLead.medicaidLeadId}
    >
      <div className={cx("consumer-medicaid-lead-item__header")}>
        <div className={cx("consumer-medicaid-lead-item__title")}>
          <div className={cx("consumer-medicaid-lead-item__title__icon")}>
            <Icon type={IconType.WILL2} />
          </div>
          {clientsFullName}
        </div>
        <div className={cx("consumer-medicaid-lead-item__jurisdiction")}>
          <Tooltip
            title={`${
              STATES_MAP[item.medicaidLead.jurisdiction] ||
              item.medicaidLead.jurisdiction
            } jurisdiction`}
          >
            {item.medicaidLead.jurisdiction}
          </Tooltip>
        </div>
      </div>
      <div className={cx("consumer-medicaid-lead-item__body")}>
        <div className={cx("consumer-medicaid-lead-item__progress")}>
          <div className={cx("consumer-medicaid-lead-item__status")}>
            <div className={cx("consumer-medicaid-lead-item__progress__title")}>
              Status:
            </div>
            <ConsumerMedicaidLeadItemStatus status={item.medicaidLead.status} />
          </div>
          <div
            className={cx("consumer-medicaid-lead-item__questions-completed")}
          >
            <div className={cx("consumer-medicaid-lead-item__progress__title")}>
              Questions Completed:
            </div>
            <ConsumerMedicaidLeadItemQuestionsComplete
              answered={answeredQuestionsCount}
              total={totalQuestionsCount}
            />
          </div>
          <div
            className={cx("consumer-medicaid-lead-item__progress-percentage")}
          >
            <div className={cx("consumer-medicaid-lead-item__progress__title")}>
              Progress:
            </div>
            <span
              className={cx("consumer-medicaid-lead-item__progress__value", {
                complete: percentageComplete?.value === 100,
              })}
            >
              {percentageComplete !== undefined
                ? `${percentageComplete.value}%`
                : "N/A"}
            </span>
          </div>
        </div>
        <div className={cx("consumer-medicaid-lead-item__description")}>
          <ConsumerMedicaidLeadItemDescription item={item} />
        </div>
        <div className={cx("consumer-medicaid-lead-item__actions")}>
          <ConsumerMedicaidLeadItemActions item={item} />
        </div>
      </div>
    </div>
  );
};

export default ConsumerMedicaidLeadItem;
