import { FormikErrors } from "formik";
import { QuestionID } from "./Questionnaire";

class ValidationError extends Error {
  public readonly errors: FormikErrors<Record<QuestionID, any>>;

  constructor(errors: FormikErrors<Record<QuestionID, any>>) {
    super("The form has validation errors");
    this.errors = errors;
  }
}

export default ValidationError;
