import * as React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import RadioButton, {
  RadioButtonProps,
} from "components/Forms/Inputs/RadioGroup/RadioButton";
import { AddonChoice } from "modules/workflow/types/Questionnaire";
import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";
import Link from "components/Link";
import currencyFormatter from "../../../../../utils/currencyFormatter";
import Alert, { AlertType } from "../../../../Alert";

const cx = classNames.bind(styles);

export type AddonWithChoicesProps = {
  choiceOff: AddonChoice;
  choiceOn: AddonChoice;
  disabled?: boolean;
  handleOff: () => void;
  handleOn: () => void;
  price: number;
  invalid?: boolean;
  tabIndex?: number;
  value?: boolean;
  buttonLabel?: string;
};

const renderChoice = (
  choice: AddonChoice,
  price: number | undefined,
  buttonOptions: RadioButtonProps,
) => (
  <li
    className={cx("addon-with-choices__item", {
      "addon-with-choices__item--recommended": Boolean(choice.recommended),
    })}
    data-tid={choice.title}
  >
    <div>
      {choice.icon || null}
      <div>
        <h3>{choice.title}</h3>
        <p>{price ? currencyFormatter.format(price) : "Free"}</p>
      </div>
    </div>
    <p>{choice.description}</p>
    {choice.note && (
      <p className={cx("addon-with-choices__item__note")}>{choice.note}</p>
    )}
    <ul>
      {choice.highlights.map((text, idx) => (
        <li key={idx}>{text}</li>
      ))}
    </ul>
    {choice.warning && (
      <div className={cx("addon-with-choices__item__warning")}>
        <Alert type={AlertType.WARNING} message={`${choice.warning}`} />
      </div>
    )}
    {choice.additionalContent !== undefined && choice.additionalContent}
    <div className={cx("addon-with-choices__item__actions")}>
      {choice.secondaryAction &&
        (choice.secondaryAction.isLoading ? (
          <LoadingSpinner size={SpinnerSize.XSMALL} />
        ) : (
          <Link onClick={choice.secondaryAction.onAction}>
            {choice.secondaryAction.label}
          </Link>
        ))}
      <RadioButton {...buttonOptions} />
    </div>
  </li>
);

const AddonWithChoices: React.FC<AddonWithChoicesProps> = ({
  choiceOff,
  choiceOn,
  disabled,
  handleOff,
  handleOn,
  price,
  invalid,
  tabIndex,
  value,
  buttonLabel,
}) => {
  const buttonOptions = {
    label: buttonLabel || "Select",
    disabled,
    invalid,
    tabIndex,
  };

  const onButtonOptions: RadioButtonProps = {
    ...buttonOptions,
    onClick: handleOn,
    selected: value === true,
    disabled:
      choiceOn.disabled !== undefined
        ? choiceOn.disabled
        : buttonOptions.disabled,
    label:
      choiceOn.buttonLabel !== undefined
        ? choiceOn.buttonLabel
        : buttonOptions.label,
  };

  const offButtonOptions: RadioButtonProps = {
    ...buttonOptions,
    onClick: handleOff,
    selected: choiceOff.disabled !== true && value === false,
    disabled:
      choiceOff.disabled !== undefined
        ? choiceOff.disabled
        : buttonOptions.disabled,
    label:
      choiceOff.buttonLabel !== undefined
        ? choiceOff.buttonLabel
        : buttonOptions.label,
  };
  return (
    <ul className={cx("addon-with-choices")}>
      {renderChoice(choiceOn, price, onButtonOptions)}
      {renderChoice(choiceOff, undefined, offButtonOptions)}
    </ul>
  );
};

export default AddonWithChoices;
