import { OrderItemType, PaymentOrderItem } from "modules/api/types/Payment";
import React, { useContext, useEffect } from "react";
import groupOrderItems from "./groupOrderItems";
import classNames from "classnames/bind";
import Coupon from "../Coupon";

import styles from "./styles.module.scss";
import currencyFormatter from "utils/currencyFormatter";
import Partner from "../../../../parties/types/Partner";
import DataLayerContext from "contexts/DataLayerContext";
import { EventName } from "contexts/DataLayerContext/types";
import { getEcommerceItemsFromOrderItems } from "contexts/DataLayerContext/helpers";
import getCouponName from "modules/payment/utils/getCouponName";
const cx = classNames.bind(styles);

type ShoppingCartProps = {
  partner?: Partner;
  orderItems?: PaymentOrderItem[];
  paymentInfo?: JSX.Element | JSX.Element[];
  totalPrice?: number;
};

const renderOrderItem = (item: PaymentOrderItem, idx: number) => (
  <li
    className={styles["shopping-cart__summary-item"]}
    key={idx}
    data-tid="order-item"
  >
    <div className={styles["shopping-cart__summary-name"]}>{item.name}</div>
    <div className={styles["shopping-cart__summary-price"]}>
      {currencyFormatter.format(item.amount)}
    </div>
  </li>
);

const renderGroup = (title: string, items: PaymentOrderItem[]) => (
  <>
    <li className={cx("shopping-cart__summary-item", "sub-group-header")}>
      <div className={styles["shopping-cart__sub-group-title"]}>{title}</div>
    </li>
    {items.map(renderOrderItem)}
  </>
);

const renderGroups = (
  groups: Record<OrderItemType, PaymentOrderItem[] | undefined>,
) => {
  const plans = groups[OrderItemType.PLAN] || [];
  const products = groups[OrderItemType.PRODUCT] || [];
  const addons = [
    ...(groups[OrderItemType.ADDON] || []),
    ...(groups[OrderItemType.SUPPORT] || []),
    ...(groups[OrderItemType.SUPERVISED_SIGNING] || []),
  ];
  const discounts = groups[OrderItemType.DISCOUNT] || [];
  const adjustments = groups[OrderItemType.ADJUSTMENT] || [];

  return (
    <>
      {plans.length
        ? renderGroup(plans.length > 1 ? "Plans" : "Plan", plans)
        : null}
      {products.length
        ? renderGroup(products.length > 1 ? "Products" : "Product", products)
        : null}
      {addons.length
        ? renderGroup(addons.length > 1 ? "Add-ons" : "Add-on", addons)
        : null}
      {discounts.length
        ? renderGroup(
            discounts.length > 1 ? "Discounts" : "Discount",
            discounts,
          )
        : null}
      {adjustments.length
        ? renderGroup(
            adjustments.length > 1 ? "Adjustment" : "Adjustments",
            adjustments,
          )
        : null}
    </>
  );
};

const getWorkflowLabel = (
  idx: number,
  groups: Record<OrderItemType, PaymentOrderItem[] | undefined>,
): string => {
  const item = Object.values(groups).flat().pop();
  return item?.workflowId ? `Plan ${idx + 1}` : "Other";
};

const renderWorkflowGroups = (
  idx: number,
  groups: Record<OrderItemType, PaymentOrderItem[] | undefined>,
) => (
  <React.Fragment key={idx}>
    <li className={cx("shopping-cart__summary-item", "group-header")}>
      <div className={styles["shopping-cart__group-title"]}>
        {getWorkflowLabel(idx, groups)}
      </div>
    </li>
    {renderGroups(groups)}
  </React.Fragment>
);

const ShoppingCart = ({
  partner,
  orderItems,
  paymentInfo,
  totalPrice,
}: ShoppingCartProps) => {
  const { sendEvent } = useContext(DataLayerContext);
  const groupedItems = groupOrderItems(orderItems || []);

  useEffect(() => {
    const couponValue =
      orderItems !== undefined ? getCouponName(orderItems) : undefined;
    const couponString = couponValue ? couponValue : "";

    const ecommerceOrderItems = getEcommerceItemsFromOrderItems(
      orderItems,
      partner?.id,
    );

    sendEvent?.({
      event: EventName.BeginCheckout,
      currency: "USD",
      coupon: couponString,
      items: ecommerceOrderItems,
    });
  }, [orderItems, partner?.id, sendEvent]);

  return (
    <div className={styles["shopping-cart"]}>
      <h2 className={styles["shopping-cart__title"]}>Order Summary</h2>
      {paymentInfo && (
        <div className={styles["shopping-cart__info"]}>{paymentInfo}</div>
      )}
      <ul className={styles["shopping-cart__summary"]}>
        <li className={styles["shopping-cart__summary-item"]}>
          <div className={styles["shopping-cart__summary-name"]}>Total</div>
          <div className={styles["shopping-cart__summary-price"]}>
            {totalPrice ? currencyFormatter.format(totalPrice) : "-"}
          </div>
        </li>

        {groupedItems.length > 1
          ? groupedItems.map((workflowItems, idx) =>
              renderWorkflowGroups(idx, workflowItems),
            )
          : renderGroups(groupedItems[0] || {})}
      </ul>
      <Coupon partner={partner} />
    </div>
  );
};

export default ShoppingCart;
