import { LedgerItem } from "modules/parties/types/Finance";

const compareLedgerItems = (
  a: LedgerItem,
  b: LedgerItem,
  sortField: string,
): number => {
  switch (sortField) {
    case "displayName":
    case "plan":
      return a[sortField].localeCompare(b[sortField]);
    case "dateCompleted":
    case "amountCollected":
    case "partnerRevenue":
    case "guidrRevenue":
      return a[sortField] - b[sortField];
    default:
      return 0;
  }
};

const sortLedgerItems = (
  items: LedgerItem[],
  sortField: string,
  order: string,
): LedgerItem[] => {
  if (!sortField || !order) return items;

  if (order === "asc")
    return [...items].sort((a, b) => compareLedgerItems(a, b, sortField));

  if (order === "desc")
    return [...items]
      .sort((a, b) => compareLedgerItems(a, b, sortField))
      .reverse();

  return items;
};

export default sortLedgerItems;
