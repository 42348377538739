import Input from "components/Forms/Inputs/Input";
import { InputType } from "components/Forms/Inputs/Input/types";
import React from "react";
import { useId } from "react-id-generator";
import QuestionWrapper, { QuestionSizes } from "../QuestionWrapper";
import { QuestionLifeInsurancePolicyValueProps } from "./types";
import styles from "./styles.module.scss";

export const FACE_VALUE_LABEL = "Face Value:";
export const CASH_VALUE_LABEL = "Cash Value:";
export const DEATH_BENEFIT_LABEL = "Death Benefit:";

const QuestionLifeInsurancePolicyValue = ({
  name,
  label,
  description,
  disabled,
  size,
  questionActions,
  faceValueLimits,
  cashValueLimits,
  deathBenefitLimits,
}: QuestionLifeInsurancePolicyValueProps) => {
  const [questionId1, questionId2, questionId3] = useId(3, "question-");

  return (
    <QuestionWrapper
      disabled={disabled}
      help={description}
      label={label}
      id={name}
      name={name}
      size={QuestionSizes.FULLWIDTH}
    >
      <div className={styles["life-insurance-policy-value-inputs"]}>
        <div className={styles["face-value"]}>
          <p className={styles["life-insurance-policy-value-label"]}>
            {FACE_VALUE_LABEL}
          </p>
          <Input
            disabled={disabled}
            id={questionId1}
            name={`${name}.faceValue`}
            min={faceValueLimits.min}
            max={faceValueLimits.max}
            type={InputType.CURRENCY}
          />
        </div>
        <div className={styles["cash-value"]}>
          <p className={styles["life-insurance-policy-value-label"]}>
            {CASH_VALUE_LABEL}
          </p>
          <Input
            disabled={disabled}
            id={questionId2}
            name={`${name}.cashValue`}
            min={cashValueLimits.min}
            max={cashValueLimits.max}
            type={InputType.CURRENCY}
          />
        </div>
        <div className={styles["death-benefit"]}>
          <p className={styles["life-insurance-policy-value-label"]}>
            {DEATH_BENEFIT_LABEL}
          </p>
          <Input
            disabled={disabled}
            id={questionId3}
            name={`${name}.deathBenefit`}
            min={deathBenefitLimits.min}
            max={deathBenefitLimits.max}
            type={InputType.CURRENCY}
          />
        </div>
      </div>
    </QuestionWrapper>
  );
};

export default QuestionLifeInsurancePolicyValue;
