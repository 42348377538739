import * as React from "react";

import { AnswerValueType } from "modules/workflow/types/Answer";

import styles from "./styles.module.scss";
import {
  isAssetSplitAnswer,
  isLifeInsurancePolicyValueAnswer,
} from "modules/parties/types/ComplexAnswerType";
import currencyFormatter from "utils/currencyFormatter";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import interpolate from "utils/interpolate";

type SummaryAnswerProps = {
  label: string;
  value?: AnswerValueType;
  clients?: ClientsMap;
};

const sanitizeLabel = (questionLabel: string) =>
  questionLabel.replace(/\(optional\)/gi, "").trim();

const sanitizeValue = (value: AnswerValueType, idx?: number): JSX.Element => {
  if (Array.isArray(value)) {
    return (
      <>
        {value.length
          ? (value as Array<AnswerValueType>).map(sanitizeValue)
          : "-"}
      </>
    );
  }
  if (isAssetSplitAnswer(value)) {
    return (
      <>
        <div key={`${value.type}_${idx}_1`}>{value.mainOwned}</div>
        {value.jointlyOwned && (
          <div key={`${value.type}_${idx}_2`}>{value.jointlyOwned}</div>
        )}
        {value.spouseOwned && (
          <div key={`${value.type}_${idx}_3`}>{value.spouseOwned}</div>
        )}
      </>
    );
  }
  if (isLifeInsurancePolicyValueAnswer(value)) {
    return (
      <>
        <div key={`${value.type}_${idx}_1`}>
          Face Value: {currencyFormatter.format(value.faceValue || 0)}
        </div>
        <div key={`${value.type}_${idx}_2`}>
          Cash Value: {currencyFormatter.format(value.cashValue || 0)}
        </div>
        <div key={`${value.type}_${idx}_3`}>
          Death Benefit: {currencyFormatter.format(value.deathBenefit || 0)}
        </div>
      </>
    );
  }
  if (value !== undefined && value !== null && value !== "") {
    return <div key={`${value}_${idx}`}>{value}</div>;
  }
  return <span key={`na_${idx}`}>n/a</span>;
};

const SummaryAnswer: React.FC<SummaryAnswerProps> = ({
  label,
  value,
  clients,
}) => {
  const interpolatedLabel = clients?.main
    ? interpolate(label, clients.main, clients.spouse)
    : label;
  return (
    <div className={styles["summary-page__section__answer"]}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeLabel(interpolatedLabel),
        }}
      />
      <div data-clarity-mask="true">{sanitizeValue(value)}</div>
    </div>
  );
};

export default SummaryAnswer;

type SummaryAnswerGroupProps = {
  label: string;
};

export const SummaryAnswerGroup: React.FC<SummaryAnswerGroupProps> = ({
  label,
  children,
}) => (
  <div className={styles["summary-page__section__group"]}>
    <h3 data-clarity-mask="true">{sanitizeLabel(label)}</h3>
    {children}
  </div>
);
