import Link from "components/Link";
import { showModalConfirm } from "components/Modal";
import { NotificationType } from "components/Notification";
import { FormikProps } from "formik";
import { groupBy, keyBy, mapValues } from "lodash";
import logger from "logger";
import { PerPartyQuestionnaireAnswer } from "modules/parties/types/PerPartyAnswer";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { Question, QuestionType } from "modules/workflow/types/Questionnaire";
import React from "react";
import {
  canImportAnswer,
  hasAnswerToImport,
  transformAnswerForImport,
} from "./importHelper";

export type ImportAnswersProps = {
  allPageQuestions: Question[];
  clientAnswers: AnswersByQuestionnaireType;
  clientId?: string;
  currentPageQuestions: Question[];
  formHandle?: FormikProps<AnswersMap>;
  pageQuestionnaireId: string;
  spouseAnswers?: AnswersMap;
  spouseId?: string;
};

enum QuestionGroup {
  TO_IMPORT = "to_import",
  UNABLE_TO_IMPORT = "unable_to_import",
  UNANSWERED = "unanswered",
}

const ImportAnswers: React.FC<ImportAnswersProps> = ({
  allPageQuestions,
  clientAnswers,
  currentPageQuestions,
  formHandle,
  pageQuestionnaireId,
  spouseAnswers,
  spouseId,
  clientId,
}) => {
  if (!spouseAnswers) return null;

  const questionsGroups = groupBy(allPageQuestions, (question) =>
    !hasAnswerToImport(question, spouseAnswers, spouseId, clientId)
      ? QuestionGroup.UNANSWERED
      : canImportAnswer(
            question,
            clientAnswers,
            spouseAnswers,
            pageQuestionnaireId,
            currentPageQuestions,
            spouseId,
            clientId,
          )
        ? QuestionGroup.TO_IMPORT
        : QuestionGroup.UNABLE_TO_IMPORT,
  );

  const answersToImport: AnswersMap = mapValues(
    keyBy(questionsGroups[QuestionGroup.TO_IMPORT] || [], "id"),
    ({ id: questionId, data }) => {
      const spouseAnswer = transformAnswerForImport(
        spouseAnswers[data.copyFrom || questionId],
        data.definition,
        spouseId,
        clientId,
      );
      return data.definition.type === QuestionType.SUBQUESTIONNAIRE
        ? {
            ...(spouseAnswer as PerPartyQuestionnaireAnswer | undefined),
            imported: true,
          }
        : spouseAnswer;
    },
  );

  const handleImport = () => {
    formHandle &&
      showModalConfirm({
        labelConfirm: "Yes",
        onConfirm: async () => {
          formHandle.setValues(answersToImport);
          if (questionsGroups[QuestionGroup.UNABLE_TO_IMPORT]?.length) {
            logger.notify(
              NotificationType.WARNING,
              "Some questions couldn't be imported due to different selection of parties",
            );
          }
        },
        text: "Would you like to populate questions on this page with the answers your spouse provided?",
        title: "Copy your spouse's answers",
      });
  };

  return questionsGroups[QuestionGroup.TO_IMPORT]?.length ? (
    <Link onClick={handleImport}>Copy answers</Link>
  ) : null;
};

export default ImportAnswers;
