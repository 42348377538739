import LoadingOverlay from "components/LoadingOverlay";
import { isAdmin } from "modules/auth/helper";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import { go } from "modules/navigation/helpers/navigator";
import {
  getRoute,
  getWorkflowDetailsRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import {
  deleteChild,
  deleteRelatedParty,
  triggerEditParty,
} from "modules/parties/actions";
import assignChildrenToParents from "modules/parties/helpers/assignChildrenToParents";
import { CLIENT_FORM_ID } from "modules/parties/helpers/getClientQuestionnaire/constants";
import { getClientFormValues } from "modules/parties/helpers/getClientQuestionnaire";
import { isRelatedAs } from "modules/parties/helpers/getRelationshipToClient";
import Address from "modules/parties/types/Address";
import { ClientType } from "modules/parties/types/Client";
import Party, { RelationshipType } from "modules/parties/types/Party";
import collectAllAddresses from "modules/workflow/helpers/collectAllAddresses";
import { isEditable } from "modules/workflow/helpers/lifecycle";
import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";

import PeopleRegistryScreen from "./PeopleRegistryScreen";

const PeopleRegistryScreenContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: workflowId } = useParams() as Record<string, string>;
  const partnerId = getFormattedPartnerId() || undefined;
  const { workflows, isLoadingWorkflowDetails, user } = useSelector(
    (state) => state,
    shallowEqual,
  );
  const previousUrl = ((history.location.state || {}) as any).from;

  const currentWorkflow = workflows[workflowId];
  const workflowOwnerId =
    currentWorkflow?.userId !== user?.uid ? currentWorkflow?.userId : undefined;

  if (isLoadingWorkflowDetails) return <LoadingOverlay />;
  if (!currentWorkflow)
    return <Redirect to={getRoute(ROUTE_TEMPLATE.WORKFLOW)} />;

  const { clients, parties } = currentWorkflow.contacts || {};
  const mainClient = clients && clients[ClientType.MAIN];
  const relatedParties = mainClient
    ? assignChildrenToParents(Object.values(parties || {}))
    : [];
  const availableAddresses: Address[] = collectAllAddresses(
    workflows,
    workflowId,
  );

  const admin = isAdmin(partnerId, user);

  const editable = isEditable(admin, currentWorkflow?.state.steps);

  const handleTriggerEditParty = (party: Party) => {
    const clientAnswers = {
      [CLIENT_FORM_ID]: getClientFormValues(mainClient, admin),
    };
    return (
      editable &&
      triggerEditParty(
        clients,
        currentWorkflow.id,
        party,
        availableAddresses,
        workflowOwnerId,
        clientAnswers,
      )(dispatch)
    );
  };

  const handleDeleteRelatedParty = async (party: Party) => {
    if (!editable || !party.partyId) {
      return;
    }

    if (isRelatedAs(party, mainClient, RelationshipType.CHILD)) {
      return deleteChild(
        currentWorkflow?.id,
        mainClient?.clientId,
        party,
        workflowOwnerId,
      )(dispatch);
    }

    deleteRelatedParty(
      currentWorkflow?.id,
      mainClient?.clientId,
      party,
      workflowOwnerId,
    )(dispatch);
  };

  const handleBack = () =>
    go(dispatch, previousUrl || getWorkflowDetailsRoute(workflowId));

  return (
    <PeopleRegistryScreen
      client={mainClient}
      isEditable={editable}
      onBack={handleBack}
      onDeleteParty={handleDeleteRelatedParty}
      onEditParty={handleTriggerEditParty}
      relatedParties={relatedParties}
    />
  );
};

export default PeopleRegistryScreenContainer;
