import React from "react";
import { QuestionnairePage } from "modules/workflow/types/Questionnaire";
import Workflow from "modules/workflow/types/Workflow";
import ControlBar from "components/Forms/Form/ControlBar";
import ExternalDocumentPage from "./ExternalDocumentPage";
import getExternalProducts from "modules/workflow/helpers/getExternalProducts";

export const getExternalProductsPages = (
  workflow: Workflow,
): QuestionnairePage[] => {
  const externalProducts = getExternalProducts(workflow);

  return externalProducts.map((product) => ({
    id: product.id,
    caption: product.name,
    isStatic: true,
    questionnaireId: "",
    sections: [],
    render: (formProps) => (
      <div>
        <ExternalDocumentPage
          planName={workflow.plan?.name || "Plan"}
          productName={product.name}
        />
        <ControlBar {...formProps} onSave={undefined} showSaveButton={false} />
      </div>
    ),
  }));
};
