import { Dispatch, createAction } from "@reduxjs/toolkit";
import {
  CreateMedicaidLeadClientResponse,
  CreateMedicaidLeadResponse,
  FetchMedicaidLeadResponse,
  EnrichedMedicaidLead,
  SaveMedicaidLeadAnswersResponse,
  SaveMedicaidLeadClientResponse,
  UpdateMedicaidLeadPageResponse,
  GetMedicaidLeadCalculationResponse,
  MedicaidLead,
  UpdateMedicaidLeadPartnerResponse,
  MedicaidLeadPreviews,
  FetchMedicaidLeadPreviewsResponse,
  MedicaidLeadStatus,
  UpdateMedicaidLeadStatusResponse,
  MedicaidLeadReviewVerdict,
  SubmitMedicaidLeadReviewResponse,
  MedicaidCalculation,
  FetchMedicaidLeadsResponse,
  RenderMedicaidDocumentsResponse,
  MedicaidLeadDocumentWithOptionalSignedUrl,
  GetMedicaidLeadDocumentsResponse,
} from "./types";
import { API, get, patch, post, put } from "modules/api";
import { QuestionnaireID } from "modules/workflow/types/Questionnaire";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import Client, { ClientType } from "modules/parties/types/Client";
import { capitalize } from "lodash";
import { PartnerID } from "modules/parties/types/Partner";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";

export const createMedicaidLeadInitiated = createAction(
  "CREATE_MEDICAID_LEAD_INITIATED",
);

export const newMedicaidLeadCreated = createAction<EnrichedMedicaidLead>(
  "NEW_MEDICAID_LEAD_CREATED",
);

export const createMedicaidLeadFailed = createAction<any>(
  "CREATE_MEDICAID_LEAD_FAILED",
);

export const fetchMedicaidLeadInitiated = createAction<string>(
  "FETCH_MEDICAID_LEAD_INITIATED",
);

export const medicaidLeadLoaded = createAction<EnrichedMedicaidLead>(
  "MEDICAID_LEAD_LOADED",
);

export const fetchMedicaidLeadFailed = createAction<any>(
  "FETCH_MEDICAID_LEAD_FAILED",
);

export const saveMedicaidLeadAnswersInitiated = createAction(
  "SAVE_MEDICAID_ANSWERS_INITIATED",
);

export const medicaidLeadAnswersSaved = createAction<AnswersMap>(
  "MEDICAID_LEAD_ANSWERS_SAVED",
);

export const saveMedicaidLeadAnswersFailed = createAction<any>(
  "SAVED_MEDICAID_LEAD_ANSWERS_FAILED",
);

export const createMedicaidLeadClientInitiated = createAction(
  "CREATE_MEDICAID_LEAD_CLIENT_INITIATED",
);

export const medicaidLeadClientCreated = createAction<Record<string, Client>>(
  "MEDICAID_LEAD_CLIENT_CREATED",
);

export const createMedicaidLeadClientFailed = createAction<any>(
  "CREATE_MEDICAID_LEAD_CLIENT_FAILED",
);

export const saveMedicaidLeadClientInitiated = createAction(
  "SAVE_MEDICAID_LEAD_CLIENT_INITIATED",
);

export const medicaidLeadClientSaved = createAction<Record<string, Client>>(
  "MEDICAID_LEAD_CLIENT_SAVED",
);

export const saveMedicaidLeadClientFailed = createAction<any>(
  "SAVE_MEDICAID_LEAD_CLIENT_FAILED",
);

export const medicaidLeadAnswersChanged =
  createAction<AnswersByQuestionnaireType>("MEDICAID_LEAD_ANSWERS_CHANGED");

export const medicaidLeadPageChanged = createAction<number>(
  "MEDICAID_LEAD_PAGE_CHANGED",
);

export const changeMedicaidLeadPageFailed = createAction<any>(
  "CHANGE_MEDICAID_LEAD_PAGE_FAILED",
);

export const medicaidLeadCalculationInitialised = createAction(
  "MEDICAID_LEAD_CALCULATION_INITIALISED",
);

export const medicaidLeadCalculationFinished = createAction<
  GetMedicaidLeadCalculationResponse & { medicaidLeadId: string }
>("MEDICAID_LEAD_CALCULATION_FINISHED");

export const medicaidLeadCalculationFailed = createAction<any>(
  "MEDICAID_LEAD_CALCULATION_FAILED",
);

export const updateMedicaidLeadPartnerInitialised = createAction(
  "UPDATE_MEDICAID_LEAD_PARTNER_INITIALISED",
);

export const medicaidLeadPartnerUpdated = createAction<MedicaidLead>(
  "MEDICAID_LEAD_PARTNER_UPDATED",
);

export const updateMedicaidLeadPartnerFailed = createAction<any>(
  "UPDATE_MEDICAID_LEAD_PARTNER_FAILED",
);

export const fetchMedicaidLeadPreviewsInitialised = createAction(
  "FETCH_MEDICAID_LEAD_PREVIEWS_INITIALISED",
);

export const fetchMedicaidLeadPreviewsFinished =
  createAction<MedicaidLeadPreviews>("FETCH_MEDICAID_LEAD_PREVIEWS_FINISHED");

export const fetchMedicaidLeadPreviewsFailed = createAction<any>(
  "FETCH_MEDICAID_LEAD_PREVIEWS_FAILED",
);

export const updateMedicaidLeadStatusInitialised = createAction(
  "UPDATE_MEDICAID_LEAD_STATUS_INITIALISED",
);

export const medicaidLeadStatusUpdated = createAction<MedicaidLead>(
  "MEDICAID_LEAD_STATUS_UPDATED",
);

export const updateMedicaidLeadStatusFailed = createAction<any>(
  "UPDATE_MEDICAID_LEAD_STATUS_FAILED",
);

export const submitMedicaidLeadReviewInitiated = createAction(
  "SUBMIT_MEDICAID_LEAD_REVIEW_INITIATED",
);

export const fetchAdminMedicaidLeadDataInitialised = createAction(
  "FETCH_ADMIN_MEDICAID_DATA_INITIALISED",
);

export const adminMedicaidLeadDataFetched = createAction<{
  enrichedMedicaidLead: EnrichedMedicaidLead;
  medicaidLeadCalculation: MedicaidCalculation;
}>("ADMIN_MEDICAID_LEAD_DATA_FETCHED");

export const fetchAdminMedicaidLeadDataFailed = createAction<any>(
  "FETCH_ADMIN_MEDICAID_LEAD_DATA_FAILED",
);

export const medicaidLeadReviewed = createAction<{
  medicaidLeadPreviews: MedicaidLeadPreviews;
  enrichedMedicaidLead?: EnrichedMedicaidLead;
  medicaidCalculation?: MedicaidCalculation;
}>("MEDICAID_LEAD_REVIEWED");

export const submitMedicaidLeadReviewFailed = createAction<any>(
  "MEDICAID_LEAD_REVIEW_FAILED",
);

export const unsetAdminMedicaidLeadData = createAction<MedicaidLeadPreviews>(
  "UNSET_ADMIN_MEDICAID_LEAD_DATA",
);

export const fetchMedicaidLeadsInitialised = createAction(
  "FETCH_MEDICAID_LEADS_INITIALISED",
);

export const medicaidLeadsFetched = createAction<
  Record<string, EnrichedMedicaidLead>
>("MEDICAID_LEADS_FETCHED");

export const fetchMedicaidLeadsFailed = createAction<any>(
  "FETCH_MEDICAID_LEADS_FAILED",
);

export const renderMedicaidDocumentsInitialised = createAction(
  "RENDER_MEDICAID_DOCUMENTS_INITIALISED",
);

export const medicaidDocumentRenderStatusUpdated = createAction<
  MedicaidLeadDocumentWithOptionalSignedUrl[]
>("MEDICAID_DOCUMENT_RENDER_STATUS_UPDATED");

export const renderMedicaidDocumentsFinished = createAction<
  DownloadableDocumentDetails[]
>("RENDER_MEDICAID_DOCUMENTS_FINISHED");

export const renderMedicaidDocumentsFailed = createAction<any>(
  "RENDER_MEDICAID_DOCUMENTS_FAILED",
);

export const unsetRenderedMedicaidLeadDocuments = createAction(
  "UNSET_MEDICAID_LEAD_DOCUMENTS",
);

export const getMedicaidLeadDocumentsInitialised = createAction(
  "GET_MEDICAID_LEAD_DOCUMENTS_INITIALISED",
);

export const getMedicaidLeadDocumentsFailed = createAction<any>(
  "GET_MEDICAID_LEAD_DOCUMENTS_FAILED",
);

export const initialiseMedicaidLead = async (
  dispatch: Dispatch<any>,
  jurisdiction: string,
  partnerId?: PartnerID,
) => {
  dispatch(createMedicaidLeadInitiated());

  try {
    const response = await put<CreateMedicaidLeadResponse>(
      API.MEDICAID_LEAD(),
      {
        partnerId,
        jurisdiction,
      },
    );
    const { medicaidLead, questionnaire, questions, answers, clients } =
      response.data;

    const sanitisedClients = {
      [ClientType.MAIN]: clients.Main,
      [ClientType.SPOUSE]: clients.Spouse,
    };

    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD, {
        medicaidLeadId: medicaidLead.medicaidLeadId,
      }),
    );

    dispatch(
      newMedicaidLeadCreated({
        medicaidLead,
        questionnaire,
        questions,
        answers,
        clients: sanitisedClients,
      }),
    );
  } catch (error) {
    dispatch(createMedicaidLeadFailed(error));
  }
};

export const fetchMedicaidLead = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
) => {
  dispatch(fetchMedicaidLeadInitiated(medicaidLeadId));

  try {
    const response = await get<FetchMedicaidLeadResponse>(API.MEDICAID_LEAD(), {
      medicaidLeadId,
    });

    const { medicaidLead, questionnaire, questions, answers, clients } =
      response.data;

    const sanitisedClients = {
      [ClientType.MAIN]: clients.Main,
      [ClientType.SPOUSE]: clients.Spouse,
    };

    dispatch(
      medicaidLeadLoaded({
        medicaidLead,
        questionnaire,
        questions,
        answers,
        clients: sanitisedClients,
      }),
    );
  } catch (error) {
    dispatch(fetchMedicaidLeadFailed(error));
  }
};

export const saveMedicaidLeadAnswers = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
  questionnaireId: QuestionnaireID,
  answers: AnswersMap,
) => {
  dispatch(saveMedicaidLeadAnswersInitiated());

  try {
    const response = await post<SaveMedicaidLeadAnswersResponse>(
      API.MEDICAID_LEAD_ANSWERS(),
      {
        medicaidLeadId,
        answers,
      },
    );

    const { validatedAnswers } = response.data;

    dispatch(medicaidLeadAnswersSaved(validatedAnswers));
  } catch (error) {
    dispatch(saveMedicaidLeadAnswersFailed(error));
  }
};

export const createMedicaidLeadClient = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string | undefined,
  clientType: ClientType,
  client: Partial<Client>,
) => {
  dispatch(createMedicaidLeadClientInitiated());

  try {
    if (medicaidLeadId === undefined) {
      throw new Error(
        "Could not create medicaid lead client: Medicaid lead undefined",
      );
    }
    const response = await put<CreateMedicaidLeadClientResponse>(
      API.MEDICAID_LEAD_CLIENT(),
      { medicaidLeadId, clientType: capitalize(clientType), ...client },
    );

    const { client: medicaidLeadClient } = response.data;

    dispatch(medicaidLeadClientCreated({ [clientType]: medicaidLeadClient }));
  } catch (error) {
    dispatch(createMedicaidLeadClientFailed(error));
  }
};

export const saveMedicaidLeadClient = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string | undefined,
  clientId: string,
  clientType: ClientType,
  client: Partial<Client>,
) => {
  dispatch(saveMedicaidLeadClientInitiated());

  try {
    if (medicaidLeadId === undefined) {
      throw new Error(
        "Could not save medicaid lead client: Medicaid Lead Id was undefined",
      );
    }
    const response = await patch<SaveMedicaidLeadClientResponse>(
      API.MEDICAID_LEAD_CLIENT(),
      {
        medicaidLeadId,
        clientType: capitalize(clientType),
        ...client,
        clientId,
      },
    );

    const { client: medicaidLeadClient } = response.data;

    dispatch(medicaidLeadClientSaved({ [clientType]: medicaidLeadClient }));
  } catch (error) {
    dispatch(saveMedicaidLeadClientFailed(error));
  }
};

export const changeMedicaidLeadPage = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
  pageIdx: number,
) => {
  dispatch(medicaidLeadPageChanged(pageIdx));

  try {
    await patch<UpdateMedicaidLeadPageResponse>(API.MEDICAID_LEAD_PAGE(), {
      medicaidLeadId,
      pageIdx,
    });
  } catch (error) {
    dispatch(changeMedicaidLeadPageFailed(error));
  }
};

export const getMedicaidLeadCalculation = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
) => {
  dispatch(medicaidLeadCalculationInitialised);

  try {
    const response = await get<GetMedicaidLeadCalculationResponse>(
      API.MEDICAID_LEAD_CALCULATION(),
      {
        medicaidLeadId,
      },
    );

    dispatch(
      medicaidLeadCalculationFinished({
        ...response.data,
        medicaidLeadId,
      }),
    );
  } catch (error) {
    dispatch(medicaidLeadCalculationFailed(error));
  }
};

export const updateMedicaidLeadPartner = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
  partnerId: PartnerID,
) => {
  dispatch(updateMedicaidLeadPartnerInitialised);

  try {
    const response = await patch<UpdateMedicaidLeadPartnerResponse>(
      API.MEDICAID_LEAD_PARTNER(),
      {
        medicaidLeadId,
        partnerId,
      },
    );

    dispatch(medicaidLeadPartnerUpdated(response.data));
  } catch (error) {
    dispatch(updateMedicaidLeadPartnerFailed(error));
  }
};

export const fetchMedicaidLeadPreviews = async (dispatch: Dispatch<any>) => {
  dispatch(fetchMedicaidLeadPreviewsInitialised);

  try {
    const response = await get<FetchMedicaidLeadPreviewsResponse>(
      API.MEDICAID_LEAD_PREVIEWS(),
    );

    dispatch(fetchMedicaidLeadPreviewsFinished(response.data));
  } catch (error) {
    dispatch(fetchMedicaidLeadPreviewsFailed(error));
  }
};

export const updateMedicaidLeadStatus = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
  status: MedicaidLeadStatus,
) => {
  dispatch(updateMedicaidLeadStatusInitialised);

  try {
    const response = await patch<UpdateMedicaidLeadStatusResponse>(
      API.MEDICAID_LEAD_STATUS(),
      {
        medicaidLeadId,
        status,
      },
    );

    dispatch(medicaidLeadStatusUpdated(response.data));
  } catch (error) {
    dispatch(updateMedicaidLeadStatusFailed(error));
  }
};

export const submitMedicaidLeadReview = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
  verdict: MedicaidLeadReviewVerdict,
) => {
  dispatch(submitMedicaidLeadReviewInitiated);

  try {
    const response = await post<SubmitMedicaidLeadReviewResponse>(
      API.MEDICAID_LEAD_REVIEW(),
      {
        medicaidLeadId,
        verdict,
      },
    );

    dispatch(medicaidLeadReviewed(response.data));
  } catch (error) {
    dispatch(submitMedicaidLeadReviewFailed(error));
  }
};

export const fetchAdminMedicaidLeadData = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
) => {
  dispatch(fetchAdminMedicaidLeadDataInitialised);

  try {
    const fetchMedicaidLeadResponse = await get<FetchMedicaidLeadResponse>(
      API.MEDICAID_LEAD(),
      {
        medicaidLeadId,
      },
    );

    const getMedicaiLeadCalculationResponse =
      await get<GetMedicaidLeadCalculationResponse>(
        API.MEDICAID_LEAD_CALCULATION(),
        {
          medicaidLeadId,
        },
      );

    const enrichedMedicaidLead = fetchMedicaidLeadResponse.data;

    const medicaidLeadCalculation = getMedicaiLeadCalculationResponse.data;

    dispatch(
      adminMedicaidLeadDataFetched({
        enrichedMedicaidLead,
        medicaidLeadCalculation,
      }),
    );
  } catch (error) {
    dispatch(fetchAdminMedicaidLeadDataFailed(error));
  }
};

export const fetchMedicaidLeads = async (dispatch: Dispatch<any>) => {
  dispatch(fetchMedicaidLeadsInitialised);

  try {
    const response = await get<FetchMedicaidLeadsResponse>(
      API.MEDICAID_LEADS(),
    );

    dispatch(medicaidLeadsFetched(response.data));
  } catch (error) {
    dispatch(fetchMedicaidLeadsFailed(error));
  }
};

export const renderMedicaidDocuments = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
) => {
  dispatch(renderMedicaidDocumentsInitialised);

  try {
    const response = await post<RenderMedicaidDocumentsResponse>(
      API.RENDER_MEDICAID_DOCUMENTS(),
      {
        medicaidLeadId,
      },
    );

    const { documents } = response.data;

    dispatch(medicaidDocumentRenderStatusUpdated(documents));
  } catch (error) {
    dispatch(renderMedicaidDocumentsFailed(error));
  }
};

export const getMedicaidLeadDocuments = async (
  dispatch: Dispatch<any>,
  medicaidLeadId: string,
) => {
  dispatch(getMedicaidLeadDocumentsInitialised);

  try {
    const response = await get<GetMedicaidLeadDocumentsResponse>(
      API.GET_MEDICAID_LEAD_DOCUMENTS(),
      {
        medicaidLeadId,
      },
    );

    const { documents } = response.data;

    dispatch(medicaidDocumentRenderStatusUpdated(documents));
  } catch (error) {
    dispatch(getMedicaidLeadDocumentsFailed(error));
  }
};
