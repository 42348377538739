import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import React, { useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  fetchMedicaidLead,
  initialiseMedicaidLead,
} from "modules/medicaid/actions";
import { shallowEqual, useDispatch } from "react-redux";
import MedicaidLeadInterview from "../MedicaidLeadInterview";
import { useSelector } from "redux/reducer";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const MedicaidLeadContainer = () => {
  const dispatch = useDispatch();
  const { lead, isLoadingMedicaidLead } = useContext(MedicaidLeadContext);

  const { jurisdiction, partnerId } = useSelector(
    (state) => ({
      jurisdiction: state.state,
      partnerId: state.partner?.id,
    }),
    shallowEqual,
  );

  const { medicaidLeadId } = useParams<{ medicaidLeadId?: string }>();

  if (jurisdiction === null) {
    return <Redirect to={ROUTE_TEMPLATE.ROOT} />;
  }

  if (!isLoadingMedicaidLead) {
    if (medicaidLeadId === undefined) {
      initialiseMedicaidLead(dispatch, jurisdiction, partnerId);
    } else if (lead === undefined) {
      fetchMedicaidLead(dispatch, medicaidLeadId);
    }
  }

  if (lead === undefined || isLoadingMedicaidLead)
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;

  return <MedicaidLeadInterview />;
};

export default MedicaidLeadContainer;
