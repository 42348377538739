import * as React from "react";
import classNames from "classnames/bind";

import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type HeaderAccountModalNavigationLinkProps = {
  label: string;
  icon?: string;
  alert?: number;
  onClick: () => void;
};

export const HeaderAccountModalNavigationLink: React.FunctionComponent<
  HeaderAccountModalNavigationLinkProps
> = ({ children, label, icon, alert, onClick }) => {
  let className = cx(
    "header-account-modal__icon",
    icon ? "header-account-modal__icon--" + icon : undefined,
  );
  return (
    <li className={styles["header-account-modal__item"]}>
      <TabbedButton
        tabIndex={0}
        className={styles["header-account-modal__link"]}
        onClick={onClick}
      >
        <div className={className}></div>
        <span className={styles["header-account-modal__link-text"]}>
          {label}
        </span>
        {alert && alert > 0 && (
          <span
            className={styles["header-account-modal__link-alert-container"]}
          >
            <span className={styles["header-account-modal__link-alert"]}>
              {alert}
            </span>
          </span>
        )}
      </TabbedButton>
    </li>
  );
};

type HeaderAccountModalNavigationProps = {};

const HeaderAccountModalNavigation: React.FunctionComponent<
  HeaderAccountModalNavigationProps
> = ({ children }) => {
  return <ul className={styles["header-account-modal__list"]}>{children}</ul>;
};

export default HeaderAccountModalNavigation;
