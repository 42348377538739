import { Box } from "@mui/material";
import axios from "axios";
import { ModalControls, ModalType, showModal } from "components/Modal";
import TabbedButton from "components/TabbedButton";
import logger, { TYPE } from "logger";
import { API, post } from "modules/api";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { AnswersMap } from "modules/workflow/types/Answer";
import {
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import React from "react";
import usePermissions from "./usePermissions";

const PermissionConfigurationAdd: React.FC = () => {
  const { refetchPermissions } = usePermissions();

  const handleModalOpen = () => {
    showPermission(handleAddPermission);
  };

  const handleAddPermission = async (email: string) => {
    try {
      const res = await post(API.PERMISSIONS(), { role: "Admin", email });
      refetchPermissions(
        (permissions) => permissions && permissions.concat([res.data]),
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        logger.notify(
          TYPE.ERROR,
          "There is no such e-mail in the database",
          error,
        );
      } else {
        logger.notify(
          TYPE.ERROR,
          "Adding permission failed. Please, try again later",
          error,
        );
      }
    }
  };

  return (
    <Box mb={2}>
      <TabbedButton onClick={handleModalOpen}>Add New Permission</TabbedButton>
    </Box>
  );
};

export const showPermission = (onSave: (email: string) => void) => {
  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleFormSubmit = async () =>
    submitHandle ? submitHandle() : undefined;

  showModal({
    title: "Add Permission",
    type: ModalType.CONFIRM,
    onConfirm: handleFormSubmit,
    renderContent: (controls: ModalControls) => (
      <PermissionModalContent
        getSubmitHandle={getSubmitHandle}
        onSave={onSave}
        controls={controls}
      />
    ),
  });
};

type ModalProps = {
  getSubmitHandle?: (submitHandle: () => any) => void;
  controls: ModalControls;
  onSave: (state: string) => void;
};

const QUESTIONNAIRE_ID = "addPermissionModal";

const PermissionModalContent: React.FC<ModalProps> = ({
  getSubmitHandle,
  onSave,
  controls,
}) => {
  const handleSave = async (_: any, values: AnswersMap) => {
    const { email } = values || {};
    controls?.busy(true);
    email && (await onSave(String(email)));
    controls?.close();
  };
  return (
    <QuestionnaireForm
      getSubmitHandle={getSubmitHandle}
      customFormProps={{ showSaveAndExitButton: false }}
      onSavePageAnswers={handleSave}
      questionsBySubquestionnaire={{
        [QUESTIONNAIRE_ID]: {
          email: {
            id: "email",
            data: {
              required: true,
              definition: {
                type: QuestionType.TEXT,
                subtype: TextFieldType.EMAIL,
              },
              descriptions: [{ label: "E-mail" }],
            },
          },
        },
      }}
      questionnairePages={[
        {
          id: "default_page",
          questionnaireId: QUESTIONNAIRE_ID,
          caption: "",
          sections: [
            {
              caption: "",
              questions: ["email"],
            },
          ],
        },
      ]}
    />
  );
};

export default PermissionConfigurationAdd;
