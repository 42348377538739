import React, { useContext, useEffect, useMemo } from "react";

import ProductSelectorItem from "./ProductSelectorItem";
import Product from "modules/product/types/Product";
import Plan from "modules/product/types/Plan";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";

import styles from "./styles.module.scss";
import PlanningDisclaimer from "../PlanningDisclaimer";
import ProductSelectorItemSmall from "./ProductSelectorItemSmall";
import { sortBy, sumBy } from "lodash";
import LoadingOverlay from "components/LoadingOverlay";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EcommerceItemID,
  EventName,
  ItemListID,
} from "contexts/DataLayerContext/types";
import {
  getEcommerceItemCategory,
  getEcommerceItemID,
  getEcommerceItemName,
  getItemListName,
} from "contexts/DataLayerContext/helpers";
import { buildEcommerceItemsArray } from "modules/landing/helpers";

type ProductSelectorProps = {
  onSelectBasicPlan: (workflowId: string, plan: Plan) => any;
  onSelectBasicProduct: (workflowId: string, product: Product) => any;
  onSelectPlan: (workflowId: string, plan: Plan) => any;
  onSelectProduct: (workflowId: string, product: Product) => any;
  onStartAdvisor: () => void;
  workflowDefinitions: Record<string, DefinitionWithProductsPlansAndAddons>;
  partnerName?: string;
  partnerId?: string;
};

type ProductSelectorBoxProps = {
  definition: DefinitionWithProductsPlansAndAddons;
  onSelectBasicPlan: (plan: Plan) => any;
  onSelectBasicProduct: (product: Product) => any;
};

function planComparator(plan1: Plan, plan2: Plan) {
  if (plan1.id > plan2.id) return -1;
  else return +1;
}

const ProductSelectorBox = ({
  definition,
  onSelectBasicPlan,
  onSelectBasicProduct,
}: ProductSelectorBoxProps) => {
  const products = sortBy(definition.data.availableProducts, [
    "priority",
    "name",
  ]);
  const plans = definition.data.availablePlans;

  return (
    <div className={styles["product-selector-box"]}>
      <div className={styles["product-selector-box__cols"]}>
        <div className={styles["product-selector-box__plans"]}>
          {plans
            ?.filter((plan) => plan.standalone !== false)
            .sort(planComparator)
            .map((plan) => (
              <ProductSelectorItem
                key={plan.id}
                onSelectBasic={onSelectBasicPlan}
                item={plan}
                priceSaving={
                  plan.id === "will-poa-hcp"
                    ? sumBy(products, (product) => product.price.regular) -
                      plan.price.regular
                    : plan.id === "base-plan"
                      ? sumBy(
                          products.filter(
                            (product) =>
                              product.id === "hcp-multi-product" ||
                              product.id === "product-poa-multi",
                          ),
                          (product) => product.price.regular,
                        ) - plan.price.regular
                      : undefined
                }
                buttonLabel={
                  plan.id === "will-poa-hcp"
                    ? "Select All Three"
                    : "Select Both"
                }
              />
            ))}
        </div>
        <div className={styles["product-selector-box__products"]}>
          {products?.map(
            (product) =>
              !product.external && (
                <ProductSelectorItemSmall
                  key={product.id}
                  onSelectBasic={onSelectBasicProduct}
                  item={product}
                  buttonLabel="Select"
                />
              ),
          )}
        </div>
      </div>
      <PlanningDisclaimer />
    </div>
  );
};

const ProductSelector = ({
  onSelectBasicPlan,
  onSelectBasicProduct,
  workflowDefinitions,
  partnerName,
  partnerId,
}: ProductSelectorProps) => {
  const { sendEvent } = useContext(DataLayerContext);
  // TODO multiple definitions not supported
  const defaultWorkflowDefinition = Object.values(workflowDefinitions)[0];

  const ecommerceItemOrderMap = useMemo(
    () => ({
      [EcommerceItemID.ESSENTIALS]: 0,
      [EcommerceItemID.POWERS]: 1,
      [EcommerceItemID.WILL]: 2,
      [EcommerceItemID.POA]: 3,
      [EcommerceItemID.HCP]: 4,
      [EcommerceItemID.SELF_SIGNING]: -1,
      [EcommerceItemID.SUPERVISED_SIGNING]: -1,
    }),
    [],
  );

  useEffect(() => {
    if (defaultWorkflowDefinition !== undefined && partnerId !== undefined) {
      sendEvent?.({
        event: EventName.ViewItemList,
        item_list_id: ItemListID.NewWorkflow,
        item_list_name: getItemListName(ItemListID.NewWorkflow),
        items: buildEcommerceItemsArray(
          defaultWorkflowDefinition,
          ItemListID.NewWorkflow,
          partnerId,
          ecommerceItemOrderMap,
        ),
      });
    }
  }, [defaultWorkflowDefinition, ecommerceItemOrderMap, partnerId, sendEvent]);

  const handleSelectPlan =
    (definitionId: string) =>
    (plan: Plan): any => {
      const itemId = getEcommerceItemID(plan.id);
      if (itemId !== undefined) {
        sendEvent?.({
          event: EventName.SelectItem,
          item_list_id: ItemListID.NewWorkflow,
          item_list_name: getItemListName(ItemListID.NewWorkflow),
          items: [
            {
              item_id: itemId,
              item_name: getEcommerceItemName(itemId),
              index: ecommerceItemOrderMap[itemId],
              item_brand: partnerId,
              item_category: getEcommerceItemCategory(itemId),
              item_list_id: ItemListID.NewWorkflow,
              item_list_name: getItemListName(ItemListID.NewWorkflow),
              price: plan.price.regular,
              quantity: 1,
            },
          ],
        });
      }

      onSelectBasicPlan(definitionId, plan);
    };

  const handleSelectProduct =
    (definitionId: string) =>
    (product: Product): any => {
      const itemId = getEcommerceItemID(product.id);
      if (itemId !== undefined) {
        sendEvent?.({
          event: EventName.SelectItem,
          item_list_id: ItemListID.NewWorkflow,
          item_list_name: getItemListName(ItemListID.NewWorkflow),
          items: [
            {
              item_id: itemId,
              item_name: getEcommerceItemName(itemId),
              index: ecommerceItemOrderMap[itemId],
              item_brand: partnerId,
              item_category: getEcommerceItemCategory(itemId),
              item_list_id: ItemListID.NewWorkflow,
              item_list_name: getItemListName(ItemListID.NewWorkflow),
              price: product.price.regular,
              quantity: 1,
            },
          ],
        });
      }

      onSelectBasicProduct(definitionId, product);
    };

  if (!defaultWorkflowDefinition) {
    return <LoadingOverlay />;
  }

  return (
    <div className={styles["product-selector"]}>
      <h1 className={styles["product-selector__title"]}>
        Get any or all of the{" "}
        <span>Essential Estate Planning Documents from</span>
      </h1>
      <h2 className={styles["product-selector__partner"]}>{partnerName}</h2>
      <ProductSelectorBox
        definition={defaultWorkflowDefinition}
        onSelectBasicPlan={handleSelectPlan(defaultWorkflowDefinition.id)}
        onSelectBasicProduct={handleSelectProduct(defaultWorkflowDefinition.id)}
      />
    </div>
  );
};

export default ProductSelector;
