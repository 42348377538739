import { FooterNavigationLink } from "components/Footer/FooterNavigation";
import React from "react";

import LogoInvertedImg from "resources/logos/Aturna_Brandmark_RGB_White.svg";
import { LandingFooterProps } from "./types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import {
  go,
  navigateToGlobalLanding,
  navigateToPartnerLanding,
} from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { openPlanningDisclaimerModal } from "modules/product/components/PlanningDisclaimer";
import { ReactComponent as LogoSVGNoFill } from "resources/logos/Aturna_Brandmark_RGB_NoFill.svg";

const cx = classNames.bind(styles);

const LandingFooter = ({ user, partner }: LandingFooterProps) => {
  const dispatch = useDispatch();

  const handleNavigateToTermsOfUse = () =>
    go(dispatch, ROUTE_TEMPLATE.TERMS_AND_CONDITIONS);
  const handleNavigateToPrivacyPolicy = () =>
    go(dispatch, ROUTE_TEMPLATE.PRIVACY);

  const handleNavigateToCookiesPolicy = () =>
    go(dispatch, ROUTE_TEMPLATE.COOKIES_POLICY);

  const handleClickFooterLogo = () => {
    if (partner) {
      navigateToPartnerLanding(dispatch, partner.id);
    } else {
      navigateToGlobalLanding(dispatch);
    }
  };

  return (
    <footer className={cx("footer")}>
      <div className={cx("landing__container-100")}>
        <div className={cx("grid", "landing__grid", "footer__grid")}>
          <div className={cx("grid__item", "footer__info-container")}>
            {partner && (
              <div className={cx("footer__info-container__powered-by")}>
                <h2
                  className={cx("footer__info-container__powered-by__heading")}
                >
                  Powered by{" "}
                  <img
                    src={LogoInvertedImg}
                    alt="aturna.legal&#8480;"
                    width="98"
                    height="25"
                  />
                </h2>
                <h2
                  className={cx(
                    "footer__info-container__powered-by__subheading",
                  )}
                >
                  empowered by{" "}
                  <span
                    className={cx(
                      "footer__info-container__powered-by__subheading__partner-display-name",
                    )}
                  >
                    {partner.data.displayName}
                  </span>
                </h2>
              </div>
            )}

            <div className={cx("grid", "footer__info-container__links")}>
              <div
                className={cx(
                  "grid__item",
                  "footer__info-container__links__general",
                )}
              >
                <div
                  className={cx(
                    "footer__info-container__links__general__heading",
                  )}
                >
                  General
                </div>
                <div
                  className={cx(
                    "footer__info-container__links__general__links",
                  )}
                >
                  <ol>
                    <FooterNavigationLink
                      label="Terms of Use"
                      onClick={handleNavigateToTermsOfUse}
                      className={styles["footer-link"]}
                      divider
                    />
                    <FooterNavigationLink
                      label="Privacy Policy"
                      onClick={handleNavigateToPrivacyPolicy}
                      className={styles["footer-link"]}
                      divider
                    />
                    <FooterNavigationLink
                      label="Planning Disclaimer"
                      onClick={openPlanningDisclaimerModal}
                      className={styles["footer-link"]}
                      divider
                    />
                    <FooterNavigationLink
                      label="Cookies Policy"
                      onClick={handleNavigateToCookiesPolicy}
                      className={styles["footer-link"]}
                    />
                  </ol>
                </div>
              </div>
            </div>
            <p className={cx("footer__info-container__copyright")}>
              Copyright &copy; Aturna&#8480; 2024
            </p>
          </div>
          <div
            className={cx("grid__item", "footer-logo")}
            onClick={handleClickFooterLogo}
          >
            <LogoSVGNoFill aria-label="Logo" />
            <p>Find your legal way forward</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
