import ContentPage from "components/ContentPage";
import Link from "components/Link";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import React from "react";
import { useDispatch } from "react-redux";

import styles from "./styles.module.scss";

const TermsPage: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.policy}>
      <ContentPage title="Terms of Use">
        <p>Last updated: January 19th, 2024</p>
        <p>
          By using the Aturna&#8480; Estate Planning Document Platform
          (“Platform”) you understand and agree to be bound by these terms of
          use ("Terms of Use") as may be updated from time to time at the sole
          discretion of Aturna. In these Terms of Use, the words "you" and
          "your" refer to each Platform user. "We", "us", "our", and "Aturna"
          refer to GUIDR LLC. “Estate Planning Documents” refers to the estate
          planning templates, and documents that are available in, and/or may be
          generated from, the Platform.
        </p>
        <p>
          <strong>
            If you do not agree to these Terms of Use, you are not authorized by
            Aturna to access or use the Platform.{" "}
          </strong>
          If you have any questions about these Terms of Use, please contact:{" "}
          <a href="mailto:support@aturna.legal">support@aturna.legal</a>
        </p>

        <h2>1. About the Aturna&#8480; Estate Planning Document Platform</h2>
        <p>
          The Platform is an online service that automates the generation of
          Estate Planning Documents based on information that is input into the
          Platform by a user.{" "}
          <strong>
            Aturna is not a law firm and may not perform services performed by
            an attorney. By using the Platform, you are not receiving legal
            advice from Aturna. The Platform and Estate Planning Documents are
            not a substitute for the advice or services of an attorney. The
            Platform is intended to be used in conjunction with legal services
            from an Authorized Attorney, as further defined and described below.
            Those legal services are not provided by Aturna.
          </strong>{" "}
          The law is different from jurisdiction to jurisdiction and is subject
          to various interpretations by different courts. The Platform and
          Estate Planning Documents are only intended be used in the
          jurisdiction(s) where the Authorized Attorney is providing you with
          the legal services using the Aturna&#8480; Platform.
        </p>

        <h2>2. Who May Use The Platform</h2>
        <p>
          The Platform may be used by licensed attorneys and law firms who have
          entered into an Aturna&#8480; Law Firm User Agreement and agree to
          Terms of Use (“Authorized Attorneys”). The Platform may also be used
          by clients of Authorized Attorneys (“Authorized Clients”) who have
          engaged an Authorized Attorney for legal services and who also agree
          to these Terms of Use. Authorized Attorneys and Authorized Clients may
          be referred to herein as “Authorized Users.” Access to or use of the
          Platform by anyone other than Authorized Users is prohibited.
        </p>
        <p>
          Aturna is directed to users in the 50 United States and District of
          Columbia. Aturna makes no representations that the Platform is
          appropriate or available for use elsewhere. It is the responsibility
          of the Authorized Client to use the correct State jurisdiction, based
          on their state residency requirements and applicable rule of law. You
          may not use the Platform if you are a resident outside the United
          States, of a country embargoed by the United States, or are a foreign
          person or entity blocked or denied by the United States government.
        </p>
        <p>
          You may only create an account on behalf of yourself, or yourself and
          a spouse or legally recognized domestic partner (“Spouse”). If You
          create an account on behalf of yourself and a Spouse, You represent
          you have permission to enter estate planning information on behalf of
          your Spouse, with their explicit permission and at their exact
          direction. The User whose email address is used to create an account
          and initiate the estate plan is the primary account owner, but any
          additional account user must also agree to these terms.
        </p>

        <h2>3. Legal Services from An Authorized Attorney</h2>
        <p>
          If you are an Authorized Client, you represent that you have engaged
          an Authorized Attorney to provide you with estate planning legal
          services. The terms of your engagement with the Authorized Attorney
          are subject to your agreement with such attorney. Aturna is not
          providing you with legal services or legal advice. You agree that the
          information you input into the Platform, and the Estate Planning
          Documents that are generated based on your information, will be shared
          with your Authorized Attorney, and you expressly consent to the
          sharing of such information and documents.
        </p>
        <p>
          If you are an Authorized Attorney, you have been engaged by an
          Authorized Client to provide he/she/it with estate planning legal
          services. The terms of your engagement with Authorized Clients are
          subject to your agreement with each such client. You will have access
          to and/or receive information that the Authorized Client inputs into
          the Platform, and you agree to have access to and/or receive the
          Estate Planning Documents that are generated based on such
          information. You agree to use such information and documents solely
          for the purpose of providing estate planning legal services to the
          Authorized Client. Your use of such information and documents will be
          subject to the agreement between you and the Authorized Client and any
          applicable attorney-client privilege.
        </p>
        <p>
          Further, Aturna does not provide any tax advice of any type or for any
          purposes. Aturna also does not provide financial advice. To the extent
          there is financial information on the Aturna platform, it is for
          educational purposes only. Any graphics or similar content presented
          about financial assets in Aturna is intended for informational
          purposes only, and may not represent an accurate picture of your
          finances or your ability (or inability) to qualify for Medicaid.
        </p>

        <h2>4. Fees for Authorized Clients</h2>
        <p>
          If you are an Authorized Client, you agree to pay to Aturna the fees
          for Estate Planning legal services that are provided to you by your
          Authorized Attorney (“Fees”). The Fees will be as published in the
          Authorized Attorney's then-current Fee Schedule, which may change from
          time to time. Aturna will remit to your Authorized Attorney the Fees
          that are payable to such attorney for its legal services, less any
          amounts the Authorized Attorney is obligated to pay Aturna for use of
          the Platform. Should any Fees be collected for legal services that
          have not yet been fully performed by your Authorized Attorney, the
          unearned portion of any such Fees shall be refundable in accordance
          with the Authorized Attorney's refund policy. Other fees, taxes, or
          third-party fees may apply.
        </p>

        <h2>5. Privacy Policy</h2>
        <p>
          Aturna respects your privacy and permits you to control the treatment
          of your personal information. A copy of Aturna's{" "}
          <Link onClick={() => go(dispatch, ROUTE_TEMPLATE.PRIVACY)}>
            Privacy Policy
          </Link>{" "}
          is expressly incorporated into these Terms of Use.
        </p>

        <h2>6. Username and Password</h2>
        <p>
          You will only be able to access the Platform through a secure online
          portal. You will be asked to create a username and password for your
          own account within the Platform. You may be offered the ability to
          login via third-party sign-in. Any activity on your account will be
          deemed to be authorized by you, whether you authorized that activity
          or not. You are entirely responsible for maintaining the
          confidentiality of your username and password. You may not use a third
          party's account, a third party's username, or a third party's password
          at any time. You agree to notify Aturna immediately of any
          unauthorized use of your account, username, or password. Aturna will
          not be liable for any loss or damage you incur as a result of someone
          else's use of your account, username, or password, either with or
          without your knowledge. You may be held liable for any losses incurred
          by Aturna, our affiliates, licensors, service providers, officers,
          directors, employees, consultants, agents, and representatives due to
          someone else's use of your account, username, or password.
        </p>

        <h2>7. Personal Information </h2>
        <p>
          Through the Platform, Authorized Clients will have the opportunity to
          provide personal information to be used for estate planning purposes
          (“Personal Information”). You acknowledge and agree that you are
          solely responsible for the accuracy and completeness of your Personal
          Information and that you are providing such Personal Information
          knowingly and willingly. We will employ information security practices
          and procedures commensurate with reasonable industry standards to
          protect your Personal Information. You hereby grant to Aturna a
          worldwide, royalty-free, nonexclusive, and fully sublicensable license
          to use, distribute, reproduce, modify, publish, and translate your
          Personal Information to enable your use of the Platform and to
          generate Estate Planning Documents. You also expressly consent to
          Aturna sharing your Personal Information and your Estate Planning
          Documents with your Authorized Attorney. We may use, without
          limitation, any data or information about use of the Platform that is
          not individually identifiable to you or any other particular user,
          such as data or information that has been aggregated and anonymized.
          More information on how we use and protect your Personal Information
          is in our{" "}
          <Link onClick={() => go(dispatch, ROUTE_TEMPLATE.PRIVACY)}>
            Privacy Policy
          </Link>
          .
        </p>

        <h2>8. Permission to Download and Use Estate Planning Documents</h2>
        <p>
          If you are an Authorized Client, Aturna hereby grants you and your
          Authorized Attorney permission to download, view, copy and print the
          Estate Planning Documents for which you have paid the required Fee,
          and to use such Estate Planning Documents for the Authorized Client's
          personal estate planning. You may not sell, license, or redistribute
          the Estate Planning Documents, or use the Estate Planning Documents or
          any materials or information available in the Platform for the benefit
          of any other person or for any commercial purpose.
        </p>

        <h2>9. Ownership</h2>
        <p>
          All right, title and interest in and to the Platform and Estate
          Planning Documents, including, but not limited to the software,
          system, services, documents, written materials, forms, templates,
          letters, reports, tools, videos, other content in any media and/or
          formats, workflows, processes, strategies, and methodologies, and all
          intellectual property rights therein, including, but not limited to
          all patent rights, copyright, trade secret, trademarks, and other
          proprietary rights of any kind, are owned by Aturna or its licensors,
          affiliates, or service providers. Except as expressly authorized, no
          part of the Platform and Estate Planning Documents may be copied,
          reproduced, republished, downloaded, uploaded, posted, displayed,
          transmitted, or distributed in any way. No license is granted under
          any of Aturna's intellectual property rights, whether by estoppel,
          implication or otherwise. Any rights not expressly granted herein are
          reserved by Aturna and its licensors, affiliates, and service
          providers.
        </p>

        <h2>10. Restrictions on Use of the Platform</h2>
        <p>
          You may only use the Platform as permitted by law, including all
          applicable federal, state, local or international laws and
          regulations. Without limiting the foregoing, you may not, for example:
        </p>
        <ul>
          <li>
            Use any engine, software, tool, agent, device, mechanism, or the
            like to access, search, or download content or intellectual property
            from the Platform in a way that is not expressly authorized under
            these Terms of Use;
          </li>
          <li>
            Probe, scan, or test the security vulnerability of the Platform or
            any system or network operated by Aturna;
          </li>
          <li>Breach any security or authentication measures;</li>
          <li>
            Use the Platform to derive information for the purposes of
            developing a system that competes with the Platform;
          </li>
          <li>
            Decipher, decompile, disassemble, or reverse engineer any of the
            software used in connection with the Platform;
          </li>
          <li>
            Plant malware or viruses use the Platform to distribute malware or
            viruses;
          </li>
          <li>Violate the privacy of others;</li>
          <li>Collect or track the personal information of others;</li>
          <li>Violate any applicable law or regulation;</li>
          <li>
            Impersonate or misrepresent your affiliation with any person or
            entity; or post or transmit anything that is fraudulent or
            misleading;{" "}
          </li>
          <li>
            Send unsolicited communications, promotions, advertisements, or spam
            or otherwise infringe on others' rights;
          </li>
          <li>
            Harass, abuse, insult, harm, defame, slander, disparage, intimidate,
            or discriminate based on gender, sexual orientation, religion,
            ethnicity, race, age, national origin, or disability;
          </li>
          <li>
            Interfere with the access of any user, host, or network, including
            introducing any virus to, overloading, flooding, spamming, or
            introducing any other material or content which is malicious or
            technologically harmful;
          </li>
          <li>
            Attack the Platform via a denial-of-service attack or a distributed
            denial-of-service attack; or otherwise attempt to interfere with the
            proper working of the Platform; or
          </li>
          <li>
            Attempt any of the above or encourage or enable any other individual
            to do any of the above.
          </li>
        </ul>
        <p>
          We have the right to investigate violations of these Terms of Use and
          may also consult and cooperate with law enforcement authorities to
          prosecute users who violate the law.
        </p>

        <h2>11. Links to Third Party Sites</h2>
        <p>
          The Platform may contain links to websites controlled by third parties
          (“Third-Party Site(s)”). Aturna is not responsible for and does not
          endorse or accept any responsibility for the availability, contents,
          products, services or use of any Third-Party Site, any website
          accessed from a Third-Party Site, or any changes or updates to such
          sites. Aturna makes no guarantees about the content or quality of the
          products or services provided by such sites. The inclusion of any link
          to a Third-Party Site is for convenience only and does not imply
          endorsement by Aturna, nor does it imply that Aturna sponsors, is
          affiliated or associated with, guarantees, or is legally authorized to
          use any trade name, registered trademark, logo, legal or official
          seal, or copyrighted symbol that may be reflected in the links. You
          acknowledge that you bear all risks associated with access to and use
          of content provided on a Third-Party Site and agree that Aturna is not
          responsible for any loss or damage of any sort you may incur from
          dealing with a third party.
        </p>

        <h2>12. Feedback</h2>
        <p>
          Any feedback or comments you provide to us or to any other party
          regarding the Platform, either in response to a request from us or as
          an unsolicited idea, suggestion, or proposal (collectively,
          “Feedback”), will be owned by Aturna and may be used by Aturna for any
          purpose without restriction. You agree that your Feedback will not
          contain: (a) any unlawful, libelous, abusive, or obscene material; or
          (b) a computer virus or other malware that could affect the operation
          of the Platform. You agree that you will not (i) misrepresent yourself
          as someone else; (ii) use a false email address to conceal your
          identity; or (iii) mislead Aturna and/or third parties as to the
          origin of your Feedback. You agree that your Feedback will not violate
          any third-party rights including copyright, trademark, privacy,
          confidentiality, proprietary or trade secrets.
        </p>

        <h2>13. Representations and Warranties </h2>
        <p>
          You represent and warrant that (i) you have the right, power, and
          authority to consent to these Terms of Use, (ii) you will abide by
          these Terms of Use, and (iii) in accessing and using the Platform, you
          will abide by all applicable laws and regulations.{" "}
        </p>

        <h2>14. Aturna's Warranty Disclaimer</h2>
        <p>
          THE PLATFORM IS PROVIDED TO YOU "AS IS" AND WITH ALL FAULTS AND
          DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
          UNDER APPLICABLE LAW, ATURNA, ON ITS OWN BEHALF AND ON BEHALF OF ITS
          LICENSORS, AFFILIATES, SERVICE PROVIDERS AND ITS AND THEIR RESPECTIVE
          LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES,
          WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE
          PLATFORM, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES
          THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE
          OR TRADE PRACTICE. ATURNA IS NOT A LAW FIRM AND IS NOT PROVIDING LEGAL
          ADVICE TO YOU USE THROUGH PROVISION OF THE PLATFORM OR ANY DOCUMENTS
          AVAILABLE IN OR PRODUCED FROM USE OF THE PLATFORM. ATURNA PROVIDES NO
          GUARANTEE THAT THE DOCUMENTS, CONTENT, OR INFORMATION PROVIDED THROUGH
          THE PLATFORM IS CORRECT, COMPLETE, OR UP TO DATE. ATURNA PROVIDES NO
          WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND, THAT
          THE PLATFORM WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
          RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER PLATFORM, APPLICATIONS,
          SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY
          PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY
          ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
        </p>

        <h2>15. Indemnification</h2>
        <p>
          You agree to indemnify and defend Aturna and its trustees, officers,
          directors, staff, employees, agents, licensors, service providers, and
          their respective successors, heirs, and assigns (the{" "}
          <strong>“Indemnified Parties”</strong>), against any liability,
          damage, loss, or expense (including reasonable attorneys' fees and
          expenses of litigation) incurred by or imposed upon the Indemnified
          Parties or any one of them in connection with any third party claims,
          suits, actions, demands, or judgments arising out of any breach of
          warranty or representation by you.
        </p>

        <h2>16. Limitation of Liability</h2>
        <p>TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:</p>
        <ol>
          <li>
            IN NO EVENT WILL ATURNA OR ITS LICENSORS, SERVICE PROVIDERS, OR
            AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY USE,
            INTERRUPTION, DELAY OR INABILITY TO USE THE PLATFORM, LOST REVENUES
            OR PROFITS, DELAYS, INTERRUPTION OR LOSS OF SERVICES, BUSINESS OR
            GOODWILL, LOSS OR CORRUPTION OF DATA, LOSS RESULTING FROM SYSTEM OR
            SYSTEM SERVICE FAILURE, MALFUNCTION OR SHUTDOWN, FAILURE TO
            ACCURATELY TRANSFER, READ OR TRANSMIT INFORMATION, FAILURE TO UPDATE
            OR PROVIDE CORRECT INFORMATION, SYSTEM INCOMPATIBILITY OR PROVISION
            OF INCORRECT COMPATIBILITY INFORMATION OR BREACHES IN SYSTEM
            SECURITY, OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
            SPECIAL OR PUNITIVE DAMAGES, WHETHER ARISING OUT OF OR IN CONNECTION
            WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE)
            OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE
            AND WHETHER OR NOT ATURNA WAS ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.
          </li>
          <li>
            IN NO EVENT WILL ATURNAS' OR ITS LICENSORS', SERVICES PROVIDERS', OR
            AFFILIATES', COLLECTIVE AGGREGATE LIABILITY ARISING OUT OF OR
            RELATED TO YOUR USE OF THE PLATFORM OR THESE TERMS OF USE, WHETHER
            ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE) OR OTHERWISE, EXCEED ONE-HUNDRED DOLLARS ($100).
          </li>
          <li>
            THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY EVEN IF YOUR
            REMEDIES UNDER THESE TERMS OF USE FAIL THEIR ESSENTIAL PURPOSE.
          </li>
        </ol>

        <h2>17. Termination</h2>
        <p>
          We may terminate or modify your access to and use of the Platform, at
          our sole discretion, at any time and without notice to you, for
          example, if you are not complying with these Terms of Use, or if you
          use the Platform in any way that would cause us legal liability or
          disrupt others' use of the Platform. Likewise, you may cancel your
          account at any time. Any obligations or liabilities that accrue prior
          to termination will survive termination.
        </p>

        <h2>18. Changes to Terms of Use</h2>
        <p>
          We reserve the right, at our sole discretion, to change, modify, add,
          or remove any portion of these Terms of Use, in whole or in part, at
          any time. Changes in the Terms of Use will be effective when posted.
          Your continued use of the Platform after any changes to the Terms of
          Use are posted will be considered acceptance of those changes.
        </p>

        <h2>19. General Terms</h2>
        <p>
          These Terms of Use will be governed by the laws of the State of New
          York without regard to its conflict of law's provisions. If a dispute
          arises under these Terms of Use, the parties agree to first attempt to
          resolve such dispute through binding arbitration. Notwithstanding the
          foregoing, any litigation related to these Terms of Use must be
          brought exclusively in the State of New York, with venue in Onondaga
          County, New York, and all parties hereto waive any objection to
          jurisdiction and venue in such courts. These Terms of Use constitute
          the entire and exclusive agreement between Authorized Clients and
          Aturna and supersede and replace any other agreements, terms, and
          conditions. Authorized Attorneys are subject to the terms of Aturna's
          Law Firm User Agreement in addition to these Terms of Use. Aturna's
          failure to enforce a provision of these Terms of Use is not a waiver
          of its right to do so later. If a provision is found unenforceable,
          the remaining provisions of these Terms of Use will remain in full
          effect and an enforceable term will be substituted reflecting our
          intent as closely as possible. You may not assign any of your rights
          in these Terms of Use without our written consent, and any such
          attempt will be null and have no effect. Aturna may freely assign or
          transfer these Terms of Use without restriction. Except as expressly
          set forth in these Terms of Use, the exercise by any party of any of
          its remedies herein will be without prejudice to its other remedies
          under these Terms of Use or otherwise.
        </p>

        <h2>20. Contact Information </h2>
        <p>
          If you have any questions about these Terms of Use, please contact us
          at:
        </p>
        <a href="mailto:support@aturna.legal">support@aturna.legal</a>

        <p>
          GUIDR LLC is located at 6240 Shirley Street, Suite 105, Naples,
          Florida.
        </p>
      </ContentPage>
    </div>
  );
};

export default TermsPage;
