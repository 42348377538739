import React from "react";
import { CSVUploadProps } from "./types";
import { Button } from "@mui/material";

const CSVUpload = ({ label, onClick, disabled }: CSVUploadProps) => {
  return (
    <Button
      variant="contained"
      component="label"
      disableElevation={true}
      color="primary"
      style={{ backgroundColor: "#8200DC" }}
      disabled={disabled}
    >
      {label || "Upload CSV"}
      <input hidden type="file" accept=".csv" onChange={onClick} />
    </Button>
  );
};

export default CSVUpload;
