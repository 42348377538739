import { callGetPartnerDetailsAPI } from "modules/partner/actions";
import useSWR from "swr";

// partner id is only used as key to keep the fetches separate
// request header is filled in from url as usual
const usePartner = (id: string) => {
  const { data, error, mutate } = useSWR(["usePartner", id], () =>
    callGetPartnerDetailsAPI(),
  );

  return {
    partner: data,
    error,
    refetchPartner: mutate,
  };
};

export default usePartner;
