import * as React from "react";
import SimpleHeader from "components/Header/SimpleHeader";
import { reviewAdvisorAnswers } from "modules/product/actions";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "redux/reducer";
import { go } from "modules/navigation/helpers/navigator";

type ProductTailoringScreenHeaderProps = {
  onHome: () => void;
};

const ProductTailoringScreenHeader: React.FC<
  ProductTailoringScreenHeaderProps
> = ({ onHome }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { isAdvisorComplete } = useSelector((state) => state, shallowEqual);

  const handleReviewAdvisorAnswers = () => dispatch(reviewAdvisorAnswers());

  const handleBack = () => {
    const { from } = (location.state || {}) as any;
    from ? go(dispatch, from) : onHome();
  };

  return isAdvisorComplete ? (
    <SimpleHeader
      onLogoClicked={onHome}
      backLabel="Need to change something?"
      onBack={handleReviewAdvisorAnswers}
    />
  ) : (
    <SimpleHeader
      onLogoClicked={onHome}
      backLabel="Changed your mind?"
      onBack={handleBack}
    />
  );
};

export default ProductTailoringScreenHeader;
