import * as React from "react";
import {
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import { SectionState } from "modules/workflow/helpers/getSectionsProgress";
import QuestionnaireSummary from "../components/QuestionnaireSummary";
import { FormProps } from "components/Forms/Form";
import { Step, WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import Party from "modules/parties/types/Party";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { isAtLeast } from "../helpers/lifecycle";
import Addon from "modules/product/types/Addon";
import { PaymentActionType } from "modules/api/types/Payment";
import SummaryDescription from "../components/QuestionnaireSummary/SummaryDescription";
import { SUMMARY_PAGE_ID } from "./pageIDs";

const getSummaryPage = (
  clients: ClientsMap,
  allRelatedParties: Party[],
  summaryPages: QuestionnairePage[],
  questionnairePages: QuestionnairePage[],
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire,
  questionnaireAnswers: AnswersByQuestionnaireType,
  preselectedAddons: Addon[],
  workflowState: WorkflowPayloadState,
  onConfirmSummary: () => Promise<void>,
  onRejectLawyerReview: () => Promise<void>,
  onPreviewDocuments: () => Promise<void>,
  onCancelPreview: () => Promise<void>,
  onTriggerPayment: (paymentType: PaymentActionType) => void,
  isEditable: boolean,
  creator: string,
  creatorName?: string,
  onEditPage?: (pageIdx: number, pageId: string, pageCaption?: string) => void,
) => {
  const { steps } = workflowState;
  const editPageHandler = isEditable ? onEditPage : null;
  const isSummaryConfirmed = isAtLeast(
    steps,
    Step.WaitingForCoupledConfirmSummary,
  );

  return {
    id: SUMMARY_PAGE_ID,
    caption: "Summary",
    description: <SummaryDescription {...{ steps, creator }} />,
    questionnaireId: "",
    isStatic: true,
    sections: [],
    getProgress: (isVisited) => {
      return {
        label: "Summary",
        state: isSummaryConfirmed
          ? SectionState.COMPLETE
          : SectionState.PENDING,
      };
    },
    render: (formProps: FormProps) => (
      <QuestionnaireSummary
        allRelatedParties={allRelatedParties}
        clients={clients}
        isEditable={isEditable}
        onBack={formProps.onBack}
        onCancelPreview={onCancelPreview}
        onConfirmSummary={onConfirmSummary}
        onEditPage={editPageHandler}
        onNext={formProps.onNext}
        onPreviewDocuments={onPreviewDocuments}
        onRejectLawyerReview={onRejectLawyerReview}
        onTriggerPayment={onTriggerPayment}
        preselectedAddons={preselectedAddons}
        questionnaireAnswers={questionnaireAnswers}
        questionnairePages={questionnairePages}
        questionsBySubquestionnaire={questionsBySubquestionnaire}
        summaryPages={summaryPages}
        workflowState={workflowState}
      />
    ),
  } as QuestionnairePage;
};

export default getSummaryPage;
