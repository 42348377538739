import * as helper from "./apiHelper";
import { User } from "modules/auth/User";
import { Method } from "axios";
import { forbiddenError, invalidActionError } from "./actions";
import { Environment, getEnvironment } from "../../utils/environment";
import { ErrorType } from "./types/ErrorResponsePayload";
import store from "redux/store";
import type { RootState } from "redux/reducer";
import { loadUserProfile } from "modules/auth/actions";
import { workflowLifecycleUpdated } from "modules/workflow/actions";
import { hasLifecycleUpdate, LifecycleUpdate } from "./types/WorkflowPayload";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import ApiError from "./types/ApiError";
import { PartnerID } from "modules/parties/types/Partner";
import { v4 as uuidv4 } from "uuid";
import { configureScope } from "@sentry/react";
import isSentryEnabled from "utils/isSentryEnabled";

const MAX_RETRIES = 1;

const {
  REACT_APP_WORKFLOW_SERVICE_ENDPOINT,
  REACT_APP_WORKFLOW_SERVICE_ENDPOINT_STG,
  REACT_APP_WORKFLOW_SERVICE_ENDPOINT_PROD,
} = process.env;

const resolveHost = () => {
  const env = getEnvironment();
  if (Environment.STAGING === env) {
    return REACT_APP_WORKFLOW_SERVICE_ENDPOINT_STG;
  }
  if (Environment.PROD === env) {
    return REACT_APP_WORKFLOW_SERVICE_ENDPOINT_PROD;
  }
  return REACT_APP_WORKFLOW_SERVICE_ENDPOINT;
};

const HOST = resolveHost() || "";

export const API: { [key: string]: (data?: any) => Endpoint } = {
  ADDRESS_LOOKUP: () => ({
    url: `${HOST}/api/address`,
    auth: "kiosk",
  }),
  ANSWERS: ({ workflowId, questionnaireId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/answer/${questionnaireId}`,
    auth: "kiosk",
  }),
  CONTACT_LAWYER: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/support`,
    auth: "kiosk",
  }),
  FINANCIAL_PROFILE: ({ partnerId }: { partnerId: PartnerID }) => ({
    url: `${HOST}/api/finance/${partnerId}`,
    auth: "kiosk",
  }),
  GLOBAL_FINANCIAL_STATUS: () => ({
    url: `${HOST}/api/finance`,
    auth: "kiosk",
  }),
  GLOBAL_FINANCIAL_STATUS_REPORT: () => ({
    url: `${HOST}/api/finance/csv`,
    auth: "kiosk",
  }),
  PAGE_ANSWERS: ({ workflowId, questionnaireId, pageId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/answer/${questionnaireId}/page/${pageId}`,
    auth: "kiosk",
  }),
  PARTIES: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/parties`,
    auth: "kiosk",
  }),
  PARTNER: () => ({
    url: `${HOST}/api/partner`,
    auth: "kiosk",
  }),
  PARTNER_SALES_RECEIPT: () => ({
    url: `${HOST}/api/finance/receipt`,
    auth: "kiosk",
  }),
  PARTNER_ARCHIVE: () => ({
    url: `${HOST}/api/partner/archive`,
    auth: "kiosk",
  }),
  PARTNERS: () => ({
    url: `${HOST}/api/partners`,
    auth: "kiosk",
  }),
  PARTNER_WORKFLOWS: () => ({
    url: `${HOST}/api/partner/workflows`,
    auth: "kiosk",
  }),
  PARTNER_WORKFLOWS_PRIORITISED: () => ({
    url: `${HOST}/api/partner/workflowsPrioritised`,
    auth: "kiosk",
  }),
  PARTNER_WORKFLOWS_REPORT: () => ({
    url: `${HOST}/api/partner/workflows/csv`,
    auth: "kiosk",
  }),
  PARTNER_DEFINITIONS: () => ({
    url: `${HOST}/api/partner/definitions`,
    auth: "kiosk",
  }),
  PARTNER_DEFINITION: ({ definitionId }) => ({
    url: `${HOST}/api/partner/definitions/${definitionId}`,
    auth: "kiosk",
  }),
  PARTNER_INTEGRATIONS: ({ integrationId }) => ({
    url: `${HOST}/api/partner/integrations/${integrationId}`,
    auth: "kiosk",
  }),
  PARTNER_REGISTRATION: () => ({
    url: `${HOST}/api/partner/registration`,
    auth: "kiosk",
  }),
  PARTNER_AUDITS: () => ({
    url: `${HOST}/api/partner/audits`,
    auth: "kiosk",
  }),
  LATEST_PARTNER_AUDIT: () => ({
    url: `${HOST}/api/partner/audit`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD: () => ({
    url: `${HOST}/api/medicaid/lead`,
    auth: "kiosk",
  }),
  MEDICAID_LEADS: () => ({
    url: `${HOST}/api/medicaid/leads`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_ANSWERS: () => ({
    url: `${HOST}/api/medicaid/lead/answers`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_CALCULATION: () => ({
    url: `${HOST}/api/medicaid/lead/calculation`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_CLIENT: () => ({
    url: `${HOST}/api/medicaid/lead/client`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_PAGE: () => ({
    url: `${HOST}/api/medicaid/lead/page`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_PARTNER: () => ({
    url: `${HOST}/api/medicaid/lead/partner`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_PREVIEWS: () => ({
    url: `${HOST}/api/medicaid/lead/previews`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_REVIEW: () => ({
    url: `${HOST}/api/medicaid/lead/review`,
    auth: "kiosk",
  }),
  MEDICAID_LEAD_STATUS: () => ({
    url: `${HOST}/api/medicaid/lead/status`,
    auth: "kiosk",
  }),
  MEDICAID_VARIABLES: () => ({
    url: `${HOST}/api/medicaid/variables`,
    auth: "kiosk",
  }),
  MEDICAID_VARIABLES_CSV: () => ({
    url: `${HOST}/api/medicaid/variables/csv`,
    auth: "kiosk",
  }),
  RENDER_MEDICAID_DOCUMENTS: () => ({
    url: `${HOST}/api/medicaid/lead/render`,
    auth: "kiosk",
  }),
  GET_MEDICAID_LEAD_DOCUMENTS: () => ({
    url: `${HOST}/api/medicaid/lead/documents`,
    auth: "kiosk",
  }),
  PARTNER_REGISTRATION_INSTANCE: ({ registrationId }) => ({
    url: `${HOST}/api/partner/registration/${registrationId}`,
    auth: "kiosk",
  }),
  PARTY: ({ partyId }) => ({
    url: `${HOST}/api/parties/${partyId}`,
    auth: "kiosk",
  }),
  PAYMENT_INSTANCE: ({ paymentId }) => ({
    url: `${HOST}/api/payment/${paymentId}`,
    auth: "kiosk",
  }),
  PAYMENTS: () => ({ url: `${HOST}/api/payment`, auth: "kiosk" }),
  PERMISSIONS: () => ({
    url: `${HOST}/api/partner/roles`,
    auth: "kiosk",
  }),
  DRAFTS: () => ({
    url: `${HOST}/api/partner/drafts`,
    auth: "kiosk",
  }),
  PERMISSION: ({ permissionId, role }) => ({
    url: `${HOST}/api/partner/roles/${permissionId}/${role}`,
    auth: "kiosk",
  }),
  PRODUCTS: () => ({ url: `${HOST}/api/partner/products`, auth: "kiosk" }),
  QUESTIONNAIRE: ({ workflowId, questionnaireId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/questionnaire/${questionnaireId}`,
    auth: "kiosk",
  }),
  RENDER_DOCUMENTS: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/render`,
    auth: "kiosk",
  }),
  RENDER_DOCUMENTS_OF_TYPE: ({ workflowId, documentType }) => ({
    url: `${HOST}/api/workflow/${workflowId}/render/${documentType}`,
    auth: "kiosk",
  }),
  REGISTER: () => ({ url: `${HOST}/api/user/register`, auth: "firebase" }),
  USER_INFO: () => ({ url: `${HOST}/api/user/me`, auth: "firebase" }),
  WORKFLOWS: () => ({ url: `${HOST}/api/workflow`, auth: "kiosk" }),
  WORKFLOW_CLIENT: ({ clientId }) => ({
    url: `${HOST}/api/clients/${clientId}`,
    auth: "kiosk",
  }),
  WORKFLOW_CLIENTS: ({ workflowId, clientType }) => ({
    url: `${HOST}/api/workflow/${workflowId}/clients/${clientType}`,
    auth: "kiosk",
  }),
  WORKFLOW_DOCUMENTS: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/documents`,
    auth: "kiosk",
  }),
  WORKFLOW_DOCUMENTS_WITH_TYPE: ({ workflowId, documentType }) => ({
    url: `${HOST}/api/workflow/${workflowId}/documents/${documentType}`,
    auth: "kiosk",
  }),
  WORKFLOW: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}`,
    auth: "kiosk",
  }),
  WORKFLOW_PARTIES: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/parties`,
    auth: "kiosk",
  }),
  WORKFLOW_PARTY_RELATIONSHIP: ({ workflowId, partyId, clientId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/parties/${partyId}/relationships/${clientId}`,
    auth: "kiosk",
  }),
  WORKFLOW_PRODUCT: ({ workflowId }) => ({
    url: `${HOST}/api/workflow/${workflowId}/product`,
    auth: "kiosk",
  }),
  WORKFLOW_ARCHIVE: () => ({
    url: `${HOST}/api/workflow/archive`,
    auth: "kiosk",
  }),
  WORKFLOW_AUDIT: () => ({
    url: `${HOST}/api/workflow/audit`,
    auth: "kiosk",
  }),
};

export const getTransactionIdHeader = (): Record<string, string> => {
  if (isSentryEnabled()) {
    const transactionId = uuidv4();
    configureScope((scope) =>
      scope.setTag("aturna_transaction_id", transactionId),
    );
    return {
      "Aturna-Transaction-ID": transactionId,
    };
  } else {
    return {};
  }
};

export const getPartnerHeader = (
  partnerId?: string | null,
): Record<string, string> => (partnerId ? { "Kiosk-Partner": partnerId } : {});

export const getAuthHeaderForAPI = (
  auth?: string,
  user?: User | null,
): Record<string, string | undefined> => {
  const partnerId = getFormattedPartnerId();
  const defaultHeaders = getPartnerHeader(partnerId);

  return !auth || !user
    ? defaultHeaders
    : {
        ...defaultHeaders,
        IAM_FIREBASE: user.idToken,
      };
};

export type Endpoint = {
  url: string;
  auth?: string;
};

const sendRequest = async <T>(
  method: Method,
  endpoint: Endpoint,
  queryParams?: Record<string, any>,
  data?: Record<string, any>,
  headers?: Record<string, string>,
  retryCount: number = 0,
): Promise<helper.ApiResponse<T>> => {
  const { user }: RootState = store.getState();
  try {
    const response = await helper.sendRequest(
      method,
      endpoint.url,
      queryParams,
      data,
      {
        ...getTransactionIdHeader(),
        ...getAuthHeaderForAPI(endpoint.auth, user),
        ...headers,
      },
    );

    // Handle any lifecycle updates
    if (hasLifecycleUpdate(response?.data)) {
      store.dispatch(
        workflowLifecycleUpdated(response.data.lifecycle as LifecycleUpdate),
      );
    }

    return response;
  } catch (error) {
    const e: any = error;
    switch (e.response?.status) {
      case 403: {
        if (endpoint.url !== API.USER_INFO().url && retryCount < MAX_RETRIES) {
          await loadUserProfile()(store.dispatch);
          return sendRequest(
            method,
            endpoint,
            queryParams,
            data,
            headers,
            retryCount + 1,
          );
        }
        store.dispatch(forbiddenError({ method, url: endpoint.url }));
        throw new ApiError(
          "Your session has expired. Please, try again.",
          e.response,
        );
      }

      case 400: {
        if (e.response.data.short === ErrorType.ACTION_NOT_ALLOWED) {
          store.dispatch(invalidActionError(e.response.data));
        }
      }
    }
    throw e;
  }
};

export const get = async <T = any>(
  endpoint: Endpoint,
  queryParams?: Record<string, any>,
  headers?: Record<string, string>,
): Promise<helper.ApiResponse<T>> =>
  sendRequest<T>("get", endpoint, queryParams, undefined, headers);

export const post = <T = any>(
  endpoint: Endpoint,
  data?: { [key: string]: any },
  queryParams?: Record<string, any>,
  headers?: { [key: string]: any },
): Promise<helper.ApiResponse<T>> =>
  sendRequest<T>("post", endpoint, queryParams, data, headers);

export const put = <T = any>(
  endpoint: Endpoint,
  data?: { [key: string]: any },
  queryParams?: Record<string, any>,
  headers?: { [key: string]: any },
): Promise<helper.ApiResponse<T>> =>
  sendRequest<T>("put", endpoint, queryParams, data, headers);

export const patch = <T = any>(
  endpoint: Endpoint,
  data?: { [key: string]: any },
  queryParams?: Record<string, any>,
  headers?: { [key: string]: any },
): Promise<helper.ApiResponse<T>> =>
  sendRequest<T>("patch", endpoint, queryParams, data, headers);

export const del = (
  endpoint: Endpoint,
  queryParams?: Record<string, any>,
  headers?: { [key: string]: any },
): Promise<helper.ApiResponse> =>
  sendRequest("delete", endpoint, queryParams, undefined, headers);
