export enum AnimatedLoadingOverlaySize {
  LARGE,
  MEDIUM,
  INLINE,
}

export type AnimatedLoadingOverlayProps = {
  label?: string;
  size: AnimatedLoadingOverlaySize;
};
