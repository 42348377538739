import React from "react";
import moment from "moment";
import type { MedicaidLeadPreviewItemProps } from "./types";
import currencyFormatter from "utils/currencyFormatter";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const MedicaidLeadPreviewItem = ({
  item,
  medicaidLeadId,
  handleShowDetails,
}: MedicaidLeadPreviewItemProps) => (
  <li className={cx("medicaid-lead-preview-item")}>
    <div className={cx("medicaid-lead-preview-item__client-data")}>
      <div>
        <h3>Protectable Assets</h3>
        <span className={cx("medicaid-lead-preview-item__client-data__value")}>
          {currencyFormatter.format(item.protectableAmount)}
        </span>
      </div>
      <div>
        <h3>Marital Status</h3>
        <span className={cx("medicaid-lead-preview-item__client-data__value")}>
          {item.maritalStatus}
        </span>
      </div>
      <div>
        <h3>{item.csAge === undefined ? "Age" : "IS Age"}</h3>
        <span className={cx("medicaid-lead-preview-item__client-data__value")}>
          {item.isAge}
        </span>
      </div>
      <div className={cx("medicaid-lead-preview-item__action")}>
        <TabbedButton label="Review" onClick={handleShowDetails} />
      </div>
    </div>
    <span className={cx("medicaid-lead-preview-item__date")}>
      {moment(item.updated).format("MMMM DD, YYYY")}
    </span>
  </li>
);

export default MedicaidLeadPreviewItem;
