import * as React from "react";
import { FieldArray } from "formik";
import classNames from "classnames/bind";

import getRelationshipToClient from "modules/parties/helpers/getRelationshipToClient";
import {
  GenericParty,
  getFullName,
  getGenericPartyId,
} from "modules/parties/types/Party";
import { InputType } from "components/Forms/Inputs/Input/types";
import Input from "components/Forms/Inputs/Input";
import Client from "modules/parties/types/Client";
import { Share } from "..";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type QuestionShareDistributionSharesItemProps = {
  client?: Client;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  isEqualDistribution?: boolean;
  name: string;
  party: GenericParty;
};

const QuestionShareDistributionSharesItem: React.FunctionComponent<
  QuestionShareDistributionSharesItemProps
> = ({ client, disabled, error, id, isEqualDistribution, name, party }) => {
  const className = cx("question-share-distribution-shares-item", {
    "question-share-distribution-shares-item--disabled": disabled,
    "question-share-distribution-shares-item--invalid": !disabled && error,
  });
  const relationship = getRelationshipToClient(party, client);
  return (
    <li className={className}>
      <div className={styles["question-share-distribution-shares-item__row"]}>
        <div className={styles["question-share-distribution-shares-item__row"]}>
          <div
            className={styles["question-share-distribution-shares-item__col"]}
          >
            <div
              className={
                styles["question-share-distribution-shares-item__name"]
              }
            >
              {getFullName(party)}
            </div>
            {relationship ? (
              <div
                className={
                  styles["question-share-distribution-shares-item__info"]
                }
              >
                <span
                  className={
                    styles["question-share-distribution-shares-item__label"]
                  }
                >
                  Relationship:
                </span>{" "}
                {relationship}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={styles["question-share-distribution-shares-item__input"]}
        >
          {!isEqualDistribution && (
            <Input
              disabled={disabled}
              id={id}
              max={100}
              maxLength={3}
              min={0}
              name={name}
              type={InputType.PERCENTAGE_INTEGER}
            />
          )}
        </div>
      </div>
    </li>
  );
};

type QuestionShareDistributionSharesProps = {
  client?: Client;
  disabled?: boolean;
  error?: string | [];
  id: string;
  isEqualDistribution?: boolean;
  name: string;
  parties: GenericParty[];
  shares: Share[];
};

const QuestionShareDistributionShares: React.FunctionComponent<
  QuestionShareDistributionSharesProps
> = ({
  client,
  disabled,
  error,
  id,
  isEqualDistribution,
  name,
  parties,
  shares,
}) => {
  return shares.length > 0 ? (
    <React.Fragment>
      <FieldArray
        name={name}
        render={() => (
          <ul className={styles["question-share-distribution-shares"]}>
            {shares.map((share, index) => {
              const party = parties.find(
                (party) => getGenericPartyId(party) === share.partyId,
              );
              return (
                party && (
                  <QuestionShareDistributionSharesItem
                    client={client}
                    disabled={disabled}
                    error={
                      error &&
                      ((Array.isArray(error) &&
                        error.find((item, i) => i === index)) ||
                        typeof error === "string")
                        ? true
                        : false
                    }
                    id={index === 0 ? id : undefined}
                    key={getGenericPartyId(party)}
                    name={`${name}.${index}.share`}
                    party={party}
                    isEqualDistribution={isEqualDistribution}
                  />
                )
              );
            })}
          </ul>
        )}
      />
    </React.Fragment>
  ) : (
    <div className={styles["question-share-distribution-shares__empty"]}>
      <p>No beneficiaries selected...</p>
    </div>
  );
};

export default QuestionShareDistributionShares;
