import * as React from "react";

import Party from "modules/parties/types/Party";
import {
  QuestionnairePage,
  QuestionnaireSection,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import { AnswersMap } from "modules/workflow/types/Answer";
import CollapsableCard from "components/CollapsableCard";
import SummarySection from "./SummarySection";

import styles from "./styles.module.scss";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";

type SummaryPageProps = {
  allQuestions: QuestionsMapByQuestionnaire;
  allRelatedParties: Party[];
  answers: AnswersMap;
  clients: ClientsMap;
  onEdit: (() => void) | null;
  page: QuestionnairePage;
};

const renderSection = (
  page: QuestionnairePage,
  section: QuestionnaireSection,
  idx: number,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersMap,
  allParties: Party[],
  clients: ClientsMap | undefined,
) => {
  const pageQuestions = allQuestions[page.questionnaireId];
  const questionIDs = (section.questions || []).flat();
  const sectionQuestions = questionIDs
    .map((questionId) => pageQuestions[questionId])
    .filter((question) => !Boolean(question.skipSummary));
  return sectionQuestions.length ? (
    <SummarySection
      key={[page.questionnaireId, page.id, idx].join("_")}
      sectionQuestions={sectionQuestions}
      allQuestions={allQuestions}
      title={section.caption}
      answers={answers}
      allParties={allParties}
      clients={clients}
    />
  ) : null;
};

const SummaryPage: React.FC<SummaryPageProps> = ({
  allQuestions,
  allRelatedParties,
  answers,
  clients,
  onEdit,
  page,
}) => {
  return page.caption ? (
    <CollapsableCard
      title={page.caption}
      buttonLabel={"Edit"}
      onClick={
        page.onEditSummarySection !== null && onEdit !== null
          ? page.onEditSummarySection || onEdit
          : undefined
      }
    >
      <section className={styles["summary-page"]}>
        {page.renderSummary
          ? page.renderSummary()
          : page.sections.map((section, idx) =>
              renderSection(
                page,
                section,
                idx,
                allQuestions,
                answers,
                allRelatedParties,
                clients,
              ),
            )}
      </section>
    </CollapsableCard>
  ) : null;
};

export default SummaryPage;
