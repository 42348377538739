import * as React from "react";
import classNames from "classnames/bind";
import Workflow from "modules/workflow/types/Workflow";
import ClientDashboardProductsItemStage from "./ClientDashboardProductsItemStage";
import ClientDashboardProductsItemAction from "./ClientDashboardProductsItemAction";
import getProgressInfo from "./getProgressInfo";

import styles from "./styles.module.scss";
import {
  Step,
  WorkflowStatus,
  workflowStepToStatusMap,
} from "modules/api/types/WorkflowPayload";
import Icon, { IconType } from "components/Icons";
import Tooltip from "components/Tooltip";
import { STATES_MAP } from "resources/options/states";
import {
  SECONDS_IN_A_DAY,
  WORKFLOW_EXPIRY_AFTER_DAYS,
  WORKFLOW_EXPIRY_WARNING_AFTER_DAYS,
} from "../../../../../../resources/options/expiry";
const cx = classNames.bind(styles);

type ClientDashboardProductsItemProps = {
  onOpenWorkflow: (workflow: Workflow, pageIdx?: number) => any;
  onProceedToDownload: (workflow: Workflow) => any;
  onViewParties: (workflow: Workflow) => any;
  onViewSummary: (workflow: Workflow) => any;
  userState?: string;
  workflow: Workflow;
};

const getItemState = (currentStep: Step, isQuestionnaireComplete: boolean) => {
  const state = workflowStepToStatusMap[currentStep] || WorkflowStatus.OPEN;
  return isQuestionnaireComplete && state === WorkflowStatus.OPEN
    ? WorkflowStatus.INTERVIEW_FINISHED
    : state;
};

const ClientDashboardProductsItem: React.FunctionComponent<
  ClientDashboardProductsItemProps
> = ({
  onOpenWorkflow,
  onProceedToDownload,
  onViewParties,
  onViewSummary,
  userState,
  workflow,
}) => {
  const handleOpenWorkflow = React.useCallback(
    (pageIdx?: number) => onOpenWorkflow(workflow, pageIdx),
    [onOpenWorkflow, workflow],
  );
  const handleViewParties = React.useCallback(
    () => onViewParties(workflow),
    [onViewParties, workflow],
  );
  const handleViewSummary = React.useCallback(
    () => onViewSummary(workflow),
    [onViewSummary, workflow],
  );
  const handleProceedToDownload = React.useCallback(
    () => onProceedToDownload(workflow),
    [onProceedToDownload, workflow],
  );

  const { progress, maybeUserState, noInterview, onlineProduct } =
    workflow.state;
  const overallProgress = getProgressInfo(progress?.summary);
  const started =
    noInterview || (overallProgress && overallProgress.percent > 0);
  const complete = overallProgress?.percent === 100;
  const itemState = getItemState(workflow.state.steps.current.step, complete);

  const classNames = cx("client-dashboard-products-item", {
    "client-dashboard-products-item--complete": complete,
  });

  const pageProgresses = (progress?.questionnairesProgresses || [])
    .map((q) => q.pageProgresses)
    .flat();
  const currentPage =
    overallProgress?.percent === 100
      ? pageProgresses[pageProgresses.length - 1]
      : pageProgresses.find(
          (page) => page.summary.completed < page.summary.total,
        );
  const currentPageIdx = currentPage ? pageProgresses.indexOf(currentPage) : 0;

  return (
    <div className={classNames} data-tid="workflow-tile">
      <div
        className={cx(
          "client-dashboard-products-item__row",
          "client-dashboard-products-item__header",
        )}
      >
        <div className={styles["client-dashboard-products-item__icon"]}>
          <Icon type={IconType.WILL2} />
        </div>
        <h3 className={styles["client-dashboard-products-item__title"]}>
          <span data-clarity-mask="true">
            {workflow.state.coupledWorkflowId
              ? workflow.state.labelPrefix + " "
              : ""}
          </span>
          {workflow.state.label}
        </h3>
        {maybeUserState ? (
          <div className={styles["client-dashboard-products-item__state"]}>
            <Tooltip
              title={`${
                STATES_MAP[maybeUserState] || maybeUserState
              } jurisdiction`}
            >
              {maybeUserState}
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className={styles["client-dashboard-products-item__row"]}>
        {!noInterview ? (
          <div className={styles["client-dashboard-products-item__col"]}>
            <div
              className={styles["client-dashboard-products-item__col__heading"]}
            >
              {complete ? "Latest action" : "Current section"}
            </div>
            <ClientDashboardProductsItemStage
              currentPage={currentPage?.title || "N/A"}
              complete={complete}
              itemState={itemState}
            />
          </div>
        ) : null}
        {!workflow.state.noInterview ? (
          <>
            <div className={styles["client-dashboard-products-item__col"]}>
              <div
                className={
                  styles["client-dashboard-products-item__col__heading"]
                }
              >
                Questions completed
              </div>
              <div
                className={styles["client-dashboard-products-item__col__value"]}
              >
                {overallProgress === null ? "N/A" : overallProgress.summary}
              </div>
            </div>
            <div className={styles["client-dashboard-products-item__col"]}>
              <div
                className={
                  styles["client-dashboard-products-item__col__heading"]
                }
              >
                Progress
              </div>
              <div
                className={styles["client-dashboard-products-item__col__value"]}
              >
                {overallProgress === null ? "-" : overallProgress.percent}%
              </div>
            </div>
          </>
        ) : null}
      </div>
      <ClientDashboardProductsItemAction
        itemState={itemState}
        started={started}
        pageProgresses={pageProgresses}
        currentPageIdx={currentPageIdx}
        noInterview={noInterview}
        onlineProduct={onlineProduct}
        onOpenWorkflow={handleOpenWorkflow}
        onViewParties={handleViewParties}
        onProceedToDownload={handleProceedToDownload}
        onProceedToSummary={handleViewSummary}
        history={workflow.state.steps.history}
      />
      {new Date(workflow.created).getTime() +
        WORKFLOW_EXPIRY_WARNING_AFTER_DAYS * SECONDS_IN_A_DAY <
      new Date().getTime() ? (
        <>
          <div className={styles["client-dashboard-products-item__expiring"]}>
            If you do not finish your plan within {WORKFLOW_EXPIRY_AFTER_DAYS}{" "}
            days of starting it, your plan will be archived and you will need to
            start over.
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ClientDashboardProductsItem;
