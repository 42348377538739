/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useId } from "react-id-generator";
import { useField, useFormikContext } from "formik";
import classNames from "classnames/bind";
import debounce from "lodash/debounce";
import AddressType from "modules/parties/types/Address";
import {
  clearAddressDetails,
  formatAddressDetails,
  formatExistingAddresses,
  setAddressDetails,
} from "./helpers";
import { Sanitisers } from "components/Forms/Inputs/Input/helpers";
import Autocomplete from "components/Forms/Inputs/Autocomplete";
import Summary from "components/Forms/Inputs/Summary";
import Label from "components/Forms/Label";
import QuestionInput from "../QuestionInput";
import { InputType } from "components/Forms/Inputs/Input/types";
import { QuestionSizes } from "../QuestionWrapper";
import Address from "modules/parties/types/Address";

import styles from "components/Forms/Questions/QuestionWrapper/styles.module.scss";
import QuestionState from "components/Forms/Questions/QuestionState";
import Alert, { AlertType } from "components/Alert";
const cx = classNames.bind(styles);

export const specialAdministrativeAreas: Record<string, string> = {
  LA: "Parish",
  AK: "Borough",
};

export type OptionType = {
  label: string;
  type: string;
  value: string;
};

type QuestionAddressProps = {
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  tabIndex?: number;
  onAddressLookup: (addressId: string) => Promise<AddressType | undefined>;
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<OptionType[] | undefined>;
  supportedStates?: string[];
  availableAddresses?: Address[];
};

const QuestionAddress: React.FunctionComponent<QuestionAddressProps> = (
  props,
) => {
  const [, meta] = useField(props.name);
  const formikContext = useFormikContext();
  const [questionId] = useId(1, "question-");
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<OptionType | undefined>(undefined);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onAddressLookup = useCallback(props.onAddressLookup, []);
  const onAddressSearch = useCallback(props.onAddressSearch, []);

  const availableAddresses = useRef(props.availableAddresses);
  const availableAddressesOptions = useMemo(
    () => formatExistingAddresses(props.availableAddresses),
    [props.availableAddresses],
  );

  const mount = () => {
    formikContext.validateField(props.name);
  };
  useEffect(mount, []);

  useEffect(() => {
    if (!open && meta.error) {
      setOpen(true);
    }
  }, [meta.error, open]);

  const debounceAddressSearch = useMemo(
    () =>
      debounce(
        (
          queryParams: { containerId?: string; searchTerm?: string },
          callback: (results: OptionType[] | undefined) => void,
        ) => {
          return (async () => {
            const response = await onAddressSearch(
              queryParams.containerId,
              queryParams.searchTerm,
            );
            return callback(response);
          })();
        },
        300,
      ),
    [onAddressSearch],
  );

  useEffect(() => {
    if (inputValue || value) {
      let active = true;
      setLoading(true);
      if (value && value.type === "Address") {
        clearAddressDetails(
          formikContext.setFieldValue,
          formikContext.setFieldTouched,
          props.name,
        );
        (async function () {
          const result = await onAddressLookup(value.value);
          if (active) {
            if (result) {
              setAddressDetails(
                formikContext.setFieldValue,
                formikContext.setFieldTouched,
                result,
                props.name,
              );
            }
            setLoading(false);
          }
        })();
      } else if (value && value.type === "Existing") {
        const result = availableAddresses.current?.find(
          ({ id }) => id === value.value,
        );
        result &&
          setAddressDetails(
            formikContext.setFieldValue,
            formikContext.setFieldTouched,
            result,
            props.name,
          );
        setLoading(false);
      } else {
        debounceAddressSearch(
          value && value.value
            ? { containerId: value.value }
            : { searchTerm: inputValue },
          (results: OptionType[] | undefined) => {
            if (active) {
              let newOptions: OptionType[] = [];
              if (value) {
                newOptions = [value];
              }
              if (results) {
                newOptions = [...newOptions, ...results];
              }
              setOptions(newOptions);
              setLoading(false);
            }
          },
        );
      }
      return () => {
        active = false;
      };
    }
    setLoading(false);
  }, [
    debounceAddressSearch,
    formikContext.setFieldTouched,
    formikContext.setFieldValue,
    inputValue,
    onAddressLookup,
    props.name,
    value,
  ]);

  let className = cx("question", "question--large", {
    "question--disabled": props.disabled,
  });

  const clearAddress = useCallback(() => {
    setValue(undefined);
    setOptions([]);
    setInputValue("");
  }, []);

  const adminAreaLabel =
    (meta.value?.state && specialAdministrativeAreas[meta.value.state]) ||
    "County";
  const unsupportedState =
    props.supportedStates &&
    meta.value?.state &&
    !props.supportedStates.includes(meta.value?.state);

  return (
    <React.Fragment>
      <div data-questionid={props.name} className={className}>
        <div className={styles["question__inner"]}>
          {/* <Label disabled={props.disabled} label={open ? "Search for address" : props.label} id={questionId} /> */}
          {/* this fake label is needed to prevent chrome from inferring this is an address field to disable autofill */}
          {/* kept the original above in case we need to revert this */}
          <Label style={{ display: "none" }} label="Location Autocomplete" />
          {unsupportedState ? (
            <Alert
              type={AlertType.WARNING}
              message={`Selected address doesn't match your account's jurisdiction (${props.supportedStates!.join(
                ", ",
              )})`}
            />
          ) : null}
          {!open ? (
            <Summary
              disabled={props.disabled}
              id={questionId}
              label={formatAddressDetails(meta.value)}
              onClick={() => {
                setOpen(true);
                setTimeout(() => {
                  let element = document.getElementById(
                    questionId,
                  ) as HTMLInputElement;
                  element && element.focus();
                }, 0);
              }}
              tabIndex={props.tabIndex}
            />
          ) : (
            <Autocomplete<OptionType>
              disabled={props.disabled}
              disableCloseOnSelect={true}
              filterOptions={(option) => option}
              filterSelectedOptions={true}
              id={questionId}
              inputValue={inputValue}
              // name={props.name}
              loading={loading}
              onClear={clearAddress}
              onInputChange={(event, newInputValue) => {
                newInputValue = newInputValue.replace(
                  Sanitisers.LEADING_WHITESPACE,
                  "",
                );
                if (
                  !newInputValue ||
                  (value && value.label !== newInputValue)
                ) {
                  clearAddress();
                } else {
                  setInputValue(newInputValue);
                }
              }}
              onValueChange={(event, newValue) => {
                setValue(newValue || undefined);
                setOptions(newValue ? [newValue] : []);
                setInputValue(newValue?.label || "");
              }}
              options={
                options.length || inputValue
                  ? options
                  : availableAddressesOptions
              }
              getOptionLabel={(option) => option.label}
              // placeholder={props.placeholder || "Start typing an address to search..."}
              tabIndex={props.tabIndex}
              value={value}
            />
          )}
        </div>
      </div>
      {open && (
        <>
          <QuestionInput
            label="Address Line 1"
            name={`${props.name}.line1`}
            placeholder="Please enter line 1 of the address"
            type={InputType.TEXT}
          />

          <QuestionInput
            label="Address Line 2"
            name={`${props.name}.line2`}
            placeholder="Please enter line 2 of the address"
            type={InputType.TEXT}
          />

          <QuestionInput
            label="City"
            name={`${props.name}.city`}
            placeholder="Please enter the city"
            type={InputType.TEXT}
          />

          <QuestionState
            label="State"
            name={`${props.name}.state`}
            placeholder="Please select the state"
            size={QuestionSizes.MEDIUM}
          />

          <QuestionInput
            label={adminAreaLabel}
            name={`${props.name}.adminAreaName`}
            placeholder={`Please enter the ${adminAreaLabel.toLocaleLowerCase()}`}
            type={InputType.TEXT}
          />

          <QuestionInput
            label="Zip Code"
            name={`${props.name}.zip`}
            placeholder="Please enter the zip code"
            type={InputType.ZIP_CODE}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default QuestionAddress;
