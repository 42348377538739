import { Box, Grid, Paper, Typography } from "@mui/material";
import Icon, { IconType } from "components/Icons";
import React from "react";

import styles from "./styles.module.scss";

import classNames from "classnames/bind";
import Button from "components/Button";
import { ChevronColour } from "components/Button/types";

const cx = classNames.bind(styles);

type ProductOfferingProps = {
  title: string;
  description: string;
  price: string;
  enabled: boolean;
  icon: IconType;
  handleSelect: () => void;
};

const ProductOffering: React.FC<ProductOfferingProps> = ({
  title,
  description,
  price,
  enabled,
  icon,
  handleSelect,
}) => {
  if (!enabled) return null;

  return (
    <Paper className={cx("product-offering")} elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={2} className={cx("product-offering__icon")}>
          <Icon type={icon} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
      </Grid>
      <Typography variant="body1" sx={{ padding: "0.5rem" }}>
        {description}
      </Typography>
      <Box className={cx("product-offering__button-container")}>
        <Button
          label={price}
          chevronRight
          chevronColour={ChevronColour.GREEN}
          onClick={handleSelect}
        />
      </Box>
    </Paper>
  );
};

export default ProductOffering;
