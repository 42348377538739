import { Box } from "@mui/material";
import { ModalControls, ModalType, showModal } from "components/Modal";
import { NotificationType } from "components/Notification";
import TabbedButton from "components/TabbedButton";
import { keyBy } from "lodash";
import logger from "logger";
import { API, post } from "modules/api";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { AnswersMap } from "modules/workflow/types/Answer";
import { Question, QuestionType } from "modules/workflow/types/Questionnaire";
import moment from "moment";
import React from "react";

const QUESTIONNAIRE_ID = "export-report";

type ModalProps = {
  getSubmitHandle?: (submitHandle: () => any) => void;
  controls: ModalControls;
};

const questions: Question[] = [
  {
    id: "report",
    data: {
      descriptions: [{ label: "Report Type" }],
      required: true,
      definition: {
        type: QuestionType.SELECT,
        values: [
          { value: "BasicReport", display: "Basic Report" },
          { value: "ProjectedPaymentsReport", display: "Projected Payments" },
        ],
        limits: { max: 1 },
      },
      defaultValue: "BasicReport",
    },
  },
  {
    id: "from",
    data: {
      definition: { type: QuestionType.DATE },
      descriptions: [{ label: "From" }],
    },
  },
  {
    id: "to",
    data: {
      definition: { type: QuestionType.DATE },
      descriptions: [{ label: "To" }],
    },
  },
];

const CsvExport: React.FC = () => {
  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleFormSubmit = async () => {
    if (!submitHandle) return undefined;
    await submitHandle();
  };

  const handleOpen = () => {
    showModal({
      type: ModalType.CONFIRM,
      cancellable: true,
      labelCancel: "Close",
      title: "Export Report",
      onConfirm: handleFormSubmit,
      labelConfirm: "Download",
      renderContent: (controls: ModalControls) => (
        <CsvExportModalContent
          getSubmitHandle={getSubmitHandle}
          controls={controls}
        />
      ),
    });
  };

  return (
    <Box
      mb={2}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "-38px",
      }}
    >
      <TabbedButton onClick={handleOpen}>Export Report</TabbedButton>
    </Box>
  );
};

const CsvExportModalContent: React.FC<ModalProps> = ({
  getSubmitHandle,
  controls,
}) => {
  const handleDownload = async (_: any, values: AnswersMap) => {
    controls.busy(true);

    const { report, from, to } = values;

    const payload = {
      report,
    } as AnswersMap;
    if (from)
      payload.from = moment(from as string, "YYYY-MM-DD", true)
        .startOf("day")
        .utc()
        .format();
    if (to)
      payload.to = moment(to as string, "YYYY-MM-DD", true)
        .endOf("day")
        .utc()
        .format();

    try {
      const response = await post(API.PARTNER_WORKFLOWS_REPORT(), payload);
      let csvContent = atob(response.data.csvFile);
      var blob = new Blob([csvContent], {
        type: "data:application/octet-stream;base64",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `export-${report}.csv`;
      document.body.append(link);
      link.click();
      link.remove();

      controls.close();
      return response.data;
    } catch (error) {
      controls.busy(false);
      logger.notify(NotificationType.ERROR, "CSV download failed", error);
    }
  };

  return (
    <QuestionnaireForm
      getSubmitHandle={getSubmitHandle}
      onSavePageAnswers={handleDownload}
      customFormProps={{ showSaveAndExitButton: false }}
      questionsBySubquestionnaire={{
        [QUESTIONNAIRE_ID]: keyBy(questions, "id"),
      }}
      questionnairePages={[
        {
          id: "default_page",
          questionnaireId: QUESTIONNAIRE_ID,
          caption: "",
          sections: [
            {
              caption: "",
              questions: questions.map((question) => question.id),
            },
          ],
        },
      ]}
    />
  );
};

export default CsvExport;
