import * as React from "react";
import classNames from "classnames/bind";

import Party from "modules/parties/types/Party";
import Client from "modules/parties/types/Client";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import RelationshipRepeaterItem from "./RelationshipRepeaterItem";

import styles from "./styles.module.scss";
import { IconType } from "components/Icons";
const cx = classNames.bind(styles);

const renderParty = (
  isEditable: boolean,
  onEdit: (party: Party) => any,
  onRemove: (party: Party) => any,
  party: Party,
  client?: Client,
  detailed?: boolean,
  parentActionBtn?: {
    label: string;
    onClick: (party: Party) => any;
  },
) =>
  party ? (
    <RelationshipRepeaterItem
      key={party.partyId}
      party={party}
      client={client}
      isEditable={isEditable}
      renderChildren={detailed}
      parentActionBtn={parentActionBtn}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  ) : null;

export type PartyActionButton = {
  label: string;
  buttonType?: TabbedButtonType;
  onClick: () => any;
};

type RelationshipRepeaterProps = {
  addPartyLabel?: string;
  client?: Client;
  isEditable: boolean;
  detailed?: boolean;
  parties: Party[];
  onAddParty?: () => Promise<void>;
  onEditParty: (party: Party) => any;
  onRemoveParty: (party: Party) => any;
  renderActionBtn?: (party: Party) => PartyActionButton | undefined;
  customActionBtns?: PartyActionButton[];
};

const RelationshipRepeater: React.FunctionComponent<
  RelationshipRepeaterProps
> = ({
  addPartyLabel = "New Person",
  client,
  isEditable,
  customActionBtns,
  detailed,
  renderActionBtn,
  parties,
  onAddParty,
  onEditParty,
  onRemoveParty,
}) => {
  let className = cx("relationship-repeater", {
    "relationship-repeater--empty": parties.length === 0,
  });
  return (
    <fieldset className={className}>
      {parties.length === 0 ? (
        <p className={styles["relationship-repeater__empty"]}>
          You haven’t added anyone yet
        </p>
      ) : (
        <ul className={styles["relationship-repeater__list"]}>
          {parties.map((party) =>
            renderParty(
              isEditable,
              onEditParty,
              onRemoveParty,
              party,
              client,
              detailed,
              renderActionBtn && renderActionBtn(party),
            ),
          )}
        </ul>
      )}
      <div className={styles["relationship-repeater__actions"]}>
        {onAddParty && (
          <TabbedButton
            label={addPartyLabel}
            icon={IconType.PLUS}
            mood={TabbedButtonMood.POSITIVE}
            onClick={onAddParty}
            style={TabbedButtonType.PRIMARY}
          />
        )}
        {customActionBtns &&
          customActionBtns.map((buttonProps, idx) => (
            <TabbedButton
              key={idx}
              label={buttonProps.label}
              mood={TabbedButtonMood.POSITIVE}
              onClick={buttonProps.onClick}
              style={buttonProps.buttonType || TabbedButtonType.SECONDARY}
            />
          ))}
      </div>
    </fieldset>
  );
};

export default RelationshipRepeater;
