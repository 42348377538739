import { PartnerID } from "modules/parties/types/Partner";

export enum RenderedSalesReceiptStatus {
  FAILED = "Failed",
  FINISHED = "Finished",
  PENDING = "Pending",
}

export type RenderedSalesReceiptId = string;

export type RenderedSalesReceipt = {
  renderedSalesReceiptId: RenderedSalesReceiptId;
  partnerId: PartnerID;
  month: string;
  status: RenderedSalesReceiptStatus;
  fileName: string;
  bucket: string;
  created: string;
  updated: string;
};

export type DownloadRenderedSalesReceiptProps = {
  partnerId: PartnerID;
  from: string;
  to: string;
  setIsDownloadingReceipt: React.Dispatch<React.SetStateAction<boolean>>;
};
