import * as React from "react";
import { showModalForm, ModalControls } from "components/Modal";
import {
  formValuesToModifiedParty,
  partyToFormValues,
  relatedPartyFormSections,
  relatedPartyQuestions,
  RELATED_PARTY_QUESTIONNAIRE_ID,
} from "./relatedPartyQuestionnaireDescription";
import {
  childFormSections,
  childQuestions,
  childToFormValues,
  formValuesToChild,
  grandchildFormSections,
  grandchildQuestions,
  formValuesToGrandchild,
  grandchildToFormValues,
  CHILD_QUESTIONNAIRE_ID,
  GRANDCHILD_QUESTIONNAIRE_ID,
} from "./childQuestionnaireDescription";
import Client from "modules/parties/types/Client";
import Party, { PartyToSave } from "modules/parties/types/Party";
import PartyDetailsFormContent from "./PartyDetailsFormContent";
import {
  Question,
  QuestionID,
  QuestionnaireSection,
} from "modules/workflow/types/Questionnaire";
import { processAddressQuestions } from "modules/workflow/helpers/processQuestions";
import Address from "modules/parties/types/Address";
import { AddressSearchResultsType } from "modules/address/actions";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";

function showPartyDetailsModal<T>(
  title: string,
  clients: ClientsMap | undefined,
  onSaveParty: (party: Partial<T>) => Promise<Party | undefined>,
  party: Party | undefined,
  formSections: QuestionnaireSection[],
  questionnaireId: string,
  questions: Question[],
  formValuesToItem: (values: Record<QuestionID, any>) => Partial<T>,
  itemToFormValues: (client: Client, item: Party) => AnswersMap,
  onCancel?: () => void,
  formAnswers?: AnswersByQuestionnaireType,
) {
  let modalControls: ModalControls;
  const getModalControls = (controls: ModalControls) => {
    modalControls = controls;
  };

  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleSave = async (party: Partial<T>) => {
    modalControls?.busy(true);
    try {
      const result = await onSaveParty(party);
      if (result) {
        modalControls?.close();
      } else {
        modalControls?.busy(false);
      }
    } catch (e) {
      modalControls?.busy(false);
    }
  };

  const handleFormSubmit = (): Promise<void> =>
    submitHandle ? submitHandle() : Promise.resolve();

  const handleFormCancel = () => {
    modalControls?.close();
    onCancel && onCancel();
  };

  showModalForm({
    getModalControls: getModalControls,
    onCancel: handleFormCancel,
    onSubmit: handleFormSubmit,
    renderContent: (modalControls) => (
      <PartyDetailsFormContent
        clients={clients}
        formAnswers={formAnswers}
        formSections={formSections}
        formValuesToParty={formValuesToItem}
        getSubmitHandle={getSubmitHandle}
        onSaveParty={handleSave}
        party={party}
        partyToFormValues={itemToFormValues}
        questions={questions}
        questionnaireId={questionnaireId}
        onChange={() => modalControls.setDirty(true)}
      />
    ),
    title: title,
  });
}

export const showRelatedPartyDetailsModal = (
  title: string,
  clients: ClientsMap | undefined,
  onSaveParty: (party: Partial<PartyToSave>) => Promise<Party | undefined>,
  party: Party | undefined,
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  onCancel?: () => void,
) =>
  showPartyDetailsModal<PartyToSave>(
    title,
    clients,
    onSaveParty,
    party,
    relatedPartyFormSections,
    RELATED_PARTY_QUESTIONNAIRE_ID,
    processAddressQuestions(
      relatedPartyQuestions,
      onAddressLookup,
      onAddressSearch,
      availableAddresses,
    ),
    formValuesToModifiedParty,
    partyToFormValues,
    onCancel,
  );

export const showChildDetailsModal = (
  title: string,
  clients: ClientsMap | undefined,
  onSaveChild: (party: Partial<PartyToSave>) => Promise<Party | undefined>,
  child: Party | undefined,
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  onCancel?: () => void,
  formAnswers?: AnswersByQuestionnaireType,
) => {
  showPartyDetailsModal<PartyToSave>(
    title,
    clients,
    onSaveChild,
    child,
    childFormSections,
    CHILD_QUESTIONNAIRE_ID,
    processAddressQuestions(
      childQuestions,
      onAddressLookup,
      onAddressSearch,
      availableAddresses,
    ),
    formValuesToChild,
    childToFormValues,
    onCancel,
    formAnswers,
  );
};

export const showGrandchildDetailsModal = (
  title: string,
  clients: ClientsMap | undefined,
  onSaveGrandchild: (party: Partial<Party>) => Promise<Party | undefined>,
  child: Party | undefined,
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  onCancel?: () => void,
) =>
  showPartyDetailsModal<Party>(
    title,
    clients,
    onSaveGrandchild,
    child,
    grandchildFormSections,
    GRANDCHILD_QUESTIONNAIRE_ID,
    processAddressQuestions(
      grandchildQuestions,
      onAddressLookup,
      onAddressSearch,
      availableAddresses,
    ),
    formValuesToGrandchild,
    grandchildToFormValues,
    onCancel,
  );
