import { FormSubmitHandle } from "components/Forms/Form";
import { showModalConfirm } from "components/Modal";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { Step } from "modules/api/types/WorkflowPayload";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import {
  acceptEngagementAgreement,
  rejectEngagementAgreement,
  acceptLawyerReview,
  finishLawyerReview,
  reinitiateLawyerReview,
} from "modules/workflow/review/actions";
import { useCallback } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { RootState, useSelector } from "redux/reducer";
import { useWorkflowDetails } from "screens/workflowDetails/context";
import { openWorkflow } from "../actions";
import hasSkippedPayment from "../helpers/hasSkippedPayment";

const useWorkflowActions = () => {
  const dispatch = useDispatch();
  const { asUser, isAdmin, coupledWorkflow, requestedPageIdx, workflow } =
    useWorkflowDetails();
  const partnerId = getFormattedPartnerId() || undefined;
  const { user } = useSelector((state: RootState) => state, shallowEqual);

  const handleAcceptEngagementAgreement = useCallback(
    () =>
      showModalConfirm({
        text: "Did you perform a conflicts check, and do you wish to ACCEPT the engagement?",
        title: "Accept Engagement Agreement",
        onConfirm: async () => {
          isAdmin &&
            workflow.state.steps.current.step ===
              Step.AwaitingEngagementCheck &&
            (await acceptEngagementAgreement(
              workflow.id,
              asUser,
              hasSkippedPayment(workflow.state.steps),
            )(dispatch));
        },
      }),
    [dispatch, asUser, isAdmin, workflow],
  );

  const handleRejectEngagementAgreement = useCallback(
    () =>
      showModalConfirm({
        text: "Did you perform a conflicts check, and do you wish to REJECT the engagement?",
        title: "Reject Engagement Agreement",
        onConfirm: async () => {
          isAdmin &&
            workflow.state.steps.current.step ===
              Step.AwaitingEngagementCheck &&
            (await rejectEngagementAgreement(workflow.id, asUser)(dispatch));
        },
      }),
    [dispatch, asUser, isAdmin, workflow],
  );

  const handleFinishLawyerReview = useCallback(
    () =>
      showModalConfirm({
        text: "Any changes you made will be sent back to the client for their review. You won't be able to make any corrections in the meantime. Do you wish to proceed?",
        title: "Finish review",
        onConfirm: async () => {
          isAdmin &&
            workflow.state.steps.current.step === Step.ReviewInProgress &&
            (await finishLawyerReview(workflow.id, asUser)(dispatch));
        },
      }),
    [dispatch, asUser, isAdmin, workflow],
  );

  const handleAcceptLawyerReview = useCallback(
    () =>
      showModalConfirm({
        text: "Are you happy with the corrections from the lawyer and want to finalize your documents?",
        title: "Accept lawyer review",
        onConfirm: async () => {
          try {
            user &&
              workflow.state.steps.current.step ===
                Step.AwaitingCustomerConfirmReview &&
              (await acceptLawyerReview(workflow.id, asUser)(dispatch));
          } catch (e) {
            logger.notify(
              NotificationType.ERROR,
              "We were unable to process your request. Please, try again later.",
            );
          }
          return;
        },
      }),
    [asUser, user, dispatch, workflow],
  );

  const handleReinitiateReview = useCallback(
    () =>
      showModalConfirm({
        text: "Are you sure you want to initiate the review again?",
        title: "Reinitiate lawyer review",
        onConfirm: async () => {
          user && (await reinitiateLawyerReview(workflow.id, asUser)(dispatch));
          return;
        },
      }),
    [asUser, dispatch, user, workflow],
  );

  const handleNavigationRequest = useCallback(
    async (
      saveDelta: boolean,
      submitHandle: FormSubmitHandle | undefined,
      successCallback: () => any,
    ) => {
      try {
        if (submitHandle) await submitHandle(saveDelta);
        successCallback();
      } catch (e) {}
    },
    [],
  );

  const handleSwitchToSpouse = useCallback(
    (submitHandle?: FormSubmitHandle) =>
      coupledWorkflow &&
      handleNavigationRequest(true, submitHandle, () =>
        openWorkflow(
          user,
          partnerId,
          coupledWorkflow,
          requestedPageIdx,
        )(dispatch),
      ),
    [
      handleNavigationRequest,
      coupledWorkflow,
      dispatch,
      partnerId,
      requestedPageIdx,
      user,
    ],
  );

  return {
    handleAcceptEngagementAgreement,
    handleRejectEngagementAgreement,
    handleAcceptLawyerReview,
    handleFinishLawyerReview,
    handleNavigationRequest,
    handleReinitiateReview,
    handleSwitchToSpouse,
  };
};

export default useWorkflowActions;
