import { createAction, Dispatch } from "@reduxjs/toolkit";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { ErrorType } from "modules/api/types/ErrorResponsePayload";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import {
  RenderedDocumentStatus,
  WorkflowDocumentDetails,
  WorkflowDocumentType,
} from "modules/api/types/WorkflowDetailsPayload";
import { callWorkflowDetailsAPI } from "../actions";
import { callFetchRenderedDocumentsAPI, callRenderDocumentsAPI } from "./api";
import isBadRequestError from "utils/isBadRequestError";

////////////////////////////////////////////////////////////////////
// Update workflow documents
////////////////////////////////////////////////////////////////////

const RENDER_POLLING_TIMEOUT_MS = 2000;
let loadCancelled = false;

export const awaitRenderCompletion =
  (workflowId: string, asUser?: string) =>
  async (dispatch: Dispatch): Promise<WorkflowDocumentDetails[]> => {
    // TODO replace with callWorkflowDocumentsAPI when implemented
    const workflow = await callWorkflowDetailsAPI(workflowId, asUser);
    const documents = workflow?.documents || [];

    // Update current workflow
    if (workflow) {
      dispatch(renderedDocumentsLoaded({ workflowId, documents }));
    }

    // Poll
    const hasPendingDocument = Boolean(
      documents.find((doc) => doc.status === RenderedDocumentStatus.PENDING),
    );
    if (!workflow || hasPendingDocument) {
      await new Promise((resolve) =>
        setTimeout(resolve, RENDER_POLLING_TIMEOUT_MS),
      ); // sleep
      return loadCancelled
        ? []
        : awaitRenderCompletion(workflowId, asUser)(dispatch);
    }

    return workflow.documents || [];
  };

////////////////////////////////////////////////////////////////////
// Load documents
////////////////////////////////////////////////////////////////////

const getRenderedWorkflowDocuments =
  (
    workflowId: string,
    ownerId?: string,
    documentType?: WorkflowDocumentType,
    forceRerender?: boolean,
  ) =>
  async (dispatch: Dispatch): Promise<WorkflowDocumentDetails[]> => {
    if (forceRerender) {
      await renderDocuments(workflowId, documentType, ownerId)(dispatch);
      return getRenderedWorkflowDocuments(
        workflowId,
        ownerId,
        documentType,
        false,
      )(dispatch);
    }

    const renderedDocuments = await awaitRenderCompletion(
      workflowId,
      ownerId,
    )(dispatch);
    return documentType
      ? renderedDocuments.filter((doc) => doc.documentType === documentType)
      : renderedDocuments;
  };

export type LoadDocumentsActionPayload = {
  workflowId: string;
  ownerId?: string;
  documentType?: WorkflowDocumentType;
};
export const loadDocumentsStarted = createAction<LoadDocumentsActionPayload>(
  "LOAD_DOCUMENTS_STARTED",
);
export const loadDocumentsCancelled = createAction("LOAD_DOCUMENTS_CANCELLED");
export type LoadDocumentsFailedActionPayload = {
  workflowId: string;
  documentType?: WorkflowDocumentType;
  documents?: WorkflowDocumentDetails[];
  error: any;
};
export const loadDocumentsFailed =
  createAction<LoadDocumentsFailedActionPayload>("LOAD_DOCUMENTS_FAILED");

export const cancelDocumentsLoading = () => {
  loadCancelled = true;
};

export const loadDocuments =
  (workflowId: string, ownerId?: string, documentType?: WorkflowDocumentType) =>
  async (dispatch: Dispatch) => {
    const triggerRender = true;
    loadCancelled = false;
    dispatch(loadDocumentsStarted({ workflowId, ownerId, documentType }));

    try {
      const documents = await getRenderedWorkflowDocuments(
        workflowId,
        ownerId,
        documentType,
        triggerRender,
      )(dispatch);
      if (loadCancelled) return dispatch(loadDocumentsCancelled());

      const failedDocuments = documents.filter(
        (doc) => doc.status === RenderedDocumentStatus.FAILURE,
      );
      if (failedDocuments.length) {
        logger.notify(
          NotificationType.ERROR,
          "Some documents couldn't be rendered. Please, try again later.",
          failedDocuments,
        );
        return dispatch(
          loadDocumentsFailed({
            workflowId: workflowId,
            documentType,
            error: `Some documents failed to render: ${failedDocuments
              .map((doc) => doc.id)
              .join(", ")}`,
            documents,
          }),
        );
      }

      return loadDocumentsDownloadDetails(
        workflowId,
        documentType,
        ownerId,
        true,
      )(dispatch);
    } catch (e) {
      if (isBadRequestError(e)) {
        if (
          e.response?.data.short ===
          ErrorType.MEDICAID_PARTNER_DEFAULTS_NOT_FOUND
        ) {
          logger.notify(
            NotificationType.ERROR,
            "The law firm has not set up their Asset Protection Analysis defaults. Please try again later.",
          );
        } else if (
          e.response?.data.short === ErrorType.MEDICAID_VARIABLES_NOT_FOUND
        ) {
          logger.notify(
            NotificationType.ERROR,
            "We have not not set up the medicaid variables for your state. Please try again later.",
          );
        }
      } else {
        logger.notify(
          NotificationType.ERROR,
          "Your documents are not available at the moment. Please, try again later.",
          e,
        );
      }
      dispatch(
        loadDocumentsFailed({ workflowId: workflowId, documentType, error: e }),
      );
    }
  };

////////////////////////////////////////////////////////////////////
// Render documents
////////////////////////////////////////////////////////////////////

export type RenderedDocumentLoadedPayload = {
  workflowId: string;
  documents: WorkflowDocumentDetails[] | undefined;
};

export const renderDocumentsStarted = createAction<{
  workflowId: string;
  ownerId?: string;
  documentType?: WorkflowDocumentType;
}>("RENDER_DOCUMENT_STARTED");
export const renderedDocumentsLoaded =
  createAction<RenderedDocumentLoadedPayload>("RENDERED_DOCUMENTS_LOADED");
export const renderDocumentsFailed = createAction<any>(
  "RENDER_DOCUMENT_FAILED",
);
export const renderDocumentMissingAnswers = createAction<object>(
  "RENDER_DOCUMENT_MISSING_ANSWERS",
);

const renderDocuments =
  (workflowId: string, documentType?: WorkflowDocumentType, asUser?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(
      renderDocumentsStarted({ workflowId, ownerId: asUser, documentType }),
    );
    try {
      const { documents } = await callRenderDocumentsAPI(
        workflowId,
        documentType,
        asUser,
      );

      dispatch(renderedDocumentsLoaded({ workflowId, documents }));
    } catch (e) {
      if (isBadRequestError(e) && e.response?.status === 400) {
        if (e.response.data.short === ErrorType.ACTION_NOT_ALLOWED) {
          dispatch(renderDocumentsFailed(e));
        } else {
          dispatch(
            renderDocumentMissingAnswers(e.response.data?.additionalData || {}),
          );
        }
      } else {
        dispatch(renderDocumentsFailed(e));
        logger.notify(
          NotificationType.ERROR,
          "Your documents can't be rendered at the moment. Please, try again later",
          e,
        );
      }
      throw e;
    }
  };

////////////////////////////////////////////////////////////////////
// Load download details
////////////////////////////////////////////////////////////////////

export type LoadDownloadDetailsPayload = {
  workflowId: string;
  documentType?: WorkflowDocumentType;
  asUser?: string;
  downloadDetails: DownloadableDocumentDetails[];
};
export const loadDocumentsDownloadDetailsStarted = createAction<{
  workflowId: string;
  documentType?: WorkflowDocumentType;
}>("LOAD_DOCUMENTS_DOWNLOAD_DETAILS_STARTED");
export const documentsDownloadDetailsLoaded =
  createAction<LoadDownloadDetailsPayload>("DOCUMENTS_DOWNLOAD_DETAILS_LOADED");
export const loadDocumentsDownloadDetailsFailed = createAction<{
  workflowId: string;
  documentType?: WorkflowDocumentType;
  error: any;
}>("LOAD_DOCUMENTS_DOWNLOAD_DETAILS_FAILED");

export const loadDocumentsDownloadDetails =
  (
    workflowId: string,
    documentType?: WorkflowDocumentType,
    asUser?: string,
    reusePollingSession?: boolean,
  ) =>
  async (
    dispatch: Dispatch,
  ): Promise<DownloadableDocumentDetails[] | undefined> => {
    if (!reusePollingSession) {
      loadCancelled = false;
    }

    try {
      dispatch(
        loadDocumentsDownloadDetailsStarted({ workflowId, documentType }),
      );
      const downloadDetails = await callFetchRenderedDocumentsAPI(
        workflowId,
        documentType,
        asUser,
      );

      if (loadCancelled) {
        dispatch(loadDocumentsCancelled());
        return;
      }

      dispatch(
        documentsDownloadDetailsLoaded({
          workflowId,
          downloadDetails,
          documentType,
          asUser,
        }),
      );
      return downloadDetails;
    } catch (e) {
      dispatch(
        loadDocumentsDownloadDetailsFailed({
          workflowId,
          documentType,
          error: e,
        }),
      );
      return [];
    }
  };
