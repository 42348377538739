import React, { useContext, useEffect } from "react";
import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import { fetchMedicaidLeads } from "modules/medicaid/actions";
import { useDispatch } from "react-redux";
import PageHeader from "components/PageHeader";
import CollapsableCard from "components/CollapsableCard";
import PaginatedList from "components/PaginatedList";
import { EnrichedMedicaidLead } from "modules/medicaid/types";
import ConsumerMedicaidLeadItem from "./ConsumerMedicaidLeadItem";
import { useSelector } from "redux/reducer";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { IconType } from "components/Icons";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const cx = classNames.bind(styles);

const ConsumerMedicaidLeads = () => {
  const dispatch = useDispatch();
  const { availableLeads } = useContext(MedicaidLeadContext);

  const userDisplayName = useSelector((state) => state.user?.displayName);

  useEffect(() => {
    fetchMedicaidLeads(dispatch);
  }, [dispatch]);

  const handleCreateMedicaidLead = () =>
    go(dispatch, getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD));

  if (availableLeads === undefined) {
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const enrichedMedicaidLeadsArray: EnrichedMedicaidLead[] =
    Object.values(availableLeads);

  const time = new Date().getHours();
  const message =
    time < 12 ? "Good morning" : time < 18 ? "Good afternoon" : "Good evening";

  const fullMessage = [message, userDisplayName].filter(Boolean).join(", ");

  return (
    <>
      <PageHeader title={fullMessage} />
      <CollapsableCard title="Your Medicaid Workflows">
        <div className={cx("consumer-medicaid-leads-actions")}>
          <TabbedButton
            icon={IconType.PLUS}
            label="Add a new medicaid workflow"
            style={TabbedButtonType.SECONDARY}
            onClick={handleCreateMedicaidLead}
          />
        </div>

        <PaginatedList<EnrichedMedicaidLead>
          items={enrichedMedicaidLeadsArray}
          pageSize={3}
          renderItem={(item) => (
            <ConsumerMedicaidLeadItem
              item={item}
              key={item.medicaidLead.medicaidLeadId}
            />
          )}
        ></PaginatedList>
      </CollapsableCard>
    </>
  );
};

export default ConsumerMedicaidLeads;
