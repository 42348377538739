import LinkList, { LinkListItem } from "components/linkList/LinkList";
import {
  DefinitionDataAddon,
  DefinitionDataPlan,
  DefinitionDataProduct,
  KioskProductDescription,
} from "modules/api/responseTypes/DefinitionResponseType";
import { go } from "modules/navigation/helpers/navigator";
import Addon from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";
import React from "react";
import { useDispatch } from "react-redux";

type Props = {
  keys: string[];
  definitions: Record<
    string,
    DefinitionDataProduct | DefinitionDataPlan | DefinitionDataAddon
  >;
  descriptions: Record<string, Addon | Plan | KioskProductDescription>;
  categoryPath: string;
  toggleItem?: (key: string) => void;
};

const isToggleable = (
  def: any,
): def is DefinitionDataProduct | DefinitionDataPlan | DefinitionDataAddon =>
  def.hasOwnProperty("enabled");

const isProductOrPlanDefinition = (
  def: any,
): def is DefinitionDataProduct | DefinitionDataPlan =>
  def.hasOwnProperty("price");

const ConfigList: React.FC<Props> = ({
  keys,
  definitions,
  descriptions,
  categoryPath,
  toggleItem,
}) => {
  const dispatch = useDispatch();

  const renderItem = (
    key: string,
    definition:
      | DefinitionDataProduct
      | DefinitionDataPlan
      | DefinitionDataAddon,
  ) => {
    return (
      <LinkListItem
        key={key}
        active={isToggleable(definition) ? definition.enabled : undefined}
        title={descriptions[key]?.name}
        subtitle={descriptions[key]?.description}
        onClick={() => go(dispatch, `${categoryPath}/${key}`)}
        toggleItem={
          isToggleable(definition) ? toggleItem?.bind(null, key) : undefined
        }
        clickable={isProductOrPlanDefinition(definition)}
      />
    );
  };

  const nonExternalKeys = keys.filter((k) => {
    const d = descriptions[k];
    return !(d && "external" in d && d.external);
  });

  return (
    <LinkList>
      {nonExternalKeys.map((key) => renderItem(key, definitions[key]))}
    </LinkList>
  );
};

export default ConfigList;
