import * as React from "react";

import InputNumber, {
  InputNumberProps,
} from "components/Forms/Inputs/Input/Number";
import InputString, {
  InputStringProps,
} from "components/Forms/Inputs/Input/String";
import { InputType } from "./types";

type InputProps = {
  disabled?: boolean;
  id?: string;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  name: string;
  placeholder?: string;
  tabIndex?: number;
  type: InputType;
  prefix?: string;
  explainer?: string;
};

const Input: React.FunctionComponent<InputProps> = (props) => {
  switch (props.type) {
    case InputType.CURRENCY:
    case InputType.CURRENCY_ALLOW_NEGATIVE:
    case InputType.DECIMAL:
    case InputType.DECIMAL_ALLOW_NEGATIVE:
    case InputType.INTEGER:
    case InputType.INTEGER_ALLOW_NEGATIVE:
    case InputType.NUMBER:
    case InputType.PERCENTAGE:
    case InputType.PERCENTAGE_INTEGER:
      return <InputNumber {...(props as InputNumberProps)} />;
    default:
      return <InputString {...(props as InputStringProps)} />;
  }
};
export default Input;
