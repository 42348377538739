import React from "react";
import classNames from "classnames/bind";
import currencyFormatter from "utils/currencyFormatter";

import styles from "./styles.module.scss";
import Icon, { IconType } from "components/Icons";
import Tooltip from "components/Tooltip";
import getPercentage from "utils/getPercentage";
import { mapValues } from "lodash";
const cx = classNames.bind(styles);

type Props = {
  className?: string;
  incompleteStyle?: "error" | "warning";
  protectedAmount: number;
  totalAmount: number;
  title?: string;
  progressTransition?: boolean;
};

const Bar: React.FC<{ boundPercentage: number }> = ({ boundPercentage }) => {
  return (
    <div
      className={cx("Bar", {
        "Bar--empty": boundPercentage === 0,
      })}
    >
      <div
        style={{
          width: `${boundPercentage}%`,
        }}
        className={cx("Bar__Fill")}
      />
      <div className={cx("Bar__Handle")}>
        <div />
      </div>
    </div>
  );
};

const SliderPartitionLabels: React.FC<{ lower: number; upper: number }> = ({
  lower,
  upper,
}) => {
  return (
    <div className={cx("Slider__Extremes")}>
      <div
        className={cx("Slider__Extremes__Min", {
          empty: lower === 0,
        })}
      >
        {currencyFormatter.format(lower)}
      </div>

      <div
        className={cx("Slider__Extremes__Max", {
          empty: upper === 0,
        })}
      >
        {currencyFormatter.format(upper)}
      </div>
    </div>
  );
};

const ProtectedAssetsSlider = ({
  className,
  incompleteStyle = "error",
  protectedAmount,
  title,
  totalAmount,
  progressTransition,
}: Props) => {
  const boundPercentage = mapValues(
    getPercentage(protectedAmount, totalAmount),
    (percentage) => Math.min(100, percentage),
  );

  return (
    <div
      className={[
        className,
        cx("Slider", `Slider--${incompleteStyle}`, {
          "Slider--empty": boundPercentage.exact === 0,
        }),
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <div
        className={cx("Slider__PercentageWrapper")}
        style={{
          left: `min(max(calc(${
            boundPercentage.exact
          }% + 7px), 19px), calc(100% - ${
            boundPercentage.exact === 100 ? "33" : "25"
          }px))`,
        }}
      >
        <div className={cx("Slider__Percentage")}>
          {title ? (
            <Tooltip title={title}>{boundPercentage.value}%</Tooltip>
          ) : (
            `${boundPercentage.value}%`
          )}
        </div>
      </div>

      <div className={cx("Slider__Labels")}>
        <div
          className={cx("Label", "Label__Protected")}
          style={{
            width: `max(0px, calc(${boundPercentage.exact}% - 20px))`,
          }}
        >
          <div>
            <div className={cx("Label__Icon")}>
              <Icon type={IconType.SUCCESS} />
            </div>
            <div className={cx("Label__Text")}>
              <span style={{ whiteSpace: "nowrap" }}>Protected</span>
            </div>
          </div>
        </div>

        <div
          className={cx("Label", "Label__AtRisk", `Label--${incompleteStyle}`)}
          style={{
            width: `max(0px, calc(${100 - boundPercentage.value}% - 40px))`,
          }}
        >
          <div>
            <div className={cx("Label__Text")}>
              <span>At Risk</span>
            </div>
            <div className={cx("Label__Icon")}>
              <Icon type={IconType.WARNING} />
            </div>
          </div>
        </div>
      </div>
      <Bar boundPercentage={boundPercentage.exact} />
      <SliderPartitionLabels
        lower={protectedAmount}
        upper={totalAmount - protectedAmount}
      />
    </div>
  );
};

export default ProtectedAssetsSlider;
