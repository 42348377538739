import { PartnerFinancialStatus } from "modules/parties/types/Finance";

const comparePartnerFinancialStatuses = (
  a: PartnerFinancialStatus,
  b: PartnerFinancialStatus,
  sortField: string,
) => {
  switch (sortField) {
    case "partnerId":
    case "displayName":
      return a[sortField].localeCompare(b[sortField]);
    case "totalPaymentsCollected":
      return a[sortField] - b[sortField];
    default:
      return 0;
  }
};

export const sortPartnerFinancialStatuses = (
  partnerFinancialStatuses: PartnerFinancialStatus[],
  sortField: string,
  order: string,
): PartnerFinancialStatus[] => {
  if (!sortField || !order) return partnerFinancialStatuses;

  if (order === "asc")
    return [...partnerFinancialStatuses].sort((a, b) =>
      comparePartnerFinancialStatuses(a, b, sortField),
    );

  if (order === "desc")
    return [...partnerFinancialStatuses]
      .sort((a, b) => comparePartnerFinancialStatuses(a, b, sortField))
      .reverse();

  return partnerFinancialStatuses;
};
