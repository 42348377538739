import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import HeaderAccountModal from "../HeaderAccountModal";
import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";
import { User } from "modules/auth/User";
import MenuLink from "../MenuLink";

type HeaderAccountProps = {
  onLogout: () => void;
  user: User;
  profileMenuLinks: MenuLink[];
};

const HeaderAccount: React.FunctionComponent<HeaderAccountProps> = ({
  user,
  onLogout,
  profileMenuLinks,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node) &&
        open
      ) {
        setOpen(false);
      }
    },
    [open],
  );

  const handleMenuItemSelected = useCallback(() => {
    if (open) setOpen(false);
  }, [open]);

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if ((event.key === "Escape" || event.key === "Esc") && open) {
        setOpen(false);
      }
    },
    [open],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleClickOutside, true);
      document.addEventListener("keyup", handleKeyboard, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keyup", handleKeyboard, true);
    };
  }, [open, handleClickOutside, handleKeyboard]);

  // TODO needs user avatar image
  let style;
  //let image = "/images/avatar/avatar@2x.png";
  //let style = {
  //  backgroundImage: "url(" + image + ")",
  //};

  return (
    <div ref={ref} className={styles["header-account"]}>
      <TabbedButton
        tabIndex={0}
        ariaLabel="Account information"
        className={styles["header-account__button"]}
        onClick={() => setOpen(!open)}
      >
        <span
          className={styles["header-account__name"]}
          data-clarity-mask="true"
        >
          {user.displayName}
        </span>
        <span className={styles["header-account__avatar"]} style={style}></span>
      </TabbedButton>
      <HeaderAccountModal
        open={open}
        user={user}
        onLogout={onLogout}
        menuLinks={profileMenuLinks}
        onMenuItemSelected={handleMenuItemSelected}
      />
    </div>
  );
};

export default HeaderAccount;
