import * as React from "react";

import questionStyles from "components/Forms/Questions/QuestionWrapper/styles.module.scss";
import CheckboxButton from "../Checkbox/CheckboxButton";

type CheckboxGroupProps = {
  disabled?: boolean;
  id?: string;
  isInvalid?: boolean;
  onChange: (selectedValues: string[]) => void;
  options: Array<{
    label: string;
    value: string;
    icon?: React.ReactComponentElement<any>;
  }>;
  selectedValues: string[];
  stacked?: boolean;
  tabIndex?: number;
};

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  disabled,
  id,
  isInvalid,
  onChange,
  options,
  selectedValues,
  tabIndex,
}) => {
  const toggleOption = (value: string) => {
    const nextState = [...selectedValues];
    selectedValues.includes(value)
      ? nextState.splice(nextState.indexOf(value), 1)
      : nextState.push(value);

    onChange(nextState);
  };

  return (
    <div className={questionStyles["question__inner-buttons"]}>
      {options.map((option, idx) => (
        <CheckboxButton
          disabled={disabled}
          icon={option.icon}
          id={idx === 0 ? id : ""}
          invalid={isInvalid}
          key={option.value}
          label={option.label}
          onClick={() => toggleOption(option.value)}
          selected={selectedValues.includes(option.value)}
          tabIndex={tabIndex === undefined ? 0 : tabIndex}
        />
      ))}
    </div>
  );
};

export default CheckboxGroup;
