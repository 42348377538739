import React, { useCallback } from "react";
import classnames from "classnames/bind";
import Form, { FormSubmitHandle } from "components/Forms/Form";
import { InputType } from "components/Forms/Inputs/Input/types";
import QuestionInput from "components/Forms/Questions/QuestionInput";
import TabbedButton from "components/TabbedButton";
import {
  getInitialValues,
  getValidationSchema,
} from "modules/workflow/helpers/validation";
import {
  Question,
  QuestionID,
  QuestionType,
} from "modules/workflow/types/Questionnaire";

import styles from "./styles.module.scss";
import Link from "components/Link";
import Icon, { IconType } from "components/Icons";
import { ModalType, showModalConfirm } from "components/Modal";
import { PromoCodesConfig } from "modules/parties/types/Partner";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { user } from "modules/auth/reducer";
import { savePartner } from "modules/partner/actions";
import { useDispatch } from "react-redux";
const cx = classnames.bind(styles);

type PromoCode = { code: string; discount: number };

type PromoCodesSetupFormProps = {
  promoCodes: PromoCodesConfig;
};

const PromoCodesSetupForm: React.FC<PromoCodesSetupFormProps> = ({
  promoCodes,
}) => {
  const dispatch = useDispatch();
  const codes: PromoCode[] = Object.entries(promoCodes).map(
    ([code, discount]) => ({ code, discount }),
  );

  const saveNewPromoCodes = useCallback(
    async (promoCodes: PromoCodesConfig) => {
      if (!user) {
        logger.warn("No user available.");
        return;
      }

      try {
        await savePartner({ promoCodes })(dispatch);
        logger.notify(
          NotificationType.SUCCESS,
          "Updated promo codes were saved",
        );
      } catch (e) {
        logger.notify(
          NotificationType.ERROR,
          "Saving data failed. Please, review the entered data and try again.",
          e,
        );
        throw e;
      }
    },
    [dispatch],
  );

  const handleAddCode = useCallback(
    (code: string, discount: number) =>
      saveNewPromoCodes({ ...promoCodes, [code]: discount }),
    [promoCodes, saveNewPromoCodes],
  );

  const handleRemoveCode = useCallback(
    async (code: string) => {
      const { [code]: _, ...nextCodes } = promoCodes;
      return saveNewPromoCodes(nextCodes);
    },
    [promoCodes, saveNewPromoCodes],
  );

  const onSaveCode = ({ code, discount }: Record<QuestionID, any>) =>
    handleAddCode(code, discount);

  const confirmRemoveCode = ({ code, discount }: PromoCode) => {
    showModalConfirm({
      labelConfirm: "Revoke",
      onConfirm: async () => handleRemoveCode(code),
      text: `Are you sure you want to revoke promo code ${code} (discount: ${discount}%)?`,
      title: "Revoke promo code",
      type: ModalType.WARNING,
    });
  };

  const questions: Question[] = [
    {
      id: "code",
      data: {
        descriptions: [{ label: "Promo code" }],
        definition: { type: QuestionType.TEXT },
        required: true,
      },
    },

    {
      id: "discount",
      data: {
        descriptions: [
          {
            label: "Discount (%)",
          },
        ],
        definition: { type: QuestionType.PERCENTAGE },
        required: true,
      },
    },
  ];

  const renderPromoCode = ({ code, discount }: PromoCode) => (
    <li className={cx("promoCode")} key={code}>
      <div id={`remove_${code}`} className={cx("promoCode__action")}>
        <Link onClick={() => confirmRemoveCode({ code, discount })}>
          <Icon
            className={cx("promoCode__action__icon")}
            type={IconType.CROSS}
          />
        </Link>
      </div>
      <div className={cx("promoCode__discount")}>{discount}%</div>
      <div className={cx("promoCode__code")}>{code}</div>
    </li>
  );

  return (
    <div className={cx("promoCodeConfig")}>
      <div className={cx("promoCodeConfig__active")}>
        <h2>Active Promo Codes</h2>
        <ul>{codes.map(renderPromoCode)}</ul>
      </div>
      <div className={cx("promoCodeConfig__new")}>
        <h2>Add a promo code</h2>
        <Form
          schema={getValidationSchema(questions)}
          state={getInitialValues(questions)}
          resetOnSave
          onSave={onSaveCode}
        >
          {(submitHandle: FormSubmitHandle) => (
            <>
              <div className={cx("promoCodeConfig__new__fields")}>
                <QuestionInput name="code" label="Code" type={InputType.TEXT} />
                <QuestionInput
                  name="discount"
                  label="Discount"
                  type={InputType.PERCENTAGE_INTEGER}
                />
              </div>
              <TabbedButton onClick={() => submitHandle()}>Set</TabbedButton>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default PromoCodesSetupForm;
