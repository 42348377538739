import React, { useCallback, useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  loadStripe,
  PaymentIntent,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { shallowEqual, useDispatch } from "react-redux";
import { addressSearch, addressLookup } from "modules/address/actions";
import { useSelector } from "redux/reducer";
import logger from "../../logger";

import PaymentScreen from "./PaymentScreen";
import { useParams } from "react-router-dom";
import {
  getRoute,
  getWorkflowDetailsRoute,
  getWorkflowRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import LoadingOverlay from "components/LoadingOverlay";
import {
  paymentDetailsInvalid,
  paymentInitializationError,
  paymentSuccessful,
  submitPayment,
} from "modules/payment/actions";
import BillingInfo from "modules/payment/types/BillingInfo";
import { NotificationType } from "components/Notification";
import Address from "modules/parties/types/Address";
import collectAllAddresses from "modules/workflow/helpers/collectAllAddresses";
import { Step } from "modules/api/types/WorkflowPayload";
import AwaitingCoupledScreen from "./AwaitingCoupledScreen";
import AwaitingInterviewCompletionScreen from "./AwaitingInterviewCompletionScreen";
import { go, redirect } from "modules/navigation/helpers/navigator";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";
import { isAtLeast, isPaid } from "modules/workflow/helpers/lifecycle";
import Workflow from "modules/workflow/types/Workflow";
import { Environment, getEnvironment } from "utils/environment";
import { PaymentStatus } from "modules/api/types/Payment";
import { isAdmin } from "modules/auth/helper";
import Alert, { AlertType } from "components/Alert";
import getExternalProducts from "modules/workflow/helpers/getExternalProducts";
import DataLayerContext from "contexts/DataLayerContext";
import { EventName } from "contexts/DataLayerContext/types";

const {
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_STRIPE_API_KEY_STG,
  REACT_APP_STRIPE_API_KEY_PROD,
} = process.env;

const resolveStripeApiKey = () => {
  const env = getEnvironment();
  if (Environment.STAGING === env) {
    return REACT_APP_STRIPE_API_KEY_STG;
  }
  if (Environment.PROD === env) {
    return REACT_APP_STRIPE_API_KEY_PROD;
  }
  return REACT_APP_STRIPE_API_KEY;
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: "F37Sonic Regular",
      src: "url(https://aturna.legal/fonts/F37Sonic-Regular.woff)",
    },
  ],
};

const stripeApiKey = resolveStripeApiKey();
const stripePromise = stripeApiKey
  ? loadStripe(stripeApiKey)
  : Promise.reject("Stripe not configured");

const PaymentScreenContainer = () => {
  const { id: workflowId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { sendEvent } = useContext(DataLayerContext);

  const {
    activePayment,
    isLoadingWorkflowDetails,
    isSubmittingPayment,
    partner,
    state,
    workflows,
    user,
  } = useSelector((state) => state, shallowEqual);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const admin = isAdmin(partner?.id, user);
  const workflow: Workflow | undefined = workflowId
    ? workflows[workflowId]
    : undefined;
  const isWaitingForCoupled =
    workflow?.state.steps.current.step === Step.WaitingForCoupledConfirmSummary;
  const isInterviewCompleted =
    workflow &&
    isAtLeast(workflow.state.steps, Step.WaitingForCoupledConfirmSummary);
  const availableAddresses: Address[] = collectAllAddresses(
    workflows,
    workflowId,
  );

  const handleInitializationError = useCallback(
    (errorMsg: string) => {
      dispatch(
        paymentInitializationError({
          error: errorMsg,
          redirectPath: workflow
            ? getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID)
            : getRoute(ROUTE_TEMPLATE.WORKFLOW),
        }),
      );
    },
    [dispatch, workflow],
  );

  // Init Stripe
  useEffect(() => {
    isInterviewCompleted &&
      !isWaitingForCoupled &&
      activePayment &&
      stripePromise
        .then((stripe) =>
          stripe
            ? setStripe(stripe)
            : handleInitializationError("Stripe failed to initialize"),
        )
        .catch((err) => {
          logger.error(err);
          handleInitializationError("Stripe not configured");
        });
  }, [
    activePayment,
    handleInitializationError,
    isInterviewCompleted,
    isWaitingForCoupled,
  ]);

  const handleAddressLookup = async (addressId: string) =>
    addressLookup(addressId)(dispatch);

  const handleAddressSearch = async (
    containerId?: string,
    searchTerm?: string,
  ) => addressSearch(containerId, searchTerm)(dispatch);

  const handlePaymentFormInvalid = (message?: string) => {
    sendEvent?.({
      event: EventName.CheckoutError,
    });
    dispatch(paymentDetailsInvalid(message));
  };

  const handleSubmitPayment = async (
    cardInfo?: StripeCardElement | StripeCardNumberElement,
    billingInfo?: BillingInfo,
  ) => {
    if (!stripe || !activePayment?.clientSecret) {
      logger.notify(
        NotificationType.ERROR,
        "Payment is not possible at the moment. Please, try again later.",
      );
      return;
    }
    if (workflow?.isSkippingPayment) {
      dispatch(
        paymentSuccessful({
          workflowId,
          paymentIntent: {} as PaymentIntent,
        }),
      );
    } else {
      cardInfo &&
        billingInfo &&
        dispatch(
          submitPayment(
            stripe,
            activePayment.workflowId,
            activePayment.clientSecret,
            cardInfo,
            billingInfo,
          ),
        );
    }
  };

  const handleOpenSpouseWorkflow = () => {
    const spouseWorkflowId = workflow?.state.coupledWorkflowId;
    spouseWorkflowId &&
      go(
        dispatch,
        getWorkflowDetailsRoute(spouseWorkflowId, undefined, SUMMARY_PAGE_ID),
      );
  };

  const handleOpenInterview = () => {
    workflow &&
      go(
        dispatch,
        getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID),
      );
  };

  if (isLoadingWorkflowDetails || !workflow) return <LoadingOverlay />;
  if (!isInterviewCompleted)
    return (
      <AwaitingInterviewCompletionScreen
        onOpenInterview={handleOpenInterview}
      />
    );
  if (isWaitingForCoupled)
    return (
      <AwaitingCoupledScreen
        onOpenSpouseWorkflow={handleOpenSpouseWorkflow}
        isAdmin={admin}
      />
    );

  if (!activePayment) {
    workflowId &&
      redirect(
        dispatch,
        getWorkflowRoute(workflowId, ROUTE_TEMPLATE.WORKFLOW_SIGNING), // TODO only if available
      );
    return <LoadingOverlay />;
  }

  if (activePayment.status === PaymentStatus.Success) {
    logger.report(
      "Payment screen accessed for an already-paid workflow",
      `Payment ID: ${activePayment.id}`,
      `Workflow ID: ${activePayment.workflowId} (current step: ${workflow?.state.steps.current.step})`,
    );

    workflowId &&
      redirect(
        dispatch,
        getWorkflowDetailsRoute(workflowId, undefined, SUMMARY_PAGE_ID),
      );
    return <LoadingOverlay />;
  }

  const paymentInfo = (
    <>
      {activePayment.amount > 0 ? (
        <Alert
          type={AlertType.INFO}
          message="A hold will be placed on your card until the law firm has reviewed and accepted the engagement agreement; your card will only be charged once the engagement is accepted."
        />
      ) : undefined}
      {!isPaid(workflow.state.steps) &&
      getExternalProducts(workflow).length > 0 ? (
        <Alert
          type={AlertType.WARNING}
          message="You are only charged for the documents you receive online once your interview is finalized. Documents prepared in the law firm will be charged separately."
        />
      ) : undefined}
    </>
  );

  return stripe ? (
    <Elements stripe={stripe} options={ELEMENTS_OPTIONS}>
      <PaymentScreen
        allOrderItems={activePayment.allOrderItems}
        availableAddresses={availableAddresses}
        clients={workflow.contacts?.clients}
        coupledWorkflowId={workflow.state.coupledWorkflowId}
        isSubmitting={isSubmittingPayment}
        jurisdiction={state || undefined}
        partner={partner || undefined}
        onAddressLookup={handleAddressLookup}
        onAddressSearch={handleAddressSearch}
        onPaymentFormInvalid={handlePaymentFormInvalid}
        onSubmit={handleSubmitPayment}
        orderItems={activePayment.orderItems}
        supervisedSigning={Boolean(workflow.state.supervisedSigning)}
        hasExternalProducts={Boolean(workflow.state.hasExternalProducts)}
        totalPrice={activePayment.amount}
        paymentInfo={paymentInfo}
        shouldSkipPaymentDetails={workflow?.isSkippingPayment}
      />
    </Elements>
  ) : (
    <LoadingOverlay />
  );
};

export default PaymentScreenContainer;
