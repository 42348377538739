import { Box, Grid } from "@mui/material";
import Dropdown from "components/Forms/Inputs/Dropdown";
import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import PageHeader from "components/PageHeader";
import { uniq } from "lodash";
import {
  AdminWorkflowStatus,
  workflowStepToAdminStatusMap,
} from "modules/api/types/WorkflowPayload";
import CsvExport from "modules/partner/settings/components/CsvExport";
import WorkflowsList, {
  WorkflowListColumn,
} from "modules/workflow/components/WorkflowsList";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import Workflow from "modules/workflow/types/Workflow";
import React, { useMemo, useState } from "react";
import { filterProducts, filterSearchWords, filterStatus } from "./helpers";

export const ALL_STATUSES = "All statuses";
export const ARCHIVED = "Archived";
export const ALL_PRODUCTS = "All products";

type AdminWorkflowsScreenProps = {
  workflowDefinitions: Record<
    string,
    DefinitionWithProductsPlansAndAddons
  > | null;
  partnerWorkflows: Workflow[];
  onOpenWorkflow: (
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) => any;
};

const statusOptions = [
  { label: ALL_STATUSES, value: ALL_STATUSES },
  ...uniq(Object.values(workflowStepToAdminStatusMap)).map((value) => ({
    label: value,
    value,
  })),
  { label: ARCHIVED, value: ARCHIVED },
];

const AdminWorkflowsScreen = ({
  workflowDefinitions,
  partnerWorkflows,
  onOpenWorkflow,
}: AdminWorkflowsScreenProps) => {
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<
    AdminWorkflowStatus | typeof ALL_STATUSES | typeof ARCHIVED
  >(ALL_STATUSES);
  const [selectedProductId, setSelectedProductId] =
    useState<string>(ALL_PRODUCTS);

  const workflowsListColumns: WorkflowListColumn[] = [
    {
      label: "Client Name",
      accessor: "client_name",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const {
          state: { clientFullName },
        } = workflow;
        return <div data-clarity-mask="true">{clientFullName}</div>;
      },
    },
    {
      label: "Status",
      accessor: "status",
      sortable: true,
      reset: false,
      valueExtractor: ({ state }: Workflow) =>
        workflowStepToAdminStatusMap[state.steps.current.step] || "N/A",
    },
    {
      label: "Products",
      accessor: "products",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const {
          state: { label },
        } = workflow;
        return <div style={{ maxWidth: "250px" }}>{label}</div>;
      },
    },
    {
      label: "State",
      accessor: "state",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const {
          state: { maybeUserState },
        } = workflow;
        return <div>{maybeUserState}</div>;
      },
    },
    {
      label: "Created",
      accessor: "created",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const createdDate = new Date(workflow.created).toLocaleDateString();
        const creatorInitials =
          workflow.creator === "CLIENT"
            ? ""
            : workflow.creatorName === undefined
              ? ""
              : workflow.creatorName
                  .split(" ")
                  .map((n) => n[0])
                  .join(".") + ".";
        return (
          <div>
            {createdDate}
            <br />
            {creatorInitials}
          </div>
        );
      },
    },
    {
      label: "Last Edited",
      accessor: "last_edited",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const editedDate = new Date(workflow.updated).toLocaleDateString();
        const updaterName =
          workflow.updaterName === undefined
            ? ""
            : workflow.updaterName === ""
              ? ""
              : workflow.updaterName
                  .split(" ")
                  .map((n) => n[0])
                  .join(".") + ".";
        const updaterInitials =
          workflow.creator === "CLIENT" ? "" : updaterName;
        return (
          <div>
            {editedDate}
            <br />
            {updaterInitials}
          </div>
        );
      },
    },
    {
      label: "Signing Type",
      accessor: "signing_type",
      sortable: true,
      reset: false,
      valueExtractor: (workflow: Workflow) => {
        const {
          state: { supervisedSigning },
        } = workflow;
        return supervisedSigning
          ? "Supervised"
          : workflow.creator === "LAWYER"
            ? "Internal"
            : "Self";
      },
    },
  ];

  const productOptions = useMemo(() => {
    const allOption = { label: ALL_PRODUCTS, value: ALL_PRODUCTS };
    if (!workflowDefinitions) return [allOption];

    let productOptionsMap = {} as {
      [key: string]: { label: string; value: string };
    };
    Object.values(workflowDefinitions).forEach((definition) => {
      productOptionsMap = {
        ...productOptionsMap,
        ...definition.data.availableProducts.reduce(
          (workflowProducts, product) => {
            return {
              ...workflowProducts,
              [product.id]: { label: product.name, value: product.id },
            };
          },
          {},
        ),
      };
    });

    return [allOption, ...Object.values(productOptionsMap)];
  }, [workflowDefinitions]);

  const filteredWorkflows = partnerWorkflows.filter(
    (workflow) =>
      filterSearchWords(searchText, workflow) &&
      filterProducts(selectedProductId, workflow) &&
      filterStatus(selectedStatus, workflow),
  );

  return (
    <div data-tid="admin-dashboard">
      <CsvExport />
      <PageHeader title="All workflows" />
      <Box mb={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputStringPrimitive
              value={searchText}
              onChangeValue={setSearchText}
              placeholder="Search by client name"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              name="status"
              options={statusOptions}
              onChange={(value) =>
                setSelectedStatus(
                  value as
                    | AdminWorkflowStatus
                    | typeof ALL_STATUSES
                    | typeof ARCHIVED,
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              name="product"
              options={productOptions}
              onChange={(value) => setSelectedProductId(value as string)}
            />
          </Grid>
        </Grid>
      </Box>
      <WorkflowsList
        workflows={filteredWorkflows}
        onOpenWorkflow={onOpenWorkflow}
        columns={workflowsListColumns}
      />
    </div>
  );
};

export default AdminWorkflowsScreen;
