import { formatAddressDetails } from "components/Forms/Questions/QuestionAddress/helpers";
import { ModalControls, ModalType, showModalConfirm } from "components/Modal";
import { NotificationType } from "components/Notification";
import logger from "logger";
import {
  approvePartnerRegistration,
  rejectPartnerRegistration,
} from "modules/admin/actions";
import { PartnerRegistration } from "modules/parties/types/Partner";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { QuestionID, QuestionType } from "modules/workflow/types/Questionnaire";
import React from "react";
import { Dispatch } from "redux";

export const showAcceptRegistrationModal = (
  dispatch: Dispatch,
  registration: PartnerRegistration,
  onComplete?: () => void,
) => {
  const { id, userId, request } = registration;
  const handleAccept = async () => {
    await approvePartnerRegistration(id, userId)(dispatch);
    logger.notify(NotificationType.SUCCESS, "Your review was saved.");
    onComplete && onComplete();
  };

  showModalConfirm({
    labelConfirm: "Accept Partner",
    onConfirm: () => handleAccept(),
    title: "Are you sure you want to create the following partner?",
    text: (
      <table>
        <tbody>
          <tr>
            <td>
              <strong>Company Name: </strong>
            </td>
            <td>{request.displayName}</td>
          </tr>
          <tr>
            <td>
              <strong>ID: </strong>
            </td>
            <td> {request.partnerId}</td>
          </tr>
          <tr>
            <td>
              <strong>Attorney Name: </strong>
            </td>
            <td>{request.attorneyName}</td>
          </tr>
          <tr>
            <td>
              <strong>Phone: </strong>
            </td>
            <td>{request.phone}</td>
          </tr>
          <tr>
            <td>
              <strong>Email Address: </strong>
            </td>
            <td>{request.email}</td>
          </tr>
          <tr>
            <td>
              <strong>Address: </strong>
            </td>
            <td>{formatAddressDetails(request.address)}</td>
          </tr>
          <tr>
            <td>
              <strong>Timezone: </strong>
            </td>
            <td>{request.timezone}</td>
          </tr>
        </tbody>
      </table>
    ),
  });
};

export const showRejectRegistrationModal = (
  dispatch: Dispatch,
  registration: PartnerRegistration,
  onComplete?: () => void,
) => {
  const { id, userId, request } = registration;
  let modalControls: ModalControls;
  const getModalControls = (controls: ModalControls) => {
    modalControls = controls;
  };

  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleFormSubmit = async (): Promise<void> => {
    await (submitHandle ? submitHandle() : Promise.resolve());
    logger.notify(NotificationType.SUCCESS, "Your review was saved.");
  };

  const handleSubmit = async (values: Record<QuestionID, any>) => {
    modalControls?.busy(true);

    const result = await rejectPartnerRegistration(
      id,
      userId,
      values.message,
    )(dispatch);

    if (result) {
      modalControls?.close();
    } else {
      modalControls?.busy(false);
    }
    onComplete && onComplete();
  };

  const handleFormCancel = () => {
    modalControls?.close();
  };

  showModalConfirm({
    getModalControls: getModalControls,
    labelConfirm: "Reject Request",
    title: `Please provide a reason for rejecting the request "${request.partnerId}"`,
    type: ModalType.WARNING,
    onConfirm: handleFormSubmit,
    onCancel: handleFormCancel,
    renderContent: (controls: ModalControls) => (
      <QuestionnaireForm
        getSubmitHandle={getSubmitHandle}
        onSavePageAnswers={async (_, values) => {
          controls.busy(true);
          await handleSubmit(values);
          controls.close();
        }}
        questionsBySubquestionnaire={{
          contactForm: {
            message: {
              id: "message",
              data: {
                descriptions: [{ label: "Rejection message" }],
                required: true,
                definition: { type: QuestionType.LONG_TEXT },
              },
            },
          },
        }}
        questionnairePages={[
          {
            id: "contactFormPage",
            caption: "",
            questionnaireId: "contactForm",
            sections: [{ caption: "", questions: ["message"] }],
          },
        ]}
      />
    ),
  });
};
