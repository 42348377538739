import LoadingOverlay from "components/LoadingOverlay";
import PartnerRequestsDetail from "modules/globalAdmin/components/PartnerRequestDetail";
import PartnerRequestsList from "modules/globalAdmin/components/PartnerRequestList";
import usePartnerRequests from "modules/globalAdmin/hooks/usePartnerRequests";
import { RegistrationState } from "modules/parties/types/Partner";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const PartnerRequestsScreen: React.FC = () => {
  const { path } = useRouteMatch();
  const { partnerRequests } = usePartnerRequests();

  if (!partnerRequests) return <LoadingOverlay />;

  const activeRequests = partnerRequests.filter(
    (request) => request.status.type === RegistrationState.PENDING,
  );

  return (
    <Switch>
      <Route
        path={`${path}/:requestId`}
        render={({ match }) => (
          <PartnerRequestsDetail
            registration={activeRequests?.find(
              (request) => request.id === match.params.requestId,
            )}
          />
        )}
      />
      <Route>
        <PartnerRequestsList registrations={activeRequests} />
      </Route>
    </Switch>
  );
};

export default PartnerRequestsScreen;
