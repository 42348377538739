import React from "react";
import { ConsumerMedicaidLeadItemQuestionsCompleteProps } from "./types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const ConsumerMedicaidLeadItemQuestionsComplete = ({
  answered,
  total,
}: ConsumerMedicaidLeadItemQuestionsCompleteProps) => (
  <div
    className={cx("consumer-medicaid-lead-item-questions-complete", {
      complete:
        answered !== undefined && total !== undefined && answered >= total,
    })}
  >
    {answered === undefined && total === undefined ? (
      <span>N/A</span>
    ) : (
      <span>
        {answered}/{total}
      </span>
    )}
  </div>
);

export default ConsumerMedicaidLeadItemQuestionsComplete;
