import Link from "components/Link";
import { PageProgress } from "modules/api/types/WorkflowPayload";
import * as React from "react";

import ClientDashboardProductsItemBreakdownItem from "./ClientDashboardProductsItemBreakdownItem";

import styles from "./styles.module.scss";

type ClientDashboardProductsItemBreakdownProps = {
  pageProgresses: PageProgress[];
  currentPageIdx: number;
  onEditPage: (pageIdx: number) => void;
  onViewParties: () => any;
};

const ClientDashboardProductsItemBreakdown: React.FunctionComponent<
  ClientDashboardProductsItemBreakdownProps
> = ({ pageProgresses, currentPageIdx, onEditPage, onViewParties }) => {
  const renderPage = (pageProgress: PageProgress, idx: number) => {
    const {
      title,
      summary: { completed, total },
    } = pageProgress;

    const pageIdx = pageProgresses.indexOf(pageProgress);

    return (
      <ClientDashboardProductsItemBreakdownItem
        key={idx}
        complete={completed}
        title={title}
        total={total}
        pageIdx={pageIdx}
        isAccessible={currentPageIdx >= idx}
        onEditPage={onEditPage}
      />
    );
  };

  return (
    <div className={styles["client-dashboard-products-item-breakdown"]}>
      <ul className={styles["client-dashboard-products-item-breakdown__list"]}>
        <li
          className={styles["client-dashboard-products-item-breakdown__item"]}
        >
          <div
            className={
              styles["client-dashboard-products-item-breakdown__item-col"]
            }
          >
            <div
              className={
                styles["client-dashboard-products-item-breakdown__item-title"]
              }
            >
              Section
            </div>
          </div>
          <div
            className={
              styles["client-dashboard-products-item-breakdown__item-col"]
            }
          >
            <div
              className={
                styles["client-dashboard-products-item-breakdown__item-title"]
              }
            >
              Questions completed
            </div>
          </div>
          <div
            className={
              styles["client-dashboard-products-item-breakdown__item-col"]
            }
          >
            <div
              className={
                styles["client-dashboard-products-item-breakdown__item-title"]
              }
            >
              Status
            </div>
          </div>
          <div
            className={
              styles["client-dashboard-products-item-breakdown__item-col"]
            }
          ></div>
        </li>
        {pageProgresses.map(renderPage)}
        <li
          className={styles["client-dashboard-products-item-breakdown__item"]}
        >
          <div
            className={
              styles["client-dashboard-products-item-breakdown__item-col"]
            }
          >
            <Link onClick={onViewParties}>View all mentioned people</Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ClientDashboardProductsItemBreakdown;
