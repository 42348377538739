import React from "react";
import { type AcceptedMedicaidLeadPreviewItemProps } from "./types";
import moment from "moment";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ClientTypeUpperCase } from "modules/parties/types/Client";
import { getFullName } from "modules/parties/types/Party";
import currencyFormatter from "utils/currencyFormatter";
import TabbedButton from "components/TabbedButton";

const cx = classNames.bind(styles);

const AcceptedMedicaidLeadPreviewItem = ({
  item,
  handleShowDetails,
}: AcceptedMedicaidLeadPreviewItemProps) => (
  <li className={cx("accepted-medicaid-lead-preview-item")}>
    <div className={cx("accepted-medicaid-lead-preview-item__client-data")}>
      <div className={cx("accepted-medicaid-lead-preview-item__client-name")}>
        <h3>Client</h3>
        <span
          className={cx(
            "accepted-medicaid-lead-preview-item__client-data__value",
          )}
        >
          {getFullName(item.enriched.clients.Main)}
        </span>
      </div>
      <div>
        <h3>Protectable Assets</h3>
        <span
          className={cx(
            "accepted-medicaid-lead-preview-item__client-data__value",
          )}
        >
          {currencyFormatter.format(item.calculation.protectableAmount)}
        </span>
      </div>
      <div>
        <h3>Marital Status</h3>
        <span
          className={cx(
            "accepted-medicaid-lead-preview-item__client-data__value",
          )}
        >
          {item.enriched.clients[ClientTypeUpperCase.SPOUSE] === undefined
            ? "Single"
            : "Married"}
        </span>
      </div>
      <div className={cx("accepted-medicaid-lead-preview-item__action")}>
        <TabbedButton label="View" onClick={handleShowDetails} />
      </div>
    </div>
    <span className={cx("accepted-medicaid-lead-preview-item__date")}>
      {moment(item.enriched.medicaidLead.updated).format("MMMM DD, YYYY")}
    </span>
  </li>
);

export default AcceptedMedicaidLeadPreviewItem;
