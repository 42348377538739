import * as React from "react";
import { User } from "../../modules/auth/User";

import HeaderLogo from "./HeaderLogo";
import MenuLink from "./MenuLink";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

import styles from "./styles.module.scss";
import {
  mobileWidthBreakpoint,
  useWindowDimensions,
} from "utils/windowDimensions";
import { useState } from "react";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type HeaderProps = {
  hideNavigationMenu?: boolean;
  hideProfileMenu?: boolean;
  mainNavigationLinks: MenuLink[];
  onLogin: () => any;
  onLogoClicked: () => any;
  onLogout: () => any;
  onStateChange?: () => void;
  profileMenuLinks: MenuLink[];
  selectedState?: string;
  user: User | null;
  greenBorderBottom?: boolean;
};

const Header = ({
  hideNavigationMenu,
  hideProfileMenu,
  mainNavigationLinks,
  onLogin,
  onLogoClicked,
  onLogout,
  onStateChange,
  profileMenuLinks,
  selectedState,
  user,
  greenBorderBottom,
}: HeaderProps) => {
  const [headerIsOverlaying, setHeaderIsOverlaying] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width < mobileWidthBreakpoint;
  const toggleHeaderOverlay = () => setHeaderIsOverlaying(!headerIsOverlaying);
  return (
    <header
      className={cx("header", {
        "is-overlaying": headerIsOverlaying,
        "green-border-bottom": Boolean(greenBorderBottom),
      })}
    >
      <div className={cx("header__container")}>
        <HeaderLogo onClick={onLogoClicked} />
        {isMobile ? (
          <HeaderMobile
            hideNavigationMenu={hideNavigationMenu}
            hideProfileMenu={hideProfileMenu}
            mainNavigationLinks={mainNavigationLinks}
            onLogin={onLogin}
            onLogout={onLogout}
            onStateChange={onStateChange}
            profileMenuLinks={profileMenuLinks}
            selectedState={selectedState}
            user={user}
            toggleHeaderOverlay={toggleHeaderOverlay}
          />
        ) : (
          <HeaderDesktop
            hideNavigationMenu={hideNavigationMenu}
            hideProfileMenu={hideProfileMenu}
            mainNavigationLinks={mainNavigationLinks}
            onLogin={onLogin}
            onLogout={onLogout}
            onStateChange={onStateChange}
            profileMenuLinks={profileMenuLinks}
            selectedState={selectedState}
            user={user}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
