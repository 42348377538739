import * as React from "react";
import { Formik } from "formik";
import * as yup from "yup";

import TabbedButton, { TabbedButtonMood } from "components/TabbedButton";
import Fieldset from "components/Forms/Fieldset";
import ShoppingCart from "./ShoppingCart";

import styles from "./styles.module.scss";
import { AnswersMap } from "modules/workflow/types/Answer";
import { PaymentOrderItem } from "modules/api/types/Payment";
import Partner from "../../../parties/types/Partner";

type PaymentProps = {
  partner?: Partner;
  form: (values: {}) => React.ReactNode;
  isSubmitting?: boolean;
  onFormInvalid?: () => void;
  onSubmit?: (values: {}) => void;
  orderItems?: PaymentOrderItem[];
  paymentInfo?: JSX.Element | JSX.Element[];
  schema: yup.ObjectSchema;
  state: {};
  totalPrice?: number;
};

const Payment: React.FunctionComponent<PaymentProps> = ({
  partner,
  form,
  isSubmitting,
  onFormInvalid,
  onSubmit,
  orderItems,
  paymentInfo,
  schema,
  state,
  totalPrice,
}) => {
  const handleSubmitButtonClicked = async (
    formValues: AnswersMap,
    submitCallback: () => any,
  ) => {
    try {
      await schema.validate(formValues);
    } catch (e) {
      onFormInvalid && onFormInvalid();
    }
    submitCallback(); // Proceed to built-in Formik validation
  };
  const label = totalPrice === 0 ? "Complete transaction" : "Confirm payment";
  return (
    <Formik
      initialValues={state}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        onSubmit && onSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => (
        <div className={styles["payment"]}>
          <div className={styles["payment__col"]}>
            <form onSubmit={handleSubmit}>
              {form(values)}
              <Fieldset>
                <TabbedButton
                  ariaLabel={label}
                  disabled={Boolean(isSubmitting)}
                  label={label}
                  mood={TabbedButtonMood.POSITIVE}
                  onClick={() =>
                    handleSubmitButtonClicked(values, handleSubmit)
                  }
                />
              </Fieldset>
            </form>
          </div>
          <div className={styles["payment__col"]}>
            <ShoppingCart
              partner={partner}
              orderItems={orderItems}
              totalPrice={totalPrice}
              paymentInfo={paymentInfo}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Payment;
