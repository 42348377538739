import * as React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type LabelProps = {
  disabled?: boolean;
  label?: string | JSX.Element;
  id?: string;
  invalid?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

const Label: React.FunctionComponent<LabelProps> = (props) => {
  function handleClick(event: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    let element = document.getElementById(
      event.currentTarget.htmlFor,
    ) as HTMLElement;
    if (element && element.getAttribute("role") === "button") {
      let parent = element?.parentElement?.parentElement;
      if (parent && parent.tagName === "UL") {
        let target = parent.querySelector(
          "div[role='button'][tabindex='0']",
        ) as HTMLElement;
        if (target) {
          target.focus();
          event.preventDefault();
        }
      } else {
        element.focus();
        event.preventDefault();
      }
    }
  }
  let className = cx(
    "label",
    {
      "label--disabled": props.disabled,
      "label--invalid": !props.disabled && props.invalid,
    },
    props.className,
  );
  return (
    <label
      className={className}
      /*htmlFor={props.id}*/ onClick={handleClick}
      style={props.style}
    >
      {props.label}
    </label>
  );
};

export default Label;
