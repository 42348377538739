import { maxBy, uniq } from "lodash";
import { AddonID } from "modules/product/types/Addon";
import { AdvisorPlan, Score } from "./actions";

export const getRecommendedPlan = (
  scoredAnswers: Array<Score | undefined>,
): AdvisorPlan => {
  const totalScoresPerPlan: Score =
    scoredAnswers.reduce((acc, plansScore) => {
      Object.keys(plansScore || {}).forEach((planId) => {
        acc![planId] = (acc![planId] || 0) + plansScore![planId];
      });
      return acc;
    }, {} as Score) || {};

  return (
    (maxBy(Object.entries(totalScoresPerPlan), ([, score]) => score)?.[0] as
      | AdvisorPlan
      | undefined) || AdvisorPlan.ESSENTIALS
  );
};

export const getRecommendedAddons = (
  scoredAnswers: Array<Score | undefined>,
): AddonID[] => {
  return uniq(
    scoredAnswers
      .map((score) => (score ? Object.keys(score)[0] : undefined))
      .filter(Boolean) as AddonID[],
  );
};
