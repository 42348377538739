import { flattenDeep } from "lodash";
import mergeWith from "lodash/mergeWith";
import {
  Question,
  QuestionID,
  QuestionnaireID,
  QuestionnairePage,
  QuestionsMap,
} from "../types/Questionnaire";

export const getPageQuestionsIDs = (page: QuestionnairePage): QuestionID[] =>
  flattenDeep(
    page.sections.map((s) => (s.questions || []).map((id) => id)),
  ).filter(Boolean);

export const getPageQuestions = (
  page: QuestionnairePage,
  questions: QuestionsMap,
): Question[] =>
  getPageQuestionsIDs(page)
    .map((questionId) => questions[questionId])
    .filter(Boolean);

export const getQuestionIDsByQuestionnaire = (
  pages: QuestionnairePage[],
): Record<QuestionnaireID, QuestionID[]> => {
  const mergeArraysCustomizer = (
    objValue: string[] | undefined,
    srcValue: string | string[],
  ) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };

  return pages
    .map((page) => ({
      [page.questionnaireId]: getPageQuestionsIDs(page),
    }))
    .reduce(
      (result, pageQuestions) =>
        mergeWith(result, pageQuestions, mergeArraysCustomizer),
      {} as Record<QuestionnaireID, QuestionID[]>,
    );
};
