import * as React from "react";
import classNames from "classnames/bind";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import Workflow from "../../types/Workflow";

import styles from "./styles.module.scss";
import PaginatedList, { ListType } from "components/PaginatedList";
import {
  getBtnLabel,
  getBtnLabelLawyerInitiatedWorkflow,
  getPageId,
  getStatus,
  sortWorkflows,
} from "./helpers";
import { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import ArchiveWorkflow from "../WorkflowArchiveModal/WorkflowArchiveModal";
import { Step } from "modules/api/types/WorkflowPayload";
import { useDispatch } from "react-redux";
import { startLawyerReview } from "modules/workflow/review/actions";
import { useSelector } from "redux/reducer";
import LoadingSpinner from "components/LoadingSpinner";

const cx = classNames.bind(styles);

export type WorkflowListColumn = {
  label: string;
  accessor: string;
  sortable: boolean;
  reset: boolean;
  valueExtractor: (wf: Workflow) => string | number | JSX.Element;
};

type WorkflowsListProps = {
  columns: WorkflowListColumn[];
  id?: string;
  onOpenWorkflow: (
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) => any;
  workflows: Workflow[];
};

const WorkflowsList = ({
  id,
  workflows,
  onOpenWorkflow,
  columns,
}: WorkflowsListProps) => {
  const dispatch = useDispatch();
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const loadingWorkflows = useSelector(
    (state) => state.clientWorkflowsPrioritisedLoading,
  );
  const sortedWorkflows = sortWorkflows(workflows, sortField, order);

  const showLoadingSpinnner = useMemo<boolean>(
    () => workflows.length === 0 && loadingWorkflows,
    [loadingWorkflows, workflows.length],
  );

  const renderWorkflow = (wf: Workflow) => {
    const currentStep = wf.state.steps.current.step;

    const btnLabel =
      wf.creator === "LAWYER"
        ? getBtnLabelLawyerInitiatedWorkflow(currentStep)
        : getBtnLabel(currentStep);
    const status = getStatus(currentStep);
    const pageId = getPageId(currentStep);

    const handleOpenWorkflow = async (pageId?: string) => {
      if (currentStep === Step.AwaitingLawyerReview) {
        await startLawyerReview(
          wf.id,
          wf.userId,
        )(dispatch).then(() => onOpenWorkflow(wf, undefined, pageId));
      } else {
        onOpenWorkflow(wf, undefined, pageId);
      }
    };

    return (
      <tr key={wf.id}>
        <td>
          <div>
            <ArchiveWorkflow
              className={cx("list__actions__archive-button")}
              workflow={wf}
              afterSave={() => {}}
            />
            <ReactTooltip id="archiveTooltip" place="top" effect="solid">
              Archive the Workflow
            </ReactTooltip>
            <ReactTooltip id="reinstateTooltip" place="top" effect="solid">
              Unarchive the Workflow
            </ReactTooltip>
          </div>
        </td>
        {columns.map(({ label, accessor, valueExtractor }) => (
          <td key={accessor}>{valueExtractor(wf)}</td>
        ))}
        <td>
          <div className={cx("list__actions")}>
            {wf.archived ? (
              <div className={cx("list__archived")}>
                {wf.updaterName === "SYSTEM" ? (
                  <span>Auto Archived</span>
                ) : (
                  <span>Archived</span>
                )}
              </div>
            ) : (
              <TabbedButton
                onClick={() => handleOpenWorkflow(pageId)}
                style={TabbedButtonType.PRIMARY}
                status={status}
              >
                {btnLabel}
              </TabbedButton>
            )}
          </div>
          <div className={cx("list__links")}>
            {wf.state.coupledWorkflowId !== undefined && !wf.archived ? (
              <div className={cx("list__actions__coupled-label")}>
                (coupled)
              </div>
            ) : (
              <div />
            )}
          </div>
        </td>
      </tr>
    );
  };

  if (showLoadingSpinnner) return <LoadingSpinner />;

  return (
    <PaginatedList<Workflow>
      listType={ListType.TABLE}
      items={sortedWorkflows}
      sortField={sortField}
      setSortField={setSortField}
      order={order}
      setOrder={setOrder}
      pageSize={20}
      id={id}
      renderItem={renderWorkflow}
      tableColumns={[
        ...columns,
        { label: "", accessor: "empty", sortable: false, reset: true },
      ]}
      itemNamePlural="workflows"
      customFirstColumn={true}
    />
  );
};

export default WorkflowsList;
