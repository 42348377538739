import GenericQuestion from "modules/workflow/components/GenericQuestion";
import { Question } from "modules/workflow/types/Questionnaire";
import React, { useState } from "react";
import { QuestionOrOptionProps } from "./types";
import styles from "./styles.module.scss";
import CheckboxButton from "components/Forms/Inputs/Checkbox/CheckboxButton";
import { useField } from "formik";
import { OrOption } from "modules/workflow/types/Answer";

const QuestionOrOption = ({
  name,
  label,
  description,
  disabled,
  client,
  questionDefinition,
  option,
  size,
  questionActions,
}: QuestionOrOptionProps) => {
  const [field, , helpers] = useField(name);
  const [optionSelected, setOptionSelected] = useState(
    (field.value as OrOption)?.isOptionSelected || false,
  );

  const onClickOption = () => {
    const optionIsSelected = !optionSelected;
    setOptionSelected(optionIsSelected);
    helpers.setValue({
      ...(field.value as OrOption),
      isOptionSelected: optionIsSelected,
    });
  };

  const question: Question = {
    id: `${name}.question`,
    label,
    description,
    data: {
      definition: questionDefinition,
      descriptions: [],
      required: !optionSelected,
    },
  };

  return (
    <div className={styles["question-or-option"]}>
      <GenericQuestion
        client={client}
        disabled={disabled || optionSelected}
        question={question}
        size={size}
        questionActions={questionActions}
      />
      <div className={styles["or-option"]}>
        <CheckboxButton
          label={option}
          onClick={onClickOption}
          selected={optionSelected}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default QuestionOrOption;
