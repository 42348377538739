import { callGetFinancialProfileAPI } from "modules/admin/actions";
import { PartnerID } from "modules/parties/types/Partner";
import useSWR from "swr";

const useFinancialProfile = (partnerId: PartnerID) => {
  const { data, error, mutate } = useSWR(["useFinancialProfile"], () =>
    callGetFinancialProfileAPI(partnerId),
  );

  return {
    financialProfile: data,
    error,
    refetchFinancialProfile: mutate,
  };
};

export default useFinancialProfile;
