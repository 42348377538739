import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames/bind";
import { useField } from "formik";
import Inputmask from "inputmask";

import PlatformContext from "contexts/PlatformContext";
import { handleChangeNumber } from "../helpers";
import { InputType } from "../types";
import getMask from "./masks";

import styles from "../styles.module.scss";
const cx = classNames.bind(styles);

type InputNumberPrimitiveProps = Omit<InputNumberProps, "name"> & {
  invalid?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  valid?: boolean;
  value: number | string;
};

export const InputNumberPrimitive: React.FunctionComponent<
  InputNumberPrimitiveProps
> = ({
  disabled,
  id,
  invalid,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  tabIndex,
  type = InputType.TEXT,
  valid,
  value,
}) => {
  const [state, setState] = useState(value || value === 0 ? value : "");
  const input = useRef<HTMLInputElement | null>(null);
  const platform = useContext(PlatformContext);
  const { inputMask, ...inputProps } = useMemo(
    () =>
      getMask({
        max: max,
        min: min,
        platform: platform,
        type: type,
      }),
    [max, min, platform, type],
  );
  const mount = () => {
    inputProps.onMount && inputProps.onMount(input);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mount, []);
  useEffect(() => {
    setState((state) => {
      return value === Number(state) ? state : value;
    });
  }, [value]);
  useEffect(() => {
    if (inputMask && input && input.current && !input.current.inputmask) {
      Inputmask(inputMask).mask(input.current);
    }
  }, [input, inputMask]);
  let className = cx("input", {
    "input--disabled": disabled,
    "input--invalid": !disabled && invalid,
    "input--valid": !disabled && valid && typeof value === "number",
    "input--currency":
      type === InputType.CURRENCY || type === InputType.CURRENCY_ALLOW_NEGATIVE,
    "input--percentage":
      type === InputType.PERCENTAGE || type === InputType.PERCENTAGE_INTEGER,
  });
  return (
    <div className={className}>
      {(type === InputType.PERCENTAGE ||
        type === InputType.PERCENTAGE_INTEGER) && (
        <span className={styles["input__text"]}>
          {state
            ? state
            : inputProps.placeholder
              ? inputProps.placeholder
              : "0"}
        </span>
      )}
      <input
        autoComplete="chrome-off"
        disabled={disabled}
        id={id}
        inputMode={inputProps.inputMode}
        maxLength={inputProps.maxLength ? inputProps.maxLength : maxLength}
        name={name}
        pattern={inputProps.pattern}
        placeholder={
          inputProps.placeholder ? inputProps.placeholder : placeholder
        }
        onBlur={inputProps.onBlur ? inputProps.onBlur(onBlur) : onBlur}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setState(event.currentTarget.value);
          onChange && handleChangeNumber(event, onChange);
        }}
        onFocus={inputProps.onFocus ? inputProps.onFocus(onFocus) : onFocus}
        onInput={inputProps.onInput ? inputProps.onInput(onInput) : onInput}
        onKeyDown={
          inputProps.onKeyDown ? inputProps.onKeyDown(onKeyDown) : onKeyDown
        }
        ref={input}
        tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
        type={inputProps.type}
        value={state}
      />
    </div>
  );
};

export type InputNumberProps = {
  disabled?: boolean;
  id?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  name: string;
  placeholder?: string;
  tabIndex?: number;
  type?: InputType;
};

const InputNumber: React.FunctionComponent<InputNumberProps> = ({
  disabled,
  id,
  max,
  maxLength,
  min,
  name,
  placeholder,
  tabIndex,
  type,
}) => {
  const [field, meta] = useField(name);
  const { name: fieldName, onBlur, onChange, value } = field;
  const invalid = meta.error && meta.touched ? true : false;
  const valid = !meta.error && meta.touched && meta.value ? true : false;
  return (
    <InputNumberPrimitive
      disabled={disabled}
      id={id}
      max={max}
      maxLength={maxLength}
      min={min}
      placeholder={placeholder}
      tabIndex={tabIndex}
      type={type}
      name={fieldName}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      invalid={invalid}
      valid={valid}
    />
  );
};

export default InputNumber;
