import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormikProps } from "formik";

import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import styles from "./styles.module.scss";
import logger from "../../../logger";
import { AnswersMap } from "modules/workflow/types/Answer";
import { IconType } from "components/Icons";

export type ControlBarProps = {
  beforeLeave?: (values: AnswersMap | undefined) => any;
  customActions?: JSX.Element | JSX.Element[];
  forceFullPageSubmitOnForward?: boolean;
  formikBag?: FormikProps<AnswersMap>;
  isActionPending?: boolean;
  onBack?: () => any;
  onExit?: () => any;
  onNext?: () => any;
  onSave?: (isDelta: boolean) => Promise<any>;
  saveOnNavigate?: boolean;
  showBackButton?: boolean;
  showExitButton?: boolean;
  showSubmitButton?: boolean;
  showNextButton?: boolean;
  showSaveAndExitButton?: boolean;
  showSaveButton?: boolean;
  saveButtonLabel?: string;
  submitButtonLabel?: string;
};

enum NavigationDirection {
  FORTH,
  BACK,
  EXIT,
}

const ControlBar: React.FunctionComponent<ControlBarProps> = ({
  beforeLeave,
  customActions,
  forceFullPageSubmitOnForward = true,
  formikBag,
  onBack,
  onExit,
  onNext,
  onSave,
  saveOnNavigate = true,
  showBackButton,
  showExitButton,
  showSubmitButton,
  showNextButton,
  showSaveAndExitButton,
  showSaveButton,
  saveButtonLabel,
  submitButtonLabel,
}) => {
  const [isPendingSave, setIsPendingSave] = useState(false);
  const [isPendingNavigation, setIsPendingNavigation] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = useCallback(
    async (
      callbackFn?: () => any,
      submitOnlyTouchedValues: boolean = false,
    ) => {
      try {
        setIsPendingSave(true);
        onSave && (await onSave(submitOnlyTouchedValues));
        callbackFn && callbackFn();
      } catch (e) {
        logger.debug("Form submission inerrupted (submit):", e);
      }
      if (isMounted.current) {
        setIsPendingSave(false);
      }
    },
    [onSave],
  );

  const handleSubmitForm = useCallback(() => submitForm(), [submitForm]);

  const handleNavigation = useCallback(
    async (
      direction: NavigationDirection | null,
      formikProps?: FormikProps<AnswersMap>,
      callbackFn?: () => any,
    ) => {
      setIsPendingNavigation(true);
      try {
        if (beforeLeave) {
          await beforeLeave(formikProps?.values);
        }
      } catch (e) {
        logger.debug("Form navigation interrupted (before leave):", e);
      }

      if (saveOnNavigate) {
        const submitOnlyTouchedValues =
          direction === NavigationDirection.BACK ||
          direction === NavigationDirection.EXIT ||
          !forceFullPageSubmitOnForward;
        await submitForm(callbackFn, submitOnlyTouchedValues);
      } else {
        callbackFn && (await callbackFn());
      }
      if (isMounted.current) {
        setIsPendingNavigation(false);
      }
    },
    [beforeLeave, forceFullPageSubmitOnForward, saveOnNavigate, submitForm],
  );

  const handleSaveAndExit = useCallback(
    () => handleNavigation(NavigationDirection.EXIT, formikBag, onExit),
    [formikBag, handleNavigation, onExit],
  );

  const handleBack = useCallback(
    () => handleNavigation(NavigationDirection.BACK, formikBag, onBack),
    [formikBag, handleNavigation, onBack],
  );

  const handleForward = useCallback(
    () => handleNavigation(NavigationDirection.FORTH, formikBag, onNext),
    [formikBag, handleNavigation, onNext],
  );

  return (
    <>
      {(showSaveButton ||
        showSaveAndExitButton ||
        showBackButton ||
        showNextButton ||
        showSubmitButton ||
        showExitButton ||
        customActions) && (
        <footer className={styles.control}>
          <div className={styles["control__container"]}>
            {showSaveAndExitButton && (
              <TabbedButton
                ariaLabel="Save and exit"
                label={saveButtonLabel || "Save and exit"}
                disabled={isPendingNavigation}
                onClick={handleSaveAndExit}
                style={TabbedButtonType.SECONDARY}
                icon={IconType.EXIT}
                tabIndex={-1}
              />
            )}
            {showSaveButton && (
              <TabbedButton
                ariaLabel="Save"
                label={isPendingSave ? "Saving..." : saveButtonLabel || "Save"}
                onClick={handleSubmitForm}
                disabled={isPendingSave}
                style={TabbedButtonType.SECONDARY}
                tabIndex={-1}
              />
            )}
            {showExitButton && (
              <TabbedButton
                ariaLabel="Exit"
                label={"Exit"}
                onClick={onExit}
                style={TabbedButtonType.SECONDARY}
                icon={IconType.EXIT}
                tabIndex={-1}
              />
            )}
            {(showBackButton ||
              showNextButton ||
              showSubmitButton ||
              customActions) && (
              <div className={styles["control__nav"]}>
                {showBackButton && (
                  <TabbedButton
                    ariaLabel="Previous step"
                    label="Previous step"
                    onClick={handleBack}
                    disabled={isPendingNavigation}
                    style={TabbedButtonType.SECONDARY}
                    tabIndex={-1}
                  />
                )}
                {showNextButton && (
                  <TabbedButton
                    ariaLabel="Next step"
                    label={isPendingNavigation ? "Please wait" : "Next step"}
                    disabled={isPendingNavigation}
                    onClick={handleForward}
                    style={TabbedButtonType.PRIMARY}
                    tabIndex={0}
                  />
                )}
                {showSubmitButton && (
                  <TabbedButton
                    ariaLabel={submitButtonLabel || "Submit"}
                    label={submitButtonLabel || "Submit"}
                    onClick={handleSubmitForm}
                    disabled={isPendingSave}
                    style={TabbedButtonType.PRIMARY}
                    tabIndex={-1}
                  />
                )}
                {customActions}
              </div>
            )}
          </div>
        </footer>
      )}
    </>
  );
};

export default ControlBar;
