import * as React from "react";
import { useId } from "react-id-generator";
import Textarea from "components/Forms/Inputs/Textarea";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

type QuestionTextareaProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  maxLength?: number;
  maxRows?: number;
  name: string;
  placeholder?: string;
  resize?: boolean;
  rows?: number;
  size?: QuestionSizes;
  tabIndex?: number;
};

const QuestionTextarea: React.FunctionComponent<QuestionTextareaProps> = (
  props,
) => {
  const [questionId] = useId(1, "question-");
  return (
    <QuestionWrapper
      className="question--textarea"
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={props.size ? props.size : QuestionSizes.MEDIUM}
    >
      <Textarea
        disabled={props.disabled}
        id={questionId}
        maxLength={props.maxLength}
        maxRows={props.maxRows}
        name={props.name}
        placeholder={props.placeholder}
        resize={props.resize}
        rows={props.rows}
        tabIndex={props.tabIndex}
      />
    </QuestionWrapper>
  );
};

export default QuestionTextarea;
