import * as React from "react";

import styles from "./styles.module.scss";

const Tel: React.FunctionComponent = ({ children }) => {
  return (
    <a className={styles.tel} href={`tel:${children}`}>
      {children}
    </a>
  );
};

export default Tel;
