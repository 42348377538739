import LoadingOverlay from "components/LoadingOverlay";
import { isAdmin } from "modules/auth/helper";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { startProductAdvisor } from "modules/product/actions";
import ProductSelector from "modules/product/components/ProductSelector";
import Plan from "modules/product/types/Plan";
import Product from "modules/product/types/Product";
import {
  createNewWorkflowWithPlan,
  createNewWorkflowWithProduct,
} from "modules/workflow/actions";
import { isUserInUnsupportedState } from "modules/workflow/helpers/isUserInSupportedState";
import React, { useCallback } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import StateNotSupportedScreen from "screens/stateNotSupported/StateNotSupportedScreen";
import { useSelector } from "redux/reducer";

const ProductSelectorScreenContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    user,
    availableWorkflows,
    isLoadingPartner,
    isLoadingProducts,
    partner,
  } = useSelector((state) => state, shallowEqual);

  const handleProductSelected = useCallback(
    (workflowId: string, product: Product) =>
      dispatch(
        startProductAdvisor({
          workflowId,
          fromUrl: location.pathname,
          productId: product.id,
        }),
      ),
    [dispatch, location.pathname],
  );

  const handleBasicProductSelected = useCallback(
    (workflowId: string, product: Product) =>
      dispatch(
        createNewWorkflowWithProduct(user!, workflowId, product, user!.state!),
      ),
    [dispatch, user],
  );

  const handlePlanSelected = useCallback(
    (workflowId: string, plan: Plan) =>
      dispatch(
        startProductAdvisor({
          workflowId,
          fromUrl: location.pathname,
          planId: plan.id,
        }),
      ),
    [dispatch, location.pathname],
  );

  const handleBasicPlanSelected = useCallback(
    (workflowId: string, plan: Plan) =>
      dispatch(
        createNewWorkflowWithPlan(user!, workflowId, plan, user!.state!),
      ),
    [dispatch, user],
  );

  const handleStartAdvisor = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.ADVISOR),
    [dispatch],
  );

  return isLoadingPartner || isLoadingProducts ? (
    <LoadingOverlay />
  ) : !isAdmin(partner?.id, user) &&
    isUserInUnsupportedState(user?.state, partner?.data.jurisdiction) ? (
    <StateNotSupportedScreen />
  ) : (
    <ProductSelector
      // hasAdvisor={Boolean(partner?.onboardingQuestionnaire)}
      onSelectBasicPlan={handleBasicPlanSelected}
      onSelectBasicProduct={handleBasicProductSelected}
      onSelectPlan={handlePlanSelected}
      onSelectProduct={handleProductSelected}
      onStartAdvisor={handleStartAdvisor}
      workflowDefinitions={availableWorkflows || {}}
      partnerName={partner?.data.displayName}
      partnerId={partner?.id}
    />
  );
};

export default ProductSelectorScreenContainer;
