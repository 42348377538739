import React from "react";
import { User } from "modules/auth/User";
import Workflow from "modules/workflow/types/Workflow";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";

import PageHeader from "components/PageHeader";
import ClientDashboard from "./components/ClientDashboard";
import ClientDashboardContacts from "./components/ClientDashboardContacts";
import ClientDashboardProducts from "./components/ClientDashboardProducts";
import Partner from "modules/parties/types/Partner";

type WorkflowScreenProps = {
  assignedWorkflows: Workflow[];
  availableWorkflows: Record<
    string,
    DefinitionWithProductsPlansAndAddons
  > | null;
  onOpenWorkflow: (
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) => any;
  onTriggerNewWorkflow: () => any;
  onViewWorkflowDocuments: (workflow: Workflow) => any;
  onViewWorkflowParties: (workflow: Workflow) => any;
  onViewWorkflowSummary: (workflow: Workflow) => any;
  partnerDetails: Partner | null;
  triggerContactLawyer: () => Promise<any>;
  user: User | null;
};

const WorkflowScreen = ({
  assignedWorkflows,
  availableWorkflows,
  onOpenWorkflow,
  onTriggerNewWorkflow,
  onViewWorkflowDocuments,
  onViewWorkflowParties,
  onViewWorkflowSummary,
  partnerDetails,
  triggerContactLawyer,
  user,
}: WorkflowScreenProps) => {
  const time = new Date().getHours();
  const message =
    time < 12 ? "Good morning" : time < 18 ? "Good afternoon" : "Good evening";
  return (
    <div data-tid="client-dashboard">
      <PageHeader
        title={user?.displayName ? `${message}, ${user.displayName}` : message}
        maskTitle={true}
      />
      <ClientDashboard>
        <div>
          <ClientDashboardProducts
            assignedWorkflows={assignedWorkflows}
            availableWorkflows={availableWorkflows}
            onOpenWorkflow={onOpenWorkflow}
            onTriggerNewWorkflow={onTriggerNewWorkflow}
            onViewWorkflowParties={onViewWorkflowParties}
            onViewWorkflowDocuments={onViewWorkflowDocuments}
            onViewWorkflowSummary={onViewWorkflowSummary}
            partnerActive={
              partnerDetails != null ? !partnerDetails.archived : false
            }
            userState={user?.state}
          />
        </div>
        <div>
          {partnerDetails && (
            <ClientDashboardContacts
              partnerDetails={partnerDetails}
              onContactLawyer={triggerContactLawyer}
              user={user}
            />
          )}
        </div>
      </ClientDashboard>
    </div>
  );
};

export default WorkflowScreen;
