import { PartyID } from "modules/parties/types/Party";
import {
  QuestionID,
  QuestionnaireID,
} from "modules/workflow/types/Questionnaire";

export enum ErrorType {
  ACTION_NOT_ALLOWED = "action-not-allowed",
  INVALID_PROMO_CODE = "invalid-promo-code",
  PAYMENT_ILLEGAL_STATUS = "payment-illegal-status",
  MEDICAID_PARTNER_DEFAULTS_NOT_FOUND = "medicaid-partner-defaults-not-found",
  MEDICAID_VARIABLES_NOT_FOUND = "medicaid-variables-not-found",
}

export enum ErrorDetailType {
  SIMPLE = "Simple",
  PER_PARTY = "PerParty",
}

export type SimpleErrorDetail = {
  type: ErrorDetailType.SIMPLE;
  description: string;
};

export type PerPartyErrorDetail = {
  type: ErrorDetailType.PER_PARTY;
  partyId: PartyID;
  questionId: QuestionID;
  error: ErrorDetail;
};

export type ErrorDetail = SimpleErrorDetail | PerPartyErrorDetail;

export type ErrorDetailsMap = Record<
  QuestionnaireID,
  Record<QuestionID, ErrorDetail | ErrorDetail[]>
>;

export type BadRequestResponsePayload = {
  short: ErrorType;
  detail: string;
  additionalData?: {
    details?: ErrorDetailsMap;
  };
};
