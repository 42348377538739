/**
 * Note:
 *
 * Static page IDs were extracted into a separate file to prevent actions
 * from importing the whole static page (and all its imports down the dependency
 * chain) when only the ID is needed, which caused an error in unit tests.
 */

export const ADDONS_PAGE_ID = "addons";
export const ASSEMBLE_DOCUMENTS_PAGE_ID = "assembleDocs";
export const SUMMARY_PAGE_ID = "summary";
export const MEDICAID_LEAD_SUMMARY_PAGE_ID = "medicaid-lead-summary";
