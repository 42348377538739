import React from "react";
import { useField } from "formik";
import Addon from "modules/product/types/Addon";
import { AddonChoices } from "modules/workflow/types/Questionnaire";
import AddonWithChoices from "./AddonWithChoices";

type QuestionAddonProps = {
  addon: Addon;
  choices: AddonChoices;
  name: string;
  isSecondaryWorkflow?: boolean;
  disabled?: boolean;
  tabIndex?: number;
};

const QuestionAddon: React.FunctionComponent<QuestionAddonProps> = ({
  addon,
  choices,
  name,
  disabled,
  tabIndex,
  isSecondaryWorkflow,
}) => {
  const [, meta, helpers] = useField(name);

  const handleOn = () => helpers.setValue(meta.value === true ? null : true);
  const handleOff = () => helpers.setValue(meta.value === false ? null : false);

  const props = {
    choiceOff: choices.off,
    choiceOn: choices.on,
    disabled,
    handleOff,
    handleOn,
    invalid: Boolean(meta.error),
    price: isSecondaryWorkflow ? addon.price.coupled : addon.price.regular,
    tabIndex,
    value: meta.value,
  };

  return <AddonWithChoices {...props} />;
};

export default QuestionAddon;
