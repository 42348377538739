type Address = {
  id: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  adminAreaName?: string;
};

export const getFullAddress = (address?: Address): string =>
  [
    address?.line1,
    address?.line2,
    address?.city,
    // address?.adminAreaName,
    [address?.state, address?.zip].filter(Boolean).join(" "),
  ]
    .filter(Boolean)
    .join(", ");

export default Address;

export type StoredAddress = {
  type: "Address";
  address: Address;
};
