import logger from "logger";
import { callOauthCallback, loadPartnerDetails } from "modules/partner/actions";
import React, { FC, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useSelector } from "redux/reducer";
import { shallowEqual, useDispatch } from "react-redux";
import LoadingOverlay from "components/LoadingOverlay";
import { redirect } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { isAdmin } from "modules/auth/helper";
import parseParams from "utils/parseUrlParams";

const ActionStepOauth: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { user, partner } = useSelector((state) => state, shallowEqual);
  const calledOnce = useRef(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    loadPartnerDetails()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const admin = isAdmin(partner?.id, user);
    if (partner && admin && isAuthenticated) {
      redirect(dispatch, ROUTE_TEMPLATE.SITE_CONFIGURATION);
    }

    if (partner && user && !admin) {
      redirect(dispatch, ROUTE_TEMPLATE.PARTNER_ROOT);
    }
  }, [partner, user, isAuthenticated, dispatch]);

  useEffect(() => {
    const admin = isAdmin(partner?.id, user);
    if (!admin || calledOnce.current) return;

    const { code } = parseParams(location.search);
    callOauthCallback(code)
      .then((res: Boolean) => {
        logger.debug(res);
        setIsAuthenticated(true);
        calledOnce.current = true;
      })
      .catch(logger.error);
  }, [location.search, partner, user]);

  return <LoadingOverlay />;
};

export default ActionStepOauth;
