import { callGetPartnerDefinitionsAPI } from "modules/admin/actions";
import {
  Definition,
  DEFINITION_CATEGORY_ADDONS,
  DEFINITION_CATEGORY_PLANS,
  DEFINITION_CATEGORY_PRODUCTS,
} from "modules/api/responseTypes/DefinitionResponseType";
import useSWR from "swr";

// This function is used to set any addon with an undefined 'enabled' field to true
const defaultAddonsEnabledToTrue = (definition: Definition) =>
  ({
    ...definition,
    data: {
      ...definition.data,
      [DEFINITION_CATEGORY_ADDONS]: Object.fromEntries(
        Object.entries(definition.data[DEFINITION_CATEGORY_ADDONS]).map(
          ([addonId, addon]) => [
            addonId,
            {
              ...addon,
              enabled: addon.enabled !== false,
            },
          ],
        ),
      ),
    },
  }) as Definition;

const fetchFunction = async () => {
  const data = await callGetPartnerDefinitionsAPI();
  return {
    definition: defaultAddonsEnabledToTrue(data.definitions[0]),
    descriptions: {
      products: data[DEFINITION_CATEGORY_PRODUCTS],
      addons: data[DEFINITION_CATEGORY_ADDONS],
      plans: data[DEFINITION_CATEGORY_PLANS],
    },
    addonJurisdictions: data.addonJurisdictions,
  };
};

// partner id is only used as key to keep the fetches separate
// request header is filled in from url as usual
const useDefinition = (partnerId: string) => {
  const { data, error, mutate } = useSWR(
    ["useDefinition", partnerId],
    fetchFunction,
  );

  return {
    data,
    error,
    refetchDefinition: mutate,
  };
};

export default useDefinition;
