import * as React from "react";

import { ReactComponent as LogoSVG } from "resources/logos/Aturna_Brandmark_RGB_White.svg";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";

type HeaderLogoProps = {
  onClick: () => any;
};

const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = ({ onClick }) => {
  return (
    <TabbedButton
      tabIndex={0}
      className={styles["header-logo"]}
      onClick={onClick}
    >
      <LogoSVG aria-label="Logo" />
      Home
    </TabbedButton>
  );
};

export default HeaderLogo;
