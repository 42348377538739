import BackLink from "components/BackLink";
import LoadingOverlay from "components/LoadingOverlay";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import usePartner from "../hooks/usePartner";
import { partnerAdminRoutes } from "../routes";

type Props = { partnerId: string; screen: string };

const PartnersAdminDetailScreen: React.FC<Props> = ({
  partnerId,
  screen: screenId,
}) => {
  const dispatch = useDispatch();

  const { partner, error } = usePartner(partnerId);

  const screen = partnerAdminRoutes[screenId];

  if (!partner) return <LoadingOverlay />;
  if (error) return <Redirect to={ROUTE_TEMPLATE.ADMIN_PARTNERS} />;
  if (!screen)
    return <Redirect to={getRoute(ROUTE_TEMPLATE.ADMIN_PARTNERS_PARTNER)} />;

  return (
    <div>
      <BackLink
        onClick={() => {
          return go(dispatch, ROUTE_TEMPLATE.ADMIN_PARTNERS_PARTNER);
        }}
        text={`Back to ${partner.data.displayName}`}
      />
      {screen.render(partner)}
    </div>
  );
};

export default PartnersAdminDetailScreen;
