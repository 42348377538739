import * as React from "react";
import classNames from "classnames/bind";
import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";
import Link from "components/Link";

const cx = classNames.bind(styles);

export type ConfirmExtraAction =
  | { label: string; onTrigger: () => void; style?: React.CSSProperties }
  | (() => JSX.Element);

export type ConfirmProps = {
  extraAction?: ConfirmExtraAction;
  initialValue?: boolean;
  label: string;
  maskLabel?: boolean;
  onValueChange?: (newValue: boolean) => void;
  value?: boolean;
};

const Confirm: React.FC<ConfirmProps> = ({
  extraAction,
  initialValue,
  label,
  maskLabel,
  value,
  onValueChange,
}) => {
  const [uncontrolledValue, setUncontrolledValue] = React.useState<boolean>(
    Boolean(initialValue),
  );

  const handleClick = React.useCallback(() => {
    const nextValue = value === undefined ? !uncontrolledValue : !value;

    if (value === undefined) setUncontrolledValue(nextValue);
    onValueChange && onValueChange(nextValue);
  }, [onValueChange, uncontrolledValue, value]);

  const classNames = cx("confirm", {
    "confirm--selected": value === undefined ? uncontrolledValue : value,
  });

  return (
    <TabbedButton
      className={classNames}
      onClick={handleClick}
      label={label}
      maskLabel={maskLabel}
      role="checkbox"
    >
      {extraAction ? (
        typeof extraAction === "function" ? (
          extraAction()
        ) : (
          <Link
            className={cx("confirm__action")}
            style={extraAction.style}
            onClick={extraAction.onTrigger}
          >
            {extraAction.label}
          </Link>
        )
      ) : null}
    </TabbedButton>
  );
};

export default Confirm;
