import { Middleware } from "@reduxjs/toolkit";
import { History } from "history";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";

import createThemeMiddleware from "modules/theme/middleware";
import createNavigationMiddleware from "modules/navigation/middleware";
import createAuthMiddleware from "modules/auth/middleware";
import createWorkflowMiddleware from "modules/workflow/middleware";
import createDocumentsMiddleware from "modules/workflow/documents/middleware";
import createPartnerMiddleware from "modules/partner/middleware";
import createAdminMiddleware from "modules/admin/middleware";
import createProductMiddleware from "modules/product/middleware";
import createPaymentMiddleware from "modules/payment/middleware";
import createAgreementMiddleware from "modules/workflow/agreement/middleware";
import createAdvisorMiddleware from "modules/advisor/middleware";
import createWorkflowReviewMiddleware from "modules/workflow/review/middleware";

const initMiddlewares = (history: History): Array<Middleware> => {
  const middlewares = [
    thunk,
    createThemeMiddleware(),
    createPartnerMiddleware(),
    createAdminMiddleware(),
    routerMiddleware(history),
    createNavigationMiddleware(),
    createAuthMiddleware(),
    createWorkflowMiddleware(),
    createWorkflowReviewMiddleware(),
    createAgreementMiddleware(),
    createDocumentsMiddleware(),
    createProductMiddleware(),
    createPaymentMiddleware(),
    createAdvisorMiddleware(),
  ];
  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }
  return middlewares;
};

export default initMiddlewares;
