import { ModalControls, ModalType, showModal } from "components/Modal";
import { keyBy } from "lodash";
import Partner from "modules/parties/types/Partner";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { AnswersMap, AnswerValueType } from "modules/workflow/types/Answer";
import { Question, QuestionType } from "modules/workflow/types/Questionnaire";
import React, { useState } from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";

type StateEntryModalFormContentProps = {
  getSubmitHandle?: (submitHandle: () => any) => void;
  initialValue?: string;
  onStateChanged: (state: string) => void;
  controls: ModalControls;
};

const stateEntryQuestions: Question[] = [
  {
    id: "state",
    data: {
      descriptions: [{ label: "" }],
      required: true,
      definition: { type: QuestionType.STATE, maxSuggestedItems: 51 },
    },
  },
];

export const checkStateSupport = (
  state: AnswerValueType,
  partner: Partner | null,
) => {
  if (typeof state !== "string") return true;
  if (state === "") return true;
  return !!partner?.data.jurisdiction?.includes(state);
};

const StateEntryModalFormContent: React.FC<StateEntryModalFormContentProps> = ({
  getSubmitHandle,
  initialValue,
  onStateChanged,
  controls,
}) => {
  const { partner } = useSelector((state) => state, shallowEqual);
  const [isStateSupported, setIsStateSupported] = useState(() =>
    checkStateSupport(initialValue, partner),
  );
  const QUESTIONNAIRE_ID = "state_picker";

  const handleSave = async (_: any, values: AnswersMap) => {
    const { state } = values || {};
    controls.busy(true);
    state && state !== initialValue && (await onStateChanged(String(state)));
    controls.close();
  };

  return (
    <>
      <QuestionnaireForm
        onChange={(change) => {
          const {
            [QUESTIONNAIRE_ID]: { state },
          } = change;
          setIsStateSupported(checkStateSupport(state, partner));
        }}
        getSubmitHandle={getSubmitHandle}
        onSavePageAnswers={handleSave}
        customFormProps={{ showSaveAndExitButton: false }}
        questionsBySubquestionnaire={{
          [QUESTIONNAIRE_ID]: keyBy(stateEntryQuestions, "id"),
        }}
        answers={{
          [QUESTIONNAIRE_ID]: { state: initialValue },
        }}
        questionnairePages={[
          {
            id: "default_page",
            questionnaireId: QUESTIONNAIRE_ID,
            caption: "",
            sections: [
              {
                caption: "",
                questions: ["state"],
              },
            ],
          },
        ]}
      />
      {partner && !isStateSupported && (
        <div style={{ maxWidth: 349 }}>
          <br />
          Unfortunately this partner doesn't service your chosen state. Please
          change your state or choose a different partner.
        </div>
      )}
    </>
  );
};

export const showStateEntryModal = (
  onSave: (state: string) => void,
  currentState?: string,
) => {
  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleFormSubmit = async () =>
    submitHandle ? submitHandle() : undefined;

  showModal({
    id: "state_entry",
    title: "Please select your state",
    type: ModalType.CONFIRM,
    cancellable: Boolean(currentState),
    onConfirm: handleFormSubmit,
    renderContent: (controls: ModalControls) => (
      <StateEntryModalFormContent
        getSubmitHandle={getSubmitHandle}
        onStateChanged={onSave}
        initialValue={currentState}
        controls={controls}
      />
    ),
  });
};
