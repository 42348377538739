import { Box, Grid, Typography } from "@mui/material";
import DataLayerContext from "contexts/DataLayerContext";
import {
  getItemListName,
  getEcommerceItemName,
  getEcommerceItemIdx,
  getEcommerceItemCategory,
} from "contexts/DataLayerContext/helpers";
import {
  EventName,
  ItemListID,
  EcommerceItemID,
  EcommerceItemCategory,
  CTALocation,
  CTAServiceName,
  CTAType,
} from "contexts/DataLayerContext/types";
import {
  buildEcommerceItemsArray,
  getDefinition,
  getEnabledProductsAndPlans,
  getLandingButtonLabels,
  getProductAndPlanIds,
  shouldShowProductsHeading,
} from "modules/landing/helpers";
import { go } from "modules/navigation/helpers/navigator";
import {
  ROUTE_TEMPLATE,
  getPartnerRoute,
  getRoute,
} from "modules/navigation/routes";
import { PartnerID } from "modules/parties/types/Partner";
import React, { useContext, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "redux/reducer";
import ProductOffering from "../ProductOffering";
import { IconType } from "components/Icons";

type ProductsProps = {
  partnerId: PartnerID | undefined;
  scrollToOptions: () => void;
};

const Products: React.FC<ProductsProps> = ({ partnerId, scrollToOptions }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { availableWorkflows, isLoadingProducts } = useSelector(
    (state) => ({
      availableWorkflows: state.availableWorkflows,
      isLoadingProducts: state.isLoadingProducts,
    }),
    shallowEqual,
  );
  const { sendEvent } = useContext(DataLayerContext);

  const definition = getDefinition(availableWorkflows, partnerId);

  useEffect(() => {
    if (
      partnerId !== undefined &&
      !isLoadingProducts &&
      (location?.hash === "#essentialDocuments" ||
        (location.state as Record<string, any> | undefined)?.from ===
          ROUTE_TEMPLATE.SEARCH)
    ) {
      scrollToOptions();
    }
  }, [
    isLoadingProducts,
    location?.hash,
    location.state,
    partnerId,
    scrollToOptions,
  ]);

  useEffect(() => {
    // Appends the view item list event to the data layer.
    if (partnerId === undefined) {
      // National Page
      sendEvent?.({
        event: EventName.ViewItemList,
        item_list_id: ItemListID.NationalLanding,
        item_list_name: getItemListName(ItemListID.NationalLanding),
        items: [
          {
            item_id: EcommerceItemID.WILL,
            item_name: getEcommerceItemName(EcommerceItemID.WILL),
            index: 0,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
          {
            item_id: EcommerceItemID.HCP,
            item_name: getEcommerceItemName(EcommerceItemID.HCP),
            index: 1,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
          {
            item_id: EcommerceItemID.POA,
            item_name: getEcommerceItemName(EcommerceItemID.POA),
            index: 2,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
        ],
      });
    } else if (definition !== undefined) {
      sendEvent?.({
        event: EventName.ViewItemList,
        item_list_id: ItemListID.PartnerLanding,
        item_list_name: getItemListName(ItemListID.PartnerLanding),
        items: buildEcommerceItemsArray(
          definition,
          ItemListID.PartnerLanding,
          partnerId,
        ),
      });
    }
  }, [definition, partnerId, sendEvent]);

  const sendSelectItemEvent = (ecommerceItemId: EcommerceItemID) => {
    if (ecommerceItemId !== undefined) {
      if (partnerId === undefined) {
        sendEvent?.({
          event: EventName.SelectItem,
          item_list_id: ItemListID.NationalLanding,
          item_list_name: getItemListName(ItemListID.NationalLanding),
          items: [
            {
              item_id: ecommerceItemId,
              item_name: getEcommerceItemName(ecommerceItemId),
              index: getEcommerceItemIdx(ecommerceItemId, {
                [EcommerceItemID.WILL]: 0,
                [EcommerceItemID.HCP]: 1,
                [EcommerceItemID.POA]: 2,
              }),
              item_brand: partnerId,
              item_category: getEcommerceItemCategory(ecommerceItemId),
              item_list_id: ItemListID.NationalLanding,
              item_list_name: getItemListName(ItemListID.NationalLanding),
              quantity: 1,
            },
          ],
        });
      } else {
        const ecommerceItem = buildEcommerceItemsArray(
          definition,
          ItemListID.PartnerLanding,
          partnerId,
        ).find((item) => item.item_id === ecommerceItemId);

        ecommerceItem !== undefined &&
          sendEvent?.({
            event: EventName.SelectItem,
            item_list_id: ItemListID.PartnerLanding,
            item_list_name: getItemListName(ItemListID.PartnerLanding),
            items: [ecommerceItem],
          });
      }
    }
  };

  const {
    will: willPrice,
    hcp: hcpPrice,
    poa: poaPrice,
  } = getLandingButtonLabels(definition);

  const {
    will: willEnabled,
    hcp: hcpEnabled,
    poa: poaEnabled,
  } = getEnabledProductsAndPlans(definition);

  const {
    will: willId,
    hcp: hcpId,
    poa: poaId,
  } = getProductAndPlanIds(definition);

  const showProductsHeading = shouldShowProductsHeading(definition);

  const handleNavigateToProductTailoring =
    (
      buttonLabel: string,
      ctaServiceName: CTAServiceName,
      productId?: string,
      ecommerceItemId?: EcommerceItemID,
    ) =>
    () => {
      sendEvent?.({
        event: EventName.CTAClick,
        cta_type: CTAType.SERVICE_BUTTON,
        cta_location: CTALocation.LIST_SERVICES,
        cta_service_name: ctaServiceName,
        cta_text: buttonLabel,
      });

      ecommerceItemId && sendSelectItemEvent(ecommerceItemId);

      partnerId && definition && productId
        ? go(
            dispatch,
            getPartnerRoute(partnerId, ROUTE_TEMPLATE.PRODUCT_TAILORING, {
              workflowId: definition.id,
              productId,
            }),
          )
        : go(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));
    };

  if (!showProductsHeading) return null;

  return (
    <Box>
      <Typography variant="h3">Products</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={4}>
          <ProductOffering
            title={"Last Will & Testament"}
            description="Provide your instructions and appoint your agents for after
                    your death."
            handleSelect={handleNavigateToProductTailoring(
              willPrice,
              CTAServiceName.WILL,
              willId,
              EcommerceItemID.WILL,
            )}
            price={willPrice}
            enabled={willEnabled}
            icon={IconType.ADD_WILL}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProductOffering
            title={"Healthcare Directive"}
            description="Make your healthcare wishes known and appoint a healthcare
                    agent."
            handleSelect={handleNavigateToProductTailoring(
              hcpPrice,
              CTAServiceName.HCP,
              hcpId,
              EcommerceItemID.HCP,
            )}
            price={hcpPrice}
            enabled={hcpEnabled}
            icon={IconType.ADD_HEALTHCARE_DIRECTIVE}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ProductOffering
            title={"Power of Attorney"}
            description="Create instructions for your legal and financial decisions
                    and appoint an agent to act on your behalf."
            handleSelect={handleNavigateToProductTailoring(
              poaPrice,
              CTAServiceName.POA,
              poaId,
              EcommerceItemID.POA,
            )}
            price={poaPrice}
            enabled={poaEnabled}
            icon={IconType.ADD_POWER_OF_ATTORNEY}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Products;
