import mapValues from "lodash/mapValues";
import pickBy from "lodash/pickBy";
import {
  QuestionID,
  QuestionnaireID,
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";

export const filterMandatoryQuestions = (
  allQuestions: QuestionsMapByQuestionnaire,
  whitelistedIDs?: string[],
): Record<QuestionnaireID, string[]> =>
  mapValues(
    allQuestions,
    (questionsMap) =>
      Object.values(pickBy(questionsMap, (question) => question.data.required))
        .map((q) =>
          !whitelistedIDs || whitelistedIDs.includes(q.id) ? q.id : undefined,
        )
        .filter(Boolean) as string[],
  );

export default (
  questionIDsByQuestionnaire: Record<QuestionnaireID, QuestionID[]>,
  allQuestions: QuestionsMapByQuestionnaire,
): Record<QuestionnaireID, QuestionID[]> =>
  mapValues(questionIDsByQuestionnaire, (questionIDs, questionnaireId) =>
    questionIDs.filter((questionId) => {
      const question =
        allQuestions[questionnaireId] &&
        allQuestions[questionnaireId][questionId];
      return question?.data?.required;
    }),
  );
