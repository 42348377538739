import { sumBy } from "lodash";
import { OrderItemType, PaymentOrderItem } from "modules/api/types/Payment";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import Client from "modules/parties/types/Client";
import Partner from "modules/parties/types/Partner";
import { getFullName } from "modules/parties/types/Party";
import moment from "moment";
import * as React from "react";
import { STATES_MAP } from "resources/options/states";
import currencyFormatter from "utils/currencyFormatter";

import styles from "./styles.module.scss";

const PLATFORM_NAME = "Aturna";
const PlatformName = () => <span>{PLATFORM_NAME}&#8480;</span>;

export type EngagementAgreementProps = {
  clients: ClientsMap | undefined;
  jurisdiction: string;
  orderItems: PaymentOrderItem[] | undefined;
  partner: Partner | undefined;
  supervisedSigning: boolean;
  hasExternalProducts: boolean;
};

const renderClientDetails = (client?: Client) => {
  const state =
    (client?.address?.state && STATES_MAP[client.address.state]) || "";
  return client ? (
    <div className={styles["client-info"]}>
      <div>{getFullName(client)}</div>
      <div>
        {[
          client.address?.line1,
          client.address?.line2,
          client.address?.zip,
          client.address?.city,
        ]
          .filter(Boolean)
          .join(", ")}
      </div>
      <div>{state}</div>
    </div>
  ) : null;
};

const EngagementAgreement: React.FC<EngagementAgreementProps> = ({
  clients,
  jurisdiction,
  orderItems,
  partner,
  supervisedSigning,
  hasExternalProducts,
}) => {
  const totalPrice = sumBy(orderItems, "amount");

  return (
    <div className={styles["engagement-agreement"]}>
      <header>
        <div data-clarity-mask="true">
          {renderClientDetails(clients?.main)}
          {renderClientDetails(clients?.spouse)}
        </div>

        <div>{moment().format("MMMM D, YYYY")}</div>
      </header>

      <h2>Services Engaged For:</h2>
      <ul>
        {orderItems?.map((item, idx) =>
          item.type === OrderItemType.DISCOUNT ||
          item.type === OrderItemType.ADJUSTMENT ? null : (
            <li key={`${item.name}${idx}`}>{item.name}</li>
          ),
        )}
      </ul>

      {hasExternalProducts && (
        <small>
          <em>
            This engagement agreement covers the documents generated and paid
            for online through <PlatformName />. The Trust Upgrade will be
            separately through the law firm.
          </em>
        </small>
      )}

      <h2>Fee:</h2>
      <ul>
        {orderItems?.map((item, idx) => (
          <li key={`${item.name}${idx}`}>
            <span className={styles["engagement-agreement__price"]}>
              {currencyFormatter.format(item.amount)}
            </span>
            {item.name}
          </li>
        ))}
      </ul>

      <p>
        <label>Total:</label>{" "}
        <strong>
          {totalPrice ? currencyFormatter.format(totalPrice) : ""}
        </strong>
      </p>

      <small>
        <em>
          Please <strong>do not</strong> confirm payment if you do not
          understand this Limited Scope Engagement Agreement or do not
          understand the fee you are being asked to pay. Contact our office for
          further assistance or clarification.
        </em>
      </small>

      <p>
        Thank you for retaining {partner?.data.displayName || "N/A"} to provide
        estate planning legal documents, and services, elected by you through
        the <PlatformName /> online planning platform. This agreement becomes
        effective upon both parties (you and the law firm) accepting the
        engagement agreement. This agreement confirms the terms of our
        engagement to represent you. Unless otherwise agreed upon, this
        agreement shall only apply to online communications through the{" "}
        <PlatformName /> platform and any email or telephone calls during the
        the online planning process.{" "}
        <em>
          We are authorized subscribers to the <PlatformName /> platform and are
          not affiliated with them. We make no representation as to your use of
          its platform. This engagement agreement is limited to the legal
          services we will provide you in the completion of your estate planning
          documents with our law firm as provided on the <PlatformName /> online
          platform. You must review the Terms of Use, Privacy Policy and
          Planning Disclaimer provided on the <PlatformName /> platform and
          agree with them. We recommend you <u>not</u> proceed until you review
          and agree with them. By continuing and engaging us under this
          agreement, you are affirmatively agreeing to the <PlatformName />{" "}
          Terms of Use, Privacy Policy and Planning Disclaimer posted on their
          platform.
        </em>{" "}
        We are providing limited estate planning legal services to review the
        appropriateness of the legal documents provided by the <PlatformName />{" "}
        platform based on the information you provided.
      </p>

      <h2>I. Services Covered by Your Flat Planning Fee</h2>

      <p>
        We will provide competent legal services based on settled legal issues
        and standards of professionalism and the highest ethical standards for
        the limited engagement legal services being offered. The estate planning
        documents we review are outlined in the <PlatformName /> platform prior
        to you making the selection to purchase it. You may select additional
        features to your personal estate plan during the online planning process
        on the <PlatformName /> platform as it outlines them and offers them to
        you. If at any time you are not clear on what documents and legal
        service are being offered and what you are paying for, it is your
        responsibility to communicate that to us. Your failure to communicate
        any concerns will be interpreted by us that you understand what you have
        read and agreed to in the <PlatformName /> platform online planning
        process. By clicking and agreeing to the services and fees chosen on the{" "}
        <PlatformName /> platform and this engagement agreement, you authorize
        us to commence work on your behalf in accordance with the items you
        selected and information you provided. By agreeing to utilize the online
        planning process on the <PlatformName /> platform to create your estate
        planning documents with our review, you agree it guided you to make
        choices based on the information you provided and will complete your
        documents based on that information. While the <PlatformName />{" "}
        platform’s online planning process is designed to be similar to what you
        would receive from us had you come into our office to discuss the
        services offered online, it is not the same.This Limited Scope
        Engagement Agreement is limiting our service to reviewing the
        information you entered into the <PlatformName /> platform and ensuring
        the choices you made in the legal documents on the <PlatformName />{" "}
        platform are appropriate based on the personal information you entered.
        We are unable to provide you any advice regarding information or
        questions you have that are not specifically addressed or provided to us
        from information you entered on the <PlatformName /> platform.{" "}
        {supervisedSigning ? (
          <>
            In addition to the Limited Scope Engagement outlined above, you have
            opted for a "Law Firm Review and Supervised Signing" offered on the{" "}
            <PlatformName /> platform. This entitles you to a: (i) twenty (20)
            minute virtual, telephonic or in-person meeting with an attorney in
            our office to review our findings on the appropriateness of the
            documents you selected on the <PlatformName /> platform and identify
            issues or concerns you have that were not addressed by the platform,
            and (ii) access to law firm personnel to oversee the proper
            execution of the legal documents provided by the <PlatformName />{" "}
            platform.
          </>
        ) : (
          <>
            If you are uncomfortable relying on the <PlatformName /> platform
            and our limited review, we recommend you select the "Law Firm Review
            and Supervised Signing" option on the <PlatformName /> platform to
            have a virtual, telephonic or in-person meeting with an attorney in
            our office to discuss the issues or concerns you may have. By not
            selecting the Law Firm Review and Supervised Signing option, you
            agree to assume all risk and liability for matters that arise that
            we were not made aware of by the information provided by the{" "}
            <PlatformName /> platform.
          </>
        )}
      </p>

      {supervisedSigning ? (
        <>
          <p>
            The attorney will be able to change the options you selected when
            completing the <PlatformName /> online added will require your
            approval and additional payment, if necessary, after the conclusion
            of the planning process to the extent they will provide a solution
            to the issue you discuss. Any enhancements meeting. Any enhancements
            needed or recommended by the attorney related to questions you have
            that are not available on the <PlatformName /> platform will require
            a separate engagement agreement with our firm and are not covered by
            this Limited Scope Engagement Agreement.
          </p>
          <p>
            Upon your completion of the interview on the <PlatformName />{" "}
            platform, acknowledging your agreement to this engagement, and
            remittance of payment, <PlatformName /> will provide us the summary
            of all the information you provided and confirmed. Our office will
            complete a conflicts check in order to determine whether we can
            accept this engagement, and, upon acceptance, our office will will
            contact you within two (2) business days to schedule your attorney
            review and in-office execution of your plan.
          </p>
        </>
      ) : (
        <>
          <p>
            We highly recommend your online plan be reviewed by one of our
            attorneys with you personally and that you sign your documents under
            the supervision of our office. The amount of information you share
            on the online <PlatformName /> platform is limited. Conversation
            directly with the attorney can, in many cases, lead to things you
            may not have considered or that may not have been expressly
            identified on the <PlatformName /> platform. The online platform is
            designed to enable you to do self-help documents similar to what you
            would receive from non-lawyer platforms, but with the comfort of
            knowing it is being reviewed by us and that you have access to us
            under the terms and conditions offered during the online process,
            should you need it. The online <PlatformName /> platform permits you
            to decide the level of direct interaction from us you desire. We
            encourage you to contact us any time you are unclear on what the{" "}
            <PlatformName /> platform is providing you.
          </p>

          <p>
            Upon your completion of the interview on the <PlatformName />{" "}
            platform, confirming of this agreement, and remittance of payment,{" "}
            <PlatformName /> will provide us the summary of all the information
            you provided and confirmed. Upon our receipt, we will perform a
            conflicts check in order to determine whether we can accept this
            engagement, review your draft documents and exercise our independent
            professional judgment as to the appropriateness of the documents. If
            we discover any area of concern, we will notify you within two (2)
            business days and/or may recommend add-ons or edits to your plan
            through the <PlatformName /> platform. Upon approval of the plan,
            you will be notified that your documents are ready for download, and
            may proceed to execute them in accordance with the instructions
            provided by <PlatformName />.
          </p>
        </>
      )}

      <p>
        Throughout the <PlatformName /> online planning process, you may be
        advised on additional provisions to add to your planning based on the
        information you have provided that can enhance the result you achieve
        from the documents you chose.
      </p>

      <p>
        The online <PlatformName /> platform will bill you for all services
        selected on our behalf and at all times provide you the opportunity to
        communicate directly with our office to request a meeting to discuss any
        questions or concerns with your planning. By agreeing to use the{" "}
        <PlatformName /> platform and online planning process, you agree it is
        designed to minimize your costs and allow you to do as much of the work
        as possible in completing your plan. The online planning process will
        allow you to access our attorneys at any time after your engagement of
        us for the selected services but will require you to incur additional
        costs. If you are uncomfortable with this “pay only for what you want”
        approach, you may, at any time, contact our office to have us do your
        planning under the traditional method of meeting with an attorney
        personally in our offices without the use of the <PlatformName /> online
        planning process. By agreeing to move forward within the{" "}
        <PlatformName /> platform, you agree it is the method you prefer to work
        with us, and you accept the responsibility to contact us directly for
        matters not addressed on the <PlatformName /> platform. You will not
        receive any invoices from our firm as you agreed to all amounts due for
        the services outlined for the services you choose on the{" "}
        <PlatformName /> online platform prior to creating them and{" "}
        <PlatformName /> will remit your fees to us.
      </p>

      {jurisdiction === "Washington" && (
        <p>
          Under the Rules of Professional Conduct in Washington State the
          following provisions apply to Flat Fee engagements: Upon the receipt
          of funds, {partner?.data.displayName || "N/A"} will deposit the funds
          in their operating account, not in a client trust account. Paying your
          legal fee in advance does not affect your right to terminate the
          attorney-client relationship. In the event our relationship is
          terminated before the agreed upon legal services have been completed,
          you may or may not have a right to a refund of a portion of the fee.
        </p>
      )}

      <h2>II. Services Not Covered by the Initial Engagement Fee</h2>

      <p>
        Although the work included under your "flat fee" arrangement is
        sufficient to produce your documents, some clients choose to retain us
        to perform additional services. For example, should you desire to make
        substantial changes to the planning designed through your online
        interview, you will be billed an additional amount for the work
        necessary to carry out your requested changes. Substantial changes shall
        include the addition of provisions which will enhance your plan, that
        were not initially selected through the online <PlatformName />{" "}
        platform. Also, should you choose to have us perform additional services
        for you after your planning documents are executed, a separate agreement
        outlining additional services and charges would apply for the time
        devoted to the preparation, drafting and execution of the additional
        documents required. We will be willing to discuss fees with you at any
        time should questions arise prior to initiating any additional work
        beyond the scope of this Limited Scope Engagement Agreement.
      </p>

      <h2>III. Process for Handling Disputes</h2>

      <p>
        In the event a dispute arises between us relating to our fees, costs, or
        performance that cannot be resolved between us, the dispute shall be
        settled by mediation and if necessary, you may have the right to
        arbitration of the dispute pursuant to {jurisdiction} law.
      </p>

      <h2>IV. Termination of Services</h2>

      <p>
        You have the right to discharge us at any time. If you do, our right to
        be paid all previously incurred, but unpaid fees, charges and
        disbursements will not be affected.
      </p>

      <p>
        We may elect to terminate our services and decline to represent you
        further with your consent or for good cause. Good cause exists if, among
        other things, (a) you fail to meet your obligations under this
        agreement, (b) you refuse to cooperate with us or follow our advice in a
        material matter, or (c) under any other circumstances in which our
        professional or legal responsibilities and obligations mandate or permit
        termination.
      </p>

      <p>
        Without limiting in any way our general legal and ethical rights to
        withdraw from this representation for nonpayment of fees, it is agreed
        that sufficient cause for withdrawal shall be deemed to exist if any of
        our invoices to you for legal fees, disbursements and/or charges remain
        outstanding for a period of 90 days. In such event, we may elect to
        terminate our services and decline to represent you further. Such
        termination shall not affect our right to be paid all our previously
        incurred but unpaid fees, and all our previously incurred but unpaid
        charges and disbursements, in accordance with this agreement.
      </p>

      <h2>V. Attorney/Client Privilege and Confidentiality</h2>

      <p>
        Communications between you and us are privileged and confidential. We
        will take all reasonable steps necessary to protect that privilege and
        maintain confidentiality. Our firm maintains the highest standards of
        ethical integrity. Private communications between you and us are
        privileged and confidential. Unless authorized, by you or your appointed
        agent, we are unable to share such confidential information. We do not
        control what <PlatformName /> shares of your information. By signing
        this agreement, you acknowledge you have read and agree to the Terms of
        Use and Privacy Policy of <PlatformName />.
      </p>

      <p>
        <strong>Married Clients:</strong> In estate and long term planning, it
        is common for attorneys to represent spouses and/or non-traditional
        couples jointly and give undivided loyalty to both. Although rare, we
        may receive confidential information from one spouse that could affect
        how we might plan for your joint estate (i.e. intent to divorce,
        existence of an illegitimate child, or the suspicion of extramarital
        relationships etc.). This can have a substantial impact on your shared
        estate planning goals. In order to represent you jointly and be equally
        loyal to both, you agree all communications to us by either of you, may
        be disclosed to the other.
      </p>
    </div>
  );
};
export default EngagementAgreement;
