import { PartnerID, PartnerRegistration } from "modules/parties/types/Partner";
import { UserPartner } from "./UserProfile";

export enum UserRole {
  CLIENT = "",
  ADMIN = "Admin",
  GLOBAL_ADMIN = "GlobalAdmin",
}

export type UserID = string;

export type FirebaseUser = {
  activePartners?: Record<PartnerID, UserPartner>;
  partnerRegistrations?: Record<PartnerID, PartnerRegistration>;
  createdAt?: Date;
  displayName: string;
  email: string;
  firstName?: string;
  idToken?: string;
  lastName?: string;
  lastSignedIn?: Date;
  middleName?: string;
  state?: string;
  timezone?: string;
  uid: UserID;
};

export type User = FirebaseUser & {
  phoneNumber?: string;
  globalRoles: UserRole[];
  state?: string;
};
