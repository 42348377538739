import { createAction, Dispatch } from "@reduxjs/toolkit";
import moment from "moment";
import { FirebaseUser } from "./User";
import { signOut, getIdToken } from "modules/auth/authProvider";
import { get, API } from "../api";
import { UserProfile } from "./UserProfile";
import LoginCallbackMeta from "./loginCallback/LoginCallbackMeta";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import {
  navigateToGlobalLanding,
  navigateToPartnerLanding,
} from "modules/navigation/helpers/navigator";

export const loginRequired = createAction<LoginCallbackMeta | undefined>(
  "LOGIN_REQUIRED",
);
export const signupRequired = createAction<LoginCallbackMeta | undefined>(
  "SIGNUP_REQUIRED",
);
export const userAuthenticated =
  createAction<FirebaseUser>("USER_AUTHENTICATED");
export const userNotAuthenticated = createAction("USER_NOT_AUTHENTICATED");
export const authenticationFailed = createAction<any>("AUTHENTICATION_FAILED");
export const userLoggedOut = createAction<string | undefined>(
  "USER_LOGGED_OUT",
);
export const loginFailed = createAction<any>("LOGIN_FAILED");
export const logoutFailed = createAction<any>("LOGOUT_FAILED");
export const userProfileLoaded = createAction<UserProfile>(
  "USER_PROFILE_LOADED",
);
export const loadingUserProfileStarted = createAction("LOADING_USER_PROFILE");
export const userProfileIncomplete = createAction("USER_PROFILE_INCOMPLETE");
export const sessionInitiated = createAction("SESSION_INITIATED");
export const sessionRehydrated = createAction<string | undefined>(
  "SESSION_REHYDRATED",
);
export const stateNotSelected = createAction("STATE_NOT_SELECTED");
export const stateChanged = createAction<string>("STATE_CHANGED");
export const stateLoaded = createAction<string | null>("STATE_LOADED");
export const changeState = createAction("CHANGE_STATE");

export const logOut = (message?: string) => async (dispatch: Dispatch) => {
  try {
    const partnerId = getFormattedPartnerId();
    await signOut();
    dispatch(userLoggedOut(message));
    if (partnerId) {
      navigateToPartnerLanding(dispatch, partnerId);
    } else {
      navigateToGlobalLanding(dispatch);
    }
  } catch (e) {
    dispatch(logoutFailed(e));
  }
};

const fetchUserProfile = async (): Promise<UserProfile> => {
  const { data } = await get(API.USER_INFO());
  return data;
};

const authenticate = async (loginResult: any | null): Promise<FirebaseUser> => {
  const idToken = await getIdToken();
  const { displayName, email, metadata, uid } = loginResult;
  const { creationTime, lastSignInTime } = metadata;

  const user: FirebaseUser = {
    uid,
    displayName,
    email,
    idToken,
    createdAt: creationTime && moment(creationTime).toDate(),
    lastSignedIn: lastSignInTime && moment(lastSignInTime).toDate(),
  };

  return user;
};

const processLoginResult = async (loginResult: any, dispatch: Dispatch) => {
  let firebaseUser: FirebaseUser;
  try {
    firebaseUser = await authenticate(loginResult);
  } catch (e) {
    dispatch(authenticationFailed(e));
    throw e;
  }

  dispatch(userAuthenticated(firebaseUser));
  await loadUserProfile()(dispatch);
};

export const loadUserProfile =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(loadingUserProfileStarted());
    try {
      const profile = await fetchUserProfile();
      if (!profile) {
        dispatch(userProfileIncomplete());
        return;
      }

      dispatch(userProfileLoaded(profile));
    } catch (e) {
      dispatch(loginFailed(e));
      throw e;
    }
  };

export const handleLoginResult =
  (loginResult: any | null) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (loginResult !== null) {
      try {
        await processLoginResult(loginResult, dispatch);
        dispatch(sessionInitiated());
      } catch (e) {}
    } else {
      dispatch(userNotAuthenticated());
    }
    dispatch(sessionRehydrated(loginResult ? loginResult.uid : undefined));
  };
