import * as React from "react";
import Link from "components/Link";
import classNames from "classnames/bind";

import linkStyles from "./link.module.scss";
const cx = classNames.bind(linkStyles);

type CheckboxLinkProps = {
  disabled?: boolean;
  invalid?: boolean;
  label: string;
  onClick: () => any;
  selected?: boolean;
  tabIndex?: number;
};

const CheckboxLink: React.FC<CheckboxLinkProps> = ({
  disabled,
  invalid,
  label,
  onClick,
  selected,
  tabIndex,
}) => {
  let className = cx("checkbox-link", {
    "checkbox-link--disabled": disabled,
    "checkbox-link--invalid": !disabled && invalid,
    "checkbox-link--selected": selected,
  });

  return (
    <Link
      disabled={disabled}
      onClick={onClick}
      className={className}
      tabIndex={tabIndex}
    >
      <span className={linkStyles["checkbox-link__text"]}>{label}</span>
    </Link>
  );
};

export default CheckboxLink;
