import * as React from "react";
import classNames from "classnames/bind";
import FocusLock from "react-focus-lock";
import { Slide } from "@mui/material";
import { RemoveScroll } from "react-remove-scroll";
import PlatformContext from "contexts/PlatformContext";
import { TransitionProps } from "@mui/material/transitions";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { showModal, ModalControls, ModalType } from "..";
import Dialog from "../Dialog";

import styles from "./styles.module.scss";
import logger from "logger";
import { NotificationType } from "components/Notification";
import axios from "axios";
const cx = classNames.bind(styles);

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

type ModalFormState = {
  busy: boolean;
  close: boolean;
  open: boolean;
  dirty: boolean;
};

export class ModalForm extends React.PureComponent<
  ModalFormProps & { onExited: () => void; ref: React.RefObject<any> },
  ModalFormState
> {
  static contextType = PlatformContext;

  _isMounted: boolean = false;
  state: ModalFormState = {
    busy: false,
    close: false,
    open: true,
    dirty: false,
  };

  controls: ModalControls = {
    close: () => {
      this._isMounted && this.setState({ ...this.state, open: false });
    },
    busy: (busy: boolean) =>
      this._isMounted && this.setState({ ...this.state, busy: busy }),
    setDirty: (dirty: boolean) => this.setState({ ...this.state, dirty }),
  };

  constructor(
    props: ModalFormProps & {
      onExited: () => void;
      ref: React.RefObject<any>;
    },
  ) {
    super(props);
    props.getModalControls && props.getModalControls(this.controls);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCancelModal = () => {
    this.state.dirty
      ? !this.state.busy && this.setState({ ...this.state, close: true })
      : this.props.onCancel && this.props.onCancel();
  };

  render() {
    const { children, getModalControls, onCancel, onExited, onSubmit, title } =
      this.props;
    const className = cx("modal-form", {
      "modal-form-close": this.state.close,
      "modal-form-busy": this.state.busy,
    });
    return (
      <Dialog
        fullScreen={this.context.isMobileOnly}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() =>
          !this.state.busy &&
          this.setState({ ...this.state, close: !this.state.close })
        }
        onExited={onExited}
        open={this.state.open}
        scroll="paper"
        TransitionComponent={Transition}
      >
        <div className={className}>
          <FocusLock
            className={styles["modal-form__popup"]}
            disabled={!this.state.close}
            lockProps={{
              "aria-label": "Yes, delete my changes",
              "aria-modal": "true",
              role: "dialog",
            }}
          >
            <div
              className={styles["modal-form__focus-guard"]}
              tabIndex={0}
            ></div>
            <p className={styles["modal-form__popup-message"]}>
              Are you sure you want to cancel? Your changes will be lost.
            </p>
            <div className={styles["modal-form__popup-buttons"]}>
              <TabbedButton
                style={TabbedButtonType.SECONDARY}
                label="No, continue editing"
                onClick={() =>
                  !this.state.busy &&
                  this.setState({ ...this.state, close: false })
                }
              />
              <TabbedButton
                mood={TabbedButtonMood.NEGATIVE}
                label="Yes, delete my changes"
                onClick={() => {
                  if (!this.state.busy) {
                    onCancel && onCancel();
                    !getModalControls &&
                      this.setState({ ...this.state, open: false });
                  }
                }}
              />
            </div>
          </FocusLock>
          <header className={styles["modal-form__header"]}>
            <TabbedButton
              style={TabbedButtonType.SECONDARY}
              mood={TabbedButtonMood.NEGATIVE}
              label="Cancel"
              onClick={this.handleCancelModal}
            />
            <h2 className={styles["modal-form__title"]}>{title}</h2>
            <TabbedButton
              label="Save"
              mood={TabbedButtonMood.POSITIVE}
              onClick={async () => {
                if (!this.state.busy) {
                  if (
                    onSubmit &&
                    document.activeElement instanceof HTMLElement
                  ) {
                    document.activeElement.blur();
                  }
                  try {
                    onSubmit && (await onSubmit());
                    !getModalControls &&
                      this.setState({ ...this.state, open: false });
                  } catch (e) {
                    logger.debug(`[Modal dialog] Save interrupted: ${e}`);
                    if (axios.isAxiosError(e) && e.message) {
                      logger.notify(NotificationType.ERROR, e.message, e);
                    }
                  }
                }
              }}
            />
          </header>
          <RemoveScroll
            className={styles["modal-form__content"]}
            enabled={this.context.isMobile}
          >
            {typeof children === "function"
              ? children(this.controls)
              : children}
          </RemoveScroll>
        </div>
      </Dialog>
    );
  }
}

export type ModalFormProps = {
  getModalControls?: (controls: ModalControls) => void;
  onCancel?: () => void;
  onSubmit?: () => Promise<void>;
  renderContent: (modalControls: ModalControls) => JSX.Element;
  title: string;
};

const showModalForm = (props: ModalFormProps) => {
  showModal({
    ...props,
    type: ModalType.FORM,
  });
};

export default showModalForm;
