import { createReducer } from "@reduxjs/toolkit";
import Payment, {
  OrderItemType,
  PaymentStatus,
} from "modules/api/types/Payment";
import { userLoggedOut } from "modules/auth/actions";
import {
  couponApplied,
  fetchPaymentStatusFailed,
  paymentDetailsInvalid,
  paymentFailed,
  paymentInitializationError,
  paymentInitialized,
  paymentSubmissionStarted,
  paymentSuccessful,
  proceedToPayment,
  paymentSkipped,
} from "./actions";

export const activePayment = createReducer<Payment | null>(null, (builder) =>
  builder
    .addCase(proceedToPayment, () => null)
    .addCase(paymentInitializationError, () => null)
    .addCase(paymentInitialized, (_, { payload }) => payload)
    .addCase(couponApplied, (_, { payload }) => payload)
    .addCase(paymentSuccessful, (activePayment, { payload }) => ({
      ...(activePayment as Payment),
      status:
        payload.workflowId === activePayment?.workflowId
          ? PaymentStatus.Success
          : (activePayment as Payment).status,
    }))
    .addCase(fetchPaymentStatusFailed, (activePayment, { payload }) =>
      payload.paymentId === activePayment?.id
        ? {
            ...(activePayment as Payment),
            workflowError: true,
          }
        : activePayment,
    )
    .addCase(paymentSkipped, (activePayment, { payload: skippedPayment }) => ({
      ...(activePayment as Payment),
      orderItems: [
        ...(activePayment?.orderItems || []),
        {
          type: OrderItemType.DISCOUNT,
          name: skippedPayment.promoCode,
          amount: -1 * (activePayment?.amount || 0),
        },
      ],
      allOrderItems: [
        ...(activePayment?.allOrderItems || []),
        {
          type: OrderItemType.DISCOUNT,
          name: skippedPayment.promoCode,
          amount: -1 * (activePayment?.amount || 0),
        },
      ],
      amount: 0,
    }))
    .addCase(userLoggedOut, () => null),
);

export const isSubmittingPayment = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(paymentSubmissionStarted, () => true)
    .addCase(paymentSuccessful, () => false)
    .addCase(paymentDetailsInvalid, () => false)
    .addCase(paymentFailed, () => false),
);
