import { SelectMode } from ".";
import FieldState, {
  OTHER_VALUE,
  SelectMultipleOrOtherState,
  SelectOneOrOtherState,
} from "./FieldState";

const parseMultiSelectInitialValue = (
  options: any[],
  initialValues: any[] | undefined,
  allowOther: boolean,
  otherToggled: boolean,
): SelectMultipleOrOtherState =>
  (initialValues || []).reduce(
    (acc, value) => {
      if (options.includes(value)) {
        acc.value.push(value);
      } else if (allowOther) {
        !acc.value.includes(OTHER_VALUE) && acc.value.push(OTHER_VALUE);
        acc.other = value;
      }
      return acc;
    },
    {
      value: allowOther && otherToggled ? [OTHER_VALUE] : [],
      other: undefined,
    } as SelectMultipleOrOtherState,
  );

const parseSingleSelectInitialValue = (
  options: any[],
  value: any | undefined,
  allowOther: boolean,
  otherToggled: boolean,
): SelectOneOrOtherState => {
  const hasValue = value !== undefined && value !== "";
  if (hasValue && options.includes(value)) return { value, other: undefined };
  if (hasValue && allowOther && value !== null)
    return { value: OTHER_VALUE, other: value };
  if (allowOther && otherToggled)
    return { value: OTHER_VALUE, other: undefined };
  return { value: undefined, other: undefined };
};

export default (
  mode: SelectMode,
  otherFieldToggled: boolean,
  optionValues: string[],
  initialValue: any,
  allowOther: boolean,
): FieldState =>
  mode === SelectMode.MULTIPLE
    ? parseMultiSelectInitialValue(
        optionValues,
        initialValue,
        allowOther,
        otherFieldToggled,
      )
    : parseSingleSelectInitialValue(
        optionValues,
        initialValue,
        allowOther,
        otherFieldToggled,
      );
