import Condition from ".";

export enum ChildConstraint {
  UnderstatedAgeChildExists = "understatedAgeChildExists",
  ChildExists = "exists",
}

type ChildCondition = { child: ChildConstraint };

export default ChildCondition;

export const isChildCondition = (
  condition: Condition,
): condition is ChildCondition => condition.hasOwnProperty("child");
