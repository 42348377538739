import * as React from "react";
import classNames from "classnames/bind";
import { Zoom } from "@mui/material";
import { RemoveScroll } from "react-remove-scroll";
import PlatformContext from "contexts/PlatformContext";
import { TransitionProps } from "@mui/material/transitions";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { showModal, ModalControls, ModalType } from "..";
import Dialog from "../Dialog";

import styles from "./styles.module.scss";
import logger from "logger";
import { NotificationType } from "components/Notification";
import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";
import ApiError from "modules/api/types/ApiError";
const cx = classNames.bind(styles);

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Zoom ref={ref} {...props} />;
  },
);

type ModalConfirmState = {
  busy: boolean;
  open: boolean;
};

export class ModalConfirm extends React.PureComponent<
  ModalConfirmProps & {
    onExited: () => void;
    ref: React.RefObject<any>;
  },
  ModalConfirmState
> {
  _isMounted: boolean = false;
  state: ModalConfirmState = { open: true, busy: false };
  static contextType = PlatformContext;
  controls: ModalControls = {
    close: () => {
      this._isMounted && this.setState({ ...this.state, open: false });
    },
    busy: (busy: boolean) =>
      this._isMounted && this.setState({ ...this.state, busy: busy }),
    setDirty: (dirty: boolean) => undefined,
  };
  constructor(
    props: ModalConfirmProps & {
      onExited: () => void;
      ref: React.RefObject<any>;
    },
  ) {
    super(props);
    props.getModalControls && props.getModalControls(this.controls);
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const {
      cancellable = true,
      children,
      getModalControls,
      invertActions,
      labelCancel,
      labelConfirm,
      onCancel,
      onConfirm,
      onExited,
      text,
      title,
      maskTitle,
      type,
    } = this.props;
    let className = cx("modal-confirm", {
      "modal-confirm-warning": type === ModalType.WARNING,
      "modal-confirm-busy": this.state.busy,
    });
    let classNameFooter = cx("modal-confirm__footer", {
      "modal-confirm__footer-align-right": type === ModalType.NOTIFICATION,
    });

    const primaryButtonMood =
      type === ModalType.WARNING
        ? TabbedButtonMood.NEGATIVE
        : TabbedButtonMood.POSITIVE;

    const modalControls = [
      cancellable &&
      (onCancel || (!getModalControls && type !== ModalType.NOTIFICATION)) ? (
        <TabbedButton
          key="modal_btn_cancel"
          style={
            invertActions
              ? TabbedButtonType.PRIMARY
              : TabbedButtonType.SECONDARY
          }
          mood={invertActions ? primaryButtonMood : undefined}
          label={labelCancel ? labelCancel : "Cancel"}
          onClick={() => {
            if (!this.state.busy) {
              onCancel && onCancel();
              !getModalControls &&
                this.setState({ ...this.state, open: false });
            }
          }}
        />
      ) : null,

      onConfirm || type === ModalType.NOTIFICATION ? (
        this.state.busy ? (
          <div
            key={"modal_btn_confirm_spinner"}
            className={styles["modal-confirm__footer__spinner"]}
          >
            <LoadingSpinner size={SpinnerSize.XSMALL} />
          </div>
        ) : (
          <TabbedButton
            key="modal_btn_confirm"
            label={
              labelConfirm
                ? labelConfirm
                : type === ModalType.NOTIFICATION
                  ? "Close"
                  : "Confirm"
            }
            style={
              invertActions
                ? TabbedButtonType.SECONDARY
                : TabbedButtonType.PRIMARY
            }
            mood={invertActions ? undefined : primaryButtonMood}
            onClick={async () => {
              if (onConfirm && document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
              try {
                if (onConfirm) {
                  this.setState({ ...this.state, busy: true });
                  await onConfirm();
                  this.setState({ ...this.state, busy: false });
                }
                !getModalControls &&
                  this.setState({ ...this.state, open: false });
              } catch (e) {
                const err = e as any;
                if (err.response || err.message) {
                  logger.notify(
                    NotificationType.ERROR,
                    err.response
                      ? new ApiError(err.message, err.response)
                      : err.message,
                    err,
                  );
                }
                logger.debug(`[Confirm dialog] Confirm interrupted: ${e}`);
                this.setState({ ...this.state, busy: false });
              }
            }}
          />
        )
      ) : null,
    ];

    return (
      <RemoveScroll enabled={this.context.isMobile}>
        <Dialog
          onClose={() => {
            if (!this.state.busy && cancellable) {
              onCancel && onCancel();
              !getModalControls &&
                this.setState({ ...this.state, open: false });
            }
          }}
          onExited={onExited}
          open={this.state.open}
          scroll="body"
          TransitionComponent={Transition}
        >
          <div className={className}>
            {title && (
              <header className={styles["modal-confirm__header"]}>
                <h2
                  className={styles["modal-confirm__title"]}
                  data-clarity-mask={maskTitle}
                >
                  {title}
                </h2>
              </header>
            )}
            <section className={styles["modal-confirm__content"]}>
              {text && <p>{text}</p>}
              {typeof children === "function"
                ? children(this.controls)
                : children}
            </section>
            {(onCancel || onConfirm || !getModalControls) && (
              <footer className={classNameFooter}>
                {invertActions ? modalControls.reverse() : modalControls}
              </footer>
            )}
          </div>
        </Dialog>
      </RemoveScroll>
    );
  }
}

export type ModalConfirmProps = {
  cancellable?: boolean;
  getModalControls?: (controls: ModalControls) => void;
  invertActions?: boolean;
  labelCancel?: string;
  labelConfirm?: string;
  onCancel?: () => void;
  onConfirm?: () => Promise<void>;
  renderContent?: (modalControls: ModalControls) => JSX.Element | null;
  text?: string | JSX.Element | JSX.Element[];
  title?: string;
  maskTitle?: boolean;
  type?: ModalType.CONFIRM | ModalType.NOTIFICATION | ModalType.WARNING;
};

const showModalConfirm = (props: ModalConfirmProps) => {
  showModal({
    ...props,
    type: props.type ? props.type : ModalType.CONFIRM,
  });
};

export default showModalConfirm;
