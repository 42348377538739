import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import usePrevious from "utils/usePrevious";
import Location from "modules/address/types/Location";
import AturnaMarker from "./AturnaMarker";
import mapTheme from "./themes/guidr";
import styles from "../styles.module.scss";

type MapProps = {
  children: ReactNode;
  highlightedPoint?: { lat: number; lon: number };
  mapViewId?: string;
  onClick?: () => void;
  position: { lat: number; lon: number; zoom: number };
  referencePoint?: Location;
  mapContainerClassName?: string;
};

const getMapPoints = (children: any): Set<Location> => {
  if (!children) return new Set();

  const childrenArr = Array.isArray(children) ? children : [children];
  const markers = childrenArr
    .filter((child) => child?.type === AturnaMarker)
    .map((marker) => {
      const { lat, lon } = marker.props || {};
      return lat !== undefined && lon !== undefined
        ? { latitude: lat, longitude: lon }
        : undefined;
    })
    .filter(Boolean) as Location[];
  return new Set(markers);
};

const getMapBounds = (markers: Set<Location>) => {
  if (window.google) {
    const mapBounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ latitude, longitude }) =>
      mapBounds.extend(new window.google.maps.LatLng(latitude, longitude)),
    );
    return mapBounds;
  }
};

const Map = ({
  children,
  highlightedPoint,
  mapViewId,
  onClick,
  position: { lat, lon: lng, zoom },
  referencePoint,
  mapContainerClassName,
}: MapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || "",
  });

  const mapRef = useRef<any>();
  const previousViewId = usePrevious(mapViewId);

  const alignMap = useCallback(() => {
    const points = getMapPoints(children);

    if (points.size) {
      referencePoint && points.add(referencePoint);
      const mapBounds = getMapBounds(points);
      mapBounds !== undefined && mapRef.current.fitBounds(mapBounds, 30);
    }
  }, [children, referencePoint]);

  // Set map bounds
  useEffect(() => {
    const isNewSearch = mapViewId && mapViewId !== previousViewId;
    if (mapRef.current !== undefined && isNewSearch) {
      alignMap();
    }
  }, [alignMap, mapViewId, previousViewId]);

  // Focus on highlighted point
  useEffect(() => {
    if (mapRef.current && highlightedPoint && window.google) {
      const selectedMarkerPosition = new window.google.maps.LatLng(
        highlightedPoint.lat,
        highlightedPoint.lon,
      );
      mapRef.current.panTo(selectedMarkerPosition);
      if (mapRef.current.getZoom() < 12) {
        mapRef.current.setZoom(12);
      }
    }
  }, [children, highlightedPoint]);

  // On screen resize
  useEffect(() => {
    const resizeHandler = debounce(() => !highlightedPoint && alignMap(), 100);
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [alignMap, highlightedPoint]);

  const hasChildren =
    children && (!Array.isArray(children) || children.length > 0);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={
        mapContainerClassName ? mapContainerClassName : styles["map-container"]
      }
      options={{
        styles: mapTheme,
        gestureHandling: "greedy",
        minZoom: 3,
        maxZoom: 18,

        // Map controls
        // disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
      onLoad={(map) => {
        mapRef.current = map;
      }}
      {...(onClick ? { onClick } : {})}
      {...(mapRef.current && hasChildren ? {} : { center: { lat, lng }, zoom })}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
