export interface FAQ {
  question: string;
  answer: string;
}

export const planningFAQs: FAQ[] = [
  {
    question: "How do I know what plan to choose?",
    answer:
      "Aturna offers the essential estate planning documents: a Health Care Directive, Power of Attorney and Will. If you are not sure which documents you need, read through our Learning Center for a short, simple description of each of the forms.",
  },
  {
    question: "Can I purchase a Trust on Aturna?",
    answer:
      "You cannot purchase a Trust on Aturna, but the good news is, you can have your chosen law firm prepare a Trust for you! Start with the Essentials online, in your own time, and then meet with the law firm for more advanced planning. Great Trust plans require a little more customization and expertise, so that's why we have you work with the law firm directly.",
  },
  {
    question: "Can I purchase an estate plan with my spouse?",
    answer: `Of course! If you are married, you can (and should) do a plan in connection with your spouse. You each have your own set of answers for your individual documents. But, we've made it easy! There is a "Copy Spouse's Answers" button on each page of the interview, so you don't have to type everything in twice.`,
  },
  {
    question: "What if I'm not married but in a long-term relationship?",
    answer: `If you are not legally married, according to the laws of your state, then you should select "Not Married" in Aturna. Each state has different rules for leaving your assets to your spouse. If you are not "officially" married, you should each do separate plans on the Aturna platform`,
  },
  {
    question: "Can Aturna help with probate?",
    answer:
      "No, Aturna does not help with probate after you or a loved one passes away - but the law firm you work with for your estate plan can help with probate. Be sure to speak with your attorney about how you can avoid probate altogether.",
  },
];

export const gettingStartedFAQs: FAQ[] = [
  {
    question: "How long does the process take?",
    answer:
      "Most people complete their plan in 30 minutes or less. Yes; that quick! Of course this depends on which document you choose, and whether you are single or married. Either way, there is a convenient progress bar so you can see how many questions are remaining as you work on your plan.",
  },
  {
    question: "Is my personal information secure?",
    answer:
      "Aturna is committed to protecting your personal information and your right to privacy. Our website uses security practices reasonable in the legal industry. Also, your personal information is subject to attorney-client privilege, since every plan is reviewed by an authorized law firm. If you want more information about our commitment to protecting your personal information, please visit our Privacy Policy.",
  },
  {
    question: "Are these estate planning documents state specific?",
    answer:
      "Yes. The documents produced through Aturna are state specific. This includes the health care directives, powers of attorney, and Will language, as well as the signing instructions. We've done a lot of research. And remember, all documents are reviewed for completeness and accuracy by a law firm admitted to practice law in your state.",
  },
  {
    question: "Can I request a refund?",
    answer:
      "You cannot request a refund through Aturna directly. Sorry! Instead, please reach out to the law firm you engaged with. They will have their own refund policy in place.",
  },
  {
    question: "What if I need an attorney's help?",
    answer:
      "Any plan you choose in Aturna is done in connection with a knowledgeable estate planning law firm in your state. Toward the end of your plan, you can choose whether to sign your documents on your own using our printable instructions, or sign under the watchful eye of that law firm, to ensure everything is done correctly.",
  },
  {
    question: "What should I do after my documents are signed?",
    answer:
      "If you worked with a law firm for your supervised signing, check to see if they store the original documents for you. If you chose the self-sign option, or if your law firm does not store your original documents, store them in a safe place and make sure your loved ones know where they can find them in an emergency. It's also a good idea to send a copy of your signed documents to your chosen law firm, so their file is up to date. Life changes quickly, so be sure to review your plan once a year to see if you need any updates.",
  },
  {
    question: "Do you accept legal insurance?",
    answer:
      "Our online platform does not have a way for you to enter legal insurance information. However, you should contact the law firm that you want to work with, since they may accept legal insurance. They will help you decide the best way to move forward with your legal insurance plan.",
  },
];
