import axios, { AxiosError } from "axios";
import type { BadRequestResponsePayload } from "modules/api/types/ErrorResponsePayload";

const isBadRequestError = (
  e: unknown,
): e is AxiosError<BadRequestResponsePayload> =>
  axios.isAxiosError(e) &&
  e.response !== undefined &&
  e.response.data !== undefined &&
  e.response.data !== null &&
  (e.response.data as BadRequestResponsePayload).hasOwnProperty("short") &&
  (e.response.data as BadRequestResponsePayload).hasOwnProperty("detail");

export default isBadRequestError;
