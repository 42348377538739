import * as React from "react";
import Button from "../Button";

import buttonStyles from "./btn.module.scss";

type CheckboxButtonProps = {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactComponentElement<any>;
  id?: string;
  invalid?: boolean;
  label: string;
  onClick?: Function;
  selected?: boolean;
  tabIndex?: number;
};

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  disabled,
  icon,
  id,
  invalid,
  label,
  onClick,
  selected,
  tabIndex,
}) => {
  return (
    <Button
      className={["button-checkbox", icon ? "button-checkbox--icon" : ""]}
      disabled={disabled}
      icon={icon}
      id={id || ""}
      invalid={invalid}
      label={label}
      onClick={onClick}
      selected={selected}
      styles={buttonStyles}
      tabIndex={tabIndex === undefined ? 0 : tabIndex}
    />
  );
};

export default CheckboxButton;
