import React from "react";
import { Box, Typography } from "@mui/material";
import ContentPage from "components/ContentPage";

const CookiesPolicyPage: React.FC = () => (
  <Box>
    <ContentPage title="Cookies Policy">
      <Typography variant="body1">Last Updated: March 1, 2024</Typography>
      <Typography variant="body2">
        In addition to Personal Information that you knowingly disclose, we
        automatically collect certain information when you visit our website or
        use Aturna Services. This information does not reveal your specific
        identity (such as your name or contact information) but may include
        device and usage information, such as your IP address, software and
        hardware you use (such as browser types, operating systems, ISPs,
        platform type, device type, mobile device identifiers such as make and
        model, mobile carrier), country, language preferences, referring URLs,
        pages or features of Aturna Services used and associated dates and
        timestamps, search terms, links you click, whether you open messages
        sent to your account, and other statistics related to your usage of our
        systems.
      </Typography>
      <Typography variant="body2">
        We may also collect technical information such as web storage data, data
        stored in application data caches and data provided by web pixels,
        cookies and anonymous identifiers. We use this information primarily to
        maintain the security and operation of Aturna Services and for our
        internal analytics and reporting purposes. We retain and use this
        information and data to examine and analyze usage trends and user
        preferences.
      </Typography>
      <Typography variant="body2">
        We also use tools, including third-party tools, to collect analytics
        data. Some of this information is collected using cookies and other
        tracking technologies, such as web beacons, session replay scripts, and
        similar technologies (“tracking technologies”). We may also work with
        third party partners to employ tracking technologies. When using Aturna
        Services, we may save one or more cookies onto your web browser. We use
        both session and persistent cookies to collect, store and track
        information and data to improve your user experience. A session cookie
        is one that disappears after you close your browser. A persistent cookie
        remains after you close your browser and can generally be removed. If
        you would prefer not to accept cookies or otherwise wish to disable our
        use of tracking technologies, it is possible to modify your cookie
        settings through your web browser. Please note that doing so may
        negatively impact your user experience, as some features may not work
        properly.
      </Typography>
      <Typography variant="body2">
        Note that when clicking links to third party websites, cookies or pixels
        may be transmitted to your computer's web browser. Aturna has no control
        over cookies or pixels, or other data transmitted by a third party.
        Aturna is not responsible for any cookies, pixels or data storage on
        your computer accessed through a third-party website or service.
      </Typography>
      <Typography variant="body2">
        We automatically gather information and data which is not connected to
        your personal identity to improve Aturna. We may collect information by
        aggregating and anonymizing other information. The aggregation and
        anonymization process prevents the information from being associated or
        identified with any one customer account, user, or individual. We may
        use aggregated and anonymized information for a wide variety of
        statistical and analytical purposes.
      </Typography>
      <Typography variant="body2">
        All forms of information that we collect is referred to collectively in
        this policy as “information.”
      </Typography>
      <Typography variant="body2">
        If you would prefer not to accept cookies or otherwise wish to disable
        our use of tracking technologies, most browsers and mobile devices allow
        you to change your settings to notify you when you receive cookies or
        other tracking technologies are being used, and to choose whether to
        accept/allow it. Most browsers also allow you to disable or delete
        existing cookies or to automatically reject future cookies. Disabling
        cookies may limit your ability to use Aturna Services.
      </Typography>
    </ContentPage>
  </Box>
);

export default CookiesPolicyPage;
