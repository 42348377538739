import React from "react";
import classNames from "classnames/bind";

import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import Plan from "modules/product/types/Plan";
import SanitizeHtml from "components/SanitizeHtml";
import currencyFormatter from "utils/currencyFormatter";

const cx = classNames.bind(styles);

type ProductSelectorItemProps = {
  disabled?: boolean;
  buttonLabel: string;
  onSelectBasic: (item: Plan) => any;
  item: Plan;
  priceSaving?: number;
};

const ProductSelectorItem = ({
  disabled,
  buttonLabel,
  onSelectBasic,
  item,
  priceSaving,
}: ProductSelectorItemProps) => {
  const classNames = cx("product-selector-item", {
    "product-selector-item--disabled": disabled,
  });

  const { name, description, price } = item;

  return (
    <div className={classNames} data-tid={item.id}>
      <div>
        <h3 className={styles["product-selector-item__name"]}>{name}</h3>
        <span className={styles["product-selector-item__price"]}>
          From <strong>{currencyFormatter.format(price.regular)}</strong>
          {priceSaving !== undefined && (
            <>
              {" saving you "}
              <strong>{currencyFormatter.format(priceSaving)}</strong>
            </>
          )}
        </span>
      </div>
      {description && (
        <h4 className={styles["product-selector-item__description"]}>
          <SanitizeHtml html={description} />
        </h4>
      )}
      <div className={styles["product-selector-item__button-container"]}>
        <TabbedButton
          ariaLabel="Select"
          disabled={Boolean(disabled)}
          onClick={() => onSelectBasic(item)}
          label={buttonLabel}
        />
      </div>
    </div>
  );
};

export default ProductSelectorItem;
