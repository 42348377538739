import React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type Props = { planName: string; productName: string };

const ExternalDocumentPage: React.FC<Props> = ({ planName, productName }) => {
  return (
    <div className={cx("external-document-message")}>
      You've completed information for your {planName} interview. Since the{" "}
      {productName} is not available online, the law firm will contact you to
      arrange to prepare and execute your {productName}.
    </div>
  );
};

export default ExternalDocumentPage;
