import * as React from "react";
import {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isWindows,
  osVersion,
} from "react-device-detect";

export type PlatformType = {
  isAndroid: boolean;
  isIOS: boolean;
  isMacOs: boolean;
  isMobile: boolean;
  isMobileOnly: boolean;
  isWindows: boolean;
  osVersion: string;
};

export default React.createContext({
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isWindows,
  osVersion,
} as PlatformType);
export {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isWindows,
  osVersion,
} from "react-device-detect";
