import Form from "components/Forms/Form";
import PageHeader from "components/PageHeader";
import { FormikProps } from "formik";
import { keyBy } from "lodash";
import useMedicaidVariables from "modules/globalAdmin/hooks/useMedicaidVariables";
import FormContent from "modules/workflow/components/QuestionnaireForm/FormContent";
import {
  filterItemsByCondition,
  unassignFilteredQuestions,
} from "modules/workflow/helpers/questionnaireSchemaParser";
import {
  getInitialValues,
  getValidationSchema,
} from "modules/workflow/helpers/validation";
import { AnswersMap } from "modules/workflow/types/Answer";
import { Question } from "modules/workflow/types/Questionnaire";
import React, { useState } from "react";
import { parseMedicaidVariablesCSV, updateMedicaidVariables } from "./helpers";
import StatePicker from "./StatePicker/StatePicker";
import getDelta from "components/Forms/Form/getDelta";
import CSVUpload from "components/CSVUpload";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import logger from "logger";
import { API, put } from "modules/api";
import { NotificationType } from "components/Notification";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const cx = classNames.bind(styles);

const DEFAULT_STATE_CODE = "AL"; // Defaulting to Alabama

const MedicaidVariablesScreen = () => {
  const [jurisdiction, setJurisdiction] = useState(DEFAULT_STATE_CODE);
  const {
    questionnaireWithQuestions,
    medicaidVariables,
    refetchMedicaidVariables,
  } = useMedicaidVariables(jurisdiction);
  const [unsavedAnswersMap, setUnsavedAnswersMap] = useState<AnswersMap>({});

  const { questionnaire, questions: questionsMap } =
    questionnaireWithQuestions || {};

  if (!questionnaire || !questionsMap || !medicaidVariables)
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;

  const savedAnswersMap: AnswersMap = Object.fromEntries(
    medicaidVariables.map(({ questionId, answer }) => [questionId, answer]),
  );

  const questions = Object.values(questionsMap).map((question) => ({
    ...question,
    label:
      question.label ||
      (question.data.descriptions.length > 0
        ? question.data.descriptions[0].label
        : ""),
  }));

  const filteredQuestions = filterItemsByCondition<Question>(
    questions,
    {
      [questionnaire.id]: questionsMap,
    },
    { [questionnaire.id]: { ...savedAnswersMap, ...unsavedAnswersMap } },
    [],
    undefined,
    undefined,
    [],
    (question) =>
      question.data.condition && {
        [questionnaire.id]: question.data.condition,
      },
    [],
  );

  const handleFormChanged = (newFormState: AnswersMap) => {
    const delta = getDelta(newFormState, savedAnswersMap, questionsMap);
    setUnsavedAnswersMap(delta);
  };

  const filteredSections = questionnaire.pages[0].sections.map((section) =>
    unassignFilteredQuestions(section, keyBy(filteredQuestions, "id")),
  );

  const schema = getValidationSchema(filteredQuestions);
  const initialValues = getInitialValues(filteredQuestions, {
    ...savedAnswersMap,
    ...unsavedAnswersMap,
  });

  const handleMedicaidVariablesCSVUpload = async (file: File) => {
    try {
      const medicaidVariables = await parseMedicaidVariablesCSV(file); // { [jurisdiciton]: { [medicaidVariableName]: answer } }

      await put(API.MEDICAID_VARIABLES_CSV(), {
        answers: medicaidVariables,
      });

      refetchMedicaidVariables();

      logger.notify(
        NotificationType.SUCCESS,
        "Medicaid variables have been successfully updated",
      );
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <>
      <PageHeader title="Aturna Medicaid Variables" />
      <div className={cx("csv-upload-container")}>
        <CSVUpload
          onClick={(event) => {
            handleMedicaidVariablesCSVUpload(event.target.files[0]);
          }}
          disabled={false}
        />
      </div>
      <div>
        <StatePicker
          onChangeState={async (newState: string) => {
            await updateMedicaidVariables(questionnaire.id, {
              ...savedAnswersMap,
              unsavedAnswersMap,
            });
            setJurisdiction(newState);
            setUnsavedAnswersMap({});
          }}
          currentState={jurisdiction}
        />
        <Form
          formKey={`medicaid-variables-${jurisdiction}`}
          schema={schema}
          state={initialValues}
          showSaveButton={true}
          onFormChanged={handleFormChanged}
          onSave={async (newAnswers) => {
            await updateMedicaidVariables(questionnaire.id, newAnswers);
          }}
        >
          {(
            submitHandle: (changesOnly?: boolean) => Promise<void>,
            formHandle: FormikProps<AnswersMap>,
          ) => {
            return (
              <>
                <FormContent
                  sections={filteredSections}
                  questions={filteredQuestions}
                />
              </>
            );
          }}
        </Form>
      </div>
    </>
  );
};

export default MedicaidVariablesScreen;
