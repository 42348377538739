import Product from "modules/product/types/Product";
import Overwrite from "modules/api/responseTypes/Overwrite";
import Plan from "modules/product/types/Plan";
import Addon from "modules/product/types/Addon";
import Questionnaire from "./Questionnaire";
import {
  Definition,
  DefinitionData,
} from "modules/api/responseTypes/DefinitionResponseType";

export type PlanDefinition = Plan & { advisorQuestionnaires?: Questionnaire[] };
export type ProductDefinition = Product & {
  advisorQuestionnaires?: Questionnaire[];
};
export const isPlanDefinition = (
  definition: PlanDefinition | ProductDefinition,
): definition is PlanDefinition => definition.hasOwnProperty("products");

type DefinitionWithProductsPlansAndAddons = Overwrite<
  Definition,
  {
    data: Overwrite<
      DefinitionData,
      {
        availableProducts: ProductDefinition[];
        availablePlans: PlanDefinition[];
        availableAddons: Addon[];
      }
    >;
  }
>;

export default DefinitionWithProductsPlansAndAddons;
