import { createReducer } from "@reduxjs/toolkit";
import { MedicaidData } from "./types";
import {
  adminMedicaidLeadDataFetched,
  changeMedicaidLeadPageFailed,
  createMedicaidLeadClientFailed,
  createMedicaidLeadFailed,
  createMedicaidLeadInitiated,
  fetchAdminMedicaidLeadDataFailed,
  fetchAdminMedicaidLeadDataInitialised,
  fetchMedicaidLeadFailed,
  fetchMedicaidLeadInitiated,
  fetchMedicaidLeadPreviewsFailed,
  fetchMedicaidLeadPreviewsFinished,
  fetchMedicaidLeadPreviewsInitialised,
  fetchMedicaidLeadsFailed,
  fetchMedicaidLeadsInitialised,
  getMedicaidLeadDocumentsFailed,
  getMedicaidLeadDocumentsInitialised,
  medicaidDocumentRenderStatusUpdated,
  medicaidLeadAnswersChanged,
  medicaidLeadAnswersSaved,
  medicaidLeadCalculationFailed,
  medicaidLeadCalculationFinished,
  medicaidLeadCalculationInitialised,
  medicaidLeadClientCreated,
  medicaidLeadClientSaved,
  medicaidLeadLoaded,
  medicaidLeadPageChanged,
  medicaidLeadPartnerUpdated,
  medicaidLeadReviewed,
  medicaidLeadStatusUpdated,
  medicaidLeadsFetched,
  newMedicaidLeadCreated,
  renderMedicaidDocumentsFailed,
  renderMedicaidDocumentsInitialised,
  saveMedicaidLeadAnswersFailed,
  saveMedicaidLeadClientFailed,
  submitMedicaidLeadReviewFailed,
  unsetAdminMedicaidLeadData,
  unsetRenderedMedicaidLeadDocuments,
  updateMedicaidLeadPartnerFailed,
  updateMedicaidLeadStatusFailed,
} from "./actions";
import logger from "logger";
import { NotificationType } from "components/Notification";
import { cloneDeep, merge } from "lodash";

const initialValue = {
  isLoadingMedicaidLead: false,
  isLoadingMedicaidLeads: false,
  isLoadingMedicaidAnalysis: false,
  isLoadingMedicaidLeadPreviews: false,
  isLoadingAdminMedicaidLeadData: false,
  isRenderingMedicaidLeadDocuments: false,
};

export const medicaid = createReducer<MedicaidData>(initialValue, (builder) =>
  builder
    .addCase(createMedicaidLeadInitiated, (medicaidData) => ({
      ...medicaidData,
      isLoadingMedicaidLead: true,
    }))
    .addCase(newMedicaidLeadCreated, (medicaidData, { payload }) => ({
      ...medicaidData,
      lead: payload.medicaidLead,
      questionnaire: payload.questionnaire,
      questions: payload.questions,
      answers: payload.answers,
      clients: payload.clients,
      isLoadingMedicaidLead: false,
    }))
    .addCase(createMedicaidLeadFailed, (medicaidData) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error creating your medicaid workflow",
      );
      return medicaidData;
    })
    .addCase(fetchMedicaidLeadInitiated, (medicaidData) => ({
      ...medicaidData,
      isLoadingMedicaidLead: true,
    }))
    .addCase(medicaidLeadLoaded, (medicaidData, { payload }) => ({
      ...medicaidData,
      lead: payload.medicaidLead,
      questionnaire: payload.questionnaire,
      questions: payload.questions,
      answers: payload.answers,
      clients: payload.clients,
      isLoadingMedicaidLead: false,
    }))
    .addCase(fetchMedicaidLeadFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error fetching your medicaid workflow",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadAnswersSaved, (medicaidData, { payload }) => ({
      ...medicaidData,
      answers: {
        ...medicaidData.answers,
        ...payload,
      },
      unsavedAnswers: {},
    }))
    .addCase(saveMedicaidLeadAnswersFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error saving your answers",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadClientCreated, (medicaidData, { payload }) => ({
      ...medicaidData,
      clients: {
        ...medicaidData.clients,
        ...payload,
      },
    }))
    .addCase(createMedicaidLeadClientFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error creating your medicaid client.",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadClientSaved, (medicaidData, { payload }) => ({
      ...medicaidData,
      clients: {
        ...medicaidData.clients,
        ...payload,
      },
    }))
    .addCase(saveMedicaidLeadClientFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error saving your medicaid client.",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadAnswersChanged, (medicaidData, { payload }) => ({
      ...medicaidData,
      unsavedAnswers: merge(cloneDeep(medicaidData.unsavedAnswers), payload),
    }))
    .addCase(medicaidLeadPageChanged, (medicaidData, { payload }) => ({
      ...medicaidData,
      ...(medicaidData.lead !== undefined
        ? {
            lead: {
              ...medicaidData.lead,
              pageIdx: payload,
            },
          }
        : {}),
    }))
    .addCase(changeMedicaidLeadPageFailed, (medicaidData, { payload }) => {
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadCalculationInitialised, (medicaidData) => ({
      ...medicaidData,
      isLoadingMedicaidAnalysis: true,
    }))
    .addCase(medicaidLeadCalculationFinished, (medicaidData, { payload }) => ({
      ...medicaidData,
      analysis: {
        ...medicaidData.analysis,
        calculationError: false,
        calculation: payload,
        medicaidLeadId: payload.medicaidLeadId,
      },
      isLoadingMedicaidAnalysis: false,
    }))
    .addCase(medicaidLeadCalculationFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error while calculating your asset protection analysis",
      );
      logger.error(payload);
      return {
        ...medicaidData,
        analysis: {
          ...medicaidData.analysis,
          calculationError: true,
        },
        isLoadingMedicaidAnalysis: false,
      };
    })
    .addCase(medicaidLeadPartnerUpdated, (medicaidData, { payload }) => ({
      ...medicaidData,
      lead: payload,
    }))
    .addCase(updateMedicaidLeadPartnerFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error while assigning a partner to your workflow",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(fetchMedicaidLeadPreviewsInitialised, (medicaidData) => ({
      ...medicaidData,
      isLoadingMedicaidLeadPreviews: true,
    }))
    .addCase(
      fetchMedicaidLeadPreviewsFinished,
      (medicaidData, { payload }) => ({
        ...medicaidData,
        admin: {
          ...medicaidData.admin,
          leadPreviews: payload,
        },
      }),
    )
    .addCase(fetchMedicaidLeadPreviewsFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error while fetching your medicaid lead previews",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadStatusUpdated, (medicaidData, { payload }) => ({
      ...medicaidData,
      lead: payload,
    }))
    .addCase(updateMedicaidLeadStatusFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error updating the status of your medicaid lead.",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(medicaidLeadReviewed, (medicaidData, { payload }) => ({
      ...medicaidData,
      admin: {
        ...medicaidData.admin,
        leadPreviews: payload.medicaidLeadPreviews,
        currentLead: payload.enrichedMedicaidLead,
        currentCalculation: payload.medicaidCalculation,
      },
    }))
    .addCase(submitMedicaidLeadReviewFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error submitting your medicaid lead review.",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(fetchAdminMedicaidLeadDataInitialised, (medicaidData) => ({
      ...medicaidData,
      isLoadingAdminMedicaidLeadData: true,
    }))
    .addCase(adminMedicaidLeadDataFetched, (medicaidData, { payload }) => ({
      ...medicaidData,
      admin: {
        leadPreviews: {},
        ...medicaidData.admin,
        currentLead: payload.enrichedMedicaidLead,
        currentCalculation: payload.medicaidLeadCalculation,
      },
      isLoadingAdminMedicaidLeadData: false,
    }))
    .addCase(fetchAdminMedicaidLeadDataFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error fetching this medicaid lead.",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(unsetAdminMedicaidLeadData, (medicaidData, { payload }) => ({
      ...medicaidData,
      admin: {
        leadPreviews: payload,
      },
    }))
    .addCase(fetchMedicaidLeadsInitialised, (medicaidData, { payload }) => ({
      ...medicaidData,
      isLoadingMedicaidLeads: true,
    }))
    .addCase(medicaidLeadsFetched, (medicaidData, { payload }) => ({
      ...medicaidData,
      availableLeads: payload,
      isLoadingAdminMedicaidLeads: false,
    }))
    .addCase(fetchMedicaidLeadsFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error fetching your medicaid workflows",
      );
      logger.error(payload);
      return medicaidData;
    })
    .addCase(renderMedicaidDocumentsInitialised, (medicaidData) => ({
      ...medicaidData,
      isRenderingMedicaidLeadDocuments: true,
    }))
    .addCase(renderMedicaidDocumentsFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error rendering the medicaid lead documents, please try again later.",
      );
      logger.error(payload);
      return { ...medicaidData, isRenderingMedicaidLeadDocuments: false };
    })
    .addCase(
      medicaidDocumentRenderStatusUpdated,
      (medicaidData, { payload }) => ({
        ...medicaidData,
        admin: {
          ...medicaidData.admin,
          ...(medicaidData.admin?.leadPreviews === undefined
            ? {
                leadPreviews: {},
              }
            : { leadPreviews: medicaidData.admin.leadPreviews }),
          documents: payload,
        },
        isRenderingMedicaidLeadDocuments: false,
      }),
    )
    .addCase(unsetRenderedMedicaidLeadDocuments, (medicaidData) => ({
      ...medicaidData,
      admin: {
        currentLead: medicaidData.admin?.currentLead
          ? { ...medicaidData.admin?.currentLead }
          : undefined,
        currentCalculation: medicaidData.admin?.currentCalculation
          ? { ...medicaidData.admin?.currentCalculation }
          : undefined,
        ...(medicaidData.admin?.leadPreviews === undefined
          ? {
              leadPreviews: {},
            }
          : { leadPreviews: medicaidData.admin.leadPreviews }),
        documents: undefined,
      },
    }))
    .addCase(getMedicaidLeadDocumentsInitialised, (mediciadData) => ({
      ...mediciadData,
      isRenderingMedicaidLeadDocuments: true,
    }))
    .addCase(getMedicaidLeadDocumentsFailed, (medicaidData, { payload }) => {
      logger.notify(
        NotificationType.ERROR,
        "There was an error fetching your medicaid documents",
      );
      logger.error(payload);
      return { ...medicaidData, isRenderingMedicaidLeadDocuments: false };
    }),
);
