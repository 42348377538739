import { NotificationType } from "components/Notification";
import lodash from "lodash";
import logger from "logger";
import { callUpdateMedicaidVariablesAPI } from "modules/admin/actions";
import { MedicaidCalculation } from "modules/medicaid/types";
import { AnswerValueType, AnswersMap } from "modules/workflow/types/Answer";
import { QuestionnaireID } from "modules/workflow/types/Questionnaire";
import Papa from "papaparse";
import { INVERTED_STATES_MAP } from "resources/options/states";

export const updateMedicaidVariables = async (
  questionnaireId: QuestionnaireID,
  answers: AnswersMap,
) => {
  try {
    const response = await callUpdateMedicaidVariablesAPI(
      questionnaireId,
      answers,
    );

    logger.notify(
      NotificationType.SUCCESS,
      "Successfully updated medicaid variables",
    );
    return response;
  } catch (error) {
    logger.notify(
      NotificationType.ERROR,
      undefined,
      "Could not update medicaid variables",
    );
  }
};

enum MedicaidVariablesCSVFieldNames {
  PrePlanningOrCrisis = "Pre Planning Or Crisis",
  HealthInsuranceCost = "Health Insurance Cost",
  PersonalNeedsAllowance = "Personal Needs Allowance",
  UtilityAllowance = "Utility Allowance",
  HousingAllowance = "Housing Allowance",
  TypeOfMMMNAState = "Type of MMMNA State",
  MMMNAMaxValue = "Max Value",
  MMMNAMinValue = "Min Value",
  RegionalDivisorType = "Regional Divisor Type",
  RegionalDivisorValue = "Regional Divisor Value",
  IndividualResourceAllowance = "Individual Resource Allowance",
  SingleIndividualHouseAllowance = "Single Individual House Allowance",
  TypeOfCSRAState = "Type of CSRA State",
  CSRAMaxValue = "Maximum CSRA",
  CSRAMinValue = "Minimum CSRA",
  LifeInsuranceValueBasedOn = "Life Insurance Value based on",
  AmountOfLifeInsuranceExemption = "Amount of life insurance exemption",
  AssetToIncomeRuleExemption = "Asset to income rule exemption",
  IncomeCapState = "Income Cap State",
  IncomeCapAmount = "Income Cap Amount",
  MedicaidDepartmentName = "Name of Dept of Medicaid",
  ISEnabled = "Exempt Retirement Assets IS",
  CSEnabled = "Exempt Retirement Assets CS",
  Jurisdiction = "Jurisdiction",
}

export const fieldNameToMedicaidVariableQuestionId: Record<string, string> = {
  [MedicaidVariablesCSVFieldNames.PrePlanningOrCrisis]:
    "pre-planning-or-crisis",
  [MedicaidVariablesCSVFieldNames.HealthInsuranceCost]:
    "monthly-health-insurance-cost-average",
  [MedicaidVariablesCSVFieldNames.PersonalNeedsAllowance]:
    "personal-needs-allowance",
  [MedicaidVariablesCSVFieldNames.UtilityAllowance]: "utility-allowance",
  [MedicaidVariablesCSVFieldNames.HousingAllowance]: "housing-allowance",
  [MedicaidVariablesCSVFieldNames.TypeOfMMMNAState]: "type-of-mmmna-state",
  [MedicaidVariablesCSVFieldNames.MMMNAMaxValue]: "max-mmmna-value",
  [MedicaidVariablesCSVFieldNames.MMMNAMinValue]: "min-mmmna-value",
  [MedicaidVariablesCSVFieldNames.RegionalDivisorType]: "regional-divisor-type",
  [MedicaidVariablesCSVFieldNames.RegionalDivisorValue]:
    "regional-divisor-value",
  [MedicaidVariablesCSVFieldNames.IndividualResourceAllowance]:
    "individual-resource-allowance",
  [MedicaidVariablesCSVFieldNames.SingleIndividualHouseAllowance]:
    "single-individual-house-allowance",
  [MedicaidVariablesCSVFieldNames.TypeOfCSRAState]: "type-of-csra-state",
  [MedicaidVariablesCSVFieldNames.CSRAMaxValue]: "max-csra-value",
  [MedicaidVariablesCSVFieldNames.CSRAMinValue]: "min-csra-value",
  [MedicaidVariablesCSVFieldNames.LifeInsuranceValueBasedOn]:
    "life-insurance-exemption-based-on",
  [MedicaidVariablesCSVFieldNames.AmountOfLifeInsuranceExemption]:
    "amount-of-allowed-life-insurance-exemption",
  [MedicaidVariablesCSVFieldNames.AssetToIncomeRuleExemption]:
    "asset-to-income-rule-exemption",
  [MedicaidVariablesCSVFieldNames.IncomeCapState]: "income-cap-state",
  [MedicaidVariablesCSVFieldNames.IncomeCapAmount]: "income-cap-amount",
  [MedicaidVariablesCSVFieldNames.MedicaidDepartmentName]:
    "name-of-department-of-medicaid",
  [MedicaidVariablesCSVFieldNames.ISEnabled]: "retirement-assets-exemption-is",
  [MedicaidVariablesCSVFieldNames.CSEnabled]: "retirement-assets-exemption-cs",
  [MedicaidVariablesCSVFieldNames.Jurisdiction]:
    MedicaidVariablesCSVFieldNames.Jurisdiction,
};

const mapFieldToAnswerValue = (
  fieldName: string,
  answerString: string,
): AnswerValueType => {
  switch (fieldName) {
    case MedicaidVariablesCSVFieldNames.HealthInsuranceCost:
    case MedicaidVariablesCSVFieldNames.PersonalNeedsAllowance:
    case MedicaidVariablesCSVFieldNames.UtilityAllowance:
    case MedicaidVariablesCSVFieldNames.HousingAllowance:
    case MedicaidVariablesCSVFieldNames.MMMNAMaxValue:
    case MedicaidVariablesCSVFieldNames.MMMNAMinValue:
    case MedicaidVariablesCSVFieldNames.RegionalDivisorValue:
    case MedicaidVariablesCSVFieldNames.IndividualResourceAllowance:
    case MedicaidVariablesCSVFieldNames.SingleIndividualHouseAllowance:
    case MedicaidVariablesCSVFieldNames.CSRAMaxValue:
    case MedicaidVariablesCSVFieldNames.CSRAMinValue:
    case MedicaidVariablesCSVFieldNames.IncomeCapAmount:
    case MedicaidVariablesCSVFieldNames.AmountOfLifeInsuranceExemption: {
      return Number(answerString);
    }
    case MedicaidVariablesCSVFieldNames.AssetToIncomeRuleExemption:
    case MedicaidVariablesCSVFieldNames.IncomeCapState:
    case MedicaidVariablesCSVFieldNames.ISEnabled:
    case MedicaidVariablesCSVFieldNames.CSEnabled: {
      if (answerString.toLowerCase() === "yes") {
        return true;
      } else if (answerString.toLowerCase() === "no") {
        return false;
      }
      throw Error(
        `Expected either 'Yes' or 'No' for column '${fieldName}', but got '${answerString}' instead.`,
      );
    }
    case MedicaidVariablesCSVFieldNames.PrePlanningOrCrisis: {
      if (answerString.toLowerCase() === "pre-planning") {
        return "Pre-planning";
      } else if (answerString.toLowerCase() === "crisis") {
        return "Crisis";
      }
      throw Error(
        `Expected either 'Pre-planning' or 'Crisis' for column '${fieldName}', but got '${answerString}' instead.`,
      );
    }
    case MedicaidVariablesCSVFieldNames.TypeOfMMMNAState:
    case MedicaidVariablesCSVFieldNames.TypeOfCSRAState: {
      if (answerString.toLowerCase() === "max") {
        return "Maximum";
      } else if (answerString.toLowerCase() === "range") {
        return "Range";
      }
      throw Error(
        `Expected either 'Max' or 'Range' for column '${fieldName}', but got '${answerString}' instead.`,
      );
    }
    case MedicaidVariablesCSVFieldNames.RegionalDivisorType:
      if (answerString.toLowerCase() === "daily") {
        return "Daily";
      } else if (answerString.toLowerCase() === "monthly") {
        return "Monthly";
      }
      throw Error(
        `Expected either 'Daily' or 'Monthly' for column '${fieldName}', but got '${answerString}' instead.`,
      );
    case MedicaidVariablesCSVFieldNames.LifeInsuranceValueBasedOn:
      if (answerString.toLowerCase() === "face value") {
        return "Face Value";
      } else if (answerString.toLowerCase() === "cash value") {
        return "Cash Value";
      }
      throw Error(
        `Expected either 'Face Value' or 'Cash Value' for column '${fieldName}', but got '${answerString}' instead.`,
      );
    case MedicaidVariablesCSVFieldNames.Jurisdiction:
    case MedicaidVariablesCSVFieldNames.MedicaidDepartmentName: {
      return answerString;
    }
    default: {
      throw Error(`Unknown field: '${fieldName}'`);
    }
  }
};

export const parseMedicaidVariablesCSV = (
  file: File,
): Promise<Record<string, AnswersMap>> =>
  new Promise((resolve, reject) =>
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const sanitisedMedicaidVariables = lodash.mapKeys(
          lodash.keyBy(
            (
              results.data.slice(1, results.data.length) as Record<
                string,
                string
              >[]
            ).map((row) =>
              Object.entries(row)
                .map(([fieldName, answerString]) => ({
                  [fieldNameToMedicaidVariableQuestionId[fieldName]]:
                    mapFieldToAnswerValue(fieldName, answerString),
                }))
                .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
            ),
            MedicaidVariablesCSVFieldNames.Jurisdiction,
          ),
          (_, jurisdiction) => INVERTED_STATES_MAP[jurisdiction],
        );
        resolve(sanitisedMedicaidVariables);
      },
      error: (error) => {
        reject(error);
      },
    }),
  );

export const getTotalAssetsAtRisk = (calculation: MedicaidCalculation) =>
  calculation.answers.totalAssets;

export const getCurrentlyProtected = (calculation: MedicaidCalculation) =>
  calculation.answers.totalExemptAssets;

export const getTotalAssetsProtected = (calculation: MedicaidCalculation) =>
  calculation.answers.totalExemptAssets +
  calculation.answers.excessAssetsProtected +
  calculation.answers.totalSpendDown;

export const getExcessAssetsProtected = (calculation: MedicaidCalculation) =>
  calculation.answers.excessAssetsProtected;

export const getAdditionalMonthlyProtection = (
  calculation: MedicaidCalculation,
) => calculation.answers.monthlyDeficit;

export const getFullMonthsToQualify = (calculation: MedicaidCalculation) =>
  Math.ceil(calculation.answers.minimumMonthsToQualify);

export const getTotalNotSpentOnNHCare = (calculation: MedicaidCalculation) =>
  calculation.answers.totalNotSpentOnNHCare;

export const getTotalProtectedOnceQualified = (
  calculation: MedicaidCalculation,
) =>
  getTotalAssetsProtected(calculation) +
  getFullMonthsToQualify(calculation) *
    getAdditionalMonthlyProtection(calculation);
