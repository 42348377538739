import React, { useContext } from "react";
import { getQuestionnaireDefinition } from "modules/workflow/helpers/questionnaireSchemaParser";
import { MedicaidLeadSummaryProps } from "./types";
import SummaryPage from "modules/workflow/components/QuestionnaireSummary/SummaryPage";
import MedicaidLeadSummaryActions from "../MedicaidLeadSummaryActions";
import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const MedicaidLeadSummary = ({
  clients,
  isEditable,
  questionnaireAnswers,
  questionnairePages,
  questionsBySubquestionnaire,
  onBack,
  onEdit,
  onConfirmSummary,
}: MedicaidLeadSummaryProps) => {
  const { pages, questions } = getQuestionnaireDefinition(
    questionnairePages,
    questionsBySubquestionnaire,
    questionnaireAnswers,
    clients,
  );

  const { lead } = useContext(MedicaidLeadContext);

  if (lead === undefined)
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;

  return (
    <div>
      {pages.map((page, idx) => (
        <SummaryPage
          key={`${page.id}_${idx}`}
          page={page}
          allQuestions={questions}
          allRelatedParties={[]}
          clients={clients}
          onEdit={isEditable ? () => onEdit(idx) : null}
          answers={questionnaireAnswers[page.questionnaireId]}
        />
      ))}
      <div>
        <MedicaidLeadSummaryActions
          onBack={onBack}
          onConfirmSummary={onConfirmSummary}
          medicaidLeadStatus={lead.status}
        />
      </div>
    </div>
  );
};

export default MedicaidLeadSummary;
