import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import {
  fetchMedicaidLead,
  updateMedicaidLeadPartner,
  updateMedicaidLeadStatus,
} from "modules/medicaid/actions";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { PartnerID } from "modules/parties/types/Partner";
import { findPartner } from "modules/partner/actions";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import PartnerMapView, {
  SearchDetails,
} from "screens/core/SearchScreen/PartnerMapView";
import SearchScreenSearch from "screens/core/SearchScreen/SearchInput";
import SearchResults from "screens/core/SearchScreen/SearchResults";
import { getZipFromUrlHash } from "screens/core/SearchScreen/helpers";
import ReactGA from "react-ga4";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { MedicaidLeadStatus } from "modules/medicaid/types";
import { go } from "modules/navigation/helpers/navigator";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const cx = classNames.bind(styles);

const logEvent = (zip?: string, state?: string) => {
  if (!zip || !state) return;

  ReactGA.ga("send", {
    hitType: "event",
    eventCategory: "Medicaid Zip Lookup",
    eventAction: "Search Click",
    eventLabel: state,
    zip,
  });
};

const MedicaidLeadMapContainer = () => {
  const { lead, isLoadingMedicaidLead } = useContext(MedicaidLeadContext);
  const { medicaidLeadId } = useParams<{ medicaidLeadId?: string }>();
  const dispatch = useDispatch();

  const [zip, setZip] = useState<string>("");

  const [currentSearch, setCurrentSearch] = useState<SearchDetails | null>(
    null,
  );

  const [selectedPartnerId, setSelectedPartnerId] = useState<PartnerID | null>(
    null,
  );

  const submitSearch = useCallback(
    async (zipToSearch: string) => {
      selectedPartnerId && setSelectedPartnerId(null);
      setCurrentSearch({ ...currentSearch, isLoading: true });
      const searchResults = await findPartner(zipToSearch, true)(dispatch);
      logEvent(searchResults?.context.zip, searchResults?.context.state);
      setCurrentSearch({
        isLoading: false,
        searchId: String(Date.now()),
        searchResults,
      });
    },
    [currentSearch, dispatch, selectedPartnerId],
  );

  // On hash change
  useEffect(() => {
    const hashListener = () => {
      const hashZip = getZipFromUrlHash();
      if (!hashZip) return;
      if (hashZip !== zip) setZip(hashZip);
      submitSearch(hashZip);
    };

    window.addEventListener("hashchange", hashListener, false);
    return () => window.removeEventListener("hashchange", hashListener);
  }, [submitSearch, zip]);

  // Initial search
  useEffect(() => {
    const hashZip = getZipFromUrlHash();
    hashZip && window.dispatchEvent(new Event("hashchange"));
  }, []);

  if (lead === undefined) {
    if (medicaidLeadId === undefined) {
      return <Redirect to={getRoute(ROUTE_TEMPLATE.ROOT)}></Redirect>;
    } else if (!isLoadingMedicaidLead) {
      fetchMedicaidLead(dispatch, medicaidLeadId);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const handleSelectLawFirm = (partnerId: PartnerID) => {
    updateMedicaidLeadPartner(dispatch, lead.medicaidLeadId, partnerId).then(
      () => {
        updateMedicaidLeadStatus(
          dispatch,
          lead.medicaidLeadId,
          MedicaidLeadStatus.ReadyForPartner,
        ).then(() => {
          go(
            dispatch,
            getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD_FINISHED, {
              medicaidLeadId: lead.medicaidLeadId,
            }),
          );
        });
      },
    );
  };

  const handleToggleLawFirmDetails = (partnerId: PartnerID | null) => {
    setSelectedPartnerId(partnerId);
  };

  return (
    <div className={cx("medicaid-lead-map-container")}>
      <div className={cx("medicaid-lead-map-side-panel")}>
        <div
          className={cx(
            "zip-search",
            currentSearch ? "zip-search--side" : "zip-search--center",
          )}
        >
          <SearchScreenSearch zip={zip} setZip={setZip} />
        </div>
        <div
          className={cx("result-list-panel", {
            "result-list-panel--hidden": !currentSearch,
          })}
        >
          <SearchResults
            isLoading={Boolean(currentSearch?.isLoading)}
            results={currentSearch?.searchResults?.partners}
            onSelectLawFirm={handleSelectLawFirm}
            onShowDetails={handleToggleLawFirmDetails}
            selectedPartnerId={selectedPartnerId || undefined}
          />
        </div>
      </div>
      <div className={cx("map", currentSearch && "map--results-visible")}>
        <PartnerMapView
          onSelectLawFirm={handleSelectLawFirm}
          onToggleLawFirmDetails={handleToggleLawFirmDetails}
          search={currentSearch || undefined}
          mapContainerClassName={cx("map-container")}
        />
      </div>
    </div>
  );
};

export default MedicaidLeadMapContainer;
