import React from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

import FemaleWebP from "static/images/landing/female.webp";
import FemalePNG from "static/images/landing/female.png";
import ImgWithFallback from "components/ImgWithFallback";
import { List, ListItem, Typography } from "@mui/material";

const cx = classNames.bind(styles);

const NoLawyerEliminiation = () => (
  <div className={cx("no-lawyer-elimination")}>
    <div className={cx("landing__container-100")}>
      <div className={cx("no-lawyer-elimination__img")}>
        <ImgWithFallback src={FemaleWebP} fallback={FemalePNG} alt="Female" />
      </div>
      <div className={cx("no-lawyer-elimination__info")}>
        <Typography variant="h2">
          We don't eliminate the lawyer, the lawyer is where it all starts.
        </Typography>
        <Typography variant="body1">
          We enable you to do what you need to. Online. In an instant. And then
          connect you with a local attorney for whenever you need direction,
          support and expertise.
        </Typography>
        <List className={cx("no-lawyer-elimination__actions")} component="ol">
          <ListItem>
            <span>1.</span>
            <Typography variant="h3">Choose your service</Typography>
            <Typography variant="body2">
              Simply select one of the products and answer a few short
              questions.
            </Typography>
          </ListItem>
          <ListItem>
            <span>2.</span>
            <Typography variant="h3">Create your documents</Typography>
            <Typography variant="body2">
              Give us a few more details and tell us about the people you wish
              to include in the plan.
            </Typography>
          </ListItem>
          <ListItem>
            <span>3.</span>
            <Typography variant="h3">Wrap it all up</Typography>
            <Typography variant="body2">
              Decide if you want to finalize it all on your own or use your
              local attorney to insure your plan's proper execution and storage.
            </Typography>
          </ListItem>
        </List>
      </div>
    </div>
  </div>
);

export default NoLawyerEliminiation;
