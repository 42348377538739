import React from "react";
import Banner, { BannerType } from "components/Banner";
import { IconType } from "components/Icons";
import Product from "modules/product/types/Product";
import { showModalConfirm } from "components/Modal";

type DocumentsAssemblyProps = {
  clientWasContacted: boolean;
  externalProducts?: Product[];
  isAdmin: boolean;
  isLoadingDocuments: boolean;
  onClientContacted: () => Promise<void>;
  onDownloadDocuments: () => any;
};

const getOfflineDocumentsBanner = (
  hasExternalProducts: boolean,
  isAdmin: boolean,
  clientWasContacted: boolean,
  onClientContacted: () => Promise<void>,
) => {
  const bannerActionProps =
    isAdmin && !clientWasContacted
      ? {
          label: "Client contacted",
          onClick: () =>
            showModalConfirm({
              title: "Client contacted",
              text: "Once you reached-out to the client to arrange completion of their remaining documents offline, we will consider this plan complete. Do you wish to proceed?",
              onConfirm: onClientContacted,
            }),
        }
      : {};

  return hasExternalProducts ? (
    <Banner
      type={BannerType.INFO}
      title={`${
        isAdmin ? "This" : "Your"
      } plan contains products not available online`}
      description={
        isAdmin
          ? "Please, complete the offline documents directly with the client."
          : clientWasContacted
            ? "Ask our attorney for more details."
            : "You will be contacted by our attorney to agree on the next steps."
      }
      {...bannerActionProps}
    />
  ) : null;
};

const DocumentsAssembly: React.FC<DocumentsAssemblyProps> = ({
  clientWasContacted,
  externalProducts,
  isAdmin,
  isLoadingDocuments,
  onClientContacted,
  onDownloadDocuments,
}) => {
  const offlineDocumentsBanner = getOfflineDocumentsBanner(
    Boolean(externalProducts?.length),
    isAdmin,
    clientWasContacted,
    onClientContacted,
  );

  return (
    <>
      <Banner
        title={isAdmin ? "Online documents" : "Congratulations"}
        description={isAdmin ? "" : "Your documents are ready to download"}
        isLoading={isLoadingDocuments}
        label="Download"
        onClick={() => onDownloadDocuments()}
        buttonIcon={IconType.DOWNLOAD}
      />
      {offlineDocumentsBanner}
    </>
  );
};

export default DocumentsAssembly;
