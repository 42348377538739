import {
  Step,
  WorkflowActionType,
  WorkflowStepDetails,
} from "modules/api/types/WorkflowPayload";
import { LAWYER_INITIATED_WORKFLOW } from "../types/Workflow";

export const hasPassedStep = (
  workflowStepDetails: WorkflowStepDetails,
  step: Step,
): boolean =>
  Boolean(
    [...workflowStepDetails.history, workflowStepDetails.current].find(
      (historyStep) =>
        historyStep.step === step && historyStep.metadata?.source !== "coupled",
    ),
  );

export const hasExecutedAction = (
  workflowStepDetails: WorkflowStepDetails,
  action: WorkflowActionType,
): boolean =>
  Boolean(
    [...workflowStepDetails.history, workflowStepDetails.current].find(
      (historyStep) =>
        historyStep.action === action &&
        historyStep.metadata?.source !== "coupled",
    ),
  );

export const isEditable = (
  isAdmin: boolean,
  workflowStepDetails?: WorkflowStepDetails,
  creator?: string,
): boolean => {
  if (!workflowStepDetails) return false;

  if (isAdmin) {
    return (
      creator === LAWYER_INITIATED_WORKFLOW ||
      (isAtLeast(workflowStepDetails, Step.AwaitingLawyerReview) &&
        !isAtLeast(workflowStepDetails, Step.AwaitingCoupledReviewFinished))
    );
  }

  // TODO Delete block below with GDR-427
  const isAwaitingRepeatedPayment = [
    Step.RepeatedPaymentRequired,
    Step.RepeatedPaymentInitialised,
    Step.RepeatedPaymentFailed,
  ].includes(workflowStepDetails.current.step);
  return (
    (!isPaid(workflowStepDetails) && !isAwaitingRepeatedPayment) ||
    [Step.CustomerChangesDuringReview].includes(
      workflowStepDetails.current.step,
    )
  );
  // TODO End of the block to delete

  // TODO revert commented-out line with GDR-427
  // return !isPaid(workflowStepDetails) || [Step.CustomerChangesDuringReview].includes(workflowStepDetails.current.step);
};

export const isPaid = (workflowStepDetails: WorkflowStepDetails): boolean =>
  Boolean(
    workflowStepDetails &&
      isAtLeast(workflowStepDetails, Step.PaymentSubmitted) &&
      ![
        Step.RepeatedPaymentRequired,
        Step.RepeatedPaymentInitialised,
        Step.RepeatedPaymentFailed,
      ].includes(workflowStepDetails.current.step),
  );

export const isPendingReview = (
  workflowStepDetails: WorkflowStepDetails,
): boolean =>
  Boolean(
    workflowStepDetails &&
      isAtLeast(workflowStepDetails, Step.AwaitingLawyerReview) &&
      !isAtLeast(workflowStepDetails, Step.DocumentsReady),
  );

export const isAgreementConfirmed = (
  workflowStepDetails: WorkflowStepDetails,
): boolean => {
  const result = hasPassedStep(workflowStepDetails, Step.AgreementConfirmed);
  return result;
};

export const wasReviewInProgress = (
  workflowStepDetails: WorkflowStepDetails,
): boolean => {
  const result = hasPassedStep(workflowStepDetails, Step.ReviewInProgress);
  return result;
};

const orderedSteps = [
  Step.Initialized,
  Step.ClientDefined,
  Step.AgreementConfirmed,
  Step.SpouseDefined,
  Step.ChildrenDefined,
  Step.QuestionnaireInProgress,
  Step.WaitingForCoupledConfirmSummary,
  Step.SummaryConfirmed,
  Step.PaymentRequired,
  Step.PaymentInitialised,
  Step.PaymentFailed,
  Step.PaymentSubmitted,
  Step.AwaitingEngagementCheck,
  Step.EngagementRejected,
  Step.AwaitingPaymentCapture,
  Step.AwaitingLawyerReview,
  Step.ReviewInProgress,
  Step.AwaitingCoupledReviewFinished,
  Step.CustomerChangesDuringReview,
  Step.AwaitingConfirmCoupledCustomerChangesDuringReview,
  Step.AwaitingCustomerConfirmReview,
  Step.AwaitingCoupledCustomerConfirmReview,
  Step.RepeatedPaymentRequired,
  Step.RepeatedPaymentInitialised,
  Step.RepeatedPaymentSubmitted,
  Step.RepeatedPaymentFailed,
  Step.RepeatedPaymentConfirmed,
  Step.ContactCustomer,
  Step.DocumentsReady,
  Step.AssetProtectionRequested,

  // Deprecated
  Step.DocumentsRequested,
  Step.DocumentRenderFailed,
  Step.DocumentsRendered,
  Step.DocumentsDownloaded,

  Step.Finalized,
];

export const isAtLeast = (
  workflowStepDetails: WorkflowStepDetails,
  step: Step,
): boolean =>
  orderedSteps.indexOf(workflowStepDetails.current.step) >=
  orderedSteps.indexOf(step);

export const hasDocumentsAvailable = (
  workflowStepDetails: WorkflowStepDetails,
  step: Step,
): boolean => isAtLeast(workflowStepDetails, Step.ContactCustomer);
