import firebase from "firebase/compat/app";
import React from "react";
import Signup from "./components/Signup";

interface SignupScreenProps {
  authProvider: firebase.auth.Auth;
  userJurisdiction?: string;
}

const SignupScreen = ({
  authProvider,
  userJurisdiction,
}: SignupScreenProps) => (
  <Signup authProvider={authProvider} userJurisdiction={userJurisdiction} />
);

export default SignupScreen;
