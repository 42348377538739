import * as React from "react";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import Workflow from "modules/workflow/types/Workflow";
import PaginatedList from "components/PaginatedList";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { IconType } from "components/Icons";
import ClientDashboardProductsItem from "./ClientDashboardProductsItem";
import CollapsableCard from "components/CollapsableCard";
import groupCoupledWorkflows, {
  CoupledWorkflow,
  isCoupledWorkflow,
} from "modules/workflow/helpers/groupCoupledWorkflows";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Partner from "modules/parties/types/Partner";
import { useSelector } from "redux/reducer";
import { useMemo } from "react";
import { STATES_MAP } from "resources/options/states";
import Alert, { AlertType } from "components/Alert";

type CoupledDashboardItemProps = {
  coupledWorkflow: CoupledWorkflow;
  onOpenWorkflow: (
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) => any;
  onViewWorkflowDocuments: (workflow: Workflow) => any;
  onViewWorkflowParties: (workflow: Workflow) => any;
  onViewWorkflowSummary: (workflow: Workflow) => any;
  userState?: string;
};

const CoupledDashboardItem: React.FC<CoupledDashboardItemProps> = ({
  coupledWorkflow: { primary, secondary },
  onOpenWorkflow,
  onViewWorkflowDocuments,
  onViewWorkflowParties,
  onViewWorkflowSummary,
  userState,
}) => (
  <div className={styles["coupled-workflows"]}>
    <ClientDashboardProductsItem
      key={primary.id}
      onOpenWorkflow={onOpenWorkflow}
      workflow={primary}
      onViewParties={onViewWorkflowParties}
      onProceedToDownload={onViewWorkflowDocuments}
      onViewSummary={onViewWorkflowSummary}
      userState={userState}
    />
    <ClientDashboardProductsItem
      key={secondary.id}
      onOpenWorkflow={onOpenWorkflow}
      workflow={secondary}
      onViewParties={onViewWorkflowParties}
      onProceedToDownload={onViewWorkflowDocuments}
      onViewSummary={onViewWorkflowSummary}
      userState={userState}
    />
  </div>
);

type ClientDashboardProductsProps = {
  assignedWorkflows: Workflow[];
  availableWorkflows: Record<
    string,
    DefinitionWithProductsPlansAndAddons
  > | null;
  closed?: boolean;
  onOpenWorkflow: (workflow: Workflow, pageIdx?: number) => any;
  onTriggerNewWorkflow: () => any;
  onViewWorkflowDocuments: (workflow: Workflow) => any;
  onViewWorkflowParties: (workflow: Workflow) => any;
  onViewWorkflowSummary: (workflow: Workflow) => any;
  partnerActive: boolean;
  userState?: string;
};

const ClientDashboardProducts: React.FunctionComponent<
  ClientDashboardProductsProps
> = ({
  assignedWorkflows,
  availableWorkflows,
  closed,
  onOpenWorkflow,
  onTriggerNewWorkflow,
  onViewWorkflowDocuments,
  onViewWorkflowParties,
  onViewWorkflowSummary,
  partnerActive,
  userState,
}) => {
  const partner = useSelector<Partner | null>((state) => state.partner);
  const renderItems = groupCoupledWorkflows(assignedWorkflows);

  const isSupportedState = useMemo<boolean>(
    () =>
      userState !== undefined &&
      Boolean(partner?.data.jurisdiction.includes(userState)),
    [partner?.data.jurisdiction, userState],
  );

  return (
    <CollapsableCard
      closed={closed}
      count={renderItems.length}
      title="My Documents"
    >
      {userState !== undefined &&
        STATES_MAP[userState] !== undefined &&
        !isSupportedState && (
          <Alert
            type={AlertType.WARNING}
            message={`${partner?.data.displayName} does not support ${STATES_MAP[userState]}. To begin creating a new document please change your state or choose another partner.`}
          />
        )}
      {renderItems.length ? (
        <React.Fragment>
          <div className={styles["client-dashboard-products__actions"]}>
            {availableWorkflows && partnerActive && (
              <TabbedButton
                icon={IconType.PLUS}
                label="Add a new product"
                style={TabbedButtonType.SECONDARY}
                onClick={() => onTriggerNewWorkflow()}
                disabled={!isSupportedState}
              />
            )}
            {!partnerActive && (
              <div
                className={
                  styles["client-dashboard-products__partner-inactive"]
                }
              >
                <p>
                  Unfortunately you cannot add any new products as your Attorney
                  is no longer active on the Aturna Platform.
                </p>
                <p>
                  <Link to={"/search"}>Click here</Link> to find another Aturna
                  law firm in your area.
                </p>
              </div>
            )}
          </div>
          <PaginatedList<Workflow | CoupledWorkflow>
            renderItem={(item) =>
              isCoupledWorkflow(item) ? (
                <CoupledDashboardItem
                  key={item.primary.id}
                  coupledWorkflow={item}
                  onOpenWorkflow={onOpenWorkflow}
                  onViewWorkflowDocuments={onViewWorkflowDocuments}
                  onViewWorkflowParties={onViewWorkflowParties}
                  onViewWorkflowSummary={onViewWorkflowSummary}
                  userState={userState}
                />
              ) : (
                <ClientDashboardProductsItem
                  key={item.id}
                  onOpenWorkflow={onOpenWorkflow}
                  workflow={item}
                  onViewParties={onViewWorkflowParties}
                  onProceedToDownload={onViewWorkflowDocuments}
                  onViewSummary={onViewWorkflowSummary}
                  userState={userState}
                />
              )
            }
            items={renderItems}
            itemNamePlural="workflows"
            pageSize={5}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {partnerActive && availableWorkflows && (
            <div className={styles["client-dashboard-products__empty"]}>
              <div className={styles["client-dashboard-products__empty__text"]}>
                You have not added a document yet
              </div>
              {partnerActive && availableWorkflows && (
                <div>
                  <TabbedButton
                    icon={IconType.PLUS}
                    label="Add a new document"
                    mood={TabbedButtonMood.POSITIVE}
                    onClick={() => onTriggerNewWorkflow()}
                    disabled={!isSupportedState}
                  />
                </div>
              )}
            </div>
          )}

          {!partnerActive && (
            <div
              className={styles["client-dashboard-products__partner-inactive"]}
            >
              <p>
                Unfortunately you cannot add any new products as your Attorney
                is no longer active on the Aturna Platform.
              </p>
              <p>
                <Link to={"/search"}>
                  Would you like to find another Aturna Law Firm?
                </Link>
              </p>
            </div>
          )}
        </React.Fragment>
      )}
    </CollapsableCard>
  );
};

export default ClientDashboardProducts;
