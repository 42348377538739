import { keyBy, mapValues } from "lodash";

// US only
export const TIMEZONES_OPTIONS = [
  { value: "America/Adak", label: "Adak (HST / HDT)" },
  { value: "America/Anchorage", label: "Anchorage (AKST / AKDT)" },
  { value: "America/Boise", label: "Boise (MST / MDT)" },
  { value: "America/Chicago", label: "Chicago (CST / CDT)" },
  { value: "America/Denver", label: "Denver (MST / MDT)" },
  { value: "America/Detroit", label: "Detroit (EST / EDT)" },
  {
    value: "America/Indiana/Indianapolis",
    label: "Indiana / Indianapolis (EST / EDT)",
  },
  { value: "America/Indiana/Knox", label: "Indiana / Knox (CST / CDT)" },
  { value: "America/Indiana/Marengo", label: "Indiana / Marengo (EST / EDT)" },
  {
    value: "America/Indiana/Petersburg",
    label: "Indiana / Petersburg (EST / EDT)",
  },
  {
    value: "America/Indiana/Tell_City",
    label: "Indiana / Tell City (CST / CDT)",
  },
  { value: "America/Indiana/Vevay", label: "Indiana / Vevay (EST / EDT)" },
  {
    value: "America/Indiana/Vincennes",
    label: "Indiana / Vincennes (EST / EDT)",
  },
  { value: "America/Indiana/Winamac", label: "Indiana / Winamac (EST / EDT)" },
  { value: "America/Juneau", label: "Juneau (AKST / AKDT)" },
  {
    value: "America/Kentucky/Louisville",
    label: "Kentucky / Louisville (EST / EDT)",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "Kentucky / Monticello (EST / EDT)",
  },
  { value: "America/Los_Angeles", label: "Los Angeles (PST / PDT)" },
  { value: "America/Menominee", label: "Menominee (CST / CDT)" },
  { value: "America/Metlakatla", label: "Metlakatla (AKST / AKDT)" },
  { value: "America/New_York", label: "New York (EST / EDT)" },
  { value: "America/Nome", label: "Nome (AKST / AKDT)" },
  {
    value: "America/North_Dakota/Beulah",
    label: "North Dakota / Beulah (CST / CDT)",
  },
  {
    value: "America/North_Dakota/Center",
    label: "North Dakota / Center (CST / CDT)",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "North Dakota / New Salem (CST / CDT)",
  },
  { value: "America/Phoenix", label: "Phoenix (MST / MDT)" },
  { value: "America/Sitka", label: "Sitka (AKST / AKDT)" },
  { value: "America/Yakutat", label: "Yakutat (AKST / AKDT)" },
  { value: "Pacific/Honolulu", label: "Honolulu (HST / HDT)" },
];

export const TIMEZONES_MAP = mapValues(
  keyBy(TIMEZONES_OPTIONS, "value"),
  ({ label }) => label,
);
