import React, { useContext } from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import LandingButton from "components/Button";
import { ChevronColour } from "components/Button/types";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { useDispatch } from "react-redux";
import DataLayerContext from "contexts/DataLayerContext";
import {
  CTALocation,
  CTAType,
  EventName,
} from "contexts/DataLayerContext/types";

const cx = classNames.bind(styles);

const BUTTON_LABEL = "FIND YOUR NEAREST LAW FIRM";

const NearestLawFirm = () => {
  const dispatch = useDispatch();
  const { sendEvent } = useContext(DataLayerContext);

  const handleNavigateToMap = () => {
    sendEvent?.({
      event: EventName.CTAClick,
      cta_type: CTAType.MAP,
      cta_location: CTALocation.FOOTER_MAP,
      cta_text: BUTTON_LABEL,
    });

    go(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));
  };

  return (
    <div className={cx("nearest-law-firm")}>
      <div className={cx("landing__container-100")}>
        <LandingButton
          label={BUTTON_LABEL}
          chevronColour={ChevronColour.WHITE}
          chevronRight
          onClick={handleNavigateToMap}
        />
      </div>
    </div>
  );
};

export default NearestLawFirm;
