import Alert, { AlertType } from "components/Alert";
import Link from "components/Link";
import { Step, WorkflowStepDetails } from "modules/api/types/WorkflowPayload";
import { isPaid } from "modules/workflow/helpers/lifecycle";
import useWorkflowActions from "modules/workflow/hooks/useWorkflowActions";
import React from "react";
import { useWorkflowDetails } from "screens/workflowDetails/context";
import { CLIENT_INITIATED_WORKFLOW } from "../../types/Workflow";

type Props = { steps: WorkflowStepDetails; creator: string };

const SummaryDescription: React.FC<Props> = ({ steps, creator }) => {
  const { coupledWorkflow, isAdmin } = useWorkflowDetails();
  const { handleSwitchToSpouse } = useWorkflowActions();

  const getAwaitingCoupledCompletion = () => (
    <Alert
      message="Please, complete the spouse's interview first"
      type={AlertType.WARNING}
      action={
        coupledWorkflow && (
          <Link onClick={handleSwitchToSpouse}>Switch to spouse</Link>
        )
      }
    />
  );

  const getClientPageDescription = (
    steps: WorkflowStepDetails,
  ): JSX.Element | undefined => {
    switch (steps.current.step) {
      case Step.AwaitingLawyerReview:
      case Step.ReviewInProgress:
      case Step.AwaitingCoupledReviewFinished:
        return (
          <Alert
            message="Good news! Your interview is under review by our lawyers. We'll get back to you once the review is complete."
            type={AlertType.SUCCESS}
          />
        );

      case Step.PaymentSubmitted:
      case Step.RepeatedPaymentSubmitted:
        return (
          <Alert
            message="Your payment is currently being processed..."
            type={AlertType.INFO}
          />
        );

      case Step.AwaitingCoupledCustomerConfirmReview:
        return (
          <Alert
            type={AlertType.WARNING}
            message="Please, review the coupled interview before proceeding"
            action={
              coupledWorkflow ? (
                <Link onClick={handleSwitchToSpouse}>Switch to spouse</Link>
              ) : null
            }
          />
        );

      case Step.AwaitingConfirmCoupledCustomerChangesDuringReview:
      case Step.WaitingForCoupledConfirmSummary:
        return getAwaitingCoupledCompletion();

      case Step.AwaitingEngagementCheck:
        return (
          <Alert
            message="Your workflow is complete; the law firm is reviewing the engagement agreement."
            type={AlertType.INFO}
          />
        );

      case Step.EngagementRejected:
        return (
          <Alert
            message="The law firm rejected the engagement agreement. You will be contacted about next steps."
            type={AlertType.ERROR}
          />
        );

      default:
        return undefined;
    }
  };

  const getAdminPageDescription = (
    steps: WorkflowStepDetails,
    creator: string,
  ): JSX.Element | undefined => {
    switch (steps.current.step) {
      case Step.WaitingForCoupledConfirmSummary:
        return getAwaitingCoupledCompletion();

      case Step.AwaitingCoupledReviewFinished:
        return (
          <Alert
            message="Coupled interview is still pending your review."
            type={AlertType.WARNING}
            action={
              coupledWorkflow && (
                <Link onClick={handleSwitchToSpouse}>Switch to spouse</Link>
              )
            }
          />
        );

      case Step.CustomerChangesDuringReview:
        return (
          <Alert
            message="The interview is pending client input."
            type={AlertType.WARNING}
          />
        );

      case Step.AwaitingConfirmCoupledCustomerChangesDuringReview:
        return (
          <Alert
            message="Coupled interview is still pending client input."
            type={AlertType.WARNING}
          />
        );

      case Step.RepeatedPaymentRequired:
      case Step.PaymentRequired:
        return <Alert message="Awaiting payment" type={AlertType.WARNING} />;

      case Step.AwaitingCustomerConfirmReview:
      case Step.AwaitingCoupledCustomerConfirmReview:
        return (
          <Alert
            message="Your review is completed. The final interview answers need to be confirmed by the client before proceeding."
            type={AlertType.INFO}
          />
        );

      case Step.PaymentSubmitted:
      case Step.RepeatedPaymentSubmitted:
        return <Alert message="Processing payment..." type={AlertType.INFO} />;

      default: {
        return !isPaid(steps) && creator === CLIENT_INITIATED_WORKFLOW ? (
          <Alert message="Interview incomplete..." type={AlertType.WARNING} />
        ) : undefined;
      }
    }
  };

  return (
    (isAdmin
      ? getAdminPageDescription(steps, creator)
      : getClientPageDescription(steps)) || null
  );
};

export default SummaryDescription;
