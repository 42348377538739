import PageHeader from "components/PageHeader";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import React from "react";
import { useDispatch } from "react-redux";
import { go } from "modules/navigation/helpers/navigator";
import { partnerAdminRoutes } from "modules/globalAdmin/routes";
import LoadingOverlay from "components/LoadingOverlay";
import { Redirect } from "react-router-dom";
import BackLink from "components/BackLink";
import usePartner from "modules/globalAdmin/hooks/usePartner";
import moment from "moment";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { Box } from "@mui/material";
import styles from "./styles.module.scss";
import usePartnerAudit from "modules/globalAdmin/hooks/usePartnerAudit";

type Props = { partnerId: string };

const PartnersAdminDetail: React.FC<Props> = ({ partnerId }) => {
  const dispatch = useDispatch();

  const { partner, error } = usePartner(partnerId);
  const { partnerAudit, partnerAuditError } = usePartnerAudit(partnerId);

  if (!partner) return <LoadingOverlay />;
  if (!partnerAudit && !partnerAuditError) return <LoadingOverlay />;
  if (error) return <Redirect to={ROUTE_TEMPLATE.ADMIN_PARTNERS} />;

  const partnerName = partner.data.displayName || partner.id;

  return (
    <>
      <BackLink
        onClick={() => {
          return go(dispatch, ROUTE_TEMPLATE.ADMIN_PARTNERS);
        }}
        text="Back to Partner Management"
      />
      <PageHeader title={partnerName} />
      <Box mb={4}>
        <strong>Created: </strong>{" "}
        {moment(partner.created).format("YYYY/MM/DD hh:mm A")}
        <br />
        <strong>Last updated: </strong>{" "}
        {moment(partner.updated).format("YYYY/MM/DD hh:mm A")}
        <br />
        <strong>Partner Status: </strong>{" "}
        {partner.archived ? "Archived" : "Valid"}
        <br />
        <strong>Reason for Archiving:</strong>{" "}
        {partnerAudit ? partnerAudit.reason : ""}
      </Box>
      <div className={styles["partner-detail__buttons"]}>
        {Object.entries(partnerAdminRoutes).map(([slug, route]) => (
          <TabbedButton
            onClick={() =>
              go(
                dispatch,
                `${getRoute(
                  ROUTE_TEMPLATE.ADMIN_PARTNERS,
                )}/${partnerId}/${slug}`,
              )
            }
            style={TabbedButtonType.SECONDARY}
            key={slug}
          >
            {route.title}
          </TabbedButton>
        ))}
      </div>
    </>
  );
};

export default PartnersAdminDetail;
