import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames/bind";
import { findPartner } from "modules/partner/actions";

import styles from "./styles.module.scss";
import PartnerMapView, { SearchDetails } from "./PartnerMapView";
import SearchResults from "./SearchResults";
import { getZipFromUrlHash } from "./helpers";
import SearchScreenSearch from "./SearchInput";
import ReactGA from "react-ga4";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EventName,
  SearchEngagementAction,
} from "contexts/DataLayerContext/types";
import { PartnerID } from "modules/parties/types/Partner";
import { navigateToPartnerPricing } from "modules/navigation/helpers/navigator";

const cx = classNames.bind(styles);

const logEvent = (zip?: string, state?: string) => {
  if (!zip || !state) return;

  ReactGA.ga("send", {
    hitType: "event",
    eventCategory: "Zip Lookup",
    eventAction: "Search Click",
    eventLabel: state,
    zip,
  });
};

const SearchScreenContainer = () => {
  const dispatch = useDispatch();
  const [zip, setZip] = useState<string>("");
  const [currentSearch, setCurrentSearch] = useState<SearchDetails | null>(
    null,
  );
  const [selectedPartner, setSelectedPartner] = useState<string | null>(null);
  const { sendEvent } = useContext(DataLayerContext);

  const submitSearch = useCallback(
    async (zipToSearch: string) => {
      selectedPartner && setSelectedPartner(null);
      setCurrentSearch({ ...currentSearch, isLoading: true });
      const searchResults = await findPartner(zipToSearch)(dispatch);
      if (searchResults === undefined) {
        sendEvent?.({
          event: EventName.SearchEngagement,
          search_action: SearchEngagementAction.SEARCH_ERROR,
          search_zip_code: zip,
        });
      } else {
        sendEvent?.({
          event: EventName.SearchEngagement,
          search_action: SearchEngagementAction.SEARCH_PERFORMED,
          search_zip_code: zip,
          search_result_count: searchResults.partners.length,
        });
      }
      logEvent(searchResults?.context.zip, searchResults?.context.state);
      setCurrentSearch({
        isLoading: false,
        searchId: String(Date.now()),
        searchResults,
      });
    },
    [currentSearch, dispatch, selectedPartner, sendEvent, zip],
  );

  // On hash change
  useEffect(() => {
    const hashListener = () => {
      const hashZip = getZipFromUrlHash();
      if (!hashZip) return;
      if (hashZip !== zip) setZip(hashZip);
      submitSearch(hashZip);
    };

    window.addEventListener("hashchange", hashListener, false);
    return () => window.removeEventListener("hashchange", hashListener);
  }, [submitSearch, zip]);

  // Initial search
  useEffect(() => {
    const hashZip = getZipFromUrlHash();
    hashZip && window.dispatchEvent(new Event("hashchange"));
  }, []);

  const handleSelectLawFirm = useCallback(
    (partnerId: PartnerID) => {
      navigateToPartnerPricing(dispatch, partnerId);
    },
    [dispatch],
  );

  const handleToggleLawFirmDetails = useCallback(
    (partnerId: string | null, open?: boolean) => {
      if (open === true) return setSelectedPartner(partnerId);
      if (open === false)
        return selectedPartner &&
          (partnerId === selectedPartner || partnerId === null)
          ? setSelectedPartner(null)
          : null;
      setSelectedPartner(selectedPartner === partnerId ? null : partnerId);
    },
    [selectedPartner],
  );

  return (
    <div className={cx("root", currentSearch ? "root--side" : "root--center")}>
      <div
        className={cx(
          "zip-search",
          currentSearch ? "zip-search--side" : "zip-search--center",
        )}
      >
        <SearchScreenSearch {...{ zip, setZip }} />
      </div>
      <div
        className={cx("result-list-panel", {
          "result-list-panel--hidden": !currentSearch,
        })}
      >
        <SearchResults
          isLoading={Boolean(currentSearch?.isLoading)}
          results={currentSearch?.searchResults?.partners}
          onSelectLawFirm={handleSelectLawFirm}
          onShowDetails={handleToggleLawFirmDetails}
          selectedPartnerId={selectedPartner || undefined}
        />
      </div>
      <div className={cx("map", currentSearch && "map--results-visible")}>
        <PartnerMapView
          search={currentSearch || undefined}
          selectedPartnerId={selectedPartner || undefined}
          onSelectLawFirm={handleSelectLawFirm}
          onToggleLawFirmDetails={handleToggleLawFirmDetails}
        />
      </div>
    </div>
  );
};

export default SearchScreenContainer;
