import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames/bind";
import Alert, { AlertType } from "components/Alert";
import Confirm from "components/Forms/Inputs/Confirm";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { PaymentActionType } from "modules/api/types/Payment";
import { Step, WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import {
  isAtLeast,
  wasReviewInProgress,
} from "modules/workflow/helpers/lifecycle";
import { useWorkflowDetails } from "screens/workflowDetails/context";
import { showModalConfirm } from "components/Modal";
import logger from "logger";
import useWorkflowActions from "modules/workflow/hooks/useWorkflowActions";

import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type Props = {
  isEditable: boolean;
  onNext?: () => any;
  onCancelPreview?: () => any;
  onConfirmSummary: () => Promise<void>;
  onPreviewDocuments: () => Promise<void>;
  onRejectLawyerReview: () => Promise<void>;
  onTriggerPayment: (paymentType: PaymentActionType) => void;
  workflowState: WorkflowPayloadState;
};

const SummaryAction: React.FC<Props> = ({
  isEditable,
  onNext,
  onCancelPreview,
  onConfirmSummary,
  onPreviewDocuments,
  onRejectLawyerReview,
  onTriggerPayment,
  workflowState,
}) => {
  const { isAdmin, coupledWorkflow } = useWorkflowDetails();

  const [checkbox, setCheckbox] = useState<boolean>(false);
  const [isPending, setIsPending] = useState(false);

  const isMounted = useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const {
    handleAcceptEngagementAgreement,
    handleRejectEngagementAgreement,
    handleAcceptLawyerReview,
    handleFinishLawyerReview,
    handleSwitchToSpouse,
    handleReinitiateReview,
  } = useWorkflowActions();

  const { steps } = workflowState;
  const isComplete =
    workflowState.progress?.summary?.completed ===
    workflowState.progress?.summary?.total;

  const isCoupledWorkflow = Boolean(workflowState?.coupledWorkflowId);

  const handleConfirmSummary = async () => {
    setIsPending(true);
    try {
      await onConfirmSummary();
      onNext && (await onNext());
    } catch (e) {
      logger.error("Summary confirmation failed:", e);
    }
    if (isMounted.current) {
      setIsPending(false);
    }
  };

  const handleRejectLawyerReview = useCallback(
    () =>
      showModalConfirm({
        text: "We will send your interview back to the law firm for an additional review. If this is a coupled interview, your spouse’s interview will also be sent back to the law firm for review. Do you wish to proceed?",
        title: "Reject lawyer review",
        onConfirm: onRejectLawyerReview,
      }),
    [onRejectLawyerReview],
  );

  const handlePreviewDocuments = useCallback(
    () =>
      showModalConfirm({
        text: "The documents you are about to preview are still drafts and subject to change until your interview is finalized.",
        title: "Preview documents",
        labelConfirm: "Proceed",
        onConfirm: onPreviewDocuments,
        onCancel: onCancelPreview,
      }),
    [onPreviewDocuments, onCancelPreview],
  );

  const handleNavigateToCoupledWorkflow = useCallback(
    () => handleSwitchToSpouse(),
    [handleSwitchToSpouse],
  );

  const previewDocumentsBtn = (
    <TabbedButton
      onClick={handlePreviewDocuments}
      style={TabbedButtonType.SECONDARY}
      disabled={!isComplete}
      tooltip={
        isComplete
          ? undefined
          : "Documents can't be previewed due to incomplete interview"
      }
    >
      Preview documents
    </TabbedButton>
  );

  const renderClientSummaryActions = () => {
    switch (steps.current.step) {
      case Step.WaitingForCoupledConfirmSummary:
        return getAwaitingCoupledCompletionAction();

      case Step.SummaryConfirmed:
      case Step.PaymentFailed:
        return getSummaryConfirmedAction();

      case Step.DocumentsRequested: // Deprecated
      case Step.DocumentRenderFailed: // Deprecated
      case Step.DocumentsRendered: // Deprecated
      case Step.DocumentsDownloaded: // Deprecated
      case Step.DocumentsReady:
        return (
          <TabbedButton onClick={onNext} mood={TabbedButtonMood.POSITIVE}>
            Proceed to documents
          </TabbedButton>
        );

      case Step.PaymentInitialised:
        return (
          <TabbedButton onClick={onNext} mood={TabbedButtonMood.POSITIVE}>
            Proceed to payment
          </TabbedButton>
        );

      case Step.RepeatedPaymentRequired:
      case Step.RepeatedPaymentInitialised:
        return (
          <TabbedButton
            onClick={() => onTriggerPayment(PaymentActionType.RepeatedPayment)}
            mood={TabbedButtonMood.POSITIVE}
          >
            Proceed to payment
          </TabbedButton>
        );

      case Step.AwaitingCustomerConfirmReview:
        return (
          <div className={cx("summary-actions__btn-group")}>
            <TabbedButton
              onClick={handleRejectLawyerReview}
              style={TabbedButtonType.SECONDARY}
            >
              Reject
            </TabbedButton>
            <TabbedButton
              onClick={handleAcceptLawyerReview}
              mood={TabbedButtonMood.POSITIVE}
            >
              Accept &amp; Finalize
            </TabbedButton>
          </div>
        );

      case Step.AwaitingCoupledCustomerConfirmReview:
        return (
          <>
            <Alert
              type={AlertType.WARNING}
              message="Please, review the coupled interview before proceeding"
            />
            {coupledWorkflow ? (
              <div className={cx("summary-actions__alert-btn")}>
                <TabbedButton onClick={handleNavigateToCoupledWorkflow}>
                  Switch to spouse
                </TabbedButton>
              </div>
            ) : null}
          </>
        );

      case Step.CustomerChangesDuringReview:
        return getConfirmSummaryAction();

      case Step.AwaitingConfirmCoupledCustomerChangesDuringReview:
        return getAwaitingCoupledCompletionAction();

      default:
        return !isAtLeast(steps, Step.SummaryConfirmed)
          ? getConfirmSummaryAction()
          : null;
    }
  };

  const renderAdminSummaryActions = () => {
    switch (steps.current.step) {
      case Step.WaitingForCoupledConfirmSummary:
        return getAwaitingCoupledCompletionAction();

      case Step.SummaryConfirmed:
        return getSummaryConfirmedAction();

      case Step.AwaitingEngagementCheck:
        return (
          <div>
            <p>Would you like to accept or reject this engagement agreement?</p>
            {isCoupledWorkflow && (
              <div className={styles["summary-actions__alert"]}>
                <Alert
                  type={AlertType.WARNING}
                  message={`This is a coupled workflow and your acceptance or rejection will apply to both spouses.
                            Make sure you've completed a conflicts check for both spouses.`}
                />
              </div>
            )}
            <div
              className={cx(
                "summary-actions__btn-group",
                "summary-actions__confirm",
              )}
            >
              <TabbedButton
                onClick={handleRejectEngagementAgreement}
                mood={TabbedButtonMood.NEGATIVE}
              >
                Reject
              </TabbedButton>
              <TabbedButton
                onClick={handleAcceptEngagementAgreement}
                mood={TabbedButtonMood.POSITIVE}
              >
                Accept
              </TabbedButton>
            </div>
          </div>
        );

      case Step.ReviewInProgress:
        return (
          <div className={cx("summary-actions__btn-group")}>
            {previewDocumentsBtn}
            <TabbedButton
              onClick={handleFinishLawyerReview}
              mood={TabbedButtonMood.POSITIVE}
            >
              Finish review
            </TabbedButton>
          </div>
        );

      case Step.AwaitingCoupledReviewFinished: {
        return coupledWorkflow ? (
          <div className={cx("summary-actions__btn-group")}>
            {previewDocumentsBtn}
            <TabbedButton
              onClick={handleNavigateToCoupledWorkflow}
              mood={TabbedButtonMood.POSITIVE}
            >
              Review coupled interview
            </TabbedButton>
          </div>
        ) : null;
      }

      case Step.DocumentsRequested: // Deprecated
      case Step.DocumentRenderFailed: // Deprecated
      case Step.DocumentsRendered: // Deprecated
      case Step.DocumentsDownloaded: // Deprecated
      case Step.DocumentsReady:
        return (
          <div className={cx("summary-actions__btn-group")}>
            {wasReviewInProgress(steps) && (
              <TabbedButton
                onClick={handleReinitiateReview}
                style={TabbedButtonType.SECONDARY}
              >
                Reinitiate Review
              </TabbedButton>
            )}
            <TabbedButton onClick={onNext} mood={TabbedButtonMood.POSITIVE}>
              Proceed to documents
            </TabbedButton>
          </div>
        );

      default:
        return isEditable && !isAtLeast(steps, Step.SummaryConfirmed)
          ? getConfirmSummaryAction()
          : null;
    }
  };

  const getConfirmSummaryAction = () => (
    <>
      <h2>Are you sure?</h2>
      <div>
        <Confirm
          value={checkbox}
          onValueChange={setCheckbox}
          label="I confirm that all information listed above is accurate to the best of my knowledge."
        />
        <div className={styles["summary-actions__confirm"]}>
          <TabbedButton
            disabled={!checkbox || isPending}
            onClick={handleConfirmSummary}
          >
            {isPending ? "Please wait..." : "Confirm"}
          </TabbedButton>
        </div>
      </div>
    </>
  );

  const getAwaitingCoupledCompletionAction = () => (
    <>
      <Alert
        type={AlertType.WARNING}
        message="Please, complete the spouse's interview first"
      />
      {coupledWorkflow ? (
        <div className={cx("summary-actions__alert-btn")}>
          <TabbedButton onClick={handleNavigateToCoupledWorkflow}>
            Switch to spouse
          </TabbedButton>
        </div>
      ) : null}
    </>
  );

  const getSummaryConfirmedAction = () => (
    <div className={cx("summary-actions__alert-btn")}>
      <TabbedButton onClick={onNext}>Proceed to payment</TabbedButton>
    </div>
  );

  const action = isAdmin
    ? renderAdminSummaryActions()
    : renderClientSummaryActions();

  if (action === null) return null;

  return <div className={cx("summary-actions")}>{action}</div>;
};

export default SummaryAction;
