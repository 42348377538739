import * as React from "react";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { showModalConfirm, ModalControls } from "components/Modal";
import { QuestionID, QuestionType } from "modules/workflow/types/Questionnaire";

export const showContactFormModal = (
  onSubmitForm: (body: string) => Promise<any>,
  onCancel?: () => void,
) => {
  let modalControls: ModalControls;
  const getModalControls = (controls: ModalControls) => {
    modalControls = controls;
  };

  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const handleSubmit = async (values: Record<QuestionID, any>) => {
    modalControls?.busy(true);
    const result = await onSubmitForm(values.message);
    if (result) {
      modalControls?.close();
    } else {
      modalControls?.busy(false);
    }
  };

  const handleFormSubmit = async (): Promise<void> =>
    submitHandle ? submitHandle() : Promise.resolve();

  const handleFormCancel = () => {
    modalControls?.close();
    onCancel && onCancel();
  };

  showModalConfirm({
    getModalControls: getModalControls,
    labelConfirm: "Send",
    onCancel: handleFormCancel,
    onConfirm: handleFormSubmit,
    renderContent: (controls: ModalControls) => (
      <QuestionnaireForm
        getSubmitHandle={getSubmitHandle}
        onSavePageAnswers={async (_, values) => {
          controls.busy(true);
          await handleSubmit(values);
          controls.close();
        }}
        customFormProps={{ showSaveAndExitButton: false }}
        questionsBySubquestionnaire={{
          contactForm: {
            message: {
              id: "message",
              data: {
                descriptions: [{ label: "Your message" }],
                required: true,
                definition: { type: QuestionType.LONG_TEXT },
              },
            },
          },
        }}
        questionnairePages={[
          {
            id: "contactFormPage",
            caption: "",
            questionnaireId: "contactForm",
            sections: [{ caption: "", questions: ["message"] }],
          },
        ]}
      />
    ),
    title: "Contact your lawyer",
  });
};
