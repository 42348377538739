import { API, post } from "modules/api";
import UpdateWorkflowResponseType from "modules/api/responseTypes/UpdateWorkflowResponseType";
import { ChangeWorkflowAction } from "modules/api/types/WorkflowPayload";
import { parseWorkflow } from "../helpers/parseWorkflow";
import Workflow from "../types/Workflow";

export const callFinalizeQuestionnaireAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.ConfirmSummary },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callConfirmAgreementAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.ConfirmAgreement },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callSetSigningOptionAPI = async (
  workflowId: string,
  setSupervisedSigning: boolean,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    {
      type: ChangeWorkflowAction.SetSupervisedSigning,
      enabled: setSupervisedSigning,
    },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callSetAddonsAPI = async (
  workflowId: string,
  workflowOwner: string | undefined,
  selectedAddons: string[],
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    {
      type: ChangeWorkflowAction.SetAddons,
      ids: selectedAddons,
    },
    { asUser: workflowOwner },
  );
  return parseWorkflow(response.data);
};

export const callStartLawyerReviewAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.StartReview },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callFinishLawyerReviewAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.FinishReview },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callAcceptEngagementAgreementAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.AcceptEngagementAgreement },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callRejectEngagementAgreementAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.RejectEngagementAgreement },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callAcceptLawyerReviewAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.ConfirmReview },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callRejectLawyerReviewAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.RejectReview },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callReinitiateLawyerReviewAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.ReinitiateReview },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callClonePlanAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.InitializeCoupled },
    { asUser },
  );
  return parseWorkflow(response.data);
};

export const callConfirmClientContactedAPI = async (
  workflowId: string,
  asUser?: string,
): Promise<Workflow> => {
  const endpoint = API.WORKFLOW({ workflowId });
  const response: UpdateWorkflowResponseType = await post(
    endpoint,
    { type: ChangeWorkflowAction.CustomerContacted },
    { asUser },
  );
  return parseWorkflow(response.data);
};
