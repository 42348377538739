import React, { useEffect } from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";
import Footer from "components/Footer";
import { NotificationWrapper } from "components/Notification";
import { ModalWrapper } from "components/Modal";
import LoadingOverlay from "components/LoadingOverlay";
import PageHeaderContainer from "./PageHeaderContainer";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import LandingFooter from "modules/landing/components/LandingFooter";
import { isPath } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
const cx = classNames.bind(styles);

type AppEnvelopeProps = {
  contentClassName?: string;
  awaitSessionRehydration?: boolean;
  fullWidth?: boolean;
  reducedHeightFooter?: boolean;
  noSideMargin?: boolean;
  landingFooter?: boolean;
};

const AppEnvelope: React.FunctionComponent<AppEnvelopeProps> = ({
  children,
  contentClassName,
  awaitSessionRehydration = true,
  reducedHeightFooter,
  fullWidth,
  noSideMargin,
  landingFooter,
}) => {
  const { user, isSessionRehydrated, partner, sentryConfigured } = useSelector(
    (state) => state,
    shallowEqual,
  );

  useEffect(() => {
    console.log(`Revision ${process.env.REACT_APP_BUILD_SHA || "unreleased"}`); // eslint-disable-line no-console
  }, []);

  const isAboutUs = isPath(ROUTE_TEMPLATE.ABOUT_US);
  const isFAQs = isPath(ROUTE_TEMPLATE.FAQS);

  return (
    <React.Fragment>
      <PageHeaderContainer />
      <div className={cx("main-container")}>
        <main
          className={cx(
            styles.main,
            contentClassName,
            fullWidth && styles["main--full-width"],
            {
              "main--no-side-margin": noSideMargin,
              "about-us": isAboutUs,
              faqs: isFAQs,
            },
          )}
        >
          {isSessionRehydrated || !awaitSessionRehydration ? (
            children
          ) : (
            <LoadingOverlay />
          )}
        </main>
      </div>
      {landingFooter ? (
        <LandingFooter user={user} partner={partner} />
      ) : (
        <Footer
          user={user}
          partner={partner}
          collectFeedback={sentryConfigured}
          reducedHeight={reducedHeightFooter}
        />
      )}
      <NotificationWrapper />
      <ModalWrapper />
    </React.Fragment>
  );
};

export default AppEnvelope;
