import * as React from "react";
import {
  Question,
  QuestionType,
  QuestionDefinitionTextual,
  TextFieldType,
  QuestionDefinitionNumeric,
  QuestionDefinitionSelect,
  QuestionHelp,
  RelationshipConstraintType,
  isSelectMultiple,
} from "../types/Questionnaire";
import QuestionSwitch from "components/Forms/Questions/QuestionSwitch";
import QuestionCheckbox from "components/Forms/Questions/QuestionCheckbox";
import QuestionInput from "components/Forms/Questions/QuestionInput";
import QuestionSelect from "components/Forms/Questions/QuestionSelect";
import { InputType } from "components/Forms/Inputs/Input/types";
import QuestionRadio from "components/Forms/Questions/QuestionRadio";
import Gender from "modules/parties/types/Gender";
import logger from "logger";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
import QuestionPeoplePicker from "components/Forms/Questions/QuestionPeoplePicker";
import Party from "modules/parties/types/Party";
import Client from "modules/parties/types/Client";
import QuestionAddress from "components/Forms/Questions/QuestionAddress";
import QuestionShareDistribution from "components/Forms/Questions/QuestionPerParty/QuestionShareDistribution";
import QuestionTextarea from "components/Forms/Questions/QuestionTextarea";
import QuestionAddon from "components/Forms/Questions/QuestionAddon";
import QuestionState from "components/Forms/Questions/QuestionState";
import QuestionPerParty from "components/Forms/Questions/QuestionPerParty";
import QuestionConfirm from "components/Forms/Questions/QuestionConfirm";
import QuestionAutocomplete from "components/Forms/Questions/QuestionAutocomplete";
import QuestionImage from "components/Forms/Questions/QuestionImage";
import Alert from "components/Alert";
import PerPartySubquestionnaire from "components/Forms/Questions/QuestionPerPartySubquestionnaire";
import QuestionAssetSplit from "components/Forms/Questions/QuestionAssetSplit";
import QuestionMultiple from "components/Forms/Questions/QuestionMultiple";
import QuestionOrOption from "components/Forms/Questions/QuestionOrOption";
import QuestionLifeInsurancePolicyValue from "components/Forms/Questions/QuestionLifeInsurancePolicyValue";
import interpolate from "utils/interpolate";

export type QuestionActions = {
  onAddParty?: () => Promise<Party | undefined>;
  onEditParty?: (party: Party) => Promise<Party | undefined>;
};

export type GenericQuestionProps = {
  client?: Client;
  spouse?: Client;
  disabled?: boolean;
  question: Question;
  size?: QuestionSizes;
  questionActions?: QuestionActions;
};

const renderTextQuestion = (
  id: string,
  label: string,
  definition: QuestionDefinitionTextual,
  description?: QuestionHelp,
  size?: QuestionSizes,
  disabled?: boolean,
) => {
  switch (definition.subtype) {
    case TextFieldType.EMAIL:
      return (
        <QuestionInput
          key={id}
          label={label}
          name={id}
          type={InputType.EMAIL}
          size={size}
          disabled={disabled}
          help={description}
          explainer={definition.explainer}
        />
      );

    case TextFieldType.SSN:
      return (
        <QuestionInput
          key={id}
          label={label}
          name={id}
          type={InputType.SOCIAL_SECURITY_NUMBER}
          size={size}
          disabled={disabled}
          help={description}
          explainer={definition.explainer}
        />
      );

    case TextFieldType.TEL:
      return (
        <QuestionInput
          key={id}
          label={label}
          name={id}
          type={InputType.TEL}
          size={size}
          disabled={disabled}
          help={description}
          explainer={definition.explainer}
        />
      );

    case TextFieldType.ZIP:
      return (
        <QuestionInput
          key={id}
          label={label}
          name={id}
          type={InputType.ZIP_CODE}
          size={size}
          disabled={disabled}
          help={description}
          explainer={definition.explainer}
        />
      );

    case TextFieldType.CODE:
      return (
        <QuestionInput
          key={id}
          label={label}
          placeholder={definition.placeholder}
          name={id}
          type={InputType.CODE}
          min={definition.limits?.min}
          max={definition.limits?.max}
          size={size}
          disabled={disabled}
          help={description}
          prefix={definition.prefix}
          explainer={definition.explainer}
        />
      );

    default:
      return (
        <QuestionInput
          key={id}
          label={label}
          placeholder={definition.placeholder}
          name={id}
          type={InputType.TEXT}
          size={size}
          disabled={disabled}
          help={description}
          explainer={definition.explainer}
        />
      );
  }
};

const renderNumericQuestion = (
  id: string,
  label: string,
  definition: QuestionDefinitionNumeric,
  description?: QuestionHelp,
  size?: QuestionSizes,
  disabled?: boolean,
) => {
  // TODO limits
  // InputType.DECIMAL_ALLOW_NEGATIVE
  // InputType.INTEGER_ALLOW_NEGATIVE
  // InputType.NUMBER ?
  return definition.integral ? (
    <QuestionInput
      type={InputType.INTEGER}
      key={id}
      label={label}
      placeholder={definition.placeholder || ""}
      name={id}
      size={size}
      disabled={disabled}
      help={description}
    />
  ) : (
    <QuestionInput
      type={InputType.DECIMAL}
      key={id}
      label={label}
      placeholder={definition.placeholder || ""}
      name={id}
      size={size}
      disabled={disabled}
      help={description}
    />
  );
};

const renderSelectQuestion = (
  id: string,
  label: string,
  definition: QuestionDefinitionSelect,
  description?: QuestionHelp,
  size?: QuestionSizes,
  disabled?: boolean,
  showHelpByDefault?: boolean,
) => {
  const { values, radio, allowOther, otherLabel, stacked, autocomplete } =
    definition;
  if (isSelectMultiple(definition)) {
    return (
      <QuestionCheckbox
        name={id}
        key={id}
        label={label}
        options={(values || []).map(({ value, display }) => ({
          value,
          label: display || value,
        }))}
        size={size}
        allowOther={allowOther}
        otherLabel={otherLabel}
        disabled={disabled}
        help={description}
        stacked={stacked}
      />
    );
  }
  return radio ? (
    <QuestionRadio
      key={id}
      label={label}
      name={id}
      options={(values || []).map(({ value, display }) => ({
        value,
        label: display || value,
      }))}
      size={size}
      allowOther={allowOther}
      otherLabel={otherLabel}
      disabled={disabled}
      help={description}
      stacked={stacked}
    />
  ) : autocomplete && !allowOther ? (
    <QuestionAutocomplete
      key={id}
      label={label}
      placeholder=""
      name={id}
      options={(values || []).map(({ value, display, description }) => ({
        value,
        label: display || value,
        description,
      }))}
      size={size}
      disabled={disabled}
      help={description}
    />
  ) : (
    <QuestionSelect
      key={id}
      label={label}
      placeholder=""
      name={id}
      options={(values || []).map(({ value, display, description }) => ({
        value,
        label: display || value,
        description,
      }))}
      size={size}
      allowOther={allowOther}
      otherLabel={otherLabel}
      disabled={disabled}
      help={description}
      showHelpByDefault={showHelpByDefault}
    />
  );
};

const GenericQuestion: React.FunctionComponent<GenericQuestionProps> = ({
  client,
  spouse,
  disabled,
  question,
  size,
  questionActions,
}) => {
  const { id, data, label = "", description } = question;
  const { definition, readOnly = disabled } = data;

  const labelWithInterpolation = client
    ? interpolate(label, client, spouse)
    : label;

  switch (definition.type) {
    case QuestionType.TEXT:
      return renderTextQuestion(
        id,
        labelWithInterpolation,
        definition,
        description,
        size,
        readOnly,
      );

    case QuestionType.LONG_TEXT:
      return (
        <QuestionTextarea
          key={id}
          label={labelWithInterpolation}
          name={id}
          size={size}
          disabled={readOnly}
          help={description}
        />
      );

    case QuestionType.NUMERIC:
      return renderNumericQuestion(
        id,
        labelWithInterpolation,
        definition,
        description,
        size,
        readOnly,
      );

    case QuestionType.RELATIONSHIP:
      return (
        <QuestionPeoplePicker
          key={id}
          label={labelWithInterpolation}
          name={id}
          size={size}
          allParties={definition.allParties || []}
          assignableParties={definition.assignableParties || []}
          partiesToImport={definition.partiesToImport}
          requireEmail={definition.requireEmail}
          requirePhone={definition.requirePhone}
          client={client}
          limits={definition.limits}
          onAddParty={
            ![
              RelationshipConstraintType.DESCENDANTS,
              RelationshipConstraintType.SUBSET,
            ].includes(
              definition.relationship?.type as RelationshipConstraintType,
            )
              ? questionActions?.onAddParty
              : undefined
          }
          onEditParty={questionActions?.onEditParty}
          disabled={readOnly}
          help={description}
          underageWarning={definition.underageWarning}
        />
      );

    case QuestionType.SELECT:
      return renderSelectQuestion(
        id,
        labelWithInterpolation,
        definition,
        description,
        size,
        readOnly,
        question.data.showHelpByDefault,
      );

    case QuestionType.FLAG:
      return (
        <QuestionSwitch
          key={id}
          label={labelWithInterpolation}
          name={id}
          labelOn={definition.yesLabel || "Yes"}
          labelOff={definition.noLabel || "No"}
          size={size}
          disabled={readOnly}
          help={description}
        />
      );

    case QuestionType.CONFIRM:
      return (
        <QuestionConfirm
          key={id}
          label={labelWithInterpolation}
          name={id}
          size={size}
          disabled={readOnly}
          help={description}
          extraAction={definition.extraAction}
        />
      );

    case QuestionType.DATE:
      // TODO InputType.MONTH
      return (
        <QuestionInput
          key={id}
          label={labelWithInterpolation}
          name={id}
          type={InputType.DATE}
          size={size}
          disabled={readOnly}
          help={description}
          min={definition.limits?.min}
          max={definition.limits?.max}
        />
      );

    case QuestionType.CURRENCY:
      // TODO limits
      // TODO CURRENCY_ALLOW_NEGATIVE
      return (
        <QuestionInput
          key={id}
          label={labelWithInterpolation}
          name={id}
          type={InputType.CURRENCY}
          size={size}
          disabled={readOnly}
          help={description}
        />
      );

    case QuestionType.PERCENTAGE:
      return (
        <QuestionInput
          key={id}
          label={labelWithInterpolation}
          name={id}
          type={
            definition.allowDecimal
              ? InputType.PERCENTAGE
              : InputType.PERCENTAGE_INTEGER
          }
          size={size}
          disabled={readOnly}
          help={description}
        />
      );

    // TODO InputType.PASSWORD

    case QuestionType.GENDER:
      return (
        <QuestionRadio
          key={id}
          name={id}
          label={labelWithInterpolation}
          options={[
            {
              value: Gender.MALE,
              label: "Male",
            },
            {
              value: Gender.FEMALE,
              label: "Female",
            },
          ]}
          size={size}
          disabled={readOnly}
          help={description}
        />
      );

    case QuestionType.ADDON: {
      const { addonDetails, choices } = definition;
      if (!addonDetails) {
        logger.warn("Addon details missing", definition);
      }
      if (addonDetails && choices) {
        return (
          <QuestionAddon
            key={id}
            name={id}
            addon={addonDetails}
            choices={choices}
            disabled={readOnly}
            isSecondaryWorkflow={definition.isSecondaryWorkflow}
          />
        );
      }
      return (
        <QuestionSwitch
          key={id}
          label={labelWithInterpolation}
          name={id}
          labelOn="Yes"
          labelOff="No"
          size={size}
          disabled={readOnly}
          help={description}
        />
      );
    }

    case QuestionType.ADDRESS: {
      return (
        <QuestionAddress
          key={id}
          label={labelWithInterpolation}
          name={id}
          onAddressLookup={
            definition.onAddressLookup || (() => Promise.resolve(undefined))
          }
          onAddressSearch={
            definition.onAddressSearch || (() => Promise.resolve(undefined))
          }
          availableAddresses={definition.availableAddresses}
          supportedStates={definition.supportedStates}
          disabled={readOnly}
          // help={description}
        />
      );
    }

    case QuestionType.SHARE_DISTRIBUTION: {
      return (
        <QuestionShareDistribution
          key={id}
          label={labelWithInterpolation}
          name={id}
          client={client}
          parties={definition.parties || []}
          disabled={readOnly}
          help={description}
        />
      );
    }

    case QuestionType.PER_PARTY: {
      return (
        <QuestionPerParty
          key={id}
          label={labelWithInterpolation}
          name={id}
          client={client}
          parties={definition.parties || []}
          disabled={readOnly}
          help={description}
          renderMetaInput={(id: string) => (
            <GenericQuestion
              question={{
                id,
                data: {
                  descriptions: [{ label: "" }],
                  definition: definition.definition,
                },
              }}
            />
          )}
        />
      );
    }

    case QuestionType.SUBQUESTIONNAIRE: {
      if (!definition.subquestionnaireData) return null;
      return (
        <PerPartySubquestionnaire
          key={id}
          label={labelWithInterpolation}
          name={id}
          parties={definition.parties || []}
          questionActions={questionActions}
          {...definition.subquestionnaireData}
        />
      );
    }

    case QuestionType.CLONE_BANNER: {
      return definition.renderBanner ? definition.renderBanner() : null;
    }

    case QuestionType.STATE: {
      return (
        <QuestionState
          key={id}
          label={labelWithInterpolation}
          name={id}
          disabled={readOnly}
          help={description}
          maxItems={definition.maxSuggestedItems}
        />
      );
    }

    case QuestionType.CONTENT: {
      return <div style={{ marginBottom: "25px" }}>{definition.render()}</div>;
    }

    case QuestionType.IMAGE: {
      return (
        <QuestionImage
          key={id}
          label={labelWithInterpolation}
          name={id}
          disabled={readOnly}
          help={description}
          aspectRatio={definition.aspectRatio}
          circular={definition.circular}
          minHeight={definition.minHeight}
          minWidth={definition.minWidth}
          maxFileSize={definition.maxFileSize}
        />
      );
    }

    case QuestionType.ASSET_SPLIT: {
      return (
        <QuestionAssetSplit
          label={labelWithInterpolation}
          name={id}
          disabled={readOnly}
          help={description}
          isMarried={client?.isMarried}
          mainOwnedLabel={definition.mainOwnedLabel}
          jointlyOwnedLabel={definition.jointlyOwnedLabel}
          spouseOwnedLabel={definition.spouseOwnedLabel}
          client={client}
          spouse={spouse}
        />
      );
    }

    case QuestionType.LIFE_INSURANCE_POLICY_VALUE: {
      return (
        <QuestionLifeInsurancePolicyValue
          label={labelWithInterpolation}
          name={id}
          disabled={readOnly}
          description={description}
          faceValueLimits={definition.faceValueLimits}
          cashValueLimits={definition.cashValueLimits}
          deathBenefitLimits={definition.deathBenefitLimits}
        />
      );
    }

    case QuestionType.MULTIPLE: {
      return (
        <QuestionMultiple
          key={id}
          name={id}
          label={labelWithInterpolation}
          client={client}
          limits={definition.limits}
          addAnotherLabel={definition.addAnotherLabel}
          baseQuestionDefinition={definition.baseQuestionDefinition}
          size={QuestionSizes.FULLWIDTH}
          disabled={readOnly}
        />
      );
    }

    case QuestionType.OR_OPTION: {
      return (
        <QuestionOrOption
          key={id}
          name={id}
          label={labelWithInterpolation}
          client={client}
          questionDefinition={definition.questionDefinition}
          option={definition.option}
          disabled={readOnly}
        />
      );
    }

    case QuestionType.ALERT: {
      return (
        <div style={{ marginBottom: "25px" }}>
          <Alert message={definition.message} type={definition.level} />
        </div>
      );
    }

    default: {
      logger.warn("Unsupported question type:", definition);
      return null;
    }
  }
};

export default GenericQuestion;
