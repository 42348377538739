import * as React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Party, {
  ClientParties,
  GenericParty,
  getGenericPartyId,
} from "modules/parties/types/Party";
import Client from "modules/parties/types/Client";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import PartyListItem from "./PartyListItem";
import styles from "./styles.module.scss";
import Alert, { AlertType } from "components/Alert";

const renderParty = (
  party: GenericParty,
  partiesToImport?: ClientParties,
  client?: Client,
  disabled?: boolean,
  onEdit?: (party: Party) => any,
  onRemove?: (party: GenericParty) => any,
  requirePhone?: boolean,
  requireEmail?: boolean,
) =>
  party ? (
    <PartyListItem
      key={getGenericPartyId(party)}
      onEdit={onEdit}
      onRemove={onRemove}
      party={party}
      partiesToImport={partiesToImport}
      client={client}
      disabled={disabled}
      requireEmail={requireEmail}
      requirePhone={requirePhone}
    />
  ) : null;

type PartyListProps = {
  addPartyLabel?: string;
  alert?: string;
  client?: Client;
  disabled?: boolean;
  parties: GenericParty[];
  partiesToImport?: ClientParties;
  onAddParty?: () => any;
  onEditParty?: (party: Party) => any;
  onRemoveParty?: (party: GenericParty) => any;
  onReorderParties?: (parties: GenericParty[]) => any;
  requireEmail?: boolean;
  requirePhone?: boolean;
};

type AddPartyButtonProps = {
  disabled?: boolean;
  label?: string;
  onAddParty?: () => any;
};

export const AddPersonButton: React.FunctionComponent<AddPartyButtonProps> = ({
  disabled,
  label = "Enter a New Person",
  onAddParty,
}) => (
  <div className={styles["add-party-btn-wrapper"]}>
    <TabbedButton
      disabled={disabled}
      label={label}
      mood={TabbedButtonMood.POSITIVE}
      onClick={onAddParty}
      style={TabbedButtonType.PRIMARY}
    />
  </div>
);

export const getReorderedParties = (
  originalList: GenericParty[],
  dropResult: DropResult,
): GenericParty[] => {
  if (!dropResult.destination) return originalList;

  const originalIdx = dropResult.source.index;
  const destinationIdx = dropResult.destination.index;

  const nextList = [...originalList];
  const draggedItem = nextList.splice(originalIdx, 1)[0];
  nextList.splice(destinationIdx, 0, draggedItem);

  return nextList;
};

const PartyList: React.FunctionComponent<PartyListProps> = ({
  addPartyLabel,
  alert,
  client,
  disabled,
  parties,
  partiesToImport,
  onAddParty,
  onEditParty,
  onRemoveParty,
  onReorderParties,
  requireEmail,
  requirePhone,
}) => {
  const handleDrop = React.useCallback(
    (dropResult: DropResult) => {
      const nextOrder = getReorderedParties(parties, dropResult);
      onReorderParties && onReorderParties(nextOrder);
    },
    [onReorderParties, parties],
  );

  return (
    <div className={styles["party-list"]}>
      {alert && (
        <div className={styles["party-list__alert"]}>
          <Alert type={AlertType.WARNING} message={alert} />
        </div>
      )}
      {parties.length !== 0 &&
        (onReorderParties ? (
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="party-list">
              {(provided) => {
                return (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles["party-list__list"]}
                  >
                    {parties.map((party, idx) => (
                      <Draggable
                        key={getGenericPartyId(party)}
                        draggableId={getGenericPartyId(party)}
                        index={idx}
                      >
                        {(provided) => (
                          <div
                            data-tid="party-list-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {renderParty(
                              party,
                              partiesToImport,
                              client,
                              disabled,
                              onEditParty,
                              onRemoveParty,
                              requirePhone,
                              requireEmail,
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
        ) : (
          <ul className={styles["party-list__list"]}>
            {parties.map((party) =>
              renderParty(
                party,
                partiesToImport,
                client,
                disabled,
                onEditParty,
                onRemoveParty,
              ),
            )}
          </ul>
        ))}
      {onAddParty && (
        <AddPersonButton
          disabled={disabled}
          label={addPartyLabel}
          onAddParty={onAddParty}
        />
      )}
    </div>
  );
};

export default PartyList;
