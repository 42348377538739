import React from "react";
import {
  SECONDS_IN_A_DAY,
  WORKFLOW_EXPIRY_AFTER_DAYS,
  WORKFLOW_EXPIRY_WARNING_AFTER_DAYS,
} from "resources/options/expiry";

import styles from "./styles.module.scss";
import { ProgressArchiveWarningProps } from "./types";

const ProgressArchiveWarning = ({ created }: ProgressArchiveWarningProps) => {
  const shouldRenderWarning =
    created !== undefined &&
    new Date(created).getTime() +
      WORKFLOW_EXPIRY_WARNING_AFTER_DAYS * SECONDS_IN_A_DAY <
      new Date().getTime();

  return shouldRenderWarning ? (
    <>
      <div className={styles["progress-archive-warning"]}>
        If you do not finish your plan within {WORKFLOW_EXPIRY_AFTER_DAYS} days
        of starting it, your plan will be archived and you will need to start
        over.
      </div>
    </>
  ) : null;
};

export default ProgressArchiveWarning;
