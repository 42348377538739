const background = "#f4ebdc";
const green = "#c5facc";
const blue = "#e2f4ff";
const white = "#ffffff";
const grey = "#7a7877";
const primaryLight = "#e2d1fa";

export default [
  { elementType: "geometry", stylers: [{ color: background }] },
  { elementType: "labels.text.stroke", stylers: [{ color: white }] },
  { elementType: "labels.text.fill", stylers: [{ color: grey }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [{ color: grey, weight: 5 }],
  },
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [{ color: grey, weight: 5 }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: green }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: white }],
  },
  {
    featureType: "road",
    stylers: [{ color: white }],
    elementType: "geometry.stroke",
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: white }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: primaryLight }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: primaryLight }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: blue }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: grey }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: white }],
  },
];
