import { keyBy, mapValues, sortBy, uniq } from "lodash";
import { AddressSearchResultsType } from "modules/address/actions";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { findRelationship } from "modules/parties/helpers/getRelationshipToClient";
import Address from "modules/parties/types/Address";
import Party, {
  ChildRelationship,
  RelationshipType,
  ClientParties,
  getGrandchildren,
  getGenericPartyId,
  GenericParty,
} from "modules/parties/types/Party";
import Addon, { AddonID } from "modules/product/types/Addon";
import moment from "moment";
import { AnswersByQuestionnaireType } from "../types/Answer";
import Questionnaire, {
  Question,
  QuestionDefinitionAddon,
  QuestionDefinitionPerParty,
  QuestionDefinitionSelectRelationship,
  QuestionDefinitionShareDistribution,
  QuestionDefinitionSubquestionnaire,
  QuestionnaireID,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
  QuestionType,
  RelationshipConstraint,
  RelationshipConstraintType,
} from "../types/Questionnaire";

const excludeParties = (
  parties: Party[] = [],
  blacklistedIDs: string[],
): Party[] =>
  parties.filter((party) => !blacklistedIDs.includes(party.partyId));

const mergeRelevantContacts = (
  children: Party[],
  grandchildren: Party[],
  otherParties: Array<GenericParty>,
  relationshipConstraint?: RelationshipConstraint,
): Array<GenericParty> => {
  const allParties = [...children, ...grandchildren, ...otherParties];

  switch (relationshipConstraint?.type) {
    case RelationshipConstraintType.DESCENDANTS:
      return [...children, ...grandchildren];

    case RelationshipConstraintType.NON_DESCENDANTS:
      return otherParties;

    case RelationshipConstraintType.MATURE:
      return allParties.filter(
        (party) =>
          !party.dateOfBirth || moment().diff(party.dateOfBirth, "years") >= 18,
      );

    default:
      return allParties;
  }
};

export const filterRelevantContacts = (
  relatedParties: ClientParties | undefined,
  relatedPartiesToImport: ClientParties | undefined,
  clients: ClientsMap | undefined,
  relationshipConstraint?: RelationshipConstraint,
  partiesToExclude?: string[],
  includeSpouse?: boolean,
  restrictIDs?: string[],
): {
  allParties: Array<GenericParty>;
  assignableParties: Array<GenericParty>;
  partiesToImport: ClientParties;
} => {
  const mainClient = clients?.main;
  const clientChildren: Party[] = relatedParties?.children || [];
  const clientGrandchildren: Party[] = getGrandchildren(clientChildren);
  const childrenMap = keyBy(clientChildren, "partyId");

  const relevantGrandchildren = clientGrandchildren.filter((grandchild) => {
    const parent = grandchild.parentId && childrenMap[grandchild.parentId];
    const parentRelationship: ChildRelationship | undefined = parent
      ? (findRelationship(parent, mainClient, RelationshipType.CHILD) as
          | ChildRelationship
          | undefined)
      : undefined;
    return Boolean(parentRelationship);
  });

  const relevantChildren = clientChildren.filter((child) => {
    const relationship: ChildRelationship | undefined = child
      ? (findRelationship(child, mainClient, RelationshipType.CHILD) as
          | ChildRelationship
          | undefined)
      : undefined;
    return relationship && !child.deceased;
  });

  const spouse = includeSpouse ? clients?.spouse : undefined;

  const allParties = [
    ...clientChildren,
    ...clientGrandchildren,
    ...(relatedParties?.parties || []),
    ...(spouse ? [spouse] : []),
  ];

  const relevantRelatedContacts = mergeRelevantContacts(
    relevantChildren,
    relevantGrandchildren,
    [...(relatedParties?.parties || []), ...(spouse ? [spouse] : [])],
    relationshipConstraint,
  )
    .filter(
      (partyOrSpouse) =>
        !(partiesToExclude || []).includes(getGenericPartyId(partyOrSpouse)),
    )
    .filter((partyOrSpouse) =>
      restrictIDs
        ? restrictIDs.includes(getGenericPartyId(partyOrSpouse))
        : true,
    );

  const workflowPartiesIDs = allParties.map(getGenericPartyId);
  const unimportablePartiesIDs = [
    ...workflowPartiesIDs,
    ...(partiesToExclude || []),
  ];

  return {
    allParties,
    assignableParties: relevantRelatedContacts,
    partiesToImport: {
      parties: excludeParties(
        relatedPartiesToImport?.parties,
        unimportablePartiesIDs,
      ),
      children: excludeParties(
        relatedPartiesToImport?.children,
        unimportablePartiesIDs,
      ),
    },
  };
};

const assignPeoplePickerContacts = (
  clients: ClientsMap | undefined,
  relatedParties: ClientParties | undefined,
  spouseRelatedParties: ClientParties | undefined,
  question: Question,
  relationshipConstraint?: RelationshipConstraint,
  partiesToExclude?: string[],
  includeSpouse?: boolean,
  restrictIDs?: string[],
): Question => ({
  ...question,
  data: {
    ...question.data,
    definition: {
      ...question.data.definition,
      ...filterRelevantContacts(
        relatedParties,
        spouseRelatedParties,
        clients,
        relationshipConstraint,
        partiesToExclude,
        includeSpouse,
        restrictIDs,
      ),
    } as QuestionDefinitionSelectRelationship,
  },
});

const assignRelatedParties = (
  question: Question,
  allParties: Party[],
  clients?: ClientsMap,
  partyIDsToAssign?: string[],
): Question => {
  const clientsById = keyBy(Object.values(clients || {}), "clientId");
  const partiesById = keyBy(allParties, "partyId");
  return {
    ...question,
    data: {
      ...question.data,
      definition: {
        ...question.data.definition,
        parties: (partyIDsToAssign || [])
          .map((id) => clientsById[id] || partiesById[id])
          .filter(Boolean),
      } as QuestionDefinitionShareDistribution | QuestionDefinitionPerParty,
    },
  };
};

const assignSubuestionnaires = (
  question: Question,
  questionnaires: Questionnaire[],
  allQuestions: QuestionsMapByQuestionnaire,
  allAnswers: AnswersByQuestionnaireType,
  clients?: ClientsMap,
  allChildren?: Party[],
): Question => {
  const originalDefinition = question.data
    .definition as QuestionDefinitionSubquestionnaire;
  const questionDefinition: QuestionDefinitionSubquestionnaire = {
    ...originalDefinition,
    subquestionnaireData: {
      subquestionnaires: questionnaires,
      partySubquestionnaire: questionnaires.find(
        (qn) => qn.id === originalDefinition.questionnaireId,
      ),
      allAnswers,
      allQuestions,
      clients,
      allChildren,
    },
  };
  return {
    ...question,
    data: {
      ...question.data,
      definition: questionDefinition,
    },
  };
};

const assignAddon = (
  question: Question,
  addons: Record<AddonID, Addon>,
  isSecondaryWorkflow: boolean,
) => ({
  ...question,
  data: {
    ...question.data,
    definition: {
      ...question.data.definition,
      isSecondaryWorkflow,
      addonDetails:
        addons[(question.data.definition as QuestionDefinitionAddon).addonId],
    } as QuestionDefinitionAddon,
  },
});

export const getPartiesToExclude = (
  selectRelationshipDefinition: QuestionDefinitionSelectRelationship,
  answers: AnswersByQuestionnaireType,
): string[] =>
  uniq(
    (selectRelationshipDefinition.excludeByQuestionIds || [])
      .map(
        ({ questionnaireID, questionID }) =>
          (answers[questionnaireID]?.[questionID] as string[] | undefined) ||
          [],
      )
      .flat(),
  );

const processQuestion = (
  clients: ClientsMap | undefined,
  relatedParties: ClientParties | undefined,
  spouseRelatedParties: ClientParties | undefined,
  addons: Record<AddonID, Addon>,
  question: Question,
  questionnaireId: string,
  questionnaires: Questionnaire[],
  questions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  isSecondaryWorkflow: boolean,
): Question | null => {
  const { definition } = question.data;
  switch (definition.type) {
    case QuestionType.RELATIONSHIP:
      if (!clients?.main) {
        return question;
      }

      const restrictedIDs =
        definition.relationship?.type === RelationshipConstraintType.SUBSET
          ? (definition.relationship.referenceQuestionIds
              .map(
                (parentQuestionId) =>
                  answers[questionnaireId]?.[parentQuestionId] as
                    | string[]
                    | undefined,
              )
              .flat()
              .filter(Boolean) as string[])
          : undefined;

      const partiesToExclude = getPartiesToExclude(definition, answers);
      return assignPeoplePickerContacts(
        clients,
        relatedParties,
        spouseRelatedParties,
        question,
        definition.relationship,
        partiesToExclude,
        definition.allowSpouse,
        restrictedIDs,
      );

    case QuestionType.ADDON:
      return assignAddon(question, addons, isSecondaryWorkflow);

    case QuestionType.PER_PARTY:
    case QuestionType.SHARE_DISTRIBUTION:
      const allParties = [
        ...(relatedParties?.parties || []),
        ...(relatedParties?.children || []),
        ...getGrandchildren(relatedParties?.children || []),
      ];
      return assignRelatedParties(
        question,
        allParties,
        clients,
        answers[questionnaireId] &&
          (answers[questionnaireId][definition.referenceQuestionId] as
            | string[]
            | undefined),
      );

    case QuestionType.SUBQUESTIONNAIRE: {
      const allChildren = [
        ...(relatedParties?.children || []),
        ...getGrandchildren(relatedParties?.children || []),
      ];
      const allParties = [...(relatedParties?.parties || []), ...allChildren];
      return assignRelatedParties(
        assignSubuestionnaires(
          question,
          questionnaires,
          questions,
          answers,
          clients,
          allChildren,
        ),
        allParties,
        clients,
        answers[questionnaireId] &&
          (answers[questionnaireId][definition.referenceQuestionId] as
            | string[]
            | undefined),
      );
    }
  }
  return question;
};

export const processAddressQuestions = (
  questions: Question[],
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: () => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  supportedStates?: string[],
): Question[] =>
  questions.map((question) => {
    if (
      question.data.definition.type === QuestionType.ADDRESS &&
      (!question.data.definition.onAddressLookup ||
        !question.data.definition.onAddressSearch) // Skip if already processed
    ) {
      return {
        ...question,
        data: {
          ...question.data,
          definition: {
            ...question.data.definition,
            onAddressLookup,
            onAddressSearch,
            availableAddresses,
            supportedStates,
          },
        },
      };
    }
    return question;
  });

export const processBannerQuestions = (
  questions: Question[],
  renderBanner: () => JSX.Element | null,
): Question[] =>
  questions.map((question) => {
    if (question.data.definition.type === QuestionType.CLONE_BANNER) {
      question.data.definition = {
        ...question.data.definition,
        renderBanner,
      };
    }
    return question;
  });

export const processClientQuestions = (
  questions: Question[],
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  isLocked?: boolean,
  renderCloneWorkflowBanner?: () => JSX.Element | null,
  supportedStates?: string[],
) => {
  const questionsToProcess = isLocked
    ? questions.map((q) => ({ ...q, data: { ...q.data, readOnly: true } }))
    : questions;
  const processedQuestions = processAddressQuestions(
    questionsToProcess,
    onAddressLookup,
    onAddressSearch,
    availableAddresses,
    supportedStates,
  );
  return renderCloneWorkflowBanner
    ? processBannerQuestions(processedQuestions, renderCloneWorkflowBanner)
    : processedQuestions;
};

export const sortProductQuestionsLast = (
  questionnaires: Questionnaire[],
  questions: QuestionsMapByQuestionnaire,
): Array<{ questionnaireId: QuestionnaireID; questions: QuestionsMap }> => {
  const sortedQuestionnaireIDs = sortBy(
    questionnaires,
    ({ productQuestionnaire }) => (productQuestionnaire ? 1 : 0),
  ).map(({ id }) => id);

  return sortBy(
    Object.entries(questions).map(([questionnaireId, questions]) => ({
      questionnaireId,
      questions,
    })),
    ({ questionnaireId }) => sortedQuestionnaireIDs.indexOf(questionnaireId),
  );
};

const removeParty = (
  parties: GenericParty[] | undefined,
  partyToRemove: GenericParty,
): GenericParty[] | undefined =>
  parties?.filter(
    (p) => getGenericPartyId(p) !== getGenericPartyId(partyToRemove),
  );

export const unassignContact = (
  questions: QuestionsMap,
  toUnassign: GenericParty,
): QuestionsMap =>
  mapValues(questions, (question) => {
    switch (question.data.definition.type) {
      case QuestionType.RELATIONSHIP:
        return {
          ...question,
          data: {
            ...question.data,
            definition: {
              ...question.data.definition,
              allParties: removeParty(
                question.data.definition.allParties || [],
                toUnassign,
              ),
              assignableParties: removeParty(
                question.data.definition.assignableParties || [],
                toUnassign,
              ),
              partiesToImport: question.data.definition.partiesToImport
                ? {
                    parties: removeParty(
                      question.data.definition.partiesToImport.parties || [],
                      toUnassign,
                    ) as Party[],
                    children: removeParty(
                      question.data.definition.partiesToImport.children || [],
                      toUnassign,
                    ) as Party[],
                  }
                : question.data.definition.partiesToImport,
            },
          },
        };

      default:
        return question;
    }
  });

export default (
  clients: ClientsMap | undefined,
  relatedParties: ClientParties | undefined,
  spouseRelatedParties: ClientParties | undefined,
  addons: Record<AddonID, Addon> = {},
  questions: QuestionsMapByQuestionnaire,
  questionnaires: Questionnaire[],
  answers: AnswersByQuestionnaireType = {},
  isSecondaryWorkflow: boolean = false,
  addressData?: {
    onAddressLookup: (addressId: string) => Promise<Address | undefined>;
    onAddressSearch: () => Promise<AddressSearchResultsType[] | undefined>;
    availableAddresses: Address[];
    supportedStates?: string[];
  },
): QuestionsMapByQuestionnaire => {
  const sortedQuestionsByQuestionnaire = sortProductQuestionsLast(
    questionnaires,
    questions,
  );

  return sortedQuestionsByQuestionnaire.reduce(
    (res, { questionnaireId, questions: questionnaireQuestions }) => {
      const questionsArray = addressData
        ? processAddressQuestions(
            Object.values(questionnaireQuestions),
            addressData.onAddressLookup,
            addressData.onAddressSearch,
            addressData.availableAddresses,
            addressData.supportedStates,
          )
        : Object.values(questionnaireQuestions);

      res[questionnaireId] = keyBy(
        questionsArray
          .map((question) => {
            return processQuestion(
              clients,
              relatedParties,
              spouseRelatedParties,
              addons,
              question,
              questionnaireId,
              questionnaires,
              question.data.definition.type === QuestionType.SUBQUESTIONNAIRE
                ? { ...questions, ...res }
                : questions,
              answers,
              isSecondaryWorkflow,
            );
          })
          .filter(Boolean) as Question[],
        "id",
      );
      return res;
    },
    {} as QuestionsMapByQuestionnaire,
  );
};
