import * as React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type DropdownOption = {
  label: string;
  value: string;
};

type DropdownProps = {
  id?: string;
  name: string;
  onChange?: (selectedValue: string | null) => any;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => any;
  value?: string;
  disabled?: boolean;
  tabIndex?: number;
  placeholder?: string;
  options: DropdownOption[];
  isValid?: boolean;
  isInvalid?: boolean;
};

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { placeholder, options, isValid, isInvalid, ...fieldProps } = props;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      const values = [];
      for (const htmlOption of event.target.options as any) {
        if (htmlOption.selected) {
          const selectedOption = options.find(
            (o) => String(o.value) === htmlOption.value,
          );
          values.push(selectedOption?.value || null);
        }
      }
      props.onChange(values[0]);
    }
  };

  const className = cx("select", {
    "select--disabled": props.disabled,
    "select--invalid": !props.disabled && isInvalid,
    "select--valid": !props.disabled && isValid && props.value?.length,
    "select--placeholder": !props.value?.length && placeholder,
  });

  return (
    <div className={className}>
      <select
        {...fieldProps}
        onChange={handleChange}
        autoComplete="chrome-off"
        name={fieldProps.name}
      >
        {placeholder !== undefined && <option value="">{placeholder}</option>}
        {options.map((option, idx) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
