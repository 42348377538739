import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type AutocompleteListProps<T> = {
  getListboxProps: () => {};
  getOptionProps: (option: any, index?: number) => {};
  groupedOptions: T[];
  getOptionLabel: (option: T) => string;
  hideList?: boolean;
  limit?: number;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  size?: "small" | "large";
};

const listOrientation = (element: HTMLDivElement) => {
  return element
    ? document.documentElement.clientHeight -
        element.getBoundingClientRect().top -
        element.offsetHeight <
        element.getBoundingClientRect().top
    : false;
};

function AutocompleteList<T>(props: AutocompleteListProps<T>) {
  const [above, setAbove] = useState(false);
  const [open, setOpen] = useState(false);

  const handleEventListener = useCallback(
    (event: Event | UIEvent) => {
      setAbove(listOrientation(props.parentRef.current as HTMLDivElement));
    },
    [props.parentRef],
  );

  useEffect(() => {
    setOpen(props.groupedOptions.length > 0);
  }, [props.groupedOptions.length]);

  useEffect(() => {
    if (props.groupedOptions.length > 0 && props.parentRef.current) {
      setAbove(listOrientation(props.parentRef.current as HTMLDivElement));
      window.addEventListener("resize", handleEventListener, true);
      document.addEventListener("scroll", handleEventListener, true);
      return () => {
        window.removeEventListener("resize", handleEventListener, true);
        document.removeEventListener("scroll", handleEventListener, true);
      };
    }
  }, [handleEventListener, props.groupedOptions.length, props.parentRef]);

  let className = cx("autocomplete", {
    "autocomplete--above": above,
    "autocomplete--open": props.groupedOptions.length > 0 && open,
    "autocomplete--small": props.size !== "large",
    "autocomplete--large": props.size === "large",
  });

  const suggestions = props.limit
    ? props.groupedOptions.slice(0, props.limit)
    : props.groupedOptions;

  return (
    <React.Fragment>
      {suggestions.length > 0 && open && !props.hideList ? (
        <div data-tid="autocomplete-list" className={className}>
          <div className={styles["autocomplete__container"]}>
            <ul
              className={styles["autocomplete__list"]}
              {...props.getListboxProps()}
            >
              {suggestions.map((option, index) => {
                const optionProps: any = props.getOptionProps({
                  option,
                  index,
                });
                return (
                  <li
                    className={styles["autocomplete__list-item"]}
                    {...optionProps}
                    key={`${optionProps.key}_${index}`}
                  >
                    {props.getOptionLabel(option)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default AutocompleteList;
