import PaginatedList, { ListType } from "components/PaginatedList";
import { LedgerItem as LedgerItemType } from "modules/parties/types/Finance";
import React, { useState } from "react";
import LedgerItem from "../LedgerItem";
import { tableColumns } from "./constants";
import sortLedgerItems from "./helpers";
import { LedgerPropTypes } from "./types";

const renderLedgerItem = (item: LedgerItemType) => (
  <LedgerItem item={item} key={item.workflowId} />
);

const Ledger = ({ ledger }: LedgerPropTypes) => {
  const [sortField, setSortField] = useState("dateCompleted");
  const [order, setOrder] = useState("desc");

  const sortedLedgerItems = sortLedgerItems(ledger, sortField, order);

  return (
    <PaginatedList<LedgerItemType>
      pageSize={10}
      tableColumns={tableColumns}
      items={sortedLedgerItems}
      itemNamePlural="paid workflows"
      renderItem={renderLedgerItem}
      listType={ListType.TABLE}
      sortField={sortField}
      setSortField={setSortField}
      order={order}
      setOrder={setOrder}
    />
  );
};

export default Ledger;
