import * as React from "react";
import FooterNavigation, {
  FooterNavigationDownloadLink,
  FooterNavigationLink,
} from "./FooterNavigation";

import styles from "./styles.module.scss";
import * as Sentry from "@sentry/react";
import { User } from "modules/auth/User";
import Partner from "modules/parties/types/Partner";
import Logo from "components/Logo";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  go,
  navigateToGlobalLanding,
  navigateToPartnerLanding,
} from "modules/navigation/helpers/navigator";
import { useDispatch } from "react-redux";
import { openPlanningDisclaimerModal } from "modules/product/components/PlanningDisclaimer";
import classNames from "classnames/bind";
import { getAdministeredPartnerIDs, isAdmin } from "modules/auth/helper";
import { getLawfirmAgreementLink } from "../../utils/environment";

const cx = classNames.bind(styles);

type FooterProps = {
  collectFeedback: boolean;
  partner: Partner | null;
  user: User | null;
  reducedHeight?: boolean;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  collectFeedback,
  user,
  partner,
  reducedHeight,
}) => {
  const dispatch = useDispatch();
  const admin = partner
    ? isAdmin(partner.id, user)
    : getAdministeredPartnerIDs(user).length > 0;

  const handleLogoClick = () => {
    if (partner) {
      navigateToPartnerLanding(dispatch, partner.id);
    } else {
      navigateToGlobalLanding(dispatch);
    }
  };

  return (
    <footer className={cx("footer", reducedHeight && "footer--short")}>
      <div className={styles.footer__container}>
        <FooterNavigation>
          <FooterNavigationLink
            label="Terms of Use"
            onClick={() => go(dispatch, ROUTE_TEMPLATE.TERMS_AND_CONDITIONS)}
          />
          <FooterNavigationLink
            label="Privacy policy"
            onClick={() => go(dispatch, ROUTE_TEMPLATE.PRIVACY)}
          />
          <FooterNavigationLink
            label="Cookies Policy"
            onClick={() => go(dispatch, ROUTE_TEMPLATE.COOKIES_POLICY)}
          />
          {collectFeedback ? (
            <FooterNavigationLink
              className={styles.footer__highlight}
              label="Send feedback!"
              onClick={() =>
                Sentry.captureMessage("Feedback", {
                  user: {
                    username: user?.displayName,
                    email: user?.email,
                    id: user?.uid,
                  },
                  fingerprint: [user?.uid || "", new Date().toISOString()],
                })
              }
            />
          ) : null}
          <FooterNavigationLink
            label="Planning Disclaimer"
            onClick={openPlanningDisclaimerModal}
          />
          {admin && process.env.REACT_APP_USER_AGREEMENT ? (
            <FooterNavigationDownloadLink
              label="User Agreement"
              fileUrl={getLawfirmAgreementLink()}
            />
          ) : null}
        </FooterNavigation>
        <Logo
          empoweredBy={partner?.data.displayName}
          onClick={handleLogoClick}
        />
      </div>
    </footer>
  );
};

export default Footer;
