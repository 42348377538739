import React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

export enum SpinnerSize {
  XSMALL = "x-small",
  SMALL = "small",
}

export type LoadingSpinnerProps = {
  size?: SpinnerSize;
};

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({
  size,
}) => {
  return (
    <div className={cx("spinner-wrapper")}>
      <div
        className={cx("spinner", {
          "spinner--x-small": size === SpinnerSize.XSMALL,
          "spinner--small": size === SpinnerSize.SMALL,
        })}
      />
    </div>
  );
};

export default LoadingSpinner;
