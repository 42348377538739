import React from "react";
import Product from "modules/product/types/Product";

import Addon from "modules/product/types/Addon";
import SelectorFeature from "components/Selector/SelectorFeatures/SelectorFeature";
import SelectorFeatureList from "components/Selector/SelectorFeatures";

type PlanSelectorPlansItemFeaturesProps = {
  activeAddons?: Addon[];
  disabled?: boolean;
  includedProducts: Product[];
  selected: boolean;
  recommended?: boolean;
};

const PlanSelectorPlansItemFeatures = ({
  activeAddons,
  disabled,
  includedProducts,
  selected,
  recommended,
}: PlanSelectorPlansItemFeaturesProps) => {
  return (
    <SelectorFeatureList
      disabled={disabled}
      selected={selected}
      recommended={recommended}
    >
      {includedProducts.map((product) => {
        const productName = product.external
          ? `${product.name} Upgrade`
          : product.name;
        return (
          <SelectorFeature
            key={product.id}
            id={product.id}
            name={productName}
            description={product.description}
            disabled={disabled}
            note={
              product.external
                ? `The law firm will contact you to arrange a meeting to prepare and execute your ${product.name}`
                : undefined
            }
          />
        );
      })}
      {(activeAddons || []).map((addon) => (
        <SelectorFeature
          key={addon.id}
          id={addon.id}
          name={addon.name}
          description={addon.description}
          disabled={disabled}
        />
      ))}
    </SelectorFeatureList>
  );
};

export default PlanSelectorPlansItemFeatures;
