import * as React from "react";
import classNames from "classnames/bind";

import { ReactComponent as DefaultSVG } from "../Icons/svg/default.svg";
import { ReactComponent as LandlineSVG } from "./svg/landline.svg";
import { ReactComponent as MobileSVG } from "./svg/mobile.svg";
import { ReactComponent as LocationSVG } from "./svg/location.svg";
import { ReactComponent as CalendarSVG } from "./svg/calendar.svg";
import { ReactComponent as PaypalSVG } from "./svg/paypal.svg";
import { ReactComponent as CardSVG } from "./svg/card.svg";
import { ReactComponent as AmericanExpressSVG } from "./svg/american-express.svg";
import { ReactComponent as PlusSVG } from "./svg/plus.svg";
import { ReactComponent as MinusSVG } from "./svg/minus.svg";
import { ReactComponent as CrossSVG } from "./svg/cross.svg";
import { ReactComponent as ExitSVG } from "./svg/exit.svg";
import { ReactComponent as TrashSVG } from "./svg/trash.svg";
import { ReactComponent as ArrowSVG } from "./svg/arrow.svg";
import { ReactComponent as SmallPlusSVG } from "./svg/smallPlus.svg";
import { ReactComponent as SmallMinusSVG } from "./svg/smallMinus.svg";
import { ReactComponent as EditSVG } from "./svg/edit.svg";
import { ReactComponent as EyeSVG } from "./svg/eye.svg";
import { ReactComponent as LeftChevronSVG } from "./svg/leftChevron.svg";
import { ReactComponent as RightChevronSVG } from "./svg/rightChevron.svg";
import { ReactComponent as QuestionMarkSVG } from "./svg/questionMark.svg";
import { ReactComponent as DownloadSVG } from "./svg/download.svg";
import { ReactComponent as WillPlanSVG } from "./svg/will-plan.svg";
import { ReactComponent as HealthcareSVG } from "./svg/health-care-documents.svg";
import { ReactComponent as PowerOfAttorneySVG } from "./svg/power-of-attorney.svg";
import { ReactComponent as WillSVG } from "./svg/will.svg";
import { ReactComponent as Will2SVG } from "./svg/will2.svg";
import { ReactComponent as SearchSVG } from "./svg/search.svg";
import { ReactComponent as JPGSVG } from "./svg/jpg.svg";
import { ReactComponent as PDFSVG } from "./svg/pdf.svg";
import { ReactComponent as AddWillSVG } from "./svg/add-will.svg";
import { ReactComponent as AddWillPlanSVG } from "./svg/add-will-plan.svg";
import { ReactComponent as AddHealthcareSVG } from "./svg/add-healthcare.svg";
import { ReactComponent as AddPowerOfAttorneySVG } from "./svg/add-power-of-attorney.svg";
import { ReactComponent as SuccessSVG } from "./svg/success.svg";
import { ReactComponent as WarningSVG } from "./svg/warning.svg";
import { ReactComponent as RecommendedSVG } from "./svg/recommended.svg";
import { ReactComponent as LoginSVG } from "static/images/icons/login.svg";
import { ReactComponent as ArrowFatSVG } from "static/images/icons/arrow-fat.svg";
import { ReactComponent as TickSVG } from "static/images/icons/tick.svg";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

export enum IconType {
  ADD = "add",
  ADD_HEALTHCARE_DIRECTIVE = "Add Healthcare Directive",
  ADD_POWER_OF_ATTORNEY = "Add Power Of Attorney",
  ADD_WILL = "Add Will",
  ADD_WILL_PLAN = "Add Essentials Plan",
  AMERICAN_EXPRESS = "american-express",
  ARROW = "arrow",
  BASE_WILL_PLAN = "Essentials Plan",
  CALENDAR = "calendar",
  CARD = "card",
  CROSS = "cross",
  DOWNLOAD = "download",
  EDIT = "edit",
  EXIT = "exit",
  EYE = "eye",
  HEALTHCARE = "Healthcare",
  JPG = "jpg",
  LANDLINE = "landline",
  LEFT_CHEVRON = "left-chevron",
  LOCATION = "location",
  MINUS = "minus",
  MOBILE = "mobile",
  PAYPAL = "paypal",
  PDF = "pdf",
  PLUS = "plus",
  POWER_OF_ATTORNEY = "Power of attorney",
  QUESTION_MARK = "question-mark",
  RECOMMENDED = "recommended",
  RIGHT_CHEVRON = "right-chevron",
  SEARCH = "search",
  SMALL_MINUS = "small-minus",
  SMALL_PLUS = "small-plus",
  SUCCESS = "success",
  TRASH = "trash",
  WARNING = "warning",
  WILL = "Will",
  WILL2 = "Will2",
  LOGIN = "login",
  ARROW_FAT = "arrow-fat",
  TICK = "tick",
}

type IconProps = {
  className?: string;
  disabled?: boolean;
  hover?: boolean;
  invalid?: boolean;
  selected?: boolean;
  type?: IconType;
};

const Icon: React.FunctionComponent<IconProps> = (props) => {
  let className = cx(
    "icon",
    ["icon--" + (props.type ? props.type : "default")],
    {
      "icon--disabled": props.disabled,
      "icon--hover": props.hover,
      "icon--invalid": !props.disabled && props.invalid,
      "icon--selected": props.selected,
    },
    props.className,
  );

  switch (props.type) {
    case IconType.LANDLINE:
      return <LandlineSVG aria-label="Landline" className={className} />;
    case IconType.MOBILE:
      return <MobileSVG aria-label="Mobile" className={className} />;
    case IconType.CALENDAR:
      return <CalendarSVG aria-label="Calendar" className={className} />;
    case IconType.LOCATION:
      return <LocationSVG aria-label="Location" className={className} />;
    case IconType.CARD:
      return <CardSVG aria-label="Card" className={className} />;
    case IconType.PAYPAL:
      return <PaypalSVG aria-label="Paypal" className={className} />;
    case IconType.AMERICAN_EXPRESS:
      return (
        <AmericanExpressSVG
          aria-label="American Express"
          className={className}
        />
      );
    case IconType.PLUS:
      return <PlusSVG aria-label="Plus" className={className} />;
    case IconType.MINUS:
      return <MinusSVG aria-label="Minus" className={className} />;
    case IconType.CROSS:
      return <CrossSVG aria-label="Cross" className={className} />;
    case IconType.EXIT:
      return <ExitSVG aria-label="Exit" className={className} />;
    case IconType.TRASH:
      return <TrashSVG aria-label="Trash" className={className} />;
    case IconType.SEARCH:
      return <SearchSVG aria-label="Search" className={className} />;
    case IconType.SMALL_PLUS:
      return <SmallPlusSVG aria-label="Small Plus" className={className} />;
    case IconType.SMALL_MINUS:
      return <SmallMinusSVG aria-label="Small Minus" className={className} />;
    case IconType.ARROW:
      return <ArrowSVG aria-label="Arrow" className={className} />;
    case IconType.EDIT:
      return <EditSVG aria-label="Edit" className={className} />;
    case IconType.EYE:
      return <EyeSVG aria-label="Eye" className={className} />;
    case IconType.LEFT_CHEVRON:
      return <LeftChevronSVG aria-label="Left Chevron" className={className} />;
    case IconType.RIGHT_CHEVRON:
      return (
        <RightChevronSVG aria-label="Right Chevron" className={className} />
      );
    case IconType.QUESTION_MARK:
      return (
        <QuestionMarkSVG aria-label="Question Mark" className={className} />
      );
    case IconType.DOWNLOAD:
      return <DownloadSVG aria-label="Download" className={className} />;
    case IconType.BASE_WILL_PLAN:
      return <WillPlanSVG aria-label="Essentials Plan" />;
    case IconType.HEALTHCARE:
      return <HealthcareSVG aria-label="Healthcare" />;
    case IconType.POWER_OF_ATTORNEY:
      return <PowerOfAttorneySVG aria-label="PoA" />;
    case IconType.WILL:
      return <WillSVG aria-label="Will" />;
    case IconType.WILL2:
      return <Will2SVG aria-label="Will" />;
    case IconType.JPG:
      return <JPGSVG aria-label="JPG" />;
    case IconType.PDF:
      return <PDFSVG aria-label="PDF" />;
    case IconType.ADD_WILL_PLAN:
      return <AddWillPlanSVG aria-label="Add Essentials Plan" />;
    case IconType.ADD_HEALTHCARE_DIRECTIVE:
      return <AddHealthcareSVG aria-label="Add Healthcare" />;
    case IconType.ADD_POWER_OF_ATTORNEY:
      return <AddPowerOfAttorneySVG aria-label="Add Power of Attorney" />;
    case IconType.ADD_WILL:
      return <AddWillSVG aria-label="Add Will" />;
    case IconType.SUCCESS:
      return <SuccessSVG aria-label="Success" />;
    case IconType.WARNING:
      return <WarningSVG aria-label="Warning" />;
    case IconType.RECOMMENDED:
      return <RecommendedSVG aria-label="Recommended" className={className} />;
    case IconType.LOGIN:
      return <LoginSVG aria-label="Login" className={className} />;
    case IconType.ARROW_FAT:
      return <ArrowFatSVG aria-label="Arrow" className={className} />;
    case IconType.TICK:
      return <TickSVG aria-label="Tick" className={className} />;

    default:
      return <DefaultSVG aria-label="Default" className={className} />;
  }
};

export default Icon;
