import { createAction, Dispatch } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { User } from "modules/auth/User";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import Addon, { AddonID } from "modules/product/types/Addon";
import { PlanID } from "modules/product/types/Plan";
import ProductRecommendation from "modules/product/types/ProductRecommendation";
import { ProductSelectorType } from "modules/product/types/ProductSelector";
import { AnswersMap } from "modules/workflow/types/Answer";
import Questionnaire, {
  QuestionDefinitionAddon,
  QuestionID,
  QuestionsMap,
  QuestionType,
} from "modules/workflow/types/Questionnaire";
import { getRecommendedAddons, getRecommendedPlan } from "./helpers";

export const ADVISOR_ANSWERS_KEY = "advisorAnswers";

enum Importance {
  NO = "Not Important",
  SOMEWHAT = "Somewhat Important",
  VERY = "Very Important",
}

export enum AdvisorPlan {
  ESSENTIALS = "will-poa-hcp",
  BASE_PLAN = "base-plan",
  REVOCABLE_TRUST = "revocable-trust-plan",
  IRREVOCABLE_TRUST = "irrevocable-trust-plan",
}

export enum AdvisorAddon {
  UNDERAGE = "understated-age-beneficiaries",
  DISABLED = "standby-disabled-beneficiaries",
  SEP_SHARE = "sep-share", // TODO unsupported
  FAMILY_TR = "family-tr", // TODO unsupported
  REMARRIAGE = "remarriage", // TODO unsupported
  // = "becomes-effective"
  // = "enumerated-business-powers"
  // = "guardians-of-person-and-estate"
  // = "hcp-ca-limit-agents-authority"
  // = "hcp-ca-limit-agents-postdeath-authority"
  // = "hcp-ca-other-wishes"
  // = "hcp-fl-additional-instructions"
  // = "hcp-fl-donor-form"
  // = "monitors"
  // = "power-to-designate-beneficiaries"
}

export type Score = Record<PlanID | AddonID, number>;
type AnswerScore = { [importance in Importance]?: Score };
type AddonScore = {
  [importance in Importance]?: { [planId in AdvisorPlan]?: Score };
};

const planScores: Record<QuestionID, AnswerScore> = {
  goals_rules_1: {
    [Importance.NO]: { [AdvisorPlan.ESSENTIALS]: 1 },
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.ESSENTIALS]: 1,
      [AdvisorPlan.REVOCABLE_TRUST]: 1,
    },
    [Importance.VERY]: {
      [AdvisorPlan.REVOCABLE_TRUST]: 2,
      [AdvisorPlan.IRREVOCABLE_TRUST]: 1,
    },
  },
  goals_rules_2: {
    [Importance.NO]: { [AdvisorPlan.ESSENTIALS]: 1 },
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.ESSENTIALS]: 1,
      [AdvisorPlan.REVOCABLE_TRUST]: 1,
    },
    [Importance.VERY]: {
      [AdvisorPlan.REVOCABLE_TRUST]: 2,
      [AdvisorPlan.IRREVOCABLE_TRUST]: 1,
    },
  },
  goals_rules_3: {
    [Importance.NO]: {
      [AdvisorPlan.ESSENTIALS]: 1,
      [AdvisorPlan.REVOCABLE_TRUST]: 1,
    },
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.REVOCABLE_TRUST]: 1,
      [AdvisorPlan.IRREVOCABLE_TRUST]: 1,
    },
    [Importance.VERY]: { [AdvisorPlan.IRREVOCABLE_TRUST]: 20 },
  },
  goals_rules_6: { [Importance.VERY]: { [AdvisorPlan.IRREVOCABLE_TRUST]: 4 } },
  goals_rules_7: { [Importance.VERY]: { [AdvisorPlan.IRREVOCABLE_TRUST]: 4 } },
  goals_rules_8: { [Importance.VERY]: { [AdvisorPlan.IRREVOCABLE_TRUST]: 4 } },
};

const addonsScores: Record<QuestionID, AddonScore> = {
  goals_rules_4: {
    [Importance.VERY]: {
      [AdvisorPlan.ESSENTIALS]: { [AdvisorAddon.UNDERAGE]: 1 },
      [AdvisorPlan.REVOCABLE_TRUST]: { [AdvisorAddon.UNDERAGE]: 1 },
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.UNDERAGE]: 1 },
    },
  },
  goals_rules_5: {
    [Importance.VERY]: {
      [AdvisorPlan.ESSENTIALS]: { [AdvisorAddon.DISABLED]: 1 },
      [AdvisorPlan.REVOCABLE_TRUST]: { [AdvisorAddon.DISABLED]: 1 },
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.DISABLED]: 1 },
    },
  },
  goals_rules_6: {
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.SEP_SHARE]: 1 },
    },
    [Importance.VERY]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.SEP_SHARE]: 1 },
    },
  },
  goals_rules_7: {
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.FAMILY_TR]: 1 },
    },
    [Importance.VERY]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.FAMILY_TR]: 1 },
    },
  },
  goals_rules_8: {
    [Importance.SOMEWHAT]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.REMARRIAGE]: 1 },
    },
    [Importance.VERY]: {
      [AdvisorPlan.IRREVOCABLE_TRUST]: { [AdvisorAddon.REMARRIAGE]: 1 },
    },
  },
};

const getPlanRecommendation = (answers: AnswersMap): AdvisorPlan => {
  const scoredAnswers = Object.entries(answers).map(
    ([questionId, answer]) => planScores[questionId]?.[answer as Importance],
  );
  return getRecommendedPlan(scoredAnswers);
};

const getAddonsRecommendation = (
  answers: AnswersMap,
  recommendedPlan: AdvisorPlan,
): AddonID[] => {
  const scoredAnswers = Object.entries(answers)
    .map(
      ([questionId, answer]) =>
        addonsScores[questionId]?.[answer as Importance]?.[recommendedPlan],
    )
    .filter(Boolean);

  return getRecommendedAddons(scoredAnswers);
};

export const getRecommendation = (
  answers: AnswersMap,
): ProductRecommendation => {
  // TODO get recommendation from API
  const recommendedPlan = getPlanRecommendation(answers);
  const recommendedAddons = getAddonsRecommendation(answers, recommendedPlan);

  return {
    selector: {
      type: ProductSelectorType.PLAN,
      planId: recommendedPlan,
    },
    addons: recommendedAddons,
  };
};

export const onboardingQuestionnaireSubmitted = createAction<AnswersMap>(
  "ONBOARDING_QUESTIONNAIRE_SUBMITTED",
);

/**
 * questions within onboarding questionnaire are not enhanced with addon data, we need just addon id anyway
 */
const getOnboardingSelectedAddons = (
  questions: QuestionsMap,
  answers: AnswersMap,
): AddonID[] =>
  Object.entries(questions)
    .filter(
      ([questionID, question]) =>
        question.data.definition.type === QuestionType.ADDON &&
        answers[questionID] === true,
    )
    .map(
      ([_, question]) =>
        (question.data.definition as QuestionDefinitionAddon).addonId,
    );

export const onboardingCompleted =
  (
    user: User | null,
    questionnaireId: string,
    questionnaire: Questionnaire | undefined,
    answers: AnswersMap,
    selectedAddons?: Addon[],
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(onboardingQuestionnaireSubmitted({ [questionnaireId]: answers }));
    const selectedAddonsIds: AddonID[] = (selectedAddons || []).map(
      (addon) => addon.id,
    );
    const onboardingSelectedAddons = getOnboardingSelectedAddons(
      questionnaire?.questions || {},
      answers,
    );
    const recommendation = getRecommendation(answers);
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PRODUCT_RECOMMENDATION, undefined, {
        recommendation: recommendation.selector?.planId,
        product: recommendation.selector?.planId,
        addons: uniq([
          ...recommendation.addons,
          ...selectedAddonsIds,
          ...onboardingSelectedAddons,
        ]),
        online: true,
      }),
    );
  };
