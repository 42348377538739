import React, { useEffect, useMemo } from "react";
import { useField } from "formik";
import { useId } from "react-id-generator";
import Client from "modules/parties/types/Client";
import Party, {
  getFullName,
  ClientParties,
  getGrandchildren,
  GenericParty,
  getGenericPartyId,
} from "modules/parties/types/Party";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import Autocomplete from "components/Forms/Inputs/Autocomplete";
import PartyList, { AddPersonButton } from "components/Forms/Inputs/PartyList";
import { Sanitisers } from "components/Forms/Inputs/Input/helpers";
import { Limits, QuestionHelp } from "modules/workflow/types/Questionnaire";
import logger from "logger";
import { difference, keyBy } from "lodash";

import styles from "./styles.module.scss";
import isUnderage from "modules/parties/helpers/isUnderage";

type QuestionPeoplePickerProps = {
  client?: Client;
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  limits?: Limits;
  name: string;
  onAddParty?: () => Promise<Party | undefined>;
  onEditParty?: (party: Party) => Promise<Party | undefined>;
  requireEmail?: boolean;
  requirePhone?: boolean;
  allParties: Array<GenericParty>;
  assignableParties: Array<GenericParty>;
  partiesToImport?: ClientParties;
  placeholder?: string;
  size?: QuestionSizes;
  tabIndex?: number;
  underageWarning?: string;
};

const QuestionPeoplePicker: React.FunctionComponent<
  QuestionPeoplePickerProps
> = ({
  client,
  disabled,
  help,
  info,
  label,
  limits,
  name,
  onAddParty,
  onEditParty,
  requireEmail,
  requirePhone,
  allParties: allWorkflowParties,
  assignableParties,
  partiesToImport,
  placeholder,
  size,
  tabIndex,
  underageWarning,
}) => {
  const [questionId] = useId(1, "question-");
  const [, meta, helpers] = useField<string[]>(name);
  const [inputValue, setInputValue] = React.useState("");
  const [selectionAlert, setSelectionAlert] = React.useState<string | null>(
    null,
  );

  const allAvailableParties: Array<GenericParty> = useMemo(
    () => [
      ...allWorkflowParties,
      ...(partiesToImport?.children || []),
      ...getGrandchildren(partiesToImport?.children || []),
      ...(partiesToImport?.parties || []),
    ],
    [allWorkflowParties, partiesToImport?.children, partiesToImport?.parties],
  );

  const livingChildrenToImport = (partiesToImport?.children || []).filter(
    ({ deceased }) => !deceased,
  );
  const allSelectableParties: Array<GenericParty> = useMemo(
    () => [
      ...assignableParties,
      ...livingChildrenToImport,
      ...getGrandchildren(partiesToImport?.children || []),
      ...(partiesToImport?.parties || []),
    ],
    [
      assignableParties,
      livingChildrenToImport,
      partiesToImport?.children,
      partiesToImport?.parties,
    ],
  );

  // Correct dependent values
  useEffect(() => {
    const currentSelection = meta.value;
    const allowedValues = allSelectableParties.map(getGenericPartyId);
    const disallowedParties = difference(currentSelection, allowedValues);

    if (disallowedParties.length) {
      helpers.setValue(difference(currentSelection, disallowedParties));
    }
  }, [allSelectableParties, helpers, meta.value]);

  // Check underage parties
  useEffect(() => {
    if (underageWarning) {
      const allPartiesMap = keyBy(allAvailableParties, getGenericPartyId);
      const selectedParties = (meta.value || [])
        .map((genericPartyId) => allPartiesMap[genericPartyId])
        .filter(Boolean);

      const hasSelectedUnderageParty = Boolean(
        selectedParties.find((party) => isUnderage(party)),
      );
      if (hasSelectedUnderageParty && !selectionAlert)
        setSelectionAlert(underageWarning);
      if (!hasSelectedUnderageParty && selectionAlert) setSelectionAlert(null);
    }
  }, [underageWarning, allAvailableParties, meta.value, selectionAlert]);

  const addPerson = (newPersonId?: string) => {
    const currentValue = meta.value || [];
    if (newPersonId && currentValue.indexOf(newPersonId) === -1) {
      helpers.setValue(
        limits?.max === undefined || limits.max > 1
          ? [...currentValue, newPersonId]
          : [newPersonId],
      );
    }
  };

  const handleTriggerAddParty = async () => {
    const newParty = onAddParty && (await onAddParty());
    addPerson(newParty?.partyId);
  };

  const handleEditParty = async (
    party: Party,
    saveCallback?: (saveResult: Party | undefined) => void,
  ) => {
    try {
      const savedParty = onEditParty ? await onEditParty(party) : undefined;
      helpers.setTouched(true);
      saveCallback && saveCallback(savedParty);
    } catch (e) {
      // This can't really happen, but in case...
      logger.error("An attempt to edit a party failed", e);
      saveCallback && saveCallback(undefined);
    }
  };

  const handleRemoveParty = (party: GenericParty) => {
    const partyId = getGenericPartyId(party);
    const idx = meta.value?.indexOf(partyId);
    if (idx >= 0) {
      const nextValue = [...meta.value];
      nextValue.splice(idx, 1);
      helpers.setValue(nextValue);
    }
  };

  const handleReorderParties = (parties: GenericParty[]) => {
    helpers.setValue(parties.map(getGenericPartyId));
  };

  const selectedParties: Array<GenericParty> = (meta.value || [])
    .map((id) => {
      const party = allAvailableParties.find(
        (p) => getGenericPartyId(p) === id,
      );
      if (!party) logger.error(`[PeoplePicker] Party with ID ${id} not found`);
      return party;
    })
    .filter(Boolean) as Array<GenericParty>;

  const availableParties = allSelectableParties.filter(
    (party) => !meta.value?.includes(getGenericPartyId(party)),
  );
  const allowAddParty =
    !limits ||
    limits.max === undefined ||
    (meta.value || []).length < limits.max;

  const renderPeoplePicker = () => (
    <>
      {availableParties.length > 0 ? (
        <>
          <Autocomplete<GenericParty>
            disabled={disabled}
            inputValue={inputValue}
            name={name}
            onClear={() => setInputValue("")}
            onInputChange={(_, newInputValue) => {
              setInputValue(
                newInputValue.replace(Sanitisers.LEADING_WHITESPACE, ""),
              );
            }}
            onValueChange={(_, newValue) => {
              setInputValue("");
              newValue &&
                addPerson(newValue ? getGenericPartyId(newValue) : undefined);
            }}
            options={availableParties}
            getOptionLabel={(party) => getFullName(party)}
            placeholder={placeholder}
            tabIndex={tabIndex}
          />
          {onAddParty ? <span>or</span> : null}
        </>
      ) : null}
      {onAddParty ? (
        <AddPersonButton onAddParty={handleTriggerAddParty} />
      ) : null}
      {availableParties.length === 0 && !onAddParty ? (
        <div className={styles["PeoplePicker__picker--empty"]}>
          (No parties available)
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <QuestionWrapper
        blurTouched={true}
        className="question--autocomplete"
        disabled={disabled}
        help={help}
        id={questionId}
        info={info}
        label={label}
        name={name}
        size={size || QuestionSizes.LARGE}
      >
        <div className={styles.PeoplePicker__picker}>
          {allowAddParty ? renderPeoplePicker() : null}
        </div>
      </QuestionWrapper>
      <PartyList
        alert={selectionAlert || undefined}
        client={client}
        disabled={disabled}
        onEditParty={handleEditParty}
        onRemoveParty={handleRemoveParty}
        onReorderParties={handleReorderParties}
        parties={selectedParties}
        partiesToImport={partiesToImport}
        requireEmail={requireEmail}
        requirePhone={requirePhone}
      />
    </>
  );
};

export default QuestionPeoplePicker;
