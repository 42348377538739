import { pick } from "lodash";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import PageHeader from "components/PageHeader";
import { go } from "modules/navigation/helpers/navigator";
import {
  ApplicableJurisdiction,
  DefinitionCategory,
  DefinitionData,
  DefinitionDescriptions,
  DEFINITION_CATEGORY_ADDONS,
  DEFINITION_CATEGORY_PLANS,
  DEFINITION_CATEGORY_PRODUCTS,
} from "modules/api/responseTypes/DefinitionResponseType";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";
import ConfigList from "./ConfigList";
import PromoCodesSetupForm from "./PromoCodesSetupForm";
import Partner from "modules/parties/types/Partner";
import { ProductID } from "modules/product/types/Product";
import { PlanID } from "modules/product/types/Plan";
import { showModalConfirm } from "components/Modal";
import { saveDefinition } from "modules/admin/actions";
import logger from "logger";
import { NotificationType } from "components/Notification";
import { AddonID } from "modules/product/types/Addon";
import Alert, { AlertType } from "components/Alert";

type Props = {
  baseRoute: string;
  data: DefinitionData;
  definitionId: string;
  descriptions: DefinitionDescriptions;
  partner: Partner;
  addonJurisdictions: Record<AddonID, ApplicableJurisdiction[]>;
};

const ProductConfigurationPicker: React.FC<Props> = ({
  baseRoute,
  data,
  definitionId,
  descriptions,
  partner,
  addonJurisdictions,
}) => {
  const { categoryKey } = useParams<{ categoryKey?: string }>();
  const dispatch = useDispatch();

  const promoCodes = partner?.data.promoCodes || {};

  const handleChangeTab = (e: any, value: DefinitionCategory) => {
    go(dispatch, `${baseRoute}/${value}`);
  };

  const DISCOUNTS_ROUTE = "discounts";

  const availableAddonIds = Object.entries(addonJurisdictions)
    .filter(([_, applicableJurisdictions]) =>
      applicableJurisdictions.reduce(
        (acc, applicableJurisdiction) =>
          acc ||
          applicableJurisdiction.type === "Generic" ||
          (applicableJurisdiction.type === "Specific" &&
            partner.data.jurisdiction.includes(applicableJurisdiction.value)),
        false as boolean,
      ),
    )
    .map(([addonId, _]) => addonId);

  const renderList = (
    category: DefinitionCategory,
    onToggleItem?: (key: string) => void,
  ) => {
    const categoryPath = `${baseRoute}/${category}`;
    const pickedDescriptions =
      category === "addons"
        ? pick(descriptions[category], availableAddonIds)
        : descriptions[category];

    const pickedDefinitions =
      category === "addons"
        ? pick(data[category], availableAddonIds)
        : data[category];

    return (
      <Route path={categoryPath}>
        {category === "addons" && (
          <Alert
            type={AlertType.INFO}
            message="Only tailored options available in your jurisdictions are listed."
          />
        )}
        <ConfigList
          keys={Object.keys(pickedDefinitions)}
          descriptions={pickedDescriptions}
          definitions={pickedDefinitions}
          categoryPath={categoryPath}
          toggleItem={onToggleItem}
        />
      </Route>
    );
  };

  const confirmToggle = useCallback(
    (
      id: PlanID | ProductID,
      planOrProductOrAddon: "plan" | "product" | "addon",
    ) => {
      const category = `${planOrProductOrAddon}s` as
        | "plans"
        | "products"
        | "addons";
      const product = data[category][id];
      const productDetails = descriptions[category][id];

      const planOrProductOrAddonText =
        planOrProductOrAddon === "addon"
          ? "tailored option"
          : planOrProductOrAddon;

      const text = product.enabled
        ? `Disabling the ${planOrProductOrAddonText} will prevent clients from selecting it to start a new workflow, but it won't prevent them from accessing or completing their existing workflows.`
        : `Are you sure you want to enable ${productDetails.name}?`;

      showModalConfirm({
        title: `${product.enabled ? "Disable" : "Enable"} ${
          productDetails.name
        }`,
        text,
        onConfirm: async () => {
          try {
            await saveDefinition(definitionId, {
              [category]: {
                ...data[category],
                [id]: {
                  ...data[category][id],
                  enabled: !product.enabled,
                },
              },
            })(dispatch);
          } catch (e) {
            logger.notify(
              NotificationType.ERROR,
              "Your settings couldn't be saved. Please, try again later.",
              e,
            );
          }
        },
      });
    },
    [data, definitionId, descriptions, dispatch],
  );

  const togglePlan = useCallback(
    (planId: PlanID) => {
      confirmToggle(planId, "plan");
    },
    [confirmToggle],
  );

  const toggleProduct = useCallback(
    (productId: ProductID) => {
      confirmToggle(productId, "product");
    },
    [confirmToggle],
  );

  const toggleAddon = useCallback(
    (addonId: AddonID) => {
      confirmToggle(addonId, "addon");
    },
    [confirmToggle],
  );

  return (
    <>
      <PageHeader title="Product Configuration" />
      <div>
        <Box mb={2}>
          <Tabs
            value={categoryKey || DEFINITION_CATEGORY_PRODUCTS}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab value={DEFINITION_CATEGORY_PRODUCTS} label="Products" />
            <Tab value={DEFINITION_CATEGORY_PLANS} label="Plans" />
            <Tab value={DEFINITION_CATEGORY_ADDONS} label="Tailored Options" />
            <Tab value={DISCOUNTS_ROUTE} label="Discounts" />
          </Tabs>
          <Divider />
        </Box>
        <Switch>
          {renderList("products", toggleProduct)}
          {renderList("plans", togglePlan)}
          {renderList("addons", toggleAddon)}

          <Route path={`${baseRoute}/${DISCOUNTS_ROUTE}`}>
            <PromoCodesSetupForm promoCodes={promoCodes} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default ProductConfigurationPicker;
