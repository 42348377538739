import moment from "moment-timezone";
import { PERIOD } from "screens/finance/types";

export const getStartDate = (
  period?: PERIOD,
  timezone?: string,
): string | null => {
  if (period === undefined || period === PERIOD.ALL_TIME) return null;

  const now = timezone ? moment().tz(timezone) : moment();

  return period === PERIOD.THIS_MONTH
    ? now.startOf("month").format("YYYY-MM-DD")
    : now.subtract(1, "months").startOf("month").format("YYYY-MM-DD");
};

export const getEndDate = (period?: PERIOD, timezone?: string) => {
  if (period === undefined || period === PERIOD.ALL_TIME) return null;

  const now = timezone ? moment().tz(timezone) : moment();

  return period === PERIOD.THIS_MONTH
    ? now.endOf("month").format("YYYY-MM-DD")
    : now.subtract(1, "months").endOf("month").format("YYYY-MM-DD");
};
