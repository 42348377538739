import React from "react";
import { SelfSigningDisclaimerProps } from "./types";
import Confirm from "components/Forms/Inputs/Confirm";
import styles from "./styles.module.scss";

const DISCLAIMER_TEXT = `By checking this box and choosing self-signing,
  I understand that it is my responsibility to get my documents signed and
  executed properly.`;

const SelfSigningDisclaimer = ({
  checked,
  setChecked,
}: SelfSigningDisclaimerProps) => (
  <div className={styles["self-signing-disclaimer"]}>
    <Confirm
      label={DISCLAIMER_TEXT}
      initialValue={checked}
      onValueChange={setChecked}
    />
  </div>
);

export default SelfSigningDisclaimer;
