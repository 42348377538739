import { Box } from "@mui/material";
import { ModalControls, ModalType, showModal } from "components/Modal";
import TabbedButton from "components/TabbedButton";
import logger, { TYPE } from "logger";
import { API, post } from "modules/api";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import React from "react";
import { useDispatch } from "react-redux";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "../../../../workflow/types/Answer";
import ApiError from "../../../../api/types/ApiError";
import { PlanID } from "../../../../product/types/Plan";
import { ProductID } from "../../../../product/types/Product";
import axios from "axios";
import {
  draftingAnswersChanged,
  resetDraftingForm,
} from "modules/partner/actions";
import { useSelector } from "redux/reducer";
import { createNewWorkflow } from "modules/workflow/actions";
import { PartnerID } from "modules/parties/types/Partner";
import { UserID } from "modules/auth/User";
import { ProductSelectorType } from "modules/product/types/ProductSelector";
import { clientFields } from "modules/parties/helpers/getClientQuestionnaire/constants";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type StateSelectionProps = {
  onStateChange: (state: string) => void;
};

type CreateDraftResponse = {
  partnerId: PartnerID;
  partnerUserId: UserID;
  productId: ProductID;
  productType: string;
  userId: UserID;
  created: string;
  updated: string;
  createdBy: UserID;
};

const populateMissingAnswers = (answers: AnswersMap) => ({
  state: answers.state || "",
  timezone: "America/New_York",
  firstName: answers[clientFields.firstName] || "",
  middleName: answers[clientFields.middleName] || "",
  lastName: answers[clientFields.lastName] || "",
  phoneNumber: answers[clientFields.contact.cellPhone] || "",
  email: answers[clientFields.contact.email] || "",
  productCategory: answers.productCategory || "",
  productSelection: answers.productSelection || "",
});

const DraftAdd: React.FC<StateSelectionProps> = ({ onStateChange }) => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(resetDraftingForm());
    showDraft(onStateChange);
  };

  return (
    <Box mb={2}>
      <TabbedButton onClick={handleModalOpen}>Add New Draft</TabbedButton>
    </Box>
  );
};

export const showDraft = (onStateChange: (state: string) => void) => {
  showModal({
    title: "Add Draft",
    type: ModalType.CONFIRM,
    renderContent: (controls: ModalControls) => (
      <DraftModalContent onStateChange={onStateChange} controls={controls} />
    ),
  });
};

type ModalProps = {
  controls: ModalControls;
  onStateChange: (state: string) => void;
};

const QUESTIONNAIRE_ID = "addDraftModal";

const DraftModalContent: React.FC<ModalProps> = ({
  controls,
  onStateChange,
}) => {
  const dispatch = useDispatch();

  const draftingFormAnswers = useSelector((state) => state.draftingFormAnswers);

  const handleChange = (answersByQuestionnaire: AnswersByQuestionnaireType) =>
    answersByQuestionnaire[QUESTIONNAIRE_ID] !== undefined &&
    dispatch(draftingAnswersChanged(answersByQuestionnaire[QUESTIONNAIRE_ID]));

  const startDraft = async () => {
    controls?.busy(true);
    try {
      const res = await post<CreateDraftResponse>(
        API.DRAFTS(),
        populateMissingAnswers(draftingFormAnswers),
      );

      const { productId: productOrPlanID, productType, userId } = res.data;

      const jurisdiction = draftingFormAnswers.state;

      // change state
      if (draftingFormAnswers.state != null) {
        onStateChange(draftingFormAnswers.state.toString());
      }

      if (productType === "plan") {
        const planId: PlanID = productOrPlanID;
        jurisdiction !== null &&
          jurisdiction !== undefined &&
          (await createNewWorkflow(
            "default-workflow",
            {
              type: ProductSelectorType.PLAN,
              planId,
            },
            jurisdiction.toString(),
            "LAWYER",
            undefined,
            [],
            userId,
          )(dispatch));
      } else if (productType === "product") {
        const productId: ProductID = productOrPlanID;
        jurisdiction !== null &&
          jurisdiction !== undefined &&
          (await createNewWorkflow(
            "default-workflow",
            {
              type: ProductSelectorType.PRODUCT,
              productIds: [productId],
            },
            jurisdiction.toString(),
            "LAWYER",
            undefined,
            [],
            userId,
          )(dispatch));
      }
    } catch (error) {
      const defaultErrorMessage = "Adding draft failed. Please, try again.";
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        logger.notify(
          TYPE.ERROR,
          error?.response
            ? new ApiError(defaultErrorMessage, error.response)
            : defaultErrorMessage,
          error,
        );
      } else {
        logger.notify(TYPE.ERROR, defaultErrorMessage, error);
      }
    } finally {
      controls?.busy(false);
      controls?.close();
    }
  };

  return (
    <>
      <QuestionnaireForm
        customFormProps={{ showSaveAndExitButton: false }}
        onSavePageAnswers={() => {}}
        onChange={handleChange}
        answers={{
          [QUESTIONNAIRE_ID]: draftingFormAnswers,
        }}
        questionsBySubquestionnaire={{
          [QUESTIONNAIRE_ID]: {
            state: {
              id: "state",
              data: {
                descriptions: [{ label: "State" }],
                required: true,
                definition: { type: QuestionType.STATE, maxSuggestedItems: 51 },
              },
            },
            [clientFields.firstName]: {
              id: clientFields.firstName,
              data: {
                descriptions: [{ label: "Client First name" }],
                required: true,
                definition: { type: QuestionType.TEXT },
              },
            },
            [clientFields.middleName]: {
              id: clientFields.middleName,
              data: {
                descriptions: [{ label: "Client Middle name" }],
                required: false,
                definition: { type: QuestionType.TEXT },
              },
            },
            [clientFields.lastName]: {
              id: clientFields.lastName,
              data: {
                descriptions: [{ label: "Client Last name" }],
                required: true,
                definition: { type: QuestionType.TEXT },
              },
            },
            [clientFields.contact.cellPhone]: {
              id: clientFields.contact.cellPhone,
              data: {
                required: true,
                definition: {
                  type: QuestionType.TEXT,
                  subtype: TextFieldType.TEL,
                },
                descriptions: [{ label: "Client Phone number" }],
              },
            },
            [clientFields.contact.email]: {
              id: clientFields.contact.email,
              data: {
                required: true,
                definition: {
                  type: QuestionType.TEXT,
                  subtype: TextFieldType.EMAIL,
                },
                descriptions: [{ label: "Client E-mail" }],
              },
            },
            productCategory: {
              id: "productCategory",
              data: {
                descriptions: [{ label: "Product Category" }],
                required: true,
                definition: {
                  type: QuestionType.SELECT,
                  values: [
                    { value: "estate", display: "Estate Planning Documents" },
                  ],
                  limits: { max: 1 },
                },
                defaultValue: "estate",
              },
            },
            productSelection: {
              id: "productSelection",
              data: {
                descriptions: [{ label: "Product Selection" }],
                required: true,
                definition: {
                  type: QuestionType.SELECT,
                  values: [
                    { value: "will", display: "Will Only" },
                    { value: "hcp", display: "Healthcare Proxy Only" },
                    { value: "poa", display: "Power of Attorney Only" },
                    { value: "hcp,poa", display: "Powers Documents" },
                    { value: "will,hcp,poa", display: "Essentials Documents" },
                  ],
                  limits: { max: 1 },
                },
              },
            },
          },
        }}
        questionnairePages={[
          {
            id: "default_page",
            questionnaireId: QUESTIONNAIRE_ID,
            caption: "",
            sections: [
              {
                caption: "",
                questions: [
                  "state",
                  "timezone",
                  clientFields.firstName,
                  clientFields.middleName,
                  clientFields.lastName,
                  clientFields.contact.cellPhone,
                  clientFields.contact.email,
                  "productCategory",
                  "productSelection",
                ],
              },
            ],
          },
        ]}
      />
      <TabbedButton
        onClick={startDraft}
        label="Begin Draft"
        addClassName={cx("begin-draft-button")}
      />
    </>
  );
};

export default DraftAdd;
