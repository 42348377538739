import {
  concat,
  groupBy,
  keyBy,
  mapKeys,
  mapValues,
  merge,
  sortBy,
} from "lodash";
import { AnswersByQuestionnaireType, AnswersMap } from "../types/Answer";
import Condition, {
  isAddonCondition,
  isAddonOfferCondition,
  isAllCondition,
  isAnyCondition,
  isNotCondition,
  isWorkflowCondition,
} from "../types/Condition";
import { isChildCondition } from "../types/Condition/ChildCondition";
import {
  isClientCondition,
  isSpouseCondition,
} from "../types/Condition/ClientDetailsCondition";
import ValueCondition from "../types/Condition/ValueCondition";
import {
  Question,
  QuestionID,
  QuestionnaireID,
  QuestionnairePage,
  QuestionnaireSection,
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";

const translateCondition = (
  questionnaireId: string,
  condition: Condition,
): Condition => {
  if (isAnyCondition(condition))
    return {
      any: condition.any.map((conditionCase) =>
        translateCondition(questionnaireId, conditionCase),
      ),
    };

  if (isAllCondition(condition))
    return {
      all: condition.all.map((conditionCase) =>
        translateCondition(questionnaireId, conditionCase),
      ),
    };

  if (isNotCondition(condition))
    return { not: translateCondition(questionnaireId, condition.not) };

  if (
    isAddonCondition(condition) ||
    isAddonOfferCondition(condition) ||
    isClientCondition(condition) ||
    isSpouseCondition(condition) ||
    isChildCondition(condition) ||
    isWorkflowCondition(condition)
  )
    return condition;

  const [originalQuestionnaireId, valueConstraint] = Object.entries(
    condition as ValueCondition,
  )[0];
  const translatedValueConstraint = mapKeys(valueConstraint, (_, questionId) =>
    prefixId(originalQuestionnaireId, questionId),
  );
  return { [questionnaireId]: translatedValueConstraint };
};

export const mergeQuestions = (
  newQuestionnaireId: string,
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire,
) => {
  const allTranslatedQuestions = Object.entries(questionsBySubquestionnaire)
    .map(([originalQuestionnaireId, questionsMap]) =>
      Object.values(questionsMap).map(
        (question) =>
          ({
            ...question,
            id: prefixId(originalQuestionnaireId, question.id),
            data: {
              ...question.data,
              condition: question.data.condition
                ? translateCondition(
                    newQuestionnaireId,
                    question.data.condition,
                  )
                : undefined,
            },
          }) as Question,
      ),
    )
    .flat();
  return { [newQuestionnaireId]: keyBy(allTranslatedQuestions, "id") };
};

export const mergeConditions = (
  questionnaireId: string,
  pageCondition?: Condition,
  sectionCondition?: Condition,
): Condition | undefined => {
  if (!pageCondition && !sectionCondition) return undefined;

  const conditions = [
    pageCondition && translateCondition(questionnaireId, pageCondition),
    sectionCondition && translateCondition(questionnaireId, sectionCondition),
  ].filter(Boolean) as Condition[];

  return conditions.length === 2 ? { all: conditions } : conditions[0];
};

const prefixId = (prefix: string, id: string) => `${prefix}_${id}`;

export const prefixQuestionIDs = (
  originalQuestionnaireID: string,
  questions: Array<string | string[]> | undefined,
): Array<string | string[]> | undefined =>
  questions?.map((value) =>
    Array.isArray(value)
      ? (prefixQuestionIDs(originalQuestionnaireID, value) as string[])
      : prefixId(originalQuestionnaireID, value),
  );

export const mergeQuestionnairesAnswers = (
  newQuestionnaireId: string,
  answers: AnswersByQuestionnaireType,
): AnswersByQuestionnaireType => {
  const answersMaps: any = Object.entries(answers).map(
    ([questionnaireId, answers]) =>
      Object.entries(answers).reduce((acc, [questionId, answer]) => {
        acc[prefixId(questionnaireId, questionId)] = answer;
        return acc;
      }, {} as AnswersMap),
  );
  return { [newQuestionnaireId]: Object.assign({}, ...answersMaps) };
};

const findQuestionIDs = (
  mergedId: string,
  questionIDsByQuestionnaire: Record<string, QuestionID[]>,
): { questionId: string; questionnaireId: string } | undefined => {
  for (let [questionnaireId, questionIDs] of Object.entries(
    questionIDsByQuestionnaire,
  )) {
    const questionId = questionIDs.find(
      (id) => prefixId(questionnaireId, id) === mergedId,
    );
    if (questionId) {
      return { questionId, questionnaireId };
    }
  }
  return undefined;
};

const getQuestionIDsByQuestionnaire = (
  questionDefinitions: QuestionsMapByQuestionnaire,
): Record<string, QuestionID[]> =>
  mapValues(questionDefinitions, (questionsMap) =>
    Object.values(questionsMap).map((q) => q.id),
  );

export const explodeMergedAnswers = (
  mergedQuestionnaireId: string,
  answers: AnswersByQuestionnaireType,
  questionDefinitions: QuestionsMapByQuestionnaire,
): AnswersByQuestionnaireType => {
  const questionIDsByQuestionnaire =
    getQuestionIDsByQuestionnaire(questionDefinitions);
  const { [mergedQuestionnaireId]: mergedAnswers, ...otherAnswers } = answers;
  const explodedAnswers = Object.entries(mergedAnswers || {}).reduce(
    (acc, [mergedId, value]) => {
      const ids = findQuestionIDs(mergedId, questionIDsByQuestionnaire);
      const questionId = ids ? ids.questionId : mergedId;
      const questionnaireId = ids ? ids.questionnaireId : mergedQuestionnaireId;

      return {
        ...acc,
        [questionnaireId]: {
          ...(acc[questionnaireId] || {}),
          [questionId]: value,
        },
      };
    },
    {} as AnswersByQuestionnaireType,
  );

  return merge(otherAnswers, explodedAnswers);
};

const mergeSections = (
  sections: QuestionnaireSection[],
): QuestionnaireSection => ({
  caption: "",
  questions: sections.map(({ questions = [] }) => questions).flat(),
});

export const mergePagesToSinglePage = (
  pageId: string,
  questionnaireId: QuestionnaireID,
  subquestionnairePages: QuestionnairePage[],
  shouldMergeSections: boolean = true,
): QuestionnairePage => {
  const allSections = subquestionnairePages.map((p) => p.sections).flat();

  return {
    id: pageId,
    questionnaireId: questionnaireId,
    sections: shouldMergeSections ? [mergeSections(allSections)] : allSections,
  };
};

const mergeGroups = (
  groups: Array<[string, QuestionnairePage[]]>,
): Array<[string, QuestionnairePage[]]> => {
  const [firstGroup, ...otherGroups] = groups;
  return [
    [
      firstGroup[0],
      concat(firstGroup[1], ...otherGroups.map(([idx, pages]) => pages)),
    ],
  ];
};

export default (
  newQuestionnaireID: string,
  pages: QuestionnairePage[],
  enforceSinglePage?: boolean,
): QuestionnairePage[] => {
  const sortedPageIndexGroups = sortBy(
    Object.entries(groupBy(pages, "index")),
    ([index]) => index,
  );

  const groupedPages = enforceSinglePage
    ? mergeGroups(sortedPageIndexGroups)
    : sortedPageIndexGroups;

  return groupedPages.map(([index, group]) => ({
    id: prefixId(newQuestionnaireID, index),
    index: group[0].index,
    questionnaireId: newQuestionnaireID,
    caption: group.length === 1 ? group[0].caption : undefined,
    description: group.length === 1 ? group[0].description : undefined,
    sections: group
      .map((page) =>
        page.sections.map(
          (section) =>
            ({
              ...section,
              caption: section.caption || page.caption,
              condition: mergeConditions(
                newQuestionnaireID,
                page.condition,
                section.condition,
              ),
              questions: prefixQuestionIDs(
                page.questionnaireId,
                section.questions,
              ),
            }) as QuestionnaireSection,
        ),
      )
      .flat(),
  }));
};
