import * as yup from "yup";
import { keyBy, mapValues, sumBy } from "lodash";
import { Share } from "components/Forms/Questions/QuestionPerParty/QuestionShareDistribution";
import {
  QuestionType,
  Question,
  TextFieldType,
  QuestionID,
  QuestionsMapByQuestionnaire,
  QuestionsMap,
  QuestionDefinition,
  isImageData,
  QuestionDefinitionTextual,
  isSelectMultiple,
  QuestionDefinitionNumeric,
  QuestionDefinitionCurrency,
  Limits,
} from "../types/Questionnaire";
import Party, { getGenericPartyId } from "modules/parties/types/Party";
import { PartyValue } from "components/Forms/Questions/QuestionPerParty";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
  AnswerValueType,
  Multiple,
  OrOption,
  StoredAnswerValueType,
} from "../types/Answer";
import {
  PartyOrClient,
  PerPartyAnswerType,
  PerPartyRelationshipAnswer,
  PerPartySharesAnswer,
  PerPartyValuePairsAnswer,
} from "modules/parties/types/PerPartyAnswer";
import { StoredAddress } from "modules/parties/types/Address";
import currencyFormatter from "utils/currencyFormatter";
import getLimitsErrorMessage from "./getLimitsErrorMessage";
import { ComplexAnswerType } from "modules/parties/types/ComplexAnswerType";

yup.setLocale({
  mixed: {
    oneOf: "Please complete this question.",
    required: "Please complete this question.",
    // eslint-disable-next-line no-template-curly-in-string
    notType: "This is not a valid ${type}.",
  },
  string: {
    email: "This is not a valid email address.",
  },
});

const regexCurrency = /^(-?\d)+(\.[\d]{1,2})?$/;
yup.addMethod<yup.NumberSchema>(yup.number, "currency", function () {
  return this.test("currency", "This is not a valid amount.", function (value) {
    return value ? regexCurrency.test(value.toString(10)) : true;
  });
});

const regexPhoneNumber = /(^\d{3}-\d{3}-\d{4}$)/;
yup.addMethod<yup.StringSchema>(yup.string, "phone", function () {
  return this.test(
    "phone",
    "This is not a valid phone number.",
    function (value) {
      return value ? regexPhoneNumber.test(value) : true;
    },
  );
});

const regexSocialSecurityNumber = /(^\d{3}-\d{2}-\d{4}$)/;
yup.addMethod<yup.StringSchema>(
  yup.string,
  "socialSecurityNumber",
  function () {
    return this.test(
      "socialSecurityNumber",
      "This is not a valid social security number.",
      function (value) {
        return value ? regexSocialSecurityNumber.test(value) : true;
      },
    );
  },
);

const regexZipCode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
yup.addMethod<yup.StringSchema>(yup.string, "zipCode", function () {
  return this.test(
    "zipCode",
    "This is not a valid zip code.",
    function (value) {
      return value ? regexZipCode.test(value) : true;
    },
  );
});

const regexDateWithoutLetters = /^[^a-zA-Z]+$/;
yup.addMethod<yup.DateSchema>(yup.date, "withoutLetters", function () {
  return this.test(
    "withoutLetters",
    "This is not a valid date.",
    function (value) {
      const { options } = this as yup.TestContext & yup.TestContextExtended;
      if (options?.originalValue) {
        return value
          ? regexDateWithoutLetters.test(options.originalValue)
          : false;
      }
      return true;
    },
  );
});

const toNumber = (value: any): number | undefined =>
  value !== undefined && Number.isFinite(Number(value))
    ? Number(value)
    : undefined;

const getShare = (share: Share): number => {
  // Percent
  const numericShare = toNumber(share.share);
  if (numericShare !== undefined) return numericShare;

  // Fraction
  if (typeof share?.share !== "string" || !share.share.includes("/")) return 0;
  const parts = share.share.match(/^([0-9]+)\/([0-9]+)$/);
  return parts?.length === 3 &&
    Number.isInteger(Number(parts[1])) &&
    Number.isInteger(Number(parts[2]))
    ? (100 * Number(parts[1])) / Number(parts[2])
    : 0;
};

yup.addMethod<yup.ArraySchema<Share>>(
  yup.array,
  "shareDistribution",
  function () {
    return this.test(
      "shareDistribution",
      "Please complete this question.",
      function (value: Share[] | null | undefined) {
        const { path, createError } = this;
        if (!value || value.length === 0) return true;

        const sum = sumBy(value, (share) => getShare(share));
        return sum !== 100
          ? createError({
              path,
              message: `Share allocations must add-up to 100%`,
            })
          : true;
      },
    );
  },
);

const setStringValueLimits = (
  schema: yup.StringSchema,
  min?: number,
  max?: number,
): yup.StringSchema => {
  let schemaWithLowerLimit =
    min !== undefined
      ? schema.min(min, `Value must be at least ${min} characters long.`)
      : schema;
  return max !== undefined
    ? schemaWithLowerLimit.max(
        max,
        `Value must be at most ${max} characters long.`,
      )
    : schemaWithLowerLimit;
};

const getTextfieldValidationSchema = (
  definition: QuestionDefinitionTextual,
) => {
  switch (definition.subtype) {
    case TextFieldType.ZIP:
      return yup.string().zipCode();

    case TextFieldType.EMAIL:
      return yup.string().email();

    case TextFieldType.SSN:
      return yup.string().socialSecurityNumber();

    case TextFieldType.TEL:
      return yup.string().phone();

    // case TextFieldType.CODE:
    default:
      return setStringValueLimits(
        yup.string(),
        definition.limits?.min,
        definition.limits?.max,
      );
  }
};

export const isNumeric = (questionDefinition: QuestionDefinition | undefined) =>
  questionDefinition &&
  [
    QuestionType.CURRENCY,
    QuestionType.NUMERIC,
    QuestionType.PERCENTAGE,
  ].includes(questionDefinition.type);

const applyLimitsToSchema = (
  schema: any,
  limits: Limits,
  valueFormatter?: (val: number) => string,
) => {
  if (limits.min) {
    schema.min(
      limits.min,
      getLimitsErrorMessage(
        `Value must be greater than or equal to ${
          valueFormatter ? valueFormatter(limits.min) : limits.min
        }`,
        limits,
      ),
    );
  }
  if (limits.max) {
    schema.max(
      limits.max,
      getLimitsErrorMessage(
        `Value must be less than or equal to ${
          valueFormatter ? valueFormatter(limits.max) : limits.max
        }`,
        limits,
      ),
    );
  }
  return schema;
};

const applyNumericLimits = (
  schema: any,
  questionDefinition: QuestionDefinitionNumeric | QuestionDefinitionCurrency,
  valueFormatter?: (val: number) => string,
) => {
  const min = questionDefinition.limits?.min;
  const max = questionDefinition.limits?.max;
  if (min !== undefined) {
    schema = schema.min(
      min,
      `Value must be greater than or equal to ${
        valueFormatter ? valueFormatter(min) : min
      }`,
    );
  }
  if (max !== undefined) {
    schema = schema.max(
      max,
      `Value must be less than or equal to ${
        valueFormatter ? valueFormatter(max) : max
      }`,
    );
  }
  return schema;
};

export const isRequired = (
  questionDefinition: QuestionDefinition | undefined,
  required?: boolean,
): boolean =>
  Boolean(
    isNumeric(questionDefinition)
      ? required
      : required && (questionDefinition as any)?.limits?.min !== 0,
  );

export const getFieldValidationSchema = (
  questionDefinition: QuestionDefinition,
  required?: boolean,
): any => {
  let schema: any;

  switch (questionDefinition.type) {
    case QuestionType.ADDRESS:
      schema = yup.object().shape({
        line1: yup.string().required(),
        line2: yup.string(),
        city: yup.string().required(),
        state: yup.string().required(),
        adminAreaName: yup.string(),
        zip: yup.string().required(),
      });
      break;

    case QuestionType.CURRENCY:
      schema = applyNumericLimits(
        yup.number().currency(),
        questionDefinition,
        (amount) => currencyFormatter.format(amount),
      );
      break;

    case QuestionType.DATE:
      schema = yup.date().withoutLetters();
      break;

    case QuestionType.ADDON:
    case QuestionType.FLAG:
      schema = yup.mixed().oneOf([true, false]);
      break;

    case QuestionType.CONFIRM:
      schema = yup.boolean().oneOf([true], questionDefinition.errorMessage);
      break;

    case QuestionType.NUMERIC: {
      schema = applyNumericLimits(yup.number(), questionDefinition);
      break;
    }

    case QuestionType.PERCENTAGE:
      const percentageSchema = yup.number().min(0).max(100);
      schema = questionDefinition.allowDecimal
        ? percentageSchema
        : percentageSchema.integer();
      break;

    case QuestionType.TEXT:
      schema = getTextfieldValidationSchema(questionDefinition);
      if (questionDefinition.blacklist) {
        schema = schema.notOneOf(
          questionDefinition.blacklist.map((val) => val.toUpperCase()),
          "Entered value can't be used. Please, choose a different one.",
        );
      }
      break;

    case QuestionType.SHARE_DISTRIBUTION:
      schema = yup
        .array()
        .of(
          yup.object().shape({
            share: yup
              .string()
              .required()
              .matches(
                /^[0-9]+(\/[0-9]+)?$/,
                "Provided value is not a valid share",
              )
              .test(
                "min",
                "Share allocations must be greater than 0%.",
                (val) => !!val && Number.parseInt(val) > 0,
              )
              .test(
                "max",
                "Share allocations must be less than or equal 100%.",
                (val) => !!val && Number.parseInt(val) <= 100,
              ),
          }),
        )
        .shareDistribution();
      break;

    case QuestionType.SUBQUESTIONNAIRE:
      const perPartiesSchema = mapValues(
        keyBy(questionDefinition.parties || [], (p) => getGenericPartyId(p)),
        (party) =>
          yup
            .object()
            .required("Please, provide answers for all selected parties"),
      );
      schema = yup.object().shape({
        type: yup
          .string()
          .matches(
            new RegExp(`^${PerPartyAnswerType.QUESTIONNAIRE}$`),
            "Provided answer is invalid",
          ),
        values: yup.object().shape(perPartiesSchema),
      });
      break;

    case QuestionType.PER_PARTY: {
      schema = yup.array().of(
        yup.object().shape({
          value: getFieldValidationSchema(
            questionDefinition.definition,
            required,
          ),
        }),
      );
      break;
    }

    case QuestionType.SELECT: {
      if (isSelectMultiple(questionDefinition)) {
        const innerSchema = questionDefinition.allowOther
          ? yup.string().required()
          : yup.string();

        const selectSchema = yup.array().of(innerSchema).nullable();
        const min =
          questionDefinition.limits?.min !== undefined
            ? questionDefinition.limits?.min
            : required
              ? 1
              : 0;
        const max = questionDefinition.limits?.max;

        const minMessage = getLimitsErrorMessage(
          min > 1
            ? `Please, select ${
                max === undefined || max > min ? "at least " : ""
              }${min} ${min > 1 ? "options" : "option"}`
            : "Please complete this question",
          questionDefinition.limits,
        );

        const schemaWithLowerBounds = selectSchema.min(min, minMessage);

        schema =
          max !== undefined
            ? schemaWithLowerBounds.max(
                max,
                `Please, select up to ${max} ${max > 1 ? "options" : "option"}`,
              )
            : schemaWithLowerBounds;
      } else {
        schema = yup.string().nullable().min(1, "Please, specify a value");
      }
      break;
    }

    case QuestionType.RELATIONSHIP: {
      const partyIDs = (questionDefinition.assignableParties || []).map(
        getGenericPartyId,
      );

      const idsToImport: string[] = (
        questionDefinition.partiesToImport
          ? Object.values(questionDefinition.partiesToImport).flat()
          : ([] as Party[])
      ).map(({ partyId }) => partyId);

      const idsWithoutPhone: string[] = questionDefinition.requirePhone
        ? (questionDefinition.assignableParties || [])
            .filter((p) => !p.contact?.cellPhone)
            .map(getGenericPartyId)
        : [];

      const idsWithoutEmail: string[] = questionDefinition.requireEmail
        ? (questionDefinition.assignableParties || [])
            .filter((p) => !p.contact?.email)
            .map(getGenericPartyId)
        : [];

      const allIDs = [...partyIDs, ...idsToImport];
      const min =
        questionDefinition.limits?.min !== undefined
          ? questionDefinition.limits?.min
          : required
            ? 1
            : 0;
      const max = questionDefinition.limits?.max;

      const partialSchema = yup
        .array()
        .of(yup.mixed().oneOf(allIDs, "Invalid person selected"))
        .of(
          yup
            .mixed()
            .notOneOf(
              idsToImport,
              "Some of the selected people are missing mandatory details",
            ),
        )
        .of(
          yup
            .mixed()
            .notOneOf(
              [...idsWithoutPhone, ...idsWithoutEmail],
              "Some of the selected parties are missing mandatory contact information",
            ),
        )
        .min(
          min,
          `Please, select ${
            max === undefined || max > min ? "at least " : ""
          }${min} ${min > 1 ? "people" : "person"}`,
        );

      schema =
        max !== undefined
          ? partialSchema.max(
              max,
              `Please, select up to ${max} ${max > 1 ? "people" : "person"}`,
            )
          : partialSchema;
      break;
    }

    case QuestionType.IMAGE: {
      const partialSchema = yup.string().typeError("Please, select an image");
      schema = required ? partialSchema : partialSchema.nullable();
      break;
    }

    case QuestionType.ASSET_SPLIT: {
      schema = yup.object().shape({
        mainOwned: yup.number().required(),
        jointlyOwned: yup.number(),
        spouseOwned: yup.number(),
      });
      break;
    }

    case QuestionType.LIFE_INSURANCE_POLICY_VALUE: {
      schema = yup.object().shape({
        faceValue: applyLimitsToSchema(
          yup.number(),
          questionDefinition.faceValueLimits,
          (val: number) => currencyFormatter.format(val),
        ),
        cashValue: applyLimitsToSchema(
          yup.number(),
          questionDefinition.cashValueLimits,
          (val: number) => currencyFormatter.format(val),
        ),
        deathBenefit: applyLimitsToSchema(
          yup.number(),
          questionDefinition.deathBenefitLimits,
          (val: number) => currencyFormatter.format(val),
        ),
      });
      break;
    }

    case QuestionType.MULTIPLE: {
      let arraySchema = yup.array(
        getFieldValidationSchema(questionDefinition.baseQuestionDefinition),
      );

      if (questionDefinition.limits.min !== undefined) {
        arraySchema = arraySchema.min(
          questionDefinition.limits.min,
          `The minimum number of items is ${questionDefinition.limits.min}}`,
        );
      }

      if (questionDefinition.limits.max !== undefined) {
        arraySchema = arraySchema.max(
          questionDefinition.limits.max,
          `The maximum number of items is ${questionDefinition.limits.min}`,
        );
      }

      schema = yup.object().shape({
        answers: arraySchema,
      });
      break;
    }

    case QuestionType.OR_OPTION: {
      schema = yup.object().shape({
        question: getFieldValidationSchema(
          questionDefinition.questionDefinition,
        ),
        isOptionSelected: yup.boolean(),
      });

      break;
    }

    case QuestionType.ALERT: {
      break;
    }

    // case QuestionType.GENDER:
    // case QuestionType.LONG_TEXT:
    // case QuestionType.SELECT:
    // case QuestionType.STATE:
    default:
      schema = yup.string();
      break;
  }

  return schema && isRequired(questionDefinition, required)
    ? schema.required()
    : schema;
};

export const getValidationSchema = (questions: Question[]) =>
  yup.object().shape(
    questions.reduce(
      (schema, question) => {
        const { definition, required } = question.data;
        schema[question.id] = getFieldValidationSchema(definition, required);
        return schema;
      },
      {} as Record<QuestionID, any>,
    ),
  );

export const getFieldInitialValue = (
  questionId: string,
  questionType: QuestionType,
  answers?: AnswersMap,
  defaultValue?: any,
  multiple?: boolean,
) => {
  if (
    answers &&
    answers[questionId] !== undefined &&
    answers[questionId] !== null
  )
    return answers[questionId];

  if (defaultValue !== undefined) return defaultValue;

  switch (questionType) {
    case QuestionType.ADDON:
    case QuestionType.FLAG:
      return null;

    case QuestionType.CONFIRM:
      return false;

    case QuestionType.NUMERIC:
    case QuestionType.PERCENTAGE:
    case QuestionType.TEXT:
    case QuestionType.CURRENCY:
    case QuestionType.DATE:
    case QuestionType.GENDER:
      return "";

    case QuestionType.ADDRESS:
      return {
        line1: "",
        line2: "",
        city: "",
        state: "",
        adminAreaName: "",
        zip: "",
      };

    case QuestionType.RELATIONSHIP:
      return [];

    case QuestionType.SELECT:
      return multiple ? [] : "";
  }
};

export const getInitialValues = (
  questions: Question[],
  answers: AnswersMap = {},
) =>
  questions.reduce((acc, question) => {
    const { definition, defaultValue } = question.data;
    acc[question.id] = getFieldInitialValue(
      question.id,
      definition.type,
      answers,
      defaultValue,
      definition.type === QuestionType.SELECT
        ? isSelectMultiple(definition)
        : undefined,
    );
    return acc;
  }, {} as AnswersMap);

export const getInitialValuesByQuestionnaire = (
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
  answersByQuestionnaire: AnswersByQuestionnaireType,
): AnswersByQuestionnaireType =>
  mapValues(
    questionsByQuestionnaire,
    (questionnaireQuestions, questionnaireId) =>
      getInitialValues(
        Object.values(questionnaireQuestions),
        answersByQuestionnaire[questionnaireId],
      ),
  );

const parseFraction = (
  fractionString: string | number | undefined,
): [number, number] | null => {
  const parts = fractionString
    ? String(fractionString)
        .split("/")
        .map((n) => (isNaN(Number(n)) ? 0 : Number(n)))
    : [];
  return !fractionString ||
    parts[0] === 0 ||
    parts[1] === 0 ||
    parts.length !== 2
    ? null
    : (parts as [number, number]);
};

export const sanitizeValue = (
  questionType: QuestionType,
  value: AnswerValueType,
  clients?: ClientsMap,
  innerQuestionType?: QuestionType,
): AnswerValueType => {
  switch (questionType) {
    case QuestionType.SHARE_DISTRIBUTION:
      const shares = value as Share[] | null;
      if (!shares) return null;

      const isFractions = shares.find((share) =>
        String(share.share).includes("/"),
      );
      const sharesMap = mapValues(
        keyBy(value as Share[], "partyId"),
        (value) => (isFractions ? parseFraction(value.share) : value.share),
      );

      return {
        type: isFractions
          ? PerPartyAnswerType.FRACTIONS
          : PerPartyAnswerType.PERCENTAGE,
        values: sharesMap,
      };

    case QuestionType.PER_PARTY:
      const values = value as PartyValue[] | null;
      if (!values) return null;

      const valuesMap = mapValues(
        keyBy(value as PartyValue[], "partyId"),
        (value) => value.value,
      );

      return {
        type: PerPartyAnswerType.VALUES,
        values: valuesMap,
      };

    case QuestionType.RELATIONSHIP: {
      return !Array.isArray(value) || value.length === 0
        ? null
        : ({
            type: PerPartyAnswerType.PARTIES,
            values: (value as string[]).map((id) => ({
              id,
              type: Object.values(clients || {}).find(
                (client) => client?.clientId === id,
              )
                ? PartyOrClient.CLIENT
                : PartyOrClient.PARTY,
            })),
          } as PerPartyRelationshipAnswer);
    }

    case QuestionType.IMAGE: {
      return isImageData(value) ? value.downloadUrl || value.base64 : value;
    }

    case QuestionType.ADDRESS: {
      return value
        ? ({
            type: "Address",
            address: { id: "address", ...(value as object) },
          } as StoredAddress)
        : value;
    }

    case QuestionType.ASSET_SPLIT: {
      return {
        type: ComplexAnswerType.ASSET_SPLIT,
        ...(value as object),
      };
    }

    case QuestionType.LIFE_INSURANCE_POLICY_VALUE: {
      return {
        type: ComplexAnswerType.LIFE_INSURANCE_POLICY_VALUE,
        faceValue: 0,
        cashValue: 0,
        deathBenefit: 0,
        ...(value as object),
      };
    }

    case QuestionType.MULTIPLE: {
      return innerQuestionType !== undefined
        ? {
            type: ComplexAnswerType.MULTIPLE,
            answers:
              (value as Multiple)?.answers.map((answerValueType) =>
                sanitizeValue(innerQuestionType, answerValueType),
              ) || [],
          }
        : value;
    }

    case QuestionType.OR_OPTION: {
      return innerQuestionType !== undefined
        ? {
            type: ComplexAnswerType.OR_OPTION,
            question: sanitizeValue(
              innerQuestionType,
              (value as OrOption)?.question,
            ),
            isOptionSelected: (value as OrOption)?.isOptionSelected || false,
          }
        : value;
    }

    default:
      return value;
  }
};

export const sanitizeValues = (
  questions: QuestionsMap,
  answers: AnswersMap = {},
  clients?: ClientsMap,
): AnswersMap =>
  mapValues(answers, (value, key) => {
    const question = questions[key];
    switch (question.data.definition.type) {
      case QuestionType.MULTIPLE: {
        return sanitizeValue(
          question?.data.definition.type,
          value,
          clients,
          question.data.definition.baseQuestionDefinition.type,
        );
      }
      case QuestionType.OR_OPTION: {
        return sanitizeValue(
          question?.data.definition.type,
          value,
          clients,
          question.data.definition.questionDefinition.type,
        );
      }
      default: {
        return sanitizeValue(question?.data.definition.type, value, clients);
      }
    }
  });

export const parseStoredValue = (
  questionType: QuestionType,
  answerValue: StoredAnswerValueType,
): AnswerValueType => {
  switch (questionType) {
    case QuestionType.SHARE_DISTRIBUTION: {
      const savedShares = answerValue as PerPartySharesAnswer | null;
      return savedShares?.values
        ? Object.entries(savedShares.values).map(([partyId, share]) => ({
            partyId,
            share:
              savedShares.type === PerPartyAnswerType.FRACTIONS
                ? share.join("/")
                : share,
          }))
        : null;
    }

    case QuestionType.PER_PARTY: {
      const savedValues = answerValue as PerPartyValuePairsAnswer | null;
      return savedValues?.values
        ? Object.entries(savedValues.values).map(([partyId, value]) => ({
            partyId,
            value,
          }))
        : null;
    }

    case QuestionType.RELATIONSHIP: {
      // Backwards compatibility
      if (Array.isArray(answerValue)) return answerValue;

      const savedValue = answerValue as PerPartyRelationshipAnswer | null;
      return savedValue?.values.map(({ id }) => id);
    }

    case QuestionType.ADDRESS: {
      return (answerValue as StoredAddress | null)?.address;
    }

    default:
      return answerValue;
  }
};

export const parseStoredAnswers = (
  questions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
): AnswerValueType =>
  mapValues(answers, (answersMap, questionnaireId) =>
    mapValues(answersMap, (value, questionId) => {
      const question: Question =
        questions[questionnaireId] && questions[questionnaireId][questionId];
      return parseStoredValue(question?.data.definition.type, value);
    }),
  );
