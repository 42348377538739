import React from "react";
import { MedicaidClientDetailsProps } from "./types";
import getAge from "modules/parties/helpers/getAge";
import { getFullAddress } from "modules/parties/types/Address";
import { getFullName } from "modules/parties/types/Party";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import getPartyPronouns from "modules/parties/helpers/getPartyPronouns";

const cx = classNames.bind(styles);

const MedicaidClientDetails = ({ client }: MedicaidClientDetailsProps) => (
  <ol className={cx("medicaid-client-details")}>
    <li>
      <span className={cx("medicaid-client-details__attribute-heading")}>
        Name:
      </span>
      <br />
      <span className={cx("medicaid-client-details__attribute-value")}>
        {getFullName(client)}
      </span>
    </li>
    <li>
      <span className={cx("medicaid-client-details__attribute-heading")}>
        Pronouns:
      </span>
      <br />
      <span className={cx("medicaid-client-details__attribute-value")}>
        {getPartyPronouns(client.gender)}
      </span>
    </li>
    <li>
      <span className={cx("medicaid-client-details__attribute-heading")}>
        Age:
      </span>
      <br />
      <span className={cx("medicaid-client-details__attribute-value")}>
        {getAge(client.dateOfBirth)}
      </span>
    </li>
    {client.contact?.cellPhone !== undefined &&
      client.contact.cellPhone !== "" && (
        <li>
          <span className={cx("medicaid-client-details__attribute-heading")}>
            Cell Phone:
          </span>
          <br />
          <a
            className={cx("medicaid-client-details__attribute-value")}
            href={`tel:${client.contact.cellPhone}`}
          >
            {client.contact.cellPhone}
          </a>
        </li>
      )}
    {client.contact?.homePhone !== undefined &&
      client.contact.homePhone !== "" && (
        <li>
          <span className={cx("medicaid-client-details__attribute-heading")}>
            Home Phone:
          </span>
          <br />
          <a
            className={cx("medicaid-client-details__attribute-value")}
            href={`tel:${client.contact.homePhone}`}
          >
            {client.contact.homePhone}
          </a>
        </li>
      )}
    {client.contact?.workPhone !== undefined &&
      client.contact.workPhone !== "" && (
        <li>
          <span className={cx("medicaid-client-details__attribute-heading")}>
            Work Phone:
          </span>
          <br />
          <a
            className={cx("medicaid-client-details__attribute-value")}
            href={`tel:${client.contact.workPhone}`}
          >
            {client.contact.workPhone}
          </a>
        </li>
      )}
    {client.contact?.email !== undefined && client.contact.email !== "" && (
      <li>
        <span className={cx("medicaid-client-details__attribute-heading")}>
          Email:
        </span>
        <br />
        <a
          className={cx("medicaid-client-details__attribute-value")}
          href={`mailto:${client.contact.email}`}
        >
          {client.contact.email}
        </a>
      </li>
    )}
    {client.address !== undefined && getFullAddress(client.address) !== "" && (
      <li>
        <span className={cx("medicaid-client-details__attribute-heading")}>
          Address:
        </span>
        <br />
        <span className={cx("medicaid-client-details__attribute-value")}>
          {getFullAddress(client.address)}
        </span>
      </li>
    )}
  </ol>
);

export default MedicaidClientDetails;
