import { API, get, post } from "modules/api";
import LoadRenderedDocumentsResponseType, {
  DocumentsRenderResponsePayload,
  DownloadableDocumentDetails,
} from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import { WorkflowDocumentType } from "modules/api/types/WorkflowDetailsPayload";

export const callRenderDocumentsAPI = async (
  workflowId: string,
  documentType?: WorkflowDocumentType,
  asUser?: string,
): Promise<DocumentsRenderResponsePayload> => {
  const response: LoadRenderedDocumentsResponseType = await post(
    documentType
      ? API.RENDER_DOCUMENTS_OF_TYPE({ workflowId, documentType })
      : API.RENDER_DOCUMENTS({ workflowId }),
    {},
    { asUser },
  );
  return response.data;
};

export const callFetchRenderedDocumentsAPI = async (
  workflowId: string,
  documentType?: WorkflowDocumentType,
  asUser?: string,
): Promise<DownloadableDocumentDetails[]> => {
  const endpoint = documentType
    ? API.WORKFLOW_DOCUMENTS_WITH_TYPE({ workflowId, documentType })
    : API.WORKFLOW_DOCUMENTS({ workflowId });
  const response: any = await get(endpoint, { asUser });
  return response.data?.documents || [];
};
