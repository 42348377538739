import MenuLink from "components/Header/MenuLink";

type BuildHeaderLinksParams = {
  globalAdmin: boolean;
  globalAdminLinks: MenuLink[];
  draftingAdmin: boolean;
  draftingAdminLinks: MenuLink[];
  admin: boolean;
  adminLinks: MenuLink[];
  loggedIn: boolean;
  userLinks: MenuLink[];
  standardLinks: MenuLink[];
};

export const buildHeaderLinks = ({
  globalAdmin,
  globalAdminLinks,
  draftingAdmin,
  draftingAdminLinks,
  admin,
  adminLinks,
  loggedIn,
  userLinks,
  standardLinks,
}: BuildHeaderLinksParams): MenuLink[] => {
  if (globalAdmin) {
    return [...globalAdminLinks];
  }

  if (draftingAdmin) {
    return [...adminLinks, ...draftingAdminLinks];
  }

  if (admin) {
    return [...adminLinks];
  }

  if (loggedIn) {
    return [...userLinks, ...standardLinks];
  }

  return standardLinks;
};
