import * as React from "react";
import {
  Question,
  QuestionnairePage,
} from "modules/workflow/types/Questionnaire";
import Party, { ClientParties, getFullName } from "../types/Party";
import {
  partyToFormValues,
  relatedPartyFormSections,
  relatedPartyQuestions,
  RELATED_PARTY_QUESTIONNAIRE_ID,
} from "../components/PartyDetailsModal/relatedPartyQuestionnaireDescription";
import { getQuestionnaireDefinition } from "modules/workflow/helpers/questionnaireSchemaParser";
import { flattenDeep, keyBy } from "lodash";
import SummarySection from "modules/workflow/components/QuestionnaireSummary/SummarySection";
import {
  childFormSections,
  childQuestions,
  childToFormValues,
  CHILD_QUESTIONNAIRE_ID,
  grandchildQuestions,
  grandchildToFormValues,
} from "../components/PartyDetailsModal/childQuestionnaireDescription";
import { getPageQuestionsIDs } from "modules/workflow/helpers/getPageQuestions";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";

const renderRelatedParties = (
  clients: ClientsMap | undefined,
  relatedParties: Party[],
  onEditParty?: (party: Party) => any,
) =>
  clients?.main &&
  relatedParties.map((party) => {
    const QUESTIONNAIRE_ID = RELATED_PARTY_QUESTIONNAIRE_ID;
    const relatedPartyAnswers = partyToFormValues(clients.main!, party);
    const { pages, questions } = getQuestionnaireDefinition(
      [
        {
          id: party.partyId,
          questionnaireId: QUESTIONNAIRE_ID,
          sections: relatedPartyFormSections,
        },
      ],
      { [QUESTIONNAIRE_ID]: keyBy(relatedPartyQuestions, "id") },
      { [QUESTIONNAIRE_ID]: relatedPartyAnswers },
      clients, // Clients conditions
      undefined, // Workflow conditions
      undefined, // Addons conditions
      undefined, // Children conditions
    );
    const filteredQuestionIDs: string[] = flattenDeep(
      pages.map(getPageQuestionsIDs),
    ).filter(Boolean);

    const filteredQuestions: Question[] = filteredQuestionIDs
      .map(
        (id) => questions[QUESTIONNAIRE_ID] && questions[QUESTIONNAIRE_ID][id],
      )
      .filter(Boolean);

    return (
      <SummarySection
        key={`party_${party.partyId}`}
        title={getFullName(party)}
        maskTitle={true}
        onEdit={onEditParty && onEditParty.bind(null, party)}
        sectionQuestions={filteredQuestions}
        answers={relatedPartyAnswers}
        allParties={relatedParties}
        clients={clients}
      />
    );
  });

const renderChildren = (
  clientsMap: ClientsMap | undefined,
  children: Party[],
  onEditChild?: (child: Party) => any,
) =>
  children.map((child) => {
    const childAnswers = childToFormValues(clientsMap?.main, child);
    const { pages, questions } = getQuestionnaireDefinition(
      [
        {
          id: child.partyId,
          questionnaireId: CHILD_QUESTIONNAIRE_ID,
          sections: childFormSections,
        },
      ],
      { [CHILD_QUESTIONNAIRE_ID]: keyBy(childQuestions, "id") },
      { [CHILD_QUESTIONNAIRE_ID]: childAnswers },
      clientsMap, // Clients conditions
      undefined, // Workflow conditions
      undefined, // Addons conditions
      undefined, // Children conditions
    );
    const filteredQuestionIDs: string[] = flattenDeep(
      pages.map(getPageQuestionsIDs),
    ).filter(Boolean);

    const filteredQuestions: Question[] = filteredQuestionIDs
      .map(
        (id) =>
          questions[CHILD_QUESTIONNAIRE_ID] &&
          questions[CHILD_QUESTIONNAIRE_ID][id],
      )
      .filter(Boolean);

    return (
      <SummarySection
        key={`child_${child.partyId}`}
        title={getFullName(child)}
        maskTitle={true}
        sectionQuestions={filteredQuestions}
        answers={childAnswers}
        allParties={children}
        clients={clientsMap}
        onEdit={onEditChild && onEditChild.bind(null, child)}
      >
        {(child.children || []).map((grandchild) => {
          const grandchildAnswers = grandchildToFormValues(
            undefined,
            grandchild,
          );
          return (
            <SummarySection
              key={`grandchild_${grandchild.partyId}`}
              title={getFullName(grandchild)}
              maskTitle={true}
              sectionQuestions={grandchildQuestions}
              answers={grandchildAnswers}
              allParties={children}
              clients={clientsMap}
              onEdit={onEditChild && onEditChild.bind(null, grandchild)}
            />
          );
        })}
      </SummarySection>
    );
  });

export default (
  relatedParties: ClientParties,
  handleEditChild: (child: Party) => any,
  handleEditParty: (party: Party) => any,
  clients: ClientsMap | undefined,
  isEditable?: boolean,
) => {
  const peopleSummaryPage: QuestionnairePage = {
    id: "related_parties_summary",
    questionnaireId: "",
    caption: "People & Roles",
    description: "",
    isStatic: true,
    sections: [],
    onEditSummarySection: null,
    renderSummary: () => {
      return (
        <>
          {renderChildren(
            clients,
            relatedParties.children,
            isEditable ? handleEditChild : undefined,
          )}
          {renderRelatedParties(
            clients,
            relatedParties.parties,
            isEditable ? handleEditParty : undefined,
          )}
        </>
      );
    },
  };

  return peopleSummaryPage;
};
