import { ValueConditionConstraint, ValueConstraintType } from ".";

export enum DiffType {
  LOWER = "Lt",
  LOWER_OR_EQUAL = "Lte",
  EQUAL = "Eq",
  GREATER_OR_EQUAL = "Gte",
  GREATER = "Gt",
}

export enum DateDiffUnit {
  YEARS = "years",
  MONTHS = "months",
  WEEKS = "weeks",
  DAYS = "days",
  // HOURS = "hours",
  // MINUTES = "minutes",
  // SECONDS = "seconds",
}

type DatediffConstraint = {
  type: ValueConstraintType.DateDiff;
  value: number;
  op: DiffType;
  unit: DateDiffUnit;
};

export default DatediffConstraint;

export const isDateDiffConstraint = (
  valueConstraint: ValueConditionConstraint,
): valueConstraint is DatediffConstraint =>
  (valueConstraint as any).type === ValueConstraintType.DateDiff;
