import notification, { NotificationType } from "components/Notification";
import * as Sentry from "@sentry/react";
import ErrorType from "modules/api/ErrorType";
import ValidationError from "modules/workflow/types/ValidationError";
import ApiError from "modules/api/types/ApiError";

const { REACT_APP_VERBOSE_LOGGING } = process.env;

export const TYPE = NotificationType;

export const isVerbose = REACT_APP_VERBOSE_LOGGING === "on";

const getErrorType = (response: any): ErrorType | undefined =>
  response?.data?.short;

const captureSentryException = (
  apiError: ApiError | undefined,
  messages: string[],
) => {
  if (apiError?.response.status === 403) return;
  if (getErrorType(apiError?.response) === ErrorType.ZIP_NOT_FOUND) return;

  Sentry.captureException(messages);
};

const shouldReport = (message: any) =>
  !(message instanceof ValidationError) &&
  message instanceof Error &&
  (message as any).response?.code !== 504;

export default {
  verbose: (...messages: any) => {
    isVerbose && console.log(...messages); // eslint-disable-line no-console
  },
  debug: (...messages: any) => console.log(...messages), // eslint-disable-line no-console
  warn: (...messages: any) => console.warn(...messages), // eslint-disable-line no-console
  error: (...messages: any) => console.error(...messages), // eslint-disable-line no-console

  report: (...messages: any) => {
    console.error(...messages); // eslint-disable-line no-console
    Sentry.captureException([...messages]);
  },

  notify: (
    type: NotificationType,
    message?: string | ApiError,
    ...messages: any
  ) => {
    const apiError: ApiError | undefined = [message, ...messages].find(
      (m: any) => m instanceof ApiError,
    );
    const otherReportableError = messages.find(shouldReport);
    const notificationMessage =
      apiError?.message || (typeof message === "string" ? message : null);

    notificationMessage && notification[type](notificationMessage);

    switch (type) {
      case NotificationType.ERROR:
        console.error(message, ...messages); // eslint-disable-line no-console
        (apiError || otherReportableError) &&
          captureSentryException(apiError, [message, ...messages]);
        break;
      case NotificationType.WARNING:
        console.warn(message, ...messages); // eslint-disable-line no-console
        break;
      case NotificationType.INFO:
      case NotificationType.SUCCESS:
        console.info(message, ...messages); // eslint-disable-line no-console
        break;
      default:
        console.log(message, ...messages); // eslint-disable-line no-console
    }
  },
};
