import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";

import Navigator from "./Navigator";
import * as serviceWorker from "./serviceWorker";
import { Environment, getEnvironment } from "./utils/environment";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import store, { history } from "redux/store";
import PlatformContext, {
  isAndroid,
  isIOS,
  isMacOs,
  isMobile,
  isMobileOnly,
  isWindows,
  osVersion,
} from "contexts/PlatformContext";
import logger from "logger";
import type { RootState } from "redux/reducer";
import getWorkflowID from "modules/workflow/helpers/getWorkflowID";

import ReactGA from "react-ga4";
import { DataLayerContextProvider } from "contexts/DataLayerContext";
import isSentryEnabled from "utils/isSentryEnabled";

switch (getEnvironment()) {
  case Environment.LOCAL:
  case Environment.DEV: {
    ReactGA.initialize("G-DD4GWBN8H1");
    break;
  }
  case Environment.STAGING: {
    ReactGA.initialize("G-VE4H6PTDLJ");
    break;
  }
  case Environment.PROD: {
    ReactGA.initialize("G-ZWLP1VEH5D");
    break;
  }
}

let html = document.getElementsByTagName("html")[0];
isMobile
  ? html.classList.add("platform-mobile")
  : html.classList.add("platform-desktop");
isMobileOnly && html.classList.add("platform-mobile-only");
isAndroid && html.classList.add("platform-android");
isIOS && html.classList.add("platform-ios");
isMacOs && html.classList.add("platform-mac");
isWindows && html.classList.add("platform-windows");

const getClickTargetLabel = (target: any | undefined): string | undefined => {
  if (!target) return undefined;

  const isButton = target.closest("BUTTON") || target.closest("[role=button]");
  if (isButton)
    return `${
      target.tagName.includes("svg") ||
      target.parentNode?.tagName.includes("svg")
        ? "Icon"
        : "Button"
    }: ${
      target.ariaLabel || target.parentNode?.ariaLabel || target.textContent
    }`;

  if (target.tagName === "INPUT")
    return `Input: ${target.getAttribute("name")}`;

  if (target.getAttribute("role") === "option")
    return `Option: ${target.textContent}`;
};

// Init Sentry
if (isSentryEnabled() && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getEnvironment().toString(),
    release: process.env.REACT_APP_BUILD_SHA || "unreleased",
    normalizeDepth: 5,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Capture label of the clicked element
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        const { target } = hint?.event || {};
        const label = getClickTargetLabel(target);
        const questionId = target
          .closest("[data-questionid]")
          ?.getAttribute("data-questionid");

        if (label) breadcrumb.message = target.ariaLabel || target.textContent;
        if (questionId)
          breadcrumb.data = { ...(breadcrumb.data || {}), questionId };
      }
      return breadcrumb;
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.message === "Feedback") {
        event.message = `Feedback (user: ${
          event.user?.email
        }, at: ${new Date().toISOString()})`;
        const { questionnairePage, workflows, state }: RootState =
          store.getState();
        const currentWorkflowId = getWorkflowID();
        const currentWorkflow = currentWorkflowId
          ? workflows[currentWorkflowId]
          : undefined;

        event.tags = {
          ...(event.tags || {}),
          questionnairePage: questionnairePage || "-",
          workflowPlan: currentWorkflow?.state.fromPlan,
          state,
          workflowProducts: (currentWorkflow?.state.products || [])
            .map((p) => p.productId)
            .join(", "),
          fullUrl: window.location.href,
        };
        Sentry.showReportDialog({
          title: "Tell us what you think",
          ...(event.user?.username && event.user?.username !== "[undefined]"
            ? {
                user: {
                  email: event.user?.email,
                  name: event.user?.username,
                },
              }
            : {}),
          subtitle:
            "Do you like or dislike any part of the application? Have you encountered a problem?",
          subtitle2: "We'll be happy to hear about it!",
          labelComments: "Your comments",
          labelSubmit: "Submit",
        });
      }
      return event;
    },
  });
  logger.debug("Sentry initialized");
} else {
  logger.warn("Sentry monitoring OFF");
}

ReactDOM.render(
  <Provider store={store}>
    <PlatformContext.Provider
      value={{
        isAndroid,
        isIOS,
        isMacOs,
        isMobile,
        isMobileOnly,
        isWindows,
        osVersion,
      }}
    >
      <DataLayerContextProvider>
        <Navigator />
      </DataLayerContextProvider>
    </PlatformContext.Provider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
