import * as React from "react";
import FocusLock from "react-focus-lock";
import classNames from "classnames/bind";
import Addon from "modules/product/types/Addon";
import {
  SectionProgress,
  SectionState,
} from "modules/workflow/helpers/getSectionsProgress";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import { IconType } from "components/Icons";
import { ReactComponent as MinusSVG } from "components/Icons/svg/minus.svg";
import { ReactComponent as PlusSVG } from "components/Icons/svg/plus.svg";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import {
  Question,
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import { FormikProps } from "formik";
import CoupledWorkflowPrompt from "modules/workflow/components/InterviewQuestionnaire/CoupledWorkflowPrompt";
import { FormSubmitHandle } from "../Form";
import {
  useWindowDimensions,
  mobileWidthBreakpoint,
} from "utils/windowDimensions";
import ProgressArchiveWarning from "./ProgressArchiveWarning";

const cx = classNames.bind(styles);

type ProgressSectionProps = {
  addons?: Addon[];
  isActive: boolean;
  isValid: boolean;
  label: string;
  onClick?: () => any;
};

const ProgressSection: React.FunctionComponent<ProgressSectionProps> = ({
  isActive,
  isValid,
  label,
  onClick,
}) => {
  let className = cx("progress__track-stage", {
    "progress__track-stage--current": isActive,
    "progress__track-stage--valid": isValid,
  });
  return (
    <li className={className}>
      {isActive ? (
        <span>
          <span>{label}</span>
        </span>
      ) : onClick ? (
        <TabbedButton
          className={styles["progress__track-link"]}
          label={label}
          onClick={onClick}
          tabIndex={0}
        />
      ) : (
        <React.Fragment>{label}</React.Fragment>
      )}
    </li>
  );
};

type ProgressProps = {
  addons?: Addon[];
  answeredQuestionsCount: number;
  answers?: AnswersByQuestionnaireType;
  clients?: ClientsMap | undefined;
  coupledWorkflowAnswers?: AnswersByQuestionnaireType;
  currentPageIdx?: number;
  currentPageQuestions?: Question[];
  formHandle?: FormikProps<AnswersMap>;
  isCoupledWorkflow?: Boolean;
  isSecondaryWorkflow?: boolean;
  onSectionSelected?: (
    sectionIdx: number,
    sectionId?: string,
    sectionName?: string,
  ) => any;
  questionnairePage?: QuestionnairePage;
  questionsBySubquestionnaire?: QuestionsMapByQuestionnaire;
  sections: SectionProgress[];
  submitHandle?: FormSubmitHandle;
  totalQuestionsCount: number;
  handleSwitchToSpouse?: (
    submitHandle?: FormSubmitHandle | undefined,
  ) => Promise<void> | undefined;
  created?: string;
};

const Progress: React.FunctionComponent<ProgressProps> = ({
  addons,
  answeredQuestionsCount,
  answers,
  children,
  clients,
  coupledWorkflowAnswers,
  currentPageIdx,
  currentPageQuestions,
  formHandle,
  isCoupledWorkflow,
  isSecondaryWorkflow,
  questionnairePage,
  questionsBySubquestionnaire,
  onSectionSelected,
  sections,
  submitHandle,
  totalQuestionsCount,
  handleSwitchToSpouse,
  created,
}) => {
  const [open, setOpen] = React.useState(false);

  const windowDimensions = useWindowDimensions();

  const isMobile = windowDimensions?.width < mobileWidthBreakpoint;

  let className = cx("progress", {
    "progress--open": open,
  });
  const nextPageIdx = (currentPageIdx || 0) + 1;
  const remainingQuestionsCount = totalQuestionsCount - answeredQuestionsCount;
  const percentageComplete = `${
    totalQuestionsCount > 0
      ? Math.floor((answeredQuestionsCount / totalQuestionsCount) * 100)
      : 0
  }%`;
  const handleSectionClicked = (section: SectionProgress) => {
    const sectionIdx = sections.indexOf(section);
    onSectionSelected &&
      onSectionSelected(sectionIdx, section.pageId, section.label);
  };
  const handleProgressClicked = () => {
    setOpen(!open);
    document.documentElement.classList.toggle("scroll-lock");
    document.documentElement.classList.toggle("progress-open");
    detectScrollbar();
  };
  const handleCloseProgressClicked = () => {
    setOpen(false);
    document.documentElement.classList.remove("scroll-lock");
    document.documentElement.classList.remove("progress-open");
    detectScrollbar();
  };
  const detectScrollbar = () => {
    const vscrollbar =
      document.body.scrollHeight > document.body.clientHeight &&
      !document.documentElement.classList.contains("scroll-lock");
    vscrollbar
      ? document.documentElement.classList.add("has-scrollbar")
      : document.documentElement.classList.remove("has-scrollbar");
  };
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.matchMedia("(max-width: 767px)").matches;
        !ismobile && handleCloseProgressClicked();
      },
      false,
    );
  });
  React.useEffect(() => {
    detectScrollbar();
    window.addEventListener(
      "resize",
      () => {
        detectScrollbar();
      },
      false,
    );
  });
  return (
    <React.Fragment>
      <div className={className}>
        {totalQuestionsCount ? (
          <>
            <h3 className={styles["progress__title"]}>Your progress</h3>
            <ProgressArchiveWarning created={created} />
            {!isMobile && isCoupledWorkflow && (
              <CoupledWorkflowPrompt
                answers={answers}
                clients={clients}
                coupledWorkflowAnswers={coupledWorkflowAnswers}
                questionnairePage={questionnairePage}
                questionsBySubquestionnaire={questionsBySubquestionnaire}
                currentPageQuestions={currentPageQuestions}
                submitHandle={submitHandle}
                formHandle={formHandle}
                handleSwitchToSpouse={handleSwitchToSpouse}
              />
            )}
            <div className={styles["progress__bar-container"]}>
              <div className={styles["progress__bar"]}>
                <div
                  className={styles["progress__bar-current"]}
                  style={{ width: percentageComplete }}
                ></div>
              </div>
            </div>
            <div className={styles["progress__button-container"]}>
              <TabbedButton onClick={handleProgressClicked}>
                <div className={styles["progress__bar"]}>
                  <div
                    className={styles["progress__bar-current"]}
                    style={{ width: percentageComplete }}
                  >
                    <p className={styles["progress__text-percentage--white"]}>
                      {percentageComplete} Complete
                    </p>
                    <p className={styles["progress__text-button--white"]}>
                      {open ? (
                        <React.Fragment>
                          <span>Collapse</span>
                          <MinusSVG aria-label="Collapse" />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span>Expand</span>
                          <PlusSVG aria-label="Expand" />
                        </React.Fragment>
                      )}
                    </p>
                  </div>
                  <p className={styles["progress__text-percentage--black"]}>
                    {percentageComplete} Complete
                  </p>
                  <p className={styles["progress__text-button--black"]}>
                    {open ? (
                      <React.Fragment>
                        <span>Collapse</span>
                        <MinusSVG aria-label="Collapse" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span>Expand</span>
                        <PlusSVG aria-label="Expand" />
                      </React.Fragment>
                    )}
                  </p>
                </div>
              </TabbedButton>
            </div>
            {isMobile && isCoupledWorkflow && (
              <CoupledWorkflowPrompt
                answers={answers}
                clients={clients}
                coupledWorkflowAnswers={coupledWorkflowAnswers}
                questionnairePage={questionnairePage}
                questionsBySubquestionnaire={questionsBySubquestionnaire}
                currentPageQuestions={currentPageQuestions}
                submitHandle={submitHandle}
                formHandle={formHandle}
                handleSwitchToSpouse={handleSwitchToSpouse}
              />
            )}
            <p className={styles["progress__text-percentage"]}>
              {percentageComplete} Complete
            </p>
            <div className={styles["progress__text-container"]}>
              <p className={styles["progress__text-steps"]}>
                Step {currentPageIdx !== undefined && currentPageIdx + 1} of{" "}
                {sections.length}
              </p>
              <p className={styles["progress__text-remaining"]}>
                {remainingQuestionsCount === 0 && "All questions answered"}
                {remainingQuestionsCount > 0 && (
                  <React.Fragment>
                    {remainingQuestionsCount} Question
                    {remainingQuestionsCount === 1 ? "" : "s"} remaining
                  </React.Fragment>
                )}
              </p>
            </div>
          </>
        ) : (
          isCoupledWorkflow && (
            <CoupledWorkflowPrompt
              answers={answers}
              clients={clients}
              coupledWorkflowAnswers={coupledWorkflowAnswers}
              questionnairePage={questionnairePage}
              questionsBySubquestionnaire={questionsBySubquestionnaire}
              currentPageQuestions={currentPageQuestions}
              submitHandle={submitHandle}
              formHandle={formHandle}
              handleSwitchToSpouse={handleSwitchToSpouse}
            />
          )
        )}
        <div className={styles["progress__track-modal"]}>
          <FocusLock disabled={window.innerWidth > 767 && true}>
            <div className={styles["progress__track-modal-inner"]}>
              {sections.length > 1 ? (
                <div className={styles["progress__track-container"]}>
                  <ul data-tid="progress" className={styles["progress__track"]}>
                    {sections.map((section, idx) => (
                      <ProgressSection
                        key={`${section.label}_${idx}`}
                        isActive={currentPageIdx === idx}
                        isValid={section.state === SectionState.COMPLETE}
                        label={section.label}
                        onClick={
                          section.accessible || idx === nextPageIdx
                            ? () => {
                                handleSectionClicked(section);
                                handleCloseProgressClicked();
                              }
                            : undefined
                        }
                      />
                    ))}
                  </ul>
                </div>
              ) : null}
              <div>{children}</div>
            </div>
            <TabbedButton
              className={styles["progress__modal-button"]}
              label="Collapse"
              onClick={handleCloseProgressClicked}
              icon={IconType.MINUS}
            ></TabbedButton>
          </FocusLock>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Progress;
