import React from "react";
import DraftAdd from "./add";

type DraftingFormAddProps = {
  onStateChange: (state: string) => void;
};

const DraftingForm: React.FC<DraftingFormAddProps> = ({ onStateChange }) => {
  return (
    <>
      <DraftAdd onStateChange={onStateChange} />
    </>
  );
};

export default DraftingForm;
