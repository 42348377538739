import React from "react";
import * as yup from "yup";

import { User } from "../../auth/User";
import PageHeader from "components/PageHeader";
import Fieldset, { FieldsetRow } from "../../../components/Forms/Fieldset";
import Form from "../../../components/Forms/Form";
import QuestionInput from "components/Forms/Questions/QuestionInput";
import { InputType } from "components/Forms/Inputs/Input/types";
import {
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";

import styles from "modules/workflow/components/QuestionnaireForm/styles.module.scss";
import { getFullName } from "modules/parties/types/Party";
import { getFieldValidationSchema } from "modules/workflow/helpers/validation";
import { AnswersMap } from "modules/workflow/types/Answer";

type UserProfileFormProps = {
  onSaveUserDetails: (user: User) => any;
  user: User | null;
  selectedState: string | null;
};

const breakDownDisplayName = (
  displayName?: string,
): { firstName: string; middleName: string; lastName: string } => {
  if (!displayName) {
    return { firstName: "", middleName: "", lastName: "" };
  }

  const parts = displayName.split(" ");
  return {
    firstName: parts.shift() || "",
    lastName: parts.pop() || "",
    middleName: parts.join(" "),
  };
};

const UserProfileForm: React.FunctionComponent<UserProfileFormProps> = ({
  onSaveUserDetails,
  selectedState,
  user,
}) => {
  const handleFormSubmission = (values: AnswersMap) =>
    onSaveUserDetails({
      ...user,
      ...values,
      displayName: getFullName(values as User),
      state: selectedState,
    } as User);

  const schema = yup.object().shape({
    firstName: getFieldValidationSchema({ type: QuestionType.TEXT }, true),
    middleName: getFieldValidationSchema({ type: QuestionType.TEXT }, false),
    lastName: getFieldValidationSchema({ type: QuestionType.TEXT }, true),
    phoneNumber: getFieldValidationSchema(
      { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
      true,
    ),
    email: getFieldValidationSchema(
      { type: QuestionType.TEXT, subtype: TextFieldType.EMAIL },
      true,
    ),
  });

  const initialValues = {
    ...(user?.firstName ? {} : breakDownDisplayName(user?.displayName)),
    phoneNumber: "",
    email: "",
    ...user,
  } as User;

  return (
    <React.Fragment>
      <div className={styles.questionnaire}>
        <div className={styles["questionnaire__title"]}>
          <PageHeader title="My Profile" />
        </div>
        <div className={styles["questionnaire__content"]}>
          <Form
            state={initialValues}
            schema={schema}
            showSaveButton
            onSave={handleFormSubmission}
          >
            <Fieldset title="Contact information">
              <FieldsetRow>
                <QuestionInput
                  type={InputType.TEXT}
                  label="First name"
                  placeholder="First name"
                  name="firstName"
                  size={QuestionSizes.SMALL}
                />
                <QuestionInput
                  type={InputType.TEXT}
                  label="Middle name (optional)"
                  placeholder="Middle name"
                  name="middleName"
                  size={QuestionSizes.SMALL}
                />
                <QuestionInput
                  type={InputType.TEXT}
                  label="Last name"
                  placeholder="Last name"
                  name="lastName"
                  size={QuestionSizes.SMALL}
                />
              </FieldsetRow>
              <FieldsetRow>
                <QuestionInput
                  type={InputType.TEL}
                  label="Phone number"
                  name="phoneNumber"
                  placeholder="+1234567890"
                  size={QuestionSizes.MEDIUM}
                />
              </FieldsetRow>
              <FieldsetRow>
                <QuestionInput
                  type={InputType.EMAIL}
                  disabled
                  label="Email address"
                  placeholder="Please enter an email address"
                  name="email"
                  size={QuestionSizes.MEDIUM}
                />
              </FieldsetRow>
            </Fieldset>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserProfileForm;
