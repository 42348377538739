import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as LogoSVG } from "resources/logos/Aturna_Brandmark_RGB_White.svg";
import { ReactComponent as LegalLogoSVG } from "resources/logos/Aturna_Brandmark_RGB_White.svg";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useSelector } from "redux/reducer";

import Link from "components/Link";
import { useDispatch } from "react-redux";
import {
  navigateToGlobalLanding,
  navigateToPartnerLanding,
} from "modules/navigation/helpers/navigator";
const cx = classNames.bind(styles);

const root = document.getElementById("root");

const NotFound = () => {
  const dispatch = useDispatch();
  const el = useRef(document.createElement("div"));
  const partner = useSelector((state) => state.partner);

  const handleLogoClick = () => {
    partner
      ? navigateToPartnerLanding(dispatch, partner.id)
      : navigateToGlobalLanding(dispatch);
  };

  useEffect(() => {
    if (!root) return;
    const child = el.current;
    root.appendChild(child);

    return () => {
      root.removeChild(child);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles["not-found"]}>
      <div className={styles["not-found__container"]}>
        <h1 className={styles["not-found__logo"]}>
          <LogoSVG aria-label="Logo" />
        </h1>

        <div className={styles["not-found__grid"]}>
          <div
            className={cx("not-found__grid-item", "not-found__grid-item--text")}
          >
            <h2 className={styles["not-found__headline"]}>404</h2>

            <p>Sorry, that page cannot be found.</p>
            <p>Check to see if the link you're trying to open is correct.</p>
            <p>
              Or go back to{" "}
              <Link
                onClick={handleLogoClick}
                className={styles["not-found__text-link"]}
              >
                <LegalLogoSVG />
              </Link>
            </p>
          </div>
          <div
            className={cx(
              "not-found__grid-item",
              "not-found__grid-item--picture",
            )}
          ></div>
        </div>
      </div>
    </div>,
    el.current,
  );
};

export default NotFound;
