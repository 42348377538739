import moment from "moment";

import {
  HTMLInputMode,
  HTMLInputType,
  InputType,
  Mask,
  MaskProps,
} from "../types";
import {
  getInputPattern,
  handleInput,
  handleKeyDown,
  maskCharacter,
  RegularExpressions,
  Sanitisers,
  setNativeInput,
} from "../helpers";

export default (props: MaskProps): Mask => {
  return (
    (MASK[props.type] && MASK[props.type](props)) || {
      type: HTMLInputType.TEXT,
    }
  );
};

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [InputType.DATE]: (props: MaskProps): Mask => ({
    inputMask: {
      alias: "datetime",
      autoUnmask: true,
      groupSeparator: " / ",
      inputFormat: "mm / dd / yyyy",
      onBeforeMask: function (value: string | null) {
        if (moment(value, "YYYY-MM-DD", true).isValid()) {
          return moment(value, "YYYY-MM-DD", true).format("MM / DD / YYYY");
        }
        return value;
      },
      max: props.max ? moment(props.max).format("MM / DD / YYYY") : undefined,
      min: props.min ? moment(props.min).format("MM / DD / YYYY") : undefined,
      outputFormat: "yyyy-mm-dd",
      placeholder: "MM / DD / YYYY",
      showMaskOnHover: false,
    },
    type: HTMLInputType.TEXT,
  }),

  [InputType.EMAIL]: (props: MaskProps): Mask =>
    props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.EMAIL,
          onInput: handleInput((value: string): string => {
            return value.replace(Sanitisers.LEADING_WHITESPACE, "");
          }),
          type: HTMLInputType.EMAIL,
        }
      : {
          inputMask: {
            alias: "email",
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        },

  [InputType.MONTH]: (props: MaskProps): Mask =>
    props.platform.isMobile
      ? {
          type: HTMLInputType.MONTH,
          placeholder: "YYYY-MM",
        }
      : {
          inputMask: {
            alias: "datetime",
            autoUnmask: true,
            groupSeparator: " / ",
            inputFormat: "mm / yyyy",
            onBeforeMask: function (value: string | null) {
              if (moment(value, "YYYY-MM", true).isValid()) {
                return moment(value, "YYYY-MM", true).format("MM / YYYY");
              }
              return value;
            },
            max: props.max ? moment(props.max).format("MM / YYYY") : undefined,
            min: props.min ? moment(props.min).format("MM / YYYY") : undefined,
            outputFormat: "yyyy-mm",
            placeholder: "MM / YYYY",
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        },

  [InputType.PASSWORD]: (props: MaskProps): Mask => ({
    onInput: handleInput((value: string): string => {
      return value.replace(Sanitisers.LEADING_WHITESPACE, "");
    }),
    type: HTMLInputType.PASSWORD,
  }),

  [InputType.SOCIAL_SECURITY_NUMBER]: (props: MaskProps): Mask =>
    props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.NUMERIC,
          maxLength: 11,
          onInput: handleInput((value: string): string => {
            return maskCharacter(
              value.replace(Sanitisers.LEADING_WHITESPACE, ""),
              "-",
              [4, 7],
            );
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER_ALLOW_NEGATIVE),
          onMount: (input) => {
            const value = input.current?.value;
            const sanitised =
              value &&
              maskCharacter(
                value.replace(Sanitisers.LEADING_WHITESPACE, ""),
                "-",
                [4, 7],
              );
            sanitised &&
              sanitised !== value &&
              setNativeInput(input, sanitised);
          },
          pattern: getInputPattern(props.platform),
          placeholder: "###-##-####",
          type: HTMLInputType.TEXT,
        }
      : {
          inputMask: {
            groupSeparator: "-",
            mask: "999-99-9999",
            placeholder: "###-##-####",
            showMaskOnHover: false,
          },
          maxLength: 11,
          type: HTMLInputType.TEXT,
        },

  [InputType.TEL]: (props: MaskProps): Mask =>
    props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.NUMERIC,
          maxLength: 12,
          onInput: handleInput((value: string): string => {
            return maskCharacter(
              value.replace(Sanitisers.LEADING_WHITESPACE, ""),
              "-",
              [4, 8],
            );
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER_ALLOW_NEGATIVE),
          onMount: (input) => {
            const value = input.current?.value;
            const sanitised =
              value &&
              maskCharacter(
                value.replace(Sanitisers.LEADING_WHITESPACE, ""),
                "-",
                [4, 8],
              );
            sanitised &&
              sanitised !== value &&
              setNativeInput(input, sanitised);
          },
          pattern: getInputPattern(props.platform),
          placeholder: "###-###-####",
          type: HTMLInputType.TEXT,
        }
      : {
          inputMask: {
            groupSeparator: " ",
            mask: "999-999-9999",
            placeholder: "###-###-####",
            showMaskOnHover: false,
          },
          maxLength: 12,
          type: HTMLInputType.TEXT,
        },

  [InputType.TEXT]: (props: MaskProps): Mask => ({
    onInput: handleInput((value: string): string => {
      return value.replace(Sanitisers.LEADING_WHITESPACE, "");
    }),
    type: HTMLInputType.TEXT,
    maxLength:
      props.max === undefined
        ? undefined
        : typeof props.max === "string"
          ? Number.parseInt(props.max)
          : props.max,
  }),

  [InputType.CODE]: (props: MaskProps): Mask => ({
    onInput: handleInput((value: string): string => {
      return value
        .replace(Sanitisers.LEADING_WHITESPACE, "")
        .replace(/[^A-Z0-9]/gi, "")
        .toLocaleUpperCase();
    }),
    type: HTMLInputType.TEXT,
    maxLength:
      props.max === undefined
        ? undefined
        : typeof props.max === "string"
          ? Number.parseInt(props.max)
          : props.max,
  }),

  [InputType.ZIP_CODE]: (props: MaskProps): Mask =>
    props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.NUMERIC,
          maxLength: 10,
          onInput: handleInput((value: string): string => {
            return maskCharacter(
              value.replace(Sanitisers.LEADING_WHITESPACE, ""),
              "-",
              [6],
            );
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER_ALLOW_NEGATIVE),
          onMount: (input) => {
            const value = input.current?.value;
            const sanitised =
              value &&
              maskCharacter(
                value.replace(Sanitisers.LEADING_WHITESPACE, ""),
                "-",
                [6],
              );
            sanitised &&
              sanitised !== value &&
              setNativeInput(input, sanitised);
          },
          pattern: getInputPattern(props.platform),
          jitMasking: true,
          type: HTMLInputType.TEXT,
        }
      : {
          inputMask: {
            greedy: false,
            groupSeparator: "-",
            mask: "99999[-9999]",
            jitMasking: true,
            showMaskOnHover: false,
          },
          maxLength: 10,
          type: HTMLInputType.TEXT,
        },
};
