import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import PlanSelectorAddons from "modules/advisor/components/PlanSelector/PlanSelectorAddons";
import PlanSelectorSummary from "modules/advisor/components/PlanSelector/PlanSelectorSummary";
import PlanSelectorOption from "modules/advisor/components/PlanSelector/PlanSelectorOption";
import { useSelector } from "redux/reducer";
import { shallowEqual, useDispatch } from "react-redux";
import { ProductID } from "modules/product/types/Product";
import Addon, { AddonID } from "modules/product/types/Addon";
import { PlanID } from "modules/product/types/Plan";
import parseParams from "utils/parseUrlParams";
import { useLocation } from "react-router-dom";
import { keyBy } from "lodash";
import isPlan from "modules/product/helpers/isPlan";
import {
  createNewWorkflowWithPlan,
  createNewWorkflowWithProduct,
} from "modules/workflow/actions";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import DefinitionWithProductsPlansAndAddons, {
  isPlanDefinition,
  PlanDefinition,
  ProductDefinition,
} from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import BackLink from "components/BackLink";
import persistentStore from "modules/localStore";
import { ADVISOR_ANSWERS_KEY, AdvisorPlan } from "modules/advisor/actions";

const PLANS: PlanID[] = [
  "will-poa-hcp",
  "base-plan",
  "revocable-trust-plan",
  "irrevocable-trust-plan",
];

type PlanConfiguration = {
  recommendation?: PlanID;
  product?: ProductID | PlanID;
  addons?: Set<string>;
  online?: boolean;
};

const encodeSelection = (
  recommendedPlanId?: string,
  productId?: string,
  addons?: Set<string>,
  online?: boolean,
) =>
  Object.entries({
    recommendation: recommendedPlanId,
    product: productId,
    addons: addons ? Array.from(addons).join(",") : undefined,
    online,
  })
    .filter(([, value]) => value !== undefined)
    .map((entry) => entry.join("="))
    .join("&");

const getRelevantAddons = (
  selectedProduct: PlanDefinition | ProductDefinition | undefined,
  selectedWorkflowDefinition: DefinitionWithProductsPlansAndAddons | undefined,
): Addon[] => {
  const allProductsMap = keyBy(
    selectedWorkflowDefinition?.data.availableProducts || [],
    "id",
  );
  const addonIDs =
    selectedProduct && isPlanDefinition(selectedProduct)
      ? ((selectedProduct.products || [])
          .map((productId) => allProductsMap[productId]?.addons)
          .flat()
          .filter(Boolean) as AddonID[])
      : selectedProduct?.addons;

  return (addonIDs || [])
    .map((addonId) =>
      selectedWorkflowDefinition?.data.availableAddons?.find(
        (addon) => addon.id === addonId,
      ),
    )
    .filter(Boolean) as Addon[];
};

const ProductRecommendationScreen: React.FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { availableWorkflows, user, state } = useSelector(
    (state) => state,
    shallowEqual,
  );

  const parsedHash = parseParams(location.hash);
  const [currentSelection, setCurrentSelection] = useState<PlanConfiguration>({
    recommendation: parsedHash.recommendation,
    product: parsedHash.product,
    addons: new Set(parsedHash.addons?.split(",")),
    online: parsedHash.online === "true",
  });

  const advisorAnswers: AnswersByQuestionnaireType | undefined = useMemo(
    () => persistentStore.get(ADVISOR_ANSWERS_KEY),
    [],
  );

  const recommendedPlanId = parsedHash.recommendation || AdvisorPlan.ESSENTIALS;

  useEffect(() => {
    const hash =
      "#" +
      encodeSelection(
        currentSelection.recommendation,
        currentSelection.product,
        currentSelection.addons,
        currentSelection.online,
      );
    window.history.replaceState(null, "", hash);
  }, [
    currentSelection.addons,
    currentSelection.online,
    currentSelection.product,
    currentSelection.recommendation,
  ]);

  const plans: PlanDefinition[] = useMemo(() => {
    const allPlansMap = keyBy(
      Object.values(availableWorkflows || {})
        .map((wf) => wf.data.availablePlans)
        .flat(),
      "id",
    );
    return PLANS.map((planId) => allPlansMap[planId])
      .filter(Boolean)
      .filter((plan) => plan.recommendable);
  }, [availableWorkflows]);

  const availablePlansAndProducts = useMemo(() => {
    const allProducts = Object.values(availableWorkflows || {})
      .map((wf) => wf.data.availableProducts)
      .flat();

    return [...plans, ...allProducts].filter(Boolean);
  }, [availableWorkflows, plans]);

  const availablePlansAndProductsMap = useMemo(
    () => keyBy(availablePlansAndProducts, "id"),
    [availablePlansAndProducts],
  );

  const selectedProduct =
    (currentSelection?.product &&
      availablePlansAndProductsMap[currentSelection.product]) ||
    undefined;
  const selectedWorkflowDefinition =
    selectedProduct &&
    Object.values(availableWorkflows || {}).find(
      (wf) =>
        wf.data.availableProducts.find(
          (prod) => prod.id === selectedProduct.id,
        ) ||
        wf.data.availablePlans.find((plan) => plan.id === selectedProduct.id),
    );

  const relevantAddons: Addon[] = getRelevantAddons(
    selectedProduct,
    selectedWorkflowDefinition,
  );
  const relevantAddonsMap: Record<AddonID, Addon> = keyBy(relevantAddons, "id");

  const selectedAddons = useMemo(
    () =>
      currentSelection?.addons
        ? Array.from(currentSelection?.addons)
            .map((addonId) => relevantAddonsMap[addonId])
            .filter(Boolean)
        : [],
    [currentSelection?.addons, relevantAddonsMap],
  );

  const handleBackToQuestionnaire = useCallback(() => {
    persistentStore.set(ADVISOR_ANSWERS_KEY, advisorAnswers);
    go(dispatch, ROUTE_TEMPLATE.ADVISOR);
  }, [advisorAnswers, dispatch]);

  const handleSelectProduct = useCallback(
    (productId: string) => {
      setCurrentSelection({ ...currentSelection, product: productId });
    },
    [currentSelection],
  );

  const handleToggleAddon = useCallback(
    ({ id }: Addon) => {
      const nextAddonIDs = new Set(selectedAddons.map((addon) => addon.id));
      nextAddonIDs.has(id) ? nextAddonIDs.delete(id) : nextAddonIDs.add(id);
      setCurrentSelection({ ...currentSelection, addons: nextAddonIDs });
    },
    [currentSelection, selectedAddons],
  );

  const handleConfirmSelection = useCallback(() => {
    if (!selectedProduct || !selectedWorkflowDefinition || !state) return;
    isPlan(selectedProduct)
      ? dispatch(
          createNewWorkflowWithPlan(
            user,
            selectedWorkflowDefinition.id,
            selectedProduct,
            state,
            advisorAnswers,
            selectedAddons.map((addon) => addon.id),
          ),
        )
      : dispatch(
          createNewWorkflowWithProduct(
            user,
            selectedWorkflowDefinition.id,
            selectedProduct,
            state,
            advisorAnswers,
            selectedAddons.map((addon) => addon.id),
          ),
        );
  }, [
    advisorAnswers,
    dispatch,
    selectedAddons,
    selectedProduct,
    selectedWorkflowDefinition,
    state,
    user,
  ]);

  return (
    <div className={styles["product-recommendation"]}>
      <BackLink
        onClick={handleBackToQuestionnaire}
        text={`Back to questionnaire`}
      />
      <header className={styles["product-recommendation__header"]}>
        <h1 className={styles["product-recommendation__title"]}>
          Based on our experience, we believe this is the right plan for you...
        </h1>
      </header>
      <ul className={styles["product-recommendation__list"]}>
        {plans.map((plan) => (
          <PlanSelectorOption
            key={plan.id}
            allProducts={availablePlansAndProducts}
            onSelect={() => handleSelectProduct(plan.id)}
            productOrPlan={plan}
            selected={currentSelection?.product === plan.id}
            title={plan.name}
            isPricingShown={true}
            recommended={plan.id === recommendedPlanId}
          />
        ))}
      </ul>
      <div className={styles["product-recommendation__addons"]}>
        <PlanSelectorAddons
          addons={relevantAddons}
          selectedProduct={selectedProduct}
          onAddonToggled={handleToggleAddon}
          selectedAddons={selectedAddons}
        />
        <PlanSelectorSummary
          availableProducts={availablePlansAndProducts}
          onProductSelected={handleConfirmSelection}
          selectedProduct={selectedProduct}
          selectedAddons={selectedAddons}
        />
      </div>
    </div>
  );
};

export default ProductRecommendationScreen;
