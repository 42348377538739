import PageHeader from "components/PageHeader";
import React from "react";
import styles from "./styles.module.scss";

type Props = { title: string };

const ContentPage: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={styles["wrapper"]}>
      <PageHeader title={title} />
      {children}
    </div>
  );
};

export default ContentPage;
