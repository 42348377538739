import React, { useCallback, useContext } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import {
  onSaveUserProfile,
  onCompleteRegistration,
} from "../../modules/userProfile/actions";

import ProfileScreen from "./ProfileScreen";
import { User } from "../../modules/auth/User";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EventName,
  SignupEngagementAction,
} from "contexts/DataLayerContext/types";

const ProfileScreenContainer = () => {
  const dispatch = useDispatch();
  const { sendEvent } = useContext(DataLayerContext);

  const { user, state } = useSelector(
    (state) => ({
      user: state.user,
      state: state.state,
    }),
    shallowEqual,
  );
  const incompleteProfile = useSelector(
    ({ incompleteProfile }) => incompleteProfile,
  );
  const handleProfileUpdate = useCallback(
    (updatedUser: User) => dispatch(onSaveUserProfile(user, updatedUser)),
    [dispatch, user],
  );

  const handleCompleteRegistration = useCallback(
    (updatedUser: User) => {
      sendEvent?.({
        event: EventName.SignupEngagement,
        action: SignupEngagementAction.SIGNUP_PROFILE_COMPLETED,
        user_ID: user?.uid,
        user_State: state || undefined,
      });
      dispatch(onCompleteRegistration(updatedUser));
    },
    [dispatch, sendEvent, state, user?.uid],
  );

  return (
    <ProfileScreen
      incompleteProfile={incompleteProfile}
      onUpdateProfile={handleProfileUpdate}
      onCompleteRegistration={handleCompleteRegistration}
      selectedState={state}
      user={user}
    />
  );
};

export default ProfileScreenContainer;
