import {
  DEFINITION_CATEGORY_PLANS,
  DEFINITION_CATEGORY_PRODUCTS,
  DefinitionDataPlan,
  DefinitionDataProduct,
} from "modules/api/responseTypes/DefinitionResponseType";
import type DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import lodash, { find } from "lodash";
import currencyFormatter from "utils/currencyFormatter";
import {
  EcommerceItem,
  EcommerceItemID,
  ItemListID,
} from "contexts/DataLayerContext/types";
import {
  getEcommerceItemCategory,
  getEcommerceItemName,
  getItemListName,
} from "contexts/DataLayerContext/helpers";
import { PartnerID } from "modules/parties/types/Partner";

const DEFAULT_LABEL = "Get Started";

export const getFormattedStartingPrices = (
  ...productsOrPlans: (DefinitionDataProduct | DefinitionDataPlan | undefined)[]
): (string | undefined)[] =>
  productsOrPlans.map((productOrPlan) =>
    productOrPlan === undefined
      ? undefined
      : currencyFormatter.format(productOrPlan.price.regular),
  );

export const getDefinition = (
  availableDefinitions: Record<
    string,
    DefinitionWithProductsPlansAndAddons
  > | null,
  partnerId: string | undefined,
): DefinitionWithProductsPlansAndAddons | undefined => {
  const definitions =
    availableDefinitions !== null ? Object.values(availableDefinitions) : [];

  return definitions.find((definition) => definition.partnerId === partnerId);
};

export const getProductData = (
  definition: DefinitionWithProductsPlansAndAddons,
): (DefinitionDataProduct | undefined)[] => {
  const products = definition.data[DEFINITION_CATEGORY_PRODUCTS];

  return [
    find(products, (_, key) => key.includes("will")),
    find(products, (_, key) => key.includes("poa")),
    find(products, (_, key) => key.includes("hcp")),
  ];
};

export const getPlanData = (
  definition: DefinitionWithProductsPlansAndAddons,
): (DefinitionDataPlan | undefined)[] => {
  const plans = definition.data[DEFINITION_CATEGORY_PLANS];

  return [
    find(plans, (_, key) => key.includes("will-poa-hcp")),
    find(plans, (_, key) => key.includes("base-plan")),
  ];
};

export const getLandingButtonLabels = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
) => {
  if (definition === undefined) {
    return {
      essentials: DEFAULT_LABEL,
      powers: DEFAULT_LABEL,
      will: DEFAULT_LABEL,
      hcp: DEFAULT_LABEL,
      poa: DEFAULT_LABEL,
    };
  }

  const [essentialsPlanData, powersPlanData] = getPlanData(definition);

  const [willProductData, poaProductData, hcpProductData] =
    getProductData(definition);

  const [essentialsPrice, powersPrice, willPrice, hcpPrice, poaPrice] =
    getFormattedStartingPrices(
      essentialsPlanData,
      powersPlanData,
      willProductData,
      hcpProductData,
      poaProductData,
    );

  return {
    essentials: essentialsPrice || DEFAULT_LABEL,
    powers: powersPrice || DEFAULT_LABEL,
    will: willPrice || DEFAULT_LABEL,
    hcp: hcpPrice || DEFAULT_LABEL,
    poa: poaPrice || DEFAULT_LABEL,
  };
};

export const shouldShowProductsHeading = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
): boolean => {
  if (definition === undefined) return true;

  const [willData, hcpData, poaData] = getProductData(definition);

  return Boolean(willData?.enabled || hcpData?.enabled || poaData?.enabled);
};

export const shouldShowPlansHeading = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
) => {
  if (definition === undefined) return true;

  const [essentialsData, powersData] = getPlanData(definition);

  return Boolean(essentialsData?.enabled || powersData?.enabled);
};

export const getEnabledProductsAndPlans = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
) => {
  if (definition === undefined)
    return {
      essentials: true,
      powers: true,
      will: true,
      hcp: true,
      poa: true,
    };

  const [will, hcp, poa] = getProductData(definition).map((data) =>
    Boolean(data?.enabled),
  );
  const [essentials, powers] = getPlanData(definition).map((data) =>
    Boolean(data?.enabled),
  );

  return {
    essentials,
    powers,
    will,
    hcp,
    poa,
  };
};

export const getProductAndPlanIds = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
) => {
  if (definition === undefined) return {};

  const products = definition.data[DEFINITION_CATEGORY_PRODUCTS];
  const plans = definition.data[DEFINITION_CATEGORY_PLANS];

  const willId = Object.keys(products).find((key) => key.includes("will"));
  const hcpId = Object.keys(products).find((key) => key.includes("hcp"));
  const poaId = Object.keys(products).find((key) => key.includes("poa"));

  const essentialsId = Object.keys(plans).find((key) =>
    key.includes("will-poa-hcp"),
  );
  const powersId = Object.keys(plans).find((key) => key.includes("base-plan"));

  return {
    essentials: essentialsId,
    powers: powersId,
    will: willId,
    hcp: hcpId,
    poa: poaId,
  };
};

const getEcommerceItemIfDefinedAndEnabled = (
  productOrPlanData: DefinitionDataProduct | DefinitionDataPlan | undefined,
  itemId: EcommerceItemID,
):
  | Omit<EcommerceItem, "index" | "item_list_id" | "item_list_name">
  | undefined => {
  if (productOrPlanData === undefined || !productOrPlanData.enabled)
    return undefined;

  return {
    item_id: itemId,
    item_name: getEcommerceItemName(itemId),
    item_category: getEcommerceItemCategory(itemId),
    price: productOrPlanData.price.regular,
    quantity: 1,
  };
};

export const buildEcommerceItemsArray = (
  definition: DefinitionWithProductsPlansAndAddons | undefined,
  itemListId: ItemListID,
  partnerId: PartnerID,
  customOrder?: Record<EcommerceItemID, number>,
): EcommerceItem[] => {
  if (definition === undefined) return [];

  const [willData, hcpData, poaData] = getProductData(definition);
  const [essentialsData, powersData] = getPlanData(definition);

  return lodash
    .compact([
      getEcommerceItemIfDefinedAndEnabled(willData, EcommerceItemID.WILL),
      getEcommerceItemIfDefinedAndEnabled(hcpData, EcommerceItemID.HCP),
      getEcommerceItemIfDefinedAndEnabled(poaData, EcommerceItemID.POA),
      getEcommerceItemIfDefinedAndEnabled(
        essentialsData,
        EcommerceItemID.ESSENTIALS,
      ),
      getEcommerceItemIfDefinedAndEnabled(powersData, EcommerceItemID.POWERS),
    ])
    .map((item, idx) => ({
      ...item,
      index: customOrder ? customOrder[item.item_id] : idx,
      item_brand: partnerId,
      item_list_id: itemListId,
      item_list_name: getItemListName(itemListId),
    }));
};
