import classNames from "classnames/bind";
import React, { useContext } from "react";

import styles from "./styles.module.scss";

import { type HeroProps } from "./types";
import LandingButton from "components/Button";
import { useDispatch } from "react-redux";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { ChevronColour } from "components/Button/types";
import ImgWithFallback from "components/ImgWithFallback";

import HeroPNG from "static/images/landing/hero.png";
import HeroWebP from "static/images/landing/hero.webp";

import DataLayerContext from "contexts/DataLayerContext";
import {
  CTALocation,
  CTAType,
  EventName,
} from "contexts/DataLayerContext/types";

const cx = classNames.bind(styles);

const Hero = ({ scrollToOptions, partnerId }: HeroProps) => {
  const dispatch = useDispatch();
  const { sendEvent } = useContext(DataLayerContext);
  const handleNavigateToMap = () =>
    go(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));

  const handleMainButtonClicked = (buttonLabel: string) => () => {
    sendEvent?.({
      event: EventName.CTAClick,
      cta_type: CTAType.PRIMARY,
      cta_location: CTALocation.HERO,
      cta_text: buttonLabel,
    });
    scrollToOptions === undefined ? handleNavigateToMap() : scrollToOptions();
  };

  return (
    <section className={cx("hero")}>
      <div className={cx("landing__container-100")}>
        <div className={cx("hero-container")}>
          <ImgWithFallback
            src={HeroWebP}
            fallback={HeroPNG}
            alt="Woman"
            className={cx("hero__img")}
          />
          <div className={cx("hero__info-container")}>
            <div className={cx("hero__heading")}>
              <h1 className={cx("hero__info-heading")}>
                Simplifying Estate Planning,
              </h1>
              <h2 className={cx("hero__info-subheading")}>
                One Step at a Time
              </h2>
            </div>
            <h3 className={cx("her__info-description")}>
              Whether planning your estate or helping a loved one, our
              attorney-backed products are designed to make your life easier and
              your wishes secure.
            </h3>
            <div className={cx("hero__get-started")}>
              <LandingButton
                label="Let's get started"
                onClick={handleMainButtonClicked("Let's get started")}
                chevronColour={ChevronColour.GREEN}
                chevronRight
              />
            </div>
          </div>
        </div>
        {/* <RandomArrows className={cx("random-arrows")} /> */}
      </div>
    </section>
  );
};

export default Hero;
