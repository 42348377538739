import { FormSubmitHandle } from "components/Forms/Form";
import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import { addressLookup, addressSearch } from "modules/address/actions";
import {
  changeMedicaidLeadPage,
  createMedicaidLeadClient,
  medicaidLeadAnswersChanged,
  medicaidLeadPageChanged,
  saveMedicaidLeadAnswers,
  saveMedicaidLeadClient,
} from "modules/medicaid/actions";
import Client, { ClientType } from "modules/parties/types/Client";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { QuestionnaireID } from "modules/workflow/types/Questionnaire";
import React, { useCallback, useContext, useMemo } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import getQuestionnaireFormInput from "./helpers";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { isAdmin } from "modules/auth/helper";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const MedicaidLeadInterview = () => {
  const dispatch = useDispatch();
  const { lead, questionnaire, questions, answers, unsavedAnswers, clients } =
    useContext(MedicaidLeadContext);

  const { user, partner } = useSelector(
    (state) => ({
      user: state.user,
      partner: state.partner,
    }),
    shallowEqual,
  );

  const userIsAdmin = isAdmin(partner?.id, user);

  const handleSaveClient = useCallback(
    (clientType: ClientType) =>
      async (
        clientId: string | undefined,
        clientDetails: Partial<Client>,
      ): Promise<void> => {
        if (!userIsAdmin) {
          return clientId
            ? saveMedicaidLeadClient(
                dispatch,
                lead?.medicaidLeadId,
                clientId,
                clientType,
                clientDetails,
              )
            : createMedicaidLeadClient(
                dispatch,
                lead?.medicaidLeadId,
                clientType,
                clientDetails,
              );
        } else {
          return Promise.resolve();
        }
      },
    [dispatch, lead?.medicaidLeadId, userIsAdmin],
  );

  const handleAddressLookup = useCallback(
    (addressId: string) => addressLookup(addressId)(dispatch),
    [dispatch],
  );

  const handleAddressSearch = useCallback(
    (containerId?: string, searchTerm?: string) =>
      addressSearch(containerId, searchTerm)(dispatch),
    [dispatch],
  );

  const handlePageChanged = useCallback(
    (pageIdx: number): Promise<void> => {
      window.scrollTo(0, 0);
      if (lead) {
        if (userIsAdmin) {
          dispatch(medicaidLeadPageChanged(pageIdx));
          return Promise.resolve();
        } else {
          return changeMedicaidLeadPage(
            dispatch,
            lead?.medicaidLeadId,
            pageIdx,
          );
        }
      }
      return Promise.resolve();
    },
    [dispatch, lead, userIsAdmin],
  );

  const handleSavePageAnswers = async (
    questionnaireId: QuestionnaireID,
    pageAnswers: AnswersMap,
  ) =>
    lead &&
    !userIsAdmin &&
    (await saveMedicaidLeadAnswers(
      dispatch,
      lead.medicaidLeadId,
      questionnaireId,
      pageAnswers,
    ));

  const handleInterviewAnswersChanged = (answers: AnswersByQuestionnaireType) =>
    dispatch(medicaidLeadAnswersChanged(answers));

  const handleNavigationRequest = async (
    saveDelta: boolean,
    submitHandle: FormSubmitHandle | undefined,
    successCallback: () => any,
  ) => {
    try {
      if (submitHandle) await submitHandle(saveDelta);
      successCallback();
    } catch (e) {}
  };

  const handleConfirmSummary = useCallback(() => {
    lead &&
      go(
        dispatch,
        getRoute(ROUTE_TEMPLATE.MEDICAID_ANALYSIS, {
          medicaidLeadId: lead.medicaidLeadId,
        }),
      );
  }, [dispatch, lead]);

  const supportedStates = useMemo(
    () => (lead ? [lead.jurisdiction] : []),
    [lead],
  );

  const {
    questionnaireAnswers,
    questionnairePages,
    questionsBySubquestionnaire,
    clientsMap,
    questionnaireProgress,
  } = useMemo(
    () =>
      getQuestionnaireFormInput(
        questionnaire,
        questions,
        answers,
        unsavedAnswers,
        clients,
        user,
        handleSaveClient,
        handleAddressLookup,
        handleAddressSearch,
        handlePageChanged,
        handleConfirmSummary,
        supportedStates,
      ),
    [
      answers,
      clients,
      handleAddressLookup,
      handleAddressSearch,
      handleConfirmSummary,
      handlePageChanged,
      handleSaveClient,
      questionnaire,
      questions,
      supportedStates,
      unsavedAnswers,
      user,
    ],
  );

  if (
    lead === undefined ||
    questionnaireAnswers === undefined ||
    questionnairePages === undefined ||
    questionsBySubquestionnaire === undefined ||
    clientsMap === undefined ||
    questionnaireProgress === undefined
  )
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;

  return (
    <QuestionnaireForm
      isAdmin={userIsAdmin}
      answers={questionnaireAnswers}
      questionnaireAnswers={questionnaireAnswers}
      questionnairePages={questionnairePages}
      questionsBySubquestionnaire={questionsBySubquestionnaire}
      onChange={handleInterviewAnswersChanged}
      onSavePageAnswers={async (questionnaireId, unsavedAnswers) =>
        await handleSavePageAnswers(questionnaireId, unsavedAnswers)
      }
      onPageChanged={handlePageChanged}
      clients={clientsMap}
      pageIdx={lead.pageIdx}
      questionnaireProgress={questionnaireProgress}
      handleNavigationRequest={handleNavigationRequest}
      showPeoplePrompt={false}
      editable={!userIsAdmin}
    />
  );
};

export default MedicaidLeadInterview;
