import PageHeader from "components/PageHeader";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import {
  fetchAdminMedicaidLeadData,
  fetchMedicaidLeadPreviews,
  submitMedicaidLeadReview,
  unsetAdminMedicaidLeadData,
} from "modules/medicaid/actions";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { PartnerID } from "modules/parties/types/Partner";
import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import {
  MedicaidCalculationType,
  MedicaidLeadReviewVerdict,
  MedicaidLeadPreviewStatus,
} from "modules/medicaid/types";
import { go } from "modules/navigation/helpers/navigator";
import BackLink from "components/BackLink";
import MedicaidLeadPreviewCard from "./MedicaidLeadPreviewCard";
import AcceptedMedicaidLeadPreviewCard from "./AcceptedMedicaidLeadPreviewCard";
import { ModalType, showModal } from "components/Modal";
import RenderMedicaidDocumentsModal from "../RenderMedicaidDocumentsModal";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";
import {
  medicaidLeadPreviewIsAccepted,
  medicaidLeadPreviewIsPending,
} from "../MedicaidLeads/MedicaidLeadPreviewItem/helpers";

const cx = classNames.bind(styles);

const MedicaidLeadPreview = () => {
  const dispatch = useDispatch();
  const { partnerId, medicaidLeadId } = useParams<{
    partnerId?: PartnerID;
    medicaidLeadId?: string;
  }>();

  const {
    admin,
    isLoadingMedicaidLeadPreviews,
    isLoadingAdminMedicaidLeadData,
  } = useSelector(
    (state) => ({
      admin: state.medicaid.admin,
      isLoadingMedicaidLeadPreviews:
        state.medicaid.isLoadingMedicaidLeadPreviews,
      isLoadingAdminMedicaidLeadData:
        state.medicaid.isLoadingAdminMedicaidLeadData,
    }),
    shallowEqual,
  );

  if (medicaidLeadId === undefined) {
    return (
      <Redirect
        to={getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEADS, { partnerId })}
      />
    );
  }

  if (admin?.leadPreviews[medicaidLeadId] === undefined) {
    if (!isLoadingMedicaidLeadPreviews) {
      fetchMedicaidLeadPreviews(dispatch);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const handleSubmitLeadReview = (verdict: MedicaidLeadReviewVerdict) => {
    submitMedicaidLeadReview(dispatch, medicaidLeadId, verdict).then(() => {
      if (verdict === MedicaidLeadReviewVerdict.REJECTED) {
        go(
          dispatch,
          getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEADS, { partnerId }),
        );
      }
    });
  };

  const handleClickBack = () => {
    dispatch(unsetAdminMedicaidLeadData(admin.leadPreviews));
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEADS, { partnerId }),
    );
  };

  const handleNavigateToClientMedicaidInterview = () =>
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEAD, {
        partnerId,
        medicaidLeadId,
      }),
    );

  const handleNavigateToAssetProtectionAnalysis = () =>
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_ANALYSIS, {
        partnerId,
        medicaidLeadId,
      }),
    );

  const handleRenderMedicaidDocuments = () => {
    showModal({
      type: ModalType.CONFIRM,
      title: "Medicaid Documents",
      renderContent: () => (
        <RenderMedicaidDocumentsModal medicaidLeadId={medicaidLeadId} />
      ),
    });
  };

  const isAccepted =
    admin.leadPreviews[medicaidLeadId].previewStatus ===
    MedicaidLeadPreviewStatus.ACCEPTED;

  if (
    isAccepted &&
    (admin.currentLead === undefined ||
      admin.currentCalculation === undefined ||
      admin.currentLead.medicaidLead.medicaidLeadId !== medicaidLeadId)
  ) {
    if (!isLoadingAdminMedicaidLeadData) {
      fetchAdminMedicaidLeadData(dispatch, medicaidLeadId);
    }
  }

  const isLoading =
    isLoadingAdminMedicaidLeadData || isLoadingMedicaidLeadPreviews;

  const medicaidLeadPreview = admin.leadPreviews[medicaidLeadId];

  return (
    <>
      <BackLink text="Back to Medicaid Leads" onClick={handleClickBack} />
      <PageHeader title="Medicaid Lead Preview" />
      {!isAccepted && (
        <h2>
          A client has inquired about completing an Asset Protection Plan with
          you.
        </h2>
      )}
      <div className={cx("medicaid-lead-preview-card-container-container")}>
        <div
          className={cx("medicaid-lead-preview-card-container", {
            married:
              admin.currentCalculation?.calculationType ===
              MedicaidCalculationType.MARRIED,
          })}
        >
          {medicaidLeadPreviewIsAccepted(medicaidLeadPreview) && (
            <AcceptedMedicaidLeadPreviewCard
              enrichedMedicaidLead={medicaidLeadPreview.preview.enriched}
              medicaidCalculation={admin.currentCalculation}
              isLoading={isLoading}
            />
          )}
          {medicaidLeadPreviewIsPending(medicaidLeadPreview) && (
            <MedicaidLeadPreviewCard
              medicaidLeadPreview={medicaidLeadPreview.preview}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <div className={cx("medicaid-lead-preview-actions")}>
        {isAccepted ? (
          <>
            <TabbedButton
              label="Client's Interview"
              style={TabbedButtonType.NEUTRAL}
              onClick={handleNavigateToClientMedicaidInterview}
            />
            <TabbedButton
              label="Asset Protection Analysis"
              style={TabbedButtonType.SECONDARY}
              onClick={handleNavigateToAssetProtectionAnalysis}
            />
            <TabbedButton
              label="Render Documents"
              style={TabbedButtonType.PRIMARY}
              onClick={handleRenderMedicaidDocuments}
            />
          </>
        ) : (
          <>
            <TabbedButton
              label="Decline Lead"
              mood={TabbedButtonMood.NEGATIVE}
              onClick={() =>
                handleSubmitLeadReview(MedicaidLeadReviewVerdict.REJECTED)
              }
            />
            <TabbedButton
              label="Accept Lead"
              mood={TabbedButtonMood.POSITIVE}
              onClick={() =>
                handleSubmitLeadReview(MedicaidLeadReviewVerdict.ACCEPTED)
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default MedicaidLeadPreview;
