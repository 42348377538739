import React from "react";
import { User } from "modules/auth/User";

import HeaderMobileNavigation from "../HeaderMobileNavigation";
import MenuLink from "../MenuLink";
import { STATES_MAP } from "resources/options/states";

type HeaderMobileProps = {
  hideNavigationMenu?: boolean;
  hideProfileMenu?: boolean;
  mainNavigationLinks: MenuLink[];
  onLogin: () => any;
  onLogout: () => any;
  onStateChange?: () => void;
  profileMenuLinks: MenuLink[];
  selectedState?: string;
  user: User | null;
  toggleHeaderOverlay: () => void;
};

const HeaderMobile = ({
  hideNavigationMenu,
  hideProfileMenu,
  mainNavigationLinks,
  onLogin,
  onLogout,
  onStateChange,
  profileMenuLinks,
  selectedState,
  user,
  toggleHeaderOverlay,
}: HeaderMobileProps) => {
  const menuLinks: MenuLink[] = [
    ...(selectedState && onStateChange
      ? [
          {
            label: `Jurisdiction: ${STATES_MAP[selectedState]}`,
            onClick: onStateChange,
            icon: "users",
          },
        ]
      : []),
    ...mainNavigationLinks,
    ...(user ? profileMenuLinks : []),
  ];

  return (
    <React.Fragment>
      {!hideNavigationMenu && !hideProfileMenu && (
        <React.Fragment>
          <HeaderMobileNavigation
            user={user}
            navigationLinks={menuLinks}
            onLogin={onLogin}
            onLogout={onLogout}
            toggleHeaderOverlay={toggleHeaderOverlay}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HeaderMobile;
