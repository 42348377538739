import { AnswersByQuestionnaireType } from "../types/Answer";

export default (
  savedAnswers: AnswersByQuestionnaireType | undefined,
  unsavedAnswers: AnswersByQuestionnaireType,
): AnswersByQuestionnaireType => {
  return [
    ...Object.keys(savedAnswers || {}),
    ...Object.keys(unsavedAnswers),
  ].reduce((result, questionnaireId) => {
    result[questionnaireId] = Object.assign(
      {},
      (savedAnswers && savedAnswers[questionnaireId]) || {},
      unsavedAnswers[questionnaireId] || {},
    );
    return result;
  }, {} as AnswersByQuestionnaireType);
};
