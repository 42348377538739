import * as React from "react";
import { useId } from "react-id-generator";
import QuestionWrapper, { QuestionSizes } from "../QuestionWrapper";
import Select, { SelectMode } from "components/Forms/Inputs/Select";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

import styles from "components/Forms/Questions/QuestionWrapper/styles.module.scss";

type QuestionCheckboxProps = {
  allowOther?: boolean;
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  options?: Array<{
    label: string;
    value: any;
    icon?: React.ReactComponentElement<any>;
  }>;
  otherLabel?: string;
  size?: QuestionSizes;
  stacked?: boolean;
  tabIndex?: number;
};

const renderEmptyState = () => (
  <p className={styles["question__empty"]}>
    <span className={styles["question__empty-inner"]}>
      No options available
    </span>
  </p>
);

const QuestionCheckbox: React.FunctionComponent<QuestionCheckboxProps> = (
  props,
) => {
  const [questionId] = useId(1, "question-");
  const questionSize =
    props.size ||
    (props.stacked ? QuestionSizes.SMALL : QuestionSizes.FULLWIDTH);

  return (
    <QuestionWrapper
      blurTouched={true}
      className={
        props.stacked
          ? ["question--checkbox", "question--stacked-buttons"]
          : "question--checkbox"
      }
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={questionSize}
    >
      {props.options?.length || props.allowOther ? (
        <Select
          allowOther={props.allowOther}
          otherLabel={props.otherLabel}
          disabled={props.disabled}
          id={questionId}
          mode={SelectMode.MULTIPLE}
          name={props.name}
          options={props.options || []}
          tabIndex={props.tabIndex}
          wrappingQuestionSize={questionSize}
        />
      ) : (
        renderEmptyState()
      )}
    </QuestionWrapper>
  );
};

export default QuestionCheckbox;
