import classNames from "classnames/bind";
import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import { InputType } from "components/Forms/Inputs/Input/types";
import { IconType } from "components/Icons";
import TabbedButton from "components/TabbedButton";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useContext,
} from "react";
import { getZipFromUrlHash } from "../helpers";

import styles from "./styles.module.scss";
import {
  EventName,
  SearchEngagementAction,
} from "../../../../contexts/DataLayerContext/types";
import DataLayerContext from "../../../../contexts/DataLayerContext";

const cx = classNames.bind(styles);

type Props = { zip: string; setZip: Dispatch<SetStateAction<string>> };

const SearchScreenSearch: React.FC<Props> = ({ zip, setZip }) => {
  const searchButtonRef = useRef<HTMLDivElement>(null);
  const { sendEvent } = useContext(DataLayerContext);

  const setZipValue = useCallback(
    (value) => setZip(value.replace(/[^0-9]/g, "")),
    [setZip],
  );

  const confirmCurrentZipValue = useCallback(async () => {
    if (!zip) return;
    const currentHashZip = getZipFromUrlHash();

    sendEvent?.({
      event: EventName.SearchEngagement,
      search_action: SearchEngagementAction.SEARCH_PERFORMED,
      search_zip_code: zip,
    });

    if (zip === currentHashZip) {
      window.dispatchEvent(new Event("hashchange"));
    } else {
      window.location.hash = `zip=${zip}`;
    }
    searchButtonRef.current?.focus();
    searchButtonRef.current?.blur();
  }, [zip, sendEvent]);

  const handleKeyDown = useCallback(
    (event) =>
      Boolean(zip) && event.key === "Enter" ? confirmCurrentZipValue() : null,
    [confirmCurrentZipValue, zip],
  );

  return (
    <div className={cx("search")}>
      <div className={cx("search__title")}>Find your nearest law firm</div>
      <div className={cx("search__wrapper")}>
        <InputStringPrimitive
          initialFocus
          type={InputType.ZIP_CODE}
          value={zip}
          onChangeValue={setZipValue}
          placeholder="Enter your ZIP"
          onKeyDown={handleKeyDown}
        />
        <TabbedButton
          onClick={confirmCurrentZipValue}
          addClassName={styles["search__submit"]}
          icon={IconType.SEARCH}
          ref={searchButtonRef}
        />
      </div>
    </div>
  );
};

export default SearchScreenSearch;
