import { fetchPartnerDefinitions } from "modules/admin/actions";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import LoadingOverlay from "components/LoadingOverlay";
import ProductSetup from "modules/partner/settings/components/ProductSetupNavigator";

const ProductSetupScreen: React.FC = () => {
  const dispatch = useDispatch();
  const {
    user,
    partnerDefinitionsLoading,
    definitions,
    definitionDescriptions,
    partner,
    addonJurisdictions,
  } = useSelector((state) => state, shallowEqual);
  const definition = definitions?.[0];

  useEffect(() => {
    if (user) {
      dispatch(fetchPartnerDefinitions());
    }
  }, [user, dispatch]);

  if (partnerDefinitionsLoading || !partner) return <LoadingOverlay />;
  if (!definition) return <p>No definitions available</p>;

  return (
    <ProductSetup
      definition={definition}
      descriptions={definitionDescriptions}
      partner={partner}
      addonJurisdictions={addonJurisdictions}
    />
  );
};

export default ProductSetupScreen;
