import React from "react";
import { type FAQ as FAQType } from "./faqs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import Icon, { IconType } from "components/Icons";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

const FAQ: React.FC<FAQType> = ({ question, answer }) => {
  return (
    <Accordion disableGutters sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<Icon type={IconType.ARROW_FAT} />}
        className={cx("faq-title")}
      >
        <Typography
          variant="h3"
          fontSize={{
            xs: "0.9rem",
            lg: "1.15rem",
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" className={cx("faq-answer")}>
          {answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQ;
