import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase/compat/app";

import { ReactComponent as LogoSVG } from "resources/logos/Aturna_Brandmark_RGB_Purple.svg";
import { ReactComponent as Logo2SVG } from "static/logos/logo-main.svg";

import styles from "./styles.module.scss";
import DataLayerContext from "contexts/DataLayerContext";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  EventName,
  LoginEngagementAction,
  SignupEngagementAction,
} from "contexts/DataLayerContext/types";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import {
  navigateToGlobalLanding,
  navigateToPartnerLanding,
  redirect,
} from "../../../../modules/navigation/helpers/navigator";
import {
  getRoute,
  ROUTE_TEMPLATE,
} from "../../../../modules/navigation/routes";
import { useDispatch } from "react-redux";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";

type LoginWrapperProps = {
  authProvider: firebase.auth.Auth;
  userJurisdiction?: string;
};

const LoginWrapper = ({
  authProvider,
  userJurisdiction,
}: LoginWrapperProps) => {
  const { sendEvent } = useContext(DataLayerContext);
  const [hasSentAttemptEvent, setHasSentAttemptEvent] = useState(false);
  const [hasSentLoginErrorEvent, setHasSentLoginErrorEvent] = useState(false);
  const [hasSentRegisterViewEvent, setHasSentRegisterViewEvent] =
    useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [agreeToTermsOfUse, setAgreeToTermsOfUse] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginEmailError, setLoginEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // React States
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const googleAuthProvider = new GoogleAuthProvider();

  const NO_ERROR_MESSAGE = "";

  const DEFAULT_ERROR_MESSAGE =
    "An error has occurred, please check your details and try again.";

  const GOOGLE_SIGNIN_ERROR_MESSAGE =
    "An error occurred signing in to Google, please try again";

  const isErrorState = useCallback((): boolean => {
    return errorMessage !== "";
  }, [errorMessage]);

  if (!hasSentAttemptEvent && sendEvent !== undefined) {
    sendEvent({
      event: EventName.LoginEngagement,
      action: LoginEngagementAction.LOGIN_ATTEMPT,
      user_State: userJurisdiction,
    });
    setHasSentAttemptEvent(true);
  }

  const sendErrorEvent = useCallback(
    (errorField?: string) => {
      if (!hasSentLoginErrorEvent && sendEvent !== undefined) {
        sendEvent({
          event: EventName.LoginEngagement,
          action: LoginEngagementAction.LOGIN_ERROR,
          login_error_field: errorField,
          user_State: userJurisdiction,
        });
        setHasSentLoginErrorEvent(true);
      }
    },
    [
      hasSentLoginErrorEvent,
      setHasSentLoginErrorEvent,
      sendEvent,
      userJurisdiction,
    ],
  );

  const signInUserUsingEmailPassword = async (
    email: string,
    password: string,
  ): Promise<void> => {
    await authProvider.signInWithEmailAndPassword(email, password).catch(() => {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    });
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await signInUserUsingEmailPassword(loginEmail, loginPassword);
    setAgreeToTermsOfUse(false);
  };

  const signInWithGoogle = async () => {
    setErrorMessage(NO_ERROR_MESSAGE);
    googleAuthProvider.addScope("email");
    await authProvider
      .signInWithPopup(googleAuthProvider)
      .then(() => {})
      .catch(() => {
        setErrorMessage(GOOGLE_SIGNIN_ERROR_MESSAGE);
      });
  };

  const handleGoogle = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await signInWithGoogle();
    setAgreeToTermsOfUse(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNavigateToSignupScreen = () => {
    if (!hasSentRegisterViewEvent && sendEvent !== undefined) {
      sendEvent({
        event: EventName.SignupEngagement,
        action: SignupEngagementAction.SIGNUP_FORM_VIEW,
      });
      setHasSentRegisterViewEvent(true);
    }
    setErrorMessage(NO_ERROR_MESSAGE);
    redirect(dispatch, getRoute(ROUTE_TEMPLATE.SIGNUP));
  };

  const dispatch = useDispatch();
  const handleNavigateToForgottenPasswordScreen = () => {
    redirect(dispatch, getRoute(ROUTE_TEMPLATE.FORGOTTEN_PASSWORD));
  };

  useEffect(() => {
    if (isErrorState()) {
      sendErrorEvent();
    }
  }, [isErrorState, sendErrorEvent]);

  useEffect(() => {
    const unregisterLoginObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user !== null && sendEvent !== undefined) {
          // User has logged in or registered
          sendEvent({
            event: EventName.LoginEngagement,
            action: LoginEngagementAction.LOGIN_SUCCESS,
            user_State: userJurisdiction,
            user_ID: user.uid,
          });
        }
      });

    return () => unregisterLoginObserver();
  });

  useEffect(() => {
    setSubmitButtonEnabled(
      loginEmail.length !== 0 && loginPassword.length !== 0 && !loginEmailError,
    );
  }, [agreeToTermsOfUse, loginEmail, loginPassword, loginEmailError]);

  const handleLoginEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const emailValue = e.target.value;
    setLoginEmail(emailValue);
    validateEmail(emailValue, setLoginEmailError);
    setErrorMessage(NO_ERROR_MESSAGE);
  };

  const handleLoginPasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const passwordValue = e.target.value;
    setLoginPassword(passwordValue);
    setErrorMessage(NO_ERROR_MESSAGE);
  };

  const handleClickLogo = () => {
    const partnerId = getFormattedPartnerId();

    if (partnerId) {
      navigateToPartnerLanding(dispatch, partnerId);
    } else {
      navigateToGlobalLanding(dispatch);
    }
  };

  type ErrorSetter = (error: boolean) => void;

  const validateEmail = (currentEmail: string, errorSetter: ErrorSetter) => {
    if (currentEmail.length === 0) {
      errorSetter(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,32}[.]?$/i.test(currentEmail)
    ) {
      errorSetter(true);
    } else {
      errorSetter(false);
    }
  };

  return (
    <Box component="div" className={styles.login}>
      <Box component="div" className={styles.login__image}>
        <div
          onClick={handleClickLogo}
          className={styles["login__logo-1-container"]}
        >
          <LogoSVG
            aria-label="Logo"
            className={styles["login__image__logo-1"]}
          />
        </div>

        <h2>Sign in</h2>
        <h3 className={styles.login__h3_hasCont}>
          Need an account?{" "}
          <Link onClick={handleNavigateToSignupScreen}>Sign up here</Link>
        </h3>
        <h3 className={styles.login__h3_isCont}>
          <Link onClick={handleNavigateToForgottenPasswordScreen}>
            Forgot password?
          </Link>
        </h3>

        <Logo2SVG
          aria-label="Logo"
          className={styles["login__image__logo-2"]}
        />

        <form>
          <FormGroup className={styles.login__form}>
            <FormControl
              className={styles.login__input_container}
              variant="outlined"
            >
              <InputLabel htmlFor="ui-login-in-email-input">Email</InputLabel>
              <OutlinedInput
                className={styles.login__input_input}
                id="ui-login-in-email-input"
                name="ui-login-in-email-input"
                type={"text"}
                label="Email"
                error={loginEmailError}
                onChange={(e) => handleLoginEmailChange(e)}
              />
            </FormControl>
            <FormControl
              className={styles.login__input_container}
              variant="outlined"
            >
              <InputLabel htmlFor="ui-login-in-password-input">
                Password
              </InputLabel>
              <OutlinedInput
                className={styles.login__input_input}
                id="ui-login-in-password-input"
                name="ui-login-in-password-input"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => handleLoginPasswordChange(e)}
              />
            </FormControl>
            <Box
              component="div"
              className={styles.login__error_message_container}
            >
              <Typography className={styles.login__error_message}>
                {errorMessage}
              </Typography>
            </Box>
            <Box
              component="div"
              className={styles.login__input_button_container}
            >
              <Button
                variant="contained"
                className={styles.login__submit_button}
                type="submit"
                disabled={!submitButtonEnabled}
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </Box>

            <Box
              component="div"
              className={styles.login__input_separator_container}
            >
              <Box
                component="div"
                className={styles.login__input_separator_left}
              >
                <hr></hr>
              </Box>
              <Box
                component="div"
                className={styles.login__input_separator_middle}
              >
                OR
              </Box>
              <Box
                component="div"
                className={styles.login__input_separator_right}
              >
                <hr></hr>
              </Box>
            </Box>
            <Box
              component="div"
              className={styles.login__input_google_container}
            >
              <Button
                variant="outlined"
                className={styles.login__google_button}
                type="submit"
                onClick={handleGoogle}
              >
                <div className={styles.login__google_button_content}>
                  <div className={styles.login__gsi_material_button_icon}>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <p>Sign in with Google</p>
                </div>
              </Button>
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Box>
  );
};

export default LoginWrapper;
