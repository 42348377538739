import { groupBy } from "lodash";
import { OrderItemType, PaymentOrderItem } from "modules/api/types/Payment";

const groupOrderItems = (
  orderItems: PaymentOrderItem[],
): Record<OrderItemType, PaymentOrderItem[] | undefined>[] => {
  const itemsByWorkflow = Object.values(
    groupBy(
      orderItems.filter((item) => item.amount),
      "workflowId",
    ),
  ).map((workflowItems) => groupBy(workflowItems, "type"));
  return itemsByWorkflow as Record<
    OrderItemType,
    PaymentOrderItem[] | undefined
  >[];
};

export default groupOrderItems;
