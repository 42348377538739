import { Progress } from "modules/api/types/WorkflowPayload";

export default (progress?: Progress) => {
  if (!progress) return null;
  const { completed, total } = progress;

  if (!Number.isInteger(completed) || !Number.isInteger(total)) return null;

  return {
    summary: `${completed} / ${total}`,
    percent: Math.floor((completed / total) * 100),
  };
};
