import * as React from "react";
import { useId } from "react-id-generator";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";
import Confirm, { ConfirmExtraAction } from "components/Forms/Inputs/Confirm";
import { useField } from "formik";

type QuestionConfirmProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label: string;
  maskLabel?: boolean;
  name: string;
  size?: QuestionSizes;
  tabIndex?: number;
  extraAction?: ConfirmExtraAction;
};

const ConfirmQuestionWrapper: React.FC<QuestionConfirmProps> = ({
  name,
  label,
  maskLabel,
  extraAction,
}) => {
  const [, meta, helpers] = useField(name);

  return (
    <Confirm
      label={label}
      maskLabel={maskLabel}
      value={meta.value}
      onValueChange={helpers.setValue}
      extraAction={extraAction}
    />
  );
};

const QuestionConfirm: React.FC<QuestionConfirmProps> = (props) => {
  const [questionId] = useId(1, "question-");
  const questionSize = props.size || QuestionSizes.FULLWIDTH;

  return (
    <QuestionWrapper
      blurTouched={true}
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      name={props.name}
      size={questionSize}
    >
      <ConfirmQuestionWrapper {...props} />
    </QuestionWrapper>
  );
};

export default QuestionConfirm;
