import Link from "components/Link";
import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as BackSVG } from "components/Header/Back.svg";
import { Box } from "@mui/material";

type Props = { text: string; onClick: () => void };

const BackLink: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Box mb={2}>
      <Link className={styles["back-link"]} onClick={onClick}>
        <BackSVG className={styles["back-link__icon"]} /> {text}
      </Link>
    </Box>
  );
};

export default BackLink;
