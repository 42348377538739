import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames/bind";

import MenuLink from "../MenuLink";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import { User } from "modules/auth/User";
import {
  mobileWidthBreakpoint,
  useWindowDimensions,
} from "utils/windowDimensions";

const cx = classNames.bind(styles);

type HeaderMobileNavigationLinkProps = {
  label: string;
  isActive?: boolean;
  icon?: string;
  alert?: number;
  onClick: () => void;
};

export const HeaderMobileNavigationLink: React.FunctionComponent<
  HeaderMobileNavigationLinkProps
> = ({ label, icon, alert, onClick }) => {
  let className = cx(
    "header-mobile-navigation__icon",
    icon ? "header-mobile-navigation__icon--" + icon : undefined,
  );
  return (
    <li className={styles["header-mobile-navigation__item"]}>
      <TabbedButton
        tabIndex={0}
        className={styles["header-mobile-navigation__link"]}
        onClick={onClick}
      >
        <div className={className}></div>
        <span className={styles["header-mobile-navigation__link-text"]}>
          {label}
        </span>
        {alert && alert > 0 && (
          <span
            className={styles["header-mobile-navigation__link-alert-container"]}
          >
            <span className={styles["header-mobile-navigation__link-alert"]}>
              {alert}
            </span>
          </span>
        )}
      </TabbedButton>
    </li>
  );
};

type HeaderMobileNavigationProps = {
  navigationLinks: MenuLink[];
  onLogin: () => any;
  onLogout: () => any;
  user: User | null;
  toggleHeaderOverlay: () => void;
};

const HeaderMobileNavigation = ({
  navigationLinks,
  onLogin,
  onLogout,
  user,
  toggleHeaderOverlay,
}: HeaderMobileNavigationProps) => {
  const { width } = useWindowDimensions();
  const isMobile = width < mobileWidthBreakpoint;
  const [open, setOpen] = useState(false);
  let className = cx("header-mobile-navigation", {
    "header-mobile-navigation--open": open,
  });
  const handleProgressClicked = () => {
    setOpen(!open);
    toggleHeaderOverlay();
    !document.documentElement.classList.contains("progress-open") &&
      document.documentElement.classList.toggle("scroll-lock");
  };
  const handleCloseProgressClicked = useCallback(() => {
    setOpen(false);
    toggleHeaderOverlay();
    !document.documentElement.classList.contains("progress-open") &&
      document.documentElement.classList.remove("scroll-lock");
  }, [toggleHeaderOverlay]);
  useEffect(() => {
    if (open && !isMobile) {
      window.addEventListener("resize", handleCloseProgressClicked, true);
    }
    return () => {
      window.removeEventListener("resize", handleCloseProgressClicked, true);
    };
  }, [open, isMobile, handleCloseProgressClicked]);

  return (
    <div className={className}>
      <nav className={styles["header-mobile-navigation__nav"]}>
        <ul>
          {navigationLinks.map(({ icon, isActive, label, onClick }, idx) => (
            <HeaderMobileNavigationLink
              key={`main_mobile_menu_${idx}`}
              label={label}
              isActive={isActive}
              icon={icon}
              onClick={() => {
                onClick();
                handleCloseProgressClicked();
              }}
            />
          ))}
          {!user && (
            <HeaderMobileNavigationLink
              key={`main_mobile_menu_login`}
              isActive
              icon="login"
              label="Login"
              onClick={onLogin}
            />
          )}
        </ul>
        {user ? (
          <TabbedButton
            tabIndex={0}
            label="Log out"
            onClick={onLogout}
            addClassName={styles["header-mobile-navigation__logout"]}
          />
        ) : null}
      </nav>
      <TabbedButton
        ariaLabel="Open menu"
        className={styles["header-mobile-navigation__button"]}
        onClick={handleProgressClicked}
      >
        <span></span>
        <span></span>
        <span></span>
      </TabbedButton>
    </div>
  );
};

export default HeaderMobileNavigation;
