import React, { ReactNode, forwardRef } from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import Icon, { IconType } from "../Icons";
import { Tooltip } from "@mui/material";
const cx = classNames.bind(styles);

export enum TabbedButtonType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  NEUTRAL = "neutral",
}

export enum TabbedButtonMood {
  POSITIVE = "positive",
  NEGATIVE = "negative",
  CALL_TO_ACTION = "cta",
}

export enum TabbedButtonStatus {
  LAWYER_ACTION_REQUIRED = "lawyer-action",
  CLIENT_ACTION_REQUIRED = "client-action",
  IN_PROGRESS = "in-progress",
  NO_STATUS = "no-status",
  COMPLETED = "completed",
  REJECTED = "rejected",
}

type TabbedButtonProps = {
  children?: ReactNode;
  ariaLabel?: string;
  className?: string;
  addClassName?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  maskLabel?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  tabIndex?: number;
  style?: TabbedButtonType;
  mood?: TabbedButtonMood;
  status?: TabbedButtonStatus;
  icon?: IconType;
  iconRight?: boolean;
  borderedIcon?: boolean;
  role?: "button" | "checkbox";
  tooltip?: string;
  // Warning: Doesn't work with Formik's events correctly in Firefox
  // submit?: boolean;
};

const TabbedButton = forwardRef<HTMLDivElement, TabbedButtonProps>(
  (props, ref) => {
    const handleClick = async (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      event.preventDefault();
      if (!props.disabled) {
        // const form = event.currentTarget.closest("form");
        try {
          if (props.onClick) {
            await props.onClick(event);
          }
          // if (props.submit) {
          //   form?.dispatchEvent(new Event("submit"));
          // }
        } catch {}
      }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!props.disabled && event.key === "Enter") {
        handleClick(event);
      }
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const className = cx(
      "button",
      {
        "button--primary":
          props.style !==
            (TabbedButtonType.SECONDARY || TabbedButtonType.NEUTRAL) ||
          props.status === TabbedButtonStatus.NO_STATUS,
        "button--secondary": props.style === TabbedButtonType.SECONDARY,
        "button--neutral": props.style === TabbedButtonType.NEUTRAL,
        "button--positive": props.mood === TabbedButtonMood.POSITIVE,
        "button--negative": props.mood === TabbedButtonMood.NEGATIVE,
        "button--cta": props.mood === TabbedButtonMood.CALL_TO_ACTION,
        "button--disabled": props.disabled,
        "button--icon": props.icon && !props.label,
        "button--icon-label": props.icon && props.label,
        "button--icon--bordered": props.borderedIcon,
        "button--lawyer--action--required":
          props.status === TabbedButtonStatus.LAWYER_ACTION_REQUIRED,
        "button--client--action--required":
          props.status === TabbedButtonStatus.CLIENT_ACTION_REQUIRED,
        "button--in--progress": props.status === TabbedButtonStatus.IN_PROGRESS,
        "button--completed": props.status === TabbedButtonStatus.COMPLETED,
        "button--rejected": props.status === TabbedButtonStatus.REJECTED,
      },
      props.addClassName,
    );

    const ariaLabel =
      props.ariaLabel ||
      props.label ||
      (typeof props.children === "string" ? String(props.children) : undefined);

    const button = (
      <div
        aria-label={ariaLabel}
        className={props.className ? props.className : className}
        id={props.id}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        onMouseDown={handleMouseDown}
        role={props.role || "button"}
        tabIndex={props.disabled ? -1 : props.tabIndex ? props.tabIndex : 0}
        ref={ref}
      >
        {props.icon && !props.iconRight && (
          <Icon disabled={props.disabled} type={props.icon} />
        )}
        {props.label && (
          <span data-clarity-mask={props.maskLabel}>{props.label}</span>
        )}
        {typeof props.children === "string" ? (
          <span>{props.children}</span>
        ) : (
          props.children
        )}
        {props.icon && props.iconRight && (
          <Icon disabled={props.disabled} type={props.icon} />
        )}
      </div>
    );

    return props.tooltip ? (
      <Tooltip
        title={<span className={cx("button__tooltip")}>{props.tooltip}</span>}
        arrow
      >
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  },
);

export default TabbedButton;
