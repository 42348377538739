import * as React from "react";
import QuestionRadio from "../QuestionRadio";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

type QuestionSwitchProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label: string;
  labelOn: string;
  labelOff: string;
  name: string;
  tabIndex?: number;
  size?: QuestionSizes;
};

const QuestionSwitch: React.FunctionComponent<QuestionSwitchProps> = (
  props,
) => {
  const { labelOn, labelOff, name, help, info, label, tabIndex, disabled } =
    props;
  return (
    <QuestionRadio
      help={help}
      info={info}
      label={label}
      name={name}
      disabled={disabled}
      options={[
        { value: true, label: labelOn },
        { value: false, label: labelOff },
      ]}
      size={props.size}
      tabIndex={tabIndex}
    />
  );
};

export default QuestionSwitch;
