import React, { useCallback } from "react";
import TabbedButton from "components/TabbedButton";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import { STATES_MAP } from "resources/options/states";
import PlaceholderScreen, {
  PlaceholderScreenMood,
} from "screens/core/PlaceholderScreen";

import styles from "./styles.module.scss";
import Link from "components/Link";
import { changeState } from "modules/auth/actions";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { go } from "modules/navigation/helpers/navigator";

type Props = {
  disableStateChange?: boolean;
};

const StateNotSupportedScreen: React.FC<Props> = ({ disableStateChange }) => {
  const { partner, state } = useSelector((state) => state, shallowEqual);
  const dispatch = useDispatch();

  const handleChangePartner = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.SEARCH),
    [dispatch],
  );

  const handleChangeState = useCallback(
    () => dispatch(changeState()),
    [dispatch],
  );

  const stateText =
    (state && STATES_MAP[state]) || "the state you have selected";

  const text = `Unfortunately, ${stateText} is not serviced by ${
    partner?.data.displayName
  }. Please select a different partner${
    !disableStateChange ? " or change your state" : ""
  }.`;

  const actionButtons = [
    <TabbedButton key="btnChangePartner" onClick={handleChangePartner}>
      Find a local law firm
    </TabbedButton>,

    !disableStateChange ? (
      <Link key="btnChangeState" onClick={handleChangeState}>
        Change State
      </Link>
    ) : undefined,
  ].filter(Boolean);

  return (
    <PlaceholderScreen
      mood={PlaceholderScreenMood.NEUTRAL}
      title="Your state is not serviced by this partner"
      message={text}
      renderAction={() => <div className={styles.actions}>{actionButtons}</div>}
    />
  );
};

export default StateNotSupportedScreen;
