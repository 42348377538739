import * as React from "react";
import { useId } from "react-id-generator";

import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import Select, { SelectMode } from "components/Forms/Inputs/Select";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

type QuestionSelectProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  options?: Array<{ label: string; value: any; description?: string }>;
  otherLabel?: string;
  placeholder?: string;
  size?: QuestionSizes;
  tabIndex?: number;
  allowOther?: boolean;
  showHelpByDefault?: boolean;
};

const QuestionSelect: React.FunctionComponent<QuestionSelectProps> = (
  props,
) => {
  const [questionId] = useId(1, "question-");
  const optionsHelp: QuestionHelp[] = (props.options || [])
    .map(({ label, description }) =>
      description
        ? ({ title: label, text: description } as QuestionHelp)
        : undefined,
    )
    .filter(Boolean) as QuestionHelp[];

  const help: QuestionHelp = [
    ...(props.help
      ? Array.isArray(props.help)
        ? props.help
        : [props.help]
      : []),
    ...optionsHelp,
  ].flat();

  return (
    <QuestionWrapper
      className="question--select"
      disabled={props.disabled}
      help={help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={props.size || QuestionSizes.MEDIUM}
      showHelpByDefault={props.showHelpByDefault}
    >
      <Select
        allowOther={props.allowOther}
        disabled={props.disabled}
        id={questionId}
        options={props.options || []}
        otherLabel={props.otherLabel}
        name={props.name}
        tabIndex={props.tabIndex}
        placeholder={props.placeholder}
        mode={SelectMode.DROPDOWN}
      />
    </QuestionWrapper>
  );
};

export default QuestionSelect;
