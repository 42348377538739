import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";
import React, { useEffect, useState } from "react";
import {
  DownloadRenderedSalesReceiptProps,
  RenderedSalesReceiptId,
  RenderedSalesReceiptStatus,
} from "./types";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { API, get, post } from "modules/api";
import logger from "logger";
import { NotificationType } from "components/Notification";

const cx = classNames.bind(styles);

const DownloadRenderedSalesReceipt = ({
  partnerId,
  from,
  to,
  setIsDownloadingReceipt,
}: DownloadRenderedSalesReceiptProps) => {
  const [renderStatus, setRenderStatus] = useState("Pending");
  const [signedUrl, setSignedUrl] = useState("#");
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    async function renderSalesReceipt() {
      try {
        const renderResponse = await post(API.PARTNER_SALES_RECEIPT(), {
          partnerId,
          from,
          to,
        });
        fetchUntilNonPending(renderResponse.data.renderedSalesReceiptId);
        return renderResponse;
      } catch (error) {
        setRenderStatus(RenderedSalesReceiptStatus.FAILED);
        setIsDownloadingReceipt(false);
        logger.notify(
          NotificationType.ERROR,
          "Could not render sales receipt",
          error,
        );
      }
    }

    async function downloadSalesReceipt(
      renderedSalesReceiptId: RenderedSalesReceiptId,
    ) {
      const downloadResponse = await get(API.PARTNER_SALES_RECEIPT(), {
        renderedSalesReceiptId,
      });

      return downloadResponse;
    }

    async function fetchUntilNonPending(
      renderedSalesReceiptId: RenderedSalesReceiptId,
    ) {
      const downloadInterval = setInterval(async () => {
        try {
          const downloadResponse = await downloadSalesReceipt(
            renderedSalesReceiptId,
          );
          if (downloadResponse.status === 202) {
            setRenderStatus(RenderedSalesReceiptStatus.PENDING);
          } else if (downloadResponse.status === 200) {
            downloadResponse.data.signedUrl &&
              setSignedUrl(downloadResponse.data.signedUrl);
            setRenderStatus(RenderedSalesReceiptStatus.FINISHED);
            setIsDownloadingReceipt(false);
            clearInterval(downloadInterval);
          } else {
            setRenderStatus(RenderedSalesReceiptStatus.FAILED);
            setIsDownloadingReceipt(false);
            clearInterval(downloadInterval);
          }
        } catch (error) {
          setRenderStatus(RenderedSalesReceiptStatus.FAILED);
          setIsDownloadingReceipt(false);
          logger.notify(
            NotificationType.ERROR,
            "Could not get rendered sales receipt",
            error,
          );
          clearInterval(downloadInterval);
        }
      }, 2000);
    }

    if (!hasFetched) {
      setHasFetched(true);
      renderSalesReceipt();
    }
  }, [from, hasFetched, partnerId, setIsDownloadingReceipt, to]);

  return (
    <div className={cx("download-sales-receipt")}>
      {`${partnerId} Sales Receipt`}
      <div>
        {renderStatus === RenderedSalesReceiptStatus.PENDING ? (
          <LoadingSpinner size={SpinnerSize.XSMALL} />
        ) : renderStatus === RenderedSalesReceiptStatus.FINISHED ? (
          <a href={signedUrl || "#"}>{RenderedSalesReceiptStatus.FINISHED}</a>
        ) : (
          renderStatus
        )}
      </div>
    </div>
  );
};

export default DownloadRenderedSalesReceipt;
