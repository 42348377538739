import { Middleware } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
import { sessionRehydrated, stateChanged } from "modules/auth/actions";
import {
  getPlanTailoringRoute,
  getProductTailoringRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import { go, isPath } from "modules/navigation/helpers/navigator";
import { partnerLoaded } from "modules/partner/actions";
import type { RootState } from "redux/reducer";
import { Location } from "history";
import {
  advisorQuestionnaireComplete,
  AdvisorQuestionnaireCompletePayload,
  cancelAdvisor,
  fetchProducts,
  getProductRecommendation,
  isPlanAdvisorPayload,
  StartAdvisorPayload,
  startProductAdvisor,
} from "./actions";

const createProductMiddleware: () => Middleware =
  () =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    const loadProductsIfNeeded = (state: string, location?: Location) => {
      const { partner }: RootState = getState();
      if (!partner) return;

      if (
        isPath(ROUTE_TEMPLATE.PARTNER_ROOT, location) ||
        isPath(ROUTE_TEMPLATE.PRODUCTS, location) ||
        isPath(ROUTE_TEMPLATE.PRODUCT_RECOMMENDATION, location) ||
        isPath(ROUTE_TEMPLATE.PRODUCT_TAILORING, location) ||
        isPath(ROUTE_TEMPLATE.PLAN_TAILORING, location) ||
        isPath(ROUTE_TEMPLATE.WORKFLOW_DETAILS, location) ||
        isPath(ROUTE_TEMPLATE.WORKFLOW, location) ||
        isPath(ROUTE_TEMPLATE.PRODUCT_CONFIGURATION, location)
      ) {
        fetchProducts(state)(dispatch);
      }
    };

    const checkProducts = (location?: Location) => {
      const { state, availableWorkflows, isLoadingProducts }: RootState =
        getState();
      state &&
        !availableWorkflows &&
        !isLoadingProducts &&
        loadProductsIfNeeded(state, location);
    };

    switch (action.type) {
      case partnerLoaded.type: {
        // Execute after redirecting to /:partnerId/*
        setTimeout(() => checkProducts(), 0);
        break;
      }

      case sessionRehydrated.type: {
        checkProducts();
        break;
      }

      case LOCATION_CHANGE: {
        checkProducts(action.payload.location);
        break;
      }

      case stateChanged.type: {
        const state = action.payload;
        loadProductsIfNeeded(state);
        break;
      }

      case cancelAdvisor.type: {
        go(dispatch, action.payload.previousUrl || ROUTE_TEMPLATE.WORKFLOW);
        break;
      }

      case startProductAdvisor.type: {
        const payload: StartAdvisorPayload = action.payload;

        go(
          dispatch,
          isPlanAdvisorPayload(payload)
            ? getPlanTailoringRoute(
                payload.workflowId,
                payload.planId,
                payload.userId,
              )
            : getProductTailoringRoute(
                payload.workflowId,
                payload.productId,
                payload.userId,
              ),
          { from: payload.fromUrl },
        );
        break;
      }

      case advisorQuestionnaireComplete.type: {
        const { answers, addons } =
          action.payload as AdvisorQuestionnaireCompletePayload;
        getProductRecommendation(answers, addons)(dispatch);
      }
    }
    next(action);
  };

export default createProductMiddleware;
