import PartnersAdminDetail from "modules/globalAdmin/components/PartnersAdminDetail";
import PartnersAdminList from "modules/globalAdmin/components/PartnersAdminList";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PartnersAdminDetailScreen from "modules/globalAdmin/components/PartnerAdminScreen";

const PartnersAdminNavigator = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/:partnerId/:screen`}
        render={({ match }) => (
          <PartnersAdminDetailScreen
            partnerId={match.params.partnerId}
            screen={match.params.screen}
          />
        )}
      />
      <Route
        path={`${path}/:partnerId`}
        render={({ match }) => (
          <PartnersAdminDetail partnerId={match.params.partnerId} />
        )}
      />
      <Route>
        <PartnersAdminList />
      </Route>
    </Switch>
  );
};

export default PartnersAdminNavigator;
