import { createReducer } from "@reduxjs/toolkit";
import { userLoggedOut } from "modules/auth/actions";
import Partner from "modules/parties/types/Partner";
import {
  draftingAnswersChanged,
  loadingPartnerFailed,
  loadPartnerStarted,
  partnerIdInvalid,
  partnerLoaded,
  partnerSaved,
  partnerSelected,
  partnerUnselected,
  resetDraftingForm,
  searchPartnerComplete,
} from "./actions";
import { AnswersMap } from "modules/workflow/types/Answer";

export const partner = createReducer<Partner | null>(null, (builder) =>
  builder
    .addCase(loadPartnerStarted, () => null)
    .addCase(partnerLoaded, (currentState, { payload }) => ({
      ...currentState,
      ...payload,
    }))
    .addCase(partnerSaved, (currentState, { payload }) => ({
      integrations: currentState?.integrations,
      onboardingQuestionnaire: currentState?.onboardingQuestionnaire,
      ...payload,
    }))
    .addCase(partnerSelected, (currentState, { payload }) =>
      payload === null ? null : currentState,
    )
    .addCase(partnerUnselected, () => null)
    .addCase(searchPartnerComplete, () => null)
    .addCase(userLoggedOut, () => null),
);

export const isLoadingPartner = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(loadPartnerStarted, () => true)
    .addCase(partnerLoaded, () => false)
    .addCase(partnerIdInvalid, () => false)
    .addCase(loadingPartnerFailed, () => false),
);

export const draftingFormAnswers = createReducer<AnswersMap>({}, (builder) =>
  builder
    .addCase(draftingAnswersChanged, (prev, { payload }) => ({
      ...prev,
      ...payload,
    }))
    .addCase(resetDraftingForm, () => ({})),
);
