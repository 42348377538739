import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import type { RootState } from "redux/reducer";
import { User, UserRole } from "../User";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { PartnerID } from "modules/parties/types/Partner";
import { getUserRoles } from "../helper";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";

type ProtectedRouteProps = RouteProps & {
  partnerId?: PartnerID;
  rejectedRoles?: UserRole[];
  roles?: UserRole[];
  user?: User;
};

const isAllowed = (
  partnerId?: PartnerID,
  currentUser?: User,
  restrictedRoles?: UserRole[],
  rejectedRoles?: UserRole[],
): boolean => {
  if (!currentUser) {
    return false;
  }

  const userRoles = getUserRoles(partnerId, currentUser);

  if (rejectedRoles && userRoles.find((role) => rejectedRoles.includes(role)))
    return false;

  return restrictedRoles
    ? Boolean(
        userRoles && userRoles.find((role) => restrictedRoles.includes(role)),
      )
    : true; // Intersection
};

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  children,
  user,
  rejectedRoles,
  roles,
  ...rest
}) => {
  const partnerId = getFormattedPartnerId() || undefined;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAllowed(partnerId, user, roles, rejectedRoles)) return children;

        return user ? (
          <Redirect to={{ pathname: getRoute(ROUTE_TEMPLATE.PARTNER_ROOT) }} />
        ) : (
          <Redirect
            to={{
              pathname: getRoute(ROUTE_TEMPLATE.SIGNUP),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  partner: state.partner,
});

export default connect(mapStateToProps, null)(ProtectedRoute);
