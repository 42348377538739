import React from "react";
import { InformationBoxProps } from "./types";
import styles from "./styles.module.scss";

const InformationBox = ({ title, info }: InformationBoxProps) => {
  return (
    <div className={styles["information-box"]}>
      <h3>{title}</h3>
      <span className={styles["information-box-info"]}>{info}</span>
    </div>
  );
};

export default InformationBox;
