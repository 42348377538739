import {
  AnswerValueType,
  AssetSplit,
  LifeInsurancePolicyValue,
} from "modules/workflow/types/Answer";

export enum ComplexAnswerType {
  ASSET_SPLIT = "AssetSplit",
  MULTIPLE = "Multiple",
  OR_OPTION = "OrOption",
  LIFE_INSURANCE_POLICY_VALUE = "LifeInsurancePolicyValue",
}

export const isAssetSplitAnswer = (
  answerValueType: AnswerValueType,
): answerValueType is AssetSplit =>
  !!answerValueType?.hasOwnProperty("mainOwned");

export const isLifeInsurancePolicyValueAnswer = (
  answerValueType: AnswerValueType,
): answerValueType is LifeInsurancePolicyValue =>
  !!answerValueType?.hasOwnProperty("faceValue");
