import { List, ListItem } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import SanitizeHtml from "components/SanitizeHtml";
import Switch from "components/Forms/Inputs/Switch";

const cx = classNames.bind(styles);

type ItemProps = {
  active?: boolean;
  onClick: () => void;
  subtitle?: string;
  title: React.ReactNode;
  toggleItem?: (active: boolean) => void;
  clickable?: boolean;
};

export const LinkList: React.FC = ({ children }) => {
  return <List className={cx("config-list")}>{children}</List>;
};

export const LinkListItem: React.FC<ItemProps> = ({
  active,
  onClick,
  subtitle,
  title,
  toggleItem,
  clickable = true,
}) => {
  return (
    <ListItem
      className={cx("config-list__item", {
        "config-list__item-clickable": clickable,
      })}
      onClick={clickable ? onClick : undefined}
    >
      <header>
        {active !== undefined && (
          <Switch
            onChange={toggleItem}
            disabled={!toggleItem}
            selected={active}
          />
        )}
        <div className={cx("config-list__title")}>{title}</div>
      </header>
      {subtitle && (
        <div className={cx("config-list__subtitle")}>
          <SanitizeHtml html={subtitle} />
        </div>
      )}
    </ListItem>
  );
};

export default LinkList;
