import { createAction, Dispatch } from "@reduxjs/toolkit";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import { callConfirmAgreementAPI } from "../endpoints/workflowSteps";

export const loadingAgreementStarted = createAction<{
  workflowId: string;
  ownerId?: string;
}>("LOADING_AGREEMENT_STARTED");
export const loadingAgreementFailed = createAction<any>(
  "LOADING_AGREEMENT_FAILED",
);
export const agreementLoaded =
  createAction<DownloadableDocumentDetails>("AGREEMENT_LOADED");

export const agreementConfirmed = createAction("AGREEMENT_CONFIRMED");
export const agreementConfirmFailed = createAction("AGREEMENT_CONFIRM_FAILED");

export const confirmAgreement =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      await callConfirmAgreementAPI(workflowId, asUser);
      dispatch(agreementConfirmed());
    } catch (e) {
      dispatch(agreementConfirmFailed());
      throw e;
    }
  };
