export enum WorkflowDetailsConstraintType {
  IsCoupled = "IsCoupled",
  JurisdictionMatches = "JurisdictionMatches",
}

export type WorkflowCoupledCondition = {
  type: WorkflowDetailsConstraintType.IsCoupled;
  value: boolean;
};

export type JurisdictionMatches = {
  type: WorkflowDetailsConstraintType.JurisdictionMatches;
  value: string;
};

type WorkflowDetailsCondition = WorkflowCoupledCondition | JurisdictionMatches;

export default WorkflowDetailsCondition;
