import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material";

import styles from "./styles.module.scss";

type Props = { title: string };

const Tooltip: React.FC<Props> = ({ title, children }) => {
  return (
    <MuiTooltip
      title={<span className={styles["tooltip"]}>{title}</span>}
      arrow
    >
      <span>{children}</span>
    </MuiTooltip>
  );
};

export default Tooltip;
