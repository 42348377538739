import { createReducer } from "@reduxjs/toolkit";
import {
  workflowsLoaded,
  workflowsLoadingFailed,
  workflowsLoadingStarted,
  newWorkflowCreated,
  fetchWorkflowDetailsInitiated,
  workflowDetailsFetched,
  saveAnswersInitiated,
  answersSaved,
  invalidAnswers,
  workflowNotAssigned,
  fetchWorkflowDetailsFailed,
  workflowCloned,
  cloneWorkflowStarted,
  cloneWorkflowFailed,
  changePage,
  workflowStateChanged,
  workflowLifecycleUpdated,
  interviewAnswersChanged,
  workflowAddonsSaved,
  fetchWorkflowStateInitiated,
  workflowStateFetched,
} from "./actions";
import Workflow, { ImportableParties, WorkflowID } from "./types/Workflow";
import {
  clientSaved,
  relatedPartyDeleted,
  relatedPartySaved,
} from "modules/parties/actions";
import Client, { ClientType } from "modules/parties/types/Client";
import Party, { RelationshipType } from "modules/parties/types/Party";
import { parseAdminWorkflow } from "./helpers/parseWorkflow";
import { LOCATION_CHANGE } from "connected-react-router";
import { QuestionID } from "./types/Questionnaire";
import { AnswersByQuestionnaireType } from "./types/Answer";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { defaultsDeep, keyBy, merge, sortBy } from "lodash";
import { Step, WorkflowAction } from "modules/api/types/WorkflowPayload";
import {
  documentsDownloadDetailsLoaded,
  loadDocumentsCancelled,
  loadDocumentsDownloadDetailsFailed,
  loadDocumentsDownloadDetailsStarted,
  loadDocumentsFailed,
  loadDocumentsStarted,
  renderedDocumentsLoaded,
} from "./documents/actions";
import {
  paymentInitialized,
  paymentSkipped,
  paymentSuccessful,
} from "modules/payment/actions";
import { partnerSelected } from "modules/partner/actions";
import { userLoggedOut } from "modules/auth/actions";
import { partnerWorkflowsPrioritisedFetched } from "modules/admin/actions";
import { lawyerReviewStarted } from "./review/actions";

export const hasRehydratedWorkflows = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(workflowsLoaded, () => true)
    .addCase(partnerWorkflowsPrioritisedFetched, () => true)
    .addCase(workflowsLoadingFailed, () => true)
    .addCase(partnerSelected, () => false),
);

export const isLoadingWorkflows = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(workflowsLoadingStarted, () => true)
    .addCase(workflowsLoaded, () => false)
    .addCase(workflowsLoadingFailed, () => false),
);

export const isLoadingWorkflowDetails = createReducer<boolean>(
  false,
  (builder) =>
    builder
      .addCase(fetchWorkflowDetailsInitiated, () => true)
      .addCase(fetchWorkflowStateInitiated, () => true)
      .addCase(workflowNotAssigned, () => false)
      .addCase(workflowDetailsFetched, () => false)
      .addCase(workflowStateFetched, () => false)
      .addCase(fetchWorkflowDetailsFailed, () => false),
);

export const isEditingWorkflow = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(fetchWorkflowDetailsInitiated, () => false)
    .addCase(fetchWorkflowStateInitiated, () => false)
    .addCase(workflowNotAssigned, () => false)
    .addCase(workflowDetailsFetched, () => true)
    .addCase(workflowStateFetched, () => false)
    .addCase(fetchWorkflowDetailsFailed, () => false)
    .addCase(workflowsLoaded, () => false),
);

export const isCloningWorkflow = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(cloneWorkflowStarted, () => true)
    .addCase(cloneWorkflowFailed, () => false)
    .addCase(workflowCloned, () => false),
);

export const workflows = createReducer<Record<string, Workflow>>(
  {},
  (builder) =>
    builder
      .addCase(
        partnerWorkflowsPrioritisedFetched,
        (workflows, { payload: partnerWorkflows }) => {
          const allWorkflows: Record<string, Workflow> = {};
          partnerWorkflows.forEach((wf) => {
            allWorkflows[wf.workflowId] = {
              ...workflows[wf.workflowId],
              ...parseAdminWorkflow(wf),
            };
          });
          return allWorkflows;
        },
      )
      .addCase(workflowsLoaded, (currentWorkflows, { payload }) =>
        merge(
          currentWorkflows,
          keyBy(sortBy(payload, "created").reverse(), "id") as Record<
            string,
            Workflow
          >,
        ),
      )
      .addCase(newWorkflowCreated, (allWorkflows, { payload }) => ({
        ...allWorkflows,
        [payload.id]: payload,
      }))
      .addCase(workflowCloned, (allWorkflows, { payload }) => {
        const parentWorkflowId: string | undefined =
          payload.state?.coupledWorkflowId;
        if (parentWorkflowId && allWorkflows[parentWorkflowId]?.state) {
          allWorkflows[parentWorkflowId].state.coupledWorkflowId = payload.id;
        }
        allWorkflows[payload.id] = payload;
      })
      .addCase(workflowLifecycleUpdated, (allWorkflows, { payload }) => {
        Object.entries(payload).forEach(([workflowId, update]) => {
          const workflow = allWorkflows[workflowId];
          if (workflow && update.step !== workflow.state.steps.current.step) {
            workflow.state.steps.history.push({
              ...workflow.state.steps.current,
            });
            workflow.state.steps.current.step = update.step;
          }
        });
      })
      .addCase(workflowAddonsSaved, (allWorkflows, { payload }) => {
        const workflow = allWorkflows[payload.workflowId];
        if (workflow) {
          workflow.state.enabledAddons = payload.selectedAddons;
        }
      })
      .addCase(answersSaved, (allWorkflows, { payload }) => {
        const { workflowId, answers: answersByQuestionnaire } = payload;
        const workflow = allWorkflows[workflowId];

        if (workflow) {
          Object.entries(answersByQuestionnaire).forEach(
            ([questionnaireId, answers]) => {
              const currentQuestionnaireAnswers =
                workflow.answers && workflow.answers[questionnaireId];

              workflow.answers = {
                ...(workflow.answers || {}),
                [questionnaireId]: {
                  ...(currentQuestionnaireAnswers || {}),
                  ...answers,
                },
              };
            },
          );
        }
      })
      .addCase(workflowNotAssigned, (allWorkflows, { payload }) => {
        const { [payload]: _, ...remainingWorkflows } = allWorkflows;
        return remainingWorkflows;
      })
      .addCase(fetchWorkflowDetailsInitiated, (allWorkflows, { payload }) => {
        if (allWorkflows[payload]) {
          allWorkflows[payload].isLoading = true;
        }
      })
      .addCase(workflowStateChanged, (allWorkflows, { payload }) => {
        if (allWorkflows[payload.workflowId]) {
          allWorkflows[payload.workflowId].state = payload.state;
        }
      })
      .addCase(paymentSuccessful, (allWorkflows, { payload }) => {
        const workflow = payload.workflowId && allWorkflows[payload.workflowId];
        if (workflow) {
          workflow.state.steps = {
            current: {
              step: Step.PaymentSubmitted,
              action: WorkflowAction.SubmitPayment,
              timestamp: new Date().toISOString(),
              metadata: {},
            },
            history: [
              ...workflow.state.steps.history,
              workflow.state.steps.current,
            ],
          };
        }
      })
      .addCase(paymentInitialized, (allWorkflows, { payload }) =>
        payload.workflowId
          ? {
              ...allWorkflows,
              [payload.workflowId]: {
                ...allWorkflows[payload.workflowId],
                isSkippingPayment: false,
              },
            }
          : allWorkflows,
      )
      .addCase(paymentSkipped, (allWorkflows, { payload }) => ({
        ...allWorkflows,
        [payload.workflowId]: {
          ...allWorkflows[payload.workflowId],
          isSkippingPayment: true,
        },
      }))
      .addCase(fetchWorkflowDetailsFailed, (allWorkflows, { payload }) => {
        if (allWorkflows[payload.workflowId]) {
          allWorkflows[payload.workflowId].isLoading = false;
        }
      })
      .addCase(workflowDetailsFetched, (allWorkflows, { payload }) => ({
        ...allWorkflows,
        [payload.id]: payload,
      }))
      .addCase(clientSaved, (allWorkflows, { payload }) => {
        const {
          clientType,
          client: savedClient,
          workflowId,
        } = payload as {
          clientType: ClientType;
          client: Client;
          workflowId: string;
        };
        const workflow = allWorkflows[workflowId] || undefined;
        if (!workflow) return allWorkflows;

        workflow.contacts = {
          ...(workflow.contacts || {}),
          clients: {
            ...(workflow.contacts?.clients || {}),
            [clientType]: savedClient,
          } as ClientsMap,
        };
      })
      .addCase(relatedPartySaved, (allWorkflows, { payload }) => {
        const { party: savedParty, workflowId } = payload;
        const workflow = allWorkflows[workflowId] || undefined;
        if (!workflow) return allWorkflows;

        workflow.contacts = {
          ...(workflow.contacts || {}),
          parties: {
            ...(workflow.contacts?.parties || {}),
            [savedParty.partyId]: savedParty,
          },
        };
      })
      .addCase(relatedPartyDeleted, (allWorkflows, { payload }) => {
        const { workflowId, party } = payload;
        const workflow = allWorkflows[workflowId] || undefined;
        if (!workflow) return allWorkflows;

        const { [party.partyId]: _, ...remainingRelatedParties } =
          workflow.contacts?.parties || {};
        workflow.contacts = {
          ...workflow.contacts,
          parties: remainingRelatedParties,
        };

        // Is related to coupled workflow - keep the party in the list of available parties
        const coupledWorkflowRelationship =
          workflow.state.coupledWorkflowId &&
          (party.relationships || []).find(
            (rel) => rel.workflowId === workflow.state.coupledWorkflowId,
          );

        if (!party.parentId && coupledWorkflowRelationship) {
          const partyRecord = {
            ...party,
            relationships: (party.relationships || []).filter(
              (rel) => rel.workflowId !== workflow.id,
            ), // Remove relationships to the current workflow
          } as Party;
          const nextAvailableParties: ImportableParties = defaultsDeep(
            { children: {}, otherParties: {} },
            workflow.availableParties,
          );

          if (
            coupledWorkflowRelationship.relationship.type ===
            RelationshipType.OTHER
          ) {
            nextAvailableParties.otherParties![party.partyId] = partyRecord;
          } else {
            nextAvailableParties.children![party.partyId] = partyRecord;
          }
          workflow.availableParties = nextAvailableParties;
        }
      })
      .addCase(renderedDocumentsLoaded, (allWorkflows, { payload }) => {
        const { workflowId, documents } = payload;
        const workflow: Workflow | undefined =
          allWorkflows[workflowId] || undefined;
        if (!workflow) return allWorkflows;
        workflow.documents = documents;
      })
      .addCase(documentsDownloadDetailsLoaded, (allWorkflows, { payload }) => {
        const { workflowId, downloadDetails } = payload;
        const workflow: Workflow | undefined =
          allWorkflows[workflowId] || undefined;
        if (!workflow) return allWorkflows;
        workflow.documentsToDownload = downloadDetails;
      })
      .addCase(lawyerReviewStarted, (allWorkflows, { payload }) => ({
        ...allWorkflows,
        [payload.id]: {
          ...allWorkflows[payload.id],
          ...payload,
        },
      }))
      .addCase(partnerSelected, () => ({}))
      .addCase(userLoggedOut, () => ({})),
);

export const validationErrors = createReducer<Record<
  WorkflowID,
  Record<QuestionID, string[]>
> | null>(null, (builder) =>
  builder
    .addCase(saveAnswersInitiated, () => null)
    .addCase(workflowDetailsFetched, () => null)
    .addCase(invalidAnswers, (_, { payload }) => payload),
);

export const questionnairePage = createReducer<string | null>(null, (builder) =>
  builder
    .addCase(changePage, (_, { payload }) => payload.pageTitle)
    .addCase(LOCATION_CHANGE, () => null),
);

export const isLoadingRenderedDocuments = createReducer<boolean>(
  false,
  (builder) =>
    builder
      .addCase(loadDocumentsStarted, () => true)
      .addCase(loadDocumentsFailed, () => false)
      .addCase(loadDocumentsCancelled, () => false)
      .addCase(loadDocumentsDownloadDetailsStarted, () => true)
      .addCase(loadDocumentsDownloadDetailsFailed, () => false)
      .addCase(documentsDownloadDetailsLoaded, () => false),
);

export const unsavedInterviewAnswers =
  createReducer<AnswersByQuestionnaireType | null>(null, (builder) =>
    builder
      .addCase(interviewAnswersChanged, (_, { payload }) => payload)
      .addCase(changePage, () => null),
  );
