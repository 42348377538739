import firebase from "firebase/compat/app";
import React from "react";
import Login from "./components/Login";

interface LoginScreenProps {
  authProvider: firebase.auth.Auth;
  userJurisdiction?: string;
}

const LoginScreen = ({ authProvider, userJurisdiction }: LoginScreenProps) => (
  <Login authProvider={authProvider} userJurisdiction={userJurisdiction} />
);

export default LoginScreen;
