import * as React from "react";
import classNames from "classnames/bind";

import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type SummaryProps = {
  disabled?: boolean;
  id?: string;
  label?: string;
  onClick?: () => void;
  tabIndex?: number;
};

const Summary: React.FunctionComponent<SummaryProps> = (props) => {
  let className = cx("summary", {
    "summary--disabled": props.disabled,
  });
  return (
    <TabbedButton
      ariaLabel={props.label}
      className={className}
      id={props.id}
      onClick={props.onClick}
      tabIndex={props.disabled ? -1 : props.tabIndex ? props.tabIndex : 0}
    >
      <div className={styles["summary__inner"]} title={props.label}>
        {props.label}
      </div>
    </TabbedButton>
  );
};

export default Summary;
