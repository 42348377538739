import React from "react";
import classNames from "classnames/bind";

import TabbedButton from "components/TabbedButton";
import Product from "modules/product/types/Product";

import styles from "./styles.module.scss";
import Icon, { IconType } from "components/Icons";
import currencyFormatter from "utils/currencyFormatter";

const cx = classNames.bind(styles);

type ProductSelectorItemSmallProps = {
  disabled?: boolean;
  buttonLabel: string;
  onSelectBasic: (item: Product) => any;
  item: Product;
};

function ProductSelectorItemSmall({
  disabled,
  buttonLabel,
  onSelectBasic,
  item,
}: ProductSelectorItemSmallProps) {
  const classNames = cx("product-selector-item-small", {
    "product-selector-item-small--disabled": disabled,
  });

  const { name, price } = item;

  return (
    <div className={classNames} data-tid={item.id}>
      <div>
        <h3 className={styles["product-selector-item-small__name"]}>
          <Icon type={IconType.RIGHT_CHEVRON} /> {name + " Only"}
        </h3>
        <span className={styles["product-selector-item-small__price"]}>
          From <strong>{currencyFormatter.format(price.regular)}</strong>
        </span>
      </div>
      <div className={styles["product-selector-item-small__button-container"]}>
        <TabbedButton
          disabled={Boolean(disabled)}
          onClick={() => onSelectBasic(item)}
          label={buttonLabel}
        />
      </div>
    </div>
  );
}

export default ProductSelectorItemSmall;
