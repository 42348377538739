import * as React from "react";
import { useState } from "react";
import classNames from "classnames/bind";

import {
  PageProgress,
  WorkflowStatus,
  WorkflowStep,
} from "modules/api/types/WorkflowPayload";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import ClientDashboardProductsItemBreakdown from "../ClientDashboardProductsItemBreakdown";

import styles from "./styles.module.scss";
import ClientDashboardProductsItemHistory from "../ClientDashboardProductsItemHistory";
import actionCategories from "../../actionCategories";
import { IconType } from "components/Icons";

const cx = classNames.bind(styles);

type ClientDashboardProductsItemActionProps = {
  currentPageIdx: number;
  history: WorkflowStep[];
  itemState: WorkflowStatus;
  noInterview?: boolean;
  onlineProduct?: boolean;
  onOpenWorkflow: (pageIdx?: number | undefined) => any;
  onProceedToDownload: () => any;
  onProceedToSummary: () => any;
  onViewParties: () => any;
  pageProgresses: PageProgress[];
  started: boolean | null;
};

const getSpecificWorkflowActions = (
  currentPageIdx: number,
  started: boolean,
  itemState: WorkflowStatus,
  history: WorkflowStep[],
  showHistory: boolean,
  noInterview: boolean,
  onlineProduct: boolean,
  onOpenWorkflow: (pageIdx?: number | undefined) => any,
  onProceedToSummary: () => any,
  onProceedToDownload: () => any,
  toggleHistory: (show: boolean) => void,
) => {
  if (!started)
    return (
      <div className={styles["client-dashboard-products-item-action__row"]}>
        <TabbedButton
          label="Get started"
          mood={TabbedButtonMood.POSITIVE}
          onClick={() => onOpenWorkflow()}
        />
      </div>
    );

  switch (itemState) {
    case WorkflowStatus.PURCHASED:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>
            This product has been purchased and is now locked. If you have any
            queries regarding this please contact our offices.
          </p>
          <div
            className={
              styles["client-dashboard-products-item-action__collapsible"]
            }
          >
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              style={TabbedButtonType.SECONDARY}
              icon={showHistory ? IconType.SMALL_PLUS : IconType.SMALL_MINUS}
              label={"View history"}
              onClick={() => toggleHistory(!showHistory)}
            />
          </div>
          {!showHistory && (
            <ClientDashboardProductsItemHistory history={history} />
          )}
        </div>
      );

    case WorkflowStatus.PAYMENT_REQUIRED:
      return noInterview ? (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Awaiting your payment</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"Proceed to payment"}
              onClick={() => onOpenWorkflow()}
            />
          </div>
        </div>
      ) : (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>
            Your interview is complete. You can review it again and then proceed
            to payment.
          </p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"Continue"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.ADDITIONAL_PAYMENT_REQUIRED:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Your interview was reviewed and requires an additional payment.</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"Review & Pay"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.PAYMENT_PROCESSING:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>We are processing your payment...</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_SPOUSE_INTERVIEW:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>
            Spouse’s interview is still in progress. Once their interview is
            finished payment will be taken for both.
          </p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_ENGAGEMENT_CHECK:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Your engagement agreement is being reviewed by our lawyers.</p>
          <div>
            <TabbedButton
              style={TabbedButtonType.NEUTRAL}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.ENGAGEMENT_REJECTED:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>
            Your engagement agreement was rejected. An attorney will contact you
            to discuss next steps.
          </p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.NEGATIVE}
              style={TabbedButtonType.SECONDARY}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_LAWYER_REVIEW:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Your documents are being reviewed by our lawyers.</p>
          <div>
            <TabbedButton
              style={TabbedButtonType.NEUTRAL}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.LAWYER_REVIEW_IN_PROGRESS:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Your documents are being reviewed by our lawyers.</p>
          <div>
            <TabbedButton
              style={TabbedButtonType.NEUTRAL}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_REVIEW:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Lawyer review finished. Please, approve changes.</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.NEGATIVE}
              style={TabbedButtonType.PRIMARY}
              label={"Review"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_COUPLED_REVIEW:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Awaiting your review of the coupled interview</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.DOCUMENTS_READY:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>
            {onlineProduct
              ? "Your assessment is ready"
              : "Your documents are ready for download"}
          </p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={
                onlineProduct ? "View the details" : "Download your documents"
              }
              onClick={() => onProceedToDownload()}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_INPUT:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Awaiting your input.</p>
          <div>
            <TabbedButton
              label="Provide missing information"
              mood={TabbedButtonMood.NEGATIVE}
              style={TabbedButtonType.PRIMARY}
              onClick={() => onOpenWorkflow(currentPageIdx)}
            />
          </div>
        </div>
      );

    case WorkflowStatus.AWAITING_COUPLED_INPUT:
      return (
        <div
          className={styles["client-dashboard-products-item-action__prompt"]}
        >
          <p>Awaiting your input to spouse's interview</p>
          <div>
            <TabbedButton
              mood={TabbedButtonMood.POSITIVE}
              label={"View interview summary"}
              onClick={() => onProceedToSummary()}
            />
          </div>
        </div>
      );

    default:
      return null;
  }
};

const ClientDashboardProductsItemAction: React.FunctionComponent<
  ClientDashboardProductsItemActionProps
> = ({
  currentPageIdx,
  history,
  itemState,
  noInterview,
  onlineProduct,
  onOpenWorkflow,
  onProceedToDownload,
  onProceedToSummary,
  onViewParties,
  pageProgresses,
  started,
}) => {
  const [openBreakdown, setOpenBreakdown] = useState(false);
  const [showHistory, setShowHistory] = useState(
    itemState === WorkflowStatus.PURCHASED,
  );

  let classNames = cx("client-dashboard-products-item-action", {
    "client-dashboard-products-item-action--valid":
      actionCategories.ok.includes(itemState),
    "client-dashboard-products-item-action--warning":
      actionCategories.warn.includes(itemState),
    "client-dashboard-products-item-action--error":
      actionCategories.alert.includes(itemState),
  });

  const actions = getSpecificWorkflowActions(
    currentPageIdx,
    Boolean(started),
    itemState,
    history,
    showHistory,
    Boolean(noInterview),
    Boolean(onlineProduct),
    onOpenWorkflow,
    onProceedToSummary,
    onProceedToDownload,
    setShowHistory,
  );

  return (
    <div className={classNames}>
      {actions ? (
        actions
      ) : (
        <>
          <div className={styles["client-dashboard-products-item-action__row"]}>
            <>
              <TabbedButton
                icon={
                  openBreakdown ? IconType.SMALL_MINUS : IconType.SMALL_PLUS
                }
                borderedIcon
                label={
                  openBreakdown ? "Hide full breakdown" : "View full breakdown"
                }
                onClick={() => setOpenBreakdown(!openBreakdown)}
                style={TabbedButtonType.SECONDARY}
              />
              <TabbedButton
                label="Continue"
                mood={TabbedButtonMood.POSITIVE}
                onClick={() => onOpenWorkflow(currentPageIdx)}
              />
            </>
          </div>
          {openBreakdown && (
            <ClientDashboardProductsItemBreakdown
              pageProgresses={pageProgresses}
              currentPageIdx={currentPageIdx}
              onEditPage={onOpenWorkflow}
              onViewParties={onViewParties}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ClientDashboardProductsItemAction;
