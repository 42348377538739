import * as React from "react";
import classNames from "classnames/bind";
import getRelationshipToClient from "modules/parties/helpers/getRelationshipToClient";
import { GenericParty, getFullName } from "modules/parties/types/Party";
import Client from "modules/parties/types/Client";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type QuestionPerPartyItemProps = {
  client?: Client;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  metaInput?: JSX.Element;
  children?: any;
  name: string;
  party: GenericParty;
};

const QuestionPerPartyItem: React.FunctionComponent<
  QuestionPerPartyItemProps
> = ({ children, client, disabled, error, id, metaInput, name, party }) => {
  const className = cx("question-per-party-meta-item", {
    "question-per-party-meta-item--disabled": disabled,
    "question-per-party-meta-item--invalid": !disabled && error,
  });
  const relationship = getRelationshipToClient(party, client);
  return (
    <li className={className}>
      <div className={styles["question-per-party-meta-item__row"]}>
        <div className={styles["question-per-party-meta-item__col"]}>
          <div
            className={styles["question-per-party-meta-item__name"]}
            data-clarity-mask="true"
          >
            {getFullName(party)}
          </div>
          {relationship ? (
            <div className={styles["question-per-party-meta-item__info"]}>
              <span className={styles["question-per-party-meta-item__label"]}>
                Relationship:
              </span>{" "}
              {relationship}
            </div>
          ) : null}
        </div>
        <div className={styles["question-per-party-meta-item__input"]}>
          {metaInput}
        </div>
      </div>
      {children ? (
        <div className={styles["question-per-party-meta-item__row"]}>
          <div className={styles["question-per-party-meta-item__body"]}>
            {children}
          </div>
        </div>
      ) : null}
    </li>
  );
};

export default QuestionPerPartyItem;
