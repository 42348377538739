import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";

type Props = { html: string };

const SanitizeHtml: React.FC<Props> = ({ html }) => {
  const [sanitizedHtml, setSanitizedHtml] = useState("");

  useEffect(() => {
    setSanitizedHtml(sanitizeHtml(html));
  }, [html]);

  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default SanitizeHtml;
