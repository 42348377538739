import React from "react";
import type { CurrentProtectionProps } from "./types";
import ProtectedAssetsSlider from "components/ProtectedAssetsSlider";
import {
  getCurrentlyProtected,
  getTotalAssetsAtRisk,
} from "screens/medicaidAnalysis/helpers";

const CurrentProtection = ({
  calculation,
  className,
}: CurrentProtectionProps) => {
  const totalAssetsAtRisk = getTotalAssetsAtRisk(calculation);

  const currentlyProtected = getCurrentlyProtected(calculation);

  return (
    <div className={className}>
      <ProtectedAssetsSlider
        totalAmount={totalAssetsAtRisk}
        protectedAmount={currentlyProtected}
        title="Currently Protected Assets"
        progressTransition
      />
    </div>
  );
};

export default CurrentProtection;
