import * as React from "react";
import styles from "./styles.module.scss";

type FieldsetRowProps = {};

export const FieldsetRow: React.FunctionComponent<FieldsetRowProps> = (
  props,
) => {
  return <div className={styles["fieldset__row"]}>{props.children}</div>;
};

type FieldsetProps = {
  title?: string;
  text?: string | JSX.Element;
  children?: React.ReactNode;
};

const Fieldset = (props: FieldsetProps) => (
  <fieldset className={styles.fieldset}>
    {props.title && (
      <legend className={styles["fieldset__legend"]}>
        <h2 className={styles["fieldset__title"]}>{props.title}</h2>
      </legend>
    )}
    {props.text && (
      <p className={styles["fieldset__description"]}>{props.text}</p>
    )}
    {props.children}
  </fieldset>
);

export default Fieldset;
