import * as React from "react";
import clamp from "lodash/clamp";
import classNames from "classnames/bind";

import { Share } from "..";

import styles from "./styles.module.scss";
import CheckboxLink from "components/Forms/Inputs/Checkbox/CheckboxLink";

const cx = classNames.bind(styles);

type QuestionShareDistributionBarProps = {
  disabled?: boolean;
  error?: [] | string;
  isEqualDistribution?: boolean;
  shares: Share[];
  onDistributeEqually: () => any;
  onResetDistributions: () => any;
};

const getShare = (share: Share): number => {
  return typeof share.share === "string" && !Number.isNaN(Number(share.share))
    ? Number(share.share)
    : typeof share.share === "number"
      ? share.share
      : 0;
};

const QuestionShareDistributionBar: React.FunctionComponent<
  QuestionShareDistributionBarProps
> = ({
  disabled,
  error,
  isEqualDistribution,
  shares,
  onDistributeEqually,
  onResetDistributions,
}) => {
  const percentage = isEqualDistribution
    ? 100
    : shares.length &&
      shares.reduce((acc, share) => {
        return acc + getShare(share);
      }, 0);
  const message = 100 - percentage;
  const className = cx("question-share-distribution-bar", {
    "question-share-distribution-bar--disabled": disabled,
    "question-share-distribution-bar--invalid": !disabled && error,
    "question-share-distribution-bar--valid": percentage === 100 && !error,
  });

  const toggleEqualDistribution = React.useCallback(
    () =>
      isEqualDistribution ? onResetDistributions() : onDistributeEqually(),
    [isEqualDistribution, onDistributeEqually, onResetDistributions],
  );

  return (
    <React.Fragment>
      <div className={cx("question-share-distribution")}>
        <div className={className}>
          <div
            className={styles["question-share-distribution-bar__bar"]}
            style={{ width: clamp(percentage, 0, 100) + "%" }}
          />
          <p>{message}% remaining</p>
        </div>
        <div className={styles["question-share-distribution-bar__controls"]}>
          <CheckboxLink
            label="Distribute equally"
            onClick={toggleEqualDistribution}
            selected={isEqualDistribution}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuestionShareDistributionBar;
