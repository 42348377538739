import React from "react";
import { User } from "modules/auth/User";

import MenuLink from "../MenuLink";
import HeaderNavigation, { HeaderNavigationLink } from "../HeaderNavigation";
import HeaderProfile from "../HeaderProfile";

type HeaderDesktopProps = {
  hideNavigationMenu?: boolean;
  hideProfileMenu?: boolean;
  mainNavigationLinks: MenuLink[];
  onLogin: () => any;
  onLogout: () => any;
  onStateChange?: () => void;
  profileMenuLinks: MenuLink[];
  selectedState?: string;
  user: User | null;
};

const HeaderDesktop: React.FunctionComponent<HeaderDesktopProps> = ({
  hideNavigationMenu,
  hideProfileMenu,
  mainNavigationLinks,
  onLogin,
  onLogout,
  onStateChange,
  profileMenuLinks,
  selectedState,
  user,
}) => {
  return (
    <React.Fragment>
      {!hideNavigationMenu && (
        <HeaderNavigation>
          {mainNavigationLinks.map((link, idx) => (
            <HeaderNavigationLink key={`main_menu_${idx}`} {...link} />
          ))}
        </HeaderNavigation>
      )}
      {!hideProfileMenu && (
        <HeaderProfile
          user={user}
          onLogout={onLogout}
          onLogin={onLogin}
          onStateChange={onStateChange}
          profileMenuLinks={profileMenuLinks}
          selectedState={selectedState}
        />
      )}
    </React.Fragment>
  );
};

export default HeaderDesktop;
