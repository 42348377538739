import { keyBy, mapValues } from "lodash";
import logger, { isVerbose } from "logger";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import getAge from "modules/parties/helpers/getAge";
import { findRelationship } from "modules/parties/helpers/getRelationshipToClient";
import isUnderage from "modules/parties/helpers/isUnderage";
import Client, { ClientType } from "modules/parties/types/Client";
import Party, {
  ChildRelationship,
  RelationshipType,
  WhoseChild,
} from "modules/parties/types/Party";
import Addon, { AddonID } from "modules/product/types/Addon";
import moment from "moment";
import { AnswersByQuestionnaireType } from "../types/Answer";
import Condition, {
  AddonOfferCondition,
  isAddonCondition,
  isAddonOfferCondition,
  isAllCondition,
  isAnyCondition,
  isNotCondition,
  isWorkflowCondition,
  NotCondition,
} from "../types/Condition";
import {
  ChildConstraint,
  isChildCondition,
} from "../types/Condition/ChildCondition";
import {
  ClientDetailsConstraint,
  ClientDetailsConstraintType,
  isClientCondition,
  isSpouseCondition,
} from "../types/Condition/ClientDetailsCondition";
import ValueCondition, {
  ValueConditionConstraint,
} from "../types/Condition/ValueCondition";
import { isContainsConstraint } from "../types/Condition/ValueCondition/ContainsConstraint";
import {
  DiffType,
  isDateDiffConstraint,
} from "../types/Condition/ValueCondition/DatediffConstraint";
import { isSelectMoreThanCondition } from "../types/Condition/ValueCondition/SelectedMoreThanConstraint";
import WorkflowDetailsCondition, {
  WorkflowDetailsConstraintType,
} from "../types/Condition/WorkflowDetailsCondition";
import {
  QuestionType,
  Question,
  QuestionnaireSection,
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
  QuestionsMap,
  QuestionDefinitionSelect,
  QuestionSelectValue,
  QuestionDescription,
} from "../types/Questionnaire";
import { isAnswered } from "./getFilledQuestionsByQuestionnaire";
import {
  getPageQuestionsIDs,
  getQuestionIDsByQuestionnaire,
} from "./getPageQuestions";
import getQuestionsForIDs from "./getQuestionsForIDs";
import getSelectedAddons from "./getSelectedAddons";
import Gender from "modules/parties/types/Gender";
import disableQuestions from "./disableQuestions";

const satisfiesText = (conditionValue: any, currentAnswer: any): boolean => {
  const sanitizedAnswer = (currentAnswer || "").trim();
  if (!conditionValue) {
    return !Boolean(sanitizedAnswer);
  }
  if (conditionValue === true) {
    return Boolean(sanitizedAnswer);
  }
  return conditionValue === sanitizedAnswer;
};

const satisfiesNumeric = (
  conditionValue: any,
  currentAnswer: any,
  integer?: boolean,
): boolean => {
  const sanitizedAnswer =
    typeof currentAnswer === "string"
      ? Number.parseFloat(currentAnswer)
      : currentAnswer;
  if (!conditionValue) {
    return !Number.isFinite(sanitizedAnswer);
  }
  if (conditionValue === true) {
    return integer
      ? Number.isInteger(sanitizedAnswer)
      : Number.isFinite(sanitizedAnswer);
  }
  const sanitizedCondition =
    typeof conditionValue === "string"
      ? Number.parseFloat(conditionValue)
      : conditionValue;
  if (
    !Number.isFinite(sanitizedCondition) ||
    (integer && !Number.isInteger(sanitizedCondition))
  ) {
    return true;
  }
  return sanitizedCondition === sanitizedAnswer;
};

const satisfiesSelect = (
  conditionValue: ValueConditionConstraint,
  currentAnswer: any,
): boolean => {
  if (!conditionValue || (conditionValue as any).length === 0) {
    return !isAnswered(currentAnswer);
  }

  if (isSelectMoreThanCondition(conditionValue)) {
    return currentAnswer?.length > conditionValue.selectedMoreThan;
  }
  if (isContainsConstraint(conditionValue)) {
    return (
      Array.isArray(currentAnswer) &&
      currentAnswer.includes(conditionValue.value)
    );
  }
  if (conditionValue === true) {
    return Boolean(currentAnswer);
  }
  if (Array.isArray(conditionValue)) {
    return conditionValue.includes(currentAnswer);
  }
  return conditionValue === currentAnswer;
};

const satisfiesBoolean = (conditionValue: any, currentAnswer: any): boolean => {
  const blankValues = [null, undefined, ""];

  if (blankValues.includes(conditionValue)) {
    return blankValues.includes(currentAnswer);
  }

  return String(conditionValue) === String(currentAnswer);
};

const satisfiesDate = (
  conditionValue: ValueConditionConstraint,
  currentAnswer: any,
): boolean => {
  const enteredDate = currentAnswer
    ? moment(currentAnswer, "YYYY-MM-DD", true)
    : null;

  if (enteredDate?.isValid() && isDateDiffConstraint(conditionValue)) {
    const diffUnit =
      conditionValue.unit.toLowerCase() as moment.unitOfTime.Diff;
    const diff = moment().diff(enteredDate, diffUnit);

    switch (conditionValue.op) {
      case DiffType.LOWER:
        return diff < conditionValue.value;

      case DiffType.LOWER_OR_EQUAL:
        return diff <= conditionValue.value;

      case DiffType.EQUAL:
        return diff === conditionValue.value;

      case DiffType.GREATER_OR_EQUAL:
        return diff >= conditionValue.value;

      case DiffType.GREATER:
        return diff > conditionValue.value;
    }
  }

  return currentAnswer === conditionValue;
};

const checkQuestion = (
  question: Question | undefined,
  conditionValue: ValueConditionConstraint,
  currentAnswer: any,
): boolean => {
  switch (question?.data.definition.type) {
    case QuestionType.GENDER:
    case QuestionType.TEXT:
      return satisfiesText(conditionValue, currentAnswer);

    case QuestionType.NUMERIC:
      return satisfiesNumeric(
        conditionValue,
        currentAnswer,
        question.data.definition.integral,
      );

    case QuestionType.SELECT:
    case QuestionType.RELATIONSHIP:
      return satisfiesSelect(conditionValue, currentAnswer);

    case QuestionType.FLAG:
      return satisfiesBoolean(conditionValue, currentAnswer);

    case QuestionType.DATE:
      return satisfiesDate(conditionValue, currentAnswer);

    // TODO
    // case QuestionType.CURRENCY:

    default:
      return conditionValue === currentAnswer;
  }
};

const satisfiesValueCondition = (
  valueCondition: ValueCondition,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
) => {
  for (const [parentQuestionnaireId, condition] of Object.entries(
    valueCondition,
  )) {
    for (const [parentQuestionId, parentValue] of Object.entries(condition)) {
      const parentQuestion =
        allQuestions[parentQuestionnaireId] &&
        allQuestions[parentQuestionnaireId][parentQuestionId];
      const parentConditions = parentQuestion?.data.condition;

      // Check parent conditions recursively
      if (
        parentConditions &&
        !satisfiesCondition(
          parentConditions,
          allQuestions,
          answers,
          selectedAddons,
          clients,
          workflowState,
          allChildren,
        )
      ) {
        return false;
      }

      if (
        !checkQuestion(
          parentQuestion,
          parentValue,
          answers[parentQuestionnaireId] &&
            answers[parentQuestionnaireId][parentQuestionId],
        )
      ) {
        return false;
      }
    }
  }
  return true;
};

const satisfiesClientCondition = (
  clientDetailsConstraint: ClientDetailsConstraint,
  client: Client | undefined,
) => {
  switch (clientDetailsConstraint.type) {
    case ClientDetailsConstraintType.State:
      return client?.address?.state === clientDetailsConstraint.value;

    case ClientDetailsConstraintType.IsMarried: {
      return client?.isMarried === true;
    }

    // Given IsGender(Female), this condition will return true for users whose
    // pronouns are they/them (see GDR-2386)
    case ClientDetailsConstraintType.IsGender: {
      return clientDetailsConstraint.value === Gender.FEMALE
        ? client?.gender === Gender.FEMALE || client?.gender === Gender.OTHER
        : clientDetailsConstraint.value === client?.gender;
    }

    case ClientDetailsConstraintType.IsOlderThan: {
      return Boolean(
        client?.dateOfBirth &&
          getAge(client?.dateOfBirth) > clientDetailsConstraint.age,
      );
    }

    default:
      return false;
  }
};

const isChildRelevant = (child: Party, client: Client | undefined) => {
  const relationship = findRelationship(
    child,
    client,
    RelationshipType.CHILD,
  ) as ChildRelationship | undefined;
  return (
    relationship &&
    child.dateOfBirth &&
    (!child.deceased ||
      (child.children?.length && relationship?.treatGrandchildrenAsOwn)) &&
    (relationship.whoseChild !== WhoseChild.MY_PARTNERS ||
      relationship.treatAsOwn)
  );
};

export const satisfiesChildCondition = (
  client: Client | undefined,
  childConstraint: ChildConstraint,
  allChildren: Party[],
) => {
  switch (childConstraint) {
    case ChildConstraint.UnderstatedAgeChildExists: {
      const underageChild = allChildren.find(
        (child) => isChildRelevant(child, client) && isUnderage(child),
      );
      return Boolean(underageChild);
    }

    case ChildConstraint.ChildExists: {
      return Boolean(
        allChildren.find((child) => isChildRelevant(child, client)),
      );
    }

    default:
      return false;
  }
};

const satisfiesWorkflowCondition = (
  workflowDetailsCondition: WorkflowDetailsCondition,
  workflowState?: WorkflowPayloadState,
) => {
  switch (workflowDetailsCondition.type) {
    case WorkflowDetailsConstraintType.IsCoupled:
      return (
        Boolean(workflowState?.coupledWorkflowId) ===
        workflowDetailsCondition.value
      );
    case WorkflowDetailsConstraintType.JurisdictionMatches:
      return workflowState?.maybeUserState === workflowDetailsCondition.value;
    default:
      return false;
  }
};

const satisfiesNotCondition = (
  condition: NotCondition,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: Record<ClientType, Client | undefined>,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
) =>
  !satisfiesCondition(
    condition.not,
    allQuestions,
    answers,
    selectedAddons,
    clients,
    workflowState,
    allChildren,
  );

const satisfiesAddonOfferCondition = (
  condition: AddonOfferCondition,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: Record<ClientType, Client | undefined>,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
  allowedAddons?: AddonID[],
) =>
  allowedAddons && allowedAddons.length > 0
    ? allowedAddons.includes(
        Object.entries(condition)?.[0]?.[1]?.addonOffer || "",
      )
    : true;

export const satisfiesCondition = (
  condition: Condition,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
  allowedAddons?: AddonID[],
): boolean => {
  if (isAnyCondition(condition)) {
    return Boolean(
      condition.any.find((condition) =>
        satisfiesCondition(
          condition,
          allQuestions,
          answers,
          selectedAddons,
          clients,
          workflowState,
          allChildren,
          allowedAddons,
        ),
      ),
    );
  } else if (isAllCondition(condition)) {
    return !Boolean(
      condition.all.find(
        (condition) =>
          !satisfiesCondition(
            condition,
            allQuestions,
            answers,
            selectedAddons,
            clients,
            workflowState,
            allChildren,
            allowedAddons,
          ),
      ),
    );
  } else if (isAddonCondition(condition)) {
    const { addon: addonId } = condition;
    const addonOfferQuestion = Object.values(allQuestions)
      .flatMap((questionsMap) => Object.values(questionsMap))
      .find(
        (question) =>
          question.data.definition.type === QuestionType.ADDON &&
          question.data.definition.addonId === addonId,
      );
    const addonOfferQuestionIsHidden =
      addonOfferQuestion !== undefined &&
      addonOfferQuestion.data.condition !== undefined &&
      !satisfiesCondition(
        addonOfferQuestion.data.condition,
        allQuestions,
        answers,
        selectedAddons,
        clients,
        workflowState,
        allChildren,
      );
    return (
      !addonOfferQuestionIsHidden &&
      Boolean(selectedAddons.find((addon) => addon.id === addonId))
    );
  } else if (isClientCondition(condition)) {
    return satisfiesClientCondition(
      condition.client,
      clients && clients[ClientType.MAIN],
    );
  } else if (isSpouseCondition(condition)) {
    return satisfiesClientCondition(
      condition.spouse,
      clients && clients[ClientType.SPOUSE],
    );
  } else if (isChildCondition(condition)) {
    return satisfiesChildCondition(
      clients?.main,
      condition.child,
      allChildren || [],
    );
  } else if (isWorkflowCondition(condition)) {
    return satisfiesWorkflowCondition(condition.workflow, workflowState);
  } else if (isNotCondition(condition)) {
    return satisfiesNotCondition(
      condition,
      allQuestions,
      answers,
      selectedAddons,
      clients,
      workflowState,
      allChildren,
    );
  } else if (isAddonOfferCondition(condition)) {
    return satisfiesAddonOfferCondition(
      condition,
      allQuestions,
      answers,
      selectedAddons,
      clients,
      workflowState,
      allChildren,
      allowedAddons,
    );
  } else {
    return satisfiesValueCondition(
      condition as ValueCondition,
      allQuestions,
      answers,
      selectedAddons,
      clients,
      workflowState,
      allChildren,
    );
  }
};
type ConditionalType =
  | Question
  | QuestionnairePage
  | QuestionnaireSection
  | QuestionSelectValue
  | QuestionDescription;
const isQuestion = (item: ConditionalType): item is Question =>
  item.hasOwnProperty("data");
const isPage = (item: ConditionalType): item is QuestionnairePage =>
  item.hasOwnProperty("sections");
const isSection = (item: ConditionalType): item is QuestionnaireSection =>
  item.hasOwnProperty("questions");
const isSelectValue = (item: ConditionalType): item is QuestionSelectValue =>
  item.hasOwnProperty("value");

export function filterItemsByCondition<T extends ConditionalType>(
  items: T[],
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
  getCondition: (item: T) => any = (item) => (item as any).condition,
  allowedAddons?: AddonID[],
): T[] {
  return items.filter((item) => {
    const itemCondition = getCondition(item);

    const satisfies =
      itemCondition &&
      satisfiesCondition(
        itemCondition,
        allQuestions,
        answers,
        selectedAddons,
        clients,
        workflowState,
        allChildren,
        allowedAddons,
      );

    if (isVerbose && itemCondition && !satisfies) {
      if (isPage(item)) {
        logger.verbose(
          `[COND] Page ${item.questionnaireId}/${
            item.index || item.id || item.caption
          }' with questions:`,
          getPageQuestionsIDs(item),
          "excluded due to unsatisfied condition:",
          itemCondition,
        );
      } else if (isSection(item)) {
        logger.verbose(
          `[COND] Section '${item.caption}' with questions`,
          item.questions || [],
          "excluded due to unsatisfied condition:",
          itemCondition,
        );
      } else if (isQuestion(item)) {
        logger.verbose(
          `[COND] Question ${item.id} excluded due to unsatisfied condition:`,
          itemCondition,
        );
      } else if (isSelectValue(item)) {
        logger.verbose(
          `[COND] Select value ${item.value} excluded due to unsatisfied condition:`,
          itemCondition,
        );
      } else {
        logger.verbose(
          `[COND] Item ${item} excluded due to unsatisfied condition:`,
          itemCondition,
        );
      }
    }

    return !itemCondition || satisfies;
  });
}

const getFilteredQuestionID = (
  questionID: string,
  filteredQuestions: QuestionsMap,
) => (Boolean(filteredQuestions[questionID]) ? questionID : null);

export const unassignFilteredQuestions = (
  section: QuestionnaireSection,
  filteredQuestions: QuestionsMap,
): QuestionnaireSection => {
  const questions = section.questions
    ?.map((questionRow) => {
      if (typeof questionRow === "string") {
        return getFilteredQuestionID(questionRow, filteredQuestions);
      }
      return questionRow
        .map((questionID) =>
          getFilteredQuestionID(questionID, filteredQuestions),
        )
        .filter(Boolean) as string[];
    })
    .filter((row) => row !== null && row.length > 0) as Array<
    string | string[]
  >;
  return {
    ...section,
    questions,
  };
};

const processSelectQuestionDefinition = (
  definition: QuestionDefinitionSelect,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
): QuestionDefinitionSelect => {
  const filteredValues = filterItemsByCondition(
    definition.values,
    allQuestions,
    answers,
    selectedAddons,
    clients,
    workflowState,
    allChildren,
  );

  return {
    ...definition,
    values: filteredValues,
  };
};

export const processQuestionDescriptions = (
  question: Question,
  allQuestions: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
): Question => {
  const { descriptions } = question.data;
  const applicableDescriptions = filterItemsByCondition(
    descriptions,
    allQuestions,
    answers,
    selectedAddons,
    clients,
    workflowState,
    allChildren,
  );

  // Conditional description has precedence
  const descriptionToUse: QuestionDescription | undefined =
    applicableDescriptions.find((desc) => Boolean(desc.condition)) ||
    applicableDescriptions[0];

  return {
    ...question,
    label: descriptionToUse?.label,
    description: descriptionToUse?.description,
  };
};

export const processQuestionDetails = (
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  selectedAddons: Addon[],
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  allChildren?: Party[],
): QuestionsMapByQuestionnaire =>
  mapValues(questionsByQuestionnaire, (questions, questionnaireId) =>
    mapValues(questions, (question) => {
      const preprocessedQuestion = processQuestionDescriptions(
        question,
        questionsByQuestionnaire,
        answers,
        selectedAddons,
        clients,
        workflowState,
        allChildren,
      );

      switch (preprocessedQuestion.data.definition.type) {
        case QuestionType.SELECT: {
          return {
            ...preprocessedQuestion,
            data: {
              ...preprocessedQuestion.data,
              definition: processSelectQuestionDefinition(
                preprocessedQuestion.data.definition,
                questionsByQuestionnaire,
                answers,
                selectedAddons,
                clients,
                workflowState,
                allChildren,
              ),
            },
          };
        }
      }

      return preprocessedQuestion;
    }),
  );

export type QuestionnaireDefinition = {
  pages: QuestionnairePage[];
  questions: QuestionsMapByQuestionnaire;
  selectedAddons: Addon[];
};

export const getQuestionnaireDefinition = (
  pages: QuestionnairePage[],
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire,
  answers: AnswersByQuestionnaireType,
  clients?: ClientsMap,
  workflowState?: WorkflowPayloadState,
  preselectedAddons?: Addon[],
  allChildren?: Party[],
  allowedAddons?: AddonID[],
  editable: boolean = true,
): QuestionnaireDefinition => {
  const selectedAddons = getSelectedAddons(
    questionsBySubquestionnaire,
    answers,
    preselectedAddons,
  );

  const filteredQuestions: QuestionsMapByQuestionnaire = mapValues(
    questionsBySubquestionnaire,
    (subquestionnaireQuestions) =>
      keyBy(
        filterItemsByCondition<Question>(
          Object.values(subquestionnaireQuestions),
          questionsBySubquestionnaire,
          answers,
          selectedAddons,
          clients,
          workflowState,
          allChildren,
          (question) => question.data?.condition,
          allowedAddons,
        ),
        "id",
      ),
  );

  const filteredPages: QuestionnairePage[] = filterItemsByCondition(
    pages,
    questionsBySubquestionnaire,
    answers,
    selectedAddons,
    clients,
    workflowState,
    allChildren,
    undefined,
    allowedAddons,
  )
    .map((page) => ({
      ...page,
      sections: filterItemsByCondition(
        page.sections,
        questionsBySubquestionnaire,
        answers,
        selectedAddons,
        clients,
        workflowState,
        allChildren,
        undefined,
        allowedAddons,
      )
        .map((section) =>
          unassignFilteredQuestions(
            section,
            filteredQuestions[page.questionnaireId],
          ),
        )
        .filter((section) => section.isStatic || section.questions?.length),
    }))
    .filter((page) => page.isStatic || page.sections.length);

  const relevantQuestions = getQuestionsForIDs(
    questionsBySubquestionnaire,
    getQuestionIDsByQuestionnaire(filteredPages),
  );

  const processedQuestions = processQuestionDetails(
    relevantQuestions,
    answers,
    selectedAddons,
    clients,
    workflowState,
    allChildren,
  );

  const maybeDisabledQuestions = !editable
    ? disableQuestions(processedQuestions)
    : processedQuestions;

  return {
    pages: filteredPages,
    questions: maybeDisabledQuestions,
    selectedAddons,
  };
};
