import firebase from "../../lib/firebase";
import "firebase/compat/storage";
import { v4 as uuid } from "uuid";

const storage = firebase.app().storage();

export const saveFile = async (
  partnerId: string,
  parentFolder: string,
  file: Blob | File,
  extension: string,
): Promise<string> => {
  const { metadata } = await storage
    .ref()
    .child(`${parentFolder}/${partnerId}/${uuid()}.${extension}`)
    .put(file);

  return `https://firebasestorage.googleapis.com/v0/b/${
    metadata.bucket
  }/o/${encodeURIComponent(metadata.fullPath)}?alt=media`;
};

export const deleteFile = (parentDir: string, filename: string) =>
  storage.ref().child(`${parentDir}/${filename}`).delete();
