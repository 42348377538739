import * as React from "react";
import classNames from "classnames/bind";

import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import Label from "components/Forms/Label";

import styles from "./styles.module.scss";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
const cx = classNames.bind(styles);

type SelectProps = {
  id?: string;
  isInvalid: boolean;
  isValid: boolean;
  label?: string;
  onValueChange: (value: string) => void;
  value: string;
  wrappingQuestionSize?: QuestionSizes;
};

const OtherOptionAdditionalInput: React.FunctionComponent<SelectProps> = ({
  id,
  isInvalid,
  isValid,
  label,
  onValueChange,
  value,
  wrappingQuestionSize,
}) => (
  <div
    className={cx({
      "other_input--fullwidth_parent":
        wrappingQuestionSize === QuestionSizes.FULLWIDTH,
      "other_input--large_parent": wrappingQuestionSize === QuestionSizes.LARGE,
    })}
  >
    <Label
      className={cx("other_input__label")}
      id={id}
      invalid={isInvalid}
      label={label || "Please enter the value:"}
    />
    <InputStringPrimitive
      id={id}
      invalid={isInvalid}
      onChangeValue={onValueChange}
      valid={isValid}
      value={value}
    />
  </div>
);

export default OtherOptionAdditionalInput;
