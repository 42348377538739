import React from "react";
import { ConsumerMedicaidLeadItemActionsProps } from "./types";
import { MedicaidLeadStatus } from "modules/medicaid/types";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { useDispatch } from "react-redux";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";

const ConsumerMedicaidLeadItemActions = ({
  item,
}: ConsumerMedicaidLeadItemActionsProps) => {
  const dispatch = useDispatch();

  const handleNavigateToInterview = () =>
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD, {
        medicaidLeadId: item.medicaidLead.medicaidLeadId,
      }),
    );

  const handleNavigateToAssetProtectionAnalysis = () =>
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.MEDICAID_ANALYSIS, {
        medicaidLeadId: item.medicaidLead.medicaidLeadId,
      }),
    );

  switch (item.medicaidLead.status) {
    case MedicaidLeadStatus.InterviewInProgress:
      return (
        <TabbedButton
          label="Continue"
          onClick={handleNavigateToInterview}
          mood={TabbedButtonMood.POSITIVE}
          style={TabbedButtonType.PRIMARY}
        />
      );
    case MedicaidLeadStatus.ReadyForPartner:
      return (
        <TabbedButton
          label="Asset Protection Analysis"
          onClick={handleNavigateToAssetProtectionAnalysis}
          mood={TabbedButtonMood.POSITIVE}
        />
      );
    case MedicaidLeadStatus.LeadAccepted:
    case MedicaidLeadStatus.LeadRejected:
      return (
        <TabbedButton
          label="View Interview"
          onClick={handleNavigateToInterview}
          style={TabbedButtonType.NEUTRAL}
        />
      );
  }
};

export default ConsumerMedicaidLeadItemActions;
