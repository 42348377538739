import React, { useState } from "react";
import classNames from "classnames/bind";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

export type SwitchProps = {
  disabled?: boolean;
  initialValue?: boolean;
  onChange?: (selected: boolean) => void;
  selected?: boolean;
};

const Switch: React.FC<SwitchProps> = ({
  disabled,
  initialValue,
  onChange,
  selected,
}) => {
  const [state, setState] = useState(initialValue || false);
  const isControlled = selected !== undefined;
  const isSelected = isControlled ? selected : state;

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();
    if (disabled) return;
    if (!isControlled) setState(!isSelected);
    onChange && onChange(!isSelected);
  };

  return (
    <TabbedButton
      className={cx("Switch", {
        "Switch--selected": isSelected,
        "Switch--disabled": disabled,
      })}
      onClick={handleClick}
    />
  );
};

export default Switch;
