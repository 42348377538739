import { FormSubmitHandle } from "components/Forms/Form";
import { FormikProps } from "formik";
import { AnswersMap } from "modules/workflow/types/Answer";
import * as React from "react";

import styles from "./styles.module.scss";

type PageHeaderProps = {
  title?: React.ReactNode;
  text?: React.ReactNode;
  submitHandle?: FormSubmitHandle;
  formHandle?: FormikProps<AnswersMap>;
  renderAction?: (
    submitHandle?: FormSubmitHandle,
    formHandle?: FormikProps<AnswersMap>,
  ) => JSX.Element | null;
  maskTitle?: boolean;
};

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({
  title,
  text,
  submitHandle,
  formHandle,
  renderAction,
  maskTitle,
}) => {
  return (
    <div className={styles["page-header-wrapper"]}>
      <div className={styles["page-header"]}>
        {title && (
          <h1
            className={styles["page-header__title"]}
            data-clarity-mask={maskTitle}
          >
            {title}
          </h1>
        )}
        {text && (
          <div className={styles["page-header__description"]}>{text}</div>
        )}
      </div>
      {renderAction && (
        <div className={styles["page-actions"]}>
          {renderAction(submitHandle, formHandle)}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
