import { findRelationship } from "modules/parties/helpers/getRelationshipToClient";
import suffixes from "modules/parties/helpers/suffixes";
import Gender from "modules/parties/types/Gender";
import { AnswersMap } from "modules/workflow/types/Answer";
import { ClientDetailsConstraintType } from "modules/workflow/types/Condition/ClientDetailsCondition";
import {
  Question,
  QuestionID,
  QuestionnaireSection,
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import moment from "moment";
import Client from "../../types/Client";
import Party, {
  ChildRelationship,
  PartyToSave,
  RelationshipType,
  WhoseChild,
} from "../../types/Party";

export const CHILD_QUESTIONNAIRE_ID = "childQuestionnaire";
export const GRANDCHILD_QUESTIONNAIRE_ID = "grandchildQuestionnaire";

const getBasicFields = (questionnaireId: string): Question[] => [
  {
    id: "firstName",
    data: {
      descriptions: [{ label: "First name" }],
      required: true,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "middleName",
    data: {
      descriptions: [{ label: "Middle name" }],
      required: false,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "lastName",
    data: {
      descriptions: [{ label: "Last name" }],
      required: true,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "suffix",
    data: {
      descriptions: [
        {
          label: "Suffix (optional)",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: suffixes.map((value) => ({ value })),
        radio: true,
        allowOther: true,
        otherLabel: "Please enter the suffix",
      },
      required: false,
    },
  },
  {
    id: "dateOfBirth",
    data: {
      descriptions: [{ label: "Date of birth" }],
      required: true,
      definition: {
        type: QuestionType.DATE,
        limits: {
          max: new Date().toISOString(),
          min: moment().subtract(99, "years").toISOString(),
        },
      },
    },
  },
  {
    id: "gender",
    data: {
      descriptions: [
        {
          label: "What are their pronouns?",
          description:
            "This helps us fill in the correct pronouns throughout your legal documents.",
        },
      ],
      required: true,
      definition: {
        type: QuestionType.SELECT,
        values: [
          { display: "he/him", value: Gender.MALE },
          { display: "she/her", value: Gender.FEMALE },
          { display: "they/them", value: Gender.OTHER },
        ],
        radio: true,
      },
    },
  },
];

const detailsFields: Question[] = [
  {
    id: "whoseChild",
    data: {
      descriptions: [{ label: "Whose child is it?" }],
      condition: { client: { type: ClientDetailsConstraintType.IsMarried } },
      required: true,
      definition: {
        type: QuestionType.SELECT,
        values: [
          { value: WhoseChild.OURS },
          { value: WhoseChild.MINE },
          { display: "My spouse's", value: WhoseChild.MY_PARTNERS },
        ],
        radio: true,
      },
    },
  },
  {
    id: "treatAsOwn",
    data: {
      descriptions: [
        {
          label:
            "Should stepchild be treated as your own child in your documents?",
        },
      ],
      condition: {
        [CHILD_QUESTIONNAIRE_ID]: { whoseChild: WhoseChild.MY_PARTNERS },
      },
      required: true,
      definition: { type: QuestionType.FLAG },
    },
  },
  {
    id: "isDeceased",
    data: {
      descriptions: [{ label: "Is this child deceased?" }],
      required: true,
      definition: { type: QuestionType.FLAG },
    },
  },
  {
    id: "disinherited",
    data: {
      descriptions: [{ label: "Disinherit this child?" }],
      required: true,
      definition: { type: QuestionType.FLAG },
    },
  },
  {
    id: "treatGrandchildrenAsOwn",
    data: {
      descriptions: [
        {
          label:
            "Do you want to treat any living grandchildren from this child the same as your deceased child for purposes of your estate plan?",
        },
      ],
      required: true,
      condition: { [CHILD_QUESTIONNAIRE_ID]: { isDeceased: true } },
      definition: { type: QuestionType.FLAG },
    },
  },
];

const addressFields: Question[] = [
  {
    id: "address",
    data: {
      descriptions: [{ label: "Address" }],
      required: true,
      definition: { type: QuestionType.ADDRESS },
    },
  },
  {
    id: "phoneNumber",
    data: {
      descriptions: [{ label: "Phone number" }],
      required: false,
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },
];

export const childQuestions: Question[] = [
  ...getBasicFields(CHILD_QUESTIONNAIRE_ID),
  ...detailsFields,
  ...addressFields,
];

export const grandchildQuestions: Question[] = [
  ...getBasicFields(GRANDCHILD_QUESTIONNAIRE_ID),
  ...addressFields,
];

export const grandchildFormSections: QuestionnaireSection[] = [
  {
    caption: "",
    questions: [
      ["firstName", "middleName", "lastName"],
      "suffix",
      "dateOfBirth",
      "gender",
    ],
  },
  {
    caption: "Contact Information",
    questions: addressFields.map(({ id }) => id),
  },
];

export const childFormSections: QuestionnaireSection[] = [
  {
    caption: "",
    questions: [
      ["firstName", "middleName", "lastName"],
      "suffix",
      "dateOfBirth",
      "gender",
    ],
  },
  {
    caption: "",
    questions: [
      "whoseChild",
      "treatAsOwn",
      "disinherited",
      "isDeceased",
      "treatGrandchildrenAsOwn",
    ],
  },
  {
    caption: "Contact Information",
    questions: addressFields.map(({ id }) => id),
    condition: { not: { [CHILD_QUESTIONNAIRE_ID]: { isDeceased: true } } },
  },
];

export const childToFormValues = (
  client: Client | undefined,
  party: Party,
): AnswersMap => {
  const relationshipToClient = findRelationship(
    party,
    client,
    RelationshipType.CHILD,
  ) as ChildRelationship | undefined;
  return {
    firstName: party.firstName,
    middleName: party.middleName,
    lastName: party.lastName,
    gender: party.gender,
    dateOfBirth: party.dateOfBirth || "",
    address: party.address,
    isDeceased: party.deceased,
    phoneNumber: party.contact?.cellPhone,
    suffix: party.suffix,
    ...(party.parentId
      ? {}
      : {
          disinherited: relationshipToClient?.disinherited,
          whoseChild: relationshipToClient?.whoseChild,
          treatAsOwn: relationshipToClient?.treatAsOwn,
          treatGrandchildrenAsOwn:
            relationshipToClient?.treatGrandchildrenAsOwn,
        }),
  };
};

export const formValuesToChild = (
  values: Record<QuestionID, any>,
): Partial<PartyToSave> => ({
  firstName: values.firstName,
  middleName: values.middleName,
  lastName: values.lastName,
  gender: values.gender,
  dateOfBirth: values.dateOfBirth,
  deceased: values.isDeceased,
  suffix: values.suffix,
  relationshipToSave: {
    type: RelationshipType.CHILD,
    treatAsOwn: values.treatAsOwn !== undefined ? values.treatAsOwn : true, // TODO should be optional
    treatGrandchildrenAsOwn:
      values.treatGrandchildrenAsOwn !== undefined
        ? values.treatGrandchildrenAsOwn
        : true, // TODO should be optional
    disinherited: values.disinherited,
    whoseChild: values.whoseChild || WhoseChild.MINE,
  },
  ...(values.isDeceased
    ? {}
    : {
        contact: { cellPhone: values.phoneNumber },
        address: { id: "home", ...values.address },
      }),
});

export const grandchildToFormValues = (
  _: Client | undefined,
  party: Party,
): AnswersMap => ({
  firstName: party.firstName,
  middleName: party.middleName,
  lastName: party.lastName,
  gender: party.gender,
  dateOfBirth: party.dateOfBirth || "",
  address: party.address,
  phoneNumber: party.contact?.cellPhone,
  suffix: party.suffix,
});

export const formValuesToGrandchild = (
  values: Record<QuestionID, any>,
): Partial<PartyToSave> => ({
  firstName: values.firstName,
  middleName: values.middleName,
  lastName: values.lastName,
  suffix: values.suffix,
  gender: values.gender,
  dateOfBirth: values.dateOfBirth,
  relationshipToSave: {
    type: RelationshipType.GRANDCHILD,
  },
  address: {
    id: "home",
    ...values.address,
  },
  contact: {
    cellPhone: values.phoneNumber,
  },
});
