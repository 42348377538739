import React from "react";
import classNames from "classnames/bind";
import { Slide, Zoom } from "@mui/material";
import { RemoveScroll } from "react-remove-scroll";
import PlatformContext from "contexts/PlatformContext";
import { TransitionProps } from "@mui/material/transitions";
import { showModal, ModalControls, ModalType } from "..";
import Dialog, { DialogMode } from "../Dialog";

import styles from "./styles.module.scss";
import Link from "components/Link";
import Icon, { IconType } from "components/Icons";
const cx = classNames.bind(styles);

type ModalPageState = {
  open: boolean;
};

export type ModalPageProps = {
  getModalControls?: (controls: ModalControls) => void;
  labelClose?: string;
  onClose?: () => void;
  renderContent?: (modalControls: ModalControls) => JSX.Element | null;
  text?: string | JSX.Element | JSX.Element[];
  title?: string;
  transition?: "zoom" | "slideUp";
};

const ZoomTransition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Zoom ref={ref} {...props} />;
  },
);

const SlideUpTransition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);
export class ModalPage extends React.PureComponent<
  ModalPageProps & {
    onExited: () => void;
    ref: React.RefObject<any>;
  },
  ModalPageState
> {
  _isMounted: boolean = false;
  state: ModalPageState = { open: true };
  static contextType = PlatformContext;
  controls: ModalControls = {
    close: () => {
      this._isMounted && this.setState({ ...this.state, open: false });
    },
    busy: (busy: boolean) => undefined,
    setDirty: (dirty: boolean) => undefined,
  };

  constructor(
    props: ModalPageProps & {
      onExited: () => void;
      ref: React.RefObject<any>;
    },
  ) {
    super(props);
    props.getModalControls && props.getModalControls(this.controls);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    !this.props.getModalControls &&
      this.setState({ ...this.state, open: false });
  };

  render() {
    const {
      children,
      labelClose,
      onExited,
      text,
      title,
      transition = "zoom",
    } = this.props;

    const className = cx("modal-page");

    return (
      <RemoveScroll enabled={this.context.isMobile}>
        <Dialog
          mode={DialogMode.PAGE}
          onClose={this.handleClose}
          onExited={onExited}
          open={this.state.open}
          scroll="body"
          TransitionComponent={
            transition === "slideUp" ? SlideUpTransition : ZoomTransition
          }
        >
          <div className={className}>
            <div className={styles["modal-page__top-actions"]}>
              <Link className={styles["icon-link"]} onClick={this.handleClose}>
                {labelClose || "Close"} <Icon type={IconType.CROSS} />
              </Link>
            </div>
            {title && (
              <header className={styles["modal-page__header"]}>
                <h2 className={styles["modal-page__title"]}>{title}</h2>
              </header>
            )}
            <section className={styles["modal-page__content"]}>
              {text && <p>{text}</p>}
              {typeof children === "function"
                ? children(this.controls)
                : children}
            </section>
            <footer>
              <Link onClick={this.handleClose}>{labelClose || "Close"}</Link>
            </footer>
          </div>
        </Dialog>
      </RemoveScroll>
    );
  }
}

const showModalPage = (props: ModalPageProps) => {
  showModal({
    ...props,
    type: ModalType.PAGE,
  });
};

export default showModalPage;
