import store from "redux/store";
import { getPartnerIdFromUrl } from "./navigator";

export const formatPartner = (partnerId: string) => partnerId.toUpperCase();
export const formatPartnerForRoute = (partnerId: string) =>
  partnerId.toLowerCase();

const getPartnerIdFromStore = () => {
  const { partner } = store.getState();
  return partner?.id;
};

export const getFormattedPartnerId = (): string | null => {
  const partnerId = getPartnerIdFromUrl() || getPartnerIdFromStore();
  return partnerId ? formatPartner(partnerId) : null;
};

export const getPartnerIdForRoute = (): string | null => {
  const partnerId = getFormattedPartnerId();
  return partnerId && formatPartnerForRoute(partnerId);
};
