import {
  Question,
  QuestionID,
  QuestionnaireSection,
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import Client from "../../types/Client";
import Party, { PartyToSave, RelationshipType } from "../../types/Party";
import getRelationshipToClient from "../../helpers/getRelationshipToClient";
import { AnswersMap } from "modules/workflow/types/Answer";
import Gender from "modules/parties/types/Gender";
import suffixes from "modules/parties/helpers/suffixes";

export const RELATED_PARTY_QUESTIONNAIRE_ID = "relatedPartyQuestionnaire";

const basicFields: Question[] = [
  {
    id: "firstName",
    data: {
      descriptions: [{ label: "First name" }],
      required: true,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "middleName",
    data: {
      descriptions: [{ label: "Middle name" }],
      required: false,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "lastName",
    data: {
      descriptions: [{ label: "Last name" }],
      required: true,
      definition: { type: QuestionType.TEXT },
    },
  },
  {
    id: "suffix",
    data: {
      descriptions: [
        {
          label: "Suffix (optional)",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: suffixes.map((value) => ({ value })),
        radio: true,
        allowOther: true,
        otherLabel: "Please enter the suffix",
      },
      required: false,
    },
  },
  {
    id: "gender",
    data: {
      descriptions: [
        {
          label: "What are their pronouns?",
          description:
            "This helps us fill in the correct pronouns throughout your legal documents.",
        },
      ],
      required: true,
      definition: {
        type: QuestionType.SELECT,
        values: [
          { display: "he/him", value: Gender.MALE },
          { display: "she/her", value: Gender.FEMALE },
          { display: "they/them", value: Gender.OTHER },
        ],
        radio: true,
      },
    },
  },
];

const addressFields: Question[] = [
  {
    id: "address",
    data: {
      descriptions: [{ label: "Address" }],
      required: true,
      definition: { type: QuestionType.ADDRESS },
    },
  },
  {
    id: "phoneNumber",
    data: {
      descriptions: [{ label: "Phone number" }],
      required: false,
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },
  {
    id: "email",
    data: {
      descriptions: [{ label: "Email Address" }],
      required: false,
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.EMAIL },
    },
  },
];

export const relatedPartyQuestions: Question[] = [
  ...basicFields,
  {
    id: "relation",
    data: {
      descriptions: [{ label: "Relation to client" }],
      required: true,
      definition: { type: QuestionType.TEXT, placeholder: "e.g. friend" },
    },
  },
  ...addressFields,
];

export const relatedPartyFormSections: QuestionnaireSection[] = [
  {
    caption: "",
    questions: [
      ["firstName", "middleName", "lastName"],
      "suffix",
      "gender",
      "relation",
    ],
  },
  {
    caption: "Contact Information",
    questions: addressFields.map(({ id }) => id),
  },
];

export const partyToFormValues = (
  client: Client,
  party: Party,
): AnswersMap => ({
  firstName: party.firstName,
  middleName: party.middleName,
  lastName: party.lastName,
  gender: party.gender,
  suffix: party.suffix,
  address: party.address,
  email: party.contact?.email,
  phoneNumber: party.contact?.cellPhone,
  relation: getRelationshipToClient(party, client) || "",
});

export const formValuesToModifiedParty = (
  values: Record<QuestionID, any>,
): Partial<PartyToSave> => ({
  firstName: values.firstName,
  middleName: values.middleName,
  lastName: values.lastName,
  gender: values.gender,
  suffix: values.suffix,
  address: {
    id: "default",
    ...values.address,
  },
  relationshipToSave: {
    type: RelationshipType.OTHER,
    value: values.relation,
  },
  contact: {
    cellPhone: values.phoneNumber,
    email: values.email,
  },
});
