import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import {
  fetchMedicaidLead,
  getMedicaidLeadCalculation,
} from "modules/medicaid/actions";
import { MedicaidLeadStatus } from "modules/medicaid/types";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { loadPartnerDetails } from "modules/partner/actions";
import React, { useContext } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { go } from "modules/navigation/helpers/navigator";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";
import Icon, { IconType } from "components/Icons";
import AssetProtectionPlanAdditionalProtection from "../AssetPrtoectionPlanAdditionalProtection";
import PlatformContext from "contexts/PlatformContext";

const cx = classNames.bind(styles);

const MedicaidLeadFinished = () => {
  const dispatch = useDispatch();
  const { lead, isLoadingMedicaidLead, analysis, isLoadingMedicaidAnalysis } =
    useContext(MedicaidLeadContext);
  const { isMobile } = useContext(PlatformContext);

  const { partner, isLoadingPartner } = useSelector(
    (state) => ({
      partner: state.partner,
      isLoadingPartner: state.isLoadingPartner,
    }),
    shallowEqual,
  );
  const { medicaidLeadId } = useParams<{ medicaidLeadId?: string }>();

  if (medicaidLeadId === undefined) {
    return <Redirect to={ROUTE_TEMPLATE.ROOT} />;
  }

  if (lead === undefined) {
    if (!isLoadingMedicaidLead) {
      fetchMedicaidLead(dispatch, medicaidLeadId);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  if (lead.status === MedicaidLeadStatus.InterviewInProgress) {
    return (
      <Redirect
        to={getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD, { medicaidLeadId })}
      />
    );
  }

  if (lead.partnerId === undefined) {
    return (
      <Redirect
        to={getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD_MAP, { medicaidLeadId })}
      />
    );
  }

  if (
    partner === undefined ||
    partner === null ||
    lead.partnerId !== partner.id
  ) {
    if (!isLoadingPartner) {
      loadPartnerDetails(lead.partnerId)(dispatch);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  if (
    analysis?.calculation === undefined ||
    analysis.medicaidLeadId !== lead.medicaidLeadId
  ) {
    if (!isLoadingMedicaidAnalysis) {
      getMedicaidLeadCalculation(dispatch, lead.medicaidLeadId);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const handleClickBackToInterview = () => {
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.MEDICAID_LEAD, {
        medicaidLeadId: lead.medicaidLeadId,
      }),
    );
  };

  const handleClickSeeEstatePlanningOptions = () => {
    go(dispatch, getRoute(ROUTE_TEMPLATE.MY_MEDICAID_WORKFLOWS));
  };

  return (
    <div className={cx("medicaid-lead-finished")}>
      <div className={cx("medicaid-lead-finished__header")}>
        <Icon
          type={IconType.TICK}
          className={cx("medicaid-lead-finished__tick")}
        />
        <h2>Congratulations! You took the first step...</h2>
      </div>
      <h3>
        Your Asset Protection Plan analysis has been sent to{" "}
        <b>{partner.data.displayName}</b>. They will review your information and
        contact you directly with the next steps.
      </h3>
      <div className={cx("medicaid-lead-finished__reminder")}>
        <div className={cx("medicaid-lead-finished__analysis-graph")}>
          <AssetProtectionPlanAdditionalProtection
            calculation={analysis.calculation}
            showTicks={!isMobile}
          />
        </div>
      </div>
      <div className={cx("medicaid-lead-finished__actions")}>
        <TabbedButton
          label="Back to your interview"
          style={TabbedButtonType.SECONDARY}
          onClick={handleClickBackToInterview}
        />
        <TabbedButton
          label="Back to Medicaid Workflows"
          style={TabbedButtonType.PRIMARY}
          onClick={handleClickSeeEstatePlanningOptions}
        />
      </div>
    </div>
  );
};

export default MedicaidLeadFinished;
