import * as React from "react";
import classNames from "classnames/bind";

import { User } from "modules/auth/User";
import HeaderAccountModalArrow from "./HeaderAccountModalArrow";
import HeaderAccountModalInformation from "./HeaderAccountModalInformation";
import HeaderAccountModalNavigation, {
  HeaderAccountModalNavigationLink,
} from "./HeaderAccountModalNavigation";
import HeaderAccountModalLogout from "./HeaderAccountModalLogout";
import MenuLink from "../MenuLink";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type HeaderAccountModalProps = {
  onLogout: () => any;
  onMenuItemSelected: () => any;
  open: boolean;
  user: User;
  menuLinks: MenuLink[];
};

const HeaderAccountModal: React.FunctionComponent<HeaderAccountModalProps> = ({
  open,
  user,
  onLogout,
  menuLinks,
  onMenuItemSelected,
}) => {
  const className = cx("header-account-modal", {
    "header-account-modal--open": open,
  });

  const createClickHandler = React.useCallback(
    (clickHandler) => () => {
      onMenuItemSelected();
      clickHandler();
    },
    [onMenuItemSelected],
  );

  return (
    <div className={className}>
      <HeaderAccountModalArrow />
      <div className={styles["header-account-modal__container"]}>
        <HeaderAccountModalInformation user={user} />
        <HeaderAccountModalNavigation>
          {menuLinks.map(({ label, icon, onClick }, idx) => (
            <HeaderAccountModalNavigationLink
              key={`profile_menu_${idx}`}
              label={label}
              icon={icon}
              onClick={createClickHandler(onClick)}
            />
          ))}
        </HeaderAccountModalNavigation>
        <HeaderAccountModalLogout onLogout={onLogout} />
      </div>
    </div>
  );
};

export default HeaderAccountModal;
