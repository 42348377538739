import * as React from "react";
import SimpleHeader from "components/Header/SimpleHeader";
import {
  getWorkflowDetailsRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import { useDispatch } from "react-redux";
import { go, matchPathTemplate } from "modules/navigation/helpers/navigator";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";

type PaymentScreenHeaderProps = {
  onHome: () => void;
};

const SigningScreenHeader: React.FC<PaymentScreenHeaderProps> = ({
  onHome,
}) => {
  const dispatch = useDispatch();

  const handleNavigateToSummary = () => {
    const pathMatch =
      matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_SIGNING) || undefined;
    const workflowId = ((pathMatch?.params || {}) as any).id;
    go(
      dispatch,
      getWorkflowDetailsRoute(workflowId, undefined, SUMMARY_PAGE_ID),
    );
  };

  return (
    <SimpleHeader
      onLogoClicked={onHome}
      backLabel="Back to interview summary"
      onBack={handleNavigateToSummary}
    />
  );
};

export default SigningScreenHeader;
