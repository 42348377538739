import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";

import PaymentProcessingScreen from "./PaymentProcessingScreen";
import { getWorkflowDetailsRoute } from "modules/navigation/routes";
import { go } from "modules/navigation/helpers/navigator";
import { hasPassedStep, isPaid } from "modules/workflow/helpers/lifecycle";
import {
  ADDONS_PAGE_ID,
  ASSEMBLE_DOCUMENTS_PAGE_ID,
  SUMMARY_PAGE_ID,
} from "modules/workflow/staticPages/pageIDs";
import Workflow from "modules/workflow/types/Workflow";
import { Step } from "modules/api/types/WorkflowPayload";
import LoadingOverlay from "components/LoadingOverlay";
import { startLawyerReview } from "modules/workflow/review/actions";

type Props = {
  workflowId: string;
  isAdmin: boolean;
};

const PaymentProcessingtScreenContainer: React.FunctionComponent<Props> = ({
  workflowId,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const { workflows, isStartingLawyerReview } = useSelector(
    (state) => ({
      workflows: state.workflows,
      isStartingLawyerReview: state.isStartingLawyerReview,
    }),
    shallowEqual,
  );

  const workflow: Workflow | undefined = workflows[workflowId];

  if (!workflow || isStartingLawyerReview) {
    return <LoadingOverlay />;
  }

  const paymentIsCaptured =
    hasPassedStep(workflow.state.steps, Step.AwaitingPaymentCapture) &&
    workflow.state.steps.current.step !== Step.AwaitingPaymentCapture;

  const skippedPayment = workflow.isSkippingPayment;

  if (paymentIsCaptured) {
    const onClickAction = () =>
      isAdmin
        ? startLawyerReview(
            workflow.id,
            workflow.userId,
          )(dispatch).then(() =>
            go(
              dispatch,
              getWorkflowDetailsRoute(
                workflow.id,
                undefined,
                isAdmin ? ADDONS_PAGE_ID : ASSEMBLE_DOCUMENTS_PAGE_ID,
              ),
            ),
          )
        : go(
            dispatch,
            getWorkflowDetailsRoute(
              workflow.id,
              undefined,
              isAdmin ? ADDONS_PAGE_ID : ASSEMBLE_DOCUMENTS_PAGE_ID,
            ),
          );

    return (
      <PaymentProcessingScreen
        isAdmin={isAdmin}
        actionTitle={isAdmin ? "Start review" : "View documents"}
        onClickAction={onClickAction}
        skippedPayment={skippedPayment}
      />
    );
  }

  const workflowIsPaid = isPaid(workflow.state.steps);

  if (
    workflowIsPaid &&
    workflow.state.steps.current.step !== Step.PaymentSubmitted &&
    workflow.state.steps.current.step !== Step.AwaitingPaymentCapture
  ) {
    const onClickAction = () =>
      go(
        dispatch,
        getWorkflowDetailsRoute(workflowId, undefined, SUMMARY_PAGE_ID),
      );

    return (
      <PaymentProcessingScreen
        isAdmin={isAdmin}
        actionTitle="View Summary"
        onClickAction={onClickAction}
        skippedPayment={skippedPayment}
      />
    );
  }

  return (
    <PaymentProcessingScreen
      isLoading
      isAdmin={isAdmin}
      skippedPayment={skippedPayment}
    />
  );
};

export default PaymentProcessingtScreenContainer;
