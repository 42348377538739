import { chunk, intersection, isEmpty } from "lodash";
import {
  DefinitionDataProduct,
  DefinitionDataAddon,
  DefinitionDataPlan,
  KioskProductDescription,
} from "modules/api/responseTypes/DefinitionResponseType";
import Partner from "modules/parties/types/Partner";
import Addon from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";
import { AddonPrice, ProductPrice } from "modules/product/types/Price";
import {
  Question,
  QuestionID,
  QuestionnaireSection,
  QuestionType,
} from "modules/workflow/types/Questionnaire";
import { PRODUCT_FORM_ID } from ".";

const DEFAULTS_PREFIX = "defaults_";
const SPECIFIC_DEFAULTS_PREFIX = "specificDefaults_";
const PRICE_PREFIX = "price_";

export const getPriceKey = (key: string) => PRICE_PREFIX + key;
export const getDefaultsKey = (key: string, specificDocument?: string) =>
  `${
    specificDocument
      ? `${SPECIFIC_DEFAULTS_PREFIX}${specificDocument}_`
      : DEFAULTS_PREFIX
  }${key}`;

export const getPriceLabel = (priceKey: string) => {
  switch (priceKey) {
    case "regular":
      return "Regular Price";
    case "signing":
      return "Signing Price";
    case "coupled":
      return "Coupled Price";
    case "coupledSigning":
      return "Coupled Signing Price";
    case "external":
      return "External Price";
    default:
      return "Unknown Price";
  }
};

export const getPriceDescription = (priceKey: string) => {
  switch (priceKey) {
    case "regular":
      return "The amount the client will pay through Aturna for the product.";
    case "signing":
      return "The amount a single client pays through Aturna to come into your office to sign documents.";
    case "coupled":
      return "The ADDITIONAL amount paid through Aturna by the client if it is a married couple. This is added to regular price.";
    case "coupledSigning":
      return "The amount a married couple pays through Aturna to come into your office to sign documents.";
    case "external":
      return "The amount the client will pay outside of Aturna for the product.";
    default:
      return undefined;
  }
};

const getPriceIndex = (priceKey: string) => {
  switch (priceKey) {
    case "regular":
      return 0;
    case "coupled":
      return 1;
    case "signing":
      return 2;
    case "coupledSigning":
      return 3;
    case "external":
      return 4;
    default:
      return -1;
  }
};

const getSortedPriceKeys = (price: ProductPrice | AddonPrice) =>
  Object.keys(price).sort((a, b) => getPriceIndex(a) - getPriceIndex(b));

export const generateProductFormQuestions = (
  product: DefinitionDataProduct | DefinitionDataPlan,
  description: Addon | Plan | KioskProductDescription,
  partner: Partner,
): Question[] => [
  ...Object.keys(product.price).map((priceKey) => ({
    id: getPriceKey(priceKey),
    data: {
      descriptions: [
        {
          label: getPriceLabel(priceKey),
          description: getPriceDescription(priceKey),
        },
      ],
      definition: {
        type: QuestionType.CURRENCY as QuestionType.CURRENCY,
        integral: false,
        symbol: "dollar",
      },
      required: true,
    },
  })),
  ...("defaults" in description && description.defaults.length
    ? description.defaults.map((def) => ({
        id: getDefaultsKey(def.key),
        data: {
          definition: def.definition,
          descriptions: [{ label: def.label, description: def.description }],
        },
      }))
    : []),
  ...("specificDefaults" in description
    ? Object.keys(description.specificDefaults).reduce(
        (allQuestions, key) => [
          ...allQuestions,
          ...description.specificDefaults[key].map((def) => ({
            id: getDefaultsKey(def.key, key),
            data: {
              definition: def.definition,
              descriptions: [
                { label: def.label, description: def.description },
              ],
            },
          })),
        ],
        [] as Question[],
      )
    : []),
];

export const generateProductFormSections = (
  jurisdictions: string[],
  product: DefinitionDataProduct | DefinitionDataPlan,
  description: Addon | Plan | KioskProductDescription,
): QuestionnaireSection[] => [
  {
    caption: "Price",
    questions: chunk(getSortedPriceKeys(product.price).map(getPriceKey), 2),
  },
  {
    caption: "Defaults",
    questions:
      "defaults" in description
        ? description.defaults.map((def) => getDefaultsKey(def.key))
        : [],
  },
  ...("specificDefaults" in description
    ? Object.keys(description.specificDefaults)
        .filter(
          (key) =>
            !isEmpty(
              intersection(
                jurisdictions,
                description.stateSpecificDefinitions[key],
              ),
            ),
        )
        .map((key) => ({
          caption: `State-specific defaults: ${
            description.stateSpecificDefinitions[key]?.join(", ") || "default"
          }`,
          questions: description.specificDefaults[key].map((description) =>
            getDefaultsKey(description.key, key),
          ),
        }))
    : []),
];

const getProductPrices = (
  productDefinition: DefinitionDataProduct | DefinitionDataPlan,
) =>
  Object.keys(productDefinition.price).reduce(
    (priceValues, priceKey) => ({
      ...priceValues,
      [getPriceKey(priceKey)]:
        productDefinition.price[priceKey as keyof AddonPrice],
    }),
    {},
  );

const getPartnerDefaults = (
  productDefinition:
    | DefinitionDataProduct
    | DefinitionDataAddon
    | DefinitionDataPlan,
  description: Addon | Plan | KioskProductDescription,
) =>
  "defaults" in description &&
  "defaults" in productDefinition &&
  description.defaults.length
    ? description.defaults.reduce(
        (defaultValues, def) => ({
          ...defaultValues,
          [getDefaultsKey(def.key)]:
            productDefinition.defaults[def.key] !== undefined
              ? productDefinition.defaults[def.key]
              : description.defaults.find((el) => el.key === def.key)
                  ?.defaultValue,
        }),
        {},
      )
    : {};

const getSpecificDefaults = (
  productDefinition:
    | DefinitionDataProduct
    | DefinitionDataAddon
    | DefinitionDataPlan,
  description: Addon | Plan | KioskProductDescription,
) =>
  "specificDefaults" in description &&
  "stateSpecificDefaults" in productDefinition
    ? Object.keys(description.specificDefaults).reduce(
        (values, stateKey) => ({
          ...values,
          ...description.specificDefaults[stateKey].reduce(
            (stateValues, def) => ({
              ...stateValues,
              [getDefaultsKey(def.key, stateKey)]:
                productDefinition.stateSpecificDefaults[stateKey]?.[def.key] !==
                undefined
                  ? productDefinition.stateSpecificDefaults[stateKey]?.[def.key]
                  : description.specificDefaults[stateKey].find(
                      (el) => el.key === def.key,
                    )?.defaultValue,
            }),
            {},
          ),
        }),
        {},
      )
    : {};

export const generateProductFormValues = (
  productDefinition: DefinitionDataProduct | DefinitionDataPlan,
  description: Addon | Plan | KioskProductDescription,
) => ({
  [PRODUCT_FORM_ID]: {
    ...getProductPrices(productDefinition),
    ...getPartnerDefaults(productDefinition, description),
    ...getSpecificDefaults(productDefinition, description),
  },
});

export const getDefinitionFromProductForm = (
  values: Record<QuestionID, any>,
  product: DefinitionDataProduct | DefinitionDataPlan | DefinitionDataAddon,
) => {
  const price = Object.keys(values).reduce((prices, questionId) => {
    if (questionId.startsWith(PRICE_PREFIX)) {
      return {
        ...prices,
        [questionId.split(PRICE_PREFIX)[1]]: values[questionId],
      };
    }
    return { ...prices };
  }, {});

  const defaults = Object.keys(values)
    .filter((id) => id.startsWith(DEFAULTS_PREFIX))
    .reduce(
      (defaults, id) => ({
        ...defaults,
        [id.split(DEFAULTS_PREFIX)[1]]: values[id],
      }),
      {},
    );

  const stateSpecificDefaults = Object.keys(values)
    .filter((id) => id.startsWith(SPECIFIC_DEFAULTS_PREFIX))
    .reduce(
      (defaults, id) => {
        const split = id.split(SPECIFIC_DEFAULTS_PREFIX)[1].split("_");
        const stateKey = split[0];
        const defaultKey = split[1];
        return {
          ...defaults,
          [stateKey]: { ...defaults[stateKey], [defaultKey]: values[id] },
        };
      },
      {} as Record<string, Record<string, any>>,
    );

  return {
    ...product,
    price,
    defaults,
    stateSpecificDefaults,
  };
};
