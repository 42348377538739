import { PartnerPermission } from "modules/admin/types/Permissions";
import { API, get } from "modules/api";
import useSWR from "swr";

const fetchFunction = async (): Promise<PartnerPermission[]> => {
  const res = await get(API.PERMISSIONS());
  return res.data;
};

const usePermissions = () => {
  const { data, error, mutate } = useSWR(
    ["useFetchPermissions"],
    fetchFunction,
  );

  return {
    permissions: data,
    error,
    refetchPermissions: mutate,
  };
};

export default usePermissions;
