import {
  Question,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
  QuestionType,
} from "../types/Questionnaire";
import { saveFile } from "modules/fileStore";
import { ImageData } from "components/ImageUpload";
import logger from "logger";
import { AnswersMap, AnswerValueType } from "../types/Answer";

const IMAGES_FOLDER_NAME = "images";

const preProcessValue = async (
  partnerId: string,
  question: Question,
  value: AnswerValueType,
  isChanged: boolean,
): Promise<AnswerValueType> => {
  switch (question?.data.definition.type) {
    case QuestionType.IMAGE: {
      const imageData = value as ImageData;
      if (!value) return value;

      if (!partnerId) {
        logger.error("No partner provided");
        return imageData;
      }

      // do not upload if this picture has been already uploaded
      if (!isChanged) return imageData;

      try {
        const downloadUrl = await saveFile(
          partnerId,
          IMAGES_FOLDER_NAME,
          imageData.blob,
          imageData.extension,
        );
        return { ...imageData, downloadUrl } as ImageData;
      } catch (e) {
        logger.error("Saving an image failed", e);
        return imageData;
      }
    }

    default:
      return value;
  }
};

export const shouldPreprocess = (
  questions: QuestionsMapByQuestionnaire,
): boolean => {
  const allQuestions = Object.values(questions)
    .map((questionsMap) => Object.values(questionsMap))
    .flat();
  return Boolean(
    allQuestions.find((question) =>
      [QuestionType.IMAGE].includes(question.data.definition.type),
    ),
  );
};

export default async (
  partnerId: string,
  questions: QuestionsMap,
  answers: AnswersMap = {},
  changedAnswerIds: string[],
): Promise<AnswersMap> => {
  const preprocessedAnswers: AnswersMap = {};

  for (const [key, value] of Object.entries(answers)) {
    const question = questions[key];
    preprocessedAnswers[key] = await preProcessValue(
      partnerId,
      question,
      value,
      changedAnswerIds.includes(question.id),
    );
  }

  return preprocessedAnswers;
};
