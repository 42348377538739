import { sum } from "lodash";
import Plan from "../types/Plan";
import Product from "../types/Product";

const getSupervisedSigningPrice = (
  product: Product | Plan | (Product | Plan)[] | undefined,
  isCoupledWorkflow?: boolean,
): number => {
  if (!product) return 0;
  if (Array.isArray(product)) {
    return sum(
      product.map((item) => getSupervisedSigningPrice(item, isCoupledWorkflow)),
    );
  }
  return isCoupledWorkflow
    ? product.price.coupledSigning
    : product.price.signing;
};

export default getSupervisedSigningPrice;
