import Client from "../types/Client";
import Party, {
  ChildRelationship,
  RelationshipType,
  WhoseChild,
} from "../types/Party";
import { findRelationship } from "./getRelationshipToClient";

const convertWhoseChild = (spousesAnswer: WhoseChild): WhoseChild => {
  switch (spousesAnswer) {
    case WhoseChild.MINE:
      return WhoseChild.MY_PARTNERS;
    case WhoseChild.OURS:
      return WhoseChild.OURS;
    default:
      return WhoseChild.MINE;
  }
};

export default (child: Party, client?: Client, spouse?: Client): Party => {
  if (
    client &&
    spouse &&
    !findRelationship(child, client, RelationshipType.CHILD)
  ) {
    const relationshipToSpouse = findRelationship(
      child,
      spouse,
      RelationshipType.CHILD,
    ) as ChildRelationship | undefined;

    const whoseChild =
      relationshipToSpouse &&
      convertWhoseChild(relationshipToSpouse.whoseChild);
    return !relationshipToSpouse
      ? child
      : ({
          ...child,
          relationships: [
            ...(child.relationships || []),
            {
              clientId: client.clientId,
              partyId: child.partyId,
              relationship: {
                type: RelationshipType.CHILD,
                whoseChild,
                disinherited: relationshipToSpouse.disinherited,
                treatAsOwn:
                  whoseChild !== WhoseChild.MY_PARTNERS ||
                  relationshipToSpouse.treatAsOwn,
                treatGrandchildrenAsOwn:
                  relationshipToSpouse.treatGrandchildrenAsOwn,
              },
            },
          ],
        } as Party);
  }
  return child;
};
