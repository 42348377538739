const validatePasswordStrength = (password: string): boolean => {
  if (password.length < 8) {
    return false;
  }

  return /^(?=.*([A-Z])+)(?=.*[!@#$&*]+)(?=.*[0-9]+)(?=.*[a-z]+).{8,100}$/.test(
    password,
  );
};

export default validatePasswordStrength;
