import keyBy from "lodash/keyBy";
import { AddressSearchResultsType } from "modules/address/actions";
import { User } from "modules/auth/User";
import { processClientQuestions } from "modules/workflow/helpers/processQuestions";
import { getInitialValues } from "modules/workflow/helpers/validation";
import { AnswersMap, AnswerValueType } from "modules/workflow/types/Answer";
import {
  QuestionnairePage,
  QuestionID,
  QuestionAnnotation,
} from "modules/workflow/types/Questionnaire";
import StaticSubquestionnaireDefinition from "modules/workflow/types/StaticSubquestionnaireDefinition";
import Address from "../../types/Address";
import Client from "../../types/Client";
import getValue from "../getValue";
import {
  clientDetailsPageDefinition,
  clientContactDetailsPageDefinition,
  clientFields,
  CLIENT_FORM_ID,
  clientQuestions,
} from "./constants";

export const createClientDetailsPage = (
  onSave: (
    values: AnswersMap,
    _allPageValues: AnswersMap,
    isDelta?: boolean,
  ) => any,
): QuestionnairePage => ({
  ...clientDetailsPageDefinition,
  onSave,
});

export const createClientContactDetailsPage = (
  onSave: (
    valuesToSave: Record<QuestionID, any>,
    allPageValues: Record<QuestionID, any>,
    isDelta?: boolean,
  ) => any,
): QuestionnairePage => ({
  ...clientContactDetailsPageDefinition,
  onSave,
});

export const getDefaultMaritalStatus = (
  isCoupledWorkflow: boolean | undefined,
  client: Client | undefined,
  annotatedAdvisorAnswers: Record<QuestionAnnotation, AnswerValueType> = {},
): AnswerValueType => {
  if (isCoupledWorkflow) return true;
  if (client) return client.isMarried;
  return annotatedAdvisorAnswers["client.marital_status"];
};

export const getAddressIfStateDefined = (
  state?: string,
): Partial<Address> | undefined =>
  state !== undefined
    ? {
        state,
        line1: "",
        line2: "",
        city: "",
        zip: "",
        adminAreaName: "",
      }
    : undefined;

export const getClientFormValues = (
  client: Client | undefined,
  isAdmin: boolean,
  annotatedAdvisorAnswers: Record<QuestionAnnotation, AnswerValueType> = {},
  isCoupledWorkflow?: boolean,
  user?: User | null,
): Record<string, AnswerValueType> => {
  const workflowUser: User | null | undefined = isAdmin ? undefined : user;

  return {
    [clientFields.firstName]:
      client?.firstName ||
      annotatedAdvisorAnswers[clientFields.firstName] ||
      workflowUser?.firstName ||
      "",
    [clientFields.lastName]:
      client?.lastName ||
      annotatedAdvisorAnswers[clientFields.lastName] ||
      workflowUser?.lastName ||
      "",
    [clientFields.middleName]:
      client?.middleName ||
      annotatedAdvisorAnswers[clientFields.middleName] ||
      workflowUser?.middleName ||
      "",
    [clientFields.suffix]: client?.suffix || "",
    [clientFields.alias]: client?.alias || "",
    [clientFields.gender]: client?.gender || "",
    [clientFields.dateOfBirth]:
      client?.dateOfBirth || annotatedAdvisorAnswers["client.dob"] || "",
    [clientFields.isMarried]: getDefaultMaritalStatus(
      isCoupledWorkflow,
      client,
      annotatedAdvisorAnswers,
    ),
    [clientFields.clientAndSpouseUSCitizens]: client?.clientAndSpouseUSCitizens,
    [clientFields.address]:
      client?.address ||
      getAddressIfStateDefined(annotatedAdvisorAnswers.state as string) ||
      getAddressIfStateDefined(user?.state) ||
      undefined,
    [clientFields.homeAddressIsMailing]: !Boolean(
      client?.mailingAddress?.line1,
    ),
    [clientFields.mailingAddress]: client?.mailingAddress,

    [clientFields.contact.cellPhone]:
      client?.contact?.cellPhone ||
      annotatedAdvisorAnswers[clientFields.contact.cellPhone] ||
      workflowUser?.phoneNumber ||
      "",
    [clientFields.contact.homePhone]: client?.contact?.homePhone,
    [clientFields.contact.workPhone]: client?.contact?.workPhone,
    [clientFields.contact.email]:
      client?.contact?.email ||
      annotatedAdvisorAnswers[clientFields.contact.email] ||
      workflowUser?.email ||
      "",
  };
};

const getClientQuestionnaire = (
  onSave: (
    clientId: string | undefined,
    clientDetails: Partial<Client>,
    isDelta?: boolean,
  ) => any,
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  client: Client | undefined,
  isAdmin: boolean,
  annotatedAdvisorAnswers?: AnswersMap,
  isCoupledWorkflow?: boolean,
  renderCloneWorkflowBanner?: () => JSX.Element | null,
  isPrimaryWorkflow?: boolean,
  user?: User | null,
  readOnly?: boolean,
  supportedStates?: string[],
): StaticSubquestionnaireDefinition => {
  const processedQuestions = processClientQuestions(
    clientQuestions,
    onAddressLookup,
    onAddressSearch,
    availableAddresses,
    false,
    renderCloneWorkflowBanner,
    supportedStates,
  ).map((q) => {
    q.data.readOnly = readOnly;
    return q;
  });

  const clientPages: QuestionnairePage[] = [
    createClientDetailsPage(
      (
        changedValues: Record<QuestionID, any>,
        _allValues: AnswersMap,
        isDelta?: boolean,
      ) => {
        if (isDelta && Object.keys(changedValues).length === 0) return;
        const middleNameValue: string = (
          changedValues[clientFields.middleName] || ""
        ).trim();
        const middleName = middleNameValue.match(/^[a-z]$/i)
          ? `${middleNameValue}.`
          : middleNameValue;
        const middleNameToSet =
          changedValues[clientFields.middleName] === undefined
            ? undefined
            : middleName;

        const clientData: Partial<Client> = {
          firstName: changedValues[clientFields.firstName],
          lastName: changedValues[clientFields.lastName],
          middleName: middleNameToSet,
          suffix: changedValues[clientFields.suffix],
          alias: changedValues[clientFields.alias],
          gender: changedValues[clientFields.gender],
          dateOfBirth: changedValues[clientFields.dateOfBirth],
          isMarried: isCoupledWorkflow || changedValues[clientFields.isMarried],
          clientAndSpouseUSCitizens:
            changedValues[clientFields.clientAndSpouseUSCitizens],
        };
        return onSave(client?.clientId, clientData, isDelta);
      },
    ),

    createClientContactDetailsPage((changedValues, allValues, isDelta) => {
      if (isDelta && Object.keys(changedValues).length === 0) return;

      const addressData: Partial<Client> = {
        address: allValues[clientFields.address]
          ? {
              id: "home",
              ...allValues[clientFields.address],
            }
          : undefined,
        mailingAddress:
          allValues[clientFields.homeAddressIsMailing] === false
            ? {
                id: "mailing",
                ...allValues[clientFields.mailingAddress],
              }
            : null,
        contact: {
          cellPhone: getValue(
            allValues[clientFields.contact.cellPhone],
            client?.contact?.cellPhone,
          ),
          email: getValue(
            allValues[clientFields.contact.email],
            client?.contact?.email,
          ),
          homePhone: getValue(
            allValues[clientFields.contact.homePhone],
            client?.contact?.homePhone,
          ),
          workPhone: getValue(
            allValues[clientFields.contact.workPhone],
            client?.contact?.workPhone,
          ),
        },
      };
      return onSave(client?.clientId, addressData);
    }),
  ];

  const defaultValues = getClientFormValues(
    client,
    isAdmin,
    isPrimaryWorkflow ? annotatedAdvisorAnswers : undefined,
    isCoupledWorkflow,
    isPrimaryWorkflow ? user : undefined,
  );

  return {
    questionnairePages: clientPages,

    initialValues: {
      [CLIENT_FORM_ID]: getInitialValues(clientQuestions, defaultValues),
    },

    questions: { [CLIENT_FORM_ID]: keyBy(processedQuestions, "id") },
  };
};

export default getClientQuestionnaire;
