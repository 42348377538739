import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase/compat/app";

import { ReactComponent as LogoSVG } from "resources/logos/Aturna_Brandmark_RGB_Purple.svg";
import { ReactComponent as Logo2SVG } from "static/logos/logo-main.svg";

import styles from "./styles.module.scss";
import DataLayerContext from "contexts/DataLayerContext";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import {
  EventName,
  ForgotPasswordEngagementAction,
} from "contexts/DataLayerContext/types";
import ActionCodeSettings = firebase.auth.ActionCodeSettings;
import { redirect } from "../../../../modules/navigation/helpers/navigator";
import {
  getRoute,
  ROUTE_TEMPLATE,
} from "../../../../modules/navigation/routes";
import { useDispatch } from "react-redux";

type ForgotPasswordProps = {
  authProvider: firebase.auth.Auth;
  userJurisdiction?: string;
};

const ForgotPasswordWrapper = ({
  authProvider,
  userJurisdiction,
}: ForgotPasswordProps) => {
  const { sendEvent } = useContext(DataLayerContext);
  const [hasSentAttemptEvent, setHasSentAttemptEvent] = useState(false);
  const [hasSentErrorEvent, setHasSentErrorEvent] = useState(false);

  const [forgottenPasswordEmail, setForgottenPasswordEmail] = useState("");
  const [forgottenPasswordEmailError, setForgottenPasswordEmailError] =
    useState(false);
  const [
    forgottenPasswordConfirmationVisible,
    setForgottenPasswordConfirmationVisible,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // React States
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const NO_ERROR_MESSAGE = "";

  const DEFAULT_ERROR_MESSAGE =
    "An error has occurred, please check your details and try again.";

  const DEFAULT_FORGOTTEN_PASSWORD_CONFIRMATION_MESSAGE =
    "Please check your email and follow the instructions.";

  const isErrorState = useCallback((): boolean => {
    return errorMessage !== "";
  }, [errorMessage]);

  if (!hasSentAttemptEvent && sendEvent !== undefined) {
    sendEvent({
      event: EventName.ForgotPasswordEngagement,
      action: ForgotPasswordEngagementAction.FORGOT_PASSWORD_ATTEMPT,
      user_State: userJurisdiction,
    });
    setHasSentAttemptEvent(true);
  }

  const sendErrorEvent = useCallback(
    (errorField?: string) => {
      if (!hasSentErrorEvent && sendEvent !== undefined) {
        sendEvent({
          event: EventName.ForgotPasswordEngagement,
          action: ForgotPasswordEngagementAction.FORGOT_PASSWORD_ERROR,
          forgot_password_error_field: errorField,
          user_State: userJurisdiction,
        });
        setHasSentErrorEvent(true);
      }
    },
    [hasSentErrorEvent, setHasSentErrorEvent, sendEvent, userJurisdiction],
  );

  const sendPasswordResetEmail = async (email: string): Promise<void> => {
    const url = window.location.href;
    const actionCodeSettings: ActionCodeSettings = {
      url: url,
      handleCodeInApp: false,
    };
    await authProvider
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {
        setForgottenPasswordConfirmationVisible(true);
      })
      .catch(() => {
        setForgottenPasswordConfirmationVisible(true);
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
      });
  };

  const dispatch = useDispatch();
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (forgottenPasswordConfirmationVisible) {
      setErrorMessage(NO_ERROR_MESSAGE);
      setForgottenPasswordConfirmationVisible(false);
      redirect(dispatch, getRoute(ROUTE_TEMPLATE.LOGIN));
    } else {
      await sendPasswordResetEmail(forgottenPasswordEmail);
    }
  };

  useEffect(() => {
    if (isErrorState()) {
      sendErrorEvent();
    }
  }, [isErrorState, sendErrorEvent]);

  useEffect(() => {
    const unregisterLoginObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user !== null && sendEvent !== undefined) {
          // User has logged in or registered
          sendEvent({
            event: EventName.ForgotPasswordEngagement,
            action: ForgotPasswordEngagementAction.FORGOT_PASSWORD_SUCCESS,
            user_State: userJurisdiction,
            user_ID: user.uid,
          });
        }
      });

    return () => unregisterLoginObserver();
  });

  useEffect(() => {
    setSubmitButtonEnabled(
      forgottenPasswordEmail.length !== 0 && !forgottenPasswordEmailError,
    );
    setForgottenPasswordConfirmationVisible(false);
  }, [forgottenPasswordEmail.length, forgottenPasswordEmailError]);

  const handleForgottenPasswordEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const emailValue = e.target.value;
    setForgottenPasswordEmail(emailValue);
    validateEmail(emailValue, setForgottenPasswordEmailError);
    setErrorMessage(NO_ERROR_MESSAGE);
  };

  type ErrorSetter = (error: boolean) => void;

  const validateEmail = (currentEmail: string, errorSetter: ErrorSetter) => {
    if (currentEmail.length === 0) {
      errorSetter(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,32}[.]?$/i.test(currentEmail)
    ) {
      errorSetter(true);
    } else {
      errorSetter(false);
    }
  };

  return (
    <Box component="div" className={styles.login}>
      <Box component="div" className={styles.login__image}>
        <LogoSVG aria-label="Logo" className={styles["login__image__logo-1"]} />
        <h2>Forgot Password?</h2>
        <h3 className={styles.login__h3_hasCont}>
          Enter your email address to receive a link to reset your password.
        </h3>
        <h3>
          If you've previously used Google to sign in,{" "}
          <Link href="https://accounts.google.com/signin/recovery?ec=ar_c">
            reset your password here
          </Link>
          .
        </h3>
        <Logo2SVG
          aria-label="Logo"
          className={styles["login__image__logo-2"]}
        />

        <form>
          <FormGroup className={styles.login__form}>
            <FormControl
              className={styles.login__input_container}
              variant="outlined"
            >
              <InputLabel htmlFor="ui-forgotten-password-email-input">
                Email
              </InputLabel>
              <OutlinedInput
                className={styles.login__input_input}
                id="ui-forgotten-password-email-input"
                type={"text"}
                label="Email"
                error={forgottenPasswordEmailError}
                onChange={(e) => handleForgottenPasswordEmailChange(e)}
              />
            </FormControl>

            <Box
              component="div"
              className={styles.login__input_button_container}
            >
              <Button
                variant="contained"
                className={styles.login__submit_button}
                type="submit"
                disabled={!submitButtonEnabled}
                onClick={handleSubmit}
              >
                {forgottenPasswordConfirmationVisible
                  ? "Back to Sign in"
                  : "Send Password Reset Email"}
              </Button>
            </Box>

            <Box
              component="div"
              id="ui-login-forgotten-password-confirmation-message"
              className={styles.login__forgotten_password_confirmation_div}
            >
              <Typography
                className={
                  styles.login__forgotten_password_confirmation_message
                }
              >
                {forgottenPasswordConfirmationVisible
                  ? DEFAULT_FORGOTTEN_PASSWORD_CONFIRMATION_MESSAGE
                  : ""}
              </Typography>
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPasswordWrapper;
