import { OrderItemType, PaymentOrderItem } from "modules/api/types/Payment";
import {
  ItemListID,
  ItemListName,
  EcommerceItemID,
  EcommerceItemName,
  EcommerceItemCategory,
  EcommerceItem,
} from "./types";
import lodash from "lodash";
import { PartnerID } from "modules/parties/types/Partner";

export const getItemListName = (itemListId: ItemListID): ItemListName =>
  ({
    [ItemListID.NationalLanding]: ItemListName.NationalLanding,
    [ItemListID.PartnerLanding]: ItemListName.PartnerLanding,
    [ItemListID.NewWorkflow]: ItemListName.NewWorkflow,
    [ItemListID.Signing]: ItemListName.Signing,
  })[itemListId];

export const getEcommerceItemName = (
  ecommerceItemID: EcommerceItemID,
): EcommerceItemName =>
  ({
    [EcommerceItemID.WILL]: EcommerceItemName.WILL,
    [EcommerceItemID.HCP]: EcommerceItemName.HCP,
    [EcommerceItemID.POA]: EcommerceItemName.POA,
    [EcommerceItemID.ESSENTIALS]: EcommerceItemName.ESSENTIALS,
    [EcommerceItemID.POWERS]: EcommerceItemName.POWERS,
    [EcommerceItemID.SELF_SIGNING]: EcommerceItemName.SELF_SIGNING,
    [EcommerceItemID.SUPERVISED_SIGNING]: EcommerceItemName.SUPERVISED_SIGNING,
  })[ecommerceItemID];

export const getEcommerceItemCategory = (
  ecommerceItemId: EcommerceItemID,
): EcommerceItemCategory =>
  ({
    [EcommerceItemID.WILL]: EcommerceItemCategory.PRODUCTS,
    [EcommerceItemID.HCP]: EcommerceItemCategory.PRODUCTS,
    [EcommerceItemID.POA]: EcommerceItemCategory.PRODUCTS,
    [EcommerceItemID.ESSENTIALS]: EcommerceItemCategory.PACKAGES,
    [EcommerceItemID.POWERS]: EcommerceItemCategory.PACKAGES,
    [EcommerceItemID.SELF_SIGNING]: EcommerceItemCategory.SIGNING_OPTIONS,
    [EcommerceItemID.SUPERVISED_SIGNING]: EcommerceItemCategory.SIGNING_OPTIONS,
  })[ecommerceItemId];

export const getEcommerceItemID = (
  productOrPlanId: string,
): EcommerceItemID | undefined =>
  ({
    "will-optimized": EcommerceItemID.WILL,
    "hcp-multi-product": EcommerceItemID.HCP,
    "product-poa-multi": EcommerceItemID.POA,
    "will-poa-hcp": EcommerceItemID.ESSENTIALS,
    "base-plan": EcommerceItemID.POWERS,
  })[productOrPlanId];

export const getEcommerceItemIdx = (
  itemId: EcommerceItemID,
  order: Partial<Record<EcommerceItemID, number>>,
): number => {
  const customOrderIdx = order?.[itemId];
  if (customOrderIdx !== undefined) return customOrderIdx;

  return -1;
};

export const getEcommerceItemsFromOrderItems = (
  orderItems?: PaymentOrderItem[],
  partnerId?: PartnerID,
): EcommerceItem[] => {
  if (orderItems === undefined) return [];

  const productsOrPlans: EcommerceItem[] = lodash.compact(
    orderItems
      ?.filter((orderItem) =>
        [OrderItemType.PRODUCT, OrderItemType.PLAN].includes(orderItem.type),
      )
      .map((orderItem, idx) => {
        const ecommerceItemId =
          orderItem.itemId !== undefined
            ? getEcommerceItemID(orderItem.itemId)
            : undefined;

        if (ecommerceItemId === undefined) return undefined;

        return {
          item_id: ecommerceItemId,
          item_name: getEcommerceItemName(ecommerceItemId),
          index: idx,
          item_brand: partnerId,
          item_category: getEcommerceItemCategory(ecommerceItemId),
          price: orderItem.amount,
          quantity: 1,
        };
      }),
  );

  const supervisedSigningOrderItem = orderItems?.find(
    (orderItem) => orderItem.type === OrderItemType.SUPERVISED_SIGNING,
  );

  const signingOptionEcommerceItem: EcommerceItem =
    supervisedSigningOrderItem !== undefined
      ? {
          item_id: EcommerceItemID.SUPERVISED_SIGNING,
          item_name: getEcommerceItemName(EcommerceItemID.SUPERVISED_SIGNING),
          index: productsOrPlans.length,
          item_brand: partnerId,
          item_category: getEcommerceItemCategory(
            EcommerceItemID.SUPERVISED_SIGNING,
          ),
          price: supervisedSigningOrderItem.amount,
          quantity: 1,
        }
      : {
          item_id: EcommerceItemID.SELF_SIGNING,
          item_name: getEcommerceItemName(EcommerceItemID.SELF_SIGNING),
          index: productsOrPlans.length,
          item_brand: partnerId,
          item_category: getEcommerceItemCategory(EcommerceItemID.SELF_SIGNING),
          price: 0,
          quantity: 1,
        };
  return [...productsOrPlans, signingOptionEcommerceItem];
};
