import PageHeader from "components/PageHeader";
import PermissionsConfiguration from "modules/partner/settings/components/PermissionsConfiguration";
import React from "react";

const PermissionsScreen: React.FC = () => {
  return (
    <>
      <PageHeader title="Permissions Configuration" />
      <PermissionsConfiguration />
    </>
  );
};

export default PermissionsScreen;
