import { Middleware } from "redux";
import { acceptEngagementAgreementFinished } from "./actions";

import { go } from "modules/navigation/helpers/navigator";
import { getWorkflowRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { fetchPaymentState } from "modules/payment/actions";
import logger from "logger";
import { NotificationType } from "components/Notification";

const createWorkflowReviewMiddleware: () => Middleware =
  () =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case acceptEngagementAgreementFinished.type: {
        const { workflowId, asUserId, hasSkippedPayment } = action.payload;
        // eslint-disable-next-line no-console
        console.log("workflow review middleware accept finished");
        go(
          dispatch,
          getWorkflowRoute(
            workflowId,
            ROUTE_TEMPLATE.WORKFLOW_PAYMENT_PROCESSING,
          ),
        );

        // Call asynchronously
        fetchPaymentState(workflowId, workflowId, { asUser: asUserId })(
          dispatch,
        ).catch(
          () =>
            !hasSkippedPayment &&
            logger.notify(
              NotificationType.ERROR,
              "Processing the payment is taking longer than expected. You can track the workflow's state from the dashboard.",
            ),
        );
        break;
      }
    }
    next(action);
  };

export default createWorkflowReviewMiddleware;
