import { createReducer } from "@reduxjs/toolkit";
import {
  userAuthenticated,
  userProfileIncomplete,
  userProfileLoaded,
  loginFailed,
  stateNotSelected,
  stateLoaded,
  stateChanged,
  sessionRehydrated,
  userLoggedOut,
  loadingUserProfileStarted,
} from "./actions";
import {
  profileUpdated,
  registrationCompleted,
  stateSelectionSaved,
} from "../userProfile/actions";
import { User } from "./User";
import { UserProfile } from "./UserProfile";
import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { isPath } from "modules/navigation/helpers/navigator";

const convertUserProfileToUser = (userProfile: UserProfile): User => {
  const { user, globalRoles, activePartners, partnerRegistrations } =
    userProfile;
  const {
    id: uid,
    displayName,
    firstName,
    middleName,
    lastName,
    email,
    phoneNumber,
    state,
    data,
  } = user;
  return {
    activePartners,
    partnerRegistrations,
    displayName,
    email,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    globalRoles,
    state,
    timezone: data.timezone,
    uid,
  };
};

export const user = createReducer<User | null>(null, (builder) =>
  builder
    .addCase(userAuthenticated, (_, { payload }) => ({
      ...payload,
      globalRoles: [],
    }))
    .addCase(userLoggedOut, () => null)
    .addCase(registrationCompleted, (currentUser, { payload }) => ({
      ...(currentUser || {}),
      ...payload,
    }))
    .addCase(userProfileLoaded, (currentUser, { payload }) => ({
      ...(currentUser || {}),
      ...convertUserProfileToUser(payload),
    }))
    .addCase(profileUpdated, (_, { payload }) => payload)
    .addCase(stateSelectionSaved, (currentUser, { payload }) => ({
      ...(currentUser as User),
      state: payload,
    })),
);

export const incompleteProfile = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(userProfileIncomplete, () => true)
    .addCase(registrationCompleted, () => false),
);

export const isSessionRehydrated = createReducer<boolean>(false, (builder) =>
  builder.addCase(sessionRehydrated, () => true),
);

export const isLoadingUserDetails = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(userAuthenticated, () => true)
    .addCase(loadingUserProfileStarted, () => true)
    .addCase(userProfileIncomplete, () => false)
    .addCase(userProfileLoaded, () => false)
    .addCase(loginFailed, () => false),
);

export const loginError = createReducer<string | null>(null, (builder) =>
  builder
    .addCase(LOCATION_CHANGE, (previousError, action: LocationChangeAction) =>
      isPath(ROUTE_TEMPLATE.LOGIN, action.payload.location)
        ? previousError
        : null,
    )
    .addCase(userLoggedOut, (_, { payload }) => payload),
);

export const state = createReducer<string | null>(null, (builder) =>
  builder
    .addCase(stateLoaded, (_, { payload }) => payload)
    .addCase(stateChanged, (_, { payload }) => payload)
    .addCase(stateNotSelected, () => null),
);

export const stateRehydrated = createReducer<boolean>(false, (builder) =>
  builder.addCase(stateLoaded, () => true),
);
