import {
  ModalControls,
  ModalType,
  showModal,
} from "../../../../components/Modal";
import { NotificationType } from "../../../../components/Notification";
import TabbedButton, {
  TabbedButtonType,
} from "../../../../components/TabbedButton";
import logger from "../../../../logger";
import { API, post } from "../../../api";
import Partner, { PartnerID } from "../../../parties/types/Partner";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type ArchivePartnerModalProps = {
  archive: boolean;
  partnerId: PartnerID;
  controls: ModalControls;
  afterArchive: () => void;
};

type ArchivePartnerProps = { partner: Partner; afterArchive: () => void };

const ArchivePartner = ({ partner, afterArchive }: ArchivePartnerProps) => {
  const handleOpen = () => {
    showModal({
      type: ModalType.CONFIRM,
      cancellable: true,
      labelCancel: "Close",
      title: "Change Partner Status",
      renderContent: (controls: ModalControls) => (
        <ArchivePartnerModalContent
          archive={!partner.archived}
          partnerId={partner.id}
          controls={controls}
          afterArchive={afterArchive}
        />
      ),
    });
  };

  return (
    <TabbedButton style={TabbedButtonType.SECONDARY} onClick={handleOpen}>
      <div
        data-tip=""
        data-for={partner.archived ? "reinstateTooltip" : "archiveTooltip"}
      >
        {partner.archived ? (
          <FontAwesomeIcon icon={faBoxOpen} />
        ) : (
          <FontAwesomeIcon icon={faBoxArchive} />
        )}
      </div>
    </TabbedButton>
  );
};

const ArchivePartnerModalContent = ({
  archive,
  partnerId,
  controls,
  afterArchive,
}: ArchivePartnerModalProps) => {
  const [reason, setReason] = useState("");

  const handleArchive = async () => {
    logger.notify(
      NotificationType.INFO,
      archive ? "Archiving the partner" : "Reinstating the partner",
    );
    controls.busy(true);

    const payload = {
      partnerId,
      archive,
      reason,
    };

    try {
      const response = await post(API.PARTNER_ARCHIVE(), payload);
      controls.close();
      logger.notify(
        NotificationType.SUCCESS,
        archive ? "Partner archived" : "Partner reinstated",
      );
      return response.data;
    } catch (error) {
      controls.busy(false);
      logger.notify(NotificationType.ERROR, "Archive Partner failed", error);
    } finally {
      afterArchive();
    }
  };

  return (
    <>
      <InputStringPrimitive
        name="archive-modal-reason"
        value={reason}
        onChangeValue={setReason}
        placeholder="Reason"
      />
      <TabbedButton
        onClick={handleArchive}
        label={archive ? "Archive" : "Unarchive"}
        style={TabbedButtonType.PRIMARY}
        addClassName={cx("archive-button")}
      />
    </>
  );
};

export default ArchivePartner;
