import * as React from "react";

import {
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
} from "../../types/Questionnaire";
import { getQuestionnaireDefinition } from "modules/workflow/helpers/questionnaireSchemaParser";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import Party, { RelationshipType } from "modules/parties/types/Party";

import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import SummaryPage from "./SummaryPage";

import styles from "./styles.module.scss";
import { WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import { isPaid } from "modules/workflow/helpers/lifecycle";
import Addon from "modules/product/types/Addon";
import { isRelatedAs } from "modules/parties/helpers/getRelationshipToClient";
import { ClientType } from "modules/parties/types/Client";
import { PaymentActionType } from "modules/api/types/Payment";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import SummaryAction from "./SummaryAction";
import LoadingOverlay from "components/LoadingOverlay";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";

type QuestionnaireSummaryProps = {
  allRelatedParties: Party[];
  clients: ClientsMap;
  isEditable: boolean;
  onBack?: () => any;
  onCancelPreview?: () => any;
  onConfirmSummary: () => Promise<void>;
  onEditPage?:
    | ((pageIdx: number, pageId: string, pageCaption?: string) => void)
    | null;
  onNext?: () => any;
  onPreviewDocuments: () => Promise<void>;
  onRejectLawyerReview: () => Promise<void>;
  onTriggerPayment: (paymentType: PaymentActionType) => void;
  preselectedAddons?: Addon[];
  questionnaireAnswers: AnswersByQuestionnaireType;
  questionnairePages: QuestionnairePage[];
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire;
  summaryPages: QuestionnairePage[];
  workflowState: WorkflowPayloadState;
};

const QuestionnaireSummary: React.FC<QuestionnaireSummaryProps> = ({
  allRelatedParties,
  clients,
  isEditable,
  onBack,
  onCancelPreview,
  onConfirmSummary,
  onEditPage,
  onNext,
  onPreviewDocuments,
  onRejectLawyerReview,
  onTriggerPayment,
  preselectedAddons,
  questionnaireAnswers,
  questionnairePages,
  questionsBySubquestionnaire,
  summaryPages,
  workflowState,
}) => {
  const { isLoadingWorkflowDetails } = useSelector(
    (state) => state,
    shallowEqual,
  );
  const children = allRelatedParties.filter((party) =>
    isRelatedAs(party, clients[ClientType.MAIN], RelationshipType.CHILD),
  );
  const {
    pages: filteredQuestionnairePages,
    questions: filteredQuestionnaireQuestions,
  } = getQuestionnaireDefinition(
    questionnairePages,
    questionsBySubquestionnaire,
    questionnaireAnswers,
    clients,
    workflowState,
    preselectedAddons,
    children,
  );

  const { pages: filteredSummaryPages } = getQuestionnaireDefinition(
    summaryPages,
    questionsBySubquestionnaire,
    questionnaireAnswers,
    clients,
    workflowState,
    preselectedAddons,
    children,
  );

  const handleEditPage = (page: QuestionnairePage) => {
    const questionnairePage = filteredQuestionnairePages.find(
      (p) => p.id === page.id && p.questionnaireId === page.questionnaireId,
    );
    const pageIdx =
      questionnairePage &&
      filteredQuestionnairePages.indexOf(questionnairePage);
    questionnairePage &&
      onEditPage &&
      onEditPage(pageIdx!, questionnairePage.id, questionnairePage.caption);
  };

  if (isLoadingWorkflowDetails) return <LoadingOverlay />;

  return (
    <div className={styles["summary"]}>
      {filteredSummaryPages.map((page, idx) => (
        <SummaryPage
          key={`${page.questionnaireId}_${page.id}_${idx}`}
          page={page}
          allQuestions={filteredQuestionnaireQuestions}
          allRelatedParties={allRelatedParties}
          clients={clients}
          onEdit={onEditPage ? handleEditPage.bind(null, page, idx) : null}
          answers={questionnaireAnswers[page.questionnaireId]}
        />
      ))}
      <SummaryAction
        {...{
          isEditable,
          onNext,
          onCancelPreview,
          onPreviewDocuments,
          onRejectLawyerReview,
          onConfirmSummary,
          onTriggerPayment,
          workflowState,
        }}
      />
      {!isPaid ? (
        <footer>
          <TabbedButton
            style={TabbedButtonType.SECONDARY}
            label={"Back"}
            onClick={onBack}
          />
        </footer>
      ) : null}
    </div>
  );
};

export default QuestionnaireSummary;
