export enum ChevronColour {
  GREEN,
  NAVY,
  WHITE,
}

export enum ButtonSize {
  XSMALL,
  SMALL,
  MEDIUM,
  LARGE,
}

export type LandingButtonProps = {
  label: string;
  onClick?: (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  chevronColour: ChevronColour;
  chevronRight: boolean;
  size?: ButtonSize;
};
