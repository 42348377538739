import { FormikProps } from "formik";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import {
  Question,
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import React from "react";
import Link from "components/Link";
import ImportAnswers from "modules/workflow/components/InterviewQuestionnaire/ImportAnswers";
import { FormSubmitHandle } from "components/Forms/Form";
import { getFirstNamePossessive } from "modules/parties/types/Party";
import styles from "./styles.module.scss";
import { getPageQuestions } from "modules/workflow/helpers/getPageQuestions";

type CoupledWorkflowPromptProps = {
  answers?: AnswersByQuestionnaireType;
  clients: ClientsMap | undefined;
  coupledWorkflowAnswers?: AnswersByQuestionnaireType;
  questionnairePage?: QuestionnairePage;
  questionsBySubquestionnaire?: QuestionsMapByQuestionnaire;
  currentPageQuestions?: Question[];
  submitHandle?: FormSubmitHandle;
  formHandle?: FormikProps<AnswersMap>;
  handleSwitchToSpouse?: (
    submitHandle?: FormSubmitHandle | undefined,
  ) => Promise<void> | undefined;
};

const CoupledWorkflowPrompt: React.FunctionComponent<
  CoupledWorkflowPromptProps
> = ({
  answers,
  clients,
  coupledWorkflowAnswers,
  questionnairePage,
  questionsBySubquestionnaire,
  currentPageQuestions,
  submitHandle,
  formHandle,
  handleSwitchToSpouse,
}) => {
  if (
    !questionnairePage ||
    !questionsBySubquestionnaire ||
    !currentPageQuestions ||
    !handleSwitchToSpouse ||
    !clients?.main
  ) {
    return null;
  }

  const { questionnaireId } = questionnairePage;
  const allPageQuestions = getPageQuestions(
    questionnairePage,
    questionsBySubquestionnaire[questionnaireId],
  );

  const spouseAnswers =
    coupledWorkflowAnswers && coupledWorkflowAnswers[questionnaireId];

  const spouseNamePossessive = getFirstNamePossessive(
    clients?.spouse,
    "Spouse",
  );

  const mainNamePossessive = clients?.main
    ? getFirstNamePossessive(clients?.main, "Main")
    : "Main";

  const openCoupledWorkflowLink = (
    <Link onClick={() => handleSwitchToSpouse(submitHandle)}>
      Switch to <span data-clarity-mask="true">{spouseNamePossessive}</span>{" "}
      interview
    </Link>
  );

  return (
    <div className={styles["interview-actions"]}>
      <span>
        Currently in: <b data-clarity-mask="true">{mainNamePossessive}</b>{" "}
        interview
      </span>
      {openCoupledWorkflowLink}
      {answers && (
        <ImportAnswers
          pageQuestionnaireId={questionnaireId}
          allPageQuestions={allPageQuestions}
          clientAnswers={answers}
          spouseAnswers={spouseAnswers}
          formHandle={formHandle}
          currentPageQuestions={currentPageQuestions}
          spouseId={clients?.spouse?.clientId}
          clientId={clients?.main?.clientId}
        />
      )}
    </div>
  );
};

export default CoupledWorkflowPrompt;
