import * as React from "react";
import { useCallback, useRef } from "react";
import Party, { getFullName } from "modules/parties/types/Party";
import Client from "modules/parties/types/Client";
import getRelationshipToClient from "modules/parties/helpers/getRelationshipToClient";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { ModalType, showModalConfirm, ModalControls } from "components/Modal";
import { IconType } from "components/Icons";
import styles from "./styles.module.scss";

type RelationshipRepeaterItemProps = {
  client?: Client;
  isEditable: boolean;
  onEdit: (party: Party) => any;
  onRemove: (party: Party) => any;
  parentActionBtn?: {
    label: string;
    onClick: (party: Party) => any;
  };
  party: Party;
  renderChildren?: boolean;
};

const RelationshipRepeaterItem: React.FunctionComponent<
  RelationshipRepeaterItemProps
> = ({
  client,
  isEditable,
  onEdit,
  onRemove,
  parentActionBtn,
  party,
  renderChildren,
}) => {
  const modalControls = useRef<ModalControls | undefined>(undefined);
  const getModalControls = useCallback((controls: ModalControls) => {
    modalControls.current = controls;
  }, []);
  const handleConfirm = useCallback(
    async (party: Party) => {
      modalControls?.current?.busy(true);
      try {
        await onRemove(party);
        modalControls?.current?.close();
      } catch (e) {
        modalControls?.current?.busy(false);
      }
    },
    [onRemove],
  );
  const handleCancel = useCallback(() => {
    modalControls?.current?.close();
  }, []);
  return (
    <li className={styles["relationship-repeater-item"]}>
      <div className={styles["relationship-repeater-item__row"]}>
        <div className={styles["relationship-repeater-item__row"]}>
          <div className={styles["relationship-repeater-item__col"]}>
            <div
              className={styles["relationship-repeater-item__name"]}
              data-clarity-mask="true"
            >
              {getFullName(party)}
            </div>
            {party.relationships && party.relationships.length ? (
              <div className={styles["relationship-repeater-item__info"]}>
                <span className={styles["relationship-repeater-item__label"]}>
                  Relationship:
                </span>{" "}
                {getRelationshipToClient(party, client)}
              </div>
            ) : null}
            {/* Roles overview not yet supported: https://trello.com/c/YgBlbRyC/360-collect-render-all-roles-of-a-related-party */}
            {/* {party.roles.length && party.roles.length ? (
              <div className={styles["relationship-repeater-item__info"]}>
                <span className={styles["relationship-repeater-item__label"]}>Role:</span> {party.roles?.join(", ")}
              </div>
            ) : null} */}
            {party.deceased && (
              <div className={styles["relationship-repeater-item__info"]}>
                <span className={styles["relationship-repeater-item__label"]}>
                  State:
                </span>{" "}
                (Deceased)
              </div>
            )}
          </div>
        </div>
        <div className={styles["relationship-repeater-item__buttons"]}>
          {parentActionBtn && (
            <TabbedButton
              label={parentActionBtn.label}
              onClick={() => parentActionBtn.onClick(party)}
              mood={TabbedButtonMood.POSITIVE}
              style={TabbedButtonType.PRIMARY}
            />
          )}
          {isEditable && (
            <TabbedButton
              label="Edit"
              onClick={() => onEdit(party)}
              style={TabbedButtonType.SECONDARY}
            />
          )}
          {isEditable && (
            <TabbedButton
              icon={IconType.CROSS}
              mood={TabbedButtonMood.NEGATIVE}
              onClick={() =>
                showModalConfirm({
                  getModalControls: getModalControls,
                  onCancel: handleCancel,
                  onConfirm: () => handleConfirm(party),
                  text: "Please confirm that you are happy to proceed with this action.",
                  title: `Are you sure you want to delete ${getFullName(
                    party,
                  )}?`,
                  maskTitle: true,
                  type: ModalType.WARNING,
                })
              }
              style={TabbedButtonType.SECONDARY}
            />
          )}
        </div>
      </div>
      {renderChildren && party.children && party.children.length > 0 && (
        <ul className={styles["relationship-repeater-item__list"]}>
          {party.children.map((child) => (
            <RelationshipRepeaterItem
              isEditable={isEditable}
              key={child.partyId}
              renderChildren={false}
              party={child}
              client={client}
              onEdit={() => onEdit(child)}
              onRemove={() => onRemove(child)}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default RelationshipRepeaterItem;
