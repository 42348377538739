import React, { useCallback, useEffect, useRef } from "react";

import styles from "./styles.module.scss";

import classNames from "classnames/bind";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";
import NoLawyerEliminiation from "modules/landing/sections/NoLawyerElimination";
import Options from "modules/landing/sections/Options";
import LegalNeeds from "modules/landing/sections/LegalNeeds";
import NearestLawFirm from "modules/landing/sections/NearestLawFirm";
import Hero from "modules/landing/sections/Hero";
import { useLocation } from "react-router-dom";
import { isPath } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";

const cx = classNames.bind(styles);

const LandingScreen = () => {
  const location = useLocation();
  const { partner, isLoadingPartner, isLoadingProducts } = useSelector(
    (state) => ({
      partner: state.partner,
      isLoadingPartner: state.isLoadingPartner,
      isLoadingProducts: state.isLoadingProducts,
    }),
    shallowEqual,
  );

  const optionsRef = useRef<HTMLDivElement>(null);
  const scrollToOptions = useCallback(
    () => optionsRef?.current?.scrollIntoView(),
    [],
  );

  useEffect(() => {
    if (location?.hash === "#essentialDocuments") {
      scrollToOptions();
    }
  }, [location?.hash, scrollToOptions]);

  const isNationalPage = isPath(ROUTE_TEMPLATE.ROOT, location);

  return partner === null && (isLoadingPartner || isLoadingProducts) ? (
    <></>
  ) : (
    <div className={cx("landing-screen")}>
      <Hero
        scrollToOptions={partner ? scrollToOptions : undefined}
        partnerId={partner?.id}
      />
      <section ref={optionsRef} className={cx("options-section")}>
        <Options
          partnerId={isNationalPage ? undefined : partner?.id}
          scrollToOptions={scrollToOptions}
        />
      </section>
      <section>
        <NoLawyerEliminiation />
      </section>

      <section>
        <LegalNeeds partner={isNationalPage ? null : partner} />
      </section>
      {isNationalPage && (
        <section>
          <NearestLawFirm />
        </section>
      )}
    </div>
  );
};

export default LandingScreen;
