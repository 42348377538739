import React, { useCallback, useState } from "react";
import Workflow from "../../modules/workflow/types/Workflow";
import { SectionProgress } from "../../modules/workflow/helpers/getSectionsProgress";
import Client, { ClientType } from "modules/parties/types/Client";
import InterviewQuestionnaire from "modules/workflow/components/InterviewQuestionnaire";
import Party from "modules/parties/types/Party";
import Addon from "modules/product/types/Addon";
import getRelationshipToClient from "modules/parties/helpers/getRelationshipToClient";
import Address from "modules/parties/types/Address";
import { AddressSearchResultsType } from "modules/address/actions";
import {
  QuestionAnnotation,
  QuestionID,
  QuestionnaireID,
} from "modules/workflow/types/Questionnaire";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
  AnswerValueType,
} from "modules/workflow/types/Answer";
import {
  ClientsMap,
  WorkflowDocumentType,
} from "modules/api/types/WorkflowDetailsPayload";
import AddPartnerDocuments, {
  PartnerDocumentType,
} from "modules/workflow/components/AddPartnerDocuments";
import { sumBy } from "lodash";
import { PaymentActionType } from "modules/api/types/Payment";
import { useWorkflowDetails } from "./context";
import { User } from "modules/auth/User";

import { getSpouseRelatedParties } from "modules/workflow/helpers/workflowDataHelper";
import { isAdmin } from "../../modules/auth/helper";

type WorkflowDetailsScreenProps = {
  allAvailableAddresses: Address[];
  annotatedAdvisorAnswers?: Record<QuestionAnnotation, AnswerValueType>;
  cancelAddParty: () => void;
  cancelEditParty: () => void;
  clients?: ClientsMap;
  coupledWorkflow?: Workflow;
  isClientEditable: boolean;
  isEditable: boolean;
  isLoadingRenderedDocuments: boolean;
  onAddressLookup: (addressId: string) => Promise<Address | undefined>;
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>;
  onCancelRender: () => any;
  onChangeAddons: (selectedAddons: string[]) => Promise<void>;
  onChangeQuestionnairePage: (
    pageIdx: number,
    pageId?: string,
    pageCaption?: string,
    initialLoad?: boolean,
  ) => any;
  onClientContacted: () => any;
  onCloneWorkflow: () => any;
  onConfirmSummary: () => Promise<void>;
  onDeleteChild: (child: Party) => Promise<any>;
  onExit: () => any;
  onImportChild: (children: Party[]) => void;
  onInterviewAnswersChange: (answers: AnswersByQuestionnaireType) => void;
  onOpenPeopleRegistry: () => any;
  onRejectLawyerReview: () => Promise<void>;
  onRenderDocuments: (documentType?: WorkflowDocumentType) => any;
  onSaveClientDetails: (
    clientType: ClientType,
    clientId: string | undefined,
    clientDetails: Partial<Client>,
    isDelta?: boolean,
  ) => any;
  onSavePageAnswers: (
    questionnaireId: string,
    values: AnswersMap,
    sourcePageId?: string,
  ) => any;
  onTriggerPayment: (paymentType: PaymentActionType) => void;
  onValidationErrors?: (errors: object, answers: AnswersMap) => void;
  pageId?: string;
  pageIdx?: number;
  triggerAddChild: (currentAnswers: AnswersByQuestionnaireType) => any;
  triggerAddGrandchild: (parentId: string) => any;
  triggerAddParty: () => Promise<Party | undefined>;
  triggerEditParty: (
    party: Party,
    currentAnswers?: AnswersByQuestionnaireType,
  ) => Promise<Party | undefined>;
  triggerImportChild: (
    child: Party,
    grandchild?: Party,
    currentAnswers?: AnswersByQuestionnaireType,
  ) => Promise<Party | undefined>;
  unsavedInterviewAnswers: AnswersByQuestionnaireType | null;
  user: User | null;
  validationErrors?: Record<QuestionID, string[]>;
};

const getDocumentType = (
  documentId: string,
): PartnerDocumentType | undefined => {
  if (documentId.includes("will")) return PartnerDocumentType.WILL;
  if (documentId.includes("hcp")) return PartnerDocumentType.HEALTHCARE_PROXY;
  if (documentId.includes("poa")) return PartnerDocumentType.POWER_OF_ATTORNEY;
  if (documentId.includes("medicaid")) return PartnerDocumentType.MEDICAID;
  return;
};

const createCloneWorkflowBanner = (
  workflow: Workflow,
  onClone: () => void,
  user: User | null,
): JSX.Element => {
  if (workflow.plan) {
    return workflow.plan.price ? (
      <AddPartnerDocuments
        type={PartnerDocumentType.WILL_PLAN}
        price={workflow.plan.price.coupled}
        onAccept={onClone}
        showPricing={!(user && isAdmin(workflow.partnerId, user))}
      />
    ) : (
      <></>
    );
  } else {
    const documentIDs = (workflow.products || [])
      .map((p) => p.documents.documents)
      .flat();
    const type =
      documentIDs.length === 1 ? getDocumentType(documentIDs[0]) : undefined;
    const totalCoupledPrice = sumBy(
      workflow.products || [],
      (product) => product.price.coupled,
    );

    const shouldRenderBanner = workflow.products?.every(
      (product) =>
        product.features.allowCoupledWorkflow === undefined ||
        product.features.allowCoupledWorkflow === true,
    );

    return shouldRenderBanner ? (
      <AddPartnerDocuments
        type={type}
        price={totalCoupledPrice}
        onAccept={onClone}
        showPricing={!(user && isAdmin(workflow.partnerId, user))}
      />
    ) : (
      <></>
    );
  }
};

const WorkflowDetailsScreen: React.FunctionComponent<
  WorkflowDetailsScreenProps
> = ({
  allAvailableAddresses,
  annotatedAdvisorAnswers,
  clients,
  coupledWorkflow,
  isClientEditable,
  isEditable,
  isLoadingRenderedDocuments,
  onAddressLookup,
  onAddressSearch,
  onCancelRender,
  onChangeAddons,
  onChangeQuestionnairePage,
  onClientContacted,
  onCloneWorkflow,
  onConfirmSummary,
  onDeleteChild,
  onExit,
  onImportChild,
  onInterviewAnswersChange,
  onOpenPeopleRegistry,
  onRejectLawyerReview,
  onRenderDocuments,
  onSaveClientDetails,
  onSavePageAnswers,
  onTriggerPayment,
  onValidationErrors,
  pageId,
  pageIdx,
  triggerAddChild,
  triggerAddGrandchild,
  triggerAddParty,
  triggerEditParty,
  triggerImportChild,
  unsavedInterviewAnswers,
  user,
  validationErrors,
}) => {
  const { isAdmin, workflow } = useWorkflowDetails();
  const client = clients && clients[ClientType.MAIN];
  const [questionnaireProgress, setQuestionnaireProgress] = useState({
    allQuestionsIDs: {} as Record<QuestionnaireID, QuestionID[]>,
    answeredQuestionsIDs: {} as Record<QuestionnaireID, QuestionID[]>,
    sectionsProgress: [] as SectionProgress[],
    selectedAddons: [] as Addon[],
  });

  const currentSection =
    pageId !== undefined
      ? questionnaireProgress.sectionsProgress.find(
          (section) => section.pageId === pageId,
        )
      : undefined;
  const currentPageIdx =
    (currentSection
      ? questionnaireProgress.sectionsProgress.indexOf(currentSection)
      : pageIdx) || 0;

  const captureChildren = Boolean(
    (workflow?.products || []).find(
      (product) => product.features.children === true,
    ),
  );

  const spouseRelatedParties = getSpouseRelatedParties(workflow);

  const handleProgress = useCallback(
    (
      allQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
      filledQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
      sectionsProgress: SectionProgress[],
      selectedAddons: Addon[],
    ) => {
      setQuestionnaireProgress({
        allQuestionsIDs,
        answeredQuestionsIDs: filledQuestionsIDs,
        sectionsProgress,
        selectedAddons,
      });
    },
    [],
  );

  const renderCloneWorkflowBanner = useCallback(
    () =>
      workflow
        ? createCloneWorkflowBanner(workflow, onCloneWorkflow, user)
        : null,
    [onCloneWorkflow, user, workflow],
  );

  const triggerImportParty = useCallback(
    async (party: Party): Promise<Party | undefined> => {
      const parent =
        party.parentId &&
        spouseRelatedParties.children.find(
          ({ partyId }) => party.parentId === partyId,
        );

      return parent
        ? triggerImportChild(parent, party)
        : spouseRelatedParties.children.includes(party)
          ? triggerImportChild(party)
          : triggerEditParty(party);
    },
    [spouseRelatedParties.children, triggerEditParty, triggerImportChild],
  );

  const handleEditPartyQuestionAction = useCallback(
    (party: Party): Promise<Party | undefined> =>
      getRelationshipToClient(party, client)
        ? triggerEditParty(party)
        : triggerImportParty(party),
    [client, triggerEditParty, triggerImportParty],
  );

  const questionActions = {
    onAddParty: triggerAddParty,
    onEditParty: handleEditPartyQuestionAction,
  };

  return (
    <div>
      <InterviewQuestionnaire
        allAvailableAddresses={allAvailableAddresses}
        annotatedAdvisorAnswers={annotatedAdvisorAnswers}
        captureChildren={captureChildren}
        clients={clients}
        coupledWorkflowAnswers={coupledWorkflow?.answers}
        isAdmin={isAdmin}
        isClientEditable={isClientEditable}
        isEditable={isEditable}
        isLoadingRenderedDocuments={isLoadingRenderedDocuments}
        onAddressLookup={onAddressLookup}
        onAddressSearch={onAddressSearch}
        onCancelRender={onCancelRender}
        onChangeAddons={onChangeAddons}
        onClientContacted={onClientContacted}
        onConfirmSummary={onConfirmSummary}
        onDeleteChild={onDeleteChild}
        onExit={onExit}
        onImportChild={onImportChild}
        onInterviewAnswersChange={onInterviewAnswersChange}
        onOpenPeopleRegistry={onOpenPeopleRegistry}
        onPageChanged={onChangeQuestionnairePage}
        onProgress={handleProgress}
        onRejectLawyerReview={onRejectLawyerReview}
        onRenderDocuments={onRenderDocuments}
        onSaveClientDetails={onSaveClientDetails}
        onSavePageAnswers={onSavePageAnswers}
        onTriggerPayment={onTriggerPayment}
        onValidationErrors={onValidationErrors}
        pageId={pageId}
        pageIdx={currentPageIdx}
        questionActions={questionActions}
        questionnaireProgress={questionnaireProgress}
        renderCloneWorkflowBanner={renderCloneWorkflowBanner}
        triggerAddChild={triggerAddChild}
        triggerAddGrandchild={triggerAddGrandchild}
        triggerEditChild={triggerEditParty}
        triggerEditRelatedParty={triggerEditParty}
        unsavedQuestionnairesAnswers={unsavedInterviewAnswers}
        validationErrors={validationErrors}
        workflow={workflow}
      />
    </div>
  );
};

export default WorkflowDetailsScreen;
