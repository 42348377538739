import React from "react";
import type { ConsumerMedicaidLeadItemDescriptionProps } from "./types";
import { MedicaidLeadStatus } from "modules/medicaid/types";

const ConsumerMedicaidLeadItemDescription = ({
  item,
}: ConsumerMedicaidLeadItemDescriptionProps) => {
  switch (item.medicaidLead.status) {
    case MedicaidLeadStatus.InterviewInProgress:
      return (
        <span>
          You still have some questions to answer before we can work out how
          much you can protect.
        </span>
      );
    case MedicaidLeadStatus.ReadyForPartner:
      return (
        <span>
          We have sent your inquiry to your selected law firm and they are
          currently reviewing it.
        </span>
      );
    case MedicaidLeadStatus.LeadAccepted:
      return (
        <span>
          Your inquiry into an Asset Protection Plan has been reviewed by your
          selected law firm and accepted. They will be in touch soon.
        </span>
      );
    case MedicaidLeadStatus.LeadRejected:
      return (
        <span>
          The law firm you selected is not able to provide you with an Asset
          Protection Plan at this time.
        </span>
      );
  }
};

export default ConsumerMedicaidLeadItemDescription;
