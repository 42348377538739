import * as React from "react";
import classNames from "classnames/bind";

import TabbedButton, { TabbedButtonType } from "components/TabbedButton";

import styles from "./styles.module.scss";
import { IconType } from "components/Icons";
const cx = classNames.bind(styles);

type ClientDashboardProductsItemBreakdownItemProps = {
  complete: number;
  isAccessible: boolean;
  onEditPage: (pageIdx: number) => void;
  pageIdx: number;
  title: string;
  total: number;
};

const ClientDashboardProductsItemBreakdownItem: React.FunctionComponent<
  ClientDashboardProductsItemBreakdownItemProps
> = ({ complete, isAccessible, onEditPage, pageIdx, title, total }) => {
  const classNames = cx("client-dashboard-products-item-breakdown-item", {
    "client-dashboard-products-item-breakdown-item--complete":
      complete === total,
    "client-dashboard-products-item-breakdown-item--in-progress":
      complete > 0 && complete < total,
  });
  return (
    <li className={classNames}>
      <div
        className={styles["client-dashboard-products-item-breakdown-item__col"]}
      >
        <div
          className={
            styles["client-dashboard-products-item-breakdown-item__title"]
          }
        >
          {title}
        </div>
        {total ? (
          <div
            className={
              styles["client-dashboard-products-item-breakdown-item__remaining"]
            }
          >
            {total} questions
          </div>
        ) : null}
      </div>
      <div
        className={styles["client-dashboard-products-item-breakdown-item__col"]}
      >
        {total ? (
          <>
            <div
              className={
                styles[
                  "client-dashboard-products-item-breakdown-item__progress-bar"
                ]
              }
            >
              <div
                className={
                  styles[
                    "client-dashboard-products-item-breakdown-item__progress-bar-current"
                  ]
                }
                style={{ width: Math.round((complete / total) * 100) + "%" }}
              />
            </div>
            <div
              className={
                styles[
                  "client-dashboard-products-item-breakdown-item__complete"
                ]
              }
            >
              {Math.round((complete / total) * 100)}% Complete
            </div>
          </>
        ) : null}
      </div>
      <div
        className={styles["client-dashboard-products-item-breakdown-item__col"]}
      >
        <div
          className={
            styles["client-dashboard-products-item-breakdown-item__status"]
          }
        >
          <span>
            {complete === total
              ? "Complete"
              : complete > 0
                ? "In progress"
                : "Not started"}
          </span>
        </div>
      </div>
      <div
        className={styles["client-dashboard-products-item-breakdown-item__col"]}
      >
        {isAccessible && (
          <TabbedButton
            icon={IconType.EDIT}
            style={TabbedButtonType.SECONDARY}
            onClick={() => onEditPage(pageIdx)}
          />
        )}
      </div>
    </li>
  );
};

export default ClientDashboardProductsItemBreakdownItem;
