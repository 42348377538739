import { showModalConfirm } from "components/Modal";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import logger, { TYPE } from "logger";
import { PartnerPermission } from "modules/admin/types/Permissions";
import { API, del } from "modules/api";
import React, { useState } from "react";
import usePermissions from "./usePermissions";

type Props = { permission: PartnerPermission };

const PermissionsConfigurationListItem: React.FC<Props> = ({ permission }) => {
  const { refetchPermissions } = usePermissions();
  const [isPending, setIsPending] = useState(false);

  const handleDelete = async () => {
    try {
      setIsPending(true);
      await del(
        API.PERMISSION({
          permissionId: permission.user.userId,
          role: permission.role,
        }),
      );
      refetchPermissions(
        (permissions) =>
          permissions &&
          permissions.filter(
            (perm) => perm.user.userId !== permission.user.userId,
          ),
      );
    } catch (error) {
      logger.notify(
        TYPE.ERROR,
        "Deleting the user failed. Please, try again later",
        error,
      );
      setIsPending(false);
    }
  };

  return (
    <tr key={permission.user.userId}>
      <td data-clarity-mask="true">{permission.user.name}</td>
      <td data-clarity-mask="true">{permission.user.email}</td>
      <td>{permission.role}</td>
      <td>
        <TabbedButton
          onClick={() =>
            showModalConfirm({
              labelConfirm: "Delete",
              onConfirm: () => handleDelete(),
              title: "Delete permission?",
              text: `Please confirm that you want to delete ${permission.role} permission for user ${permission.user.name}.`,
            })
          }
          style={TabbedButtonType.SECONDARY}
          disabled={isPending}
          mood={TabbedButtonMood.NEGATIVE}
        >
          {isPending ? "Deleting..." : "Delete"}
        </TabbedButton>
      </td>
    </tr>
  );
};

export default PermissionsConfigurationListItem;
