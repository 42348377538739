import React, { useEffect, useState } from "react";
import usePrevious from "utils/usePrevious";
import PaginationControl from "./PaginationControl";

import classNames from "classnames/bind";

import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

export enum ListType {
  GRID,
  LIST,
  TABLE,
}

export enum PaginatedListColour {
  DEFAULT,
  WHITE,
}

type PaginatedListProps<T> = {
  id?: string;
  initialPageNumber?: number;
  itemNamePlural?: string;
  items: T[];
  listType?: ListType;
  pageSize: number;
  renderItem: (item: T, index?: number) => JSX.Element;
  sortItems?: (itemA: T, idemB: T) => number;
  tableColumns?: Array<{
    label: string;
    accessor: string;
    sortable: boolean;
    reset: boolean;
  }>;
  sortField?: string;
  setSortField?: React.Dispatch<React.SetStateAction<string>>;
  order?: string;
  setOrder?: React.Dispatch<React.SetStateAction<string>>;
  customFirstColumn?: boolean;
  colour?: PaginatedListColour;
};

function PaginatedList<T>(props: PaginatedListProps<T>) {
  const {
    id,
    initialPageNumber,
    itemNamePlural,
    items,
    listType = ListType.LIST,
    pageSize,
    renderItem,
    sortItems,
    tableColumns,
    sortField,
    setSortField,
    order,
    setOrder,
    customFirstColumn,
    colour = PaginatedListColour.DEFAULT,
  } = props;

  const className = cx("paginated-list", {
    "paginated-list--grid": listType === ListType.GRID,
    "paginated-list--white":
      listType === ListType.LIST && colour === PaginatedListColour.WHITE,
  });

  const [currentPage, setCurrentPage] = useState<number>(
    initialPageNumber || 1,
  );

  const sortedItems = [...(sortItems ? items.sort(sortItems) : items)];
  const totalPages = Math.ceil(items.length / pageSize);

  const previousItems = usePrevious(items);
  useEffect(() => {
    if (items.length !== previousItems?.length) {
      setCurrentPage(1);
    }
  }, [items.length, previousItems]);

  const renderList = (items: T[]) => (
    <ul className={className}>
      {items.length ? (
        sortedItems
          .splice((currentPage - 1) * pageSize, pageSize)
          .map(renderItem)
      ) : (
        <li className={styles["paginated-list-empty"]}>{`No ${
          itemNamePlural || "items"
        } found`}</li>
      )}
    </ul>
  );

  const renderTable = (
    items: T[],
    columns?: Array<{
      label: string;
      accessor: string;
      sortable: boolean;
      reset: boolean;
    }>,
  ) => (
    <table id={id} className={styles["paginated-list__table"]}>
      {columns && (
        <thead>
          <tr>
            {customFirstColumn !== undefined && customFirstColumn && <th></th>}
            {columns.map(({ label, accessor, sortable, reset }) => {
              const thClassStyle = "paginated-list__table__th__none";
              return (
                <th
                  className={styles[thClassStyle]}
                  key={accessor}
                  onClick={() => handleSortingChange(accessor, reset)}
                >
                  <div
                    className={styles["paginated-list__table__flex_container"]}
                  >
                    <div
                      className={
                        styles["paginated-list__table__flex_child_left"]
                      }
                    >
                      {label}&nbsp;
                    </div>
                    {sortField === accessor && sortable && order === "asc" ? (
                      <div
                        className={
                          styles["paginated-list__table__flex_child_right"]
                        }
                      >
                        <img
                          src={require("../Icons/png/up_arrow.png")}
                          alt={"ascending sort"}
                        />
                      </div>
                    ) : sortField === accessor &&
                      sortable &&
                      order === "desc" ? (
                      <div
                        className={
                          styles["paginated-list__table__flex_child_left"]
                        }
                      >
                        <img
                          src={require("../Icons/png/down_arrow.png")}
                          alt={"descending sort"}
                        />
                      </div>
                    ) : sortable ? (
                      <div
                        className={
                          styles["paginated-list__table__flex_child_right"]
                        }
                      >
                        <img
                          src={require("../Icons/png/default.png")}
                          alt={"default sort"}
                        />
                      </div>
                    ) : reset ? (
                      <div
                        className={
                          styles["paginated-list__table__flex_child_reset"]
                        }
                      >
                        <img
                          src={require("../Icons/png/reset.png")}
                          alt={"reset sort order"}
                        />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {items.length ? (
          sortedItems
            .splice((currentPage - 1) * pageSize, pageSize)
            .map(renderItem)
        ) : (
          <tr>
            <td colSpan={columns?.length || 1}>{`No ${
              itemNamePlural || "items"
            } found`}</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  const handleSortingChange = (accessor: string, reset: boolean) => {
    if (reset) {
      setSortField && setSortField("");
    } else {
      const sortOrder =
        accessor === sortField && order === "asc" ? "desc" : "asc";
      setSortField && setSortField(accessor);
      setOrder && setOrder(sortOrder);
    }
  };

  return (
    <>
      {listType === ListType.LIST || listType === ListType.GRID
        ? renderList(items)
        : renderTable(items, tableColumns)}
      {totalPages > 1 && (
        <PaginationControl
          currentPage={currentPage}
          itemCount={items.length}
          itemLimit={pageSize}
          onPageChange={setCurrentPage}
          totalPages={totalPages}
          colour={colour}
        />
      )}
    </>
  );
}

export default PaginatedList;
