import * as React from "react";
import { ReactComponent as GuidrLegalLogo } from "resources/logos/Aturna_Brandmark_RGB_White.svg";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type LogoProps = {
  empoweredBy?: string;
  multiline?: boolean;
  onClick?: () => void;
};

const Logo: React.FC<LogoProps> = ({
  empoweredBy,
  multiline = true,
  onClick,
}) => (
  <div
    className={cx("logo", {
      "logo--multiline": multiline,
      "logo--clickable": onClick !== undefined,
    })}
    onClick={onClick}
  >
    <div className={cx("logo__powered_by")}>
      Powered by <GuidrLegalLogo />
    </div>
    <div className={cx("logo__empowered")}>
      {empoweredBy ? (
        <>
          empowered by <span>{empoweredBy}</span>
        </>
      ) : null}
    </div>
  </div>
);

export default Logo;
