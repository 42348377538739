import React, { useCallback } from "react";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  QuestionAnnotation,
  QuestionID,
  QuestionnaireID,
  QuestionnairePage,
  QuestionnaireProgress,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import Client, { ClientType } from "modules/parties/types/Client";
import getClientQuestionnaire from "modules/parties/helpers/getClientQuestionnaire";
import { SectionProgress } from "../../helpers/getSectionsProgress";
import processQuestions from "../../helpers/processQuestions";
import Party, {
  ClientParties,
  getGrandchildren,
} from "modules/parties/types/Party";
import getAssembleDocumentsPage from "../../staticPages/getAssembleDocumentsPage";
import getChildrenQuestionnaire from "modules/parties/helpers/getChildrenQuestionnaire";
import Addon from "modules/product/types/Addon";
import { shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";
import { GenericQuestionProps } from "../GenericQuestion";
import Address from "modules/parties/types/Address";
import { AddressSearchResultsType } from "modules/address/actions";
import getAllQuestions from "../../helpers/getAllQuestions";
import { getInitialValuesByQuestionnaire } from "../../helpers/validation";
import getSpouseQuestionnaire from "modules/parties/helpers/getSpouseQuestionnaire";
import getSummaryPage from "../../staticPages/getSummaryPage";
import { Step, WorkflowAction } from "modules/api/types/WorkflowPayload";
import {
  ClientsMap,
  WorkflowDocumentType,
} from "modules/api/types/WorkflowDetailsPayload";
import getPeopleSummaryPage from "modules/parties/helpers/getPeopleSummaryPage";
import { hasExecutedAction } from "../../helpers/lifecycle";

import mergeAnswers from "modules/workflow/helpers/mergeAnswers";
import { PaymentActionType } from "modules/api/types/Payment";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
  AnswerValueType,
} from "modules/workflow/types/Answer";
import Workflow, {
  LAWYER_INITIATED_WORKFLOW,
} from "modules/workflow/types/Workflow";
import { sortBy } from "lodash";
import {
  getPreselectedAddons,
  getSpouseRelatedParties,
  getWorkflowRelatedParties,
} from "modules/workflow/helpers/workflowDataHelper";
import { getExternalProductsPages } from "./questionnaireHelper";
import getExternalProducts from "modules/workflow/helpers/getExternalProducts";
import useWorkflowActions from "modules/workflow/hooks/useWorkflowActions";

type InterviewQuestionnaireProps = Pick<
  GenericQuestionProps,
  "questionActions"
> & {
  allAvailableAddresses: Address[];
  annotatedAdvisorAnswers?: Record<QuestionAnnotation, AnswerValueType>;
  captureChildren: boolean;
  clients?: ClientsMap;
  coupledWorkflowAnswers?: AnswersByQuestionnaireType;
  isAdmin: boolean;
  isClientEditable: boolean;
  isEditable: boolean;
  isLoadingRenderedDocuments: boolean;
  onAddressLookup: (addressId: string) => Promise<Address | undefined>;
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>;
  onCancelRender: () => any;
  onChangeAddons: (selectedAddons: string[]) => Promise<void>;
  onClientContacted: () => any;
  onConfirmSummary: () => Promise<void>;
  onDeleteChild: (child: Party) => Promise<any>;
  onExit: () => any;
  onImportChild: (children: Party[]) => void;
  onInterviewAnswersChange: (answers: AnswersByQuestionnaireType) => void;
  onOpenPeopleRegistry: () => any;
  onPageChanged?: (
    pageIdx: number,
    pageId?: string,
    pageCaption?: string,
    initialLoad?: boolean,
  ) => any;
  onProgress?: (
    allQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
    filledQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
    sectionsProgress: SectionProgress[],
    selectedAddons: Addon[],
  ) => any;
  onRejectLawyerReview: () => Promise<void>;
  onRenderDocuments: (documentType?: WorkflowDocumentType) => any;
  onSaveClientDetails: (
    clientType: ClientType,
    clientId: string | undefined,
    clientDetails: Partial<Client>,
    isDelta?: boolean,
  ) => any;
  onSavePageAnswers: (
    questionnaireId: string,
    values: AnswersMap,
    sourcePageId?: string,
  ) => any;
  onTriggerPayment: (paymentType: PaymentActionType) => void;
  onValidationErrors?: (errors: object, answers: AnswersMap) => any;
  pageId?: string;
  pageIdx: number;
  questionnaireProgress: QuestionnaireProgress;
  renderCloneWorkflowBanner?: () => JSX.Element | null;
  triggerAddChild: (currentAnswers: AnswersByQuestionnaireType) => any;
  triggerAddGrandchild: (parentId: string) => any;
  triggerEditChild: (
    child: Party,
    currentAnswers?: AnswersByQuestionnaireType,
  ) => any;
  triggerEditRelatedParty: (party: Party) => any;
  unsavedQuestionnairesAnswers: AnswersByQuestionnaireType | null;
  validationErrors?: Record<QuestionID, string[]>;
  workflow: Workflow;
};

const InterviewQuestionnaire = ({
  allAvailableAddresses,
  annotatedAdvisorAnswers,
  captureChildren,
  clients,
  coupledWorkflowAnswers,
  isAdmin,
  isClientEditable,
  isEditable,
  isLoadingRenderedDocuments,
  onAddressLookup,
  onAddressSearch,
  onCancelRender,
  onClientContacted,
  onConfirmSummary,
  onDeleteChild,
  onExit,
  onImportChild,
  onInterviewAnswersChange,
  onOpenPeopleRegistry,
  onPageChanged,
  onProgress,
  onRejectLawyerReview,
  onRenderDocuments,
  onSaveClientDetails,
  onSavePageAnswers,
  onTriggerPayment,
  onValidationErrors,
  pageId,
  pageIdx,
  questionActions,
  questionnaireProgress,
  renderCloneWorkflowBanner,
  triggerAddChild,
  triggerAddGrandchild,
  triggerEditChild,
  triggerEditRelatedParty,
  unsavedQuestionnairesAnswers,
  validationErrors,
  workflow,
}: InterviewQuestionnaireProps) => {
  const availableWorkflows = useSelector((state) => state.availableWorkflows);
  const isLoadingWorkflowDetails = useSelector(
    (state) => state.isLoadingWorkflowDetails,
  );
  const { handleNavigationRequest, handleSwitchToSpouse } =
    useWorkflowActions();
  const { user } = useSelector((state) => state, shallowEqual);
  const draftingFormAnswers = useSelector((state) => state.draftingFormAnswers);

  const onPreviewDocuments = useCallback(
    () => onRenderDocuments(),
    [onRenderDocuments],
  );

  const isCoupledWorkflow = Boolean(workflow.state.coupledWorkflowId);
  const questionnairesAnswers = workflow.answers || {};
  const subquestionnaires = sortBy(workflow.questionnaires || [], [
    "priority",
    "id",
  ]);

  const relatedParties: ClientParties = getWorkflowRelatedParties(
    workflow,
    clients?.main,
  );
  const spouseRelatedParties: ClientParties = getSpouseRelatedParties(workflow);

  const workflowUser =
    workflow.creator === LAWYER_INITIATED_WORKFLOW ? workflow.user : user;
  const client = clients && clients[ClientType.MAIN];
  const spouse = clients && clients[ClientType.SPOUSE];
  const isPrimaryWorkflow = workflow.state.primary;
  const userStates = workflowUser?.state ? [workflowUser.state] : undefined;

  const {
    questionnairePages: clientPages,
    initialValues: clientValues,
    questions: clientQuestions,
  } = getClientQuestionnaire(
    onSaveClientDetails.bind(null, ClientType.MAIN),
    onAddressLookup,
    onAddressSearch,
    allAvailableAddresses,
    client,
    isAdmin,
    { ...annotatedAdvisorAnswers, ...draftingFormAnswers },
    isCoupledWorkflow,
    // TODO DEMO code
    renderCloneWorkflowBanner,
    // TODO END DEMO code
    isPrimaryWorkflow,
    workflowUser,
    !isClientEditable,
    userStates,
  );

  const preselectedAddons: Addon[] = getPreselectedAddons(workflow);

  const {
    questionnairePages: spousePages,
    initialValues: spouseValues,
    questions: spouseQuestions,
  } = getSpouseQuestionnaire(
    onSaveClientDetails.bind(null, ClientType.SPOUSE),
    onAddressLookup,
    onAddressSearch,
    allAvailableAddresses,
    spouse,
    annotatedAdvisorAnswers,
    !isClientEditable,
    userStates,
  );

  // Use default values
  const allQuestionsByQuestionnaire: QuestionsMapByQuestionnaire = {
    ...getAllQuestions(subquestionnaires),
    ...clientQuestions,
    ...spouseQuestions,
  };

  const assembleDocumentsPage = getAssembleDocumentsPage(
    isLoadingRenderedDocuments,
    onRenderDocuments,
    onClientContacted,
    workflow.state.steps,
    user, // TODO added temporarily for https://trello.com/c/J6d8xAUk/1115-force-user-to-use-supervised-signing
    getExternalProducts(workflow),
    workflow.state.onlineProduct,
  );

  const allAnswersByQuestionnaire: AnswersByQuestionnaireType = {
    ...getInitialValuesByQuestionnaire(
      allQuestionsByQuestionnaire,
      questionnairesAnswers,
    ),
    ...clientValues,
    ...spouseValues,
  };

  const handleEditChild = (child: Party) =>
    triggerEditChild(child, allAnswersByQuestionnaire);

  // Children
  let childrenPages: QuestionnairePage[] = [];
  if (captureChildren) {
    const { questionnairePages } = getChildrenQuestionnaire(
      client,
      relatedParties.children,
      spouseRelatedParties.children,
      () => triggerAddChild(allAnswersByQuestionnaire),
      triggerAddGrandchild,
      handleEditChild,
      onDeleteChild,
      onImportChild,
      isEditable,
    );
    childrenPages = questionnairePages;
  }

  const allAnswers = mergeAnswers(
    allAnswersByQuestionnaire,
    unsavedQuestionnairesAnswers || {},
  );
  const processedQuestions = processQuestions(
    clients,
    relatedParties,
    spouseRelatedParties,
    workflow.addons,
    allQuestionsByQuestionnaire,
    subquestionnaires,
    allAnswers,
    !workflow.state.primary,
    {
      onAddressLookup,
      onAddressSearch,
      availableAddresses: allAvailableAddresses,
    },
  );

  const dataEntryPages = [
    ...clientPages,
    ...spousePages,
    ...childrenPages,
    ...subquestionnaires
      .filter((qn) => qn.productQuestionnaire)
      .map(({ pages }) => pages)
      .flat(),
    ...getExternalProductsPages(workflow),
  ];

  const peopleSummaryPage = getPeopleSummaryPage(
    relatedParties,
    handleEditChild,
    triggerEditRelatedParty,
    clients,
    isEditable,
  );

  const summaryPage = getSummaryPage(
    clients || ({} as ClientsMap),
    [
      ...(relatedParties.parties || []),
      ...(relatedParties.children || []),
      ...getGrandchildren(relatedParties.children || []),
    ],
    [
      ...clientPages,
      ...spousePages,
      ...subquestionnaires.map(({ pages }) => pages || []).flat(),
      peopleSummaryPage,
    ],
    dataEntryPages,
    processedQuestions,
    allAnswersByQuestionnaire,
    preselectedAddons,
    workflow.state,
    onConfirmSummary,
    onRejectLawyerReview,
    onPreviewDocuments,
    onCancelRender,
    onTriggerPayment,
    isEditable,
    workflow.creator,
    workflow.creatorName,
    onPageChanged,
  );

  const allPages = [
    ...(isEditable ? dataEntryPages : []),
    summaryPage,
    ...(workflow.state.steps.current.step !== Step.PaymentSubmitted
      ? [assembleDocumentsPage]
      : []),
  ];

  return (
    <QuestionnaireForm
      answers={allAnswersByQuestionnaire}
      children={relatedParties.children}
      clients={clients}
      coupledWorkflowAnswers={coupledWorkflowAnswers}
      enforcePageCompletion={!isAdmin}
      forceSubmitPageOnForward={
        !hasExecutedAction(workflow.state.steps, WorkflowAction.ConfirmSummary)
      }
      key={workflow.id}
      onChange={onInterviewAnswersChange}
      onExit={onExit}
      onOpenPeopleRegistry={onOpenPeopleRegistry}
      onPageChanged={onPageChanged}
      onProgress={onProgress}
      onSavePageAnswers={onSavePageAnswers}
      onValidationErrors={onValidationErrors}
      pageId={pageId}
      pageIdx={pageIdx}
      preselectedAddons={preselectedAddons}
      questionActions={questionActions}
      questionnaireAnswers={allAnswersByQuestionnaire}
      questionnairePages={allPages}
      questionnaireProgress={questionnaireProgress}
      questionsBySubquestionnaire={processedQuestions}
      validationErrors={validationErrors}
      workflowState={workflow.state}
      handleSwitchToSpouse={handleSwitchToSpouse}
      handleNavigationRequest={handleNavigationRequest}
      workflow={workflow}
      workflowId={workflow.id}
      isAdmin={isAdmin}
      availableWorkflows={availableWorkflows}
      isLoading={isLoadingWorkflowDetails}
      userJurisdiction={user?.state}
    />
  );
};

export default InterviewQuestionnaire;
