import TabbedButton from "components/TabbedButton";
import { FieldArray, FieldArrayConfig, useField } from "formik";
import GenericQuestion from "modules/workflow/components/GenericQuestion";
import { Question } from "modules/workflow/types/Questionnaire";
import React, { useState } from "react";
import QuestionWrapper from "../QuestionWrapper";
import { QuestionMultipleProps } from "./types";

const DEFAULT_MIN_NUMBER_OF_QUESTIONS = 1;
const DEFAULT_ADD_ANOTHER_LABEL = "+ Add another";

const QuestionMultiple = ({
  name,
  client,
  limits,
  addAnotherLabel,
  baseQuestionDefinition,
  disabled,
  label,
  help,
  size,
}: QuestionMultipleProps) => {
  const [field] = useField(name);
  const [numberOfQuestions, setNumberOfQuestions] = useState(
    field?.value?.answers?.length ||
      limits.min ||
      DEFAULT_MIN_NUMBER_OF_QUESTIONS,
  );

  const addAnother = () => {
    if (limits.max === undefined || numberOfQuestions < limits.max) {
      setNumberOfQuestions(numberOfQuestions + 1);
    }
  };

  const questions: Question[] = Array(numberOfQuestions)
    .fill(undefined)
    .map((_, i) => ({
      id: `${name}.answers.${i}`,
      data: {
        definition: baseQuestionDefinition,
        descriptions: [],
        required: limits.min !== undefined && i < limits.min,
      },
    }));

  return (
    <QuestionWrapper
      disabled={disabled}
      help={help}
      label={label}
      id={name}
      name={name}
      size={size}
    >
      <FieldArray name={name}>
        {(props: FieldArrayConfig) =>
          questions.map((question, idx) => (
            <GenericQuestion
              client={client}
              question={question}
              disabled={disabled}
              key={`${question.id}_${idx}`}
            />
          ))
        }
      </FieldArray>
      <TabbedButton
        label={addAnotherLabel || DEFAULT_ADD_ANOTHER_LABEL}
        onClick={addAnother}
        disabled={disabled || limits.max === numberOfQuestions}
      />
    </QuestionWrapper>
  );
};

export default QuestionMultiple;
