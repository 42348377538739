import PaginatedList, { ListType } from "components/PaginatedList";
import { PartnerFinancialStatus } from "modules/parties/types/Finance";
import React, { useState } from "react";
import PartnerFinanceTableItem from "../PartnerFinanceTableItem";
import { DEFAULT_ORDER, DEFAULT_SORT_FIELD, tableColumns } from "./constants";
import { sortPartnerFinancialStatuses } from "./helpers";
import { PartnerFinanceTableProps } from "./types";

const PartnerFinanceTable = ({
  globalFinancialStatus,
  period,
}: PartnerFinanceTableProps) => {
  const [sortField, setSortField] = useState(DEFAULT_SORT_FIELD);
  const [order, setOrder] = useState(DEFAULT_ORDER);

  const sortedPartnerFinancialStatuses = sortPartnerFinancialStatuses(
    globalFinancialStatus,
    sortField,
    order,
  );

  return (
    <PaginatedList<PartnerFinancialStatus>
      items={sortedPartnerFinancialStatuses}
      itemNamePlural={"partners with paid workflows"}
      pageSize={10}
      renderItem={(item) => (
        <PartnerFinanceTableItem
          key={item.partnerId}
          partnerFinancialStatus={item}
          period={period}
        />
      )}
      listType={ListType.TABLE}
      tableColumns={tableColumns}
      sortField={sortField}
      setSortField={setSortField}
      order={order}
      setOrder={setOrder}
    />
  );
};

export default PartnerFinanceTable;
