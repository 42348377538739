import Gender from "modules/parties/types/Gender";

// Currently when a user selects he/him they're given gender: Gender.MALE, this is just the reverse
const getPartyPronouns = (gender: Gender): string =>
  ({
    [Gender.MALE]: "he/him",
    [Gender.FEMALE]: "she/her",
    [Gender.OTHER]: "they/them",
  })[gender];

export default getPartyPronouns;
