import { PlatformType } from "contexts/PlatformContext";
import { HTMLInputType } from "./types";

const AllowedKeys = [
  "Alt",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "Backspace",
  "CapsLock",
  "Control",
  "Delete",
  "Enter",
  "Escape",
  "Meta",
  "Tab",
  "Unidentified",
];

export class RegularExpressions {
  public static DECIMAL: RegExp = /^\d|^\./;
  public static DECIMAL_ALLOW_NEGATIVE: RegExp = /^\d|^\.|^-/;
  public static INTEGER: RegExp = /^\d/;
  public static INTEGER_ALLOW_NEGATIVE: RegExp = /^\d|^-/;
}

export class Sanitisers {
  public static DECIMAL: RegExp = /[^\d.]/g;
  public static DECIMAL_ALLOW_NEGATIVE: RegExp = /(?!^-)[^\d.]/g;
  public static DECIMAL_PLACES = (places: number): RegExp => {
    return new RegExp("(^-?\\d*.?\\d{0," + places + "})\\d*", "g");
  };
  public static INTEGER: RegExp = /\D+/g;
  public static INTEGER_ALLOW_NEGATIVE: RegExp = /(?!^-)[^\d]/g;
  public static LEADING_NEGATIVE_PERIOD: RegExp = /^-\./g;
  public static LEADING_PERIOD: RegExp = /^\./g;
  public static LEADING_WHITESPACE: RegExp = /^\s+/g;
  public static LEADING_ZEROS: RegExp = /^(-?)(0+(?=[1-9])|0+(?=0))/g;
  public static PERIOD: RegExp = /\./g;
  public static TRAILING_ZEROS: RegExp = /^(\d*[\d.]*?)\.?0*$/g;
}

export const getInputPattern = (platform: PlatformType): string | undefined => {
  return platform.isIOS && Math.trunc(Number(platform.osVersion)) <= 12
    ? "[0-9]*"
    : undefined;
};

export const getMaxLength = (
  digits: number,
  min?: number | string,
  max?: number | string,
): number | undefined => {
  let maxLength = 0;
  if (min !== undefined && max !== undefined) {
    maxLength = Math.max(
      (min &&
        String(min).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length) ||
        0,
      (max &&
        String(max).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length) ||
        0,
    );
  } else if (max !== undefined) {
    maxLength =
      String(max).replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "").length || 0;
  }
  if (maxLength > 0 && digits > 0) {
    maxLength += digits + 1;
  }
  return maxLength > 0 ? maxLength : undefined;
};

export const setNativeInput = (
  input: React.MutableRefObject<HTMLInputElement | null>,
  value: string,
) => {
  const setValue = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  )?.set;
  input.current && setValue && setValue.call(input.current, value);
  input.current &&
    input.current.dispatchEvent(new Event("change", { bubbles: true }));
};

export const setSelectionRange = (
  event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  value: string,
): void => {
  let selectionEnd = event.currentTarget.selectionEnd || 0;
  selectionEnd =
    value.length < event.currentTarget.value.length
      ? selectionEnd - (event.currentTarget.value.length - value.length)
      : selectionEnd + (value.length - event.currentTarget.value.length);
  event.currentTarget.value = value;
  event.currentTarget.setSelectionRange(selectionEnd, selectionEnd);
};

export const handleBlurNumber =
  (onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void) =>
  (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    event.currentTarget.type = HTMLInputType.NUMBER;
  };

export const handleChangeNumber = (
  event: React.ChangeEvent<HTMLInputElement>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  const value = event.currentTarget.value;
  const descriptor = Object.getOwnPropertyDescriptor(
    event.currentTarget,
    "value",
  );
  if (descriptor) {
    const descriptorNew = { ...descriptor };
    descriptorNew.get = function () {
      return value === "" || isNaN(Number(value)) ? "" : Number(value);
    };
    Object.defineProperty(event.currentTarget, "value", descriptorNew);
    onChange && onChange(event);
    Object.defineProperty(event.currentTarget, "value", descriptor);
  }
};

export const handleFocusNumber =
  (onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void) =>
  (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
    event.currentTarget.type = HTMLInputType.TEXT;
  };

export const handleKeyDown =
  (regex: RegExp) =>
  (onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void) =>
  (event: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown && onKeyDown(event);
    if (AllowedKeys.indexOf(event.key) > -1) {
      return;
    }
    if (
      !(event.metaKey || event.ctrlKey) &&
      event.key.length === 1 &&
      !regex.test(event.key)
    ) {
      event.preventDefault();
    }
  };

export const handleInput =
  (sanitiser: (value: string) => string) =>
  (onInput?: (event: React.FormEvent<HTMLInputElement>) => void) =>
  (event: React.FormEvent<HTMLInputElement>) => {
    onInput && onInput(event);
    const sanitised = sanitiser(event.currentTarget.value);
    if (event.currentTarget.value !== sanitised) {
      typeof event.currentTarget.selectionEnd === "number"
        ? setSelectionRange(event, sanitised)
        : (event.currentTarget.value = sanitised);
    }
  };

export function maskCharacter(
  value: string,
  character: string,
  positions: Array<number>,
): string {
  let oldValue = value
    .replace(new RegExp("[^\\d" + character + "]", "g"), "")
    .replace(new RegExp(character + "+", "g"), character);
  let newValue = value.replace(/[^\d]/g, "");
  if (
    oldValue.slice(-1) === character &&
    positions.some(function (position) {
      return oldValue.length === position;
    })
  ) {
    newValue += character;
  }
  let digits = newValue.split("");
  positions.forEach((position) => {
    if (digits.length > position - 1 && digits[position - 1] !== character) {
      digits.splice(position - 1, 0, character);
    }
  });
  return digits.join("");
}
