import { NotificationType } from "components/Notification";
import logger from "logger";
import { API, get } from "modules/api";

export const onClickCsvExport = async (period?: string) => {
  try {
    const response = await get(API.GLOBAL_FINANCIAL_STATUS_REPORT(), {
      period,
    });

    const blob = new Blob([atob(response.data.csvFile)], {
      type: "data:application/octet-stream;base64",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `financial-status-${period || "allTime"}.csv`;
    document.body.append(link);
    link.click();
    link.remove();
  } catch (error) {
    logger.notify(NotificationType.ERROR, `CSV Download failed: ${error}`);
  }
};
