import Address from "../types/Address";
import { PartyToSave, RelationshipToClient } from "../types/Party";

type UpdateOrCreateRelationship = {
  workflowId: string;
  clientId: string;
  relationship?: RelationshipToClient | null;
};

export const buildCreatePartyRequestPayload = (
  partyData: Partial<PartyToSave>,
  clientId: string,
): Record<string, any> => {
  const { relationshipToSave, ...party } = partyData;
  return {
    ...party,
    relationships: { [clientId]: relationshipToSave },
    address: (party.address as any as { address: Address })?.address, // TODO hotfix
  };
};

export const buildUpdatePartyRequestPayload = (
  partyData: Partial<PartyToSave>,
  workflowId: string,
  clientId: string,
): Record<string, any> => {
  const { relationshipToSave, ...party } = partyData;
  const relationships: UpdateOrCreateRelationship[] = [
    { workflowId, clientId, relationship: relationshipToSave },
  ];
  return {
    ...party,
    relationships,
    address: (party.address as any as { address: Address })?.address, // TODO hotfix
  };
};
