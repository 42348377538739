export enum ProductSelectorType {
  PRODUCT = "Products",
  PLAN = "Plan",
}

export type ProductSelectorProducts = {
  type: ProductSelectorType.PRODUCT;
  productIds: string[];
};

export type ProductSelectorPlan = {
  type: ProductSelectorType.PLAN;
  planId: string;
};

export type ProductSelector = ProductSelectorProducts | ProductSelectorPlan;
