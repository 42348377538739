import React, { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { SelectorOptionMood } from "../constants";
import { ReactComponent as InfoSVG } from "components/Forms/Questions/QuestionWrapper/info.svg";
import { ModalType, showModalConfirm } from "components/Modal";
import SanitizeHtml from "components/SanitizeHtml";
import currencyFormatter from "utils/currencyFormatter";
const cx = classNames.bind(styles);

type SelectorOptionProps = {
  children?: ReactNode;
  disabled?: boolean;
  selected: boolean;
  title: string;
  optionValue: string;
  optionValueMood: SelectorOptionMood;
  description?: string;
  showDescriptionPopup?: boolean;
  saving?: number;
  addClassNames?: string;
  isPricingShown: boolean;
  recommended?: boolean;
  shortTitle?: boolean;
};

const SelectorOption = ({
  addClassNames,
  children,
  disabled,
  selected,
  title,
  description,
  showDescriptionPopup,
  optionValue,
  optionValueMood,
  saving = 0,
  isPricingShown,
  recommended,
  shortTitle,
}: SelectorOptionProps) => {
  const classNames = cx("selector-option", addClassNames, {
    "selector-option--disabled": disabled,
    "selector-option--selected": selected,
    "selector-option--recommended": recommended,
  });

  return (
    <div className={classNames}>
      {showDescriptionPopup && description && (
        <InfoSVG
          aria-label={`Plan '${title}' Tooltip`}
          className={styles["selector-option__tooltip"]}
          onClick={() =>
            !disabled &&
            showModalConfirm({
              labelConfirm: "Close",
              text: <SanitizeHtml html={description || ""} />,
              title,
              type: ModalType.NOTIFICATION,
            })
          }
        />
      )}
      <div
        className={cx("selector-option__info", { "short--title": shortTitle })}
      >
        <h2
          className={styles["selector-option__title"]}
          dangerouslySetInnerHTML={{
            // hacking around ugly line wrapping after 'with'
            __html: title.replace("with", "<br/>with"),
          }}
        />
        {isPricingShown ? (
          <div
            className={cx("selector-option__price", {
              "selector-option__price--positive":
                optionValueMood === SelectorOptionMood.POSITIVE,
              "selector-option__price--negative":
                optionValueMood === SelectorOptionMood.NEGATIVE,
              "selector-option__price--neutral":
                optionValueMood === SelectorOptionMood.NEUTRAL,
            })}
          >
            {optionValue}
          </div>
        ) : null}
        {saving > 0 ? (
          <p className={styles["selector-option__discount"]}>
            Bundle saving {currencyFormatter.format(saving)}
          </p>
        ) : null}
      </div>
      {!showDescriptionPopup && description && (
        <p className={styles["selector-option__description-in-body"]}>
          {description}
        </p>
      )}
      {children}
    </div>
  );
};

export default SelectorOption;
