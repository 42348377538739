import PageHeader from "components/PageHeader";
import PartnerFinanceTable from "modules/finance/PartnerFinanceTable";
import Dropdown from "components/Forms/Inputs/Dropdown";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import React, { useState } from "react";
import useGlobalFinancialStatus from "modules/globalAdmin/hooks/useGlobalFinancialStatus";
import PartnerFinanceCsvExport from "modules/finance/PartnerFinanceCsvExport";
import { PERIOD } from "./types";

const PAGE_HEADER = "Partner Details";
const defaultRangeValue = PERIOD.THIS_MONTH;

const cx = classNames.bind(styles);

const FinanceScreen = () => {
  const [period, setPeriod] = useState<PERIOD | undefined>(defaultRangeValue);
  const { globalFinancialStatus, error } = useGlobalFinancialStatus(period);

  if (error) return null;

  const onRangeChange = (newPeriod: string | null) => {
    switch (newPeriod) {
      case PERIOD.THIS_MONTH: {
        setPeriod(PERIOD.THIS_MONTH);
        break;
      }
      case PERIOD.LAST_MONTH: {
        setPeriod(PERIOD.LAST_MONTH);
        break;
      }
      case PERIOD.ALL_TIME: {
        setPeriod(PERIOD.ALL_TIME);
        break;
      }
    }
  };

  return (
    <div className={cx("finance")}>
      <div className={cx("finance-header")}>
        <PageHeader title={PAGE_HEADER} />
        <div className={cx("finance-range-toggle")}>
          <Dropdown
            name="admin-finance-range-toggle"
            options={[
              { label: "This Month", value: PERIOD.THIS_MONTH },
              { label: "Last Month", value: PERIOD.LAST_MONTH },
              { label: "All Time", value: PERIOD.ALL_TIME },
            ]}
            value={period}
            onChange={onRangeChange}
          />
        </div>
        <div className={cx("admin-finance-csv-export")}>
          <PartnerFinanceCsvExport period={period} />
        </div>
      </div>
      <PartnerFinanceTable
        globalFinancialStatus={
          globalFinancialStatus === undefined
            ? []
            : globalFinancialStatus.partners
        }
        period={period}
      />
    </div>
  );
};

export default FinanceScreen;
