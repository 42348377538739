// This component is inspired by https://www.joshwcomeau.com/performance/embracing-modern-image-formats/
import React from "react";
import { type ImgWithFallbackProps } from "./types";

const ImgWithFallback = ({
  src,
  fallback,
  type = "image/webp",
  alt,
  className,
}: ImgWithFallbackProps) => (
  <picture className={className}>
    <source srcSet={src} type={type} />
    <img src={fallback} alt={alt} />
  </picture>
);

export default ImgWithFallback;
