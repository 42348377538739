import * as React from "react";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import { destroyModal, ModalType, showModal } from "components/Modal";

import {
  WorkflowDocumentDetails,
  WorkflowDocumentType,
} from "modules/api/types/WorkflowDetailsPayload";
import DownloadDocumentsList from "./DownloadDocumentsList";
import DocumentsRenderStatusList from "./DocumentsRenderStatusList";

const DOCUMENTS_MODAL_ID = "documentsModal";

export const showDownloadDocumentsModal = (
  title: string,
  renderedDocuments: DownloadableDocumentDetails[] | null,
  onDownloadDocument: (docDetails: DownloadableDocumentDetails) => any,
  onExpire: () => void,
) => {
  destroyModal(DOCUMENTS_MODAL_ID);
  showModal({
    id: DOCUMENTS_MODAL_ID,
    title,
    type: ModalType.CONFIRM,
    labelCancel: "Close",
    renderContent: () => (
      <DownloadDocumentsList
        {...{ renderedDocuments, onDownloadDocument, onExpire }}
      />
    ),
  });
};

export const showDocumentsRenderStatusModal = (
  documents: WorkflowDocumentDetails[],
  onRerender: (documentType?: WorkflowDocumentType) => Promise<void>,
) => {
  destroyModal(DOCUMENTS_MODAL_ID);
  showModal({
    id: DOCUMENTS_MODAL_ID,
    title: "Documents rendering status",
    type: ModalType.CONFIRM,
    labelCancel: "Close",
    labelConfirm: "Retry",
    onConfirm: onRerender,
    renderContent: () => <DocumentsRenderStatusList documents={documents} />,
  });
};
