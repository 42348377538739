import * as React from "react";
import { QuestionnairePage } from "modules/workflow/types/Questionnaire";
import { SectionState } from "modules/workflow/helpers/getSectionsProgress";
import DocumentsAssembly from "../components/DocumentsAssembly";
import { FormProps } from "components/Forms/Form";
import { Step, WorkflowStepDetails } from "modules/api/types/WorkflowPayload";
import { isAgreementConfirmed, isAtLeast } from "../helpers/lifecycle";
import { User } from "modules/auth/User";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import { ASSEMBLE_DOCUMENTS_PAGE_ID } from "./pageIDs";
import { isAdmin } from "modules/auth/helper";
import Product from "modules/product/types/Product";
import Banner, { BannerType } from "components/Banner";

export default (
  isLoadingRenderedDocuments: boolean,
  onDownloadDocuments: () => any,
  onClientContacted: () => any,
  workflowStepDetails: WorkflowStepDetails,
  user: User | null, // TODO added temporarily for https://trello.com/c/J6d8xAUk/1115-force-user-to-use-supervised-signing
  externalProducts: Product[],
  onlineProduct?: boolean,
): QuestionnairePage => {
  const admin = isAdmin(getFormattedPartnerId() || undefined, user);
  return {
    id: ASSEMBLE_DOCUMENTS_PAGE_ID,
    caption: "",
    description: "",
    questionnaireId: "",
    isStatic: true,
    sections: [],
    getProgress: (isVisited) => {
      return {
        label: onlineProduct ? "Assessment" : "Assemble Documents",
        state: !isAgreementConfirmed(workflowStepDetails)
          ? SectionState.PENDING
          : isVisited
            ? SectionState.COMPLETE
            : SectionState.STARTED,
      };
    },
    render: (_formProps: FormProps) => {
      if (workflowStepDetails.current.step === Step.EngagementRejected) {
        const title = admin
          ? "Documents are not available."
          : "Your documents are not available.";
        return (
          <Banner
            type={BannerType.INFO}
            title={title}
            description="Engagement agreement was rejected."
          />
        );
      }

      const documentDownloadEnabled =
        (admin && isAtLeast(workflowStepDetails, Step.AwaitingLawyerReview)) ||
        isAtLeast(workflowStepDetails, Step.ContactCustomer);

      if (documentDownloadEnabled) {
        return (
          <DocumentsAssembly
            isAdmin={admin}
            isLoadingDocuments={isLoadingRenderedDocuments}
            onDownloadDocuments={onDownloadDocuments}
            onClientContacted={onClientContacted}
            externalProducts={externalProducts}
            clientWasContacted={
              workflowStepDetails.current.step !== Step.ContactCustomer
            }
          />
        );
      }

      const adminDescription = isAtLeast(
        workflowStepDetails,
        Step.AwaitingEngagementCheck,
      )
        ? "Documents will be available after the engagement agreement is accepted."
        : "";

      const userDescription = isAtLeast(
        workflowStepDetails,
        Step.AwaitingLawyerReview,
      )
        ? "The law firm has accepted your engagement agreement and is reviewing your documents."
        : isAtLeast(workflowStepDetails, Step.AwaitingEngagementCheck)
          ? "The law firm is reviewing the engagement agreement."
          : "";

      const title = admin
        ? "Documents are not available yet."
        : "Your documents are not available yet.";

      return (
        <Banner
          type={BannerType.INFO}
          title={title}
          description={admin ? adminDescription : userDescription}
        />
      );
    },
  } as QuestionnairePage;
};
