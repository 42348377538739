import { AnswersByQuestionnaireType, AnswerValueType } from "../types/Answer";
import {
  QuestionDefinitionPerParty,
  QuestionDefinitionSelectRelationship,
  QuestionDefinitionShareDistribution,
  QuestionDefinitionSubquestionnaire,
  QuestionID,
  QuestionnaireID,
  QuestionsMapByQuestionnaire,
  QuestionType,
  RelationshipConstraintType,
  SelectSubsetRelationshipConstraint,
} from "../types/Questionnaire";

const hasValueChanged = (
  changedAnswers: AnswersByQuestionnaireType,
  questionnaireId: QuestionnaireID,
  questionId: QuestionID,
): boolean => changedAnswers[questionnaireId]?.[questionId] !== undefined;

export default (
  changedAnswers: AnswersByQuestionnaireType,
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
): AnswersByQuestionnaireType => {
  return Object.entries(questionsByQuestionnaire).reduce(
    (acc, [questionnaireId, questionsMap]) => {
      const dependentQuestions = Object.values(questionsMap).filter(
        (q) =>
          [
            QuestionType.SHARE_DISTRIBUTION,
            QuestionType.PER_PARTY,
            QuestionType.SUBQUESTIONNAIRE,
          ].includes(q.data.definition.type) ||
          (q.data.definition.type === QuestionType.RELATIONSHIP &&
            q.data.definition.relationship?.type ===
              RelationshipConstraintType.SUBSET),
      );

      dependentQuestions.forEach((q) => {
        const definition = q.data.definition as
          | QuestionDefinitionShareDistribution
          | QuestionDefinitionPerParty
          | QuestionDefinitionSubquestionnaire
          | QuestionDefinitionSelectRelationship;

        const hasParentChanged =
          definition.type === QuestionType.RELATIONSHIP
            ? (
                definition.relationship as SelectSubsetRelationshipConstraint
              ).referenceQuestionIds.find((referenceQuestionId) =>
                hasValueChanged(
                  changedAnswers,
                  questionnaireId,
                  referenceQuestionId,
                ),
              )
            : hasValueChanged(
                changedAnswers,
                questionnaireId,
                definition.referenceQuestionId,
              );

        if (hasParentChanged) {
          const value = acc[questionnaireId]?.[q.id];
          acc[questionnaireId] = {
            ...(acc[questionnaireId] || {}),
            [q.id]: value !== undefined ? value : null,
          };
        }
      });
      return acc;
    },
    {} as Record<QuestionnaireID, Record<QuestionID, AnswerValueType>>,
  );
};
