import React, { useEffect } from "react";

import WorkflowScreen from "./WorkflowScreen";
import { useSelector } from "redux/reducer";
import { useDispatch, shallowEqual } from "react-redux";
import { fetchWorkflows, openWorkflow } from "../../modules/workflow/actions";
import { isAdmin, isGlobalAdmin } from "modules/auth/helper";
import AdminWorkflowsScreen from "screens/workflow/AdminWorkflowsScreen";
import Workflow from "modules/workflow/types/Workflow";
import { triggerContactLawyer } from "modules/contact/actions";
import {
  getWorkflowDetailsRoute,
  getWorkflowRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import { Redirect, useLocation } from "react-router-dom";
import {
  go,
  navigateToPartnerPricing,
} from "modules/navigation/helpers/navigator";
import {
  ASSEMBLE_DOCUMENTS_PAGE_ID,
  SUMMARY_PAGE_ID,
} from "modules/workflow/staticPages/pageIDs";
import LoadingOverlay from "components/LoadingOverlay";
import { fetchPartnerWorkflowsPrioritised } from "modules/admin/actions";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";

const WorkflowScreenContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    availableWorkflows,
    clientWorkflowsPrioritisedLoading,
    hasRehydratedWorkflows,
    isLoadingProducts,
    isLoadingWorkflows,
    partner,
    user,
    workflows,
    isStartingLawyerReview,
  } = useSelector((state) => state, shallowEqual);
  const partnerId = partner?.id;
  const urlPartnerId = getFormattedPartnerId();
  const admin = isAdmin(urlPartnerId, user);

  useEffect(() => {
    // Initial load
    if (user && partnerId) {
      dispatch(admin ? fetchPartnerWorkflowsPrioritised() : fetchWorkflows());
    }
  }, [admin, dispatch, partnerId, user]);

  const onTriggerNewWorkflow = () => {
    if (partnerId) {
      navigateToPartnerPricing(dispatch, partnerId);
    }
  };

  const handleOpenWorkflow = async (
    workflow: Workflow,
    pageIdx?: number,
    pageId?: string,
  ) => openWorkflow(user, partner?.id, workflow, pageIdx, pageId)(dispatch);

  const handleContactLawyer = () => triggerContactLawyer(user, "")(dispatch);

  const handleViewWorkflowParties = (workflow: Workflow) =>
    go(
      dispatch,
      getWorkflowRoute(workflow.id, ROUTE_TEMPLATE.WORKFLOW_PARTIES),
      { from: location.pathname },
    );

  const handleViewWorkflowDocuments = (workflow: Workflow) => {
    go(
      dispatch,
      getWorkflowDetailsRoute(
        workflow.id,
        undefined,
        ASSEMBLE_DOCUMENTS_PAGE_ID,
      ),
    );
  };

  const handleViewWorkflowSummary = (workflow: Workflow) => {
    go(
      dispatch,
      getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID),
    );
  };

  const workflowsList = Object.values(workflows);
  const isLoading =
    isLoadingWorkflows ||
    isLoadingProducts ||
    clientWorkflowsPrioritisedLoading ||
    !hasRehydratedWorkflows;

  if (
    (isLoading && !hasRehydratedWorkflows) ||
    partnerId !== urlPartnerId ||
    isStartingLawyerReview
  )
    return <LoadingOverlay />;

  return isGlobalAdmin(user) ? (
    <Redirect to={ROUTE_TEMPLATE.SEARCH} />
  ) : admin ? (
    <AdminWorkflowsScreen
      workflowDefinitions={availableWorkflows}
      partnerWorkflows={workflowsList}
      onOpenWorkflow={handleOpenWorkflow}
    />
  ) : (
    <WorkflowScreen
      assignedWorkflows={workflowsList}
      availableWorkflows={availableWorkflows}
      onOpenWorkflow={handleOpenWorkflow}
      onTriggerNewWorkflow={onTriggerNewWorkflow}
      onViewWorkflowDocuments={handleViewWorkflowDocuments}
      onViewWorkflowParties={handleViewWorkflowParties}
      onViewWorkflowSummary={handleViewWorkflowSummary}
      partnerDetails={partner}
      triggerContactLawyer={handleContactLawyer}
      user={user}
    />
  );
};

export default WorkflowScreenContainer;
