import * as React from "react";

import { ReactComponent as ArrowSVG } from "./arrow.svg";
import styles from "./styles.module.scss";

type HeaderAccountModalArrowProps = {};

const HeaderAccountModalArrow: React.FunctionComponent<
  HeaderAccountModalArrowProps
> = () => {
  return (
    <div className={styles["header-account-modal__arrow-container"]}>
      <ArrowSVG
        aria-label="Header Account Modal Arrow"
        className={styles["header-account-modal__arrow"]}
      />
    </div>
  );
};

export default HeaderAccountModalArrow;
