import * as React from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

import TabbedButton, { TabbedButtonType } from "components/TabbedButton";

const cx = classNames.bind(styles);

type FooterNavigationLinkProps = {
  label: string;
  onClick: () => any;
  className?: string;
  divider?: boolean;
};

export const FooterNavigationLink: React.FunctionComponent<
  FooterNavigationLinkProps
> = ({ label, onClick, className, divider }) => {
  return (
    <li
      className={cx("footer-nav__item", {
        divider: divider === true,
      })}
    >
      <TabbedButton
        style={TabbedButtonType.SECONDARY}
        tabIndex={0}
        className={cx("footer-nav__link", className)}
        onClick={onClick}
      >
        {label}
      </TabbedButton>
    </li>
  );
};

type FooterDownloadLinkProps = {
  label: string;
  fileUrl: string;
  className?: string;
};

export const FooterNavigationDownloadLink: React.FunctionComponent<
  FooterDownloadLinkProps
> = ({ fileUrl, label, className }) => {
  return (
    <li className={cx("footer-nav__item")}>
      <a
        className={cx("footer-nav__link", className)}
        onClick={(e) => e.stopPropagation()}
        download={label}
        href={fileUrl}
      >
        {label}
      </a>
    </li>
  );
};

const FooterNavigation: React.FunctionComponent = ({ children }) => {
  return (
    <nav className={cx("footer-nav")}>
      <ul className={cx("footer-nav__list")}>{children}</ul>
    </nav>
  );
};

export default FooterNavigation;
