import { Location } from "history";
import { matchPathTemplate } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";

export default (location?: Location) => {
  const matchWorkflowDetailsRoute =
    matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_DETAILS, location) ||
    matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_PAYMENT, location) ||
    matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_SIGNING, location) ||
    matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_PARTIES, location);

  const params: Record<string, string> =
    matchWorkflowDetailsRoute?.params || {};
  return params.id;
};
