import { mapValues } from "lodash";
import { QuestionsMapByQuestionnaire } from "modules/workflow/types/Questionnaire";

const disableQuestions = (
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
): QuestionsMapByQuestionnaire =>
  mapValues(questionsByQuestionnaire, (questions) =>
    mapValues(questions, (question) => ({
      ...question,
      data: {
        ...question.data,
        readOnly: true,
      },
    })),
  );

export default disableQuestions;
