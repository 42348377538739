import Partner from "modules/parties/types/Partner";
import * as React from "react";
import { shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";

export const PartnerContext = React.createContext<Partner | null>(null);

export const PartnerContextProvider: React.FC = ({ children }) => {
  const { partner } = useSelector((state) => state, shallowEqual);
  return (
    <PartnerContext.Provider value={partner}>
      {children}
    </PartnerContext.Provider>
  );
};
