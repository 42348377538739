import { flattenDeep, isEqual, omit, uniqWith } from "lodash";
import Address from "modules/parties/types/Address";
import Workflow from "../types/Workflow";

const isAddress = (addr: Address | undefined): addr is Address =>
  addr !== undefined;

const getAddreessID = (
  workflowId: string,
  partyId: string,
  addressId: string,
) => [workflowId, partyId, addressId].join("_");

const gatherAddresses = (workflows: Workflow[]): Address[] =>
  flattenDeep(
    workflows.map((wf) => [
      ...Object.entries(wf.contacts?.clients || {}).map(
        ([clientType, client]) => [
          client?.address && {
            ...client.address,
            id: getAddreessID(wf.id, clientType, client.address.id),
          },
          client?.mailingAddress && {
            ...client.mailingAddress,
            id: getAddreessID(wf.id, clientType, client.mailingAddress.id),
          },
        ],
      ),
      ...Object.values(wf.contacts?.parties || {}).map(
        (party) =>
          party?.address && {
            ...party.address,
            id: getAddreessID(wf.id, party.partyId, party.address.id),
          },
      ),
    ]),
  )
    .flat()
    .filter(isAddress);

export const dedupAddresses = (addresses: Address[]): Address[] =>
  uniqWith(addresses, (addr1, addr2) =>
    isEqual(omit(addr1, "id"), omit(addr2, "id")),
  );

export default (
  workflows: Record<string, Workflow>,
  workflowId?: string,
): Address[] => {
  const workflowsToParse = !workflowId
    ? Object.values(workflows)
    : workflows[workflowId]
      ? [workflows[workflowId]]
      : [];
  const allAddresses: Address[] = gatherAddresses(workflowsToParse);
  return dedupAddresses(allAddresses).filter(Boolean);
};
