import * as React from "react";
import { User } from "modules/auth/User";

import Icon, { IconType } from "components/Icons";
import CollapsableCard from "components/CollapsableCard";

import styles from "./styles.module.scss";
import Partner from "modules/parties/types/Partner";
import Tel from "components/Tel";

type ClientDashboardContactsProps = {
  closed?: boolean;
  onContactLawyer: () => void;
  user: User | null;
  partnerDetails: Partner;
};

const ClientDashboardContacts: React.FunctionComponent<
  ClientDashboardContactsProps
> = ({ closed, partnerDetails }) => {
  const open = true;
  const workPhone = partnerDetails.data.contact.workPhone;
  return (
    <CollapsableCard closed={closed} title="Contact details">
      <div className={styles["client-dashboard-contacts"]}>
        {open ? (
          <React.Fragment>
            <header className={styles["client-dashboard-contacts__header"]}>
              {/* <div
                className={styles["client-dashboard-contacts__image"]}
                style={
                  image && {
                    backgroundImage: `url(${image})`,
                  }
                }
              /> */}
              <div className={styles["client-dashboard-contacts__col"]}>
                <div className={styles["client-dashboard-contacts__name"]}>
                  {partnerDetails.data.displayName}
                </div>
                {/* <div className={styles["client-dashboard-contacts__job-title"]}>Senior lawyer</div> */}
              </div>
            </header>
            <div className={styles["client-dashboard-contacts__row"]}>
              <a href={`tel:${workPhone}`}>
                <div className={styles["client-dashboard-contacts__icon"]}>
                  <Icon type={IconType.LANDLINE} />
                </div>
              </a>
              <div className={styles["client-dashboard-contacts__text"]}>
                <Tel>{workPhone}</Tel>
              </div>
            </div>
            {/* <div className={styles["client-dashboard-contacts__row"]}>
              <div className={styles["client-dashboard-contacts__icon"]}>
                <Icon type={IconType.MOBILE} />
              </div>
              <div className={styles["client-dashboard-contacts__text"]}>760-555-5555</div>
            </div>
            <h5 className={styles["client-dashboard-contacts__title"]}>Contactable Hours</h5>
            <div className={styles["client-dashboard-contacts__text"]}>Mon-Fri, 08:30 - 18:30</div>
            <footer className={styles["client-dashboard-contacts__footer"]}>
              {user?.email ? (
                <TabbedButton label="E-mail" style={TabbedButtonType.SECONDARY} onClick={onContactLawyer} />
              ) : null}
            </footer> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={styles["client-dashboard-contacts__row"]}>
              <div className={styles["client-dashboard-contacts__icon"]}>
                <Icon type={IconType.LANDLINE} />
              </div>
              <div className={styles["client-dashboard-contacts__text"]}>
                (555) 555-5555 (ext. 1234)
              </div>
            </div>
            <h5 className={styles["client-dashboard-contacts__title"]}>
              Office Hours
            </h5>
            <div className={styles["client-dashboard-contacts__text"]}>
              Mon-Fri, 08:30 - 18:30
            </div>
          </React.Fragment>
        )}
      </div>
    </CollapsableCard>
  );
};

export default ClientDashboardContacts;
