import axios, { Method } from "axios";
import { mapValues, uniq } from "lodash";
import { QuestionID } from "modules/workflow/types/Questionnaire";
import {
  ErrorDetail,
  ErrorDetailsMap,
  ErrorDetailType,
} from "./types/ErrorResponsePayload";

export type ApiResponse<T = any> = {
  data: T;
  headers: { [key: string]: any };
  status: number;
};

export const sendRequest = <T = any>(
  method: Method,
  url: string,
  params?: Record<string, any>,
  data?: Record<string, any>,
  headers?: Record<string, any>,
): Promise<ApiResponse<T>> => axios({ method, url, data, headers, params });

const parseError = (error: ErrorDetail): string[] => {
  if (error.type === ErrorDetailType.SIMPLE) {
    return [error.description];
  } else {
    return parseError(error.error);
  }
};

export const parseValidationErrors = (
  errors?: ErrorDetailsMap,
): Record<QuestionID, string[]> | undefined => {
  if (!errors || typeof errors !== "object") return errors;

  const errorsByQuestionnaire = mapValues(errors, (errorsMap) =>
    mapValues(errorsMap, (val) =>
      uniq(
        (Array.isArray(val)
          ? val.map((singleError) => parseError(singleError))
          : parseError(val)
        ).flat(),
      ),
    ),
  );

  // Important! Merging all questionnaire-specific maps into a single map by question ID.
  return Object.assign({}, ...Object.values(errorsByQuestionnaire));
};
