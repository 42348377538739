import * as React from "react";
import nextId from "react-id-generator";
import logger from "logger";

import { ModalConfirm, ModalConfirmProps } from "./ModalConfirm";
import { ModalForm, ModalFormProps } from "./ModalForm";
import { ModalPage, ModalPageProps } from "./ModalPage";

export { default as showModalConfirm } from "./ModalConfirm";
export { default as showModalForm } from "./ModalForm";

export enum ModalType {
  CONFIRM = "confirm",
  FORM = "form",
  NOTIFICATION = "Notification",
  PAGE = "page",
  WARNING = "warning",
}

export type ModalControls = {
  busy: (busy: boolean) => void;
  close: () => void;
  setDirty: (dirty: boolean) => void;
};

let modalWrapperRef = React.createRef<any>();

export const ModalWrapper: React.FC<{}> = () => {
  return <ModalsManager ref={modalWrapperRef} />;
};

type ModalsManagerState = { modals: any[] };

class ModalsManager extends React.PureComponent<{}, ModalsManagerState> {
  state: ModalsManagerState = { modals: [] };

  destroyModals() {
    this.setState({ modals: [] });
  }

  destroyModal(id: string) {
    const modalToDestroy = this.state.modals.find((modal) => modal.id === id);
    if (modalToDestroy) {
      const remainingModals = [...this.state.modals];
      remainingModals.splice(this.state.modals.indexOf(modalToDestroy), 1);
      this.setState({ modals: remainingModals });
    }
  }

  addModal({ renderContent, type, id, ...props }: ModalProps) {
    const ref = React.createRef<any>();
    const modalId = id || nextId("modal-");

    const existingModal = this.state.modals.find(
      (modal) => modal.id === modalId,
    );

    if (!existingModal) {
      this.setState({
        modals: [
          ...this.state.modals,
          {
            id: modalId,
            ref: ref,
            renderContent: renderContent,
            type: type,
            ...props,
            onExited: () => {
              this.setState({
                modals: this.state.modals.filter(function (modal) {
                  return modal.id !== modalId;
                }),
              });
            },
          },
        ],
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.modals.map(
          ({ id, renderContent, type, ...modalProps }, idx) => {
            const key = id === undefined ? `modal_${idx}` : id;
            switch (type) {
              case ModalType.CONFIRM:
              case ModalType.NOTIFICATION:
              case ModalType.WARNING:
                return (
                  <ModalConfirm key={key} type={type} {...modalProps}>
                    {(modalControls: ModalControls) =>
                      renderContent && renderContent(modalControls)
                    }
                  </ModalConfirm>
                );
              case ModalType.FORM:
                return (
                  <ModalForm key={key} {...modalProps}>
                    {(modalControls: ModalControls) =>
                      renderContent && renderContent(modalControls)
                    }
                  </ModalForm>
                );
              case ModalType.PAGE:
                return (
                  <ModalPage key={key} {...modalProps}>
                    {(modalControls: ModalControls) =>
                      renderContent && renderContent(modalControls)
                    }
                  </ModalPage>
                );
              default:
                return null;
            }
          },
        )}
      </div>
    );
  }
}

type DefaultModalProps = { id?: string; type: ModalType };
type FullModalFormProps = DefaultModalProps &
  ModalFormProps & { type: ModalType.FORM };
type FullModalConfirmProps = DefaultModalProps &
  ModalConfirmProps & {
    type: ModalType.CONFIRM | ModalType.NOTIFICATION | ModalType.WARNING;
  };
type FullModalPageProps = DefaultModalProps &
  ModalPageProps & { type: ModalType.PAGE };

type ModalProps =
  | FullModalFormProps
  | FullModalConfirmProps
  | FullModalPageProps;

export function showModal(props: ModalProps) {
  if (modalWrapperRef?.current) {
    modalWrapperRef.current.addModal(props);
  } else {
    logger.error("Modal wrapper not yet available");
  }
}

export function destroyModal(id: string) {
  modalWrapperRef.current && modalWrapperRef.current.destroyModal(id);
}

export function destroyModals() {
  modalWrapperRef.current && modalWrapperRef.current.destroyModals();
}
