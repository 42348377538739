/* eslint-disable no-console */
import React, { createContext } from "react";
import TagManager from "react-gtm-module";
import { Environment, getEnvironment } from "utils/environment";
import { DataLayerContextProviderProps, DataLayerEvent } from "./types";

const DataLayerContext = createContext<{
  sendEvent?: (event: DataLayerEvent) => void;
}>({});

export const DataLayerContextProvider = ({
  children,
}: DataLayerContextProviderProps) => {
  const gtmId = {
    [Environment.LOCAL]: "GTM-TFB9NCTN",
    [Environment.DEV]: "GTM-W3R4WXLM",
    [Environment.STAGING]: "GTM-N35T7TM4",
    [Environment.PROD]: "GTM-KWC94H8B",
  }[getEnvironment()];

  TagManager.initialize({
    gtmId,
  });

  const sendEvent = (event: DataLayerEvent) => {
    if (getEnvironment() !== Environment.PROD) {
      console.log("Pushing event to data layer:");
      console.log(JSON.stringify(event, null, 2));
    }
    TagManager.dataLayer({
      dataLayer: event,
    });
  };

  const exposedValue = {
    sendEvent,
  };

  return (
    <DataLayerContext.Provider value={exposedValue}>
      {children}
    </DataLayerContext.Provider>
  );
};

export default DataLayerContext;
