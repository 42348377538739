import React, { useMemo } from "react";
import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import ImgWithFallback from "components/ImgWithFallback";
import Wrapper from "components/Wrapper";

import FatherSonPNG from "static/images/landing/father-son.png";
import FatherSonWebP from "static/images/landing/father-son.webp";

import BabyPNG from "static/images/landing/baby.png";
import BabyWebP from "static/images/landing/baby.webp";

import GrandparentsPNG from "static/images/landing/grandparents.png";
import GrandparentsWebP from "static/images/landing/grandparents.webp";
import Button from "components/Button";
import { ButtonSize, ChevronColour } from "components/Button/types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { useDispatch } from "react-redux";
import { go } from "modules/navigation/helpers/navigator";
import { buildProductTailoringRoute } from "modules/navigation/routes";
import { ProductType } from "modules/workflow/types/Product";
import { useSelector } from "redux/reducer";

const cx = classNames.bind(styles);

const LearningCenterPage: React.FC = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.uid);
  const below600px = useMediaQuery("(max-width: 900px)");

  const handleClick = (productType: ProductType) => () =>
    go(
      dispatch,
      buildProductTailoringRoute("default-workflow", productType, userId),
    );

  const h2Sizing = {
    xs: "1.5rem",
    sm: "1.25rem",
    md: "2rem",
    lg: "2.4rem",
    xl: "2.4rem",
  };

  const bodySizing = {
    xs: "0.85rem",
    sm: "0.65rem",
    md: "0.85rem",
    lg: "1rem",
    xl: "1rem",
  };

  const buttonSize: ButtonSize = useMemo(() => {
    if (below600px) return ButtonSize.XSMALL;
    return ButtonSize.MEDIUM;
  }, [below600px]);

  return (
    <Container maxWidth={false} className={cx("learning-center-wrapper")}>
      <Container className={cx("learning-center-container")} maxWidth={false}>
        <Wrapper>
          <Stack
            textAlign="center"
            alignItems="center"
            maxWidth={900}
            spacing={{ xs: 1, md: 2, lg: 3 }}
            marginBottom={"2rem"}
          >
            <Typography
              variant="h1"
              fontSize={{
                xs: "1.5rem",
                sm: "2rem",
                md: "2.25rem",
                lg: "2.5rem",
                xl: "3rem",
              }}
            >
              The Learning Center
            </Typography>
            <Typography
              variant="h2"
              fontSize={h2Sizing}
              className={cx("learning-center-subheading")}
            >
              Estate Planning To Secure Your Wishes & Make Your Life Easier
            </Typography>
            <Typography variant="body1" fontSize={bodySizing}>
              Estate Planning doesn't have to be complicated or overwhelming.
              Our learning center is here to support you in confidently taking
              your next step toward the peace of mind of a protected future for
              yourself and your loved ones.
            </Typography>
          </Stack>
        </Wrapper>
      </Container>
      <Container className={cx("learning-center-container")} maxWidth={false}>
        <Wrapper>
          <Stack direction={{ xs: "column-reverse", sm: "row" }} spacing={1}>
            <ImgWithFallback
              src={FatherSonWebP}
              fallback={FatherSonPNG}
              alt="Father and Son"
            />
            <Stack spacing={{ xs: 1, md: 2, lg: 3 }}>
              <Typography variant="h2" fontSize={h2Sizing}>
                What is a Will?
              </Typography>
              <Typography variant="body1" fontSize={bodySizing}>
                A Will is your simple instructions to tell the court who will
                get the assets you own after you die and who will ensure it gets
                done (your Executor). It has no authority until after death. It
                is a way for you to lay out your financial wishes for your
                possessions, dependents and arrangements. More importantly, if
                you do not have a Will, the state will dictate who is in control
                of your assets and the distribution process.
              </Typography>
              <Typography fontSize={bodySizing}>
                In a Last Will and Testament you can name someone you trust as
                executor to manage your estate after your death. If you are a
                parent with minor children, you can name guardians to care for
                your children after death.
              </Typography>
              <Button
                label={below600px ? "Create Will" : "Create Your Will"}
                chevronColour={ChevronColour.GREEN}
                chevronRight
                onClick={handleClick(ProductType.WILL)}
                size={buttonSize}
              />
            </Stack>
          </Stack>
        </Wrapper>
      </Container>
      <Container className={cx("learning-center-container")} maxWidth={false}>
        <Wrapper>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
            <Stack spacing={{ xs: 1, md: 2, lg: 3 }}>
              <Typography variant="h2" fontSize={h2Sizing}>
                What is a Health Care Directive?
              </Typography>
              <Typography variant="body1" fontSize={bodySizing}>
                A Health Care Directive is a legal document that authorizes
                someone to make your health care decisions for you in the event
                you are unable to. These decisions usually relate to life and
                death. A Health Care Directive allows you to name the person you
                want to make healthcare decisions on your behalf if you become
                incapacitated or unable to make decisions on your own.
              </Typography>
              <Button
                label={
                  below600px
                    ? "Create Health Care Directive"
                    : "Create Your Health Care Directive"
                }
                chevronColour={ChevronColour.GREEN}
                chevronRight
                onClick={handleClick(ProductType.HCP)}
                size={buttonSize}
              />
            </Stack>
            <ImgWithFallback src={BabyWebP} fallback={BabyPNG} alt="Baby" />
          </Stack>
        </Wrapper>
      </Container>
      <Container className={cx("learning-center-container")} maxWidth={false}>
        <Wrapper>
          <Stack direction={{ xs: "column-reverse", sm: "row" }} spacing={1}>
            <ImgWithFallback
              src={GrandparentsWebP}
              fallback={GrandparentsPNG}
              alt="Grandparents"
            />
            <Stack spacing={{ xs: 1, md: 2, lg: 3 }}>
              <Typography variant="h2" fontSize={h2Sizing}>
                What is a Power of Attorney?
              </Typography>
              <Typography variant="body1" fontSize={bodySizing}>
                A Power of Attorney is a legal document that authorizes another
                person to handle your legal or financial matters on your behalf
                if you are unable to do so yourself due to lack of capacity. It
                essentially gives another person the right to sign your name.
                Having a Power of Attorney is essential as without it, your
                family may be required to file a legal proceeding seeking
                guardianship.
              </Typography>
              <Button
                label={
                  below600px
                    ? "Create Power of Attorney"
                    : "Create Your Power of Attorney"
                }
                chevronColour={ChevronColour.GREEN}
                chevronRight
                onClick={handleClick(ProductType.POA)}
                size={buttonSize}
              />
            </Stack>
          </Stack>
        </Wrapper>
      </Container>
    </Container>
  );
};

export default LearningCenterPage;
