import { isEqual, mapValues } from "lodash";
import { QuestionID, QuestionnaireID } from "../types/Questionnaire";

export default (
  previousQuestionSet: Record<QuestionnaireID, Record<QuestionID, any>>,
  currentQuestionSet: Record<QuestionnaireID, Record<QuestionID, any>>,
) =>
  !isEqual(
    mapValues(previousQuestionSet, Object.keys),
    mapValues(currentQuestionSet, Object.keys),
  );
