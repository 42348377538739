import PageHeader from "components/PageHeader";
import Partner from "modules/parties/types/Partner";
import PartnerConfigurationForm from "modules/partner/settings/components/PartnerConfigurationForm";
import usePartner from "modules/globalAdmin/hooks/usePartner";
import { partnerAdminRoutes } from "modules/globalAdmin/routes";
import React from "react";

type Props = { partner: Partner };

const PartnersAdminScreenSite: React.FC<Props> = ({ partner }) => {
  const { refetchPartner } = usePartner(partner.id.toLowerCase());

  return (
    <>
      <PageHeader title={partnerAdminRoutes["site-setup"].title} />
      <PartnerConfigurationForm
        {...{ partner }}
        afterSave={() => {
          refetchPartner();
        }}
      />
    </>
  );
};

export default PartnersAdminScreenSite;
