import { UserID } from "modules/auth/User";
import { PartnerID } from "modules/parties/types/Partner";
import { WorkflowID } from "modules/workflow/types/Workflow";
import { ReactNode } from "react";

export type DataLayerContextProviderProps = {
  children?: ReactNode;
};

export type DataLayerEvent =
  | CTAEvent
  | LoginEngagementEvent
  | SignupEngagementEvent
  | ForgotPasswordEngagementEvent
  | SearchEngagementEvent
  | ViewItemListEvent
  | SelectItemEvent
  | BeginCheckoutEvent
  | CheckoutErrorEvent
  | PurchaseEvent
  | WorkflowEvent;

export enum EventName {
  CTAClick = "cta_click",
  LoginEngagement = "login_engagement",
  SignupEngagement = "signup_engagement",
  ForgotPasswordEngagement = "forgot_password",
  SearchEngagement = "search_engagement",
  ViewItemList = "view_item_list",
  SelectItem = "select_item",
  BeginCheckout = "begin_checkout",
  CheckoutError = "checkout_error",
  Purchase = "purchase",
  Workflow = "workflow",
}

/* CTA Event Types */
export enum CTAType {
  PRIMARY = "primary_cta",
  MAP = "map_cta",
  SERVICE_BUTTON = "service_button",
}

export enum CTALocation {
  HERO = "hero",
  FOOTER_MAP = "footer_map",
  HERO_SERVICES = "hero_services",
  LIST_SERVICES = "list_services",
}

export enum CTAServiceName {
  ESSENTIALS = "essentials",
  POWERS = "powers",
  WILL = "will",
  POA = "poa",
  HCP = "hcp",
  EPA = "epa",
}

export type CTAEvent = {
  event: EventName.CTAClick;
  cta_type: CTAType;
  cta_location: CTALocation;
  cta_text: string;
  cta_service_name?: CTAServiceName;
  cta_color?: string;
};

/* Login & Signup Event Types */

export enum LoginEngagementAction {
  LOGIN_ATTEMPT = "login_attempt",
  LOGIN_SUCCESS = "login_success",
  LOGIN_ERROR = "login_error",
}

export enum SignupEngagementAction {
  SIGNUP_FORM_VIEW = "register_form_view",
  SIGNUP_ATTEMPT = "register_attempt",
  SIGNUP_SUCCESS = "register_success",
  SIGNUP_PROFILE_VIEW = "register_profile_view",
  SIGNUP_PROFILE_COMPLETED = "register_profile_completed",
  SIGNUP_ERROR = "register_error",
}

export enum ForgotPasswordEngagementAction {
  FORGOT_PASSWORD_ATTEMPT = "forgotten_password_attempt",
  FORGOT_PASSWORD_SUCCESS = "forgotten_password_success",
  FORGOT_PASSWORD_ERROR = "register_error",
}

export type LoginEngagementEvent = {
  event: EventName.LoginEngagement;
  action: LoginEngagementAction;
  login_error_field?: string;
  user_ID?: string;
  user_State?: string;
};

export type SignupEngagementEvent = {
  event: EventName.SignupEngagement;
  action: SignupEngagementAction;
  signup_error_field?: string;
  user_ID?: string;
  user_State?: string;
};

export type ForgotPasswordEngagementEvent = {
  event: EventName.ForgotPasswordEngagement;
  action: ForgotPasswordEngagementAction;
  forgot_password_error_field?: string;
  user_ID?: string;
  user_State?: string;
};

export enum SearchEngagementAction {
  SEARCH_PERFORMED = "search_performed",
  SEARCH_ERROR = "search_error",
  //VIEW_SEARCH_RESULT = "view_search_results",
}

export enum FirmAction {
  CLICKED = "clicked",
  SELECTED = "selected",
  DIRECTIONS = "directions",
  //VIEW_SEARCH_RESULTS = "view_search_results",
}

export type SearchEngagementEvent = {
  event: EventName.SearchEngagement;
  search_action?: SearchEngagementAction;
  search_zip_code?: string;
  search_result_count?: number;
  firm_action?: FirmAction;
  list_position?: number;
  selected_firm?: string;
};

/* View Item List Event Types */

export enum ItemListID {
  NationalLanding = "national_landing_planning_options",
  PartnerLanding = "partner_landing_planning_options",
  NewWorkflow = "new_workflow_product_selector",
  Signing = "signing_screen",
}

export enum ItemListName {
  NationalLanding = "National Landing Planning Options",
  PartnerLanding = "Partner Landing Planning Options",
  NewWorkflow = "New Workflow Product Selector",
  Signing = "Signing Screen",
}

export enum EcommerceItemID {
  WILL = "will",
  HCP = "hcp",
  POA = "poa",
  ESSENTIALS = "essentials",
  POWERS = "powers",
  SELF_SIGNING = "self_signing",
  SUPERVISED_SIGNING = "supervised_signing",
}

export enum EcommerceItemName {
  WILL = "Will",
  HCP = "HCP",
  POA = "POA",
  ESSENTIALS = "Essentials Package",
  POWERS = "Powers Package",
  SELF_SIGNING = "Self Signing",
  SUPERVISED_SIGNING = "Supervised Signing",
}

export enum EcommerceItemCategory {
  PRODUCTS = "products",
  PACKAGES = "packages",
  SIGNING_OPTIONS = "signing_options",
}

export type EcommerceItem = {
  item_id: EcommerceItemID;
  item_name: EcommerceItemName;
  index: number;
  item_brand?: PartnerID;
  item_category: EcommerceItemCategory;
  item_list_id?: ItemListID;
  item_list_name?: ItemListName;
  coupon?: string;
  discount?: number;
  price?: number;
  quantity?: number;
};

export type ViewItemListEvent = {
  event: EventName.ViewItemList;
  item_list_id: ItemListID;
  item_list_name: ItemListName;
  items: EcommerceItem[];
};

/* Select Item Event Types */

export type SelectItemEvent = {
  event: EventName.SelectItem;
  item_list_id: ItemListID;
  item_list_name: ItemListName;
  items: EcommerceItem[];
};

/* Begin Checkout Event Types */

export type BeginCheckoutEvent = {
  event: EventName.BeginCheckout;
  currency: string;
  coupon?: string;
  items: EcommerceItem[];
};

/* Checkout Error Event Types */

export type CheckoutErrorEvent = {
  event: EventName.CheckoutError;
  error_field?: string;
};

/* Purchase Event Types */

export type PurchaseEvent = {
  event: EventName.Purchase;
  currency: string;
  transaction_id: string;
  value: number;
  coupon?: string;
  items: EcommerceItem[];
};

/* Workflow Event Types */

export type WorkflowEvent = {
  event: EventName.Workflow;
  action: WorkflowInteraction.Form;
  step?: string;
  user_ID?: UserID;
  workflow_id?: WorkflowID;
};

export enum WorkflowInteraction {
  Form = "form_interaction",
}
