import { Box, Container, Stack, Typography } from "@mui/material";
import ImgWithFallback from "components/ImgWithFallback";
import Wrapper from "components/Wrapper";
import React from "react";

import AboutUsMaleWebP from "static/images/landing/about-us-male.webp";
import AboutUsMalePNG from "static/images/landing/about-us-male.png";
import AboutUsFemaleWebP from "static/images/landing/about-us-female.webp";
import AboutUsFemalePNG from "static/images/landing/about-us-female.png";

import styles from "./styles.module.scss";

import classNames from "classnames/bind";
import Button from "components/Button";
import { ButtonSize, ChevronColour } from "components/Button/types";
import { useDispatch } from "react-redux";
import {
  navigateToPartnerPricing,
  navigateToSearchScreen,
} from "modules/navigation/helpers/navigator";
import { useSelector } from "redux/reducer";

const cx = classNames.bind(styles);

const AboutUsPage: React.FC = () => {
  const dispatch = useDispatch();
  const partnerId = useSelector((state) => state.partner?.id);

  const handleClick = () => {
    if (partnerId === undefined) {
      navigateToSearchScreen(dispatch);
    } else {
      navigateToPartnerPricing(dispatch, partnerId);
    }
  };

  const responsiveTextSizing = {
    xs: "0.85rem",
    sm: "0.85rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.25rem",
  };

  return (
    <Box sx={{ backgroundColor: "#EBEBEB" }}>
      <Container
        sx={{
          backgroundColor: "white",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          paddingLeft: 0,
          paddingRight: 0,
        }}
        maxWidth={false}
      >
        <Wrapper>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            paddingLeft={{ xs: "1rem" }}
            paddingRight={{ xs: "1rem" }}
          >
            <Stack spacing={3} marginBottom="1rem">
              <Typography
                variant="h1"
                className={cx("about-us-heading")}
                marginTop="3rem"
                fontSize={{
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.25rem",
                  lg: "2.5rem",
                  xl: "3rem",
                }}
              >
                About Us
              </Typography>
              <Typography variant="body1" fontSize={responsiveTextSizing}>
                Aturna was created on the belief that every person is entitled
                to the protection that an estate plan provides, regardless of
                socioeconomic status. Additionally, Aturna believes that
                convenience should not be a trade-off for legitimacy, so every
                estate plan completed through Aturna is backed by an attorney in
                your local area.
              </Typography>
              <Typography variant="body1" fontSize={responsiveTextSizing}>
                We're establishing an enduring relationship between you and an
                attorney on your behalf so that you don't have to waste your
                time or money by doing it on your own.
              </Typography>
              <Button
                label="Let's get started"
                chevronColour={ChevronColour.GREEN}
                chevronRight
                size={ButtonSize.LARGE}
                onClick={handleClick}
              />
            </Stack>
            <Box className={cx("about-us-image-container")}>
              <ImgWithFallback
                src={AboutUsFemaleWebP}
                fallback={AboutUsFemalePNG}
                alt="Woman"
                className={cx("about-us-image")}
              />
            </Box>
          </Stack>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            paddingLeft={{ xs: "1rem" }}
            paddingRight={{ xs: "1rem" }}
          >
            <Box className={cx("about-us-image-container")}>
              <ImgWithFallback
                src={AboutUsMaleWebP}
                fallback={AboutUsMalePNG}
                alt="Woman"
                className={cx("about-us-image")}
              />
            </Box>
            <Stack spacing={3} marginBottom="1rem">
              <Typography
                variant="h2"
                className={cx("about-us-heading")}
                marginTop="1.5rem"
                fontSize={{
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.25rem",
                  lg: "2.5rem",
                  xl: "3rem",
                }}
              >
                Aturna Is Determined To Democratize And Simplify Estate Planning
                For Every American
              </Typography>
              <Typography variant="body1" fontSize={responsiveTextSizing}>
                We ensure every estate plan has the security that a dedicated
                attorney provides, by guaranteeing every plan is backed by your
                local attorney.
              </Typography>
              <Button
                label="Create your plan"
                chevronColour={ChevronColour.GREEN}
                chevronRight
                size={ButtonSize.LARGE}
                onClick={handleClick}
              />
            </Stack>
          </Stack>
        </Wrapper>
      </Container>
    </Box>
  );
};

export default AboutUsPage;
