import { PartyValue } from "components/Forms/Questions/QuestionPerParty";
import { Share } from "components/Forms/Questions/QuestionPerParty/QuestionShareDistribution";
import { keyBy } from "lodash";
import mapValues from "lodash/mapValues";
import {
  QuestionDefinition,
  QuestionID,
  QuestionnaireID,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
  QuestionType,
} from "../types/Questionnaire";
import { AnswersByQuestionnaireType, AnswersMap } from "../types/Answer";
import { PerPartyQuestionnaireAnswer } from "modules/parties/types/PerPartyAnswer";
import {
  GenericParty,
  getGenericPartyId,
  PartyID,
} from "modules/parties/types/Party";

const hasPrimitiveValue = (value: any) =>
  value !== undefined && value !== null && value.length !== 0;

const getTransformedPartyId = (
  genericParty: GenericParty,
  spouseId?: string,
  clientId?: string,
): string => {
  const partyId = getGenericPartyId(genericParty);
  return clientId && spouseId && partyId === spouseId ? clientId : partyId;
};

export const isAnswered = (
  value: any,
  questionDefinition?: QuestionDefinition,
  spouseId?: string,
  clientId?: string,
): boolean => {
  if (value === undefined || value === null) return false;

  switch (questionDefinition?.type) {
    case QuestionType.ADDRESS:
      return Boolean(value?.line1 && value.city && value.state && value.zip);

    case QuestionType.SUBQUESTIONNAIRE: {
      const values: Record<PartyID, AnswersMap | undefined> | undefined = (
        value as PerPartyQuestionnaireAnswer
      )?.values;
      if (!values) return false;

      const emptySubquestionnaire = Object.entries(values || {}).find(
        ([_partyId, answers]) =>
          !answers ||
          (typeof answers === "object" && Object.keys(answers).length === 0),
      );
      if (emptySubquestionnaire) return false;

      if (questionDefinition.parties) {
        const unansweredPartySubquestionnaire = questionDefinition.parties.find(
          (party) => {
            const partyId = getTransformedPartyId(party, spouseId, clientId);
            return (
              !values?.[partyId] || Object.keys(values[partyId]!).length === 0
            );
          },
        );

        if (unansweredPartySubquestionnaire) return false;
      }
      return true;
    }

    case QuestionType.SHARE_DISTRIBUTION: {
      if (!Array.isArray(value)) return false;

      const emptyShare = (value as Share[]).find(
        ({ share }) => !hasPrimitiveValue(share),
      );
      if (emptyShare) return false;

      const sharesMap = mapValues(
        keyBy(value as Share[], "partyId"),
        ({ share }) => share,
      );
      const unassignedParty = questionDefinition.parties?.find((party) => {
        const partyId = getTransformedPartyId(party, spouseId, clientId);
        return !sharesMap[partyId];
      });

      return !unassignedParty;
    }

    case QuestionType.PER_PARTY: {
      if (!Array.isArray(value)) return false;

      const emptyValue = (value as PartyValue[]).find(
        ({ value }) => !isAnswered(value, questionDefinition.definition),
      );
      if (emptyValue) return false;

      const valuesMap = mapValues(
        keyBy(value as PartyValue[], "partyId"),
        ({ value }) => value,
      );
      const unassignedParty = questionDefinition.parties?.find((party) => {
        const partyId = getTransformedPartyId(party, spouseId, clientId);
        return !valuesMap[partyId];
      });
      return !unassignedParty;
    }

    default:
      return hasPrimitiveValue(value);
  }
};

const isQuestionDisplayed = (
  questionnaireQuestions: QuestionsMap,
  questionId: string,
) => Boolean(questionnaireQuestions[questionId]);

const getFilledQuestionsByQuestionnaire = (
  answersByQuestionnaire: AnswersByQuestionnaireType,
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
): Record<QuestionnaireID, QuestionID[]> =>
  mapValues(answersByQuestionnaire, (answers, questionnaireId) =>
    Object.entries(answers)
      .filter(
        ([questionId, value]) =>
          questionsByQuestionnaire[questionnaireId] &&
          isAnswered(
            value,
            questionsByQuestionnaire[questionnaireId][questionId]?.data
              .definition,
          ) &&
          isQuestionDisplayed(
            questionsByQuestionnaire[questionnaireId],
            questionId,
          ),
      )
      .map(([questionId]) => questionId),
  );

export default getFilledQuestionsByQuestionnaire;
