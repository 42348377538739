import { Middleware } from "@reduxjs/toolkit";
import {
  ADVISOR_ANSWERS_KEY,
  onboardingQuestionnaireSubmitted,
} from "./actions";
import localStorage from "modules/localStore";
import { LOCATION_CHANGE } from "connected-react-router";
import { isPath } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";

const createAdvisorMiddleware: () => Middleware =
  () => (store) => (next) => async (action) => {
    next(action);
    switch (action.type) {
      case onboardingQuestionnaireSubmitted.type: {
        localStorage.set(ADVISOR_ANSWERS_KEY, action.payload);
        break;
      }

      case LOCATION_CHANGE: {
        if (
          !isPath(ROUTE_TEMPLATE.ADVISOR, action.payload.location) &&
          !isPath(
            ROUTE_TEMPLATE.PRODUCT_RECOMMENDATION,
            action.payload.location,
          )
        ) {
          localStorage.delete(ADVISOR_ANSWERS_KEY);
        }
        break;
      }
    }
  };

export default createAdvisorMiddleware;
