import PageHeader from "components/PageHeader";
import PaginatedList, { ListType } from "components/PaginatedList";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import usePartnerRequests from "modules/globalAdmin/hooks/usePartnerRequests";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { PartnerRegistration } from "modules/parties/types/Partner";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  showAcceptRegistrationModal,
  showRejectRegistrationModal,
} from "../registrationReviewModals";
import styles from "./styles.module.scss";

type Props = { registrations: PartnerRegistration[] };

const PartnersRequestListItem: React.FC<{
  registration: PartnerRegistration;
}> = ({ registration }) => {
  const dispatch = useDispatch();
  const { refetchPartnerRequests } = usePartnerRequests();

  const handleRemoveRegistrationFromList = useCallback(() => {
    refetchPartnerRequests((requests) =>
      requests?.filter((req) => req.id !== registration.id),
    );
  }, [refetchPartnerRequests, registration.id]);

  return (
    <tr>
      <td>{registration.request.displayName}</td>
      <td className={styles["id"]}>{registration.request.partnerId}</td>
      <td className={styles["buttons"]}>
        <TabbedButton
          mood={TabbedButtonMood.NEGATIVE}
          onClick={() =>
            showRejectRegistrationModal(
              dispatch,
              registration,
              handleRemoveRegistrationFromList,
            )
          }
        >
          Reject
        </TabbedButton>
        <TabbedButton
          mood={TabbedButtonMood.POSITIVE}
          onClick={() =>
            showAcceptRegistrationModal(
              dispatch,
              registration,
              handleRemoveRegistrationFromList,
            )
          }
        >
          Accept
        </TabbedButton>
        <TabbedButton
          onClick={() =>
            go(
              dispatch,
              `${getRoute(ROUTE_TEMPLATE.ADMIN_REGISTRATIONS, {
                registrationId: registration.id,
              })}`,
            )
          }
          style={TabbedButtonType.SECONDARY}
        >
          Edit
        </TabbedButton>
      </td>
    </tr>
  );
};

const renderItem = (registration: PartnerRegistration) => (
  <PartnersRequestListItem registration={registration} key={registration.id} />
);

const PartnerRequestsList: React.FC<Props> = ({ registrations }) => {
  return (
    <div>
      <PageHeader title="Pending Requests" />
      <PaginatedList<PartnerRegistration>
        listType={ListType.TABLE}
        items={registrations}
        pageSize={20}
        renderItem={renderItem}
        tableColumns={[
          { label: "Name", accessor: "name", sortable: false, reset: false },
          { label: "ID", accessor: "id", sortable: false, reset: false },
          { label: "", accessor: "empty", sortable: false, reset: false },
        ]}
      />
    </div>
  );
};

export default PartnerRequestsList;
