import React, { ReactNode } from "react";
import { useState } from "react";
import classNames from "classnames/bind";

import TabbedButton, { TabbedButtonType } from "components/TabbedButton";

import styles from "./styles.module.scss";
import { IconType } from "components/Icons";
const cx = classNames.bind(styles);

export enum CollapsableCardColour {
  WHITE,
  PRIMARY,
}

type CollapsableCardProps = {
  children?: ReactNode;
  closed?: boolean;
  count?: number;
  title: string;
  onClick?: () => void;
  buttonLabel?: string;
  colour?: CollapsableCardColour;
};

const CollapsableCard = ({
  children,
  closed,
  count,
  title,
  onClick,
  buttonLabel,
  colour = CollapsableCardColour.WHITE,
}: CollapsableCardProps) => {
  const [open, setOpen] = useState(closed ? false : true);
  const classNames = cx("collapsable-card", {
    "collapsable-card--open": open,
    "collapsable-card--primary": colour === CollapsableCardColour.PRIMARY,
  });
  return (
    <div className={classNames}>
      <div className={styles["collapsable-card__row"]}>
        <h2 className={styles["collapsable-card__title"]}>{title}</h2>
        {onClick && (
          <TabbedButton
            style={TabbedButtonType.SECONDARY}
            label={buttonLabel}
            onClick={onClick}
          />
        )}
        {count && !open ? (
          <div className={styles["collapsable-card__notification"]}>
            {count}
          </div>
        ) : null}
        <TabbedButton
          className={styles["collapsable-card__toggle"]}
          onClick={() => setOpen(!open)}
          icon={open ? IconType.MINUS : IconType.PLUS}
        />
      </div>
      {open && children}
    </div>
  );
};

export default CollapsableCard;
