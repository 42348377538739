import * as React from "react";
import { useRef } from "react";
import classNames from "classnames/bind";
import useAutocomplete from "@mui/material/useAutocomplete";

import { HTMLInputType } from "../Input/types";
import AutocompleteList from "./AutocompleteList";
import { ReactComponent as ClearSVG } from "./clear.svg";

import styles from "../Input/styles.module.scss";
import { sortBy } from "lodash";
const cx = classNames.bind(styles);

type AutocompleteProps<T> = {
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  filterOptions?: (option: T[]) => any[];
  filterSelectedOptions?: boolean;
  getOptionLabel: (option: T) => string;
  id?: string;
  inputValue?: string;
  invalid?: boolean;
  loading?: boolean;
  maxItems?: number;
  maxLength?: number;
  name?: string;
  onClear?: () => void;
  onInputChange: (event: any, value: string) => void;
  onValueChange: (event: any, value: T | null) => void;
  options?: T[];
  placeholder?: string;
  size?: "small" | "large";
  tabIndex?: number;
  valid?: boolean;
  value?: T;
};

export const getOptionPenaltyScore = (testedValue: string, term: string) => {
  const index = term.toLowerCase().indexOf(testedValue.toLowerCase());
  const occurrenceScore = index === -1 ? 100 : index;
  return occurrenceScore + Math.abs(term.length - testedValue.length);
};

function Autocomplete<T>(props: AutocompleteProps<T>) {
  const ref = useRef<HTMLDivElement | null>(null);

  // Sort options by how close are they to the input
  const sortedOptions = props.inputValue
    ? sortBy(props.options, (option) =>
        getOptionPenaltyScore(props.inputValue!, props.getOptionLabel(option)),
      )
    : props.options;

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete<T>({
    disableCloseOnSelect: props.disableCloseOnSelect,
    filterOptions: props.filterOptions,
    filterSelectedOptions: props.filterSelectedOptions,
    getOptionLabel: props.getOptionLabel,
    inputValue: props.inputValue,
    onChange: props.onValueChange,
    onInputChange: props.onInputChange,
    options: sortedOptions || [],
    value: props.value || null,
    id: props.id,
  });

  const hideList = Boolean(!props.inputValue && props.maxItems);

  let className = cx("input", "input--autocomplete", {
    "input--clear": props.inputValue || props.value,
    "input--disabled": props.disabled,
    "input--loading": props.loading,
    "input--invalid": !props.disabled && props.invalid,
    "input--valid": !props.disabled && props.valid,
    "input--autocomplete-open": groupedOptions.length > 0 && !hideList,
  });

  const handleMouseDown = (_event: React.MouseEvent<SVGSVGElement>) => {
    props.onClear && props.onClear();
    if (ref.current && ref.current.getElementsByTagName("input").length) {
      ref.current.getElementsByTagName("input")[0].focus();
    }
  };

  return (
    <div {...getRootProps()} className={className} ref={ref}>
      <input
        {...getInputProps()}
        autoComplete="off"
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        maxLength={props.maxLength}
        placeholder={props.placeholder || "Start typing to search..."}
        tabIndex={props.disabled ? -1 : props.tabIndex ? props.tabIndex : 0}
        type={HTMLInputType.TEXT}
      />
      {(props.inputValue || props.value) && (
        <ClearSVG
          aria-label="Clear Input"
          className={styles["input__clear"]}
          onMouseDown={handleMouseDown}
        />
      )}
      <AutocompleteList
        getListboxProps={getListboxProps}
        getOptionProps={getOptionProps}
        groupedOptions={groupedOptions as T[]}
        getOptionLabel={props.getOptionLabel}
        parentRef={ref}
        size={props.size}
        limit={props.maxItems}
        hideList={hideList}
      />
    </div>
  );
}

export default Autocomplete;
