import Answer from "modules/workflow/types/Answer";
import Client, { ClientType } from "modules/parties/types/Client";
import Product from "modules/product/types/Product";
import Questionnaire, {
  QuestionnaireID,
  QuestionID,
  QuestionsMap,
} from "modules/workflow/types/Questionnaire";
import WorkflowPayload from "modules/api/types/WorkflowPayload";
import Party, { PartyID, Relationship } from "modules/parties/types/Party";
import Addon from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";
import { Definition } from "../responseTypes/DefinitionResponseType";
import { User } from "../../auth/User";

export type PartyWithRelationships = {
  party: Party;
  relationships: Relationship[];
};

export type ClientsMap = Record<ClientType, Client | undefined>;

export enum WorkflowDocumentType {
  SIGNING = "Signing",
  DOCUMENT = "Document",
  AGREEMENT = "Agreement",
}

export enum RenderedDocumentStatus {
  PENDING = "Pending",
  FINISHED = "Finished",
  FAILURE = "Failure",
}

export type WorkflowDocumentDetails = {
  bucket: string;
  created: string;
  documentType: WorkflowDocumentType;
  fileName: string;
  id: string;
  name: string;
  partnerId: string;
  status: RenderedDocumentStatus;
  updated: string;
  userId: string;
  valid: boolean;
  workflowId: string;
};

export type QuestionnaireDefinitionPayload = {
  questionnaire: Omit<Questionnaire, "questions">;
  questions: QuestionsMap;
};

export type QuestionnaireDefinitionsPayloadMap = Record<
  QuestionnaireID,
  QuestionnaireDefinitionPayload
>;

type WorkflowDetailsPayload = {
  addons: Record<string, Addon>;
  answers: Record<QuestionnaireID, Record<QuestionID, Answer>>;
  availableParties: Record<PartyID, PartyWithRelationships>;
  clients: ClientsMap;
  definition: Definition;
  documents: WorkflowDocumentDetails[];
  parties: Record<string, PartyWithRelationships>;
  products: Record<string, Product>;
  plan?: Plan;
  questionnaires: QuestionnaireDefinitionsPayloadMap;
  workflow: WorkflowPayload;
  user?: User;
};

export default WorkflowDetailsPayload;
