import ClickThroughCard from "components/ClickThroughCard";
import { FormSubmitHandle } from "components/Forms/Form";
import Progress from "components/Forms/Progress";
import { FormikProps } from "formik";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import countItemsAcrossKeys from "modules/workflow/helpers/countItemsAcrossKeys";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import {
  Question,
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
  QuestionnaireProgress,
} from "modules/workflow/types/Questionnaire";
import Workflow from "modules/workflow/types/Workflow";
import React, { useCallback } from "react";
import styles from "./styles.module.scss";

type InterviewQuestionnaireSidebarProps = {
  answers?: AnswersByQuestionnaireType;
  clients?: ClientsMap;
  coupledWorkflowAnswers?: AnswersByQuestionnaireType;
  currentPageQuestions: Question[];
  isCoupledWorkflow: Boolean;
  submitForm?: FormSubmitHandle;
  submitHandle?: FormSubmitHandle;
  formHandle?: FormikProps<AnswersMap>;
  questionnairePages: QuestionnairePage[];
  questionnaireProgress: QuestionnaireProgress;
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire;
  pageIdx: number;
  onPageChanged?: (
    pageIdx: number,
    pageId?: string,
    pageCaption?: string,
    initialLoad?: boolean,
  ) => any;
  onOpenPeopleRegistry?: () => any;
  handleSwitchToSpouse?: (
    submitHandle?: FormSubmitHandle | undefined,
  ) => Promise<void> | undefined;
  handleNavigationRequest?: (
    saveDelta: boolean,
    submitHandle: FormSubmitHandle | undefined,
    successCallback: () => any,
  ) => Promise<void>;
  isAdmin?: boolean;
  workflow?: Workflow;
  showPeoplePrompt?: boolean;
};

const InterviewQuestionnaireSidebar: React.FunctionComponent<
  InterviewQuestionnaireSidebarProps
> = ({
  answers,
  clients,
  coupledWorkflowAnswers,
  currentPageQuestions,
  isCoupledWorkflow,
  submitForm,
  submitHandle,
  formHandle,
  questionnairePages,
  questionnaireProgress,
  questionsBySubquestionnaire,
  pageIdx,
  onOpenPeopleRegistry,
  onPageChanged,
  handleSwitchToSpouse,
  handleNavigationRequest,
  isAdmin,
  workflow,
  showPeoplePrompt,
}) => {
  const answeredQuestionsCount = countItemsAcrossKeys(
    questionnaireProgress.answeredQuestionsIDs,
  );
  const totalQuestionsCount = countItemsAcrossKeys(
    questionnaireProgress.allQuestionsIDs,
  );

  const handlePageChangeRequest = useCallback(
    async (
      nextPageIdx: number,
      nextPageId?: string,
      nextPageTitle?: string,
      submitForm?: FormSubmitHandle,
    ) => {
      onPageChanged &&
        handleNavigationRequest &&
        handleNavigationRequest(
          nextPageIdx < pageIdx || (isAdmin !== undefined && isAdmin),
          submitForm,
          () => onPageChanged(nextPageIdx, nextPageId, nextPageTitle),
        );
    },
    [pageIdx, handleNavigationRequest, isAdmin, onPageChanged],
  );

  return (
    <div className={styles["InterviewQuestionnaireSidebar"]}>
      <Progress
        addons={questionnaireProgress.selectedAddons}
        answeredQuestionsCount={answeredQuestionsCount}
        answers={answers}
        clients={clients}
        coupledWorkflowAnswers={coupledWorkflowAnswers}
        currentPageIdx={pageIdx}
        currentPageQuestions={currentPageQuestions}
        formHandle={formHandle}
        isCoupledWorkflow={isCoupledWorkflow}
        onSectionSelected={(idx, id, pageTitle) =>
          handlePageChangeRequest(idx, id, pageTitle, submitForm)
        }
        questionnairePage={questionnairePages[pageIdx]}
        questionsBySubquestionnaire={questionsBySubquestionnaire}
        sections={questionnaireProgress.sectionsProgress}
        submitHandle={submitHandle}
        totalQuestionsCount={totalQuestionsCount}
        isSecondaryWorkflow={workflow && !workflow.state.primary}
        handleSwitchToSpouse={handleSwitchToSpouse}
        created={workflow ? workflow.created : undefined}
      >
        {showPeoplePrompt && (
          <ClickThroughCard
            title="Your people"
            description="View and edit everyone you have added so far."
            onAction={() =>
              onOpenPeopleRegistry &&
              handleNavigationRequest &&
              handleNavigationRequest(true, submitForm, onOpenPeopleRegistry)
            }
            actionLabel="View all people"
          />
        )}
      </Progress>
    </div>
  );
};

export default InterviewQuestionnaireSidebar;
