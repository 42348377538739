import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as InfoSVG } from "components/Forms/Questions/QuestionWrapper/info.svg";
import { ReactComponent as TickSVG } from "./tick.svg";
import classNames from "classnames/bind";
import { ModalType, showModalConfirm } from "components/Modal";
import SanitizeHtml from "components/SanitizeHtml";
const cx = classNames.bind(styles);

type Props = {
  description?: string;
  name: string;
  id: string;
  disabled?: boolean;
  note?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

const SelectorFeature: React.FC<Props> = ({
  name,
  description,
  id,
  Icon,
  disabled,
  note,
}) => {
  return (
    <li key={`${id}`} className={styles["selector-feature"]}>
      {description ? (
        <InfoSVG
          aria-label={`Feature ${name} Tooltip`}
          className={styles["selector-feature__tooltip"]}
          onClick={() =>
            !disabled &&
            showModalConfirm({
              labelConfirm: "Close",
              text: <SanitizeHtml html={description || ""} />,
              title: name,
              type: ModalType.NOTIFICATION,
            })
          }
        />
      ) : null}
      <div className={styles["selector-feature__title"]}>
        {name}
        {note ? (
          <div className={styles["selector-feature__note"]}>{note}</div>
        ) : null}
      </div>
      {Icon ? (
        <Icon className={styles["selector-feature__icon"]} />
      ) : (
        <TickSVG
          aria-label={`Feature ${name} Tick`}
          className={cx(
            "selector-feature__icon",
            "selector-feature__icon--tick",
          )}
        />
      )}
    </li>
  );
};

export default SelectorFeature;
