import React, { useMemo } from "react";
import { AcceptedMedicaidLeadPreviewCardProps } from "./types";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import MedicaidClientDetails from "./MedicaidClientDetails";
import { MedicaidCalculationType } from "modules/medicaid/types";
import { Divider } from "@mui/material";
import currencyFormatter from "utils/currencyFormatter";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";
import { getTotalAssetsProtected } from "screens/medicaidAnalysis/helpers";

const cx = classNames.bind(styles);

const AcceptedMedicaidLeadPreviewCard = ({
  enrichedMedicaidLead,
  medicaidCalculation,
  isLoading,
}: AcceptedMedicaidLeadPreviewCardProps) => {
  const showLoadingOverlay =
    isLoading ||
    enrichedMedicaidLead === undefined ||
    medicaidCalculation === undefined;

  const immediatelyProtectedWithPlan = useMemo(
    () =>
      medicaidCalculation !== undefined
        ? getTotalAssetsProtected(medicaidCalculation)
        : 0,
    [medicaidCalculation],
  );

  if (showLoadingOverlay)
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.MEDIUM} />;

  return (
    <div className={cx("accepted-medicaid-lead-preview-container")}>
      <div className={cx("accepted-medicaid-lead-preview")}>
        <div className={cx("accepted-medicaid-lead-preview__section")}>
          <h3>Institutionalized Spouse</h3>
          <MedicaidClientDetails
            client={medicaidCalculation.workings.institutionalisedSpouse}
          />
        </div>
        <Divider
          className={cx("accepted-medicaid-lead-preview__divider")}
          orientation="vertical"
          variant="middle"
          light={true}
        />
        {medicaidCalculation.calculationType ===
          MedicaidCalculationType.MARRIED && (
          <>
            <div className={cx("accepted-medicaid-lead-preview__section")}>
              <h3>Community Spouse</h3>
              <MedicaidClientDetails
                client={medicaidCalculation.workings.communitySpouse}
              />
            </div>
            <Divider
              className={cx("accepted-medicaid-lead-preview__divider")}
              orientation="vertical"
              variant="middle"
              light={true}
            />
          </>
        )}
        <div className={cx("accepted-medicaid-lead-preview__section")}>
          <h3>Assets</h3>
          <ol>
            <li>
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-heading",
                )}
              >
                Total Real Property:
              </span>
              <br />
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-value",
                )}
              >
                {currencyFormatter.format(
                  medicaidCalculation.workings.totalRealPropertyAssets,
                )}
              </span>
            </li>
            <li>
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-heading",
                )}
              >
                Total Retirement:
              </span>
              <br />
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-value",
                )}
              >
                {currencyFormatter.format(
                  medicaidCalculation.workings.totalRetirementAssets,
                )}
              </span>
            </li>
            <li>
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-heading",
                )}
              >
                Total Assets:
              </span>
              <br />
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-value",
                )}
              >
                {currencyFormatter.format(
                  medicaidCalculation.answers.totalAssets,
                )}
              </span>
            </li>
            <li>
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-heading",
                )}
              >
                Immediately Protect:
              </span>
              <br />
              <span
                className={cx(
                  "accepted-medicaid-lead-preview__attribute-value",
                )}
              >
                {currencyFormatter.format(immediatelyProtectedWithPlan)}
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default AcceptedMedicaidLeadPreviewCard;
