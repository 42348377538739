import React from "react";
import {
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { gettingStartedFAQs, planningFAQs } from "./faqs";
import FAQ from "./FAQ";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import ImgWithFallback from "components/ImgWithFallback";

import MotherAndDaughterWebP from "static/images/faqs/mother-and-daughter.webp";
import MotherAndDaughterPNG from "static/images/faqs/mother-and-daughter.png";

const cx = classNames.bind(styles);

const FAQsPage: React.FC = () => {
  const aboveMediumWidth = useMediaQuery("(min-width: 900px)");
  const subHeadingFontSize = {
    xs: "1rem",
    sm: "1.25rem",
    md: "1.5rem",
    lg: "2rem",
  };

  return (
    <Container className={cx("faqs")}>
      <Grid container>
        <Grid item md={12}>
          <Typography
            variant="h1"
            fontSize={{
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
            }}
          >
            Frequently Asked Questions
          </Typography>
        </Grid>
        {aboveMediumWidth && (
          <Grid item md={6}>
            <Container className={cx("faqs-image-container")}>
              <ImgWithFallback
                src={MotherAndDaughterWebP}
                fallback={MotherAndDaughterPNG}
                alt="Mother and Daughter"
              />
            </Container>
          </Grid>
        )}
        <Grid item sm={12} md={6}>
          <Stack spacing={3} marginBottom={"2rem"}>
            <Paper sx={{ padding: "1rem" }} elevation={3}>
              <Typography variant="h2" fontSize={subHeadingFontSize}>
                Planning
              </Typography>
              {planningFAQs.map(({ question, answer }) => (
                <FAQ key={question} question={question} answer={answer} />
              ))}
            </Paper>
            <Paper sx={{ padding: "1rem" }} elevation={3}>
              <Typography variant="h2" fontSize={subHeadingFontSize}>
                Getting Started
              </Typography>
              {gettingStartedFAQs.map(({ question, answer }) => (
                <FAQ key={question} question={question} answer={answer} />
              ))}
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQsPage;
