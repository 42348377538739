import * as React from "react";
import classNames from "classnames/bind";
import {
  RenderedDocumentStatus,
  WorkflowDocumentDetails,
} from "modules/api/types/WorkflowDetailsPayload";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type DocumentsRenderStatusListProps = {
  documents: WorkflowDocumentDetails[];
};

const DocumentsRenderStatusList: React.FC<DocumentsRenderStatusListProps> = ({
  documents,
}) => {
  return documents.length > 0 ? (
    <ul className={cx("documents-list", "documents-render-status")}>
      {documents.map((doc) => (
        <li
          key={doc.id}
          className={cx({
            "documents-render-status__failure":
              doc.status === RenderedDocumentStatus.FAILURE,
            "documents-render-status__success":
              doc.status === RenderedDocumentStatus.FINISHED,
            "documents-render-status__pending":
              doc.status === RenderedDocumentStatus.PENDING,
          })}
        >
          <span className={cx("documents-render-status__name")}>
            {doc.name}
          </span>
          <span className={cx("documents-render-status__status")}>
            {doc.status}
          </span>
        </li>
      ))}
    </ul>
  ) : (
    <p className={styles["documents-list--empty"]}>No documents available...</p>
  );
};

export default DocumentsRenderStatusList;
