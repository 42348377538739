const parseParams = (queryString?: string | null): Record<string, string> => {
  if (!queryString) return {};
  return queryString
    .replace(/^[?#]/, "")
    .split("&")
    .reduce(
      (acc, queryParam) => {
        const parts = queryParam.split("=");
        acc[parts[0] as string] = parts[1];
        return acc;
      },
      {} as Record<string, string>,
    );
};

export const parseInteger = (param: string | undefined): number | null => {
  const int = param ? Number.parseInt(param) : NaN;
  return Number.isNaN(int) || String(int) !== param ? null : int;
};

export const parseIntegerParam = (
  queryString: string | null | undefined,
  paramName: string,
): number | null => {
  const params = parseParams(queryString);
  const int = Number.parseInt(params[paramName]);
  return Number.isNaN(int) || String(int) !== params[paramName] ? null : int;
};

export default parseParams;
