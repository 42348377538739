import { PlatformType } from "contexts/PlatformContext";

export enum HTMLInputType {
  DATE = "date",
  EMAIL = "email",
  MONTH = "month",
  NUMBER = "number",
  PASSWORD = "password",
  TEL = "tel",
  TEXT = "text",
}

export enum InputType {
  CODE = "code",
  CURRENCY = "currency",
  CURRENCY_ALLOW_NEGATIVE = "currency_allow_negative",
  DATE = "date",
  DECIMAL = "decimal",
  DECIMAL_ALLOW_NEGATIVE = "decimal_allow_negative",
  EMAIL = "email",
  INTEGER = "integer",
  NUMBER = "number",
  INTEGER_ALLOW_NEGATIVE = "integer_allow_negative",
  MONTH = "month",
  PASSWORD = "password",
  PERCENTAGE = "percentage",
  PERCENTAGE_INTEGER = "percentage_integer",
  SOCIAL_SECURITY_NUMBER = "social_security_number",
  TEL = "tel",
  TEXT = "text",
  ZIP_CODE = "zip_code",
}

export enum HTMLInputMode {
  DECIMAL = "decimal",
  EMAIL = "email",
  NONE = "none",
  NUMERIC = "numeric",
  SEARCH = "search",
  TEL = "tel",
  TEXT = "text",
  URL = "url",
}

export type Mask = {
  inputMask?: {};
  inputMode?: HTMLInputMode;
  jitMasking?: boolean;
  maxLength?: number;
  onBlur?: (
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
  ) => (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void,
  ) => (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput?: (
    onInput?: (event: React.FormEvent<HTMLInputElement>) => void,
  ) => (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  ) => (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onMount?: (input: React.MutableRefObject<HTMLInputElement | null>) => void;
  pattern?: string | undefined;
  placeholder?: string;
  type: HTMLInputType;
};

export type MaskProps = {
  max?: number | string;
  min?: number | string;
  platform: PlatformType;
  type: InputType;
};
