import React from "react";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import {
  QuestionnairePage,
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";
import { AnswersByQuestionnaireType } from "../types/Answer";
import { MEDICAID_LEAD_SUMMARY_PAGE_ID } from "./pageIDs";
import { SectionState } from "../helpers/getSectionsProgress";
import { FormProps } from "components/Forms/Form";
import MedicaidLeadSummary from "screens/medicaidAnalysis/MedicaidLeadSummary";

const getMedicaidLeadSummaryPage = (
  clients: ClientsMap,
  questionnairePages: QuestionnairePage[],
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire,
  questionnaireAnswers: AnswersByQuestionnaireType,
  isEditable: boolean,
  onConfirmSummary: () => void,
  onEditPage: (pageIdx: number) => void,
): QuestionnairePage => {
  const handleEditPage = isEditable ? onEditPage : () => {};

  return {
    id: MEDICAID_LEAD_SUMMARY_PAGE_ID,
    caption: "Summary",
    questionnaireId: "",
    isStatic: true,
    sections: [],
    getProgress: () => {
      return {
        label: "Summary",
        state: SectionState.PENDING,
      };
    },
    render: (formProps: FormProps) => {
      return (
        <MedicaidLeadSummary
          clients={clients}
          isEditable={isEditable}
          questionsBySubquestionnaire={questionsBySubquestionnaire}
          questionnairePages={questionnairePages}
          questionnaireAnswers={questionnaireAnswers}
          onBack={formProps.onBack}
          onEdit={handleEditPage}
          onConfirmSummary={onConfirmSummary}
        />
      );
    },
  };
};

export default getMedicaidLeadSummaryPage;
