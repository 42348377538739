import * as React from "react";

import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";

type HeaderAccountModalLogoutProps = {
  onLogout: () => void;
};

const HeaderAccountModalLogout: React.FunctionComponent<
  HeaderAccountModalLogoutProps
> = ({ onLogout }) => {
  return (
    <div className={styles["header-account-modal__logout"]}>
      <TabbedButton
        tabIndex={0}
        className={styles["header-account-modal__button"]}
        onClick={onLogout}
      >
        <span
          className={
            styles["header-account-modal__icon"] +
            " " +
            styles["header-account-modal__icon--logout"]
          }
        ></span>
        <span className={styles["header-account-modal__button-text"]}>
          Log out
        </span>
      </TabbedButton>
    </div>
  );
};

export default HeaderAccountModalLogout;
