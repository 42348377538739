import * as React from "react";
import { QuestionnairePage } from "modules/workflow/types/Questionnaire";
import { SectionState } from "modules/workflow/helpers/getSectionsProgress";
import ControlBar from "components/Forms/Form/ControlBar";
import Party, { getFullName } from "../types/Party";
import Client from "../types/Client";
import RelationshipRepeater from "components/Forms/RelationshipRepeater";

export default (
  client: Client | undefined,
  children: Party[] = [],
  spouseWorkflowChildren: Party[] = [],
  handleAddChild: () => any,
  handleAddGrandchild: (parentId: string) => any,
  handleEditChild: (child: Party) => any,
  handleDeleteChild: (child: Party) => Promise<any>,
  handleImportChild: (childrenToImport: Party[]) => void,
  isEditable: boolean,
) => {
  const childrenToImport = spouseWorkflowChildren.filter(
    (child) =>
      !children.find((currentChild) => currentChild.partyId === child.partyId),
  );
  const childrenPage: QuestionnairePage = {
    id: "children",
    questionnaireId: "",
    caption: "Your Children",
    description:
      'List all children currently living AND deceased. Include all children even if you plan on disinheriting them. If you do not have any children, simply click "Next step".',
    isStatic: true,
    sections: [],
    getProgress: (isVisited) => {
      return {
        label: "Children",
        state:
          isVisited || children.length > 0
            ? SectionState.COMPLETE
            : SectionState.PENDING,
        subsections: children.map((child) => ({
          label: getFullName(child),
          state: SectionState.COMPLETE,
        })),
      };
    },
    render: (formProps) => (
      <>
        <RelationshipRepeater
          client={client}
          isEditable={isEditable}
          detailed
          parties={children}
          onEditParty={handleEditChild}
          onRemoveParty={handleDeleteChild}
          renderActionBtn={(child) =>
            child.deceased
              ? {
                  label: "+ Grandchild",
                  onClick: () => handleAddGrandchild(child.partyId),
                }
              : undefined
          }
          onAddParty={handleAddChild}
          addPartyLabel="Child"
          customActionBtns={
            childrenToImport.length
              ? [
                  {
                    label: "Import from spouse",
                    onClick: () => handleImportChild(childrenToImport),
                  },
                ]
              : undefined
          }
        />
        <ControlBar {...formProps} onSave={undefined} showSaveButton={false} />
      </>
    ),
  };

  return {
    questionnairePages: [childrenPage],
  };
};
