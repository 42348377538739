import React from "react";
import { MedicaidLeadPreviewCardProps } from "./types";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import currencyFormatter from "utils/currencyFormatter";
import { Divider } from "@mui/material";

const cx = classNames.bind(styles);

const MedicaidLeadPreviewCard = ({
  medicaidLeadPreview,
}: MedicaidLeadPreviewCardProps) => {
  return (
    <div className={cx("medicaid-lead-preview-container")}>
      <div className={cx("medicaid-lead-preview")}>
        <ol className={cx("medicaid-lead-preview__client-info")}>
          <h3>Client Details</h3>
          <li>
            <span className={cx("medicaid-lead-preview__attribute-heading")}>
              Marital Status:
            </span>
            <br />
            <span className={cx("medicaid-lead-preview__attribute-value")}>
              {medicaidLeadPreview.maritalStatus}
            </span>
          </li>
          {medicaidLeadPreview.csAge && (
            <li>
              <span className={cx("medicaid-lead-preview__attribute-heading")}>
                Community Spouse Age:
              </span>
              <br />
              <span className={cx("medicaid-lead-preview__attribute-value")}>
                {medicaidLeadPreview.csAge}
              </span>
            </li>
          )}
          <li>
            <span className={cx("medicaid-lead-preview__attribute-heading")}>
              Institutionalized Spouse Age:
            </span>
            <br />
            <span className={cx("medicaid-lead-preview__attribute-value")}>
              {medicaidLeadPreview.isAge}
            </span>
          </li>
        </ol>
        <Divider
          orientation="vertical"
          light={true}
          variant="inset"
          className={cx("medicaid-lead-preview__divider")}
        />
        <ol>
          <h3>Assets</h3>
          <li>
            <span className={cx("medicaid-lead-preview__attribute-heading")}>
              Total Real Property:
            </span>
            <br />
            <span className={cx("medicaid-lead-preview__attribute-value")}>
              {currencyFormatter.format(
                medicaidLeadPreview.assets.realProperty,
              )}
            </span>
          </li>
          <li>
            <span className={cx("medicaid-lead-preview__attribute-heading")}>
              Total Retirement Assets:
            </span>
            <br />
            <span className={cx("medicaid-lead-preview__attribute-value")}>
              {currencyFormatter.format(medicaidLeadPreview.assets.retirement)}
            </span>
          </li>
          <li>
            <span className={cx("medicaid-lead-preview__attribute-heading")}>
              Total Assets:
            </span>
            <br />
            <span className={cx("medicaid-lead-preview__attribute-value")}>
              {currencyFormatter.format(medicaidLeadPreview.assets.other)}
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default MedicaidLeadPreviewCard;
