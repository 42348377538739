import React, { useContext, useState } from "react";
import { User } from "../../modules/auth/User";
import UserProfileForm from "../../modules/userProfile/components/UserProfileForm";
import Alert, { AlertType } from "../../components/Alert";

import styles from "./ProfileScreen.module.scss";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EventName,
  SignupEngagementAction,
} from "contexts/DataLayerContext/types";

type ProfileScreenProps = {
  incompleteProfile: boolean;
  onCompleteRegistration: (user: User) => any;
  onUpdateProfile: (user: User) => any;
  selectedState: string | null;
  user: User | null;
};

const ProfileScreen = ({
  incompleteProfile,
  onCompleteRegistration,
  onUpdateProfile,
  selectedState,
  user,
}: ProfileScreenProps) => {
  const [hasSentViewEvent, setHasSentViewEvent] = useState(false);
  const { sendEvent } = useContext(DataLayerContext);

  if (incompleteProfile && !hasSentViewEvent && sendEvent !== undefined) {
    sendEvent({
      event: EventName.SignupEngagement,
      action: SignupEngagementAction.SIGNUP_PROFILE_VIEW,
      user_ID: user?.uid,
      user_State: selectedState || undefined,
    });
    setHasSentViewEvent(true);
  }

  const saveDetailsHandler = incompleteProfile
    ? onCompleteRegistration
    : onUpdateProfile;

  return (
    <div>
      {incompleteProfile && (
        <div className={styles.ProfileScreen__banner}>
          <Alert
            message="Please, complete your profile to proceed"
            type={AlertType.ERROR}
          />
        </div>
      )}
      <UserProfileForm
        user={user}
        onSaveUserDetails={saveDetailsHandler}
        selectedState={selectedState}
      />
    </div>
  );
};

export default ProfileScreen;
