import { AnswersMap, AnswerValueType } from "modules/workflow/types/Answer";
import { PartyID } from "./Party";

export enum PerPartyAnswerType {
  FRACTIONS = "FractionPairs",
  PARTIES = "SelectRelatedParty",
  PERCENTAGE = "NumberPairs",
  QUESTIONNAIRE = "PerPartyQuestionnaire",
  VALUES = "ValuePairs",
}

export enum PartyOrClient {
  PARTY = "Party",
  CLIENT = "Client",
}

export type SelectedParty = {
  type: PartyOrClient;
  id: string;
};

export type PerPartyRelationshipAnswer = {
  type: PerPartyAnswerType.PARTIES;
  values: SelectedParty[];
};

export type PerPartyValuePairsAnswer = {
  type: PerPartyAnswerType.VALUES;
  values: Record<PartyID, AnswerValueType>;
};

export type PerPartyQuestionnaireAnswer = {
  type: PerPartyAnswerType.QUESTIONNAIRE;
  values: Record<PartyID, AnswersMap | undefined>;
  imported?: boolean;
};

export type PerPartyPercentageAnswer = {
  type: PerPartyAnswerType.PERCENTAGE;
  values: Record<PartyID, number>;
};

export type PerPartyFractionsAnswer = {
  type: PerPartyAnswerType.FRACTIONS;
  values: Record<PartyID, [number, number]>;
};

export type PerPartySharesAnswer =
  | PerPartyPercentageAnswer
  | PerPartyFractionsAnswer;

type PerPartyAnswer =
  | PerPartyRelationshipAnswer
  | PerPartyValuePairsAnswer
  | PerPartyPercentageAnswer
  | PerPartyFractionsAnswer
  | PerPartyQuestionnaireAnswer;

export default PerPartyAnswer;
