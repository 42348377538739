import React, { useMemo } from "react";
import { AssetProtectionPlanImmediateProtectionProps } from "./types";
import {
  getTotalAssetsAtRisk,
  getTotalProtectedOnceQualified,
} from "screens/medicaidAnalysis/helpers";
import ProtectedAssetsSlider from "components/ProtectedAssetsSlider";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const AssetProtectionPlanImmediateProtection = ({
  calculation,
  className,
}: AssetProtectionPlanImmediateProtectionProps) => {
  const totalAssetsAtRisk = useMemo(
    () => getTotalAssetsAtRisk(calculation),
    [calculation],
  );

  const totalProtectedOnceQualified = useMemo(
    () => getTotalProtectedOnceQualified(calculation),
    [calculation],
  );

  return (
    <div className={className}>
      <div className={cx("slider-container")}>
        <ProtectedAssetsSlider
          className={cx("slider")}
          totalAmount={totalAssetsAtRisk}
          protectedAmount={totalProtectedOnceQualified}
          title="Protected Assets With a Plan"
          progressTransition
        />
      </div>
    </div>
  );
};

export default AssetProtectionPlanImmediateProtection;
