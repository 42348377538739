import Questionnaire, {
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";

export default (
  workflowQuestionnaires?: Questionnaire[],
): QuestionsMapByQuestionnaire =>
  (workflowQuestionnaires || []).reduce((result, questionnaire) => {
    return {
      ...result,
      [questionnaire.id]: questionnaire.questions,
    };
  }, {} as QuestionsMapByQuestionnaire);
