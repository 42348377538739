import {
  WorkflowAction,
  WorkflowActionType,
  WorkflowStep,
} from "modules/api/types/WorkflowPayload";
import * as React from "react";

import ClientDashboardProductsItemHistoryItem from "./ClientDashboardProductsItemHistoryItem";

import styles from "./styles.module.scss";

type ClientDashboardProductsItemHistoryProps = {
  history: WorkflowStep[];
};

const whitelistedActions: Record<WorkflowActionType, string | undefined> = {
  [WorkflowAction.Initialize]: "Initialized",
  [WorkflowAction.CreateClient]: undefined,
  [WorkflowAction.UpdateClient]: undefined,
  [WorkflowAction.DeleteClient]: undefined,
  [WorkflowAction.ConfirmAgreement]: undefined,
  [WorkflowAction.CreateSpouse]: undefined,
  [WorkflowAction.UpdateSpouse]: undefined,
  [WorkflowAction.DeleteSpouse]: undefined,
  [WorkflowAction.CreateChild]: undefined,
  [WorkflowAction.UpdateChild]: undefined,
  [WorkflowAction.CreateParty]: undefined,
  [WorkflowAction.UpdateParty]: undefined,
  [WorkflowAction.DeleteRelationship]: undefined,
  [WorkflowAction.SubmitAnswers]: undefined,
  [WorkflowAction.ConfirmSummary]: "Interview Completed",
  [WorkflowAction.InitialisePayment]: undefined,
  [WorkflowAction.InitializeCoupled]: "Coupled Workflow Created",
  [WorkflowAction.SetSupervisedSigning]: undefined,
  [WorkflowAction.SetAddons]: "Addons updated",
  [WorkflowAction.SubmitPayment]: undefined,
  [WorkflowAction.ConfirmPayment]: "Payment Sent",
  [WorkflowAction.FailPayment]: "Payment Failed",
  [WorkflowAction.CancelPayment]: "Payment Canceled",
  [WorkflowAction.SkipPayment]: "Payment Skipped",
  [WorkflowAction.AcceptEngagementAgreement]: "Engagement Agreement Accepted",
  [WorkflowAction.RejectEngagementAgreement]: "Engagement Agreement Rejected",
  [WorkflowAction.StartReview]: undefined,
  [WorkflowAction.FinishReview]: "Reviewed by lawyer",
  [WorkflowAction.ConfirmReview]: "Review accepted",
  [WorkflowAction.RejectReview]: "Review rejected",
  [WorkflowAction.CustomerContacted]: "Client contacted by lawyer",
  [WorkflowAction.RenderDocuments]: "Documents Requested",
  [WorkflowAction.ConfirmRenderDocuments]: "Documents Rendered",
  [WorkflowAction.FailRenderDocuments]: "Document Error",
  [WorkflowAction.DownloadDocuments]: undefined,
  [WorkflowAction.Finalize]: "Finalized",
};

const ClientDashboardProductsItemHistory: React.FunctionComponent<
  ClientDashboardProductsItemHistoryProps
> = ({ history }) => {
  const historySteps = history
    .map((stepData) =>
      whitelistedActions[stepData.action]
        ? {
            action: whitelistedActions[stepData.action]!,
            timestamp: stepData.timestamp,
          }
        : undefined,
    )
    .filter(Boolean) as Array<{ action: string; timestamp: string }>;

  return (
    <div className={styles["client-dashboard-products-item-history"]}>
      <ul className={styles["client-dashboard-products-item-history__list"]}>
        <li className={styles["client-dashboard-products-item-history__item"]}>
          <div
            className={
              styles["client-dashboard-products-item-history__item-col"]
            }
          >
            <div
              className={
                styles["client-dashboard-products-item-history__item-title"]
              }
            >
              Date
            </div>
          </div>
          <div
            className={
              styles["client-dashboard-products-item-history__item-col"]
            }
          >
            <div
              className={
                styles["client-dashboard-products-item-history__item-title"]
              }
            >
              Action
            </div>
          </div>
        </li>
        {historySteps.map(({ action, timestamp }, idx) => (
          <ClientDashboardProductsItemHistoryItem
            key={`${timestamp}_${action}_${idx}`}
            timestamp={timestamp}
            action={action}
          />
        ))}
      </ul>
    </div>
  );
};

export default ClientDashboardProductsItemHistory;
