import { RenderedDocumentStatus } from "modules/api/types/WorkflowDetailsPayload";
import Client from "modules/parties/types/Client";
import { PartnerID } from "modules/parties/types/Partner";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import Questionnaire, {
  QuestionsMap,
} from "modules/workflow/types/Questionnaire";
import { ReactNode } from "react";

export enum MedicaidLeadStatus {
  InterviewInProgress = "InterviewInProgress",
  ReadyForPartner = "ReadyForPartner",
  LeadAccepted = "LeadAccepted",
  LeadRejected = "LeadRejected",
}

export type MedicaidLead = {
  medicaidLeadId: string;
  userId: string;
  partnerId?: PartnerID;
  status: MedicaidLeadStatus;
  pageIdx: number;
  archived: boolean;
  jurisdiction: string;
  updated: string;
  created: string;
};

export type MedicaidLeadAssetsPreview = {
  realProperty: number;
  retirement: number;
  other: number;
};

export type MedicaidLeadPreview = {
  maritalStatus: MedicaidCalculationType;
  csAge?: number;
  isAge: number;
  protectableAmount: number;
  assets: MedicaidLeadAssetsPreview;
  status: MedicaidLeadStatus;
  updated: string;
};

export type MedicaidLeadPreviewWithId = MedicaidLeadPreview & {
  medicaidLeadId: string;
};

export enum MedicaidLeadPreviewStatus {
  ACCEPTED = "Accepted",
  PENDING = "Pending",
}

export type SimpleMedicaidCalculation = {
  totalAssets: EnrichedMedicaidLead;
  protectableAmount: number;
};

export type EnrichedMedicaidLeadWithSimpleCalculation = {
  enriched: EnrichedMedicaidLead;
  calculation: SimpleMedicaidCalculation;
};

export type AcceptedMedicaidLeadPreview = {
  previewStatus: MedicaidLeadPreviewStatus.ACCEPTED;
  preview: EnrichedMedicaidLeadWithSimpleCalculation;
};

export type PendingMedicaidLeadPreview = {
  previewStatus: MedicaidLeadPreviewStatus.PENDING;
  preview: MedicaidLeadPreviewWithId;
};

export type MedicaidLeadReviewWithPreviewStatus =
  | AcceptedMedicaidLeadPreview
  | PendingMedicaidLeadPreview;

export type MedicaidLeadPreviews = Record<
  string,
  MedicaidLeadReviewWithPreviewStatus
>;

export type EnrichedMedicaidLead = {
  medicaidLead: MedicaidLead;
  questionnaire: Questionnaire;
  questions: QuestionsMap;
  answers: AnswersMap;
  clients: Record<string, Client>;
};

export type MedicaidLeadContextProps = {
  children: ReactNode;
};

export type CreateMedicaidLeadResponse = EnrichedMedicaidLead;

export type FetchMedicaidLeadResponse = EnrichedMedicaidLead;

export type SaveMedicaidLeadAnswersResponse = {
  validatedAnswers: AnswersMap;
};

export type SaveMedicaidLeadClientResponse = {
  client: Client;
};

export type CreateMedicaidLeadClientResponse = {
  client: Client;
};

export type UpdateMedicaidLeadPageResponse = {
  pageIdx: number;
};

export enum MedicaidCalculationType {
  SINGLE = "Single",
  MARRIED = "Married",
}

export type MedicaidCalculationSingleAnswers = {
  totalIncome: number;
  exemptIncome: number;
  excessIncome: number;
  totalAssets: number;
  totalExemptAssets: number;
  totalSpendDown: number;
  totalCountableAssets: number;
  totalExcessAssets: number;
  monthlyDeficit: number;
  amountNeededPerMonth: number;
  minimumMonthsToQualify: number;
  excessAssetsProtected: number;
  assetsAtRiskUntilBreakEven: number;
  timeUntilAssetsBeginBeingProtected: number;
  totalNotSpentOnNHCare: number;
  percentSaved: number;
};

export type MedicaidCalculationSingleWorkings = {
  housingExemption: number;
  lifeInsuranceExemption: number;
  institutionalisedSpouse: Client;
  totalRealPropertyAssets: number;
  totalRetirementAssets: number;
};

export type MedicaidCalculationSingle = {
  calculationType: MedicaidCalculationType.SINGLE;
  answers: MedicaidCalculationSingleAnswers;
  workings: MedicaidCalculationSingleWorkings;
};

export type MedicaidCalculationMarriedAnswers = {
  totalIncome: number;
  exemptIncome: number;
  excessIncome: number;
  totalAssets: number;
  totalExemptAssets: number;
  totalCountableAssets: number;
  totalSpendDown: number;
  totalExcessAssets: number;
  monthlyDeficit: number;
  csSpouseIncomeAllowanceAvailable: number;
  netMonthlyDeficit: number;
  amountNeededPerMonth: number;
  minimumMonthsToQualify: number;
  excessAssetsProtected: number;
  assetsAtRiskUntilBreakEven: number;
  timeUntilAssetsBeginBeingProtected: number;
  totalNotSpentOnNHCare: number;
  percentSaved: number;
};

export type MedicaidCalculationMarriedWorkings = {
  housingExemption: number;
  lifeInsuranceExemption: number;
  csraExemption: number;
  institutionalisedSpouse: Client;
  communitySpouse: Client;
  totalRealPropertyAssets: number;
  totalRetirementAssets: number;
};

export type UnrenderedMedicaidLeadDocument = {
  medicaidLeadId: string;
  documentDefinitionId: string;
  status: RenderedDocumentStatus.PENDING | RenderedDocumentStatus.FAILURE;
  displayName: string;
  updated: string;
  created: string;
};

export type RenderedMedicaidLeadDocument = {
  medicaidLeadId: string;
  documentDefinitionId: string;
  status: RenderedDocumentStatus.FINISHED;
  fileName: string;
  displayName: string;
  bucket: string;
  signedUrl: string;
  validSince: string;
  validUntil: string;
  expiresInSeconds: number;
  updated: string;
  created: string;
};

export type MedicaidLeadDocumentWithOptionalSignedUrl =
  | UnrenderedMedicaidLeadDocument
  | RenderedMedicaidLeadDocument;

export type MedicaidCalculationMarried = {
  calculationType: MedicaidCalculationType.MARRIED;
  answers: MedicaidCalculationMarriedAnswers;
  workings: MedicaidCalculationMarriedWorkings;
};

export type MedicaidCalculation =
  | MedicaidCalculationSingle
  | MedicaidCalculationMarried;

export type MedicaidAnalysis = {
  calculationError?: boolean;
  calculation?: MedicaidCalculation;
  medicaidLeadId?: string;
};

export type GetMedicaidLeadCalculationResponse = MedicaidCalculation;

export type UpdateMedicaidLeadPartnerResponse = MedicaidLead;

export type FetchMedicaidLeadPreviewsResponse = MedicaidLeadPreviews;

export type UpdateMedicaidLeadStatusResponse = MedicaidLead;

export type FetchMedicaidLeadsResponse = Record<string, EnrichedMedicaidLead>;

export type RenderMedicaidDocumentsResponse = {
  documents: MedicaidLeadDocumentWithOptionalSignedUrl[];
};

export type GetMedicaidLeadDocumentsResponse = {
  documents: MedicaidLeadDocumentWithOptionalSignedUrl[];
};

export type SubmitMedicaidLeadReviewResponse = {
  medicaidLeadPreviews: MedicaidLeadPreviews;
  enrichedMedicaidLead?: EnrichedMedicaidLead;
  medicaidCalculation?: MedicaidCalculation;
};

export type AdminMedicaidData = {
  leadPreviews: MedicaidLeadPreviews;
  currentLead?: EnrichedMedicaidLead;
  currentCalculation?: MedicaidCalculation;
  documents?: MedicaidLeadDocumentWithOptionalSignedUrl[];
};

export type MedicaidData = {
  lead?: MedicaidLead;
  availableLeads?: Record<string, EnrichedMedicaidLead>;
  analysis?: MedicaidAnalysis;
  questionnaire?: Questionnaire;
  questions?: QuestionsMap;
  answers?: AnswersMap;
  unsavedAnswers?: AnswersByQuestionnaireType;
  clients?: Record<string, Client>;
  admin?: AdminMedicaidData;
  isLoadingMedicaidLead: boolean;
  isLoadingMedicaidLeads: boolean;
  isLoadingMedicaidAnalysis: boolean;
  isLoadingMedicaidLeadPreviews: boolean;
  isLoadingAdminMedicaidLeadData: boolean;
  isRenderingMedicaidLeadDocuments: boolean;
};

export enum MedicaidLeadReviewVerdict {
  ACCEPTED = "Accepted",
  REJECTED = "Rejected",
}
