import * as React from "react";
import QuestionAutocomplete, {
  QuestionAutocompleteProps,
} from "components/Forms/Questions/QuestionAutocomplete";
import { STATES_OPTIONS } from "resources/options/states";
import { sortBy } from "lodash";

const sortedOptions = sortBy(STATES_OPTIONS, "label");

type QuestionStateProps = Omit<QuestionAutocompleteProps, "options">;

const QuestionState: React.FunctionComponent<QuestionStateProps> = (props) => {
  return <QuestionAutocomplete options={sortedOptions} {...props} />;
};

export default QuestionState;
