import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import WorkflowDetailsScreen from "./WorkflowDetailsScreen";
import { RootState, useSelector } from "redux/reducer";
import {
  changePage,
  cloneWorkflow,
  confirmClientContacted,
  exitWorkflow,
  finalizeQuestionnaire,
  interviewAnswersChanged,
  saveAnswers,
  setWorkflowAddons,
} from "../../modules/workflow/actions";
import Client, { ClientType } from "modules/parties/types/Client";
import {
  cancelAddParty,
  cancelEditParty,
  deleteChild,
  saveClient,
  triggerAddChild,
  triggerAddGrandchild,
  triggerAddParty,
  triggerEditChild,
  triggerEditParty,
  updateChild,
} from "modules/parties/actions";
import { addressLookup, addressSearch } from "modules/address/actions";
import reportValidationErrors from "modules/workflow/helpers/reportValidationErrors";
import parseParams, { parseInteger } from "utils/parseUrlParams";
import Party, {
  getFirstNamePossessive,
  PartyToSave,
  RelationshipType,
} from "modules/parties/types/Party";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import Address from "modules/parties/types/Address";
import collectAllAddresses from "modules/workflow/helpers/collectAllAddresses";
import { getWorkflowRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { Step } from "modules/api/types/WorkflowPayload";
import { ModalControls, ModalType, showModal } from "components/Modal";
import PartyPickerModalContent from "modules/parties/components/PartyPickerModalContent";
import { go } from "modules/navigation/helpers/navigator";
import { getAnnotatedAdvisorAnswers } from "modules/workflow/helpers/annotationsHelper";
import LoadingOverlay from "components/LoadingOverlay";
import { isEditable, isPaid } from "modules/workflow/helpers/lifecycle";
import { rejectLawyerReview } from "modules/workflow/review/actions";
import logger from "logger";
import { NotificationType } from "components/Notification";
import {
  cancelDocumentsLoading,
  loadDocuments,
} from "modules/workflow/documents/actions";
import { WorkflowDocumentType } from "modules/api/types/WorkflowDetailsPayload";
import { initPayment } from "modules/payment/actions";
import { PaymentActionType } from "modules/api/types/Payment";
import { useWorkflowDetails, WorkflowDetailsProvider } from "./context";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EventName,
  WorkflowInteraction,
} from "contexts/DataLayerContext/types";
import {
  clientContactDetailsPageDefinition,
  clientDetailsPageDefinition,
} from "modules/parties/helpers/getClientQuestionnaire/constants";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";

const isClientTouched = (
  clientDetails: Partial<Client>,
  isCoupledWorkflow: boolean,
) => {
  const { isMarried, ...propsWithoutMarried } = clientDetails;
  const setValues = Object.values(
    isCoupledWorkflow ? propsWithoutMarried : clientDetails,
  ).filter((val) => val !== undefined);
  return setValues.length > 0;
};

const WorkflowScreenContainerContent: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { asUser, coupledWorkflow, isAdmin, workflow } = useWorkflowDetails();
  const {
    availableWorkflows,
    isLoadingProducts,
    isLoadingRenderedDocuments,
    partner,
    unsavedInterviewAnswers,
    user,
    validationErrors: allValidationErrors,
    workflows,
  } = useSelector((state: RootState) => state, shallowEqual);
  const { id } = useParams() as Record<string, string>;
  const location = useLocation();
  const { sendEvent } = useContext(DataLayerContext);
  const [previousPageId, setPreviousPageId] = useState<string | null>(null);
  const validationErrors = workflow && allValidationErrors?.[workflow.id];

  const urlParams = parseParams(location.hash);
  const pageParam = parseInteger(urlParams.page);
  const requestedPageIdx = pageParam === null ? 0 : pageParam - 1;
  const requestedPageId = urlParams.pageId;

  const editable = isEditable(
    isAdmin,
    workflow?.state.steps,
    workflow?.creator,
  );
  const clients = workflow?.contacts?.clients;
  const client: Client | undefined = clients?.[ClientType.MAIN];

  const availableAddresses: Address[] = collectAllAddresses(workflows, id);

  useEffect(() => {
    if (
      requestedPageId === clientContactDetailsPageDefinition.id ||
      requestedPageId === clientDetailsPageDefinition.id ||
      requestedPageId === SUMMARY_PAGE_ID
    ) {
      if (previousPageId !== requestedPageId) {
        sendEvent?.({
          event: EventName.Workflow,
          action: WorkflowInteraction.Form,
          step: {
            [clientContactDetailsPageDefinition.id]:
              clientContactDetailsPageDefinition.caption,
            [clientDetailsPageDefinition.id]:
              clientDetailsPageDefinition.caption,
            [SUMMARY_PAGE_ID]: "Summary",
          }[requestedPageId],
          user_ID: workflow?.userId,
          workflow_id: workflow?.id,
        });
        setPreviousPageId(requestedPageId);
      }
    } else {
      const pageHeader = workflow?.questionnaires
        ?.flatMap((questionnaire) => questionnaire.pages)
        .find((page) => page.id === requestedPageId)?.caption;

      if (pageHeader !== undefined && previousPageId !== requestedPageId) {
        sendEvent?.({
          event: EventName.Workflow,
          action: WorkflowInteraction.Form,
          step: pageHeader,
          user_ID: workflow?.userId,
          workflow_id: workflow?.id,
        });
        setPreviousPageId(requestedPageId);
      }
    }
  }, [
    requestedPageId,
    workflow?.questionnaires,
    location?.hash,
    sendEvent,
    workflow?.userId,
    workflow?.id,
    previousPageId,
  ]);

  const handleSavePageAnswers = async (
    questionnaireId: string,
    answers: AnswersMap,
    sourcePageId?: string,
  ): Promise<void> => {
    if (editable && Object.keys(answers).length > 0) {
      await saveAnswers(
        workflow.id!,
        questionnaireId,
        answers,
        asUser,
        sourcePageId,
      )(dispatch);
    }
  };

  const handleSaveClient = (
    clientType: ClientType,
    clientId: string | undefined,
    client: Partial<Client>,
    isDelta?: boolean,
  ): Promise<void> => {
    return editable &&
      (!isDelta ||
        isClientTouched(client, Boolean(workflow.state.coupledWorkflowId)))
      ? saveClient(workflow.id!, clientId, clientType, client, asUser)(dispatch)
      : Promise.resolve();
  };

  const handleValidationErrors = (errors: object, answers: AnswersMap) =>
    reportValidationErrors(errors, answers);

  const handleRenderDocuments = (documentType?: WorkflowDocumentType) =>
    dispatch(loadDocuments(workflow.id, asUser, documentType));

  const handleExit = () => dispatch(exitWorkflow());

  const handleDeleteChild = (child: Party) =>
    editable
      ? deleteChild(workflow.id, client?.clientId, child, asUser)(dispatch)
      : Promise.reject("The form has been finalized");

  const handleCloneWorkflow = () => cloneWorkflow(workflow, asUser)(dispatch);

  const handleClientContacted = () =>
    confirmClientContacted(workflow.id, asUser)(dispatch);

  const handleChangeQuestionnairePage = (
    pageIdx: number,
    pageId?: string,
    pageTitle?: string,
    initialLoad?: boolean,
  ) => dispatch(changePage({ pageIdx, pageId, pageTitle, initialLoad }));

  const handleTriggerAddParty = () =>
    editable
      ? triggerAddParty(
          clients,
          workflow.id,
          availableAddresses,
          asUser,
        )(dispatch)
      : Promise.reject("The form has been finalized");

  const handleTriggerAddChild = (currentAnswers: AnswersByQuestionnaireType) =>
    editable &&
    triggerAddChild(
      clients,
      workflow.id,
      availableAddresses,
      asUser,
      currentAnswers,
    )(dispatch);

  const handleTriggerAddGrandchild = (parentId: string) =>
    editable &&
    triggerAddGrandchild(
      clients,
      workflow.id,
      parentId,
      availableAddresses,
      asUser,
    )(dispatch);

  const handleTriggerEditParty = (
    party: Party,
    currentAnswers?: AnswersByQuestionnaireType,
  ): Promise<Party | undefined> =>
    editable
      ? triggerEditParty(
          clients,
          workflow.id,
          party,
          availableAddresses,
          asUser,
          currentAnswers,
        )(dispatch)
      : Promise.resolve(undefined);

  const importGrandchild = async (
    grandchild: Party,
  ): Promise<Party | undefined> => {
    const changes: Partial<PartyToSave> = {
      ...grandchild,
      relationshipToSave: { type: RelationshipType.GRANDCHILD },
    };
    return updateChild(
      workflow.id,
      client?.clientId,
      grandchild,
      changes,
      asUser,
    )(dispatch);
  };

  const handleTriggerImportChild = async (
    child: Party,
    triggeredByGrandchild?: Party,
  ): Promise<Party | undefined> => {
    if (!isEditable || !client) return;
    if (child.parentId && !triggeredByGrandchild) {
      return importGrandchild(child);
    }

    const result = await importChildWithGrandchildren(
      child,
      triggeredByGrandchild
        ? `Edit ${getFirstNamePossessive(
            triggeredByGrandchild,
            "Grandchild",
          )} parent`
        : undefined,
    );
    return result?.[0];
  };

  const handleCancelAddParty = () => dispatch(cancelAddParty());

  const handleCancelEditParty = () => dispatch(cancelEditParty());

  const handleAddressLookup = (addressId: string) =>
    addressLookup(addressId)(dispatch);

  const handleAddressSearch = (containerId?: string, searchTerm?: string) =>
    addressSearch(containerId, searchTerm)(dispatch);

  const handleConfirmQuestionnaireSummary = async () => {
    user &&
      editable &&
      [Step.QuestionnaireInProgress, Step.CustomerChangesDuringReview].includes(
        workflow.state.steps.current.step,
      ) &&
      (await finalizeQuestionnaire(workflow.id, asUser)(dispatch));
    return;
  };

  const handleRejectLawyerReview = async () => {
    user &&
      workflow.state.steps.current.step ===
        Step.AwaitingCustomerConfirmReview &&
      (await rejectLawyerReview(workflow.id, asUser)(dispatch));
    return;
  };

  const workflowOwnerId = workflow?.userId;
  const handleChangeAddons = useCallback(
    async (selectedAddons: string[]) => {
      try {
        isAdmin &&
          (await setWorkflowAddons(
            id,
            workflowOwnerId,
            selectedAddons,
          )(dispatch));
      } catch (e) {
        logger.notify(
          NotificationType.ERROR,
          "Addons couldn't be saved. Please, try again.",
        );
        throw e;
      }
    },
    [isAdmin, dispatch, id, workflowOwnerId],
  );

  const handleOpenPeopleRegistry = () =>
    go(dispatch, getWorkflowRoute(id, ROUTE_TEMPLATE.WORKFLOW_PARTIES), {
      from: `${location.pathname}${location.hash}`,
    });

  const importChildWithGrandchildren = (
    child: Party,
    title?: string,
  ): Promise<Party[]> =>
    triggerEditChild(
      dispatch,
      clients,
      workflow.id,
      child,
      availableAddresses,
      asUser,
      undefined,
      title,
    )
      .then(() => Promise.all((child.children || []).map(importGrandchild)))
      .then((result) => result.filter(Boolean) as Party[]);

  const handleImportChild = (children: Party[]) => {
    showModal({
      title: "Select a child to import",
      renderContent: (modalControls: ModalControls) => {
        return client ? (
          <PartyPickerModalContent
            parties={children}
            onSelect={(child: Party) => {
              modalControls.close();
              importChildWithGrandchildren(child);
            }}
          />
        ) : null;
      },
      type: ModalType.NOTIFICATION,
    });
  };

  const handleInterviewAnswersChange = (answers: AnswersByQuestionnaireType) =>
    dispatch(interviewAnswersChanged(answers));

  const handleTriggerPayment = useCallback(
    async (paymentType: PaymentActionType) => {
      await initPayment(
        workflow,
        undefined,
        paymentType === PaymentActionType.RepeatedPayment,
      )(dispatch); // TODO coupon?
      go(
        dispatch,
        getWorkflowRoute(workflow.id, ROUTE_TEMPLATE.WORKFLOW_PAYMENT),
      );
    },
    [dispatch, workflow],
  );

  const annotatedAdvisorAnswers =
    workflow && availableWorkflows
      ? getAnnotatedAdvisorAnswers(
          workflow,
          availableWorkflows,
          partner || undefined,
        )
      : undefined;

  const isAwaitingReview =
    isAdmin && workflow.state.steps.current.step === Step.AwaitingLawyerReview;

  // Trigger payment for workflows without an interview
  const shouldTriggerPayment =
    workflow.state.noInterview && !isPaid(workflow.state.steps);
  useEffect(() => {
    if (shouldTriggerPayment) {
      handleTriggerPayment(PaymentActionType.OnlineProductPayment);
    }
  }, [handleTriggerPayment, shouldTriggerPayment]);

  return isAwaitingReview || isLoadingProducts || shouldTriggerPayment ? (
    <LoadingOverlay />
  ) : (
    <WorkflowDetailsScreen
      allAvailableAddresses={availableAddresses}
      annotatedAdvisorAnswers={annotatedAdvisorAnswers}
      cancelAddParty={handleCancelAddParty}
      cancelEditParty={handleCancelEditParty}
      clients={workflow?.contacts?.clients}
      coupledWorkflow={coupledWorkflow}
      isClientEditable={editable}
      isEditable={editable}
      isLoadingRenderedDocuments={isLoadingRenderedDocuments}
      onAddressLookup={handleAddressLookup}
      onAddressSearch={handleAddressSearch}
      onChangeAddons={handleChangeAddons}
      onChangeQuestionnairePage={handleChangeQuestionnairePage}
      onClientContacted={handleClientContacted}
      onCloneWorkflow={handleCloneWorkflow}
      onConfirmSummary={handleConfirmQuestionnaireSummary}
      onDeleteChild={handleDeleteChild}
      onExit={handleExit}
      onImportChild={handleImportChild}
      onInterviewAnswersChange={handleInterviewAnswersChange}
      onOpenPeopleRegistry={handleOpenPeopleRegistry}
      onRejectLawyerReview={handleRejectLawyerReview}
      onRenderDocuments={handleRenderDocuments}
      onCancelRender={cancelDocumentsLoading}
      onSaveClientDetails={handleSaveClient}
      onSavePageAnswers={handleSavePageAnswers}
      onTriggerPayment={handleTriggerPayment}
      onValidationErrors={handleValidationErrors}
      pageId={requestedPageId || undefined}
      pageIdx={requestedPageIdx || undefined}
      triggerAddChild={handleTriggerAddChild}
      triggerAddGrandchild={handleTriggerAddGrandchild}
      triggerImportChild={handleTriggerImportChild}
      triggerAddParty={handleTriggerAddParty}
      triggerEditParty={handleTriggerEditParty}
      unsavedInterviewAnswers={unsavedInterviewAnswers}
      user={user}
      validationErrors={validationErrors || undefined}
    />
  );
};

const WorkflowScreenContainer: React.FC = () => (
  <WorkflowDetailsProvider>
    <WorkflowScreenContainerContent />
  </WorkflowDetailsProvider>
);

export default WorkflowScreenContainer;
