import { SelectMode } from ".";
import FieldState, {
  isSelectMultipleFieldState,
  OTHER_VALUE,
  SelectMultipleOrOtherState,
  SelectOneOrOtherState,
} from "./FieldState";

const getSelectMultipleValue = ({
  value: selectedValues,
  other,
}: SelectMultipleOrOtherState): string[] | null => {
  const values = [...selectedValues];

  if (values.includes(OTHER_VALUE)) {
    values.splice(values.indexOf(OTHER_VALUE), 1, other || "");
  }
  return values.length ? values : null;
};

const getSelectOneValue = ({ value, other }: SelectOneOrOtherState) =>
  value === OTHER_VALUE ? other || "" : value;

export default (
  fieldState: FieldState | undefined,
  mode: SelectMode,
): string | string[] | undefined | null => {
  if (!fieldState) return null;
  return isSelectMultipleFieldState(fieldState, mode)
    ? getSelectMultipleValue(fieldState)
    : getSelectOneValue(fieldState);
};
