import * as React from "react";
import RadioButton from "./RadioButton";

import questionStyles from "components/Forms/Questions/QuestionWrapper/styles.module.scss";

type RadioGroupProps = {
  disabled?: boolean;
  id?: string;
  isInvalid?: boolean;
  onChange: (selectedValue: string | null) => void;
  options: Array<{
    label: string;
    value: string;
    icon?: React.ReactComponentElement<any>;
  }>;
  selectedValue?: string;
  stacked?: boolean;
  tabIndex?: number;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  disabled,
  id,
  isInvalid,
  onChange,
  options,
  selectedValue,
  tabIndex,
}) => {
  const toggleValue = (value: string) => {
    onChange(selectedValue === value ? null : value);
  };

  return (
    <div className={questionStyles["question__inner-buttons"]}>
      {options.map((option, idx) => (
        <RadioButton
          disabled={disabled}
          icon={option.icon}
          id={idx === 0 ? id : ""}
          invalid={isInvalid}
          key={option.value}
          label={option.label}
          onClick={() => toggleValue(option.value)}
          selected={selectedValue === option.value}
          tabIndex={tabIndex === undefined ? 0 : tabIndex}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
