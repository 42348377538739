import { IconType } from "components/Icons";

export type PackageItem = {
  icon: IconType;
  title: string;
};

export const essentialsPackageItems: PackageItem[] = [
  {
    icon: IconType.ADD_WILL,
    title: "Last Will & Testament",
  },
  {
    icon: IconType.ADD_HEALTHCARE_DIRECTIVE,
    title: "Healthcare Directive",
  },
  {
    icon: IconType.ADD_POWER_OF_ATTORNEY,
    title: "Power of Attorney",
  },
];

export const powersPackageItems: PackageItem[] = [
  {
    icon: IconType.ADD_HEALTHCARE_DIRECTIVE,
    title: "Healthcare Directive",
  },
  {
    icon: IconType.ADD_POWER_OF_ATTORNEY,
    title: "Power of Attorney",
  },
];
