import BackLink from "components/BackLink";
import PageHeader from "components/PageHeader";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { updatePartnerRegistration } from "modules/admin/actions";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  PartnerRegistration,
  PartnerRegistrationRequest,
} from "modules/parties/types/Partner";
import PartnerRegistrationForm from "modules/partner/settings/components/PartnerRegistrationForm";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import logger from "logger";
import {
  showAcceptRegistrationModal,
  showRejectRegistrationModal,
} from "./registrationReviewModals";
import { NotificationType } from "components/Notification";

type Props = {
  registration?: PartnerRegistration;
};

const PartnerRequestsDetail: React.FC<Props> = ({ registration }) => {
  const dispatch = useDispatch();

  const handleBackLink = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.ADMIN_REGISTRATIONS),
    [dispatch],
  );

  const handleSaveChanges = useCallback(
    async (request: PartnerRegistrationRequest) => {
      try {
        registration &&
          (await updatePartnerRegistration(
            registration.id,
            registration.userId,
            request,
          )(dispatch));
        logger.notify(NotificationType.SUCCESS, "Your changes were saved.");
      } catch (e) {
        logger.notify(
          NotificationType.ERROR,
          "Your changes couldn't be saved. Please, try again later.",
          e,
        );
      }
    },
    [dispatch, registration],
  );

  const onReviewComplete = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.ADMIN_REGISTRATIONS),
    [dispatch],
  );

  const handleAcceptRegistration = useCallback(async () => {
    try {
      registration?.request &&
        showAcceptRegistrationModal(dispatch, registration, onReviewComplete);
    } catch (e) {
      logger.notify(
        NotificationType.ERROR,
        "Your review couldn't be saved. Please, try again later.",
        e,
      );
    }
  }, [dispatch, onReviewComplete, registration]);

  const handleRejectRegistration = useCallback(async () => {
    try {
      registration?.request &&
        showRejectRegistrationModal(dispatch, registration, onReviewComplete);
    } catch (e) {
      logger.notify(
        NotificationType.ERROR,
        "Your review couldn't be saved. Please, try again later.",
        e,
      );
    }
  }, [dispatch, onReviewComplete, registration]);

  if (!registration)
    return <Redirect to={getRoute(ROUTE_TEMPLATE.ADMIN_REGISTRATIONS)} />;

  return (
    <div>
      <BackLink onClick={handleBackLink} text="Back to Partner Registrations" />
      <PageHeader
        title={`${registration?.request.displayName} (registration)`}
      />

      <PartnerRegistrationForm
        hideUserAgreement
        onExit={handleBackLink}
        onSubmit={handleSaveChanges}
        registration={registration}
        submitButtonLabel="Save changes"
        customActions={[
          <TabbedButton
            key="btn_reject"
            style={TabbedButtonType.SECONDARY}
            mood={TabbedButtonMood.NEGATIVE}
            onClick={handleRejectRegistration}
          >
            Reject
          </TabbedButton>,
          <TabbedButton
            key="btn_accept"
            style={TabbedButtonType.PRIMARY}
            mood={TabbedButtonMood.POSITIVE}
            onClick={handleAcceptRegistration}
          >
            Accept
          </TabbedButton>,
        ]}
      />
    </div>
  );
};

export default PartnerRequestsDetail;
