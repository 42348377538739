import * as React from "react";

import {
  Question,
  QuestionDefinitionMultiple,
  QuestionsMapByQuestionnaire,
  QuestionType,
} from "modules/workflow/types/Questionnaire";
import {
  AnswersMap,
  AnswerValueType,
  AssetSplit,
  LifeInsurancePolicyValue,
  Multiple,
  OrOption,
} from "modules/workflow/types/Answer";
import Party, {
  GenericParty,
  getFullName,
  getGenericPartyId,
} from "modules/parties/types/Party";
import Address, { getFullAddress } from "modules/parties/types/Address";
import { Share } from "components/Forms/Questions/QuestionPerParty/QuestionShareDistribution";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import SummaryAnswer, { SummaryAnswerGroup } from "./SummaryAnswer";

import styles from "./styles.module.scss";
import { PartyValue } from "components/Forms/Questions/QuestionPerParty";
import currencyFormatter from "utils/currencyFormatter";
import { PerPartyQuestionnaireAnswer } from "modules/parties/types/PerPartyAnswer";
import { isAnswered } from "modules/workflow/helpers/getFilledQuestionsByQuestionnaire";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import interpolate from "utils/interpolate";

type SummarySectionProps = {
  allParties: Party[];
  allQuestions?: QuestionsMapByQuestionnaire;
  answers: AnswersMap;
  clients: ClientsMap | undefined;
  onEdit?: () => void;
  sectionQuestions: Question[];
  title?: string;
  maskTitle?: boolean;
};

const getQuestionLabel = (question: Question): string => {
  if (question.label) return question.label;
  if (question.data.descriptions.length > 0)
    return question.data.descriptions[0].label;
  return "";
};

const serializeAnswer = (
  question: Question,
  answer: AnswerValueType,
  relatedParties: Party[],
  clients: ClientsMap | undefined,
  allQuestions?: QuestionsMapByQuestionnaire,
): JSX.Element => {
  const label = getQuestionLabel(question);
  const allPartiesAndClients = [
    ...relatedParties,
    ...Object.values(clients || {}),
  ].filter(Boolean) as GenericParty[];

  switch (question.data.definition.type) {
    case QuestionType.SHARE_DISTRIBUTION: {
      return (
        <React.Fragment key={question.id}>
          {((answer || []) as Share[]).map((share) => {
            const party = allPartiesAndClients.find(
              (p) => getGenericPartyId(p) === share.partyId,
            );
            const fullName = party ? getFullName(party) : null;
            const isFraction = String(share.share).includes("/");

            return fullName ? (
              <SummaryAnswer
                key={`${question.id}_${share.partyId}`}
                label={`${fullName}'${fullName.endsWith("s") ? "" : "s"} share`}
                value={`${share.share}${isFraction ? "" : "%"}`}
              />
            ) : null;
          })}
        </React.Fragment>
      );
    }

    case QuestionType.PER_PARTY: {
      const subQuestionDefinition = question.data.definition.definition;
      return (
        <React.Fragment key={question.id}>
          {((answer || []) as PartyValue[]).map((value) => {
            const party = allPartiesAndClients.find(
              (p) => getGenericPartyId(p) === value.partyId,
            );
            const fullName = party ? getFullName(party) : null;
            return fullName ? (
              <SummaryAnswer
                key={`${question.id}_${value.partyId}`}
                label={fullName}
                value={serializeAnswer(
                  {
                    id: `${question.id}_meta`,
                    data: {
                      descriptions: [{ label: "" }],
                      definition: subQuestionDefinition,
                    },
                  },
                  value.value,
                  relatedParties,
                  clients,
                  allQuestions,
                )}
              />
            ) : null;
          })}
        </React.Fragment>
      );
    }

    case QuestionType.RELATIONSHIP: {
      const names = (answer as string[])
        .map((partyId) => {
          const party = allPartiesAndClients.find(
            (p) => getGenericPartyId(p) === partyId,
          );
          return party ? getFullName(party) : undefined;
        })
        .filter(Boolean) as string[];
      return <SummaryAnswer key={question.id} label={label} value={names} />;
    }

    case QuestionType.SUBQUESTIONNAIRE: {
      const subquestionnaire =
        question.data.definition.subquestionnaireData?.partySubquestionnaire;
      const processedSubquestionnaireQuestions =
        allQuestions?.[question.data.definition.questionnaireId] ||
        subquestionnaire?.questions ||
        {};
      const orderedQuestionsIDs = (
        subquestionnaire?.pages[0].sections[0].questions || []
      ).flat();

      return (
        <React.Fragment key={question.id}>
          <div className={styles["summary-page__section__group-label"]}>
            {label}
          </div>
          {(question.data.definition.parties || []).map((party) => {
            const partyId = getGenericPartyId(party);
            const fullName = getFullName(party);
            const partyAnswers =
              (answer as PerPartyQuestionnaireAnswer | undefined)?.values?.[
                partyId
              ] || {};

            return (
              <SummaryAnswerGroup
                key={`${question.id}_${partyId}`}
                label={fullName}
              >
                {orderedQuestionsIDs.map((subquestionId) => {
                  const subquestion =
                    processedSubquestionnaireQuestions[subquestionId];

                  return isAnswered(
                    partyAnswers[subquestionId],
                    processedSubquestionnaireQuestions[subquestionId]?.data
                      .definition,
                  )
                    ? serializeAnswer(
                        subquestion,
                        partyAnswers[subquestionId],
                        relatedParties,
                        clients,
                        allQuestions,
                      )
                    : null;
                })}
              </SummaryAnswerGroup>
            );
          })}
        </React.Fragment>
      );
    }

    case QuestionType.ADDON:
    case QuestionType.FLAG: {
      return (
        <SummaryAnswer
          key={question.id}
          label={label}
          value={answer !== null ? (answer ? "Yes" : "No") : null}
          clients={clients}
        />
      );
    }

    case QuestionType.CURRENCY: {
      return (
        <SummaryAnswer
          key={question.id}
          label={label}
          value={
            answer !== undefined
              ? currencyFormatter.format(answer as number)
              : undefined
          }
          clients={clients}
        />
      );
    }

    case QuestionType.GENDER: {
      return (
        <SummaryAnswer key={question.id} label={"Gender"} value={answer} />
      );
    }

    case QuestionType.SELECT: {
      const options = question.data.definition.values;
      const selectedValues = Array.isArray(answer)
        ? answer
        : answer !== undefined
          ? [answer]
          : [];
      const selectedAnswers = selectedValues.map((value) => {
        const selectedOption = options.find((option) => option.value === value);
        return selectedOption?.display !== undefined
          ? selectedOption?.display
          : value;
      });

      return (
        <SummaryAnswer
          key={question.id}
          label={label}
          value={selectedAnswers}
        />
      );
    }

    case QuestionType.ADDRESS: {
      return (
        <SummaryAnswer
          key={question.id}
          label={label}
          value={getFullAddress(answer as Address)}
        />
      );
    }

    case QuestionType.ASSET_SPLIT: {
      return (
        <React.Fragment key={question.id}>
          <SummaryAnswer
            key={`${question.id}_1`}
            label={question.data.definition.mainOwnedLabel}
            value={currencyFormatter.format((answer as AssetSplit).mainOwned)}
            clients={clients}
          />
          {clients?.main?.isMarried && (
            <>
              <SummaryAnswer
                key={`${question.id}_2`}
                label={question.data.definition.jointlyOwnedLabel}
                value={currencyFormatter.format(
                  (answer as AssetSplit).jointlyOwned || 0,
                )}
                clients={clients}
              />
              <SummaryAnswer
                key={`${question.id}_3`}
                label={question.data.definition.spouseOwnedLabel}
                value={currencyFormatter.format(
                  (answer as AssetSplit).spouseOwned || 0,
                )}
                clients={clients}
              />
            </>
          )}
        </React.Fragment>
      );
    }

    case QuestionType.LIFE_INSURANCE_POLICY_VALUE: {
      return (
        <React.Fragment key={question.id}>
          <SummaryAnswer
            key={question.id}
            label={question.label || "Life Insurance Policy:"}
            value={answer as LifeInsurancePolicyValue}
            clients={clients}
          />
        </React.Fragment>
      );
    }

    case QuestionType.MULTIPLE: {
      return (
        <React.Fragment key={question.id}>
          {(answer as Multiple)?.answers.map((answer, idx) =>
            serializeAnswer(
              {
                id: `${question.id}_${idx}`,
                label: idx === 0 ? question.label || "" : "",
                description: "",
                data: {
                  definition: (
                    question.data.definition as QuestionDefinitionMultiple
                  ).baseQuestionDefinition,
                  descriptions: [],
                },
              },
              answer,
              relatedParties,
              clients,
              allQuestions,
            ),
          )}
        </React.Fragment>
      );
    }

    case QuestionType.OR_OPTION: {
      return (
        <React.Fragment key={question.id}>
          {(answer as OrOption).isOptionSelected ? (
            <SummaryAnswer
              key={question.id}
              label={question.label || ""}
              value={question.data.definition.option}
              clients={clients}
            />
          ) : (
            serializeAnswer(
              {
                ...question,
                data: {
                  ...question.data,
                  definition: question.data.definition.questionDefinition,
                },
              },
              (answer as OrOption).question,
              relatedParties,
              clients,
              allQuestions,
            )
          )}
        </React.Fragment>
      );
    }

    default:
      return (
        <SummaryAnswer
          key={question.id}
          label={label}
          value={answer}
          clients={clients}
        />
      );
  }
};

const SummarySection: React.FC<SummarySectionProps> = ({
  allParties,
  allQuestions,
  answers,
  children,
  clients,
  onEdit,
  sectionQuestions,
  title,
  maskTitle,
}) => {
  const interpolatedSummarySectionTitle =
    title && clients?.main
      ? interpolate(title, clients.main, clients.spouse)
      : title;
  return (
    <div className={styles["summary-page__section"]}>
      {title && (
        <header>
          <h2 data-clarity-mask={maskTitle}>
            {interpolatedSummarySectionTitle}
          </h2>
          {onEdit && (
            <TabbedButton style={TabbedButtonType.SECONDARY} onClick={onEdit}>
              Edit
            </TabbedButton>
          )}
        </header>
      )}
      <div>
        {sectionQuestions.map((question) =>
          serializeAnswer(
            question,
            answers[question.id],
            allParties,
            clients,
            allQuestions,
          ),
        )}
      </div>
      {children}
    </div>
  );
};

export default SummarySection;
