import React, { useState, useEffect, useCallback } from "react";
import { FormikErrors, FormikProps } from "formik";
import { pick, isEqual, merge } from "lodash";

import Form, {
  FormProps,
  FormSubmitHandle,
} from "../../../../components/Forms/Form";
import PageHeader from "components/PageHeader";
import {
  QuestionID,
  QuestionnaireID,
  QuestionnairePage,
  QuestionnaireProgress,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
} from "../../types/Questionnaire";
import {
  getValidationSchema,
  getInitialValues,
  sanitizeValues,
} from "../../helpers/validation";
import { getQuestionnaireDefinition } from "../../helpers/questionnaireSchemaParser";
import { GenericQuestionProps } from "../GenericQuestion";
import usePrevious from "../../../../utils/usePrevious";
import getSectionsProgress, {
  SectionProgress,
  SectionState,
} from "../../helpers/getSectionsProgress";
import Addon from "modules/product/types/Addon";
import { ClientType } from "modules/parties/types/Client";

import styles from "./styles.module.scss";
import FormContent from "./FormContent";
import getFilledQuestionsByQuestionnaire from "modules/workflow/helpers/getFilledQuestionsByQuestionnaire";
import getMandatoryQuestionIDs, {
  filterMandatoryQuestions,
} from "modules/workflow/helpers/getMandatoryQuestionIDs";
import hasQuestionSetChanged from "modules/workflow/helpers/hasQuestionSetChanged";
import getDelta from "components/Forms/Form/getDelta";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import getAffectedChildQuestions from "modules/workflow/helpers/getAffectedChildQuestions";
import mergeAnswers from "modules/workflow/helpers/mergeAnswers";
import {
  getPageQuestions,
  getPageQuestionsIDs,
} from "modules/workflow/helpers/getPageQuestions";
import Party from "modules/parties/types/Party";
import { shouldPreprocess } from "modules/workflow/helpers/preprocessAnswers";
import logger from "logger";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import InterviewQuestionnaireSidebar from "../InterviewQuestionnaire/InterviewQuestionnaireSidebar";
import Workflow, { WorkflowID } from "modules/workflow/types/Workflow";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import getAllowedAddons from "modules/workflow/helpers/getAllowedAddons";
import resolveConditionalPageDescriptions from "./helpers";
import { DEFINITION_CATEGORY_ADDONS } from "modules/api/responseTypes/DefinitionResponseType";
import LoadingOverlay from "components/LoadingOverlay";
import { CLIENT_PAGE_ID } from "modules/parties/helpers/getClientQuestionnaire/constants";
import InterviewAlerts from "../InterviewQuestionnaire/InterviewAlerts";

type QuestionnaireFormProps = Pick<GenericQuestionProps, "questionActions"> & {
  answers?: AnswersByQuestionnaireType;
  beforeSave?: (
    questions: QuestionsMap,
    answers: AnswersMap,
    changedAnswerIds: string[],
  ) => Promise<AnswersMap>;
  children?: Party[];
  clients?: ClientsMap;
  coupledWorkflowAnswers?: AnswersByQuestionnaireType;
  customFormProps?: Partial<FormProps>;
  enforcePageCompletion?: boolean;
  forceSubmitPageOnForward?: boolean;
  getSubmitHandle?: (submitHandle: () => any) => void;
  onChange?: (formState: AnswersByQuestionnaireType) => any;
  onExit?: () => any;
  onOpenPeopleRegistry?: () => any;
  onPageChanged?: (
    currentPageIdx: number,
    pageId?: string,
    pageCaption?: string,
    initialLoad?: boolean,
  ) => any;
  onProgress?: (
    allQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
    filledQuestionsIDs: Record<QuestionnaireID, QuestionID[]>,
    sections: SectionProgress[],
    selectedAddons: Addon[],
  ) => any;
  onSavePageAnswers: (
    questionnaireId: QuestionnaireID,
    values: AnswersMap,
    sourcePageId?: string,
    selectedAddons?: Addon[],
  ) => any;
  onValidationErrors?: (errors: object, answers: AnswersMap) => any;
  preselectedAddons?: Addon[];
  questionnaireAnswers?: AnswersByQuestionnaireType;
  questionnairePages: QuestionnairePage[];
  questionnaireProgress?: QuestionnaireProgress;
  questionsBySubquestionnaire: QuestionsMapByQuestionnaire;
  renderFooter?: (submitHandle: FormSubmitHandle) => JSX.Element | null;
  validationErrors?: Record<QuestionID, string[]>;
  pageId?: string;
  pageIdx?: number;
  workflowState?: WorkflowPayloadState;
  handleSwitchToSpouse?: (
    submitHandle?: FormSubmitHandle | undefined,
  ) => Promise<void> | undefined;
  handleNavigationRequest?: (
    saveDelta: boolean,
    submitHandle: FormSubmitHandle | undefined,
    successCallback: () => any,
  ) => Promise<void>;
  isAdmin?: boolean;
  workflow?: Workflow;
  workflowId?: WorkflowID;
  availableWorkflows?: Record<
    string,
    DefinitionWithProductsPlansAndAddons
  > | null;
  fullWidth?: boolean;
  showPeoplePrompt?: boolean;
  isLoading?: boolean;
  editable?: boolean;
  userJurisdiction?: string;
};

const filterRelevantAnswers = (
  answers: AnswersMap,
  questionKeys: string[],
): AnswersMap => pick(answers, questionKeys);

const QuestionnaireForm = ({
  answers,
  beforeSave,
  children,
  clients,
  coupledWorkflowAnswers,
  customFormProps,
  enforcePageCompletion = true,
  forceSubmitPageOnForward = true,
  getSubmitHandle,
  onChange,
  onExit,
  onOpenPeopleRegistry,
  onPageChanged,
  onProgress,
  onSavePageAnswers,
  onValidationErrors,
  questionnaireAnswers,
  questionnairePages,
  questionnaireProgress,
  questionsBySubquestionnaire,
  preselectedAddons,
  renderFooter,
  validationErrors,
  pageId,
  pageIdx,
  questionActions,
  workflowState,
  handleSwitchToSpouse,
  handleNavigationRequest,
  isAdmin,
  workflow,
  availableWorkflows,
  fullWidth,
  showPeoplePrompt,
  isLoading = false,
  editable = true,
  userJurisdiction,
}: QuestionnaireFormProps) => {
  const [unsavedAnswers, setUnsavedAnswers] =
    useState<AnswersByQuestionnaireType>({});
  const allAnswers = mergeAnswers(answers, unsavedAnswers);
  const [uncontrolledPageIdx, setUncontrolledPageIdx] = useState(0);

  const allowedAddons = getAllowedAddons(
    availableWorkflows?.["default-workflow"].data[DEFINITION_CATEGORY_ADDONS],
  );
  const { pages, questions, selectedAddons } = getQuestionnaireDefinition(
    questionnairePages,
    questionsBySubquestionnaire,
    allAnswers,
    clients,
    workflowState,
    preselectedAddons,
    children,
    allowedAddons,
    editable,
  );

  // To make sure the optional prop is provided when needed
  if (shouldPreprocess(questionsBySubquestionnaire) && !beforeSave) {
    logger.warn(
      "Questionnaire definition contains questions requiring pre-processing before save.",
    );
  }

  const previousQuestionnaireAnswers = usePrevious(allAnswers);
  const previousQuestions = usePrevious(questions);
  const previousSelectedAddons = usePrevious(selectedAddons);
  const previousPages = usePrevious(pages);
  const isPaginationUncontrolled =
    pageIdx === undefined && pageId === undefined;

  const requestedPageById =
    pageId !== undefined ? pages.find((page) => page.id === pageId) : undefined;
  const requestedPageIdx = requestedPageById
    ? pages.indexOf(requestedPageById)
    : pageIdx;

  const currentPageIdx =
    isPaginationUncontrolled && pageId === undefined
      ? uncontrolledPageIdx
      : requestedPageIdx!;
  const previousPageIdx = usePrevious(currentPageIdx);

  const page = pages[currentPageIdx] || pages[0];
  const pageDefinition = questionnairePages.find(
    (definition) => page.id === definition.id,
  );
  const { questionnaireId } = page;
  const pageQuestions = getPageQuestions(page, questions[questionnaireId]);
  const initialValues = getInitialValues(
    pageQuestions,
    allAnswers[questionnaireId],
  );

  const schema = getValidationSchema(pageQuestions);

  const handlePageChange = useCallback(
    (nextIdx: number, nextPageID: string) => {
      if (isPaginationUncontrolled) {
        setUncontrolledPageIdx(nextIdx);
      }
      onPageChanged &&
        onPageChanged(nextIdx, nextPageID, pages[nextIdx]?.caption);
    },
    [isPaginationUncontrolled, onPageChanged, pages],
  );

  // Set initial page
  useEffect(() => {
    if (previousPageIdx === undefined && currentPageIdx !== previousPageIdx) {
      window.scrollTo(0, 0);
      onPageChanged &&
        onPageChanged(
          currentPageIdx,
          pages[currentPageIdx]?.id,
          pages[currentPageIdx]?.caption,
          true,
        );
    }
  }, [currentPageIdx, onPageChanged, pages, previousPageIdx]);

  // Check page index bounds
  useEffect(() => {
    if (currentPageIdx >= pages.length) {
      const idx = pages.length - 1;
      const page = pages[idx];
      page && handlePageChange(idx, page.id);
      return;
    }
    if (currentPageIdx < 0) {
      pages[0] && handlePageChange(0, pages[0].id);
      return;
    }
  }, [currentPageIdx, previousPageIdx, handlePageChange, pages]);

  const getCurrentProgress = useCallback(
    (
      filledQuestionIDs?: Record<QuestionnaireID, QuestionID[]>,
      enforcePageCompletion?: boolean,
    ): SectionProgress[] => {
      const currentFilledQuestionsIDs =
        filledQuestionIDs ||
        getFilledQuestionsByQuestionnaire(allAnswers, questions);
      return getSectionsProgress(
        pages,
        questions,
        currentFilledQuestionsIDs,
        !enforcePageCompletion,
      );
    },
    [allAnswers, pages, questions],
  );

  const triggerProgressUpdate = useCallback(
    (filledQuestionIDs?: Record<QuestionnaireID, QuestionID[]>) => {
      if (!onProgress) return;
      const filledMandatoryQuestionsIDs = getMandatoryQuestionIDs(
        filledQuestionIDs ||
          getFilledQuestionsByQuestionnaire(allAnswers, questions),
        questions,
      );
      const sectionsProgress = getCurrentProgress(
        filledMandatoryQuestionsIDs,
        enforcePageCompletion,
      );

      const allVisibleQuestionIDs = pages.map(getPageQuestionsIDs).flat();
      const allMandatoryQuestionsIDs: Record<QuestionnaireID, QuestionID[]> =
        filterMandatoryQuestions(questions, allVisibleQuestionIDs);

      onProgress &&
        onProgress(
          allMandatoryQuestionsIDs,
          filledMandatoryQuestionsIDs,
          sectionsProgress,
          selectedAddons || [],
        );
    },
    [
      allAnswers,
      enforcePageCompletion,
      getCurrentProgress,
      onProgress,
      pages,
      questions,
      selectedAddons,
    ],
  );

  // Update progress
  useEffect(() => {
    if (!onProgress) return;

    const currentFilledQuestionsIDs = getFilledQuestionsByQuestionnaire(
      allAnswers,
      questions,
    );
    const previousFilledQuestionsIDs = getFilledQuestionsByQuestionnaire(
      previousQuestionnaireAnswers || {},
      questions,
    );
    const pageCaptions = pages.map((p) => p.caption);
    const previousPageCaptions = (previousPages || []).map((p) => p.caption);

    if (
      hasQuestionSetChanged(
        currentFilledQuestionsIDs,
        previousFilledQuestionsIDs,
      ) ||
      hasQuestionSetChanged(questions, previousQuestions || {}) ||
      !isEqual(pageCaptions, previousPageCaptions) ||
      !isEqual(selectedAddons, previousSelectedAddons) ||
      currentPageIdx !== previousPageIdx
    ) {
      triggerProgressUpdate(currentFilledQuestionsIDs);
    }
  }, [
    onProgress,
    questions,
    pages,
    previousQuestionnaireAnswers,
    previousQuestions,
    previousPageIdx,
    currentPageIdx,
    triggerProgressUpdate,
    allAnswers,
    previousPages,
    selectedAddons,
    previousSelectedAddons,
  ]);

  // Check access to requested page
  useEffect(() => {
    if (enforcePageCompletion) {
      const currentProgress = getCurrentProgress();
      for (let i = 0; i < currentPageIdx; i++) {
        if (
          currentProgress[i] &&
          currentProgress[i].state !== SectionState.COMPLETE
        ) {
          handlePageChange(i, pages[i].id);
          return;
        }
      }
    }
  }, [
    currentPageIdx,
    enforcePageCompletion,
    getCurrentProgress,
    handlePageChange,
    pages,
  ]);

  const handleFormUpdate = useCallback(
    (newFormState: AnswersMap): void => {
      const stateChanges = getDelta(
        newFormState,
        allAnswers[questionnaireId],
        questions[questionnaireId],
      );
      if (Object.keys(stateChanges).length > 0) {
        const allPageQuestionIDs = pageDefinition
          ? getPageQuestionsIDs(pageDefinition)
          : [];
        const relevantChanges = filterRelevantAnswers(
          { ...unsavedAnswers[questionnaireId], ...stateChanges },
          allPageQuestionIDs,
        );
        const nextUnsavedAnswers = {
          [questionnaireId]: getDelta(
            relevantChanges,
            answers && answers[questionnaireId],
            questions[questionnaireId],
          ),
        };

        const clearedDependentQuestions = getAffectedChildQuestions(
          nextUnsavedAnswers,
          questions,
        );
        const nextState = merge(nextUnsavedAnswers, clearedDependentQuestions);
        onChange && onChange(nextState);
        setUnsavedAnswers(nextState);
      }
    },
    [
      allAnswers,
      answers,
      onChange,
      pageDefinition,
      questionnaireId,
      questions,
      unsavedAnswers,
    ],
  );

  const handleNavigation = useCallback(
    () =>
      pages[currentPageIdx]?.onPageLeave &&
      pages[currentPageIdx].onPageLeave!(),
    [currentPageIdx, pages],
  );

  const handleNavigateForward = useCallback(
    () =>
      pages[currentPageIdx + 1] &&
      handlePageChange(currentPageIdx + 1, pages[currentPageIdx + 1].id),
    [currentPageIdx, handlePageChange, pages],
  );

  const handleNavigateBack = useCallback(
    () =>
      pages[currentPageIdx - 1] &&
      handlePageChange(currentPageIdx - 1, pages[currentPageIdx - 1].id),
    [currentPageIdx, handlePageChange, pages],
  );

  const saveValidatedAnswers = useCallback(
    async (allPageValues: AnswersMap, isDelta: boolean): Promise<void> => {
      const pageQuestions = questions[questionnaireId];
      const allRelevantValues = filterRelevantAnswers(
        allPageValues,
        Object.keys(pageQuestions),
      );

      const changedRelevantValues = filterRelevantAnswers(
        unsavedAnswers[questionnaireId],
        Object.keys(pageQuestions),
      );
      const shouldSaveDelta =
        isDelta && !(pageId === CLIENT_PAGE_ID && clients?.main === undefined);
      const answers = shouldSaveDelta
        ? changedRelevantValues
        : allRelevantValues;
      const valuesToSave = sanitizeValues(
        pageQuestions,
        beforeSave
          ? await beforeSave(
              pageQuestions,
              answers,
              Object.keys(changedRelevantValues),
            )
          : answers,
        clients,
      );

      const currentPage = pages[currentPageIdx];
      await (currentPage?.onSave
        ? currentPage.onSave!(valuesToSave, allRelevantValues, isDelta)
        : onSavePageAnswers(
            currentPage.questionnaireId,
            valuesToSave,
            isDelta || currentPage.index === undefined
              ? undefined
              : String(currentPage.index),
            selectedAddons,
          ));
      setUnsavedAnswers({});
    },
    [
      questions,
      questionnaireId,
      unsavedAnswers,
      pageId,
      clients,
      beforeSave,
      pages,
      currentPageIdx,
      onSavePageAnswers,
      selectedAddons,
    ],
  );

  const filterValidationErrors = (
    allErrors: FormikErrors<AnswersMap>,
    isDelta: boolean,
  ): FormikErrors<AnswersMap> => {
    const unsavedAndChangedQuestionsKeys = Object.keys(
      unsavedAnswers[questionnaireId] || {},
    ).filter((questionId) => {
      const savedAnswer =
        answers &&
        answers[questionnaireId] &&
        answers[questionnaireId][questionId];
      const unsavedAnswer =
        unsavedAnswers[questionnaireId] &&
        unsavedAnswers[questionnaireId][questionId];
      return !isEqual(savedAnswer, unsavedAnswer);
    });

    const currentPage = pages[currentPageIdx];
    const relevantErrors = {
      ...(isDelta
        ? pick(allErrors, unsavedAndChangedQuestionsKeys)
        : allErrors),
      ...(currentPage?.onFilterValidationErrors
        ? currentPage?.onFilterValidationErrors(
            allErrors,
            unsavedAndChangedQuestionsKeys,
            isDelta,
          )
        : {}),
    };
    return relevantErrors;
  };

  const formProps: FormProps = {
    beforeLeave: handleNavigation,
    forceFullPageSubmitOnForward: forceSubmitPageOnForward,
    filterValidationErrors: filterValidationErrors,
    onBack: handleNavigateBack,
    onExit: onExit,
    onFormChanged: handleFormUpdate,
    onFormInvalid: onValidationErrors,
    onNext: handleNavigateForward,
    onSave: saveValidatedAnswers,
    renderFooter,
    schema: schema,
    showBackButton: currentPageIdx > 0,
    showNextButton: currentPageIdx < pages.length - 1,
    showSaveAndExitButton: Boolean(onExit),
    showSaveButton: Boolean(customFormProps?.saveButtonLabel),
    state: initialValues,
    ...(customFormProps || {}),
    showSubmitButton:
      customFormProps?.showSubmitButton && currentPageIdx === pages.length - 1,
  };

  const renderAction = page.renderAction
    ? (submitHandle?: FormSubmitHandle, formHandle?: FormikProps<AnswersMap>) =>
        page.renderAction!(
          getPageQuestions(page, questions[questionnaireId]),
          allAnswers[questionnaireId],
          submitHandle,
          formHandle,
        )
    : undefined;

  const hasHeader = page.caption || page.renderAction;

  const isCoupledWorkflow = Boolean(workflowState?.coupledWorkflowId);

  const pageDescription =
    (page.conditionalDescriptions
      ? resolveConditionalPageDescriptions(
          page.conditionalDescriptions,
          questions,
          allAnswers,
          selectedAddons,
          clients,
          workflowState,
          children,
          allowedAddons,
        )
      : page.description) || "";

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <React.Fragment>
      {page.render ? (
        <div className={styles.questionnaire}>
          <InterviewAlerts
            isAdmin={isAdmin}
            pageId={pageId}
            workflowJurisdiction={workflow?.state.maybeUserState}
            userJurisdiction={userJurisdiction}
            workflowCreator={workflow?.creator}
            isOnlineProduct={workflow?.state.onlineProduct}
          />
          {hasHeader ? (
            <div className={styles["questionnaire__title"]}>
              <PageHeader
                title={page.caption || ""}
                text={page.description || ""}
                submitHandle={page.onPageLeave}
                renderAction={renderAction}
              />
            </div>
          ) : null}
          <div
            className={
              fullWidth
                ? styles["questionnaire__content_full_width"]
                : styles["questionnaire__content"]
            }
          >
            {page.render(formProps, triggerProgressUpdate)}
          </div>
          {questionnaireProgress && pageIdx !== undefined && (
            <InterviewQuestionnaireSidebar
              answers={questionnaireAnswers}
              clients={clients}
              coupledWorkflowAnswers={coupledWorkflowAnswers}
              isCoupledWorkflow={isCoupledWorkflow}
              submitForm={page.onPageLeave}
              questionnairePages={questionnairePages}
              questionnaireProgress={questionnaireProgress}
              questionsBySubquestionnaire={questionsBySubquestionnaire}
              pageIdx={pageIdx}
              currentPageQuestions={pageQuestions}
              onPageChanged={onPageChanged}
              onOpenPeopleRegistry={onOpenPeopleRegistry}
              handleSwitchToSpouse={handleSwitchToSpouse}
              handleNavigationRequest={handleNavigationRequest}
              isAdmin={isAdmin}
              workflow={workflow}
            />
          )}
        </div>
      ) : (
        <Form
          formKey={
            (page.id !== undefined ? page.id : `page_${currentPageIdx}`) +
            (clients?.main?.clientId || "")
          }
          {...formProps}
          validationErrors={validationErrors}
        >
          {(
            submitHandle: FormSubmitHandle,
            formHandle: FormikProps<AnswersMap>,
          ) => {
            getSubmitHandle && getSubmitHandle(submitHandle);
            return (
              <div className={styles.questionnaire}>
                <InterviewAlerts
                  isAdmin={isAdmin}
                  pageId={pageId}
                  workflowJurisdiction={workflow?.state.maybeUserState}
                  userJurisdiction={userJurisdiction}
                  workflowCreator={workflow?.creator}
                  isOnlineProduct={workflow?.state.onlineProduct}
                />
                {hasHeader ? (
                  <div className={styles["questionnaire__title"]}>
                    <PageHeader
                      title={page.caption || ""}
                      text={pageDescription}
                      formHandle={formHandle}
                      submitHandle={submitHandle}
                      renderAction={renderAction}
                    />
                  </div>
                ) : null}
                <div
                  className={
                    fullWidth
                      ? styles["questionnaire__content_full_width"]
                      : styles["questionnaire__content"]
                  }
                >
                  <FormContent
                    client={clients && clients[ClientType.MAIN]}
                    spouse={clients && clients[ClientType.SPOUSE]}
                    sections={page.sections}
                    questions={Object.values(questions[questionnaireId])}
                    pageId={page.id}
                    questionActions={questionActions}
                  />
                  {page.renderPageFooter && page.renderPageFooter(submitHandle)}
                </div>
                {questionnaireProgress && pageIdx !== undefined && (
                  <InterviewQuestionnaireSidebar
                    answers={questionnaireAnswers}
                    clients={clients}
                    coupledWorkflowAnswers={coupledWorkflowAnswers}
                    isCoupledWorkflow={isCoupledWorkflow}
                    submitForm={page.onPageLeave || submitHandle}
                    submitHandle={submitHandle}
                    formHandle={formHandle}
                    questionnairePages={questionnairePages}
                    questionnaireProgress={questionnaireProgress}
                    questionsBySubquestionnaire={questionsBySubquestionnaire}
                    pageIdx={pageIdx}
                    currentPageQuestions={pageQuestions}
                    onPageChanged={onPageChanged}
                    onOpenPeopleRegistry={onOpenPeopleRegistry}
                    handleSwitchToSpouse={handleSwitchToSpouse}
                    handleNavigationRequest={handleNavigationRequest}
                    isAdmin={isAdmin}
                    workflow={workflow}
                    showPeoplePrompt={showPeoplePrompt}
                  />
                )}
              </div>
            );
          }}
        </Form>
      )}
    </React.Fragment>
  );
};

export default QuestionnaireForm;
