import { createReducer } from "@reduxjs/toolkit";
import {
  loadingProductsStarted,
  loadingProductsFailed,
  productsLoaded,
  startProductAdvisor,
  advisorQuestionnaireComplete,
  getProductRecommendationComplete,
  getProductRecommendationStarted,
  getProductRecommendationFailed,
  reviewAdvisorAnswers,
} from "./actions";
import {
  createWorkflowInitiated,
  newWorkflowCreated,
  createWorkflowFailed,
} from "../workflow/actions";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import ProductRecommendation from "./types/ProductRecommendation";
import { LOCATION_CHANGE } from "connected-react-router";
import { stateChanged } from "modules/auth/actions";
import { partnerSelected } from "modules/partner/actions";

export const productsRehydrated = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(loadingProductsFailed, () => true)
    .addCase(productsLoaded, () => true),
);

export const isLoadingProducts = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(loadingProductsStarted, () => true)
    .addCase(loadingProductsFailed, () => false)
    .addCase(productsLoaded, () => false),
);

export const isSavingProduct = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(createWorkflowInitiated, () => true)
    .addCase(newWorkflowCreated, () => false)
    .addCase(createWorkflowFailed, () => false),
);

export const availableWorkflows = createReducer<Record<
  string,
  DefinitionWithProductsPlansAndAddons
> | null>(null, (builder) =>
  builder
    .addCase(productsLoaded, (_, { payload }) => payload)
    .addCase(stateChanged, () => null)
    .addCase(loadingProductsFailed, () => null)
    .addCase(partnerSelected, () => null),
);

export const productAdvisorAnswers =
  createReducer<AnswersByQuestionnaireType | null>(null, (builder) =>
    builder
      .addCase(startProductAdvisor, () => null)
      .addCase(
        advisorQuestionnaireComplete,
        (_, { payload }) => payload.answers,
      ),
  );

export const isLoadingRecommendation = createReducer<boolean>(
  false,
  (builder) =>
    builder
      .addCase(getProductRecommendationStarted, () => true)
      .addCase(getProductRecommendationFailed, () => false)
      .addCase(getProductRecommendationComplete, () => false),
);

export const productRecommendation =
  createReducer<ProductRecommendation | null>(null, (builder) =>
    builder
      .addCase(startProductAdvisor, () => null)
      .addCase(
        getProductRecommendationComplete,
        (_, { payload }) => payload || null,
      ),
  );

export const isAdvisorComplete = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(advisorQuestionnaireComplete, () => true)
    .addCase(reviewAdvisorAnswers, () => false)
    .addCase(LOCATION_CHANGE, () => false),
);
