import { callGetPartnerRequestsAPI } from "modules/admin/actions";
import useSWR from "swr";

const usePartnerRequests = () => {
  const { data, error, mutate } = useSWR(
    ["usePartnerRequests"],
    callGetPartnerRequestsAPI,
  );

  return {
    partnerRequests: data,
    error,
    refetchPartnerRequests: mutate,
  };
};

export default usePartnerRequests;
