import firebase from "firebase/compat/app";
import React from "react";
import ResetPasswordWrapper from "./components";

interface ResetPasswordScreenProps {
  authProvider: firebase.auth.Auth;
}

const ResetPasswordScreen = ({ authProvider }: ResetPasswordScreenProps) => (
  <ResetPasswordWrapper authProvider={authProvider} />
);

export default ResetPasswordScreen;
