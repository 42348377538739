import { Alert, Slide, Snackbar } from "@mui/material";
import * as React from "react";

export enum NotificationType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

let notificationRef = React.createRef<any>();

export const NotificationWrapper: React.FC<{}> = () => {
  return <Notification ref={notificationRef} />;
};

type NotificationState = {
  type: NotificationType;
  message: string | null;
  vertical: "top" | "bottom";
  horizontal: "center" | "left" | "right";
  autoHide: boolean;
};
class Notification extends React.PureComponent<{}, NotificationState> {
  state: NotificationState = {
    type: NotificationType.INFO,
    message: null,
    vertical: "top",
    horizontal: "center",
    autoHide: true,
  };

  private Transition = (props: object) => {
    return <Slide {...props} direction="down" />;
  };

  render() {
    const { type, message, vertical, horizontal, autoHide } = this.state;

    const handleClose = () => {
      this.setState({ message: null });
    };

    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={Boolean(message)}
        onClose={handleClose}
        autoHideDuration={autoHide ? 3000 : undefined}
        TransitionComponent={this.Transition}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    );
  }
}

const showNotification = (
  type: NotificationType,
  message?: React.ReactNode,
  expireInSec?: number,
) => {
  if (notificationRef?.current?.setState) {
    notificationRef?.current?.setState({ type, message });
  } else {
    alert(message);
  }
};

export default {
  info: (message?: string, expireInSec?: number) =>
    showNotification(NotificationType.INFO, message, expireInSec),

  success: (message?: string, expireInSec?: number) =>
    showNotification(NotificationType.SUCCESS, message, expireInSec),

  error: (message?: string, expireInSec?: number) =>
    showNotification(NotificationType.ERROR, message, expireInSec),

  warning: (message?: string, expireInSec?: number) =>
    showNotification(NotificationType.WARNING, message, expireInSec),
};
