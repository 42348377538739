import React, { useState } from "react";
import { RenderSalesReceiptModalFormContentProps } from "./types";
import DownloadRenderedSalesReceipt from "../DownloadRenderedSalesReceipt";
import { getEndDate, getStartDate } from "./helpers";
import { InputType } from "components/Forms/Inputs/Input/types";
import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const RenderSalesReceiptModalFormContent = ({
  period,
  setIsDownloadingReceipt,
  partnerId,
}: RenderSalesReceiptModalFormContentProps) => {
  const [fromDate, setFromDate] = useState(getStartDate(period) || "");
  const [toDate, setToDate] = useState(getEndDate(period) || "");
  const [showRenderStatus, setShowRenderStatus] = useState(false);

  const handleSubmitInterval = () => {
    setIsDownloadingReceipt(true);
    setShowRenderStatus(true);
  };

  return (
    <div className={cx("render-sales-receipt-modal-form-content")}>
      <div>
        <h3>From (including)</h3>
        <InputStringPrimitive
          name="from"
          type={InputType.DATE}
          value={fromDate}
          onChangeValue={setFromDate}
        />
        <h3>To (including)</h3>
        <InputStringPrimitive
          name="to"
          type={InputType.DATE}
          value={toDate}
          onChangeValue={setToDate}
        />
        <TabbedButton
          label="Render"
          onClick={handleSubmitInterval}
          disabled={fromDate === "" || toDate === ""}
        />
      </div>
      {showRenderStatus && (
        <div>
          <DownloadRenderedSalesReceipt
            partnerId={partnerId}
            from={fromDate}
            to={toDate}
            setIsDownloadingReceipt={setIsDownloadingReceipt}
          />
        </div>
      )}
    </div>
  );
};

export default RenderSalesReceiptModalFormContent;
