import { callGetMedicaidVariablesAPI } from "modules/admin/actions";
import useSWR from "swr";

const useMedicaidVariables = (jurisdiction: string) => {
  const { data, error, mutate } = useSWR(
    ["useMedicaidVariables", jurisdiction],
    () => callGetMedicaidVariablesAPI(jurisdiction),
  );

  return {
    medicaidVariables: data?.medicaidVariables,
    questionnaireWithQuestions: data?.questionnaireWithQuestions,
    error,
    refetchMedicaidVariables: mutate,
  };
};

export default useMedicaidVariables;
