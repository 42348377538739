import React, { FC } from "react";
import { RouteComponentProps, Redirect } from "react-router";
import parseParams from "utils/parseUrlParams";

const ActionStepCallback: FC<RouteComponentProps> = ({ location }) => {
  const { code, partner } = parseParams(location.search);
  return <Redirect to={`/${partner}/oauth?code=${code}`} />;
};

export default ActionStepCallback;
