import keyBy from "lodash/keyBy";
import Addon from "modules/product/types/Addon";
import { AnswersByQuestionnaireType } from "../types/Answer";
import {
  QuestionDefinitionAddon,
  QuestionsMapByQuestionnaire,
  QuestionType,
} from "../types/Questionnaire";
import getAnswer from "./getAnswer";

export default (
  questions: QuestionsMapByQuestionnaire,
  questionnaireAnswers: AnswersByQuestionnaireType,
  preselectedAddons?: Addon[],
): Addon[] => {
  const selectedAddonsMap: Record<string, Addon | undefined> = keyBy(
    preselectedAddons,
    "id",
  );

  Object.entries(questions).forEach(([questionnaireId, questions]) => {
    const addonQuestions = Object.values(questions).filter(
      (q) => q.data.definition.type === QuestionType.ADDON,
    );

    // Add or remove addons based on the answers
    addonQuestions.forEach((q) => {
      const addonDetails = (q.data.definition as QuestionDefinitionAddon)
        .addonDetails;
      if (!addonDetails) return;

      const answer = getAnswer(questionnaireAnswers, questionnaireId, q.id);
      if (answer === true) {
        selectedAddonsMap[addonDetails.id] = addonDetails;
      } else if (answer === false && selectedAddonsMap[addonDetails.id]) {
        selectedAddonsMap[addonDetails.id] = undefined;
      }
    });
  });

  return Object.values(selectedAddonsMap).filter(Boolean) as Addon[];
};
