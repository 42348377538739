import { ModalControls, ModalType, showModal } from "components/Modal";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath } from "react-router-dom";
import RenderSalesReceiptModalFormContent from "../RenderSalesReceiptModalFormContent/RenderSalesReceiptModalFormContext";
import { PartnerFinanceTableItemProps } from "./types";

const PartnerFinanceTableItem = ({
  partnerFinancialStatus,
  period,
}: PartnerFinanceTableItemProps) => {
  const dispatch = useDispatch();
  const [isDownloadingReceipt, setIsDownloadingReceipt] = useState(false);

  return (
    <tr key={partnerFinancialStatus.partnerId}>
      <td>{partnerFinancialStatus.displayName}</td>
      <td>{partnerFinancialStatus.partnerId}</td>
      <td>{partnerFinancialStatus.totalPaymentsCollected.toFixed(2)}</td>
      <td>
        <TabbedButton
          onClick={() =>
            showModal({
              type: ModalType.CONFIRM,
              cancellable: true,
              labelCancel: "Close",
              title: "Render Sales Receipt",
              renderContent: (controls: ModalControls) => (
                <RenderSalesReceiptModalFormContent
                  partnerId={partnerFinancialStatus.partnerId}
                  setIsDownloadingReceipt={setIsDownloadingReceipt}
                  period={period}
                />
              ),
            })
          }
          disabled={isDownloadingReceipt || period === undefined}
        >
          Download Receipt
        </TabbedButton>
      </td>
      <td>
        <TabbedButton
          onClick={() =>
            go(
              dispatch,
              generatePath(ROUTE_TEMPLATE.ADMIN_FINANCIAL_PROFILE, {
                partnerId: partnerFinancialStatus.partnerId,
              }),
            )
          }
          style={TabbedButtonType.SECONDARY}
        >
          Details
        </TabbedButton>
      </td>
      <td></td>
    </tr>
  );
};

export default PartnerFinanceTableItem;
