import { callGetPartnerAuditAPI } from "modules/partner/actions";
import useSWR from "swr";

// partner id is only used as key to keep the fetches separate
// request header is filled in from url as usual
const usePartnerAudit = (id: string) => {
  const { data, error, mutate } = useSWR(["usePartnerAudit", id], () =>
    callGetPartnerAuditAPI(),
  );

  return {
    partnerAudit: data,
    partnerAuditError: error,
    refetchPartnerAudit: mutate,
  };
};

export default usePartnerAudit;
