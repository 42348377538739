import React from "react";
import { CircularProgress } from "@mui/material";
import {
  AnimatedLoadingOverlaySize,
  type AnimatedLoadingOverlayProps,
} from "./types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const AnimatedLoadingOverlay = ({
  label,
  size,
}: AnimatedLoadingOverlayProps) => {
  const spinnerSize = {
    [AnimatedLoadingOverlaySize.LARGE]: 40,
    [AnimatedLoadingOverlaySize.MEDIUM]: 20,
    [AnimatedLoadingOverlaySize.INLINE]: 10,
  }[size];

  return (
    <div
      className={cx("animated-loading-overlay", {
        large: size === AnimatedLoadingOverlaySize.LARGE,
        medium: size === AnimatedLoadingOverlaySize.MEDIUM,
        inline: size === AnimatedLoadingOverlaySize.INLINE,
      })}
    >
      <CircularProgress color="primary" size={spinnerSize} />{" "}
      {label || "Loading..."}
    </div>
  );
};

export default AnimatedLoadingOverlay;
