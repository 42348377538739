import { createAction, Dispatch } from "@reduxjs/toolkit";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { UserID } from "modules/auth/User";
import { workflowStateChanged } from "../actions";
import {
  callAcceptEngagementAgreementAPI,
  callRejectEngagementAgreementAPI,
  callAcceptLawyerReviewAPI,
  callFinishLawyerReviewAPI,
  callReinitiateLawyerReviewAPI,
  callRejectLawyerReviewAPI,
  callStartLawyerReviewAPI,
} from "../endpoints/workflowSteps";
import Workflow, { WorkflowID } from "../types/Workflow";

export const startLawyerReviewPending = createAction(
  "START_LAWYER_REVIEW_PENDING",
);
export const lawyerReviewStarted = createAction<Workflow>(
  "LAWYER_REVIEW_STARTED",
);
export const startLawyerReviewFailed = createAction<any>(
  "START_LAWYER_REVIEW_FAILED",
);
export const startLawyerReview =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(startLawyerReviewPending());
      const workflow = await callStartLawyerReviewAPI(workflowId, asUser);
      dispatch(lawyerReviewStarted(workflow));
    } catch (e) {
      dispatch(startLawyerReviewFailed(e));
      throw e;
    }
  };

export const acceptEngagementAgreementPending = createAction(
  "ACCEPT_ENGAGEMENT_AGREEMENT_PENDING",
);
export const acceptEngagementAgreementFinished = createAction<{
  workflowId: WorkflowID;
  asUserId?: UserID;
  hasSkippedPayment?: boolean;
}>("ACCEPT_ENGAGEMENT_AGREEMENT_FINISHED");
export const acceptEngagementAgreementFailed = createAction<any>(
  "ACCEPT_ENGAGEMENT_AGREEMENT_FAILED",
);

export const acceptEngagementAgreement =
  (workflowId: string, asUserId?: string, hasSkippedPayment?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(acceptEngagementAgreementPending());
      const workflow = await callAcceptEngagementAgreementAPI(
        workflowId,
        asUserId,
      );
      dispatch(workflowStateChanged({ workflowId, state: workflow.state }));
      dispatch(
        acceptEngagementAgreementFinished({
          workflowId,
          asUserId,
          hasSkippedPayment,
        }),
      );
      logger.notify(NotificationType.SUCCESS, "Engagement was accepted");
    } catch (e) {
      dispatch(acceptEngagementAgreementFailed(e));
      throw e;
    }
  };

export const rejectEngagementAgreementPending = createAction(
  "REJECT_ENGAGEMENT_AGREEMENT_PENDING",
);
export const rejectEngagementAgreementFinished = createAction(
  "REJECT_ENGAGEMENT_AGREEMENT_FINISHED",
);
export const rejectEngagementAgreementFailed = createAction<any>(
  "REJECT_ENGAGEMENT_AGREEMENT_FAILED",
);

export const rejectEngagementAgreement =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(rejectEngagementAgreementPending());
      await callRejectEngagementAgreementAPI(workflowId, asUser);
      dispatch(rejectEngagementAgreementFinished());
      logger.notify(NotificationType.SUCCESS, "Engagement was rejected");
    } catch (e) {
      dispatch(rejectEngagementAgreementFailed(e));
      throw e;
    }
  };

export const finishReviewPending = createAction("FINISH_LAWYER_REVIEW_PENDING");
export const lawyerReviewFinished = createAction("LAWYER_REVIEW_FINISHED");
export const finishReviewFailed = createAction<any>(
  "FINISH_LAWYER_REVIEW_FAILED",
);

export const finishLawyerReview =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(finishReviewPending());
      await callFinishLawyerReviewAPI(workflowId, asUser);
      dispatch(lawyerReviewFinished());
      logger.notify(
        NotificationType.SUCCESS,
        "Documents were marked as reviewed",
      );
    } catch (e) {
      dispatch(finishReviewFailed(e));
      throw e;
    }
  };

export const acceptReviewPending = createAction("ACCEPT_LAWYER_REVIEW_PENDING");
export const lawyerReviewAccepted = createAction("LAWYER_REVIEW_ACCEPTED");
export const acceptReviewFailed = createAction<any>(
  "ACCEPT_LAWYER_REVIEW_FAILED",
);
export const acceptLawyerReview =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(acceptReviewPending());
      await callAcceptLawyerReviewAPI(workflowId, asUser);
      dispatch(lawyerReviewAccepted());
    } catch (e) {
      dispatch(acceptReviewFailed(e));
      throw e;
    }
  };

export const rejectReviewPending = createAction("REJECT_LAWYER_REVIEW_PENDING");
export const lawyerReviewRejected = createAction("LAWYER_REVIEW_REJECTED");
export const rejectReviewFailed = createAction<any>(
  "REJECT_LAWYER_REVIEW_FAILED",
);
export const rejectLawyerReview =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(rejectReviewPending());
      await callRejectLawyerReviewAPI(workflowId, asUser);
      dispatch(lawyerReviewRejected());
    } catch (e) {
      dispatch(rejectReviewFailed(e));
      throw e;
    }
  };

export const reinitiateReviewPending = createAction(
  "REINITIATE_LAWYER_REVIEW_PENDING",
);
export const lawyerReviewReinitiated = createAction(
  "LAWYER_REVIEW_REINITIATED",
);
export const reinitiateReviewFailed = createAction<any>(
  "REINITIATE_LAWYER_REVIEW_FAILED",
);
export const reinitiateLawyerReview =
  (workflowId: string, asUser?: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(reinitiateReviewPending());
      await callReinitiateLawyerReviewAPI(workflowId, asUser);
      dispatch(lawyerReviewReinitiated());
    } catch (e) {
      dispatch(reinitiateReviewFailed(e));
      throw e;
    }
  };
