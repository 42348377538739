import * as React from "react";
import SimpleHeader from "components/Header/SimpleHeader";
import { getWorkflowRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { go, matchPathTemplate } from "modules/navigation/helpers/navigator";

type PaymentScreenHeaderProps = {
  onHome: () => void;
};

const PaymentScreenHeader: React.FC<PaymentScreenHeaderProps> = ({
  onHome,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const handleNavigateToSigning = () => {
    const pathMatch =
      matchPathTemplate(ROUTE_TEMPLATE.WORKFLOW_PAYMENT, location) || undefined;
    const workflowId = ((pathMatch?.params || {}) as any).id;
    go(dispatch, getWorkflowRoute(workflowId, ROUTE_TEMPLATE.WORKFLOW_SIGNING));
  };

  return (
    <SimpleHeader
      onLogoClicked={onHome}
      backLabel={"Go Back"}
      onBack={handleNavigateToSigning}
    />
  );
};

export default PaymentScreenHeader;
