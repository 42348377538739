import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";
import { isAdmin, isGlobalAdmin } from "modules/auth/helper";
import { fetchMedicaidLead } from "modules/medicaid/actions";
import type {
  EnrichedMedicaidLead,
  MedicaidAnalysis,
  MedicaidLead,
  MedicaidLeadContextProps,
} from "modules/medicaid/types";
import Client from "modules/parties/types/Client";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import Questionnaire, {
  QuestionsMap,
} from "modules/workflow/types/Questionnaire";
import React, { createContext } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";

const MedicaidLeadContext = createContext<{
  lead?: MedicaidLead;
  availableLeads?: Record<string, EnrichedMedicaidLead>;
  analysis?: MedicaidAnalysis;
  questionnaire?: Questionnaire;
  questions?: QuestionsMap;
  answers?: AnswersMap;
  unsavedAnswers?: AnswersByQuestionnaireType;
  clients?: Record<string, Client>;
  isLoadingMedicaidLead: boolean;
  isLoadingMedicaidLeads: boolean;
  isLoadingMedicaidAnalysis: boolean;
}>({
  isLoadingMedicaidLead: false,
  isLoadingMedicaidLeads: false,
  isLoadingMedicaidAnalysis: false,
});

export const MedicaidLeadContextProvider = ({
  children,
}: MedicaidLeadContextProps) => {
  const dispatch = useDispatch();
  const { partnerId: urlPartnerId, medicaidLeadId: urlMedicaidLeadId } =
    useParams<{ partnerId?: string; medicaidLeadId?: string }>();

  const {
    user,
    lead,
    availableLeads,
    analysis,
    isLoadingMedicaidLead,
    questionnaire,
    questions,
    answers,
    unsavedAnswers,
    clients,
    isLoadingMedicaidLeads,
    isLoadingMedicaidAnalysis,
  } = useSelector(
    (state) => ({
      user: state.user,
      lead: state.medicaid.lead,
      availableLeads: state.medicaid.availableLeads,
      analysis: state.medicaid.analysis,
      questionnaire: state.medicaid.questionnaire,
      questions: state.medicaid.questions,
      answers: state.medicaid.answers,
      unsavedAnswers: state.medicaid.unsavedAnswers,
      clients: state.medicaid.clients,
      isLoadingMedicaidLead: state.medicaid.isLoadingMedicaidLead,
      isLoadingMedicaidLeads: state.medicaid.isLoadingMedicaidLeads,
      isLoadingMedicaidAnalysis: state.medicaid.isLoadingMedicaidAnalysis,
    }),
    shallowEqual,
  );

  if (urlMedicaidLeadId !== undefined) {
    if (!isAdmin(urlPartnerId, user) && !isGlobalAdmin(user)) {
      if (lead !== undefined && urlMedicaidLeadId !== lead.medicaidLeadId) {
        if (!isLoadingMedicaidLead) {
          fetchMedicaidLead(dispatch, urlMedicaidLeadId);
        }
        return (
          <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />
        );
      }
    }
  }

  const exposedValue = {
    lead,
    availableLeads,
    analysis,
    questionnaire,
    questions,
    answers,
    unsavedAnswers,
    clients,
    isLoadingMedicaidLead,
    isLoadingMedicaidLeads,
    isLoadingMedicaidAnalysis,
  };

  return (
    <MedicaidLeadContext.Provider value={exposedValue}>
      {children}
    </MedicaidLeadContext.Provider>
  );
};

export default MedicaidLeadContext;
