/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { fetchMedicaidLeadPreviews } from "modules/medicaid/actions";
import { ROUTE_TEMPLATE, getRoute } from "modules/navigation/routes";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useSelector } from "redux/reducer";
import PageHeader from "components/PageHeader";
import {
  AcceptedMedicaidLeadPreview,
  PendingMedicaidLeadPreview,
  MedicaidLeadPreviewStatus,
} from "modules/medicaid/types";
import PaginatedList, {
  ListType,
  PaginatedListColour,
} from "components/PaginatedList";
import MedicaidLeadPreviewItem from "./MedicaidLeadPreviewItem";
import {
  DEFAULT_ORDER,
  DEFAULT_SORT_FIELD,
  sortLeadPreviewArray,
} from "./MedicaidLeadPreviewItem/helpers";
import { go } from "modules/navigation/helpers/navigator";
import { map } from "lodash";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";
import CollapsableCard, {
  CollapsableCardColour,
} from "components/CollapsableCard";
import AcceptedMedicaidLeadPreviewItem from "./AcceptedMedicaidLeadPreviewItem";

const MedicaidLeads = () => {
  const dispatch = useDispatch();

  const { partner, isLoadingPartner, admin, isLoadingMedicaidLeadPreviews } =
    useSelector(
      (state) => ({
        partner: state.partner,
        isLoadingPartner: state.isLoadingPartner,
        admin: state.medicaid.admin,
        isLoadingMedicaidLeadPreviews:
          state.medicaid.isLoadingMedicaidLeadPreviews,
      }),
      shallowEqual,
    );

  const [sortField, setSortField] = useState<string>(DEFAULT_SORT_FIELD);
  const [order, setOrder] = useState(DEFAULT_ORDER);

  if (partner === null) {
    if (isLoadingPartner) {
      return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
    }
    return <Redirect to={ROUTE_TEMPLATE.ROOT} />;
  }

  if (admin?.leadPreviews === undefined) {
    if (!isLoadingMedicaidLeadPreviews) {
      fetchMedicaidLeadPreviews(dispatch);
    }
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const leadPreviewsWithIds: (
    | AcceptedMedicaidLeadPreview
    | PendingMedicaidLeadPreview
  )[] = Object.values(
    map(admin.leadPreviews, (leadPreview, medicaidLeadId) => ({
      ...leadPreview,
      medicaidLeadId,
    })),
  );

  const pendingMedicaidLeadPreviews: PendingMedicaidLeadPreview[] =
    leadPreviewsWithIds.filter(
      ({ previewStatus }) =>
        previewStatus === MedicaidLeadPreviewStatus.PENDING,
    ) as PendingMedicaidLeadPreview[];

  const acceptedMedicaidLeadPreviews: AcceptedMedicaidLeadPreview[] =
    leadPreviewsWithIds.filter(
      ({ previewStatus }) =>
        previewStatus === MedicaidLeadPreviewStatus.ACCEPTED,
    ) as AcceptedMedicaidLeadPreview[];

  const sortedLeadPreviewArray = sortLeadPreviewArray(
    pendingMedicaidLeadPreviews,
    sortField,
    order,
  );

  const handleShowMedicaidLeadPreviewDetails = (medicaidLeadId: string) => {
    go(
      dispatch,
      getRoute(ROUTE_TEMPLATE.PARTNER_MEDICAID_LEAD_PREVIEW, {
        medicaidLeadId,
      }),
    );
  };

  return (
    <div>
      <PageHeader title="Medicaid Leads" />
      <CollapsableCard title="Pending">
        <PaginatedList<PendingMedicaidLeadPreview>
          items={sortedLeadPreviewArray}
          pageSize={3}
          listType={ListType.LIST}
          renderItem={(item) => (
            <MedicaidLeadPreviewItem
              item={item.preview}
              key={item.preview.medicaidLeadId}
              medicaidLeadId={item.preview.medicaidLeadId}
              handleShowDetails={() =>
                handleShowMedicaidLeadPreviewDetails(
                  item.preview.medicaidLeadId,
                )
              }
            />
          )}
          itemNamePlural="pending leads"
        />
      </CollapsableCard>
      <br />
      <CollapsableCard
        title="Accepted"
        colour={CollapsableCardColour.PRIMARY}
        closed={acceptedMedicaidLeadPreviews.length === 0}
      >
        <PaginatedList<AcceptedMedicaidLeadPreview>
          items={acceptedMedicaidLeadPreviews}
          pageSize={3}
          renderItem={(item) => (
            <AcceptedMedicaidLeadPreviewItem
              item={item.preview}
              key={item.preview.enriched.medicaidLead.medicaidLeadId}
              handleShowDetails={() =>
                handleShowMedicaidLeadPreviewDetails(
                  item.preview.enriched.medicaidLead.medicaidLeadId,
                )
              }
            />
          )}
          itemNamePlural="accepted leads"
          colour={PaginatedListColour.WHITE}
        ></PaginatedList>
      </CollapsableCard>
    </div>
  );
};

export default MedicaidLeads;
