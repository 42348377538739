export type LocalStore = {
  set: (key: string, value: any) => void;
  get: (key: string) => any | undefined;
  delete: (key: string) => void;
};

const localStorageWrapper: LocalStore = {
  set: (key: string, value: any) =>
    localStorage.setItem(key, JSON.stringify(value)),

  get: (key: string) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : value;
  },

  delete: (key: string) => {
    localStorage.removeItem(key);
    return;
  },
};

export default localStorageWrapper;
