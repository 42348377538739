import { Switch, useParams } from "react-router-dom";
import ProtectedRoute from "modules/auth/components/ProtectedRoute";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import React, { useEffect } from "react";
import PaymentScreenContainer from "screens/payment/PaymentScreenContainer";
import WorkflowDetailsScreenContainer from "screens/workflowDetails/WorkflowDetailsScreenContainer";
import PeopleRegistryScreenContainer from "screens/peopleRegistry/PeopleRegistryScreenContainer";
import { useSelector } from "redux/reducer";
import { shallowEqual, useDispatch } from "react-redux";
import { fetchPartnerWorkflowsPrioritised } from "modules/admin/actions";
import { fetchWorkflowDetails } from "modules/workflow/actions";
import SigningScreenContainer from "screens/signingScreen/SigningScreenContainer";
import PaymentProcessingScreenContainer from "screens/payment/PaymentProcessingScreenContainer";
import { isAdmin } from "modules/auth/helper";

const WorkflowDetailNavigator: React.FC = () => {
  const { user, partner, workflows, hasRehydratedWorkflows, isLoadingPartner } =
    useSelector((state) => state, shallowEqual);
  const { id: workflowId } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const admin = isAdmin(partner?.id, user);
  const workflowOwner = workflowId && workflows[workflowId]?.userId;

  // Regular user
  useEffect(() => {
    user &&
      !isLoadingPartner &&
      !admin &&
      workflowId &&
      fetchWorkflowDetails(workflowId, { loadCoupled: true })(dispatch);
  }, [admin, dispatch, isLoadingPartner, user, workflowId]);

  // Admin
  useEffect(() => {
    if (admin) {
      hasRehydratedWorkflows && workflowId
        ? fetchWorkflowDetails(workflowId, {
            loadCoupled: true,
            asUser: workflowOwner,
          })(dispatch)
        : fetchPartnerWorkflowsPrioritised()(dispatch);
    }
  }, [admin, dispatch, hasRehydratedWorkflows, workflowId, workflowOwner]);

  return (
    <div>
      <Switch>
        <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW_SIGNING}>
          <SigningScreenContainer />
        </ProtectedRoute>

        <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW_PAYMENT_PROCESSING}>
          <PaymentProcessingScreenContainer
            workflowId={workflowId || ""}
            isAdmin={admin}
          />
        </ProtectedRoute>

        <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW_PAYMENT}>
          <PaymentScreenContainer />
        </ProtectedRoute>

        <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW_PARTIES}>
          <PeopleRegistryScreenContainer />
        </ProtectedRoute>

        <ProtectedRoute>
          <WorkflowDetailsScreenContainer />
        </ProtectedRoute>
      </Switch>
    </div>
  );
};

export default WorkflowDetailNavigator;
