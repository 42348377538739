import { keyBy } from "lodash";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import Client, { ClientType } from "modules/parties/types/Client";
import Party from "modules/parties/types/Party";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import {
  Question,
  QuestionID,
  QuestionnaireSection,
} from "modules/workflow/types/Questionnaire";
import * as React from "react";

type PartyDetailsFormContentProps<T> = {
  clients?: ClientsMap;
  formAnswers?: AnswersByQuestionnaireType;
  formSections: QuestionnaireSection[];
  formValuesToParty: (formValues: AnswersMap) => Partial<T>;
  getSubmitHandle?: (submitHandle: () => any) => void;
  onChange?: () => void;
  onSaveParty: (party: Partial<T>) => void;
  party?: Party;
  partyToFormValues: (client: Client, party: Party) => AnswersMap;
  questionnaireId: string;
  questions: Question[];
};

function PartyDetailsFormContent<T>({
  clients,
  formAnswers,
  formSections,
  formValuesToParty,
  getSubmitHandle,
  onChange,
  onSaveParty,
  party,
  partyToFormValues,
  questionnaireId,
  questions,
}: PartyDetailsFormContentProps<T>) {
  const [validationErrors, setValidationErrors] = React.useState<
    Record<QuestionID, string[]>
  >({});
  const handleSaveParty = async (values: AnswersMap) => {
    try {
      await onSaveParty(formValuesToParty(values));
    } catch (e) {
      typeof e === "object" &&
        setValidationErrors(e as Record<QuestionID, string[]>);
    }
  };

  const allAnswers: AnswersByQuestionnaireType = {
    ...formAnswers,
    [questionnaireId]:
      party && clients?.[ClientType.MAIN]
        ? partyToFormValues(clients[ClientType.MAIN]!, party)
        : {},
  };

  return (
    <QuestionnaireForm
      clients={clients}
      getSubmitHandle={getSubmitHandle}
      answers={allAnswers}
      onSavePageAnswers={(_, values) => handleSaveParty(values)}
      customFormProps={{ showSaveAndExitButton: false }}
      onChange={onChange}
      questionsBySubquestionnaire={{
        [questionnaireId]: keyBy(questions, "id"),
      }}
      questionnairePages={[
        {
          id: `${questionnaireId}_page`,
          questionnaireId,
          caption: "",
          sections: formSections,
        },
      ]}
      validationErrors={validationErrors}
    />
  );
}

export default PartyDetailsFormContent;
