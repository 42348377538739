import LoadingOverlay from "components/LoadingOverlay";
import PageHeader from "components/PageHeader";
import PaginatedList, { ListType } from "components/PaginatedList";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { API } from "modules/api";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import Partner from "modules/parties/types/Partner";
import { callGetPartners } from "modules/partner/actions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import { sortPartners } from "./helpers";
import styles from "./styles.module.scss";
import ReactTooltip from "react-tooltip";
import ArchivePartner from "../PartnerArchiveModal/PartnerArchiveModal";

const renderItem = (partner: Partner) => (
  <PartnersAdminItem {...{ partner }} key={partner.id} />
);

const PartnersAdminItem: React.FC<{ partner: Partner }> = ({ partner }) => {
  const dispatch = useDispatch();
  return (
    <tr>
      <td>{partner.data.displayName}</td>
      <td>{partner.id}</td>
      <td>{partner.data.contact.email}</td>
      <td>{new Date(partner.created).toLocaleDateString()}</td>
      <td>{partner.archived ? "Archived" : "Valid"}</td>
      <td className={styles["overview__buttons"]}>
        <TabbedButton
          style={TabbedButtonType.SECONDARY}
          addClassName={styles["icon_button"]}
          tabIndex={0}
          onClick={() =>
            go(
              dispatch,
              `${getRoute(
                ROUTE_TEMPLATE.ADMIN_PARTNERS,
              )}/${partner.id.toLowerCase()}`,
            )
          }
        >
          Edit
        </TabbedButton>
      </td>
      <td className={styles["overview__buttons"]}>
        <ArchivePartner
          partner={partner}
          afterArchive={() => {
            go(dispatch, `${getRoute(ROUTE_TEMPLATE.ADMIN_PARTNERS_SCREEN)}`);
          }}
        />
        <ReactTooltip id="archiveTooltip" place="top" effect="solid">
          Archive the Partner
        </ReactTooltip>
        <ReactTooltip id="reinstateTooltip" place="top" effect="solid">
          Reinstate the Partner
        </ReactTooltip>
      </td>
    </tr>
  );
};

const PartnersAdminList: React.FC = () => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const { data: partners } = useSWR(API.PARTNERS().url, callGetPartners);
  if (!partners) return <LoadingOverlay />;

  const sortedPartners = sortPartners(partners, sortField, order);

  return (
    <div>
      <PageHeader title="Partners" />
      <PaginatedList<Partner>
        listType={ListType.TABLE}
        items={sortedPartners}
        sortField={sortField}
        setSortField={setSortField}
        order={order}
        setOrder={setOrder}
        pageSize={20}
        renderItem={renderItem}
        tableColumns={[
          { label: "Name", accessor: "name", sortable: true, reset: false },
          { label: "ID", accessor: "id", sortable: true, reset: false },
          {
            label: "Contact",
            accessor: "contact",
            sortable: true,
            reset: false,
          },
          {
            label: "Joined",
            accessor: "created",
            sortable: true,
            reset: false,
          },
          {
            label: "Status",
            accessor: "archived",
            sortable: true,
            reset: false,
          },
          { label: "", accessor: "empty1", sortable: false, reset: false },
          { label: "", accessor: "empty2", sortable: false, reset: false },
        ]}
      />
    </div>
  );
};

export default PartnersAdminList;
