import { Middleware } from "@reduxjs/toolkit";
import { go } from "modules/navigation/helpers/navigator";
import { getWorkflowDetailsRoute } from "modules/navigation/routes";
import Workflow from "modules/workflow/types/Workflow";
import { openClientWorkflow } from "./actions";

const createNotificationMiddleware: () => Middleware =
  () =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case openClientWorkflow.type: {
        // Navigate after Redux store gets updated
        setTimeout(() => {
          const wf: Workflow = action.payload.workflow;
          const pageId = action.payload.pageId;
          const pageIdx: number | undefined = action.payload.pageIdx;
          go(dispatch, getWorkflowDetailsRoute(wf.id, pageIdx, pageId));
        }, 0);
      }
    }

    next(action);
  };

export default createNotificationMiddleware;
