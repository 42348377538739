import Fieldset, { FieldsetRow } from "components/Forms/Fieldset";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
import SanitizeHtml from "components/SanitizeHtml";
import { keyBy } from "lodash";
import Client from "modules/parties/types/Client";
import {
  Question,
  QuestionnaireSection,
  QuestionsMap,
} from "modules/workflow/types/Questionnaire";
import React from "react";
import GenericQuestion, { GenericQuestionProps } from "../GenericQuestion";
import interpolate from "utils/interpolate";

export type FormContentProps = Pick<GenericQuestionProps, "questionActions"> & {
  client?: Client;
  spouse?: Client;
  disabled?: boolean;
  sections: QuestionnaireSection[];
  questions: Question[];
  pageId?: string;
};

const FormContent: React.FC<FormContentProps> = ({
  client,
  spouse,
  disabled,
  pageId,
  questionActions,
  questions,
  sections,
}) => {
  const questionsMap: QuestionsMap = keyBy(questions, "id");

  const interpolateFormContent = (s: string) =>
    client !== undefined ? interpolate(s, client, spouse) : s;

  return (
    <>
      {sections.map((section, idx) => (
        <Fieldset
          key={`${pageId}_${idx}`}
          title={interpolateFormContent(section.caption)}
          text={
            <SanitizeHtml
              html={interpolateFormContent(section.description || "")}
            />
          }
        >
          {section.questions?.map((row, idx) => {
            const rowQuestions = typeof row === "string" ? [row] : row;

            return (
              <FieldsetRow key={`row${idx}`}>
                {rowQuestions.map((questionID) => (
                  <GenericQuestion
                    key={questionID}
                    question={questionsMap[questionID]}
                    size={
                      rowQuestions.length > 2
                        ? QuestionSizes.SMALL
                        : rowQuestions.length > 1
                          ? QuestionSizes.MEDIUM
                          : undefined
                    }
                    questionActions={questionActions}
                    client={client}
                    spouse={spouse}
                    disabled={disabled}
                  />
                ))}
              </FieldsetRow>
            );
          })}
        </Fieldset>
      ))}
    </>
  );
};

export default FormContent;
