import LoadingOverlay from "components/LoadingOverlay";
import Partner from "modules/parties/types/Partner";
import useDefinition from "modules/globalAdmin/hooks/useDefinition";
import React from "react";
import ProductSetup from "modules/partner/settings/components/ProductSetupNavigator";

type Props = { partner: Partner };

const PartnersAdminScreenProducts: React.FC<Props> = ({ partner }) => {
  const { data, refetchDefinition } = useDefinition(partner.id);

  if (!data) return <LoadingOverlay />;

  const { definition, descriptions, addonJurisdictions } = data;

  return (
    <ProductSetup
      definition={definition}
      descriptions={descriptions}
      afterSave={refetchDefinition}
      partner={partner}
      addonJurisdictions={addonJurisdictions}
    />
  );
};

export default PartnersAdminScreenProducts;
