import * as React from "react";
import { useEffect } from "react";
import { FieldArray, useField } from "formik";
import isEqual from "lodash/isEqual";
import { useId } from "react-id-generator";
import classNames from "classnames/bind";
import Client from "modules/parties/types/Client";
import { GenericParty, getGenericPartyId } from "modules/parties/types/Party";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

import styles from "./styles.module.scss";
import QuestionPerPartyItem from "./QuestionPerPartyItem";
import QuestionWrapper, { QuestionSizes } from "../QuestionWrapper";
import { AnswerValueType } from "modules/workflow/types/Answer";
const cx = classNames.bind(styles);

export type PartyValue = {
  partyId: string;
  value: AnswerValueType;
};

type QuestionPerPartyProps = {
  client?: Client;
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  parties: GenericParty[];
  renderMetaInput: (id: string) => JSX.Element;
};

const QuestionPerParty: React.FunctionComponent<QuestionPerPartyProps> = ({
  client,
  disabled,
  help,
  info,
  label,
  renderMetaInput,
  name,
  parties,
}) => {
  const [questionId] = useId(1, "question-");
  const [, meta, helpers] = useField<PartyValue[]>(name);

  useEffect(() => {
    const values = parties.map(function (party): PartyValue {
      const partyId = getGenericPartyId(party);
      return {
        partyId,
        value:
          meta.value?.find((partyValue) => partyValue.partyId === partyId)
            ?.value || "",
      };
    });
    if (!isEqual(meta.value, values)) {
      helpers.setValue(values);
    }
  });

  const values = meta.value || [];
  const error =
    !disabled && meta.touched && meta.error ? meta.error : undefined;

  return values.length > 0 ? (
    <QuestionWrapper
      className="question--share-distribution"
      disabled={disabled}
      help={help}
      id={questionId}
      info={info}
      label={label}
      name={name}
      size={QuestionSizes.FULLWIDTH}
    >
      <FieldArray
        name={name}
        render={() => (
          <ul className={cx("question-per-party-meta")}>
            {values.map((value, index) => {
              const party = parties.find(
                (party) => getGenericPartyId(party) === value.partyId,
              );
              const valueName = `${name}.${index}.value`;

              return (
                party && (
                  <QuestionPerPartyItem
                    client={client}
                    disabled={disabled}
                    error={Boolean(
                      error &&
                        ((Array.isArray(error) &&
                          error.find((item, i) => i === index)) ||
                          typeof error === "string"),
                    )}
                    id={index === 0 ? questionId : undefined}
                    key={getGenericPartyId(party)}
                    name={valueName}
                    party={party}
                    metaInput={renderMetaInput(valueName)}
                  />
                )
              );
            })}
          </ul>
        )}
      />
    </QuestionWrapper>
  ) : (
    <div className={cx("question-per-party-meta__empty")}>
      <p>No beneficiaries selected...</p>
    </div>
  );
};

export default QuestionPerParty;
