import React from "react";

import PageHeader from "components/PageHeader";
import PartnerConfigurationForm from "modules/partner/settings/components/PartnerConfigurationForm";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";
import LoadingOverlay from "components/LoadingOverlay";
import Alert, { AlertType } from "components/Alert";

const PartnerSetupScreen: React.FunctionComponent = () => {
  const { partner } = useSelector((state) => state, shallowEqual);

  return partner ? (
    <div>
      <PageHeader title="Partner Site Configuration" />
      {!partner.data.enabled && (
        <Alert
          type={AlertType.WARNING}
          message="Your law firm is not publicly accessible."
        />
      )}
      <PartnerConfigurationForm {...{ partner }} />
    </div>
  ) : (
    <LoadingOverlay />
  );
};

export default PartnerSetupScreen;
