import { mapValues, uniq } from "lodash";
import { QuestionnaireDefinitionsPayloadMap } from "modules/api/types/WorkflowDetailsPayload";
import { AnswersMap } from "../types/Answer";
import Condition, {
  isAddonCondition,
  isAllCondition,
  isAnyCondition,
  isNotCondition,
  isWorkflowCondition,
} from "../types/Condition";
import { isChildCondition } from "../types/Condition/ChildCondition";
import {
  isClientCondition,
  isSpouseCondition,
} from "../types/Condition/ClientDetailsCondition";
import Questionnaire, {
  Question,
  QuestionID,
  QuestionIdentifier,
  QuestionnaireID,
  QuestionnairePage,
  QuestionType,
} from "../types/Questionnaire";
import { parseStoredValue } from "./validation";

export const parseConditionFromPayload = (
  questionnaireId: string,
  condition: Condition,
): Condition => {
  if (isAnyCondition(condition))
    return {
      any: condition.any.map((conditionCase) =>
        parseConditionFromPayload(questionnaireId, conditionCase),
      ),
    };

  if (isAllCondition(condition))
    return {
      all: condition.all.map((conditionCase) =>
        parseConditionFromPayload(questionnaireId, conditionCase),
      ),
    };

  if (isNotCondition(condition))
    return { not: parseConditionFromPayload(questionnaireId, condition.not) };

  if (
    isAddonCondition(condition) ||
    isClientCondition(condition) ||
    isSpouseCondition(condition) ||
    isChildCondition(condition) ||
    isWorkflowCondition(condition)
  )
    return condition;

  return { [questionnaireId]: condition as AnswersMap };
};

export const parseQuestionFromPayload = (
  questionnaireID: string,
  question: Question,
): Question => {
  if (question.data?.condition) {
    question.data.condition = parseConditionFromPayload(
      questionnaireID,
      question.data.condition,
    );
  }

  switch (question.data?.definition.type) {
    case QuestionType.RELATIONSHIP: {
      if (question.data.definition.excludeByQuestionIds) {
        // Only an exclusion from the same questionnaire is supported - BE provides an array of question IDs
        const questionIDs: QuestionID[] = question.data.definition
          .excludeByQuestionIds as any;
        const parsedExcludeDefinition: QuestionIdentifier[] = questionIDs.map(
          (questionID) => ({
            questionID,
            questionnaireID,
          }),
        );

        return {
          ...question,
          data: {
            ...question.data,
            defaultValue: question.data.defaultValue
              ? parseStoredValue(
                  question.data.definition.type,
                  question.data.defaultValue,
                )
              : question.data.defaultValue,
            definition: {
              ...question.data.definition,
              excludeByQuestionIds: parsedExcludeDefinition,
            },
          },
        };
      }
      return question;
    }

    default:
      return question;
  }
};

export const parsePageFromPayload = (
  questionnaireId: string,
  page: QuestionnairePage,
  idx: number,
): QuestionnairePage => {
  const pageIdx = String(page.index !== undefined ? page.index : idx + 1);
  const pageDetails = {
    ...page,
    id: `${questionnaireId}_${pageIdx}`,
    questionnaireId,
    sections: (page.sections || []).map((section) =>
      section.condition
        ? {
            ...section,
            condition: parseConditionFromPayload(
              questionnaireId,
              section.condition,
            ),
          }
        : section,
    ),
  };

  if (pageDetails.condition) {
    pageDetails.condition = parseConditionFromPayload(
      questionnaireId,
      pageDetails.condition,
    );
  }

  return pageDetails;
};

export const parseQuestionnairesFromPayload = (
  questionnairesMap: QuestionnaireDefinitionsPayloadMap,
): Record<QuestionnaireID, Questionnaire> =>
  mapValues(questionnairesMap, (questionnaireDefinition) => ({
    ...questionnaireDefinition.questionnaire,
    pages:
      // Assign page IDs
      questionnaireDefinition.questionnaire.pages.map((page, idx) =>
        parsePageFromPayload(
          page.questionnaireId || questionnaireDefinition.questionnaire.id,
          page,
          idx,
        ),
      ),

    questions: mapValues(questionnaireDefinition.questions, (question) =>
      parseQuestionFromPayload(
        questionnaireDefinition.questionnaire.id,
        question,
      ),
    ),
  }));

export const pickQuestionnaires = (
  questionnairesMap: Record<QuestionnaireID, Questionnaire>,
  orderedProductsQuestionnaireIDs: string[],
): Questionnaire[] =>
  uniq(orderedProductsQuestionnaireIDs)
    .map((questionnaireId) => questionnairesMap[questionnaireId])
    .filter(Boolean);
