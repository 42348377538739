import { ValueConditionConstraint, ValueConstraintType } from ".";

type SelectedMoreThanConstraint = {
  type: ValueConstraintType.SelectedMoreThan;
  selectedMoreThan: number;
};

export const isSelectMoreThanCondition = (
  valueConstraint: ValueConditionConstraint,
): valueConstraint is SelectedMoreThanConstraint =>
  (valueConstraint as any).type === ValueConstraintType.SelectedMoreThan;

export default SelectedMoreThanConstraint;
