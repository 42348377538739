import { createAction, Dispatch } from "@reduxjs/toolkit";
import {
  CreatePaymentMethodCardData,
  PaymentIntent,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { API, post } from "modules/api";
import { ErrorType } from "modules/api/types/ErrorResponsePayload";
import InitPaymentResponseType, {
  PaymentResponseStatus,
} from "modules/api/responseTypes/InitPaymentResponseType";
import Payment, {
  PaymentAction,
  PaymentActionType,
  SkippedPayment,
  UpdatePaymentRequest,
} from "modules/api/types/Payment";
import { Step } from "modules/api/types/WorkflowPayload";
import { getWorkflowDetailsRoute } from "modules/navigation/routes";
import {
  ProductSelector,
  ProductSelectorType,
} from "modules/product/types/ProductSelector";
import {
  callWorkflowDetailsAPI,
  newWorkflowCreated,
  workflowStateChanged,
} from "modules/workflow/actions";
import { AnswersMap } from "modules/workflow/types/Answer";
import Workflow, { WorkflowID } from "modules/workflow/types/Workflow";
import BillingInfo from "./types/BillingInfo";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";

export const proceedToPayment = createAction<{ workflowId: string }>(
  "PROCEED_TO_PAYMENT",
);
export const paymentInitializationStarted = createAction(
  "PAYMENT_INITIALIZATION_STARTED",
);
export const paymentInitializationError = createAction<{
  error: any;
  redirectPath: string;
}>("PAYMENT_INITIALIZATION_ERROR");
export const paymentInitialized = createAction<Payment>("PAYMENT_INITIALIZED");
export const paymentSubmissionStarted = createAction(
  "PAYMENT_SUBMISSION_STARTED",
);
export const paymentFailed = createAction<any>("PAYMENT_FAILED");
export const paymentDetailsInvalid = createAction<string | undefined>(
  "PAYMENT_DETAILS_INVALID",
);
export const paymentSuccessful = createAction<{
  workflowId?: WorkflowID;
  paymentIntent: PaymentIntent;
}>("PAYMENT_SUCCESSFUL");
export const fetchPaymentStatusInitialized = createAction<{
  paymentId: string;
}>("FETCH_PAYMENT_STATUS_INITIALIZED");
export const fetchPaymentStatusFailed = createAction<{ paymentId: string }>(
  "FETCH_PAYMENT_STATUS_FAILED",
);

export const paymentSkipped = createAction<SkippedPayment>("PAYMENT_SKIPPED");

const getPaymentAction = (
  workflowDefinitionId: string,
  selector: ProductSelector,
  onboardingAnswers: AnswersMap = {},
  addons: string[] = [],
  workflowId?: string,
  repeatedPayment?: boolean,
  userId?: string,
): PaymentAction => {
  if (!workflowId)
    return {
      type: PaymentActionType.PaymentBeforeInitialization,
      createWorkflow: {
        definitionId: workflowDefinitionId,
        selector,
        onboardingAnswers,
        addons,
        userId: userId,
      },
    };

  return {
    type: repeatedPayment
      ? PaymentActionType.RepeatedPayment
      : PaymentActionType.PaymentBeforeRender,
    workflowId,
  };
};

const callInitPaymentAPI = async (
  workflowDefinitionId: string,
  selector: ProductSelector,
  onboardingAnswers: AnswersMap = {},
  addons: string[] = [],
  workflowId?: string,
  coupon?: string,
  repeatedPayment?: boolean,
  userId?: string,
): Promise<Payment | SkippedPayment> => {
  const action = getPaymentAction(
    workflowDefinitionId,
    selector,
    onboardingAnswers,
    addons,
    workflowId,
    repeatedPayment,
    userId,
  );
  const data = { action, ...(coupon ? { promoCode: coupon } : {}) };
  const response: InitPaymentResponseType = await post(API.PAYMENTS(), data);

  if (response.data.responseStatus === PaymentResponseStatus.Skipped) {
    return {
      responseStatus: response.data.payment.responseStatus,
      workflowId: response.data.workflowId,
      promoCode: response.data.promoCode,
    };
  }
  const { payment, secret, allOrderItems } = response.data;
  return { ...payment, workflowId, clientSecret: secret, allOrderItems };
};

export const initPayment =
  (workflow: Workflow, coupon?: string, repeatedPayment?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch(paymentInitializationStarted());
      const payment = await callInitPaymentAPI(
        workflow.definitionId,
        workflow.state.fromPlan
          ? {
              type: ProductSelectorType.PLAN,
              planId: workflow.state.fromPlan,
            }
          : {
              type: ProductSelectorType.PRODUCT,
              productIds: workflow.state.products.map((p) => p.productId),
            },
        undefined,
        workflow.state.enabledAddons || [],
        workflow.id,
        coupon,
        repeatedPayment,
        workflow.userId,
      );
      if (payment.responseStatus === PaymentResponseStatus.Skipped) {
        dispatch(paymentSkipped(payment));
      } else {
        dispatch(paymentInitialized(payment));
      }
    } catch (e) {
      dispatch(
        paymentInitializationError({
          error: e,
          redirectPath: getWorkflowDetailsRoute(
            workflow.id,
            undefined,
            SUMMARY_PAGE_ID,
          ),
        }),
      );
    }
  };

export const validateCouponStarted = createAction("VALIDATE_COUPON_STARTED");
export const couponApplied = createAction<Payment>("COUPON_APPLIED");
export const couponInvalid = createAction<{ error: any }>("COUPON_INVALID");
export const couponValidationFailed = createAction<{ error: any }>(
  "COUPON_VALIDATION_FAILED",
);
export const applyCoupon =
  (workflow: Workflow, coupon: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(validateCouponStarted());
      // Initializes a new payment
      const payment = await callInitPaymentAPI(
        workflow.definitionId,
        workflow.state.fromPlan
          ? { type: ProductSelectorType.PLAN, planId: workflow.state.fromPlan }
          : {
              type: ProductSelectorType.PRODUCT,
              productIds: workflow.state.products.map((p) => p.productId),
            },
        undefined,
        workflow.state.enabledAddons || [],
        workflow.id,
        coupon,
      );
      if (payment.responseStatus === PaymentResponseStatus.Skipped) {
        dispatch(paymentSkipped(payment));
      } else {
        dispatch(couponApplied(payment));
      }
    } catch (err) {
      const error: any = err;
      if (
        error.response?.status === 400 &&
        error.response.data?.short === ErrorType.INVALID_PROMO_CODE
      ) {
        dispatch(couponInvalid({ error }));
        logger.notify(
          NotificationType.ERROR,
          "Provided coupon is not valid",
          error,
        );
      } else {
        dispatch(couponValidationFailed({ error }));
        logger.notify(
          NotificationType.ERROR,
          "We couldn't validate your coupon. Please, try again",
          error,
        );
      }
    }
  };

export const submitPayment =
  (
    stripe: Stripe,
    workflowId: WorkflowID | undefined,
    stripeClientSecret: string,
    cardInfo: StripeCardElement | StripeCardNumberElement,
    billingInfo: BillingInfo,
  ) =>
  async (dispatch: Dispatch) => {
    const {
      name,
      address: { line1, line2, city, state, zip },
    } = billingInfo;

    const paymentDetails: CreatePaymentMethodCardData = {
      billing_details: {
        name,
        address: { line1, line2, city, postal_code: zip, state },
      },
      type: "card",
      card: cardInfo,
    };

    dispatch(paymentSubmissionStarted());
    try {
      const { paymentIntent: confirmedPaymentIntent, error } =
        await stripe.confirmCardPayment(stripeClientSecret, {
          payment_method: paymentDetails,
        });
      if (error) throw error;
      if (!confirmedPaymentIntent) throw new Error("Payment wasn't confirmed");

      dispatch(
        paymentSuccessful({
          workflowId,
          paymentIntent: confirmedPaymentIntent,
        }),
      );
    } catch (err) {
      const e: any = err;
      if (e?.type === "validation_error") {
        dispatch(paymentDetailsInvalid(e.message));
      } else {
        dispatch(paymentFailed(e));
      }
    }
  };

export const fetchPaymentState =
  (
    paymentId: string,
    workflowId: string,
    options: { asUser?: string } = {},
    maxRetries: number = 5,
    retryTimeoutMs: number = 2000,
  ) =>
  (dispatch: Dispatch) =>
    new Promise<Workflow>(async (resolve, reject) => {
      dispatch(fetchPaymentStatusInitialized({ paymentId }));

      // TODO rewrite without try catch
      for (let retryCount = 0; retryCount < maxRetries; retryCount++) {
        try {
          await new Promise((resolve) => setTimeout(resolve, retryTimeoutMs)); // sleep

          const workflow = await callWorkflowDetailsAPI(
            workflowId,
            options.asUser,
          );
          if (!workflow) {
            throw new Error("No workflow has been retrieved");
          }

          switch (workflow.state.steps.current.step) {
            case Step.AwaitingEngagementCheck:
            case Step.AwaitingLawyerReview:
            case Step.DocumentsReady:
            case Step.ContactCustomer:
              dispatch(
                workflowStateChanged({
                  workflowId: workflow.id,
                  state: workflow.state,
                }),
              );
              break;

            case Step.Initialized:
              dispatch(newWorkflowCreated(workflow));
              break;

            default:
              throw new Error(
                `Unexpected workflow step: ${workflow.state.steps.current.step}`,
              );
          }
          resolve(workflow);
          return;
        } catch (e) {
          logger.debug(e);
        }
      }
      dispatch(fetchPaymentStatusFailed({ paymentId }));
      reject();
    });

export const triggerWorkflowPayment =
  (workflow: Workflow) => (dispatch: Dispatch) => {
    dispatch(proceedToPayment({ workflowId: workflow.id }));
  };

export const callUpdatePaymentAPI = async (
  updatePaymentRequest: UpdatePaymentRequest,
  payload?: any,
): Promise<void> => {
  await post(
    API.PAYMENT_INSTANCE({ paymentId: updatePaymentRequest.paymentId }),
    {
      ...updatePaymentRequest,
      ...payload,
    },
  );
};

export const updatePaymentInitialized = createAction<string>(
  "UPDATE_PAYMENT_INITIALIZED",
);
export const paymentUpdated = createAction<Payment>("PAYMENT_UPDATED");
export const updatePaymentFailed = createAction<any>("UPDATE_PAYMENT_FAILED");

export const notifyPaymentSuccess =
  (updatePaymentRequest: UpdatePaymentRequest) =>
  async (dispatch: Dispatch) => {
    dispatch(updatePaymentInitialized(updatePaymentRequest.paymentId));
    try {
      await callUpdatePaymentAPI(updatePaymentRequest);
    } catch (e) {
      logger.error("Update payment failed:", e);
      dispatch(updatePaymentFailed(e));
    }
  };
