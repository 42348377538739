import React, { useCallback, useEffect, useState, useContext } from "react";
import Partner from "modules/parties/types/Partner";
import { getFullAddress } from "modules/parties/types/Address";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import Link from "components/Link";
import Icon, { IconType } from "components/Icons";
import DataLayerContext from "contexts/DataLayerContext";
import { EventName, FirmAction } from "contexts/DataLayerContext/types";

const cx = classNames.bind(styles);

type SearchResultItemProps = {
  isShowingDetails?: boolean;
  onSelectLawFirm: (partnerId: string) => void;
  onShowDetails: (partnerId: string) => void;
  partner: Partner;
  style?: object;
  searchResultsListPosition: number;
};

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  isShowingDetails,
  onSelectLawFirm,
  onShowDetails,
  partner,
  style = {},
  searchResultsListPosition,
}) => {
  const { id, distance, data } = partner;
  const [isVisible, setVisible] = useState(false);
  const { sendEvent } = useContext(DataLayerContext);

  useEffect(() => setVisible(true), []);

  const handleSelectLawFirm = useCallback(() => {
    sendEvent?.({
      event: EventName.SearchEngagement,
      firm_action: FirmAction.SELECTED,
      list_position: searchResultsListPosition,
      selected_firm: id,
    });

    onSelectLawFirm(id);
  }, [id, onSelectLawFirm, sendEvent, searchResultsListPosition]);

  const handleTileClicked = useCallback(() => {
    sendEvent?.({
      event: EventName.SearchEngagement,
      firm_action: FirmAction.CLICKED,
      list_position: searchResultsListPosition,
      selected_firm: id,
    });

    onShowDetails(id);
  }, [id, onShowDetails, sendEvent, searchResultsListPosition]);

  const handleDirectionsRequest = useCallback(() => {
    sendEvent?.({
      event: EventName.SearchEngagement,
      firm_action: FirmAction.DIRECTIONS,
      list_position: searchResultsListPosition,
      selected_firm: id,
    });

    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        [data.location!.latitude, data.location!.longitude].join(","),
      )}`,
    );
  }, [data.location, id, searchResultsListPosition, sendEvent]);

  return (
    <li
      className={cx("search-result", {
        "search-result--hidden": !isVisible,
        "search-result--focused": isShowingDetails,
      })}
      style={style}
      onClick={handleTileClicked}
    >
      <header>
        <h1>{data.displayName}</h1>
        {distance !== undefined ? (
          <div className={cx("search-result__distance")}>
            {distance.toFixed(1)}mi
          </div>
        ) : null}
      </header>
      <div className={cx("search-result__info", "search-result__address")}>
        <Icon
          className={cx("search-result__info__icon")}
          type={IconType.LOCATION}
        />
        <div>{getFullAddress(data.address)}</div>
      </div>
      <div className={cx("search-result__info", "search-result__contact")}>
        <Icon
          className={cx("search-result__info__icon")}
          type={IconType.LANDLINE}
        />
        <div>{data.contact.workPhone}</div>
      </div>
      <div className={cx("search-result__actions")}>
        <div className={cx("search-result__action")}>
          <Link onClick={handleSelectLawFirm}>Select law firm</Link>
        </div>
        <div
          className={cx(
            "search-result__action",
            "search-result__action__secondary",
          )}
        >
          {data.location?.latitude !== undefined &&
          data.location.longitude !== undefined ? (
            <Link onClick={handleDirectionsRequest}>Directions</Link>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default SearchResultItem;
