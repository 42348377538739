import RelationshipRepeater from "components/Forms/RelationshipRepeater";
import PageHeader from "components/PageHeader";
import Separator from "components/Separator";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import Client from "modules/parties/types/Client";
import Party from "modules/parties/types/Party";
import React from "react";

type PeopleRegistryScreenProps = {
  client?: Client;
  isEditable: boolean;
  onBack: () => void;
  onDeleteParty: (party: Party) => void;
  onEditParty: (party: Party) => void;
  relatedParties: Party[];
};

const PeopleRegistryScreen: React.FunctionComponent<
  PeopleRegistryScreenProps
> = ({
  client,
  isEditable,
  onBack,
  onDeleteParty,
  onEditParty,
  relatedParties,
}) => {
  return (
    <div>
      <PageHeader title="Existing people" />
      <RelationshipRepeater
        client={client}
        isEditable={isEditable}
        detailed
        parties={relatedParties}
        onEditParty={onEditParty}
        onRemoveParty={onDeleteParty}
      />
      <Separator />
      <TabbedButton style={TabbedButtonType.SECONDARY} onClick={onBack}>
        Return to last page
      </TabbedButton>
    </div>
  );
};

export default PeopleRegistryScreen;
