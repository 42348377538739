import TabbedButton from "components/TabbedButton";
import DataLayerContext from "contexts/DataLayerContext";
import { getEcommerceItemsFromOrderItems } from "contexts/DataLayerContext/helpers";
import { EventName } from "contexts/DataLayerContext/types";
import { PaymentStatus } from "modules/api/types/Payment";
import getCouponName from "modules/payment/utils/getCouponName";
import React, { useContext, useEffect } from "react";
import { useSelector } from "redux/reducer";
import PlaceholderScreen, {
  PlaceholderScreenMood,
} from "screens/core/PlaceholderScreen";

type PaymentScreenProps = {
  title?: string;
  isLoading?: boolean;
  isAdmin?: boolean;
  actionTitle?: string;
  onClickAction?: () => void;
  skippedPayment?: boolean;
};

const PAYMENT_SUCCESS_MSG = `A hold for the amount of your plan has been placed on your card, until
  the law firm accepts the engagement agreement. You will receive an email when the law firm has
  reviewed your plan.`;

const PaymentProcessingScreen = ({
  title,
  isLoading,
  isAdmin,
  actionTitle,
  onClickAction,
  skippedPayment,
}: PaymentScreenProps) => {
  const activePayment = useSelector((state) => state.activePayment);
  const partnerId = useSelector((state) => state.partner?.id);
  const { sendEvent } = useContext(DataLayerContext);

  useEffect(() => {
    if (!isAdmin && activePayment?.status === PaymentStatus.Success) {
      const couponName =
        activePayment.allOrderItems !== undefined
          ? getCouponName(activePayment.allOrderItems) || ""
          : "";

      sendEvent?.({
        event: EventName.Purchase,
        currency: activePayment.currency,
        transaction_id: activePayment.externalId,
        value: activePayment.amount,
        coupon: couponName,
        items: getEcommerceItemsFromOrderItems(
          activePayment.allOrderItems,
          partnerId,
        ),
      });
    }
  }, [
    activePayment?.allOrderItems,
    activePayment?.amount,
    activePayment?.currency,
    activePayment?.externalId,
    activePayment?.status,
    isAdmin,
    sendEvent,
    partnerId,
  ]);

  const renderAction =
    onClickAction && actionTitle
      ? () => <TabbedButton onClick={onClickAction}>{actionTitle}</TabbedButton>
      : undefined;

  const getLoadedTitle = (isAdmin?: boolean, skippedPayment?: boolean) =>
    skippedPayment
      ? isAdmin
        ? "The transaction was successful."
        : "Your transaction was successful."
      : isAdmin
        ? "The payment was successful."
        : "Your payment is complete.";

  const getLoadingTitle = (isAdmin?: boolean, skippedPayment?: boolean) =>
    skippedPayment
      ? isAdmin
        ? "We are processing the transaction."
        : "We are processing your transaction."
      : isAdmin
        ? "We are processing the payment."
        : "We are processing your payment.";

  return !isLoading ? (
    <PlaceholderScreen
      title={title || getLoadedTitle(isAdmin, skippedPayment)}
      subtitle={isAdmin ? "Please proceed with review." : "Thank you!"}
      message={!isAdmin && !skippedPayment ? PAYMENT_SUCCESS_MSG : ""}
      mood={PlaceholderScreenMood.SUCCESS}
      renderAction={renderAction}
    />
  ) : (
    <PlaceholderScreen
      title={getLoadingTitle(isAdmin, skippedPayment)}
      subtitle={"Please wait..."}
      mood={PlaceholderScreenMood.NEUTRAL}
      renderAction={renderAction}
    />
  );
};

export default PaymentProcessingScreen;
