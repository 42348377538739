import { isAdmin } from "modules/auth/helper";
import { PartnerContext } from "modules/partner/context/PartnerContext";
import * as React from "react";
import { User } from "../../../modules/auth/User";

import styles from "./styles.module.scss";

type HeaderAccountModalInformationProps = {
  user: User;
};

const HeaderAccountModalInformation: React.FunctionComponent<
  HeaderAccountModalInformationProps
> = ({ user }) => {
  const partner = React.useContext(PartnerContext);
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // logger.debug(event.currentTarget.files);
  }

  // TODO needs user avatar image
  let style;
  //let image = "/images/avatar/avatar@2x.png";
  //let style = {
  //  backgroundImage: "url(" + image + ")",
  //};

  return (
    <div className={styles["header-account-modal__information"]}>
      <div className={styles["header-account-modal__information-avatar"]}>
        <input
          type="file"
          className={styles["header-account-modal__information-avatar-upload"]}
          accept="image/png, image/jpeg"
          aria-label="Edit account image"
          onChange={handleChange}
        />
        <div
          className={styles["header-account-modal__information-avatar-img"]}
          style={style}
        ></div>
      </div>
      <p
        className={styles["header-account-modal__information-name"]}
        data-clarity-mask="true"
      >
        {user.displayName}
      </p>
      <p className={styles["header-account-modal__information-position"]}>
        {isAdmin(partner?.id, user) ? "Administrator" : ""}
      </p>
    </div>
  );
};

export default HeaderAccountModalInformation;
