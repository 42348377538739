import Addon, { AddonID } from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";
import { ProductPrice } from "modules/product/types/Price";
import { QuestionDefinition } from "modules/workflow/types/Questionnaire";

export const DEFINITION_CATEGORY_PRODUCTS = "products";
export const DEFINITION_CATEGORY_PLANS = "plans";
export const DEFINITION_CATEGORY_ADDONS = "addons";

export const definitionCategories = [
  DEFINITION_CATEGORY_PRODUCTS,
  DEFINITION_CATEGORY_PLANS,
  DEFINITION_CATEGORY_ADDONS,
] as const;

export type DefinitionCategory = (typeof definitionCategories)[number];

export enum DefinitionStatus {
  AVAILABLE = "Available",
  HIDDEN = "Hidden",
}

export type KioskProductDescription = {
  id: string;
  name: string;
  description: string;
  validJurisdictions: string[];
  updated: string;
  created: string;
  defaults: DefaultDescription[];
  specificDefaults: Record<string, DefaultDescription[]>;
  stateSpecificDefinitions: Record<string, string[]>;
  external: boolean;
};

export type DefaultDescription = {
  definition: QuestionDefinition;
  description: string;
  key: string;
  label: string;
  defaultValue?: any;
};

export type ApplicableJurisdiction =
  | { type: "Generic" }
  | { type: "Specific"; value: string };

export type ListDefinitionsResponse = {
  definitions: Record<string, Definition>;
  addons: Record<string, Addon>;
  addonJurisdictions: Record<AddonID, ApplicableJurisdiction[]>;
  plans: Record<string, Plan>;
  products: Record<string, KioskProductDescription>;
};

export type DefinitionDescriptions = {
  [DEFINITION_CATEGORY_ADDONS]: Record<string, Addon>;
  [DEFINITION_CATEGORY_PLANS]: Record<string, Plan>;
  [DEFINITION_CATEGORY_PRODUCTS]: Record<string, KioskProductDescription>;
};

export type Definition = {
  id: string;
  partnerId: string;
  status: DefinitionStatus;
  data: DefinitionData;
  created: string;
  updated: string;
};

export type DefinitionData = {
  [DEFINITION_CATEGORY_PRODUCTS]: Record<string, DefinitionDataProduct>;
  [DEFINITION_CATEGORY_PLANS]: Record<string, DefinitionDataPlan>;
  [DEFINITION_CATEGORY_ADDONS]: Record<string, DefinitionDataAddon>;
};

export type DefinitionDataAddon = {
  enabled: boolean;
};

export type DefinitionDataPlan = {
  enabled: boolean;
  price: ProductPrice;
};

export type DefinitionDataProduct = {
  enabled: boolean;
  price: ProductPrice;
  defaults: DefinitionDefaults;
  stateSpecificDefaults: Record<string, DefinitionDefaults>;
  external: boolean;
};

export type DefinitionDefaults = Record<string, any>;
