import React from "react";
import * as yup from "yup";

import { AddressSearchResultsType } from "modules/address/actions";
import AddressType from "modules/parties/types/Address";

import PageHeader from "components/PageHeader";
import Fieldset, { FieldsetRow } from "components/Forms/Fieldset";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
import QuestionAddress from "components/Forms/Questions/QuestionAddress";
import QuestionInput from "components/Forms/Questions/QuestionInput";
import { InputType } from "components/Forms/Inputs/Input/types";

import Payment from "../../modules/payment/components/Payment";
import QuestionCardNumber from "components/Forms/Questions/QuestionCardNumber";
import QuestionCardExpiry from "components/Forms/Questions/QuestionCardExpiry";
import QuestionCardCVV from "components/Forms/Questions/QuestionCardCVV";
import {
  getFieldInitialValue,
  getFieldValidationSchema,
} from "modules/workflow/helpers/validation";
import { QuestionID, QuestionType } from "modules/workflow/types/Questionnaire";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import BillingInfo from "modules/payment/types/BillingInfo";
import Address from "modules/parties/types/Address";
import { PaymentOrderItem } from "modules/api/types/Payment";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { getFullName } from "modules/parties/types/Party";
import Client, { ClientType } from "modules/parties/types/Client";
import QuestionConfirm from "components/Forms/Questions/QuestionConfirm";
import { ModalType, showModal } from "components/Modal";
import EngagementAgreement from "modules/payment/components/EngagementAgreement";
import Partner from "modules/parties/types/Partner";
import { STATES_MAP } from "resources/options/states";
import Link from "components/Link";

import styles from "./PaymentScreen.module.scss";

const JOINT_AGREEMENT = false;

type PaymentScreenProps = {
  allOrderItems?: PaymentOrderItem[];
  availableAddresses: Address[];
  clients?: ClientsMap;
  coupledWorkflowId?: string;
  isSubmitting?: boolean;
  jurisdiction?: string;
  onAddressLookup: (addressId: string) => Promise<AddressType | undefined>;
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>;
  onPaymentFormInvalid?: () => void;
  onSubmit: (
    cardInfo?: StripeCardNumberElement,
    billingInfo?: BillingInfo,
  ) => any;
  orderItems?: PaymentOrderItem[];
  partner?: Partner;
  paymentInfo?: JSX.Element | JSX.Element[];
  supervisedSigning: boolean;
  hasExternalProducts: boolean;
  totalPrice?: number;
  shouldSkipPaymentDetails?: boolean;
};

const PaymentScreen = ({
  allOrderItems,
  availableAddresses,
  clients,
  coupledWorkflowId,
  isSubmitting,
  jurisdiction,
  onAddressLookup,
  onAddressSearch,
  onPaymentFormInvalid,
  onSubmit,
  orderItems,
  partner,
  paymentInfo,
  supervisedSigning,
  hasExternalProducts,
  totalPrice,
  shouldSkipPaymentDetails,
}: PaymentScreenProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const mainClient = clients?.[ClientType.MAIN];
  const spouse = coupledWorkflowId ? clients?.[ClientType.SPOUSE] : undefined;

  const handleSubmit = async (values: Record<QuestionID, any>) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // In this case, PaymentScreenContainer should not have rendered PaymentScreen at all.
      return;
    }

    const card = elements.getElement(CardNumberElement);
    if (card) {
      onSubmit(card, {
        name: values.cardHolder as string,
        address: { id: "billing", ...values.billingAddress } as Address,
      });
    } else if (shouldSkipPaymentDetails) {
      onSubmit();
    }
  };

  const defaultPaymentDetailsState = {
    cardHolder: getFieldInitialValue("cardHolder", QuestionType.TEXT),
    billingAddress: getFieldInitialValue(
      "billingAddress",
      QuestionType.ADDRESS,
    ),
  };

  const defaultState = {
    ...(shouldSkipPaymentDetails ? {} : defaultPaymentDetailsState),
    confirmEAClient: getFieldInitialValue(
      "confirmEAClient",
      QuestionType.CONFIRM,
    ),
    ...(spouse
      ? {
          confirmEASpouse: getFieldInitialValue(
            "confirmEASpouse",
            QuestionType.CONFIRM,
          ),
        }
      : {}),
  };

  const paymentDetailsValidationSchemaShape = {
    cardHolder: getFieldValidationSchema({ type: QuestionType.TEXT }, true),
    billingAddress: getFieldValidationSchema(
      { type: QuestionType.ADDRESS },
      true,
    ),
  };

  const validationSchema = yup.object().shape({
    ...(shouldSkipPaymentDetails ? {} : paymentDetailsValidationSchemaShape),
    ...(mainClient
      ? {
          confirmEAClient: getFieldValidationSchema(
            {
              type: QuestionType.CONFIRM,
              errorMessage:
                "You need to accept the Engagement Agreement to proceed",
            },
            true,
          ),
        }
      : {}),
    ...(spouse
      ? {
          confirmEASpouse: getFieldValidationSchema(
            {
              type: QuestionType.CONFIRM,
              errorMessage:
                "You need to accept the Engagement Agreement to proceed",
            },
            true,
          ),
        }
      : {}),
  });

  const previewJointEngagementAgreement = () => {
    showModal({
      type: ModalType.CONFIRM,
      title: "Limited Scope Engagement Agreement",
      cancellable: true,
      labelCancel: "Close",
      renderContent: () => (
        <EngagementAgreement
          clients={clients}
          orderItems={allOrderItems}
          jurisdiction={STATES_MAP[jurisdiction || ""] || "N/A"}
          partner={partner}
          supervisedSigning={supervisedSigning}
          hasExternalProducts={hasExternalProducts}
        />
      ),
    });
  };

  const previewEngagementAgreement = (client: Client) => {
    showModal({
      type: ModalType.CONFIRM,
      title: "Limited Scope Engagement Agreement",
      cancellable: true,
      labelCancel: "Close",
      renderContent: () => (
        <EngagementAgreement
          clients={{ main: client, spouse: undefined }}
          orderItems={allOrderItems}
          jurisdiction={STATES_MAP[jurisdiction || ""] || "N/A"}
          partner={partner}
          supervisedSigning={supervisedSigning}
          hasExternalProducts={hasExternalProducts}
        />
      ),
    });
  };

  return (
    <React.Fragment>
      <PageHeader title="Payment details" text="" />

      <Payment
        partner={partner}
        onSubmit={handleSubmit}
        onFormInvalid={onPaymentFormInvalid}
        schema={validationSchema}
        state={defaultState}
        isSubmitting={isSubmitting}
        orderItems={orderItems}
        totalPrice={totalPrice}
        paymentInfo={paymentInfo}
        form={() => (
          <React.Fragment>
            {shouldSkipPaymentDetails || (
              <React.Fragment>
                <Fieldset title="Card details">
                  <FieldsetRow>
                    <QuestionInput
                      label="Cardholder name"
                      name="cardHolder"
                      placeholder="John Smith"
                      type={InputType.TEXT}
                      size={QuestionSizes.LARGE}
                    />
                  </FieldsetRow>
                  <FieldsetRow>
                    <QuestionCardNumber
                      label="Debit/Credit card number"
                      name="cardNumber"
                      size={QuestionSizes.LARGE}
                    />
                  </FieldsetRow>
                  <FieldsetRow>
                    <QuestionCardExpiry
                      label="Expiration date"
                      name="cardExp"
                      size={QuestionSizes.SMALL}
                    />
                    <QuestionCardCVV
                      label="CVC"
                      name="cardCVV"
                      size={QuestionSizes.SMALL}
                    />
                  </FieldsetRow>
                </Fieldset>
                <Fieldset title="Billing address">
                  <FieldsetRow>
                    <QuestionAddress
                      label="Enter the billing address"
                      name="billingAddress"
                      onAddressLookup={onAddressLookup}
                      onAddressSearch={onAddressSearch}
                      availableAddresses={availableAddresses}
                    />
                  </FieldsetRow>
                </Fieldset>
              </React.Fragment>
            )}
            {mainClient ? (
              <Fieldset title="Engagement Agreement">
                <FieldsetRow>
                  {JOINT_AGREEMENT ? (
                    <div className={styles["preview-agreement"]}>
                      <Link onClick={previewJointEngagementAgreement}>
                        View agreement
                      </Link>
                    </div>
                  ) : null}
                  {mainClient ? (
                    JOINT_AGREEMENT ? (
                      <QuestionConfirm
                        name="confirmEAClient"
                        label={`I, ${getFullName(mainClient)}, accept`}
                        maskLabel={true}
                      />
                    ) : (
                      <QuestionConfirm
                        name="confirmEAClient"
                        label={`I, ${getFullName(mainClient)}, accept`}
                        maskLabel={true}
                        extraAction={{
                          label: "View agreement",
                          onTrigger: () =>
                            previewEngagementAgreement(mainClient),
                        }}
                      />
                    )
                  ) : null}
                  {spouse ? (
                    JOINT_AGREEMENT ? (
                      <QuestionConfirm
                        name="confirmEASpouse"
                        label={`I, ${getFullName(spouse)}, accept`}
                        maskLabel={true}
                      />
                    ) : (
                      <QuestionConfirm
                        name="confirmEASpouse"
                        label={`I, ${getFullName(spouse)}, accept`}
                        maskLabel={true}
                        extraAction={{
                          label: "View agreement",
                          onTrigger: () => previewEngagementAgreement(spouse),
                        }}
                      />
                    )
                  ) : null}
                </FieldsetRow>
              </Fieldset>
            ) : null}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
};

export default PaymentScreen;
