import React from "react";

import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import currencyFormatter from "utils/currencyFormatter";
import Icon, { IconType } from "components/Icons";

type AddPartnerDocumentsProps = {
  type?: PartnerDocumentType;
  price: number;
  discount?: number;
  onAccept: () => void;
  showPricing: boolean;
};

export enum PartnerDocumentType {
  HEALTHCARE_PROXY = "Healthcare Proxy",
  HEALTHCARE_DIRECTIVE = "Healthcare Directive",
  WILL = "Will",
  WILL_PLAN = "Essentials Plan",
  POWER_OF_ATTORNEY = "Power Of Attorney",
  MEDICAID = "Medicaid",
}

const getPlanData = (
  type?: PartnerDocumentType,
): {
  productTitle: string;
  icon: IconType;
  description: (
    showPricing: boolean,
    price: number,
    discount?: number,
  ) => string;
} => {
  switch (type) {
    case PartnerDocumentType.WILL_PLAN:
      return {
        productTitle: "the Essentials Plan",
        icon: IconType.ADD_WILL_PLAN,
        description: (showPricing) =>
          showPricing
            ? "Ensure your spouse gets all the protections of the Estate Planning Essentials Plan you chose, at a small additional cost."
            : "Ensure your spouse gets all the protections of the Estate Planning Essentials Plan you chose.",
      };

    case PartnerDocumentType.WILL:
      return {
        productTitle: "a Will",
        icon: IconType.ADD_WILL,
        description: (showPricing, price, discount) =>
          showPricing
            ? `Ensure your spouse’s wishes are protected. Add their will for ${currencyFormatter.format(
                price,
              )}${
                discount
                  ? `, saving you ${currencyFormatter.format(discount)}`
                  : ""
              }.`
            : "Ensure your spouse’s wishes are protected. Add their will",
      };

    case PartnerDocumentType.POWER_OF_ATTORNEY:
      return {
        productTitle: "Power of Attorney documents",
        icon: IconType.ADD_POWER_OF_ATTORNEY,
        description: (showPricing, price, discount) =>
          showPricing
            ? `Ensure your spouse’s legal and financial decisions are efficiently managed. Add their power of attorney for ${currencyFormatter.format(
                price,
              )}${
                discount
                  ? `, saving you ${currencyFormatter.format(discount)}`
                  : ""
              }.`
            : "Ensure your spouse’s legal and financial decisions are efficiently managed. Add their power of attorney",
      };

    case PartnerDocumentType.HEALTHCARE_DIRECTIVE:
    case PartnerDocumentType.HEALTHCARE_PROXY:
      return {
        productTitle: "Health Care documents",
        icon: IconType.ADD_HEALTHCARE_DIRECTIVE,
        description: (showPricing, price, discount) =>
          showPricing
            ? `Ensure you spouse’s healthchare decisions are protected. Add their healthcare documents for ${currencyFormatter.format(
                price,
              )}${
                discount
                  ? `, saving you ${currencyFormatter.format(discount)}`
                  : ""
              }.`
            : "Ensure you spouse’s healthchare decisions are protected. Add their healthcare documents.",
      };

    default:
      return {
        productTitle: "a plan",
        icon: IconType.ADD_WILL_PLAN,
        description: (showPricing, price, discount) =>
          showPricing
            ? `Ensure your spouse’s wishes are protected. Add their plan for ${currencyFormatter.format(
                price,
              )}${
                discount
                  ? `, saving you ${currencyFormatter.format(discount)}`
                  : ""
              }.`
            : "Ensure your spouse’s wishes are protected. Add their plan.",
      };
  }
};

const AddPartnerDocuments: React.FunctionComponent<
  AddPartnerDocumentsProps
> = ({ type, showPricing, price, discount, onAccept }) => {
  const { productTitle, icon, description } = getPlanData(type);

  return (
    <div className={styles["add-partner-documents"]}>
      <div className={styles["add-partner-documents__col"]}>
        <div className={styles["add-partner-documents__icon"]}>
          <Icon type={icon} />
        </div>
        <span>
          {showPricing && (
            <div className={styles["add-partner-documents__price"]}>
              {currencyFormatter.format(price)}
            </div>
          )}
          {showPricing && discount && (
            <div className={styles["add-partner-documents__discount"]}>
              SAVE {currencyFormatter.format(discount)}
            </div>
          )}
        </span>
      </div>
      <div className={styles["add-partner-documents__col"]}>
        <h3 className={styles["add-partner-documents__title"]}>
          Create {productTitle} for your spouse?
        </h3>
        <p className={styles["add-partner-documents__description"]}>
          {description(showPricing, price, discount)}
        </p>
        <TabbedButton onClick={onAccept} label="Add documents for my spouse" />
      </div>
    </div>
  );
};

export default AddPartnerDocuments;
