import React, { useMemo } from "react";
import { AssetProtectionPlanAdditionalProtectionProps } from "./types";
import AssetProtectionChart from "screens/medicaid/components/AssetProtectionChart";
import {
  getCurrentlyProtected,
  getFullMonthsToQualify,
  getTotalAssetsAtRisk,
  getTotalAssetsProtected,
  getTotalProtectedOnceQualified,
} from "screens/medicaidAnalysis/helpers";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const AssetProtectionPlanAdditionalProtection = ({
  calculation,
  showTicks = true,
}: AssetProtectionPlanAdditionalProtectionProps) => {
  const protectedWithoutPlan = useMemo(
    () => getCurrentlyProtected(calculation),
    [calculation],
  );

  const fullMonthsToQualify = useMemo(
    () => getFullMonthsToQualify(calculation),
    [calculation],
  );

  const immediatelyProtectedWithPlan = useMemo(
    () => getTotalAssetsProtected(calculation),
    [calculation],
  );

  const totalProtectedOnceQualified = useMemo(
    () => getTotalProtectedOnceQualified(calculation),
    [calculation],
  );

  const totalAssets = useMemo(
    () => getTotalAssetsAtRisk(calculation),
    [calculation],
  );

  return (
    <div className={cx("asset-protection-chart")}>
      <AssetProtectionChart
        protectedWithoutPlan={protectedWithoutPlan}
        protectedOnSigning={immediatelyProtectedWithPlan}
        protectedOnceQualified={totalProtectedOnceQualified}
        totalAssets={totalAssets}
        fullMonthsToQualify={fullMonthsToQualify}
        percentSaved={calculation.answers.percentSaved}
        showTicks={showTicks}
      />
    </div>
  );
};

export default AssetProtectionPlanAdditionalProtection;
