import { Middleware } from "@reduxjs/toolkit";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import { downloadRenderedDocument } from "../actions";
import { agreementLoaded } from "./actions";

const createAgreementMiddleware: () => Middleware =
  () =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    switch (action.type) {
      case agreementLoaded.type: {
        const documentDetails = action.payload as DownloadableDocumentDetails;
        downloadRenderedDocument(documentDetails)(dispatch);
        break;
      }
    }
  };

export default createAgreementMiddleware;
