import * as React from "react";
import { useId } from "react-id-generator";
import { GenericParty, getGenericPartyId } from "modules/parties/types/Party";
import {
  QuestionHelp,
  QuestionSubquestionnairePreprocessedData,
} from "modules/workflow/types/Questionnaire";
import QuestionWrapper, { QuestionSizes } from "../QuestionWrapper";
import { QuestionActions } from "modules/workflow/components/GenericQuestion";
import { useField } from "formik";
import PartySubquestionnaire from "./PartySubquestionnaire";
import { AnswersMap } from "modules/workflow/types/Answer";
import { PerPartyAnswerType } from "modules/parties/types/PerPartyAnswer";

type PerPartySubquestionnaireProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  parties: GenericParty[];
  questionActions?: QuestionActions;
} & QuestionSubquestionnairePreprocessedData;

const PerPartySubquestionnaire: React.FunctionComponent<
  PerPartySubquestionnaireProps
> = ({
  allAnswers,
  allChildren,
  allQuestions,
  clients,
  disabled,
  help,
  info,
  label,
  name,
  parties,
  partySubquestionnaire,
  questionActions,
}) => {
  const [questionId] = useId(1, "question-per-party-subquestionnaire");
  const [, meta, helpers] = useField(name);

  const onSubquestionnaireChanged = React.useCallback(
    (partyId: string, values: AnswersMap | undefined) => {
      helpers.setValue({
        type: PerPartyAnswerType.QUESTIONNAIRE,
        values: {
          ...(meta.value?.values || {}),
          [partyId]: values,
        },
      });
    },
    [helpers, meta.value],
  );

  return partySubquestionnaire ? (
    <QuestionWrapper
      disabled={disabled}
      help={help}
      id={questionId}
      info={info}
      label={label}
      name={name}
      size={QuestionSizes.FULLWIDTH}
    >
      {parties.map((party) => (
        <PartySubquestionnaire
          key={`${partySubquestionnaire.id}_${getGenericPartyId(party)}`}
          party={party}
          subquestionnaire={partySubquestionnaire}
          allAnswers={{
            ...allAnswers,
            [partySubquestionnaire.id]: (meta.value?.values || {})[
              getGenericPartyId(party)
            ],
          }}
          allChildren={allChildren}
          allQuestions={allQuestions}
          clients={clients}
          disabled={disabled}
          imported={meta.value?.imported}
          onChange={onSubquestionnaireChanged}
          questionActions={questionActions}
          validate={Boolean(meta.error && meta.touched)}
        />
      ))}
    </QuestionWrapper>
  ) : null;
};

export default PerPartySubquestionnaire;
