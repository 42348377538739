import React from "react";
import TabbedButton from "components/TabbedButton";
import { onClickCsvExport } from "./helpers";
import { PartnerFinanceCsvExportProps } from "./types";

const PartnerFinanceCsvExport = ({ period }: PartnerFinanceCsvExportProps) => (
  <div>
    <TabbedButton onClick={() => onClickCsvExport(period)}>
      Export Report
    </TabbedButton>
  </div>
);

export default PartnerFinanceCsvExport;
