import firebase from "firebase/compat/app";
import { Environment, getEnvironment } from "../utils/environment";

const resolveFirebaseConfig = () => {
  const env = getEnvironment();
  if (Environment.PROD === env) {
    return {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
    };
  } else if (Environment.STAGING === env) {
    return {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY_STG,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_STG,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_STG,
    };
  }
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  };
};

firebase.initializeApp(resolveFirebaseConfig());

export default firebase;
