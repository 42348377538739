import { createReducer } from "@reduxjs/toolkit";
import { previewTheme, restoreTheme, themeChanged } from "./actions";
import defaultTheme from "./defaultTheme";
import getCustomizedTheme from "./helpers/getCustomizedTheme";

import Theme from "./types/Theme";

export const theme = createReducer<Theme>(defaultTheme, (builder) =>
  builder.addCase(themeChanged, (_, { payload }) => payload),
);

export const themePreview = createReducer<Theme | null>(null, (builder) =>
  builder
    .addCase(previewTheme, (_, { payload }) => getCustomizedTheme(payload))
    .addCase(restoreTheme, () => null)
    .addCase(themeChanged, () => null),
);
