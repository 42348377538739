import ContentPage from "components/ContentPage";
import React from "react";

import styles from "./styles.module.scss";

const PrivacyPage: React.FC = () => {
  return (
    <div className={styles.policy}>
      <ContentPage title="Privacy Policy">
        <p>Last updated: January 19th, 2024</p>
        <p>
          At Aturna&#8480;, keeping your personal information safe and private
          is our top priority. We go to great lengths to make sure your data is
          secure and protected.
        </p>
        <p>
          This notice describes the Privacy Policy of GUIDR LLC. By using the
          Aturna&#8480; Estate Planning Platform, using the Aturna website,
          downloading documents from Aturna, or engaging in any other form of
          electronic communication or interaction with Aturna (collectively,
          Aturna Services”), you are accepting the terms described in this
          Privacy Policy, as they may be modified from time to time.
        </p>
        <p>
          Aturna is committed to protecting your personal information and your
          right to privacy. This Privacy Policy is intended to help you
          understand our practices for the use, disclosure, and access to the
          information we collect when you interact with Aturna. Please read this
          Privacy Policy carefully as it will help you make informed decisions
          about sharing your personal information with us.
        </p>
        <p>
          By accessing or using any Aturna Services, you agree to this Privacy
          Policy and our Terms of Use. If you do not accept the terms of this
          Privacy Policy or our Terms of Use, you are not authorized to use
          Aturna Services for any purpose.
        </p>
        <p>
          Any information that we collect is subject to the Privacy Policy in
          effect at the time such information is collected. We may, however,
          revise the Privacy Policy from time to time. The current version will
          always be posted on our Privacy Policy page. If you have questions or
          concerns about our Privacy Policy or our practices for the security
          and protection of your personal information, please contact us at{" "}
          <a href="mailto:support@aturna.legal">support@aturna.legal</a>.
        </p>
        <h2>1. Information we collect.</h2>
        <p>
          Aturna collects information and data to develop, operate, safeguard,
          and enhance Aturna Services and user experience on our platform. The
          type of information we collect from you depends on how you use Aturna
          Services and what information you choose to provide to us. You
          represent that all personal information that you provide is true,
          complete and accurate, and you will notify us of any changes to your
          personal information. We collect information from you in two ways:
          personal information you disclose, and information automatically
          collected as explained below.
        </p>
        <p>
          Information related to the creation of your account with Aturna or you
          voluntarily disclose when you use Aturna Services, such as information
          provided to use our estate planning platform and generate estate
          planning documents, is referred to as your “Personal Information”.
          Personal Information may include such information as your name, email
          address, mailing address, phone number, pronouns, and personal
          details, family information, and financial information that you
          provide to create estate planning documents. It also may include
          payment and billing information, such as payment method, credit card
          information and contact information. Personal Information may also
          include your username, password, and other details related to your
          account with Aturna, and any text, images, file attachments, and
          content you provide while using our estate planning document platform.
        </p>
        <p>
          In addition to Personal Information that you knowingly disclose, we
          automatically collect certain information when you visit our website
          or use Aturna Services. This information does not reveal your specific
          identity (such as your name or contact information) but may include
          device and usage information, such as your IP address, software and
          hardware you use (such as browser types, operating systems, ISPs,
          platform type, device type, mobile device identifiers such as make and
          model, mobile carrier), country, language preferences, referring URLs,
          pages or features of Aturna Services used and associated dates and
          timestamps, search terms, links you click, whether you open messages
          sent to your account, and other statistics related to your usage of
          our systems.
        </p>
        <p>
          We may also collect technical information such as web storage data,
          data stored in application data caches and data provided by web
          pixels, cookies and anonymous identifiers. We use this information
          primarily to maintain the security and operation of Aturna Services
          and for our internal analytics and reporting purposes. We retain and
          use this information and data to examine and analyze usage trends and
          user preferences.
        </p>
        <p>
          We also use tools, including third-party tools, to collect analytics
          data. Some of this information is collected using cookies and other
          tracking technologies, such as web beacons, session replay scripts,
          and similar technologies ("tracking technologies"). We may also work
          with third party partners to employ tracking technologies. When using
          Aturna Services, we may save one or more cookies onto your web
          browser. We use both session and persistent cookies to collect, store
          and track information and data to improve your user experience. A
          session cookie is one that disappears after you close your browser. A
          persistent cookie remains after you close your browser and can
          generally be removed. If you would prefer not to accept cookies or
          otherwise wish to disable our use of tracking technologies, it is
          possible to modify your cookie settings through your web browser.
          Please note that doing so may negatively impact your user experience,
          as some features may not work properly.
        </p>
        <p>
          Note that when clicking links to third party websites, cookies or
          pixels may be transmitted to your computer's web browser. Aturna has
          no control over cookies or pixels, or other data transmitted by a
          third party. Aturna is not responsible for any cookies, pixels or data
          storage on your computer accessed through a third-party website or
          service.
        </p>
        <p>
          We automatically gather information and data which is not connected to
          your personal identity to improve Aturna. We may collect information
          by aggregating and anonymizing other information. The aggregation and
          anonymization process prevents the information from being associated
          or identified with any one customer account, user, or individual. We
          may use aggregated and anonymized information for a wide variety of
          statistical and analytical purposes.
        </p>
        <p>
          All forms of information that we collect is referred to collectively
          in this policy as “information.”
        </p>
        <h2>2. Using the information we collect.</h2>
        <p>
          We use the information we collect for a variety of business purposes,
          which may include:
        </p>
        <ul>
          <li>Communicating with you;</li>
          <li>Providing you with Aturna Services;</li>
          <li>Responding to requests or inquiries from you;</li>
          <li>Providing customer support or technical assistance;</li>
          <li>Creating or administering your account;</li>
          <li>Billing you for services and processing payments;</li>
          <li>
            For business analysis, research purposes, or to improve Aturna
            Services;
          </li>
          <li>
            Customizing existing and future product offerings and other aspects
            of Aturna Services;
          </li>
          <li>
            Securing and safeguarding Aturna Services and protecting your
            information and data;
          </li>
          <li>Analyzing metadata;</li>
          <li>
            Marketing or offering other services that you may be interested in;
          </li>
          <li>
            Sharing with third parties for the purposes discussed below; and/or
          </li>
          <li>
            Any legitimate business purpose, provided that the information is
            aggregated and anonymized.
          </li>
        </ul>
        <p>
          We may use your information for the above purposes and to otherwise
          perform our obligations under these Terms of Use. We may also use your
          information to respond to law enforcement or other governmental or
          legal authorities, and to otherwise use the information consistent
          with your instructions to us, including both explicit instructions,
          such as when you contact us directly with a request, and implied
          instructions, such as when you engage us to provide you with Aturna
          Services.
        </p>
        <p>
          If you provide us with feedback on your use of Aturna Services, we
          have the right, but not the obligation, to post your feedback on our
          social media platforms, website, and on the social media platforms and
          websites of our affiliates. Your feedback is free, voluntary, and
          provided at your own risk.
        </p>
        <h2>3. How we share information.</h2>
        <p>We disclose information we collect in the following cases:</p>
        <ul>
          <li>
            You asked us to, or otherwise gave your consent directly or
            impliedly;
          </li>
          <li>
            With vendors and service providers we engage to provide the Aturna
            Services;
          </li>
          <li>
            To attorneys you have authorized to receive your information for
            estate planning purposes;
          </li>
          <li>
            With vendors and service providers we engage to help us gain
            insights and analytics into how Aturna Services are used and how
            they might be improved;
          </li>
          <li>
            As necessary to comply with applicable law, including governmental
            requests, law enforcement requests, and otherwise to protect the
            rights, privacy, safety, or property of you, us, or others;
          </li>
          <li>
            As necessary in the event of a proposed or actual reorganization,
            merger, sale, joint venture, assignment, transfer, financing, or
            other disposition of all or any portion of Aturna business, assets,
            or stock; or
          </li>
          <li>
            With others for any legitimate business purpose, provided the
            information is aggregated, anonymized, and/or otherwise not
            personally identifiable to you. This includes our right to transfer
            and/or sell any data and information we collect that is not
            personally identifiable to you. We will never sell any information
            or data that is personally identifiable to you.
          </li>
        </ul>
        <h2>4. Securing your information.</h2>
        <p>
          Aturna employs information security practices and procedures
          commensurate with reasonable industry standard, including
          administrative and technical safeguards, designed to (i) keep your
          information safe and secure, (ii) protect against anticipated threats
          or hazards to the security or integrity of your information, and (iii)
          protect against unauthorized access or use of your information. As
          reminder, however, it is your responsibility to keep your username and
          password confidential and not to share your username or password with
          others. We cannot be responsible for access to your information by
          parties who have gained access to your account by using your username
          and password.
        </p>
        <h2>5. Storing your information.</h2>
        <p>
          Aturna primarily processes and stores information on servers located
          in the United States, however from time to time it may be necessary
          for us to process and store your information on servers located
          outside of the United States. By using Aturna Services, you consent to
          the transfer of your information to any location where we may need to
          process and store your information.
        </p>
        <p>
          The Aturna estate planning platform is built on Google Cloud Platform
          (GCP); a leader in the web-hosting industry. Google maintains ISO
          27001 and SOC 1/2/3 certification (amongst many others) for the GCP
          infrastructure. You can learn more about Google Cloud Platform
          security{" "}
          <a
            target="_blank"
            href="https://cloud.google.com/trust-center/"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <h2>6. Updating your information.</h2>
        <p>
          You may update your information at any time through your Aturna
          account. If you are unable to update your information for any reason,
          or if you would like to terminate your account, please contact our
          customer support team at{" "}
          <a href="mailto:support@aturna.legal">support@aturna.legal</a>.
        </p>
        <h2>7. Collection, Use, and Protection of Client Information.</h2>
        <p>
          User acknowledges that use of the Platform requires Authorized Clients
          to enter personal and confidential information (“Client Information”)
          into the Platform for purposes of producing Estate Planning documents.
          Aturna will collect, use, and protect such Client Information in
          accordance with Aturna's Terms of Use and Privacy Policy. Aturna will
          employ information security practices and procedures commensurate with
          reasonable industry standards that include administrative and
          technical safeguards designed to (i) ensure the security and
          confidentiality of Client Information, (ii) protect against
          anticipated threats or hazards to the security or integrity of Client
          Information, and (iii) protect against unauthorized access or use of
          Client Information. User acknowledges that Aturna may, directly or
          indirectly through the services of third parties, collect, use and
          store non-personally identifiable information regarding use of the
          Platform by users to improve the performance of, or develop updates
          to, the Platform, or for any other lawful purpose. User agrees to
          protect the confidentiality and privacy of any Client Information
          received by User in connection with its use of the Platform.
        </p>
        <h2>8. Promotional Activities. </h2>
        <p>
          We send promotional emails and newsletters from time to time to users
          who have provided us with their email address or whose email addresses
          we have obtained through other sources. You can opt-out of promotional
          communications by using the “Unsubscribe” link in an email you receive
          from us or by emailing us at{" "}
          <a href="mailto:support@aturna.legal">support@aturna.legal</a>
        </p>
        <h2>9. Opting out of Cookies and Tracking Technologies. </h2>
        <p>
          If you would prefer not to accept cookies or otherwise wish to disable
          our use of tracking technologies, most browsers and mobile devices
          allow you to change your settings to notify you when you receive
          cookies or other tracking technologies are being used, and to choose
          whether to accept/allow it. Most browsers also allow you to disable or
          delete existing cookies or to automatically reject future cookies.
          Disabling cookies may limit your ability to use Aturna Services.
        </p>
        <h2>10. Third-party services and analytics.</h2>
        <p>
          Aturna utilizes third party technology services and analytics
          providers to ensure the reliable delivery of Aturna. We may add or
          remove third party service and analytics providers with or without
          notice.
        </p>
        <h2>11. Links to third-party websites and services.</h2>
        <p>
          Aturna may at times offer links and references to third party websites
          and services. This Privacy Policy does not apply once you leave the
          Aturna website and access and use third party websites and services.
          In that case, the third party's privacy policy and terms of service
          would apply. Aturna has no responsibility for the collection or use of
          information or data you provide through third party websites or
          services. Aturna assumes no liability for your disclosure of personal
          information, data, and content to third parties. It is your
          responsibility to review the privacy policies and terms of service for
          third party websites and services before you access, use, or provide
          personal information, data and content. Your use of third-party
          websites, products and services is your responsibility.
        </p>
        <h2>12. Children.</h2>
        <p>
          Aturna is not directed at children under 18 years of age. We do not
          knowingly collect any information or data or market to children. By
          using Aturna, you represent that you are at least 18 years of age.
        </p>
        <h2>13.Contact Information.</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at: <a href="mailto:support@aturna.legal">support@aturna.legal</a>.
        </p>

        <p>
          GUIDR LLC is located at 6240 Shirley Street, Suite 105, Naples,
          Florida.
        </p>
      </ContentPage>
    </div>
  );
};

export default PrivacyPage;
