import React, { useCallback, useEffect, useState } from "react";
import Map from "../Map";
import AturnaMarker from "../Map/AturnaMarker";
import { SearchPartnerPayload } from "modules/api/responseTypes/PartnerListingResponseType";
import PartnerDetail from "./PartnerDetail";

export type SearchDetails = {
  isLoading: boolean;
  searchId?: string;
  searchResults?: SearchPartnerPayload | undefined;
};

type PartnerMapViewProps = {
  onSelectLawFirm: (partnerId: string) => void;
  onToggleLawFirmDetails: (partnerId: string | null, open?: boolean) => void;
  search: SearchDetails | undefined;
  selectedPartnerId?: string | undefined;
  mapContainerClassName?: string;
};

const DEFAULT_MAP_POSITION = {
  lat: 39.833333,
  lon: -98.583333,
  zoom: 5,
};

const PartnerMapView = ({
  onSelectLawFirm,
  onToggleLawFirmDetails,
  search,
  selectedPartnerId,
  mapContainerClassName,
}: PartnerMapViewProps) => {
  const [debouncedSearch, setDebouncedSearch] = useState<SearchDetails>();

  const referencePoint = debouncedSearch?.searchResults?.context?.location;
  const partners = debouncedSearch?.searchResults?.partners || [];
  const handleCloseInfoWindows = useCallback(
    () => onToggleLawFirmDetails(null, false),
    [onToggleLawFirmDetails],
  );
  const selectedPartner = selectedPartnerId
    ? partners.find(({ id }) => id === selectedPartnerId)
    : undefined;
  const { latitude: partnerLat, longitude: partnerLon } =
    selectedPartner?.data.location || {};

  // we need to prevent zooming in while transitions may be going on and map dimensions are not set yet
  useEffect(() => {
    setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);
  }, [search]);

  return (
    <Map
      position={DEFAULT_MAP_POSITION}
      onClick={handleCloseInfoWindows}
      mapViewId={debouncedSearch?.searchId}
      referencePoint={referencePoint}
      highlightedPoint={
        partnerLat !== undefined && partnerLon !== undefined
          ? { lat: partnerLat, lon: partnerLon }
          : undefined
      }
      mapContainerClassName={mapContainerClassName}
    >
      {partners.map((partner) =>
        partner.data.location ? (
          <AturnaMarker
            key={partner.id}
            partner={partner.id}
            lat={partner.data.location.latitude}
            lon={partner.data.location.longitude}
            onClick={() => onToggleLawFirmDetails(partner.id)}
            onCloseInfo={() => onToggleLawFirmDetails(partner.id, false)}
            openInfoBubble={selectedPartnerId === partner.id}
          >
            <PartnerDetail partner={partner} onSelect={onSelectLawFirm} />
          </AturnaMarker>
        ) : null,
      )}
    </Map>
  );
};

export default PartnerMapView;
