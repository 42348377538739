import React from "react";
import { ButtonSize, ChevronColour, type LandingButtonProps } from "./types";

import styles from "./styles.module.scss";
import TabbedButton from "components/TabbedButton";
import { IconType } from "components/Icons";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Button = ({
  label,
  onClick,
  chevronColour,
  chevronRight,
  size,
}: LandingButtonProps) => (
  <TabbedButton
    label={label}
    onClick={onClick}
    addClassName={cx("landing-button", {
      "chevron--right": chevronRight,
      "chevron--navy": chevronColour === ChevronColour.NAVY,
      "chevron--green": chevronColour === ChevronColour.GREEN,
      "chevron--white": chevronColour === ChevronColour.WHITE,
      "size--xsmall": size === ButtonSize.XSMALL,
      "size--small": size === ButtonSize.SMALL,
      "size--medium": size === ButtonSize.MEDIUM,
      "size--large": size === ButtonSize.LARGE,
    })}
    icon={IconType.ARROW_FAT}
    iconRight={chevronRight}
  />
);

export default Button;
