import Alert, { AlertType } from "components/Alert";
import LoadingOverlay from "components/LoadingOverlay";
import React from "react";
import PermissionConfigurationAdd from "./add";
import PermissionConfigurationList from "./list";
import usePermissions from "./usePermissions";

const PermissionsConfiguration: React.FC = () => {
  const { permissions, error } = usePermissions();

  if (!permissions) return <LoadingOverlay />;
  if (error)
    return (
      <Alert
        message="Something went wrong. Please try refreshing the page."
        type={AlertType.ERROR}
      />
    );

  return (
    <>
      <PermissionConfigurationAdd />
      <PermissionConfigurationList permissions={permissions} />
    </>
  );
};

export default PermissionsConfiguration;
