import {
  PartnerRegistration,
  PartnerRegistrationRequest,
  RegistrationState,
} from "modules/parties/types/Partner";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import {
  Question,
  QuestionDefinitionConfirm,
  QuestionID,
  QuestionnaireSection,
  QuestionType,
  TextFieldType,
} from "modules/workflow/types/Questionnaire";
import linkStyles from "components/Link/styles.module.scss";
import React, { useCallback, useMemo, useState } from "react";
import { keyBy } from "lodash";
import { addressLookup, addressSearch } from "modules/address/actions";
import { getFullName } from "modules/parties/types/Party";
import { processAddressQuestions } from "modules/workflow/helpers/processQuestions";
import reportValidationErrors from "modules/workflow/helpers/reportValidationErrors";
import { TIMEZONES_OPTIONS } from "resources/options/timezones";
import { useDispatch } from "react-redux";
import { User } from "modules/auth/User";
import styles from "./styles.module.scss";
import ErrorType from "modules/api/ErrorType";
import logger, { TYPE } from "logger";
import { STATE_CODE_OPTIONS } from "resources/options/states";
import { getLawfirmAgreementLink } from "utils/environment";

const FORM_ID = "partner-registration";
const GUIDR_PARTNER_URL_PREFIX = "https://aturna.legal/";

type Props = {
  customActions?: JSX.Element | JSX.Element[];
  hideUserAgreement?: boolean;
  onExit: () => void;
  onSubmit: (requestData: PartnerRegistrationRequest) => void;
  readOnly?: boolean;
  registration?: PartnerRegistration;
  submitButtonLabel: string;
  user?: User | null;
};

const questionDefinitions: Question[] = [
  {
    id: "companyName",
    data: {
      descriptions: [{ label: "Company Name" }],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: "preferredID",
    data: {
      descriptions: [
        {
          label: "Preferred ID",
          description:
            "Please enter a preferred ID that you would like to identify your law firm. Note this will form the part of your link to Aturna, example https://aturna.legal/YourPreferredIDHere",
        },
      ],
      definition: {
        type: QuestionType.TEXT,
        subtype: TextFieldType.CODE,
        placeholder: "FirmName",
        blacklist: [
          "login",
          "partner",
          "guidr",
          "aturna",
          "admin",
          "partners",
          "profile",
          "pages",
          "api",
        ],
        limits: {
          min: 2,
          max: 50,
        },
        prefix: GUIDR_PARTNER_URL_PREFIX,
      },
      required: true,
    },
  },

  {
    id: "attorney",
    data: {
      descriptions: [{ label: "Attorney Name" }],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: "contact_phone",
    data: {
      descriptions: [{ label: "Phone" }],

      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
      required: true,
    },
  },

  {
    id: "contact_email",
    data: {
      descriptions: [{ label: "Email address" }],

      definition: {
        type: QuestionType.TEXT,
        subtype: TextFieldType.EMAIL,
        explainer:
          "This email will be public facing, and serve as the contact email on your website",
      },
      required: true,
    },
  },

  {
    id: "billing_email",
    data: {
      descriptions: [
        {
          label: "Billing Email address",
        },
      ],

      definition: {
        type: QuestionType.TEXT,
        subtype: TextFieldType.EMAIL,
        explainer:
          "Monthly receipts and other financial correspondence will be emailed here",
      },
      required: true,
    },
  },

  {
    id: "address",
    data: {
      descriptions: [{ label: "Address" }],

      definition: { type: QuestionType.ADDRESS },
      required: true,
    },
  },

  {
    id: "timezone",
    data: {
      descriptions: [{ label: "Timezone" }],
      definition: {
        type: QuestionType.SELECT,
        values: TIMEZONES_OPTIONS.map(({ value, label }) => ({
          value,
          display: label,
        })),
        autocomplete: true,
        limits: { max: 1 },
      },
      required: true,
    },
  },

  {
    id: "jurisdiction",
    data: {
      descriptions: [{ label: "Which states does your law firm operate in?" }],
      definition: {
        type: QuestionType.SELECT,
        values: STATE_CODE_OPTIONS,
      },
      required: true,
    },
  },
];

const PartnerRegistrationForm: React.FC<Props> = ({
  customActions,
  hideUserAgreement,
  onExit,
  onSubmit,
  readOnly,
  registration,
  submitButtonLabel,
  user,
}) => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState<Record<string, string[]> | null>(
    null,
  );
  const [hasUnsavedChanges, setUnsavedChanges] = useState<Boolean>(false);

  const onAddressLookup = useCallback(
    (addressId: string) => addressLookup(addressId)(dispatch),
    [dispatch],
  );

  const onAddressSearch = useCallback(
    (containerId?: string, searchTerm?: string) =>
      addressSearch(containerId, searchTerm)(dispatch),
    [dispatch],
  );

  const handleFormChange = useCallback(() => setUnsavedChanges(true), []);

  const handleFormSubmission = useCallback(
    async (values: Record<QuestionID, any>) => {
      if (formErrors) setFormErrors(null);

      const {
        address,
        attorney,
        companyName,
        contact_email,
        billing_email,
        contact_phone,
        jurisdiction,
        preferredID,
        timezone,
      } = values;

      const requestData: PartnerRegistrationRequest = {
        address: address.address,
        attorneyName: attorney,
        displayName: companyName,
        email: contact_email,
        billingEmail: billing_email,
        jurisdiction,
        partnerId: preferredID,
        phone: contact_phone,
        timezone,
      };

      try {
        onSubmit && (await onSubmit(requestData));
        setUnsavedChanges(false);
      } catch (err) {
        const error: any = err;
        const shortCode = error.response?.data?.short;
        if (
          shortCode &&
          [
            ErrorType.PARTNER_ID_EXISTS,
            ErrorType.PARTNER_ID_REQUESTED,
          ].includes(shortCode)
        ) {
          setFormErrors({
            preferredID: [
              "Entered Preferred ID is already taken. Please, choose a different one.",
            ],
          });
          logger.notify(
            TYPE.ERROR,
            "Entered Preferred ID is already taken. Please, choose a different one.",
          );
        } else {
          logger.notify(
            TYPE.ERROR,
            "Registration submission failed. Please, try again later.",
            err,
          );
        }
        throw err;
      }
    },
    [formErrors, onSubmit],
  );

  const questions = processAddressQuestions(
    [
      ...questionDefinitions,
      ...(hideUserAgreement
        ? []
        : [
            {
              id: "user_agreement",
              data: {
                descriptions: [
                  {
                    label:
                      "By completing this application you confirm that you agree to the",
                  },
                ],
                definition: {
                  type: QuestionType.CONFIRM,
                  errorMessage: "Please, confirm your agreement to proceed",
                  extraAction: () => (
                    <a
                      className={[linkStyles.link, styles.agreement].join(" ")}
                      target="popup"
                      onClick={(e) => e.stopPropagation()}
                      download="User Agreement"
                      href={getLawfirmAgreementLink()}
                    >
                      User Agreement
                    </a>
                  ),
                } as QuestionDefinitionConfirm,
                required: true,
              },
            },
          ]),
    ],
    onAddressLookup,
    onAddressSearch,
    [],
  ).map(
    (q): Question => (readOnly ? { ...q, data: { ...q.data, readOnly } } : q),
  );

  const request = registration?.request;
  const formValues = useMemo(
    () => ({
      [FORM_ID]: {
        companyName: request?.displayName,
        preferredID: request?.partnerId,
        attorney:
          request?.attorneyName || (user ? getFullName(user) : undefined),
        contact_phone: request?.phone || user?.phoneNumber,
        contact_email: request?.email || user?.email,
        billing_email: request?.billingEmail || user?.email,
        address: request?.address,
        jurisdiction: request?.jurisdiction,
        timezone: request?.timezone || user?.timezone,
      },
    }),
    [request, user],
  );

  const formSections: QuestionnaireSection[] = [
    {
      caption: "Partner Details",
      questions: ["companyName", "preferredID", "attorney", "jurisdiction"],
    },
    {
      caption: "Address",
      questions: ["address"],
    },
    {
      caption: "Contact Information",
      questions: [
        "contact_email",
        "billing_email",
        "contact_phone",
        "timezone",
      ],
    },
    ...(registration?.status.type === RegistrationState.PENDING
      ? []
      : [
          {
            caption: "User Agreement",
            questions: ["user_agreement"],
          },
        ]),
  ];

  return (
    <QuestionnaireForm
      answers={formValues}
      validationErrors={formErrors || undefined}
      onSavePageAnswers={(_, values) => handleFormSubmission(values)}
      questionsBySubquestionnaire={{ [FORM_ID]: keyBy(questions, "id") }}
      questionnairePages={[
        {
          id: `${FORM_ID}_page`,
          questionnaireId: FORM_ID,
          caption: "",
          sections: formSections,
        },
      ]}
      onValidationErrors={reportValidationErrors}
      onChange={handleFormChange}
      customFormProps={{
        showSubmitButton: Boolean(
          !readOnly && (hasUnsavedChanges || !customActions),
        ),
        submitButtonLabel,
        showExitButton: Boolean(registration),
        onExit,
        customActions: hasUnsavedChanges ? undefined : customActions,
      }}
    />
  );
};

export default PartnerRegistrationForm;
