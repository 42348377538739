import { callgetGlobalFinancialStatusAPI } from "modules/admin/actions";
import useSWR from "swr";

const useGlobalFinancialStatus = (period?: string) => {
  const { data, error, mutate } = useSWR(
    ["useGlobalFinancialStatus", period],
    () => callgetGlobalFinancialStatusAPI(period),
  );

  return {
    globalFinancialStatus: data,
    error,
    refetchGlobalFinancialStatus: mutate,
  };
};

export default useGlobalFinancialStatus;
