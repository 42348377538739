import * as React from "react";
import { useState } from "react";
import { useField } from "formik";
import { uniq } from "lodash";
import classNames from "classnames/bind";
import Label from "components/Forms/Label";
import { ReactComponent as InfoSVG } from "./info.svg";
import {
  QuestionHelp,
  QuestionHelpItem,
} from "modules/workflow/types/Questionnaire";

import styles from "./styles.module.scss";
import SanitizeHtml from "components/SanitizeHtml";
const cx = classNames.bind(styles);

export enum QuestionSizes {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  FULLWIDTH = "fullwidth",
}

export const formatError = (error: any) => {
  return typeof error === "string"
    ? error
    : Array.isArray(error)
      ? uniq(
          error
            .map((item) => (typeof item === "object" ? item.share : item))
            .filter(Boolean),
        ).join(". ")
      : undefined;
};

type QuestionWrapperProps = {
  blurTouched?: boolean;
  className?: string | string[];
  disabled?: boolean;
  help?: QuestionHelp;
  id?: string;
  info?: QuestionHelp;
  label?: string;
  name: string;
  size?: QuestionSizes;
  showHelpByDefault?: boolean;
};

const QuestionWrapper: React.FunctionComponent<QuestionWrapperProps> = (
  props,
) => {
  const [showHelp, setHelp] = useState(props.showHelpByDefault);
  const [, meta, helpers] = useField(props.name);
  const { setTouched } = helpers;
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const currentTarget = event.currentTarget;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setTouched(true);
      }
    }, 0);
  };
  function handleMouseDown(event: React.MouseEvent<SVGSVGElement>) {
    event.preventDefault();
    setHelp(!showHelp);
  }
  let className = cx(
    "question",
    {
      "question--small": props.size === QuestionSizes.SMALL,
      "question--medium": props.size === QuestionSizes.MEDIUM,
      "question--large": props.size === QuestionSizes.LARGE,
      "question--fullwidth": props.size === QuestionSizes.FULLWIDTH,
      "question--disabled": props.disabled,
      "question--show-help-text": showHelp,
      "question--invalid": !props.disabled && meta.touched && meta.error,
      "question--valid": !props.disabled && meta.touched && !meta.error,
    },
    props.className,
  );

  const renderHelpParagraph = (
    type: "help" | "info",
    paragraph: string | QuestionHelpItem,
    idx?: number,
  ) => (
    <React.Fragment key={`${type}-${idx}`}>
      {typeof paragraph !== "string" && (
        <h3 className={styles[`question__${type}-title`]}>{paragraph.title}</h3>
      )}
      <p className={styles[`question__${type}-text`]}>
        {typeof paragraph === "string" ? paragraph : paragraph.text}
      </p>
    </React.Fragment>
  );

  const renderHelp = (type: "help" | "info", content: QuestionHelp) => (
    <div className={styles[`question__${type}`]}>
      {!Array.isArray(content) && renderHelpParagraph(type, content, -1)}
      {Array.isArray(content) &&
        content.map((item, idx) => renderHelpParagraph(type, item, idx))}
    </div>
  );

  const error = formatError(meta.error);

  return (
    <div
      data-questionid={props.name}
      className={className}
      onBlur={props.blurTouched ? handleBlur : undefined}
    >
      <div className={styles["question__inner"]}>
        {props.label && (
          <Label
            disabled={props.disabled}
            invalid={meta.touched && meta.error ? true : false}
            id={props.id}
            label={<SanitizeHtml html={props.label} />}
          />
        )}
        {props.help &&
          (!Array.isArray(props.help) || props.help.length > 0) && (
            <InfoSVG
              aria-label={`Tooltip (${props.label})`}
              className={styles["question__help-tooltip"]}
              onMouseDown={handleMouseDown}
            />
          )}
        {props.children}
      </div>
      {!props.disabled && meta.touched && error && (
        <div className={styles["question__error"]} aria-live="polite">
          {error}
        </div>
      )}

      {!props.disabled &&
        props.help &&
        showHelp &&
        renderHelp("help", props.help)}
      {!props.disabled && props.info && renderHelp("info", props.info)}
    </div>
  );
};

export default QuestionWrapper;
