import { createAction, Dispatch } from "@reduxjs/toolkit";
import { API, get, patch, post, put } from "modules/api";
import logger, { TYPE } from "../../logger";
import Workflow from "modules/workflow/types/Workflow";
import WorkflowAdminPayload from "../api/types/WorkflowAdminPayload";
import {
  Definition,
  DefinitionData,
  ListDefinitionsResponse,
} from "modules/api/responseTypes/DefinitionResponseType";
import { User, UserID } from "modules/auth/User";
import {
  PartnerID,
  PartnerRegistration,
  PartnerRegistrationRequest,
} from "modules/parties/types/Partner";
import { orderBy } from "lodash";
import {
  FinancialProfileResponse,
  GlobalFinancialStatusResponse,
} from "modules/parties/types/Finance";
import {
  QuestionnaireID,
  QuestionnaireWithQuestions,
} from "modules/workflow/types/Questionnaire";
import { MedicaidVariable } from "./types/MedicaidVariable";
import { AnswersMap } from "modules/workflow/types/Answer";
import { PERIOD } from "screens/finance/types";

export const fetchPartnerWorkflowsPrioritisedStarted = createAction(
  "FETCH_PARTNER_WORKFLOWS_PRIORITISED_STARTED",
);
export const partnerWorkflowsPrioritisedFetched = createAction<
  WorkflowAdminPayload[]
>("PARTNER_WORKFLOWS_PRIORITISED_FETCHED");
export const fetchPartnerWorkflowsPrioritisedFailed = createAction<any>(
  "FETCH_PARTNER_WORKFLOWS_PRIORITISED_FAILED",
);

export const openClientWorkflow = createAction<{
  user: User | null;
  workflow: Workflow;
  pageIdx?: number;
  pageId?: string;
}>("OPEN_CLIENT_WORKFLOW");

export const fetchPartnerDefinitionsStarted = createAction(
  "FETCH_PARTNER_DEFINITIONS_STARTED",
);
export const partnerDefinitionsFetched = createAction<ListDefinitionsResponse>(
  "PARTNER_DEFINITIONS_FETCHED",
);
export const fetchPartnerDefinitionsFailed = createAction<any>(
  "FETCH_PARTNER_DEFINITIONS_FAILED",
);

export const updateDefinitionStarted = createAction("SAVE_DEFINITION_STARTED");
export const saveDefinitionFailed = createAction<any>("SAVE_DEFINITION_FAILED");
export const definitionSaved = createAction<Definition>("DEFINITION_SAVED");

const callGetPartnerWorkflowsPrioritisedAPI = async (): Promise<
  WorkflowAdminPayload[]
> => {
  const response = await get(API.PARTNER_WORKFLOWS_PRIORITISED());
  return response.data;
};

export const callGetMedicaidVariablesAPI = async (
  jurisdiction: string,
): Promise<{
  questionnaireWithQuestions: QuestionnaireWithQuestions;
  medicaidVariables: MedicaidVariable[];
}> => {
  const response = await get(API.MEDICAID_VARIABLES(), { jurisdiction });
  return response.data;
};

export const callUpdateMedicaidVariablesAPI = async (
  questionnaireId: QuestionnaireID,
  answers: AnswersMap,
): Promise<{ medicaidVariables: MedicaidVariable[] }> => {
  const response = await put(API.MEDICAID_VARIABLES(), {
    questionnaireId,
    answers,
  });

  return response.data;
};

export const callGetFinancialProfileAPI = async (
  partnerId: PartnerID,
): Promise<FinancialProfileResponse> => {
  const response = await get(API.FINANCIAL_PROFILE({ partnerId }));

  return response.data;
};

export const callgetGlobalFinancialStatusAPI = async (
  period?: string,
): Promise<GlobalFinancialStatusResponse> => {
  const response = await get(API.GLOBAL_FINANCIAL_STATUS(), {
    period: period === PERIOD.ALL_TIME ? undefined : period,
  });

  return response.data;
};

export const callGetPartnerDefinitionsAPI =
  async (): Promise<ListDefinitionsResponse> => {
    const response = await get(API.PARTNER_DEFINITIONS());
    return response.data;
  };

export const callGetPartnerRequestsAPI = async (): Promise<
  PartnerRegistration[]
> => {
  const response = await get(API.PARTNER_REGISTRATION());
  return orderBy(response.data, [
    (registration) => registration.request.displayName.toLowerCase(),
    "request.partnerId",
  ]);
};

const callUpdatePartnerRegistrationAPI = async (
  registrationId: string,
  requester: UserID,
  updatedRequest: PartnerRegistrationRequest,
): Promise<PartnerRegistration> => {
  const response = await patch(
    API.PARTNER_REGISTRATION_INSTANCE({ registrationId }),
    updatedRequest,
    { asUser: requester },
  );
  return response.data;
};

export const updatePartnerRegistrationFailed = createAction<any>(
  "UPDATE_PARTNER_REGISTRATION_FAILED",
);
export const partnerRegistrationUpdated = createAction<PartnerRegistration>(
  "PARTNER_REGISTRATION_UPDATED",
);
export const updatePartnerRegistration =
  (
    registrationId: string,
    requester: UserID,
    updatedRequest: PartnerRegistrationRequest,
  ) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const result = await callUpdatePartnerRegistrationAPI(
        registrationId,
        requester,
        updatedRequest,
      );
      dispatch(partnerRegistrationUpdated(result));
    } catch (e) {
      dispatch(updatePartnerRegistrationFailed(e));
      throw e;
    }
  };

export const approvePartnerRegistrationFailed = createAction<any>(
  "APPROVE_PARTNER_REGISTRATION_FAILED",
);
export const partnerRegistrationApproved = createAction<PartnerRegistration>(
  "PARTNER_REGISTRATION_APPROVED",
);
export const rejectPartnerRegistrationFailed = createAction<any>(
  "REJECT_PARTNER_REGISTRATION_FAILED",
);
export const partnerRegistrationRejected = createAction<PartnerRegistration>(
  "PARTNER_REGISTRATION_REJECTED",
);
const callManagePartnerRegistrationAPI = async (
  registrationId: string,
  requester: UserID,
  accepted: boolean,
  reason?: string,
): Promise<PartnerRegistration> => {
  const response = await post(
    API.PARTNER_REGISTRATION_INSTANCE({ registrationId }),
    { type: accepted ? "Approve" : "Reject", reason },
    { asUser: requester }, // TODO only superadmin should be allowed
  );
  return response.data;
};

export const approvePartnerRegistration =
  (registrationId: string, requester: UserID) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const result = await callManagePartnerRegistrationAPI(
        registrationId,
        requester,
        true,
      );
      dispatch(partnerRegistrationApproved(result));
    } catch (e) {
      dispatch(approvePartnerRegistrationFailed(e));
      throw e;
    }
  };

export const rejectPartnerRegistration =
  (registrationId: string, requester: UserID, reason: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const result = await callManagePartnerRegistrationAPI(
        registrationId,
        requester,
        false,
        reason,
      );
      dispatch(partnerRegistrationRejected(result));
    } catch (e) {
      dispatch(rejectPartnerRegistrationFailed(e));
      throw e;
    }
  };

const callSaveDefinitionAPI = async (
  definitionId: string,
  definitionData: Partial<DefinitionData>,
): Promise<Definition> => {
  const response = await patch(
    API.PARTNER_DEFINITION({ definitionId }),
    definitionData,
  );
  return response.data as Definition;
};

export const fetchPartnerWorkflowsPrioritised =
  () =>
  async (dispatch: Dispatch): Promise<WorkflowAdminPayload[]> => {
    dispatch(fetchPartnerWorkflowsPrioritisedStarted());
    try {
      const workflows = await callGetPartnerWorkflowsPrioritisedAPI();

      dispatch(partnerWorkflowsPrioritisedFetched(workflows));
      return workflows;
    } catch (error) {
      logger.notify(
        TYPE.ERROR,
        "Loading the data failed. Please, try again later",
        error,
      );
      dispatch(fetchPartnerWorkflowsPrioritisedFailed(error));
      throw error;
    }
  };

export const fetchPartnerDefinitions = () => async (dispatch: Dispatch) => {
  dispatch(fetchPartnerDefinitionsStarted());
  try {
    const response = await callGetPartnerDefinitionsAPI();
    dispatch(partnerDefinitionsFetched(response));
    return response;
  } catch (error) {
    logger.notify(
      TYPE.ERROR,
      "Loading the data failed. Please, try again later",
      error,
    );
    dispatch(fetchPartnerDefinitionsFailed(error));
    throw error;
  }
};
export const saveDefinition =
  (definitionId: string, definitionData: Partial<DefinitionData>) =>
  async (dispatch: Dispatch) => {
    dispatch(updateDefinitionStarted());
    try {
      const updatedDefinition = await callSaveDefinitionAPI(
        definitionId,
        definitionData,
      );
      if (!updatedDefinition) {
        throw new Error("Invalid response from the server");
      }

      dispatch(definitionSaved(updatedDefinition));
    } catch (error) {
      logger.notify(
        TYPE.ERROR,
        "Saving partner details failed. Please, try again later",
        error,
      );
      dispatch(saveDefinitionFailed(error));
      throw error;
    }
  };
