import React from "react";
import TabbedButton from "components/TabbedButton";
import PlaceholderScreen from "screens/core/PlaceholderScreen";

type AwaitingInterviewCompletionScreenProps = {
  onOpenInterview: () => void;
};

const AwaitingInterviewCompletionScreen: React.FunctionComponent<
  AwaitingInterviewCompletionScreenProps
> = ({ onOpenInterview }) => (
  <PlaceholderScreen
    title="Interview in progress"
    message="You need to complete your interview before proceeding to payment."
    renderAction={() => (
      <TabbedButton onClick={onOpenInterview}>View my interview</TabbedButton>
    )}
  />
);

export default AwaitingInterviewCompletionScreen;
