import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";
import Partner from "modules/parties/types/Partner";
import React from "react";
import SearchResultItem from "./SearchResultItem";

import styles from "./styles.module.scss";

type SearchResultsListProps = {
  isLoading?: boolean;
  onSelectLawFirm: (partnerId: string) => void;
  onShowDetails: (partnerId: string) => void;
  results?: Partner[] | undefined;
  selectedPartnerId?: string | undefined;
};

const SearchResults: React.FC<SearchResultsListProps> = ({
  isLoading,
  onSelectLawFirm,
  onShowDetails,
  results,
  selectedPartnerId,
}) => (
  <div className={styles["results-list"]}>
    {isLoading ? (
      <div className={styles["results-list--empty"]}>
        <LoadingSpinner size={SpinnerSize.SMALL} />
      </div>
    ) : !results?.length ? (
      <div className={styles["results-list--empty"]}>No results found</div>
    ) : (
      <ul>
        {results.map((partner, idx) => (
          <SearchResultItem
            key={partner.id}
            style={{ transitionDelay: `${idx * 0.2}s, 0s` }}
            partner={partner}
            onSelectLawFirm={onSelectLawFirm}
            onShowDetails={onShowDetails}
            isShowingDetails={selectedPartnerId === partner.id}
            searchResultsListPosition={idx}
          />
        ))}
      </ul>
    )}
  </div>
);

export default SearchResults;
