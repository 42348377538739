import * as React from "react";

import { User } from "modules/auth/User";
import HeaderAccount from "../HeaderAccount";
import TabbedButton from "components/TabbedButton";
import styles from "./styles.module.scss";
import MenuLink from "../MenuLink";
import StateSelectorHandle from "../StateSelectorHandle";
import { IconType } from "components/Icons";

type HeaderProfileProps = {
  user: User | null;
  onLogout: () => any;
  onLogin: () => any;
  onStateChange?: () => void;
  profileMenuLinks: MenuLink[];
  selectedState?: string;
};

const HeaderProfile: React.FunctionComponent<HeaderProfileProps> = ({
  user,
  onLogout,
  onLogin,
  onStateChange,
  profileMenuLinks,
  selectedState,
}) => {
  const renderUserMenu = (user: User) => (
    <HeaderAccount
      user={user}
      onLogout={onLogout}
      profileMenuLinks={profileMenuLinks}
    />
  );

  return (
    <div className={styles["header-profile"]}>
      {onStateChange && selectedState ? (
        <StateSelectorHandle
          selectedState={selectedState}
          onTriggerStateSelect={onStateChange}
        />
      ) : null}
      {user ? (
        renderUserMenu(user)
      ) : (
        <TabbedButton
          tabIndex={0}
          label="Login"
          onClick={onLogin}
          icon={IconType.LOGIN}
        />
      )}
    </div>
  );
};

export default HeaderProfile;
