const getPercentage = (
  fraction: number,
  total: number,
): { value: number; exact: number } => {
  const percent = (fraction / total) * 100;
  const floor = Math.floor(percent);

  return { value: floor, exact: percent };
};

export default getPercentage;
