import { createReducer } from "@reduxjs/toolkit";
import {
  ApplicableJurisdiction,
  Definition,
  DefinitionDescriptions,
} from "modules/api/responseTypes/DefinitionResponseType";
import { AddonID } from "modules/product/types/Addon";
import {
  fetchPartnerWorkflowsPrioritisedStarted,
  fetchPartnerWorkflowsPrioritisedFailed,
  partnerWorkflowsPrioritisedFetched,
  fetchPartnerDefinitionsStarted,
  fetchPartnerDefinitionsFailed,
  partnerDefinitionsFetched,
  definitionSaved,
} from "./actions";

export const clientWorkflowsPrioritisedLoading = createReducer<boolean>(
  false,
  (builder) =>
    builder
      .addCase(fetchPartnerWorkflowsPrioritisedStarted, () => true)
      .addCase(fetchPartnerWorkflowsPrioritisedFailed, () => false)
      .addCase(partnerWorkflowsPrioritisedFetched, () => false),
);

export const partnerDefinitionsLoading = createReducer<boolean>(
  false,
  (builder) =>
    builder
      .addCase(fetchPartnerDefinitionsStarted, () => true)
      .addCase(fetchPartnerDefinitionsFailed, () => false)
      .addCase(partnerDefinitionsFetched, () => false),
);

export const definitions = createReducer<Definition[]>([], (builder) =>
  builder
    .addCase(partnerDefinitionsFetched, (_, { payload }) => {
      const definitions = payload.definitions;
      return definitions
        ? Object.keys(definitions).map((key) => definitions[key])
        : [];
    })
    .addCase(definitionSaved, (currentState, { payload }) => [
      ...currentState.filter((def) => def.id !== payload.id),
      payload,
    ]),
);

export const addonJurisdictions = createReducer<
  Record<AddonID, ApplicableJurisdiction[]>
>({}, (builder) =>
  builder.addCase(
    partnerDefinitionsFetched,
    (_, { payload }) => payload.addonJurisdictions,
  ),
);

export const definitionDescriptions = createReducer<DefinitionDescriptions>(
  { products: {}, plans: {}, addons: {} },
  (builder) =>
    builder.addCase(partnerDefinitionsFetched, (_, { payload }) => ({
      addons: payload.addons,
      products: payload.products,
      plans: payload.plans,
    })),
);
