import React from "react";
import TabbedButton from "components/TabbedButton";
import PlaceholderScreen from "screens/core/PlaceholderScreen";

type AwaitingCoupledScreenProps = {
  isAdmin: boolean;
  onOpenSpouseWorkflow: () => void;
};

const AwaitingCoupledScreen: React.FunctionComponent<
  AwaitingCoupledScreenProps
> = ({ isAdmin, onOpenSpouseWorkflow }) => (
  <PlaceholderScreen
    title="Spouse's interview in progress"
    message={
      isAdmin
        ? ""
        : "You'll be able to pay for your products once you complete your spouse's interview."
    }
    renderAction={() => (
      <TabbedButton onClick={onOpenSpouseWorkflow}>{`View ${
        isAdmin ? "" : "my "
      }spouse's interview`}</TabbedButton>
    )}
  />
);

export default AwaitingCoupledScreen;
