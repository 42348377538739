import { Middleware } from "@reduxjs/toolkit";
import {
  fetchPaymentState,
  notifyPaymentSuccess,
  paymentDetailsInvalid,
  paymentFailed,
  paymentInitializationError,
  paymentSuccessful,
  proceedToPayment,
} from "./actions";
import { getWorkflowRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import logger from "logger";
import { NotificationType } from "components/Notification";
import type { RootState } from "redux/reducer";
import { go, redirect } from "modules/navigation/helpers/navigator";
import { OrderItemType, PaymentStatus } from "modules/api/types/Payment";
import { ErrorType } from "modules/api/types/ErrorResponsePayload";

export const getSpecificPaymentErrorDetails = (
  error: any,
): { message: string; redirect: boolean } => {
  const { additionalData, short } = error?.response?.data || {};

  if (short === ErrorType.INVALID_PROMO_CODE)
    return {
      message: `Coupon "${additionalData["promo-code"]}" is not valid`,
      redirect: false,
    };

  if (short === ErrorType.PAYMENT_ILLEGAL_STATUS) {
    return {
      message:
        additionalData?.status === PaymentStatus.Success
          ? "Your payment has already been made. Please, wait until it's processed."
          : "Payment has failed. Please, try again later or contact the support if problems persist.",
      redirect: false,
    };
  }

  return {
    message:
      "Payments are not available at the moment. Please, try again later.",
    redirect: true,
  };
};

const createPaymentMiddleware: () => Middleware =
  () =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case proceedToPayment.type: {
        redirect(
          dispatch,
          getWorkflowRoute(
            action.payload.workflowId,
            ROUTE_TEMPLATE.WORKFLOW_SIGNING,
          ),
        );
        break;
      }

      case paymentInitializationError.type: {
        const { redirectPath, error } = action.payload;
        const { message: errorMessage, redirect: doRedirect } =
          getSpecificPaymentErrorDetails(error);

        if (doRedirect) redirect(dispatch, redirectPath);
        setTimeout(
          () => logger.notify(NotificationType.ERROR, errorMessage, error),
          500,
        );
        break;
      }

      case paymentFailed.type: {
        logger.notify(
          NotificationType.ERROR,
          action.payload.message ||
            "Payment failed. Please, review your data and try again.",
          action.payload,
        );
        break;
      }

      case paymentDetailsInvalid.type: {
        logger.notify(
          NotificationType.ERROR,
          `${
            action.payload || "Provided details are not valid."
          } Please, review the form and try again.`,
        );
        break;
      }

      case paymentSuccessful.type: {
        const { activePayment }: RootState = getState();
        if (activePayment?.workflowId !== action.payload.workflowId) {
          logger.error(
            `Unexpected payment confirmation - expected workflowId: ${activePayment?.workflowId}, received: ${action.payload.workflowId}`,
          );
          return;
        }

        // Notify backend
        activePayment &&
          (await notifyPaymentSuccess({
            action: activePayment.paymentAction,
            paymentId: activePayment?.id,
            workflowId: activePayment?.workflowId,
            promoCode: activePayment?.orderItems?.find(
              (orderItem) => orderItem.type === OrderItemType.DISCOUNT,
            )?.name,
          })(dispatch));

        if (activePayment?.workflowId) {
          go(
            dispatch,
            getWorkflowRoute(
              activePayment.workflowId,
              ROUTE_TEMPLATE.WORKFLOW_PAYMENT_PROCESSING,
            ),
          );
        }

        // Call asynchronously
        activePayment?.workflowId &&
          fetchPaymentState(
            activePayment.id,
            activePayment.workflowId,
          )(dispatch).catch(() =>
            logger.notify(
              NotificationType.ERROR,
              "Processing your payment is taking longer than expected. You can track your workflow's state from the dashboard.",
            ),
          );

        break;
      }
    }
    next(action);
  };

export default createPaymentMiddleware;
