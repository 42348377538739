import * as React from "react";
import { Alert as MuiAlert, AlertClasses } from "@mui/material";

export enum AlertType {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

type AlertProps = {
  message: string;
  type: AlertType;
  action?: React.ReactNode;
  classes?: Partial<AlertClasses>;
};

const Alert: React.FunctionComponent<AlertProps> = ({
  action,
  type,
  message,
  classes,
}) => {
  return (
    <MuiAlert severity={type} classes={classes} {...{ action }}>
      {message}
    </MuiAlert>
  );
};

export default Alert;
