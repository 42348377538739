import { keys, pickBy } from "lodash";
import { DefinitionDataAddon } from "modules/api/responseTypes/DefinitionResponseType";

const getAllowedAddons = (
  definitionAddons: Record<string, DefinitionDataAddon> | undefined,
): string[] =>
  definitionAddons === undefined
    ? []
    : keys(pickBy(definitionAddons, (addonData) => addonData.enabled));
export default getAllowedAddons;
