import Link from "components/Link";
import { ModalType, showModal } from "components/Modal";
import React from "react";

import styles from "./styles.module.scss";

const ProductsDisclaimerLong: React.FC = () => (
  <div className={styles["disclaimer-modal-content"]}>
    <section>
      The Will, Health Care Directive and Power of Attorney are the starting
      point for all estate planning, but there are typically four additional
      levels to consider:
      <ol>
        <li>Traditional Estate Planning.</li>
        <li>Asset Protection Planning.</li>
        <li>Medicaid and Veterans Benefits Planning.</li>
        <li>Estate Tax Planning.</li>
      </ol>
    </section>

    <section>
      Each level utilizes different legal documents including revocable living
      trusts, irrevocable trusts for asset protection, irrevocable trusts for
      estate tax planning, irrevocable trusts for special needs planning,
      Limited Liability Companies, and many others.{" "}
      <strong>
        If any of the following circumstances apply to you or appeal to you,
        please consider scheduling a meeting to discuss your options with one of
        our attorneys.
      </strong>
      <ul>
        <li>Want to avoid Probate</li>
        <li>Do not agree with your spouse on the plan he or she wants</li>
        <li>Have no family or friends you trust to carry out your wishes</li>
        <li>Have children or other beneficiaries who do not get along</li>
        <li>
          Are in a second marriage and either or both of you have children from
          a previous marriage
        </li>
        <li>
          Are concerned with your spouse remarrying after you die and your
          assets going to people you didn’t intend
        </li>
        <li>
          Have a beneficiary who is a spendthrift or unable to manage money, is
          a minor, has a high risk of being sued, is in a bad marriage, abuses
          drugs or alcohol, is disabled or likely to need long-term care in the
          future, is currently receiving government financial assistance
          benefits or has developmentally disabled children
        </li>
        <li>
          Want to protect your assets from predators, future creditors,
          lawsuits, unforeseen liabilities or from long-term care costs.{" "}
        </li>
        <li>
          Are likely to need long-term care in the future or may lose a spouse
          soon due to a terminal illness
        </li>
        <li>
          Are thinking of retiring and want to ensure you have reviewed the
          estate planning considerations with retirement or
        </li>
        <li>
          Have enough assets that may subject you to federal or state gift or
          estate taxes
        </li>
      </ul>
    </section>

    <section style={{ textAlign: "center" }}>
      <strong>
        <u>
          The "Essentials Plan" does not necessarily achieve any of these unique
          planning goals!
        </u>
      </strong>
    </section>
  </div>
);

export const openPlanningDisclaimerModal = () =>
  showModal({
    type: ModalType.CONFIRM,
    cancellable: true,
    id: "products_disclaimer",
    labelCancel: "Close",
    renderContent: () => <ProductsDisclaimerLong />,
  });

const ProductsDisclaimer: React.FC = () => (
  <div className={styles["planning-disclaimer-short"]}>
    <p>
      The Essential Documents Package is a great start to your estate planning{" "}
      <strong>but may not be enough to meet your long-term objectives. </strong>
      While complex and sophisticated planning includes the essential documents
      package, it also often includes additional legal documents or advanced
      language in the essential documents package to do more than what most
      people <em>typically</em> need.{" "}
      <Link
        className={styles["planning-disclaimer-short__link"]}
        onClick={openPlanningDisclaimerModal}
      >
        Find out more
      </Link>
    </p>
  </div>
);

export default ProductsDisclaimer;
