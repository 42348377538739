import MedicaidLeadContext from "contexts/MedicaidLeadContext";
import {
  fetchMedicaidLead,
  getMedicaidLeadCalculation,
} from "modules/medicaid/actions";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import MedicaidAnalysis from "../MedicaidAnalysis";
import UnqualifiedForMedicaidMessage from "../MedicaidAnalysis/UnqualifiedForMedicaidMessage";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const MedicaidAnalysisContainer = () => {
  const dispatch = useDispatch();
  const { medicaidLeadId } = useParams<{ medicaidLeadId?: string }>();
  const { lead, analysis, isLoadingMedicaidAnalysis, isLoadingMedicaidLead } =
    useContext(MedicaidLeadContext);

  if (medicaidLeadId === undefined) {
    return <Redirect to={ROUTE_TEMPLATE.ROOT} />;
  }

  if (analysis?.calculationError) {
    return (
      <h3>
        Sorry, there was an issue with your asset protection analysis. Please
        try again later.
      </h3>
    );
  }

  if (lead === undefined || lead.medicaidLeadId !== medicaidLeadId) {
    !isLoadingMedicaidLead && fetchMedicaidLead(dispatch, medicaidLeadId);

    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  if (
    analysis?.calculation === undefined ||
    analysis?.medicaidLeadId !== medicaidLeadId
  ) {
    !isLoadingMedicaidAnalysis &&
      getMedicaidLeadCalculation(dispatch, medicaidLeadId);

    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  const clientQualifiesForMedicaid =
    analysis.calculation.answers.monthlyDeficit > 0 || true;

  return clientQualifiesForMedicaid ? (
    <MedicaidAnalysis
      medicaidLeadId={medicaidLeadId}
      medicaidLeadStatus={lead.status}
      calculation={analysis.calculation}
      partnerId={lead?.partnerId}
    />
  ) : (
    <UnqualifiedForMedicaidMessage calculation={analysis.calculation} />
  );
};

export default MedicaidAnalysisContainer;
