import * as React from "react";
import { useState } from "react";
import { useField } from "formik";
import { useId } from "react-id-generator";

import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import Autocomplete from "components/Forms/Inputs/Autocomplete";
import { Sanitisers } from "components/Forms/Inputs/Input/helpers";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

export type QuestionAutocompleteProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  maxItems?: number;
  maxLength?: number;
  name: string;
  options?: Array<{ label: string; value: any }>;
  placeholder?: string;
  size?: QuestionSizes;
  tabIndex?: number;
};

const QuestionAutocomplete: React.FunctionComponent<
  QuestionAutocompleteProps
> = (props) => {
  const [questionId] = useId(1, "question-");
  const [inputValue, setInputValue] = useState("");
  const [, meta, helpers] = useField(props.name);
  const { setTouched, setValue } = helpers;

  // Reset invalid value
  React.useEffect(() => {
    if (meta.value && !props.options?.find((opt) => opt.value === meta.value)) {
      helpers.setValue(null);
    }
  }, [helpers, meta.error, meta.touched, meta.value, props.options]);

  return (
    <QuestionWrapper
      blurTouched={true}
      className="question--autocomplete"
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={props.size || QuestionSizes.LARGE}
    >
      <Autocomplete
        disabled={props.disabled}
        id={questionId}
        inputValue={inputValue}
        invalid={meta.touched && meta.error ? true : false}
        maxItems={props.maxItems}
        maxLength={props.maxLength}
        name={props.name}
        onClear={() => {
          setValue("");
          setInputValue("");
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(
            newInputValue.replace(Sanitisers.LEADING_WHITESPACE, ""),
          );
          const optionMatchingInput = props.options?.find(
            (option) =>
              option.label.toLowerCase() === newInputValue.toLowerCase(),
          );
          if (optionMatchingInput) {
            setValue(optionMatchingInput.value);
            setTimeout(() => {
              setTouched(true);
            }, 0);
          }
        }}
        onValueChange={(_event, newValue) => {
          setValue(newValue?.value);
          setTimeout(() => {
            setTouched(true);
          }, 0);
        }}
        options={props.options}
        getOptionLabel={(option) => option.label}
        placeholder={props.placeholder}
        tabIndex={props.tabIndex}
        valid={meta.touched && !meta.error}
        value={props.options?.find((option) => option.value === meta.value)}
      />
    </QuestionWrapper>
  );
};

export default QuestionAutocomplete;
