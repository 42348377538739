import React, { useEffect } from "react";
import { Formik, Form as FormikForm, FormikProps, FormikErrors } from "formik";
import isEqual from "lodash/isEqual";
import * as yup from "yup";

import usePrevious from "utils/usePrevious";
import ControlBar from "./ControlBar";
import getDelta from "./getDelta";
import { QuestionID } from "modules/workflow/types/Questionnaire";
import { mapValues } from "lodash";
import ValidationError from "modules/workflow/types/ValidationError";
import getTouchedFields from "./getTouchedFields";
import { AnswersMap } from "modules/workflow/types/Answer";

export type FormSubmitHandle = (changesOnly?: boolean) => Promise<void>;

export type FormProps = {
  beforeLeave?: (values: AnswersMap | undefined) => any;
  children?:
    | ((
        submitHandle: FormSubmitHandle,
        formHandle: FormikProps<AnswersMap>,
      ) => JSX.Element | null)
    | any;
  customActions?: JSX.Element | JSX.Element[];
  filterValidationErrors?: (
    allErrors: FormikErrors<AnswersMap>,
    isDelta: boolean,
  ) => FormikErrors<AnswersMap>;
  forceFullPageSubmitOnForward?: boolean;
  formKey?: any;
  onBack?: () => any;
  onExit?: () => any;
  onFormChanged?: (values: AnswersMap) => any;
  onFormInvalid?: (validationErrors: object, answers: AnswersMap) => any;
  onNext?: () => any;
  onSave?: (values: AnswersMap, isDelta: boolean) => any;
  renderFooter?: (submitHandle: FormSubmitHandle) => JSX.Element | null;
  resetOnSave?: boolean;
  saveButtonLabel?: string;
  saveOnNavigate?: boolean;
  schema: yup.ObjectSchema;
  showBackButton?: boolean;
  showExitButton?: boolean;
  showNextButton?: boolean;
  showSaveAndExitButton?: boolean;
  showSaveButton?: boolean;
  showSubmitButton?: boolean;
  state: AnswersMap;
  submitButtonLabel?: string;
  validationErrors?: Record<QuestionID, any>;
};

type FormWrapperProps = {
  values: AnswersMap;
  onFormChanged?: (values: AnswersMap, delta: AnswersMap) => any;
};

// A wrapper detecting form changes and calling the callback as Formik doesn't do that natively.
// https://github.com/jaredpalmer/formik/issues/1633#issuecomment-630870985
export const FormWrapper: React.FunctionComponent<FormWrapperProps> = (
  props,
) => {
  const { values, children, onFormChanged } = props;
  const previousValues = usePrevious(values);
  useEffect(() => {
    if (
      onFormChanged &&
      previousValues && // Don't trigger `formChanged` when initialized
      values !== previousValues &&
      !isEqual(values, previousValues)
    ) {
      const delta = getDelta(values, previousValues);
      onFormChanged(values, delta);
    }
  }, [onFormChanged, previousValues, values]);

  return <>{children}</>;
};

const Form: React.FunctionComponent<FormProps> = ({
  beforeLeave,
  children,
  customActions,
  filterValidationErrors,
  forceFullPageSubmitOnForward,
  formKey,
  onBack,
  onExit,
  onFormChanged,
  onFormInvalid,
  onNext,
  onSave,
  renderFooter,
  resetOnSave,
  saveButtonLabel,
  saveOnNavigate = true,
  schema,
  showBackButton,
  showExitButton,
  showNextButton,
  showSaveAndExitButton,
  showSaveButton,
  showSubmitButton,
  state,
  submitButtonLabel,
  validationErrors,
}) => {
  const handleFormSubmit = async (values: AnswersMap) => {
    onSave && (await onSave(values, false));
  };

  const initialErrors: Record<QuestionID, any> = mapValues(
    validationErrors || {},
    (error) => (Array.isArray(error) ? error.join(", ") : error),
  );
  const initialTouched: Record<QuestionID, boolean> =
    getTouchedFields(initialErrors);
  const previousErrors = usePrevious(initialErrors);
  const previousState = usePrevious(state);

  const validateForm = async (
    formHelpers: FormikProps<AnswersMap>,
    changesOnly?: boolean,
  ) => {
    const errors = await formHelpers.validateForm();
    const relevantErrors = filterValidationErrors
      ? filterValidationErrors(errors, Boolean(changesOnly))
      : errors;
    const hasValidationErrors = Object.keys(relevantErrors).length > 0;

    if (hasValidationErrors) {
      formHelpers.setErrors(relevantErrors);
      formHelpers.setTouched(getTouchedFields(relevantErrors));
      onFormInvalid && onFormInvalid(relevantErrors, formHelpers.values);
      throw new ValidationError(relevantErrors);
    }
  };

  const validateAndSave = async (
    formHelpers: FormikProps<AnswersMap>,
    changesOnly: boolean = false,
  ) => {
    await validateForm(formHelpers, changesOnly);
    onSave && (await onSave(formHelpers.values, changesOnly));
    resetOnSave && formHelpers.resetForm();
    return;
  };

  const createSubmitHandle =
    (formHelpers: FormikProps<AnswersMap>): FormSubmitHandle =>
    async (changesOnly?: boolean) => {
      return validateAndSave(formHelpers, changesOnly);
    };

  return (
    <Formik
      // `enableReinitialize` breaks the form state - preferred solution using `key` prop
      // (Issue with Address lookup component)
      // https://github.com/formium/formik/issues/811
      // Allow reinitialization when initialErrors change to make Formik display passed validation errors
      enableReinitialize={
        !isEqual(previousErrors, initialErrors) ||
        !isEqual(
          Object.keys(state).sort(),
          Object.keys(previousState || []).sort(),
        )
      }
      key={formKey}
      initialTouched={initialTouched}
      initialErrors={initialErrors}
      initialValues={state}
      validationSchema={schema}
      // Submit through an inner submit button
      // Form navigation controls are handled through `beforeLeave` prop.
      onSubmit={handleFormSubmit}
    >
      {(formikBag) => (
        <FormWrapper values={formikBag.values} onFormChanged={onFormChanged}>
          <FormikForm autoComplete="chrome-off">
            {typeof children === "function"
              ? children(createSubmitHandle(formikBag), formikBag)
              : children}
            {renderFooter ? (
              renderFooter(createSubmitHandle(formikBag))
            ) : (
              <ControlBar
                beforeLeave={beforeLeave}
                customActions={customActions}
                forceFullPageSubmitOnForward={forceFullPageSubmitOnForward}
                formikBag={formikBag}
                onBack={onBack}
                onExit={onExit}
                onNext={onNext}
                onSave={createSubmitHandle(formikBag)}
                saveButtonLabel={saveButtonLabel}
                saveOnNavigate={saveOnNavigate}
                showBackButton={showBackButton}
                showExitButton={showExitButton}
                showNextButton={showNextButton}
                showSaveAndExitButton={showSaveAndExitButton}
                showSaveButton={showSaveButton}
                showSubmitButton={showSubmitButton}
                submitButtonLabel={submitButtonLabel}
              />
            )}
          </FormikForm>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default Form;
