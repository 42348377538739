import * as React from "react";
import { useId } from "react-id-generator";

import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";
import ImageUpload, { ImageData } from "components/ImageUpload";
import { useField } from "formik";

type QuestionImageProps = {
  aspectRatio?: number;
  circular?: boolean;
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  size?: QuestionSizes;
  tabIndex?: number;
  minWidth?: number;
  minHeight?: number;
  maxFileSize?: number;
};

const QuestionImage: React.FunctionComponent<QuestionImageProps> = (props) => {
  const [questionId] = useId(1, "question-");
  const [, meta, helpers] = useField(props.name);

  const onImageChange = React.useCallback(
    (imageData: ImageData | null) => helpers.setValue(imageData),
    [helpers],
  );

  return (
    <QuestionWrapper
      className="question--image"
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={props.size || QuestionSizes.MEDIUM}
    >
      <ImageUpload
        defaultAspectRatio={props.aspectRatio}
        circular={props.circular}
        alt={props.label}
        preview={false}
        onChange={onImageChange}
        selectedImage={meta.value}
        minWidth={props.minWidth}
        minHeight={props.minHeight}
        maxFileSize={props.maxFileSize}
      />
    </QuestionWrapper>
  );
};

export default QuestionImage;
