import PageHeader from "components/PageHeader";
import React from "react";
import DraftingForm from "modules/partner/settings/components/DraftingForm";

type DraftingScreenProps = {
  onStateChange: (state: string) => void;
};

const DraftingScreen: React.FC<DraftingScreenProps> = ({ onStateChange }) => {
  return (
    <>
      <PageHeader title="Drafting" />
      <DraftingForm onStateChange={onStateChange} />
    </>
  );
};

export default DraftingScreen;
