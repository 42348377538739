import {
  AdminWorkflowStatus,
  workflowStepToAdminStatusMap,
} from "modules/api/types/WorkflowPayload";
import Workflow from "modules/workflow/types/Workflow";
import { ALL_PRODUCTS, ALL_STATUSES, ARCHIVED } from "./AdminWorkflowsScreen";

export const getSearchWords = (searchText: string) => searchText.split(" ");

export const normalizeString = (text?: string) =>
  text
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase() || "";

export const filterSearchWords = (searchText: string, workflow: Workflow) => {
  const searchWords = getSearchWords(searchText);
  if (!searchWords.length) return true;
  return searchWords.every(
    (searchWord) =>
      normalizeString(workflow.state.clientFullName || "").indexOf(
        normalizeString(searchWord),
      ) !== -1,
  );
};

export const filterStatus = (
  selectedStatus: AdminWorkflowStatus | typeof ALL_STATUSES | typeof ARCHIVED,
  workflow: Workflow,
) => {
  if (selectedStatus === ALL_STATUSES) return !workflow.archived;
  if (selectedStatus === ARCHIVED) return workflow.archived;
  return (
    workflowStepToAdminStatusMap[workflow.state.steps.current.step] ===
      selectedStatus && !workflow.archived
  );
};

export const filterProducts = (
  selectedProductId: string,
  workflow: Workflow,
) => {
  if (selectedProductId === ALL_PRODUCTS) return true;
  return !!workflow.state.products.find(
    (product) => selectedProductId === product.productId,
  );
};
