import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export enum SelectorTitleStyle {
  DEFAULT,
  WIDE,
}

type Props = { title: string; titleStyle?: SelectorTitleStyle };

const Selector: React.FC<Props> = ({ title, titleStyle, children }) => {
  return (
    <div className={styles["selector"]}>
      <header className={styles["selector__header"]}>
        <h1
          className={cx("selector__title", {
            "selector__title--wide": titleStyle === SelectorTitleStyle.WIDE,
          })}
        >
          {title}
        </h1>
      </header>
      <ul className={styles["selector__list"]}>{children}</ul>
    </div>
  );
};

export default Selector;
