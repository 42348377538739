import * as React from "react";
import Button, { ButtonProps } from "../Button";

import styles from "./styles.module.scss";

export type RadioButtonProps = Omit<ButtonProps, "styles" | "className"> & {
  className?: string;
};

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const defaultClassNames = props.icon
    ? ["button-radio", "button-radio--icon"]
    : ["button-radio"];
  const buttonProps: ButtonProps = {
    styles,
    ...props,
    className: [...defaultClassNames, props.className || ""],
  };
  return <Button {...buttonProps} />;
};

export default RadioButton;
