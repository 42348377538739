import { Middleware } from "@reduxjs/toolkit";
import { LOCATION_CHANGE, goBack } from "connected-react-router";
import type { RootState } from "redux/reducer";
import { userProfileIncomplete } from "../auth/actions";
import { getRoute, ROUTE_TEMPLATE } from "./routes";
import { registrationCompleted } from "modules/userProfile/actions";
import LoginCallbackMeta from "modules/auth/loginCallback/LoginCallbackMeta";
import { go, isPath, redirect } from "./helpers/navigator";
import { fetchWorkflowDetailsFailed } from "modules/workflow/actions";

const createNavigationMiddleware: () => Middleware =
  () =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case LOCATION_CHANGE: {
        const { user, incompleteProfile }: RootState = getState();
        const whitelistedRouteTemplates = [ROUTE_TEMPLATE.PROFILE];

        // Prevent forbidden navigation if the profile has not been completed
        if (
          user &&
          incompleteProfile &&
          !whitelistedRouteTemplates.find((template) =>
            isPath(template, action.payload.location),
          )
        ) {
          dispatch(goBack());
        }
        window.scrollTo(0, 0);
        break;
      }

      case userProfileIncomplete.type: {
        const { router }: RootState = getState();
        if (!isPath(ROUTE_TEMPLATE.PROFILE, router.location)) {
          go(dispatch, ROUTE_TEMPLATE.PROFILE, router.location.state as {});
        }
        break;
      }

      case registrationCompleted.type: {
        const { router }: RootState = getState();

        const locationState = router.location.state as
          | Record<string, any>
          | undefined;
        const previousLocation = locationState?.from;
        const preventRedirect = (
          locationState?.meta as LoginCallbackMeta | undefined
        )?.preventLoginRedirect;

        // Navigate after the store is updated
        if (!preventRedirect) {
          setTimeout(
            () => go(dispatch, previousLocation || ROUTE_TEMPLATE.WORKFLOW),
            0,
          );
        }
        break;
      }

      case fetchWorkflowDetailsFailed.type: {
        redirect(dispatch, getRoute(ROUTE_TEMPLATE.WORKFLOW));
      }
    }

    next(action);
  };

export default createNavigationMiddleware;
