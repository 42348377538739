import { Middleware } from "@reduxjs/toolkit";

import Theme from "./types/Theme";
import type { RootState } from "redux/reducer";
import { previewTheme, restoreTheme, themeChanged } from "./actions";
import { partnerLoaded, partnerSaved } from "modules/partner/actions";

const setTheme = (theme: Theme) => {
  // Implementation using css-global-variables module deleted due to issues with the module
};

const createThemeMiddleware: () => Middleware =
  () =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case partnerSaved.type:
      case partnerLoaded.type: {
        // const partner: Partner = action.payload;
        // const theme = { ...defaultTheme, ...(partner.data?.config?.theme || {}) };
        // dispatch(themeChanged(theme));
        break;
      }

      case themeChanged.type: {
        setTheme(action.payload);
        break;
      }

      case restoreTheme.type: {
        // const { partner } = getState() as RootState;
        // const partnerTheme = { ...defaultTheme, ...(partner?.data?.config?.theme || {}) };
        // setTheme(partnerTheme);
        break;
      }

      case previewTheme.type: {
        // Apply theme after the store gets updated
        setTimeout(() => {
          const { themePreview } = getState() as RootState;
          themePreview && setTheme(themePreview);
        }, 0);
        break;
      }
    }

    next(action);
  };

export default createThemeMiddleware;
