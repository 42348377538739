import classNames from "classnames/bind";
import { InputStringPrimitive } from "components/Forms/Inputs/Input/String";
import Link from "components/Link";
import TabbedButton from "components/TabbedButton";
import { OrderItemType } from "modules/api/types/Payment";
import { applyCoupon, initPayment } from "modules/payment/actions";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";
import styles from "./styles.module.scss";
import { useFormikContext } from "formik";
import Partner from "../../../../parties/types/Partner";
const cx = classNames.bind(styles);

type CouponProps = {
  partner?: Partner;
};

const Coupon: React.FC<CouponProps> = (partner) => {
  const dispatch = useDispatch();
  const formikProps = useFormikContext();

  const { workflows, activePayment } = useSelector(
    (state) => state,
    shallowEqual,
  );
  const { id: workflowId } = useParams<{ id: string }>();

  const [couponValue, setCouponValue] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  useEffect(() => {
    if (!showDisclaimer && couponValue) {
      setShowDisclaimer(true);
    }
  }, [showDisclaimer, couponValue]);

  const setUpperCaseCouponValue = (newCouponValue: string) => {
    setCouponValue(newCouponValue.toLocaleUpperCase());
  };

  const DISCLAIMER =
    "Use of a promo code authorizes the law firm to notify the provider of the code that you used it. The law firm will not share any of your personal information.";

  const appliedCoupon = activePayment?.orderItems?.find(
    (item) => item.type === OrderItemType.DISCOUNT,
  )?.name;

  const workflow = workflows[workflowId];

  const handleAddCoupon = async () => {
    setIsPending(true);
    await applyCoupon(workflow, couponValue)(dispatch);
    formikProps.setFieldValue("confirmEAClient", false, false);
    if (partner !== undefined) {
      formikProps.setFieldValue("confirmEASpouse", false, false);
    }
    setCouponValue("");
    setIsPending(false);
  };

  const handleRemoveCoupon = async () => {
    setIsPending(true);
    setCouponValue("");
    await initPayment(workflow)(dispatch);
    formikProps.setFieldValue("confirmEAClient", false, false);
    if (partner !== undefined) {
      formikProps.setFieldValue("confirmEASpouse", false, false);
    }
    setIsPending(false);
  };

  const renderInput = () => (
    <>
      <div className={styles["coupon__input-wrapper"]}>
        <InputStringPrimitive
          value={couponValue}
          onChangeValue={setUpperCaseCouponValue}
          placeholder="Coupon code"
        />
        <TabbedButton
          addClassName={cx("coupon__add-button", {
            "coupon__add-button--loading": isPending,
          })}
          onClick={handleAddCoupon}
          disabled={!couponValue || isPending}
        >
          Apply
        </TabbedButton>
      </div>
    </>
  );

  const renderApplied = () => (
    <div>
      {appliedCoupon}
      <br />
      <Link disabled={isPending} onClick={handleRemoveCoupon}>
        {isPending ? "Removing..." : "Remove coupon"}
      </Link>
    </div>
  );

  const renderDisclaimer = () => (
    <div className={styles["coupon__disclaimer"]}>{DISCLAIMER}</div>
  );

  return (
    <div className={styles["coupon"]}>
      <h2 className={styles["coupon__title"]}>Coupon</h2>
      {appliedCoupon ? renderApplied() : renderInput()}
      {showDisclaimer && renderDisclaimer()}
    </div>
  );
};

export default Coupon;
