import React, { useContext } from "react";

import { PartnerID } from "modules/parties/types/Partner";
import { Box, Typography, Grid } from "@mui/material";
import DataLayerContext from "contexts/DataLayerContext";
import {
  buildEcommerceItemsArray,
  getDefinition,
  getEnabledProductsAndPlans,
  getLandingButtonLabels,
  getProductAndPlanIds,
  shouldShowPlansHeading,
} from "modules/landing/helpers";
import { useDispatch, shallowEqual } from "react-redux";
import { useSelector } from "redux/reducer";
import {
  getItemListName,
  getEcommerceItemName,
  getEcommerceItemIdx,
  getEcommerceItemCategory,
} from "contexts/DataLayerContext/helpers";
import {
  CTALocation,
  CTAServiceName,
  CTAType,
  EcommerceItemID,
  EventName,
  ItemListID,
} from "contexts/DataLayerContext/types";
import {
  getPartnerRoute,
  ROUTE_TEMPLATE,
  getRoute,
} from "modules/navigation/routes";
import { go } from "modules/navigation/helpers/navigator";
import PackageOffering from "../PackageOffering";
import { essentialsPackageItems, powersPackageItems } from "./constants";

type PackagesProps = {
  partnerId: PartnerID | undefined;
};

const Packages: React.FC<PackagesProps> = ({ partnerId }) => {
  const dispatch = useDispatch();
  const { availableWorkflows } = useSelector(
    (state) => ({
      availableWorkflows: state.availableWorkflows,
      isLoadingProducts: state.isLoadingProducts,
    }),
    shallowEqual,
  );
  const { sendEvent } = useContext(DataLayerContext);

  const definition = getDefinition(availableWorkflows, partnerId);

  const sendSelectItemEvent = (ecommerceItemId: EcommerceItemID) => {
    if (ecommerceItemId !== undefined) {
      if (partnerId === undefined) {
        sendEvent?.({
          event: EventName.SelectItem,
          item_list_id: ItemListID.NationalLanding,
          item_list_name: getItemListName(ItemListID.NationalLanding),
          items: [
            {
              item_id: ecommerceItemId,
              item_name: getEcommerceItemName(ecommerceItemId),
              index: getEcommerceItemIdx(ecommerceItemId, {
                [EcommerceItemID.WILL]: 0,
                [EcommerceItemID.HCP]: 1,
                [EcommerceItemID.POA]: 2,
              }),
              item_brand: partnerId,
              item_category: getEcommerceItemCategory(ecommerceItemId),
              item_list_id: ItemListID.NationalLanding,
              item_list_name: getItemListName(ItemListID.NationalLanding),
              quantity: 1,
            },
          ],
        });
      } else {
        const ecommerceItem = buildEcommerceItemsArray(
          definition,
          ItemListID.PartnerLanding,
          partnerId,
        ).find((item) => item.item_id === ecommerceItemId);

        ecommerceItem !== undefined &&
          sendEvent?.({
            event: EventName.SelectItem,
            item_list_id: ItemListID.PartnerLanding,
            item_list_name: getItemListName(ItemListID.PartnerLanding),
            items: [ecommerceItem],
          });
      }
    }
  };

  const { essentials: essentialsPrice, powers: powersPrice } =
    getLandingButtonLabels(definition);

  const { essentials: essentialsEnabled, powers: powersEnabled } =
    getEnabledProductsAndPlans(definition);

  const { essentials: essentialsId, powers: powersId } =
    getProductAndPlanIds(definition);

  const showPackages = shouldShowPlansHeading(definition);

  const handleNavigateToPlanTailoring =
    (
      buttonLabel: string,
      ctaServiceName: CTAServiceName,
      planId?: string,
      ecommerceItemId?: EcommerceItemID,
    ) =>
    () => {
      sendEvent?.({
        event: EventName.CTAClick,
        cta_type: CTAType.SERVICE_BUTTON,
        cta_location: CTALocation.LIST_SERVICES,
        cta_service_name: ctaServiceName,
        cta_text: buttonLabel,
      });

      ecommerceItemId && sendSelectItemEvent(ecommerceItemId);

      partnerId && definition && planId
        ? go(
            dispatch,
            getPartnerRoute(partnerId, ROUTE_TEMPLATE.PLAN_TAILORING, {
              workflowId: definition.id,
              planId,
            }),
          )
        : go(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));
    };

  if (!showPackages) return null;

  return (
    <Box>
      <Typography variant="h3">Packages</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <PackageOffering
            title="Essentials Package"
            description="Bundle your Healthcare Directive, Power of Attorney and Will into one convenient plan."
            price={essentialsPrice}
            enabled={essentialsEnabled}
            handleSelect={handleNavigateToPlanTailoring(
              essentialsPrice,
              CTAServiceName.ESSENTIALS,
              essentialsId,
              EcommerceItemID.ESSENTIALS,
            )}
            items={essentialsPackageItems}
            bestDeal
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PackageOffering
            title="Powers Package"
            description="Appoint your agents for healthcare and financial and legal decisions. If you also need a Will, choose the Essentials Package."
            price={powersPrice}
            enabled={powersEnabled}
            handleSelect={handleNavigateToPlanTailoring(
              powersPrice,
              CTAServiceName.POWERS,
              powersId,
              EcommerceItemID.POWERS,
            )}
            items={powersPackageItems}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Packages;
