import Condition from ".";

export enum ClientDetailsConstraintType {
  State = "State",
  IsMarried = "IsMarried",
  IsGender = "IsGender",
  IsOlderThan = "IsOlderThan",
}

export type ClientStateConstraint = {
  type: ClientDetailsConstraintType.State;
  value: string;
};

export type ClientMarriedConstraint = {
  type: ClientDetailsConstraintType.IsMarried;
};

export type ClientGenderConstraint = {
  type: ClientDetailsConstraintType.IsGender;
  value: string;
};

export type ClientOlderThanConstraint = {
  type: ClientDetailsConstraintType.IsOlderThan;
  age: number;
};

export type ClientDetailsConstraint =
  | ClientStateConstraint
  | ClientMarriedConstraint
  | ClientGenderConstraint
  | ClientOlderThanConstraint;

export type ClientCondition = { client: ClientDetailsConstraint };

export const isClientCondition = (
  condition: Condition,
): condition is ClientCondition =>
  condition.hasOwnProperty("client") &&
  Boolean((condition as ClientCondition).client.type);

export type SpouseCondition = { spouse: ClientDetailsConstraint };

export const isSpouseCondition = (
  condition: Condition,
): condition is SpouseCondition =>
  condition.hasOwnProperty("spouse") &&
  Boolean((condition as SpouseCondition).spouse.type);
