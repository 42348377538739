import { createAction, Dispatch } from "@reduxjs/toolkit";
import { API, del, get, getPartnerHeader, patch, post } from "modules/api";
import logger, { TYPE } from "../../logger";
import Partner, {
  PartnerData,
  PartnerRegistration,
  PartnerRegistrationRequest,
  PartnerAudit,
  PartnerAudits,
} from "modules/parties/types/Partner";
import PartnerResponseType, {
  PartnerApiPayload,
} from "modules/api/responseTypes/PartnerResponseType";
import {
  parsePageFromPayload,
  parseQuestionFromPayload,
} from "modules/workflow/helpers/parseQuestionnaire";
import { mapValues, orderBy } from "lodash";
import PartnerListingResponseType, {
  SearchPartnerPayload,
} from "modules/api/responseTypes/PartnerListingResponseType";
import stripHtml from "utils/stripHtml";
import ApiError from "modules/api/types/ApiError";
import PartnerAuditResponseType from "modules/api/responseTypes/PartnerAuditResponseType";
import PartnerAuditsResponseType from "modules/api/responseTypes/PartnerAuditsResponseType";
import { AnswersMap } from "modules/workflow/types/Answer";

export const partnerSelected = createAction<string | null>("PARTNER_SELECTED");
export const partnerUnselected = createAction<string | null>(
  "PARTNER_UNSELECTED",
);
export const loadPartnerStarted = createAction("LOAD_PARTNER_STARTED");
export const loadingPartnerFailed = createAction<any>("LOAD_PARTNER_FAILED");
export const partnerLoaded = createAction<Partner>("PARTNER_LOADED");
export const partnerIdInvalid = createAction("PARTNER_ID_INVALID");

export const updatePartnerStarted = createAction("SAVE_PARTNER_STARTED");
export const savePartnerFailed = createAction<any>("SAVE_PARTNER_FAILED");
export const partnerSaved = createAction<Partner>("PARTNER_SAVED");

export const draftingAnswersChanged = createAction<AnswersMap>(
  "DRAFTING_ANSWERS_CHANGED",
);

export const resetDraftingForm = createAction("RESET_DRAFTING_FORM");

const parsePartner = (apiPayload: PartnerApiPayload): Partner => {
  const { partner, onboardingQuestionnaire, integrations } = apiPayload;

  if (onboardingQuestionnaire) {
    partner.onboardingQuestionnaire = {
      ...onboardingQuestionnaire.questionnaire,

      pages: onboardingQuestionnaire.questionnaire.pages.map((page, idx) =>
        parsePageFromPayload(
          page.questionnaireId || onboardingQuestionnaire.questionnaire.id,
          page,
          idx,
        ),
      ),

      questions: mapValues(onboardingQuestionnaire.questions, (question) =>
        parseQuestionFromPayload(
          onboardingQuestionnaire.questionnaire.id,
          question,
        ),
      ),
    };
  }
  if (integrations) {
    partner.integrations = integrations;
  }
  return partner;
};

const callSavePartnerAPI = async (
  partnerData: Partial<PartnerData>,
): Promise<Partner> => {
  const response = await patch(API.PARTNER(), partnerData);
  return response.data as Partner;
};

export const callGetPartners = async (): Promise<Partner[]> => {
  const response = await get(API.PARTNERS());
  return orderBy(response.data.partners, [
    (partner) => partner.data.displayName.toLowerCase(),
    "id",
  ]);
};

export const callGetPartnerDetailsAPI = async (
  partnerId?: string,
): Promise<Partner> => {
  const response: PartnerResponseType = await get(
    API.PARTNER(),
    undefined,
    getPartnerHeader(partnerId),
  );
  return parsePartner(response.data);
};

export const callGetPartnerAuditAPI = async (
  partnerId?: string,
): Promise<PartnerAudit> => {
  const response: PartnerAuditResponseType = await get(
    API.LATEST_PARTNER_AUDIT(),
    undefined,
  );
  return response.data;
};

export const callGetPartnerAuditsAPI = async (
  partnerId?: string,
): Promise<PartnerAudits> => {
  const response: PartnerAuditsResponseType = await get(
    API.PARTNER_AUDITS(),
    undefined,
  );
  return response.data;
};

export const callRegisterNewPartnerAPI = async (
  partnerData: PartnerRegistrationRequest,
): Promise<PartnerRegistration> => {
  const response = await post(API.PARTNER_REGISTRATION(), partnerData);
  return response.data;
};

export const callResubmitPartnerRegistrationAPI = async (
  registrationId: string,
  partnerData: PartnerRegistrationRequest,
): Promise<PartnerRegistration> => {
  const response = await patch(
    API.PARTNER_REGISTRATION_INSTANCE({ registrationId }),
    partnerData,
  );
  return response.data;
};

export const callDeletePartnerRegistrationAPI = async (
  registrationId: string,
): Promise<PartnerRegistration> => {
  const response = await del(
    API.PARTNER_REGISTRATION_INSTANCE({ registrationId }),
  );
  return response.data;
};

const callFindPartnerByZipAPI = async (
  zip: string,
  medicaidFilter?: boolean,
): Promise<SearchPartnerPayload> => {
  const response: PartnerListingResponseType = await get(API.PARTNERS(), {
    byZip: zip,
    medicaidFilter,
  });
  return response.data;
};

export const callOauthCallback = async (code: string): Promise<Boolean> => {
  const response = await post(
    API.PARTNER_INTEGRATIONS({ integrationId: "ActionStep" }),
    { code },
  );
  return response !== null;
};

export const loadPartnerDetails =
  (partnerId?: string) => async (dispatch: Dispatch) => {
    dispatch(loadPartnerStarted());
    try {
      const partnerDetails = await callGetPartnerDetailsAPI(partnerId);
      if (!partnerDetails) {
        throw new Error("Invalid response from the server");
      }

      dispatch(partnerLoaded(partnerDetails));
      return partnerDetails;
    } catch (error) {
      if ((error as any)?.response?.status === 404) {
        dispatch(partnerIdInvalid());
      } else {
        logger.notify(
          TYPE.ERROR,
          "Loading the data failed. Please, try again later",
          error,
        );
        dispatch(loadingPartnerFailed(error));
      }
    }
  };

export const savePartner =
  (partnerData: Partial<PartnerData>) => async (dispatch: Dispatch) => {
    dispatch(updatePartnerStarted());
    try {
      const sanitizedContactData = partnerData.contact
        ? {
            contact: {
              ...partnerData.contact,

              email: stripHtml(partnerData.contact?.email),
              billingEmail: stripHtml(partnerData.contact?.billingEmail),
            },
          }
        : {};

      const sanitizedData: Partial<PartnerData> = {
        ...partnerData,
        displayName: stripHtml(partnerData.displayName),
        attorney: stripHtml(partnerData.attorney),
        openingHours: stripHtml(partnerData.openingHours),
        ...sanitizedContactData,
      };
      const updatedPartner = await callSavePartnerAPI(sanitizedData);
      if (!updatedPartner) {
        throw new Error("Invalid response from the server");
      }

      dispatch(partnerSaved(updatedPartner));
    } catch (error) {
      logger.notify(
        TYPE.ERROR,
        "Saving partner details failed. Please, try again later",
        error,
      );
      dispatch(savePartnerFailed(error));
      throw error;
    }
  };

export const searchPartnerStarted = createAction<string>(
  "SEARCH_PARTNER_STARTED",
);
export const searchPartnerFailed = createAction<any>("SEARCH_PARTNER_FAILED");
export const searchPartnerComplete = createAction<SearchPartnerPayload>(
  "SEARCH_PARTNER_COMPLETE",
);
export const findPartner =
  (zip: string, medicaidFilter?: boolean) => async (dispatch: Dispatch) => {
    dispatch(searchPartnerStarted(zip));
    try {
      const result = await callFindPartnerByZipAPI(zip, medicaidFilter);
      dispatch(searchPartnerComplete(result));
      return result;
    } catch (err) {
      const error: any = err;
      const defaultErrorMessage =
        "We were unable to search by the entered ZIP. Please, try again.";

      logger.notify(
        TYPE.ERROR,
        error?.response
          ? new ApiError(defaultErrorMessage, error.response)
          : defaultErrorMessage,
        error,
      );
      dispatch(searchPartnerFailed(error));
    }
  };

export const partnerRegistrationSubmitted = createAction<PartnerRegistration>(
  "PARTNER_REGISTRATION_SUBMITTED",
);

export const partnerRegistrationDeleted = createAction<string>(
  "PARTNER_REGISTRATION_DELETED",
);
export const registerPartner =
  (partnerData: PartnerRegistrationRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const result = await callRegisterNewPartnerAPI(partnerData);
    dispatch(partnerRegistrationSubmitted(result));
  };

export const resubmitPartnerRegistration =
  (registrationId: string, partnerData: PartnerRegistrationRequest) =>
  async (dispatch: Dispatch): Promise<void> => {
    const result = await callResubmitPartnerRegistrationAPI(
      registrationId,
      partnerData,
    );
    dispatch(partnerRegistrationSubmitted(result));
  };

export const deletePartnerRegistration =
  (registrationId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    await callDeletePartnerRegistrationAPI(registrationId);
    dispatch(partnerRegistrationDeleted(registrationId));
  };
