import { cloneDeep } from "lodash";
import Party from "../types/Party";

export default (sourceParties: Party[]): Party[] => {
  const allParties = cloneDeep(sourceParties);
  return allParties.reduce((result, party) => {
    const parent =
      party.parentId && allParties.find((p) => p.partyId === party.parentId);
    if (parent) {
      parent.children = [...(parent.children || []), party];
    } else {
      result.push(party);
    }
    return result;
  }, [] as Party[]);
};
