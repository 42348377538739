import * as React from "react";
import { useContext, useEffect, useMemo, useRef } from "react";
import classNames from "classnames/bind";
import { useField } from "formik";
import Inputmask from "inputmask";

import PlatformContext from "contexts/PlatformContext";
import { InputType } from "../types";
import getMask from "./masks";

import styles from "../styles.module.scss";
const cx = classNames.bind(styles);

type InputStringPrimitiveProps = Omit<InputStringProps, "name"> & {
  initialFocus?: boolean;
  invalid?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeValue?: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  valid?: boolean;
  value: string;
  prefix?: string;
  explainer?: string;
};

export const InputStringPrimitive: React.FunctionComponent<
  InputStringPrimitiveProps
> = ({
  disabled,
  id,
  initialFocus,
  invalid,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onChangeValue,
  onFocus,
  onInput,
  onKeyDown,
  placeholder,
  tabIndex,
  type = InputType.TEXT,
  valid,
  value,
  prefix,
  explainer,
}) => {
  const input = useRef<HTMLInputElement | null>(null);
  const platform = useContext(PlatformContext);
  const { inputMask, ...inputProps } = useMemo(
    () =>
      getMask({
        max: max,
        min: min,
        platform: platform,
        type: type,
      }),
    [max, min, platform, type],
  );
  const mount = () => {
    inputProps.onMount && inputProps.onMount(input);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(mount, []);
  useEffect(() => {
    if (inputMask && input && input.current && !input.current.inputmask) {
      Inputmask(inputMask).mask(input.current);
    }
  }, [input, inputMask]);
  let className = cx("input", {
    "input--disabled": disabled,
    "input--invalid": !disabled && invalid,
    "input--valid": !disabled && valid,
    "input--date": type === InputType.DATE || type === InputType.MONTH,
    "input--email": type === InputType.EMAIL,
    "input--phone": type === InputType.TEL,
    "input--prefix": Boolean(prefix),
    "input--placeholder":
      !value &&
      !inputMask &&
      (type === InputType.DATE || type === InputType.MONTH),
  });

  useEffect(() => {
    if (initialFocus) {
      input.current?.focus();
    }
  }, [initialFocus]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    onChangeValue && onChangeValue(String(event.currentTarget.value));
  };

  return (
    <div className={className}>
      {prefix && <div className={styles["prefix"]}>{prefix}</div>}
      <input
        autoComplete="chrome-off"
        disabled={disabled}
        id={id}
        inputMode={inputProps.inputMode}
        maxLength={inputProps.maxLength ? inputProps.maxLength : maxLength}
        name={name}
        pattern={inputProps.pattern}
        placeholder={
          inputProps.placeholder ? inputProps.placeholder : placeholder
        }
        onBlur={inputProps.onBlur ? inputProps.onBlur(onBlur) : onBlur}
        onChange={handleValueChange}
        onFocus={inputProps.onFocus ? inputProps.onFocus(onFocus) : onFocus}
        onInput={inputProps.onInput ? inputProps.onInput(onInput) : onInput}
        onKeyDown={
          inputProps.onKeyDown ? inputProps.onKeyDown(onKeyDown) : onKeyDown
        }
        ref={input}
        tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
        type={inputProps.type}
        value={value}
      />
      {explainer && <div className={styles["explainer"]}>{explainer}</div>}
    </div>
  );
};

export type InputStringProps = {
  disabled?: boolean;
  id?: string;
  max?: string;
  maxLength?: number;
  min?: string;
  name: string;
  placeholder?: string;
  tabIndex?: number;
  type?: InputType;
  prefix?: string;
  explainer?: string;
};

const InputString: React.FunctionComponent<InputStringProps> = (props) => {
  const [field, meta] = useField(props.name);
  const invalid = meta.error && meta.touched ? true : false;
  const valid = !meta.error && meta.touched && meta.value ? true : false;
  return (
    <InputStringPrimitive
      {...props}
      {...field}
      invalid={invalid}
      valid={valid}
    />
  );
};

export default InputString;
