import { ValueConditionConstraint, ValueConstraintType } from ".";
import { AnswerValueType } from "../../Answer";

type ContainsConstraint = {
  type: ValueConstraintType.Contains;
  value: AnswerValueType;
};

export const isContainsConstraint = (
  valueConstraint: ValueConditionConstraint,
): valueConstraint is ContainsConstraint =>
  (valueConstraint as any).type === ValueConstraintType.Contains;

export default ContainsConstraint;
