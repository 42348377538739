import {
  ApplicableJurisdiction,
  Definition,
  DefinitionDescriptions,
  DEFINITION_CATEGORY_PRODUCTS,
} from "modules/api/responseTypes/DefinitionResponseType";
import Partner from "modules/parties/types/Partner";
import { AddonID } from "modules/product/types/Addon";
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ProductConfigurationPicker from "./ProductConfigurationPicker";
import ProductForm from "./ProductForm";

type Props = {
  afterSave?: () => void;
  definition: Definition;
  descriptions: DefinitionDescriptions;
  partner: Partner;
  addonJurisdictions: Record<AddonID, ApplicableJurisdiction[]>;
};

const ProductSetup: React.FC<Props> = ({
  afterSave,
  definition,
  descriptions,
  partner,
  addonJurisdictions,
}) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${url}/:categoryKey/:productKey`}
        render={({ match }) => (
          <ProductForm
            backLink={`${url}/${match.params.categoryKey}`}
            data={definition.data}
            definitionId={definition.id}
            descriptions={descriptions}
            afterSave={afterSave}
            partner={partner}
          />
        )}
      />
      <Route path={`${url}/:categoryKey`} exact>
        <ProductConfigurationPicker
          baseRoute={url}
          partner={partner}
          data={definition.data}
          descriptions={descriptions}
          definitionId={definition.id}
          addonJurisdictions={addonJurisdictions}
        />
      </Route>
      <Redirect to={`${url}/${DEFINITION_CATEGORY_PRODUCTS}`} />
    </Switch>
  );
};

export default ProductSetup;
