export enum Environment {
  LOCAL = "LOCAL",
  DEV = "DEV",
  STAGING = "STAGING",
  PROD = "PROD",
}

const localDomain = process.env.REACT_APP_LOCAL_DOMAIN ?? "";
const devDomain = process.env.REACT_APP_DEV_DOMAIN ?? "";
const devDefaultDomain = process.env.REACT_APP_DEV_DEFAULT_DOMAIN ?? "";
const stagingDomain = process.env.REACT_APP_STAGING_DOMAIN ?? "";
const stagingDefaultDomain = process.env.REACT_APP_STAGING_DEFAULT_DOMAIN ?? "";
const prodDomain = process.env.REACT_APP_PROD_DOMAIN ?? "";
const prodDefaultDomain = process.env.REACT_APP_PROD_DEFAULT_DOMAIN ?? "";

const assetBuckets: Record<Environment, string> = {
  [Environment.LOCAL]: process.env.REACT_APP_LOCAL_ASSETS_BUCKET ?? "",
  [Environment.DEV]: process.env.REACT_APP_DEV_ASSETS_BUCKET ?? "",
  [Environment.STAGING]: process.env.REACT_APP_STAGING_ASSETS_BUCKET ?? "",
  [Environment.PROD]: process.env.REACT_APP_PROD_ASSETS_BUCKET ?? "",
};

export const getEnvironment = (hostname?: string): Environment => {
  const raw = hostname || window.location.hostname;

  if (raw.includes("localhost") || (localDomain && raw.includes(localDomain))) {
    return Environment.LOCAL;
  }
  if (
    (devDomain && raw.includes(devDomain)) ||
    (devDefaultDomain && raw.includes(devDefaultDomain))
  ) {
    return Environment.DEV;
  }
  if (
    (stagingDomain && raw.includes(stagingDomain)) ||
    (stagingDefaultDomain && raw.includes(stagingDefaultDomain))
  ) {
    return Environment.STAGING;
  }
  if (
    (prodDomain && raw.includes(prodDomain)) ||
    (prodDefaultDomain && raw.includes(prodDefaultDomain))
  ) {
    return Environment.PROD;
  }
  return Environment.DEV;
};

export const isDevEnvironment = (hostname?: string): boolean =>
  Environment.DEV === getEnvironment(hostname);

export const isLiveEnvironment = (hostname?: string): boolean =>
  getEnvironment(hostname) === Environment.PROD;

export const getAssetBucket = () => assetBuckets[getEnvironment()];

export const getLawfirmAgreementLink = () =>
  `https://firebasestorage.googleapis.com/v0/b/${getAssetBucket()}/o/${encodeURIComponent(
    process.env.REACT_APP_USER_AGREEMENT || "",
  )}?alt=media
`;
