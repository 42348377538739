import React, { useEffect } from "react";
import { RenderMedicaidDocumentsModalProps } from "./types";
import { useSelector } from "redux/reducer";
import { shallowEqual, useDispatch } from "react-redux";
import {
  getMedicaidLeadDocuments,
  renderMedicaidDocuments,
} from "modules/medicaid/actions";
import { RenderedDocumentStatus } from "modules/api/types/WorkflowDetailsPayload";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { filter } from "lodash";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const cx = classNames.bind(styles);

const RENDER_INTERVAL = 2000;

const RenderMedicaidDocumentsModal = ({
  medicaidLeadId,
}: RenderMedicaidDocumentsModalProps) => {
  const dispatch = useDispatch();

  const { documents, isRenderingMedicaidLeadDocuments } = useSelector(
    (state) => ({
      documents: state.medicaid.admin?.documents,
      isRenderingMedicaidLeadDocuments:
        state.medicaid.isRenderingMedicaidLeadDocuments,
    }),
    shallowEqual,
  );

  useEffect(() => {
    let fetchInterval: NodeJS.Timeout;
    const medicaidLeadDocuments =
      documents?.filter((doc) => doc.medicaidLeadId === medicaidLeadId) || [];

    if (
      medicaidLeadDocuments.length === 0 &&
      !isRenderingMedicaidLeadDocuments
    ) {
      renderMedicaidDocuments(dispatch, medicaidLeadId);
    } else {
      fetchInterval = setInterval(() => {
        if (
          documents !== undefined &&
          documents.length !== 0 &&
          filter(
            documents,
            (doc) => doc.status === RenderedDocumentStatus.PENDING,
          ).length === 0
        ) {
          clearInterval(fetchInterval);
        } else {
          if (!isRenderingMedicaidLeadDocuments) {
            getMedicaidLeadDocuments(dispatch, medicaidLeadId);
          }
        }
      }, RENDER_INTERVAL);
    }

    return () => {
      clearInterval(fetchInterval);
    };
  }, [dispatch, documents, isRenderingMedicaidLeadDocuments, medicaidLeadId]);

  if (documents === undefined)
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.MEDIUM} />;

  return (
    <div>
      <ol className={cx("rendered-medicaid-lead-documents")}>
        {documents.map((renderedDocument) => (
          <li
            className={cx("rendered-medicaid-lead-document")}
            key={`${renderedDocument.medicaidLeadId}-${renderedDocument.documentDefinitionId}`}
          >
            <div className={cx("rendered-medicaid-lead-document__title")}>
              {renderedDocument.displayName}:
            </div>
            {renderedDocument.status === RenderedDocumentStatus.FINISHED ? (
              <a href={renderedDocument.signedUrl}>Finished</a>
            ) : (
              <div>{renderedDocument.status}</div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default RenderMedicaidDocumentsModal;
