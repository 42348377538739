import { FormikErrors } from "formik";
import { mapValues } from "lodash";
import { AnswersMap } from "modules/workflow/types/Answer";
import { QuestionID } from "modules/workflow/types/Questionnaire";

const getTouchedFields = (
  validationErrors: FormikErrors<AnswersMap>,
): Record<QuestionID, any> =>
  mapValues(validationErrors, (value) =>
    typeof value === "object" ? getTouchedFields(value) : true,
  );

export default getTouchedFields;
