import Partner from "modules/parties/types/Partner";
import React from "react";
import PartnersAdminScreenProducts from "./components/PartnerAdminScreenProducts";
import PartnersAdminScreenSite from "./components/PartnerAdminScreenSite";

export type PartnersAdminRoute = {
  title: string;
  titleShort: string;
  render: (partner: Partner) => React.ReactNode;
};

export const partnerAdminRoutes: Record<string, PartnersAdminRoute> = {
  "site-setup": {
    title: "Site Setup",
    titleShort: "Site",
    render: (partner: Partner) => <PartnersAdminScreenSite {...{ partner }} />,
  },
  "product-setup": {
    title: "Product Configuration",
    titleShort: "Products",
    render: (partner: Partner) => (
      <PartnersAdminScreenProducts {...{ partner }} />
    ),
  },
} as const;

export type PartnersAdminRouteSlug = keyof typeof partnerAdminRoutes;
