import { isEqual, keyBy } from "lodash";
import Workflow from "../types/Workflow";

export type CoupledWorkflow = {
  primary: Workflow;
  secondary: Workflow;
};

export const isCoupledWorkflow = (
  item: Workflow | CoupledWorkflow,
): item is CoupledWorkflow =>
  isEqual(Object.keys(item), ["primary", "secondary"]);

export default (workflows: Workflow[]): Array<Workflow | CoupledWorkflow> => {
  const workflowsByIds = keyBy(workflows, "id");

  return Object.values(
    workflows.reduce(
      (res, wf) => {
        const coupled =
          wf.state.coupledWorkflowId &&
          workflowsByIds[wf.state.coupledWorkflowId];

        if (!coupled) {
          res[wf.id] = wf;
        } else if (wf.state.primary) {
          res[wf.id] = { primary: wf, secondary: coupled };
        } else {
          res[coupled.id] = { primary: coupled, secondary: wf };
        }
        return res;
      },
      {} as Record<string, Workflow | CoupledWorkflow>,
    ),
  );
};
