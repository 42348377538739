import Party, { getFullName } from "modules/parties/types/Party";
import * as React from "react";

import styles from "./styles.module.scss";

export type PartyPickerModalContentProps = {
  parties: Party[];
  onSelect: (party: Party) => void;
};

const PartyPickerModalContent: React.FC<PartyPickerModalContentProps> = ({
  parties,
  onSelect,
}) => (
  <ul className={styles.list}>
    {parties.map((party) => (
      <li key={party.partyId} onClick={() => onSelect(party)}>
        <div className={styles.item}>
          <h4 className={styles.name}>{getFullName(party)}</h4>
          <div className={styles.dob}>(Born: {party.dateOfBirth})</div>
        </div>
      </li>
    ))}
  </ul>
);

export default PartyPickerModalContent;
