import firebase from "../../lib/firebase";
import "firebase/compat/auth";

const authProvider = firebase.auth();

export const authConfig = {
  credentialHelper: "none",
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

export const getIdToken = async (): Promise<string | undefined> =>
  authProvider.currentUser?.getIdToken();

export const signOut = async (): Promise<void> => authProvider.signOut();

export default authProvider;
