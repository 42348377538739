import PaginatedList, { ListType } from "components/PaginatedList";
import { PartnerPermission } from "modules/admin/types/Permissions";
import React from "react";
import PermissionsConfigurationListItem from "./listItem";

type Props = { permissions: PartnerPermission[] };
type Column = {
  label: string;
  accessor: string;
  sortable: boolean;
  reset: boolean;
};

const columns: Column[] = [
  { label: "Name", accessor: "name", sortable: false, reset: false },
  { label: "E-mail", accessor: "email", sortable: false, reset: false },
  { label: "Role", accessor: "role", sortable: false, reset: false },
];

const PermissionConfigurationList: React.FC<Props> = ({ permissions }) => {
  return (
    <PaginatedList<PartnerPermission>
      listType={ListType.TABLE}
      renderItem={(permission) => (
        <PermissionsConfigurationListItem
          key={permission.user.userId}
          {...{ permission }}
        />
      )}
      tableColumns={[
        ...columns,
        { label: "", accessor: "empty", sortable: false, reset: false },
      ]}
      items={permissions}
      itemNamePlural="workflows"
      pageSize={6}
    />
  );
};

export default PermissionConfigurationList;
