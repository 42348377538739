import React, { ReactNode } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type SelectorFeatureListProps = {
  children?: ReactNode;
  disabled?: boolean;
  selected?: boolean;
  recommended?: boolean;
};

const SelectorFeatureList = ({
  children,
  disabled,
  selected,
  recommended,
}: SelectorFeatureListProps) => {
  let classNames = cx("selector-feature-list", {
    "selector-feature-list--disabled": disabled,
    "selector-feature-list--selected": selected,
    "selector-feature-list--recommended": recommended,
  });

  return <ul className={classNames}>{children}</ul>;
};

export default SelectorFeatureList;
