import { AnswerValueType } from "../../Answer";
import ContainsConstraint from "./ContainsConstraint";
import DatediffConstraint from "./DatediffConstraint";
import SelectedMoreThanConstraint from "./SelectedMoreThanConstraint";

export enum ValueConstraintType {
  SelectedMoreThan = "SelectedMoreThan",
  Contains = "Contains",
  DateDiff = "DateDiff",
}

export type ValueConditionConstraint =
  | AnswerValueType
  | SelectedMoreThanConstraint
  | ContainsConstraint
  | DatediffConstraint;

type ValueCondition = {
  [questionnaireId: string]: { [questionId: string]: ValueConditionConstraint };
};

export default ValueCondition;
