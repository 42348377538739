import {
  AcceptedMedicaidLeadPreview,
  EnrichedMedicaidLeadWithSimpleCalculation,
  MedicaidLeadPreview,
  MedicaidLeadPreviewStatus,
  MedicaidLeadReviewWithPreviewStatus,
  PendingMedicaidLeadPreview,
} from "modules/medicaid/types";

export const medicaidLeadPreviewIsPending = (
  medicaidLeadPreview: MedicaidLeadReviewWithPreviewStatus,
): medicaidLeadPreview is PendingMedicaidLeadPreview =>
  medicaidLeadPreview.previewStatus === MedicaidLeadPreviewStatus.PENDING;

export const medicaidLeadPreviewIsAccepted = (
  medicaidLeadPreview: MedicaidLeadReviewWithPreviewStatus,
): medicaidLeadPreview is AcceptedMedicaidLeadPreview =>
  medicaidLeadPreview.previewStatus === MedicaidLeadPreviewStatus.ACCEPTED;

export const medicaidLeadPreviewsArePending = (
  medicaidLeadPreviews: MedicaidLeadReviewWithPreviewStatus[],
): medicaidLeadPreviews is PendingMedicaidLeadPreview[] =>
  medicaidLeadPreviews.every(
    ({ previewStatus }) => previewStatus === MedicaidLeadPreviewStatus.PENDING,
  );

export const medicaidLeadPreviewsAreAccepted = (
  medicaidLeadPreviews: MedicaidLeadReviewWithPreviewStatus[],
): medicaidLeadPreviews is AcceptedMedicaidLeadPreview[] =>
  medicaidLeadPreviews.every(
    ({ previewStatus }) => previewStatus === MedicaidLeadPreviewStatus.ACCEPTED,
  );

enum MEDICAID_LEAD_SORT_FIELDS {
  UPDATED = "updated",
  MARITAL_STATUS = "maritalStatus",
  PROTECTABLE_ASSETS = "protectableAmount",
  CS_AGE = "csAge",
  IS_AGE = "isAge",
  EMPTY = "empty",
}

export const TABLE_COLUMNS = [
  {
    label: "Updated",
    accessor: MEDICAID_LEAD_SORT_FIELDS.UPDATED,
    sortable: true,
    reset: false,
  },
  {
    label: "Marital Status",
    accessor: MEDICAID_LEAD_SORT_FIELDS.MARITAL_STATUS,
    sortable: true,
    reset: false,
  },
  {
    label: "Protectable Assets",
    accessor: MEDICAID_LEAD_SORT_FIELDS.PROTECTABLE_ASSETS,
    sortable: true,
    reset: false,
  },
  {
    label: "CS Age",
    accessor: MEDICAID_LEAD_SORT_FIELDS.CS_AGE,
    sortable: true,
    reset: false,
  },
  {
    label: "IS Age",
    accessor: MEDICAID_LEAD_SORT_FIELDS.IS_AGE,
    sortable: true,
    reset: false,
  },
  {
    label: "",
    accessor: MEDICAID_LEAD_SORT_FIELDS.EMPTY,
    sortable: false,
    reset: true,
  },
];

export const DEFAULT_ORDER = "desc";
export const DEFAULT_SORT_FIELD = MEDICAID_LEAD_SORT_FIELDS.UPDATED;

const comparePendingMedicaidLeadPreviews = (
  a: MedicaidLeadPreview,
  b: MedicaidLeadPreview,
  sortField: string,
): number => {
  switch (sortField) {
    case MEDICAID_LEAD_SORT_FIELDS.UPDATED:
    case MEDICAID_LEAD_SORT_FIELDS.MARITAL_STATUS:
      return a[sortField].localeCompare(b[sortField]);
    case MEDICAID_LEAD_SORT_FIELDS.PROTECTABLE_ASSETS:
    case MEDICAID_LEAD_SORT_FIELDS.IS_AGE:
    case MEDICAID_LEAD_SORT_FIELDS.CS_AGE:
      return (a[sortField] || 0) - (b[sortField] || 0);
    default:
      return 0;
  }
};

const compareAcceptedMedicaidLeadPreviews = (
  a: EnrichedMedicaidLeadWithSimpleCalculation,
  b: EnrichedMedicaidLeadWithSimpleCalculation,
  sortField: string,
): number => 0;

export const sortLeadPreviewArray = <
  T extends MedicaidLeadReviewWithPreviewStatus,
>(
  leadPreviewArray: T[],
  sortField: string,
  order: string,
): T[] => {
  if (!sortField || !order || leadPreviewArray.length === 0)
    return leadPreviewArray;

  if (medicaidLeadPreviewsArePending(leadPreviewArray)) {
    const sortedAscending = [...leadPreviewArray].sort((a, b) =>
      comparePendingMedicaidLeadPreviews(a.preview, b.preview, sortField),
    );
    if (order === "asc") return sortedAscending;
    else if (order === "desc") return sortedAscending.reverse();
  } else if (medicaidLeadPreviewsAreAccepted(leadPreviewArray)) {
    const sortedAscending = [...leadPreviewArray].sort((a, b) =>
      compareAcceptedMedicaidLeadPreviews(a.preview, b.preview, sortField),
    );
    if (order === "asc") return sortedAscending;
    else if (order === "desc") return sortedAscending.reverse();
  }

  return leadPreviewArray;
};
