import { SelectMode } from ".";

export const OTHER_VALUE = "__other__";
export const OTHER_OPTION = { label: "Other", value: OTHER_VALUE };

export type SelectMultipleOrOtherState = {
  value: string[];
  other?: string;
};

export type SelectOneOrOtherState = {
  value: string | undefined;
  other?: string;
};

type FieldState = SelectMultipleOrOtherState | SelectOneOrOtherState;

export const isSelectMultipleFieldState = (
  _state: FieldState,
  mode: SelectMode,
): _state is SelectMultipleOrOtherState => mode === SelectMode.MULTIPLE;

export default FieldState;
