import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import omit from "lodash/omit";
import ReactGA from "react-ga4";

import rootReducer, { history } from "./reducer";
import initMiddlewares from "./middlewares";
import Workflow from "modules/workflow/types/Workflow";

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
});

// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: any) => ({
    ...(state || {}),
    user: omit(state?.user || {}, ["idToken"]),
    theme: "(removed)",
    partner: state.partner?.id,
    workflows: Object.values(
      state.workflows as Record<string, Workflow>,
    ).reduce(
      (acc, wf) => {
        acc[wf.id] = {
          ...wf,
          products: "(removed)",
          questionnaires: "(removed)",
        };
        return acc;
      },
      {} as Record<string, any>,
    ),
  }),
});

export default configureStore({
  reducer: rootReducer,
  middleware: initMiddlewares(history),
  enhancers: [sentryReduxEnhancer],
});

export { history } from "./reducer";
