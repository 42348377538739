import React from "react";
import LedgerItemProps from "./types";
import styles from "./styles.module.scss";

const LedgerItem = ({ item }: LedgerItemProps) => {
  return (
    <tr className={styles["ledger-item"]} key={item.workflowId}>
      <td>{new Date(item.dateCompleted * 1000).toLocaleDateString()}</td>
      <td>{item.displayName}</td>
      <td>{item.plan}</td>
      <td>{item.amountCollected.toFixed(2)}</td>
      <td>{item.partnerRevenue.toFixed(2)}</td>
      <td>{item.guidrRevenue.toFixed(2)}</td>
      <td></td>
    </tr>
  );
};

export default LedgerItem;
