import { SectionState, SectionProgress } from "../helpers/getSectionsProgress";
import { FormProps, FormSubmitHandle } from "components/Forms/Form";
import Addon from "modules/product/types/Addon";
import Party, {
  ClientParties,
  GenericParty,
} from "modules/parties/types/Party";
import Address from "modules/parties/types/Address";
import { OptionType } from "components/Forms/Questions/QuestionAddress";
import { FormikErrors, FormikProps } from "formik";
import { ConfirmExtraAction } from "components/Forms/Inputs/Confirm";
import { ImageData } from "components/ImageUpload";
import { AlertType } from "components/Alert";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
  AnswerValueType,
} from "./Answer";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import React from "react";
import Condition from "./Condition";

// QuestionTypes as defined in the questionnaire schema on the BE
export enum QuestionType {
  ADDON = "AddonOffer",
  ADDRESS = "Address",
  ALERT = "Alert",
  ASSET_SPLIT = "AssetSplit",
  CLONE_BANNER = "CloneBanner",
  CONFIRM = "Confirm",
  CONTENT = "Content",
  CURRENCY = "Currency",
  DATE = "Date",
  FLAG = "Flag",
  GENDER = "Gender",
  IMAGE = "Image",
  LIFE_INSURANCE_POLICY_VALUE = "LifeInsurancePolicyValue",
  MULTIPLE = "Multiple",
  NUMERIC = "Numeric",
  OR_OPTION = "OrOption",
  PERCENTAGE = "Percentage",
  PER_PARTY = "PerParty",
  SUBQUESTIONNAIRE = "PerPartyQuestionnaire",
  RELATIONSHIP = "SelectRelationship",
  SELECT = "Select",
  SHARE_DISTRIBUTION = "ShareDistribution",
  STATE = "State",
  TEXT = "Textual",
  LONG_TEXT = "LongText",
}

export enum TextFieldType {
  CODE = "Code",
  EMAIL = "Email",
  SSN = "SocialSecurityNumber",
  TEL = "TelephoneNumber",
  ZIP = "ZipCode",
}

export type Limits = {
  min?: number;
  max?: number;
  errorMessageKey?: string;
};

export type DateLimits = {
  min?: string;
  max?: string;
};

export type QuestionDefinitionCurrency = {
  type: QuestionType.CURRENCY;
  symbol: string;
  limits?: Limits;
};

export type QuestionDefinitionDate = {
  type: QuestionType.DATE;
  limits?: DateLimits;
};

export type QuestionDefinitionNumeric = {
  type: QuestionType.NUMERIC;
  integral: boolean;
  limits?: Limits;
  placeholder?: string;
};

export type QuestionDefinitionTextual = {
  type: QuestionType.TEXT;
  blacklist?: string[];
  placeholder?: string;
  subtype?: TextFieldType;
  limits?: Limits;
  prefix?: string;
  explainer?: string;
};

export type QuestionSelectValue = {
  display?: string;
  value: any;
  condition?: Condition;
  description?: string;
};

export type QuestionDefinitionSelect = {
  type: QuestionType.SELECT;
  values: QuestionSelectValue[];
  autocomplete?: boolean;
  radio?: boolean;
  stacked?: boolean;
  limits?: Limits;
  allowOther?: boolean;
  otherLabel?: string;
};
export const isSelectMultiple = (
  definition: QuestionDefinitionSelect,
): boolean =>
  !definition.radio &&
  (definition.limits?.max === undefined || definition.limits.max > 1);

export type AddonChoice = {
  title: string;
  description: string;
  note?: string;
  recommended?: boolean;
  icon?: JSX.Element;
  highlights: string[];
  secondaryAction?: {
    label: string;
    onAction: () => void;
    isLoading?: boolean;
  };
  disabled?: boolean;
  buttonLabel?: string;
  warning?: string;
  additionalContent?: JSX.Element;
};

export type AddonChoices = {
  on: AddonChoice;
  off: AddonChoice;
};

export type QuestionDefinitionAddon = {
  type: QuestionType.ADDON;
  addonId: string;
  choices?: AddonChoices;
  isSecondaryWorkflow?: boolean;
  addonDetails?: Addon;
};

export type QuestionDefinitionShareDistribution = {
  type: QuestionType.SHARE_DISTRIBUTION;
  referenceQuestionId: string;
  parties?: GenericParty[];
};

export type QuestionDefinitionPerParty = {
  type: QuestionType.PER_PARTY;
  referenceQuestionId: string;
  definition: QuestionDefinition;
  parties?: GenericParty[];
};

export type QuestionSubquestionnairePreprocessedData = {
  allAnswers: AnswersByQuestionnaireType;
  allQuestions: QuestionsMapByQuestionnaire;
  clients?: ClientsMap;
  partySubquestionnaire: Questionnaire | undefined;
  subquestionnaires: Questionnaire[];
  allChildren?: Party[];

  // TODO Currently not supported
  // preselectedAddons?: Addon[];
  // workflowState?: WorkflowPayloadState;
};

export type QuestionDefinitionSubquestionnaire = {
  type: QuestionType.SUBQUESTIONNAIRE;
  referenceQuestionId: string;
  questionnaireId: string;

  // Assigned during pre-processing
  subquestionnaireData?: QuestionSubquestionnairePreprocessedData;
  parties?: GenericParty[];
};

export type QuestionDefinitionConfirm = {
  type: QuestionType.CONFIRM;
  extraAction?: ConfirmExtraAction;
  errorMessage?: string;
};

export type QuestionDefinitionAddress = {
  type: QuestionType.ADDRESS;
  onAddressLookup?: (addressId: string) => Promise<Address | undefined>;
  onAddressSearch?: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<OptionType[] | undefined>;
  supportedStates?: string[];
  availableAddresses?: Address[];
};

export type QuestionDefinitionCloneBanner = {
  type: QuestionType.CLONE_BANNER;
  renderBanner?: () => JSX.Element | null;
};

export enum RelationshipConstraintType {
  ANY = "Any",
  DESCENDANTS = "Descendants",
  NON_DESCENDANTS = "NonDescendants",
  MATURE = "Mature",
  SUBSET = "Subset",
}

export type SelectRelationshipConstraint = {
  type: Exclude<RelationshipConstraintType, RelationshipConstraintType.SUBSET>;
};

export type SelectSubsetRelationshipConstraint = {
  type: RelationshipConstraintType.SUBSET;
  referenceQuestionIds: string[];
};

export type QuestionIdentifier = {
  questionnaireID: string;
  questionID: string;
};

export type RelationshipConstraint =
  | SelectRelationshipConstraint
  | SelectSubsetRelationshipConstraint;

export type QuestionDefinitionSelectRelationship = {
  type: QuestionType.RELATIONSHIP;
  allParties?: Array<GenericParty>;
  assignableParties?: Array<GenericParty>;
  partiesToImport?: ClientParties;
  relationship?: RelationshipConstraint;
  underageWarning?: string;
  limits?: Limits;
  excludeByQuestionIds?: QuestionIdentifier[];
  requireEmail?: boolean;
  requirePhone?: boolean;
  allowSpouse?: boolean;
  radio?: boolean;
  allowOther?: boolean;
};

export type QuestionDefinitionStateSelect = {
  type: QuestionType.STATE;
  maxSuggestedItems?: number;
};

export type QuestionDefinitionPercentage = {
  type: QuestionType.PERCENTAGE;
  allowDecimal?: boolean;
};

export type QuestionDefinitionContent = {
  type: QuestionType.CONTENT;
  render: () => JSX.Element;
};

export type QuestionDefinitionImage = {
  type: QuestionType.IMAGE;
  aspectRatio?: number;
  circular?: boolean;
  minWidth?: number;
  minHeight?: number;
  maxFileSize?: number;
} & ({ data?: ImageData } | { url?: string });

export type QuestionDefinitionAssetSplit = {
  type: QuestionType.ASSET_SPLIT;
  mainOwnedLabel: string;
  jointlyOwnedLabel: string;
  spouseOwnedLabel: string;
};

export type QuestionDefinitionLifeInsurancePolicyValue = {
  type: QuestionType.LIFE_INSURANCE_POLICY_VALUE;
  faceValueLimits: Limits;
  cashValueLimits: Limits;
  deathBenefitLimits: Limits;
};

export type QuestionDefinitionMultiple = {
  type: QuestionType.MULTIPLE;
  limits: Limits;
  addAnotherLabel?: string;
  baseQuestionDefinition: QuestionDefinition;
};

export type QuestionDefinitionOrOption = {
  type: QuestionType.OR_OPTION;
  questionDefinition: QuestionDefinition;
  option: string;
};

export type QuestionDefinitionAlert = {
  type: QuestionType.ALERT;
  level: AlertType;
  message: string;
};

export type QuestionDefinitionFlag = {
  type: QuestionType.FLAG;
  yesLabel?: string;
  noLabel?: string;
};

export type QuestionDefinitionOther = {
  type: Exclude<
    QuestionType,
    | QuestionType.ADDON
    | QuestionType.ADDRESS
    | QuestionType.ALERT
    | QuestionType.ASSET_SPLIT
    | QuestionType.CLONE_BANNER
    | QuestionType.CONFIRM
    | QuestionType.CONTENT
    | QuestionType.CURRENCY
    | QuestionType.DATE
    | QuestionType.FLAG
    | QuestionType.IMAGE
    | QuestionType.LIFE_INSURANCE_POLICY_VALUE
    | QuestionType.MULTIPLE
    | QuestionType.NUMERIC
    | QuestionType.OR_OPTION
    | QuestionType.PER_PARTY
    | QuestionType.PERCENTAGE
    | QuestionType.RELATIONSHIP
    | QuestionType.SELECT
    | QuestionType.SHARE_DISTRIBUTION
    | QuestionType.STATE
    | QuestionType.SUBQUESTIONNAIRE
    | QuestionType.TEXT
  >;
};

export type QuestionDefinition =
  | QuestionDefinitionAddon
  | QuestionDefinitionAddress
  | QuestionDefinitionAlert
  | QuestionDefinitionAssetSplit
  | QuestionDefinitionCloneBanner
  | QuestionDefinitionConfirm
  | QuestionDefinitionContent
  | QuestionDefinitionCurrency
  | QuestionDefinitionDate
  | QuestionDefinitionFlag
  | QuestionDefinitionImage
  | QuestionDefinitionLifeInsurancePolicyValue
  | QuestionDefinitionMultiple
  | QuestionDefinitionNumeric
  | QuestionDefinitionOrOption
  | QuestionDefinitionOther
  | QuestionDefinitionPercentage
  | QuestionDefinitionPerParty
  | QuestionDefinitionSelect
  | QuestionDefinitionSelectRelationship
  | QuestionDefinitionShareDistribution
  | QuestionDefinitionStateSelect
  | QuestionDefinitionSubquestionnaire
  | QuestionDefinitionTextual;

export type QuestionHelp =
  | string
  | QuestionHelpItem
  | Array<string | QuestionHelpItem>;

export type QuestionHelpItem = {
  title: string;
  text: string;
};

export type QuestionDescription = {
  label: string;
  description?: QuestionHelp;
  condition?: Condition;
};

export type QuestionData = {
  definition: QuestionDefinition;
  descriptions: QuestionDescription[];
  required?: boolean;
  readOnly?: boolean;
  condition?: Condition;
  defaultValue?: AnswerValueType;
  annotations?: QuestionAnnotation[];
  copyFrom?: QuestionID;
  showHelpByDefault?: boolean;
};

export type QuestionID = string;
export type QuestionsMap = Record<QuestionID, Question>;
export type QuestionsMapByQuestionnaire = Record<QuestionnaireID, QuestionsMap>;
export type QuestionAnnotation = string;

export type QuestionnaireWithQuestions = {
  questionnaire: Questionnaire;
  questions: QuestionsMap;
};

export type Question = {
  id: QuestionID;
  label?: string;
  description?: QuestionHelp;
  data: QuestionData;
  skipSummary?: boolean;
  created?: string;
  updated?: string;
};

export type QuestionnairePageID = string;

export type ConditionalPageDescription = {
  description: string;
  condition: Condition;
};

export type QuestionnairePage = {
  id: QuestionnairePageID;
  caption?: string;
  description?: React.ReactNode;
  condition?: Condition;
  conditionalDescriptions?: ConditionalPageDescription[];
  sections: QuestionnaireSection[];
  isStatic?: boolean;
  index?: number;
  questionnaireId: string;
  render?: (formProps: FormProps, onPageUpdate?: () => any) => JSX.Element;
  renderAction?: (
    pageQuestions: Question[],
    currentAnswers: AnswersMap,
    submitHandle?: FormSubmitHandle,
    formHandle?: FormikProps<AnswersMap>,
  ) => JSX.Element | null;
  renderPageFooter?: (submitForm: () => Promise<void>) => JSX.Element;
  renderSummary?: () => JSX.Element;
  onEditSummarySection?: (() => any) | null;
  getState?: () => SectionState;
  getProgress?: (isVisited?: boolean) => SectionProgress | null;
  onPageLeave?: FormSubmitHandle;
  onSave?: (
    valuesToSave: AnswersMap,
    allPageValues: AnswersMap,
    isDelta?: boolean,
  ) => any;
  onFilterValidationErrors?: (
    allValidationErrors: FormikErrors<AnswersMap>,
    unsavedAndChangedQuestionsKeys: string[],
    isDelta?: boolean,
  ) => FormikErrors<AnswersMap>;
};

export type QuestionnaireSection = {
  caption: string;
  condition?: Condition;
  description?: string;
  questions?: Array<string | string[]>;
  isStatic?: boolean;
  render?: () => Element;
  getState?: () => SectionState;
};

export type QuestionnaireProgress = {
  allQuestionsIDs: Record<string, string[]>;
  answeredQuestionsIDs: Record<string, string[]>;
  sectionsProgress: SectionProgress[];
  selectedAddons: Addon[];
};

export const isImageData = (answer: ImageData | any): answer is ImageData =>
  answer?.hasOwnProperty("base64");

export type QuestionnaireID = string;

type Questionnaire = {
  id: QuestionnaireID;
  pages: QuestionnairePage[];
  priority: number;
  questions: QuestionsMap;
  selectedAddons?: Addon[];
  productQuestionnaire?: boolean;
  created?: string;
  updated?: string;
};
export default Questionnaire;
