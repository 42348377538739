import * as React from "react";
import { useEffect } from "react";
import { useField } from "formik";
import isEqual from "lodash/isEqual";
import { useId } from "react-id-generator";
import QuestionShareDistributionShares from "./QuestionShareDistributionShares";
import QuestionShareDistributionBar from "./QuestionShareDistributionBar";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import Client from "modules/parties/types/Client";
import { GenericParty, getGenericPartyId } from "modules/parties/types/Party";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

export type Share = {
  partyId: string;
  share: number | string | undefined;
};

type QuestionShareDistributionProps = {
  client?: Client;
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  name: string;
  parties: GenericParty[];
};

const QuestionShareDistribution: React.FunctionComponent<
  QuestionShareDistributionProps
> = ({ client, disabled, help, info, label, name, parties }) => {
  const [questionId] = useId(1, "question-");
  const [, meta, helpers] = useField<Share[]>(name);

  useEffect(() => {
    const shares = parties.map(function (party): Share {
      const partyId = getGenericPartyId(party);
      return {
        partyId,
        share:
          meta.value?.find((share) => share.partyId === partyId)?.share || "",
      };
    });
    if (!isEqual(meta.value, shares)) {
      helpers.setValue(shares);
    }
  });

  const distributeEqually = React.useCallback(() => {
    helpers.setValue(
      parties.map(
        (party): Share => ({
          partyId: getGenericPartyId(party),
          share: `1/${parties.length}`,
        }),
      ),
    );
  }, [helpers, parties]);

  const resetDistributions = React.useCallback(() => {
    helpers.setValue(
      parties.map(
        (party): Share => ({
          partyId: getGenericPartyId(party),
          share: "",
        }),
      ),
    );
  }, [helpers, parties]);

  const isEqualDistribution = Boolean(
    meta.value?.find((dist) => String(dist.share).includes("/")),
  );

  return (
    <React.Fragment>
      <QuestionWrapper
        className="question--share-distribution"
        disabled={disabled}
        help={help}
        id={questionId}
        info={info}
        label={label}
        name={name}
        size={QuestionSizes.FULLWIDTH}
      >
        <QuestionShareDistributionBar
          error={
            !disabled && meta.touched && meta.error ? meta.error : undefined
          }
          disabled={disabled}
          shares={meta.value ? meta.value : []}
          isEqualDistribution={isEqualDistribution}
          onDistributeEqually={distributeEqually}
          onResetDistributions={resetDistributions}
        />
      </QuestionWrapper>
      <QuestionShareDistributionShares
        client={client}
        disabled={disabled}
        error={!disabled && meta.touched && meta.error ? meta.error : undefined}
        id={questionId}
        name={name}
        parties={parties}
        shares={meta.value ? meta.value : []}
        isEqualDistribution={isEqualDistribution}
      />
    </React.Fragment>
  );
};

export default QuestionShareDistribution;
