import * as React from "react";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type LinkProps = {
  disabled?: boolean;
  onClick: () => any;
  className?: string;
  style?: React.CSSProperties;
  tabIndex?: number;
};

const Link: React.FunctionComponent<LinkProps> = ({
  disabled,
  children,
  onClick,
  className,
  style,
  tabIndex,
}) => {
  const clickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    },
    [onClick],
  );
  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    className: cx("link", { "link--disabled": disabled }, className),
    onClick: clickHandler,
    disabled,
    style,
    tabIndex,
  };

  return (
    <button type="button" {...buttonProps}>
      {children}
    </button>
  );
};

export default Link;
