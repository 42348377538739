import React, { useCallback } from "react";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { getPartnerRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import { shallowEqual, useDispatch } from "react-redux";
import { changeState, logOut } from "modules/auth/actions";
import PaymentScreenHeader from "screens/payment/PaymentScreenHeader";
import MenuLink from "components/Header/MenuLink";
import { isAdmin, isDraftingEnabled, isGlobalAdmin } from "modules/auth/helper";
import { useSelector } from "redux/reducer";
import ProductTailoringScreenHeader from "screens/productTailoring/ProductTailoringScreenHeader";
import {
  isPath,
  go,
  navigateToGlobalLanding,
  navigateToPartnerLanding,
} from "modules/navigation/helpers/navigator";
import { PartnerContextProvider } from "modules/partner/context/PartnerContext";
import SigningScreenHeader from "screens/signingScreen/SigningScreenHeader";
import SearchScreenHeader from "./SearchScreen/SearchScreenHeader";
import { getAllPartnerIDs } from "modules/auth/helper";
import { partnerUnselected } from "modules/partner/actions";
import { buildHeaderLinks } from "utils/buildHeaderLinks";

const HARRY_USER_ID_FOR_MEDICAID_TESTING = "n3ubrGSXJROpuoDKbA2I8PE7UC3";

const PageHeaderWrapper: React.FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    user,
    incompleteProfile,
    isLoadingUserDetails,
    isLoadingPartner,
    isSessionRehydrated,
    partner,
    state,
  } = useSelector((state) => state, shallowEqual);
  const admin = isAdmin(partner?.id, user);
  const draftingAdmin = admin && isDraftingEnabled(partner);

  const globalAdmin = isGlobalAdmin(user);
  const createLink = (
    label: string,
    route: ROUTE_TEMPLATE | string,
    icon?: string,
    alert?: number,
  ): MenuLink => ({
    label,
    onClick: () => go(dispatch, route),
    isActive: isPath(route as ROUTE_TEMPLATE, undefined, false),
    icon,
    alert,
  });

  const handleLogOut = useCallback(() => dispatch(logOut()), [dispatch]);

  const handleLogIn = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.LOGIN),
    [dispatch],
  );

  const navigateHome = useCallback(() => {
    if (incompleteProfile) return;
    if (!user) {
      if (partner === null || isPath(ROUTE_TEMPLATE.PARTNER_ROOT)) {
        partner && dispatch(partnerUnselected(partner.id));
        navigateToGlobalLanding(dispatch);
      } else {
        navigateToPartnerLanding(dispatch, partner.id);
      }
      return;
    }

    go(
      dispatch,
      partner && !isPath(ROUTE_TEMPLATE.MY_PARTNERS)
        ? getPartnerRoute(partner.id, ROUTE_TEMPLATE.WORKFLOW)
        : ROUTE_TEMPLATE.WORKFLOW,
    );
  }, [dispatch, incompleteProfile, partner, user]);

  const onLogoClickedInSearchPage = useCallback(() => {
    if (partner) {
      if (user) {
        go(dispatch, ROUTE_TEMPLATE.WORKFLOW, { partnerId: partner.id });
      } else {
        navigateToPartnerLanding(dispatch, partner.id);
      }
    } else {
      navigateToGlobalLanding(dispatch);
    }
  }, [dispatch, partner, user]);

  const handleChangeState = useCallback(
    () => dispatch(changeState()),
    [dispatch],
  );

  const adminLinks = [
    createLink("Documents", ROUTE_TEMPLATE.WORKFLOW),
    createLink("Site Setup", ROUTE_TEMPLATE.SITE_CONFIGURATION),
    createLink("Product Setup", ROUTE_TEMPLATE.PRODUCT_CONFIGURATION),
    createLink("Permissions", ROUTE_TEMPLATE.PERMISSION_CONFIGURATION),
  ];

  const draftingLinks = [createLink("Drafting", ROUTE_TEMPLATE.DRAFTING)];

  const userLinks = [
    createLink(
      "My Documents",
      partner
        ? getPartnerRoute(partner.id, ROUTE_TEMPLATE.WORKFLOW)
        : ROUTE_TEMPLATE.MY_PARTNERS,
    ),
    ...(user?.uid === HARRY_USER_ID_FOR_MEDICAID_TESTING
      ? [
          createLink(
            "My Medicaid Workflows",
            ROUTE_TEMPLATE.MY_MEDICAID_WORKFLOWS,
          ),
        ]
      : []),
  ];

  const globalAdminLinks = [
    createLink("Partners", ROUTE_TEMPLATE.ADMIN_PARTNERS),
    createLink("Partner Registrations", ROUTE_TEMPLATE.ADMIN_REGISTRATIONS),
    createLink("Finance", ROUTE_TEMPLATE.ADMIN_FINANCE),
    createLink("Medicaid", ROUTE_TEMPLATE.ADMIN_MEDICAID_VARIABLES),
  ];

  const standardLinks = [
    createLink("About Us", ROUTE_TEMPLATE.ABOUT_US),
    createLink("Learning Center", ROUTE_TEMPLATE.LEARNING_CENTER),
    createLink("FAQs", ROUTE_TEMPLATE.FAQS),
  ];

  const headerLinks = buildHeaderLinks({
    globalAdmin,
    globalAdminLinks,
    draftingAdmin,
    draftingAdminLinks: draftingLinks,
    admin,
    adminLinks,
    loggedIn: user !== null,
    userLinks,
    standardLinks,
  });

  const assignedPartnerIDs = getAllPartnerIDs(user);
  const activeRegistrations = Object.values(user?.partnerRegistrations || {});
  const totalPartners = assignedPartnerIDs.size + activeRegistrations.length;

  const profileLinks: MenuLink[] = [
    createLink("My Profile", ROUTE_TEMPLATE.PROFILE, "user"),

    ...(totalPartners > 1
      ? [createLink("My Law Firms", ROUTE_TEMPLATE.MY_PARTNERS, "users")]
      : []),

    ...(totalPartners === 1 && (!partner || !assignedPartnerIDs.has(partner.id))
      ? [createLink("My Law Firm", ROUTE_TEMPLATE.MY_PARTNERS, "users")]
      : []),
  ];

  if (isPath(ROUTE_TEMPLATE.SEARCH, location))
    return (
      <SearchScreenHeader
        onLogin={handleLogIn}
        onLogout={handleLogOut}
        onNavigateHome={onLogoClickedInSearchPage}
        profileLinks={profileLinks}
        mainNavigationLinks={headerLinks}
        user={user}
      />
    );

  if (isPath(ROUTE_TEMPLATE.WORKFLOW_PAYMENT, location))
    return <PaymentScreenHeader onHome={navigateHome} />;

  if (isPath(ROUTE_TEMPLATE.WORKFLOW_SIGNING, location))
    return <SigningScreenHeader onHome={navigateHome} />;

  if (
    isPath(ROUTE_TEMPLATE.PRODUCT_TAILORING, location) ||
    isPath(ROUTE_TEMPLATE.PLAN_TAILORING, location)
  )
    return <ProductTailoringScreenHeader onHome={navigateHome} />;

  return (
    <PartnerContextProvider>
      <Header
        hideNavigationMenu={
          incompleteProfile ||
          isLoadingUserDetails ||
          isPath(ROUTE_TEMPLATE.MY_PARTNERS, location)
        }
        hideProfileMenu={
          isLoadingUserDetails || isLoadingPartner || !isSessionRehydrated
        }
        mainNavigationLinks={headerLinks}
        onLogin={handleLogIn}
        onLogoClicked={navigateHome}
        onLogout={handleLogOut}
        onStateChange={handleChangeState}
        profileMenuLinks={profileLinks}
        selectedState={!admin && !globalAdmin && state ? state : undefined}
        user={user}
        greenBorderBottom={
          isPath(ROUTE_TEMPLATE.ROOT, location) ||
          isPath(ROUTE_TEMPLATE.PARTNER_ROOT, location)
        }
      />
    </PartnerContextProvider>
  );
};

export default PageHeaderWrapper;
