import * as React from "react";
import Header from "components/Header";
import { User } from "modules/auth/User";
import MenuLink from "components/Header/MenuLink";

type SearchScreenHeaderProps = {
  hideProfileMenu?: boolean;
  onLogin: () => any;
  onLogout: () => any;
  onNavigateHome: () => any;
  profileLinks: MenuLink[];
  mainNavigationLinks?: MenuLink[];
  user: User | null;
};

const SearchScreenHeader = ({
  onLogin,
  onLogout,
  onNavigateHome,
  profileLinks,
  mainNavigationLinks,
  user,
}: SearchScreenHeaderProps) => {
  return (
    <Header
      mainNavigationLinks={mainNavigationLinks || []}
      onLogin={onLogin}
      onLogoClicked={onNavigateHome}
      onLogout={onLogout}
      profileMenuLinks={profileLinks}
      user={user}
    />
  );
};

export default SearchScreenHeader;
