import AddressType from "modules/parties/types/Address";

export const formatAddressDetails = (address?: AddressType): string => {
  return [
    [address?.line1, address?.line2, address?.city].filter(Boolean).join(", "),
    address?.state,
    address?.zip,
  ]
    .filter(Boolean)
    .join(" ");
};

export const setAddressDetails = (
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void,
  address: AddressType,
  name: string,
) => {
  ["line1", "line2", "city", "state", "zip", "adminAreaName"].forEach(
    (field) => {
      if (address[field as keyof AddressType]) {
        setFieldValue(`${name}.${field}`, address[field as keyof AddressType]);
        setTimeout(() => {
          setFieldTouched(`${name}.${field}`);
        }, 0);
      }
    },
  );
};

export const clearAddressDetails = (
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void,
  name: string,
) => {
  ["line1", "line2", "city", "state", "zip", "adminAreaName"].forEach(
    (field) => {
      setFieldValue(`${name}.${field}`, "");
      setTimeout(() => {
        setFieldTouched(`${name}.${field}`, false);
      }, 0);
    },
  );
};

export const formatExistingAddresses = (addresses?: AddressType[]) => {
  return (addresses || []).map((address) => {
    return {
      label: formatAddressDetails(address),
      type: "Existing",
      value: address.id,
    };
  });
};
