import { cloneDeep, compact, keyBy, mapValues, pickBy } from "lodash";
import { AddressSearchResultsType } from "modules/address/actions";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import { User } from "modules/auth/User";
import getClientQuestionnaire from "modules/parties/helpers/getClientQuestionnaire";
import getSpouseQuestionnaire from "modules/parties/helpers/getSpouseQuestionnaire";
import Address from "modules/parties/types/Address";
import Client, { ClientType } from "modules/parties/types/Client";
import { dedupAddresses } from "modules/workflow/helpers/collectAllAddresses";
import getFilledQuestionsByQuestionnaire from "modules/workflow/helpers/getFilledQuestionsByQuestionnaire";
import { filterMandatoryQuestions } from "modules/workflow/helpers/getMandatoryQuestionIDs";
import getSectionsProgress from "modules/workflow/helpers/getSectionsProgress";
import mergeAnswers from "modules/workflow/helpers/mergeAnswers";
import { parseQuestionnairesFromPayload } from "modules/workflow/helpers/parseQuestionnaire";
import { getQuestionnaireDefinition } from "modules/workflow/helpers/questionnaireSchemaParser";
import getMedicaidLeadSummaryPage from "modules/workflow/staticPages/getMedicaidLeadSummaryPage";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import Questionnaire, {
  QuestionnairePage,
  QuestionnaireProgress,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";

type QuestionnaireFormInput = {
  questionnaireAnswers?: AnswersByQuestionnaireType;
  questionnairePages?: QuestionnairePage[];
  questionsBySubquestionnaire?: QuestionsMapByQuestionnaire;
  clientsMap?: ClientsMap;
  questionnaireProgress?: QuestionnaireProgress;
};

const getQuestionnaireFormInput = (
  questionnaire: Questionnaire | undefined,
  questions: QuestionsMap | undefined,
  answers: AnswersMap | undefined,
  unsavedAnswers: AnswersByQuestionnaireType | undefined,
  clients: Record<string, Client> | undefined,
  user: User | null,
  handleSaveClient: (
    clientType: ClientType,
  ) => (
    clientId: string | undefined,
    clientDetails: Partial<Client>,
  ) => Promise<void>,
  handleAddressLookup: (addressId: string) => Promise<Address | undefined>,
  handleAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  handleChangePage: (pageIdx: number) => Promise<void> | undefined,
  onConfirmSummary: () => void,
  supportedStates: string[],
): QuestionnaireFormInput => {
  if (
    questionnaire === undefined ||
    questions === undefined ||
    answers === undefined ||
    clients === undefined
  ) {
    return {};
  }
  const clientsMap = {
    [ClientType.MAIN]: clients?.[ClientType.MAIN],
    [ClientType.SPOUSE]: clients?.[ClientType.SPOUSE],
  };

  const parsedQuestionnaires = parseQuestionnairesFromPayload({
    [questionnaire.id]: {
      questionnaire: questionnaire,
      questions: cloneDeep(questions),
    },
  });

  const parsedQuestionnaire =
    parsedQuestionnaires[questionnaire.id] || questionnaire;

  const availableAddresses = dedupAddresses(
    compact([
      clientsMap[ClientType.MAIN]?.address,
      clientsMap[ClientType.SPOUSE]?.address,
    ]),
  );

  const clientQuestionnaire = getClientQuestionnaire(
    handleSaveClient(ClientType.MAIN),
    handleAddressLookup,
    handleAddressSearch,
    availableAddresses,
    clients?.[ClientType.MAIN],
    false,
    undefined,
    false,
    undefined,
    undefined,
    user,
    false,
    supportedStates,
  );

  const spouseQuestionnaire = getSpouseQuestionnaire(
    handleSaveClient(ClientType.SPOUSE),
    handleAddressLookup,
    handleAddressSearch,
    availableAddresses,
    clients?.[ClientType.SPOUSE],
    undefined,
    false,
    supportedStates,
  );

  const answersByQuestionnaire: AnswersByQuestionnaireType = {
    ...clientQuestionnaire.initialValues,
    ...spouseQuestionnaire.initialValues,
    [parsedQuestionnaire.id]: answers,
  };

  const questionsByQuestionnaire: QuestionsMapByQuestionnaire = {
    ...clientQuestionnaire.questions,
    ...spouseQuestionnaire.questions,
    [parsedQuestionnaire.id]: keyBy(parsedQuestionnaire.questions, "id"),
  };

  const dataEntryPages = [
    ...clientQuestionnaire.questionnairePages,
    ...spouseQuestionnaire.questionnairePages,
    ...parsedQuestionnaire.pages,
  ];

  const allPages = [
    ...dataEntryPages,
    getMedicaidLeadSummaryPage(
      clientsMap,
      dataEntryPages,
      questionsByQuestionnaire,
      answersByQuestionnaire,
      true,
      onConfirmSummary,
      handleChangePage,
    ),
  ];

  const allAnswersByQuestionnaire = mergeAnswers(
    answersByQuestionnaire,
    unsavedAnswers || {},
  );

  const { pages: filteredPages, questions: filteredQuestionsByQuestionnaire } =
    getQuestionnaireDefinition(
      allPages,
      questionsByQuestionnaire,
      allAnswersByQuestionnaire,
      clientsMap,
      undefined,
      undefined,
      undefined,
      undefined,
    );

  const mandatoryQuestionsByQuestionnaire: QuestionsMapByQuestionnaire =
    mapValues(filteredQuestionsByQuestionnaire, (questionsMap) =>
      pickBy(questionsMap, (question) => Boolean(question.data.required)),
    );

  const answeredQuestionsIDs = getFilledQuestionsByQuestionnaire(
    allAnswersByQuestionnaire,
    mandatoryQuestionsByQuestionnaire,
  );

  const sectionsProgress = getSectionsProgress(
    filteredPages,
    mandatoryQuestionsByQuestionnaire,
    answeredQuestionsIDs,
  );

  const allQuestionsIDs = filterMandatoryQuestions(
    filteredQuestionsByQuestionnaire,
  );

  const questionnaireProgress: QuestionnaireProgress = {
    allQuestionsIDs,
    answeredQuestionsIDs,
    sectionsProgress,
    selectedAddons: [],
  };

  return {
    questionnaireAnswers: allAnswersByQuestionnaire,
    questionnairePages: allPages,
    questionsBySubquestionnaire: filteredQuestionsByQuestionnaire,
    clientsMap,
    questionnaireProgress,
  };
};

export default getQuestionnaireFormInput;
