import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
} from "@mui/material";
import classNames from "classnames/bind";
import Icon from "components/Icons";
import React from "react";
import styles from "./styles.module.scss";
import { type PackageItem } from "../Packages/constants";
import Button from "components/Button";
import { ChevronColour } from "components/Button/types";

const cx = classNames.bind(styles);

type PackageOfferingProps = {
  title: string;
  description: string;
  price: string;
  enabled: boolean;
  handleSelect: () => void;
  items: PackageItem[];
  bestDeal?: boolean;
};

const PackageOffering: React.FC<PackageOfferingProps> = ({
  title,
  description,
  price,
  enabled,
  handleSelect,
  items,
  bestDeal,
}) => {
  if (!enabled) return null;

  return (
    <Paper className={cx("package-offering")} elevation={3}>
      <Typography variant="h4">
        {title}
        {bestDeal && <span className={cx("best-deal")}>Best Deal</span>}
      </Typography>
      <Typography
        variant="body1"
        className={cx("package-offering__description")}
      >
        {description}
      </Typography>
      <List className={cx("package-offering__items")}>
        {items.map((item) => (
          <ListItem>
            <ListItemIcon>
              <Icon type={item.icon} />
            </ListItemIcon>
            <Typography variant="h5">{item.title}</Typography>
          </ListItem>
        ))}
      </List>
      <Box className={cx("package-offering__button-container")}>
        <Button
          label={price}
          chevronRight
          chevronColour={ChevronColour.GREEN}
          onClick={handleSelect}
        />
      </Box>
    </Paper>
  );
};

export default PackageOffering;
