import { Share } from "components/Forms/Questions/QuestionPerParty/QuestionShareDistribution";
import { isEqual, mapKeys, mapValues } from "lodash";
import PerPartyAnswer, {
  PerPartyAnswerType,
} from "modules/parties/types/PerPartyAnswer";
import { isAnswered } from "modules/workflow/helpers/getFilledQuestionsByQuestionnaire";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
  AnswerValueType,
} from "modules/workflow/types/Answer";
import {
  Question,
  QuestionDefinition,
  QuestionDefinitionSelect,
  QuestionDefinitionSubquestionnaire,
  QuestionType,
  RelationshipConstraintType,
} from "modules/workflow/types/Questionnaire";

export const UNIMPORTABLE_QUESTION_TYPES = [QuestionType.CLONE_BANNER];

export const hasAnswerToImport = (
  question: Question,
  spouseAnswers: AnswersMap,
  spouseId?: string,
  clientId?: string,
): boolean =>
  !UNIMPORTABLE_QUESTION_TYPES.includes(question.data.definition.type) && // Exclude unsupported question types
  isAnswered(
    spouseAnswers[question.id],
    question.data.definition,
    spouseId,
    clientId,
  );

export const canImportAnswer = (
  question: Question,
  clientAnswers: AnswersByQuestionnaireType,
  spouseAnswers: AnswersMap,
  questionnaireId: string,
  currentQuestions: Question[],
  spouseId?: string,
  clientId?: string,
): boolean => {
  switch (question.data.definition.type) {
    case QuestionType.SUBQUESTIONNAIRE:
    case QuestionType.SHARE_DISTRIBUTION:
    case QuestionType.PER_PARTY: {
      const parentQuestionId = question.data.definition.referenceQuestionId;

      const currentSourceAnswer = [
        ...((clientAnswers[questionnaireId]?.[parentQuestionId] as
          | string[]
          | undefined) || []),
      ]
        .map((id) => (id === spouseId ? clientId : id))
        .sort();
      const spouseSourceAnswer = [
        ...((spouseAnswers[parentQuestionId] as string[] | undefined) || []),
      ].sort();

      return isEqual(currentSourceAnswer, spouseSourceAnswer);
    }

    case QuestionType.RELATIONSHIP: {
      const relationshipConstraint = question.data.definition.relationship;

      if (relationshipConstraint?.type === RelationshipConstraintType.SUBSET) {
        const parentQuestionIds = relationshipConstraint.referenceQuestionIds;

        const currentSourceAnswers = parentQuestionIds
          .map(
            (parentQuestionId) =>
              clientAnswers[questionnaireId]?.[parentQuestionId] as
                | string[]
                | undefined,
          )
          .flat()
          .filter(Boolean)
          .map((id) => (id === spouseId ? clientId : id))
          .sort() as string[];

        const spouseSourceAnswers = parentQuestionIds
          .map(
            (parentQuestionId) =>
              spouseAnswers[parentQuestionId] as string[] | undefined,
          )
          .flat()
          .filter(Boolean)
          .sort() as string[];

        return isEqual(currentSourceAnswers, spouseSourceAnswers);
      } else {
        return true;
      }
    }

    case QuestionType.SELECT: {
      const displayedQuestion = currentQuestions.find(
        (q) => q.id === question.id,
      );
      if (displayedQuestion) {
        const availableValues = (
          displayedQuestion?.data.definition as QuestionDefinitionSelect
        ).values.map(({ value }) => value);
        return availableValues.includes(spouseAnswers[question.id]);
      }
      return true;
    }

    default:
      return true;
  }
};

export const transformAnswerForImport = (
  spouseAnswerToImport: AnswerValueType,
  questionDefinition: QuestionDefinition,
  spouseId?: string,
  clientId?: string,
): AnswerValueType => {
  if (
    !clientId ||
    !spouseId ||
    spouseAnswerToImport === undefined ||
    spouseAnswerToImport === null
  )
    return spouseAnswerToImport;

  switch (questionDefinition.type) {
    case QuestionType.RELATIONSHIP:
      return (spouseAnswerToImport as string[]).map((id) =>
        id === clientId ? spouseId : id,
      );

    case QuestionType.SHARE_DISTRIBUTION: {
      const shareDistributionAnswer = spouseAnswerToImport as Share[];
      return shareDistributionAnswer.map((share) => ({
        ...share,
        partyId: share.partyId === clientId ? spouseId : share.partyId,
      }));
    }

    case QuestionType.SUBQUESTIONNAIRE:
    case QuestionType.PER_PARTY: {
      const perPartyAnswer = spouseAnswerToImport as PerPartyAnswer;
      const valuesWithTransformedKeys = mapKeys(
        perPartyAnswer.values,
        (_, partyId) => (partyId === clientId ? spouseId : partyId),
      );
      const values = mapValues(valuesWithTransformedKeys, (value) => {
        if (!value) return value;

        switch (perPartyAnswer.type) {
          case PerPartyAnswerType.PARTIES: {
            return (value as string[]).map((partyId) =>
              partyId === clientId ? spouseId : partyId,
            );
          }

          case PerPartyAnswerType.QUESTIONNAIRE: {
            const subquestions =
              (questionDefinition as QuestionDefinitionSubquestionnaire)
                .subquestionnaireData?.partySubquestionnaire?.questions || {};

            return mapValues(
              value as AnswersMap,
              (subanswer, subquestionKey) => {
                const subquestionDefinition =
                  subquestions[subquestionKey]?.data.definition;
                return subquestionDefinition
                  ? transformAnswerForImport(
                      subanswer,
                      subquestionDefinition,
                      spouseId,
                      clientId,
                    )
                  : subanswer;
              },
            );
          }

          default:
            return value;
        }
      });

      return { ...perPartyAnswer, values };
    }

    default:
      return spouseAnswerToImport;
  }
};
