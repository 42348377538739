import React from "react";
import { InterviewAlertsProps } from "./types";
import {
  clientContactDetailsPageDefinition,
  clientDetailsPageDefinition,
} from "modules/parties/helpers/getClientQuestionnaire/constants";
import Alert, { AlertType } from "components/Alert";

import styles from "./styles.module.scss";
import { STATES_MAP } from "resources/options/states";
import { alertClasses } from "@mui/material";
import { LAWYER_INITIATED_WORKFLOW } from "modules/workflow/types/Workflow";

const InterviewAlerts = ({
  isAdmin,
  pageId,
  workflowJurisdiction,
  userJurisdiction,
  workflowCreator,
  isOnlineProduct,
}: InterviewAlertsProps) => {
  const showConsumerJurisdictionWarning =
    !isAdmin &&
    pageId === clientDetailsPageDefinition.id &&
    workflowJurisdiction !== undefined;

  const showConsumerAddressWarning =
    !isAdmin && pageId === clientContactDetailsPageDefinition.id;

  const showAdminDraftingJurisdictionWarning =
    (isAdmin &&
      workflowJurisdiction &&
      workflowJurisdiction !== userJurisdiction) ||
    workflowCreator === LAWYER_INITIATED_WORKFLOW;

  return (
    <>
      {showConsumerJurisdictionWarning && (
        <div className={styles["alert"]}>
          <Alert
            type={
              workflowJurisdiction === userJurisdiction
                ? AlertType.INFO
                : AlertType.WARNING
            }
            message={`${"These documents will be created in the "} ${STATES_MAP[workflowJurisdiction]} jurisdiction. If this is not correct please click 'My Documents' above and use the
            green state picker in the title bar to choose the correct state before proceeding.
          `}
            classes={alertClasses}
          />
        </div>
      )}
      {showConsumerAddressWarning && (
        <div className={styles["alert"]}>
          <Alert
            type={AlertType.INFO}
            message={`It is important to create documents that follow the laws of the state
        or commonwealth in which you reside. While the documents might be
        recognized in another state, that is not always the case and may cause
        significant delays for your health, finances, or your estate if
        executed in the wrong jurisdiction. If you are not sure in which state
        you should be creating documents, please reach out to the law firm to
        discuss further.`}
          />
        </div>
      )}
      {showAdminDraftingJurisdictionWarning && (
        <div className={styles["alert"]}>
          <Alert
            type={AlertType.INFO}
            message={`${
              isOnlineProduct
                ? "This assessment is for"
                : "These documents are in"
            } ${
              (workflowJurisdiction && STATES_MAP[workflowJurisdiction]) ||
              workflowJurisdiction
            } jurisdiction.`}
          />
        </div>
      )}
    </>
  );
};

export default InterviewAlerts;
