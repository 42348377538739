import { createAction, Dispatch } from "@reduxjs/toolkit";
import { post, API } from "../api";
import logger, { TYPE } from "../../logger";
import DefinitionWithProductsPlansAndAddons from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import ProductsResponseType from "modules/api/responseTypes/ProductsResponseType";
import { PlanID } from "./types/Plan";
import { ProductID } from "./types/Product";
import ProductRecommendation from "./types/ProductRecommendation";
import { AnswersByQuestionnaireType } from "modules/workflow/types/Answer";
import parseProducts from "./helpers/parseProducts";
import Addon from "./types/Addon";

const callProductsAPI = async (
  state: string,
): Promise<Record<string, DefinitionWithProductsPlansAndAddons>> => {
  const response: ProductsResponseType = await post(API.PRODUCTS(), {
    userState: state,
  });
  return parseProducts(response.data);
};

export const loadingProductsStarted = createAction("LOADING_PRODUCTS_STARTED");
export const loadingProductsFailed = createAction<any>(
  "LOADING_PRODUCTS_FAILED",
);
export const productsLoaded =
  createAction<Record<string, DefinitionWithProductsPlansAndAddons>>(
    "PRODUCTS_LOADED",
  );
export const fetchProducts =
  (state: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(loadingProductsStarted());
    try {
      const productsOverview = await callProductsAPI(state);

      dispatch(productsLoaded(productsOverview));
    } catch (error) {
      logger.notify(
        TYPE.ERROR,
        "Loading products failed. Please, try again later",
        error,
      );
      dispatch(loadingProductsFailed(error));
    }
  };

export const getProductRecommendationStarted = createAction(
  "GET_PRODUCT_RECOMMENDATION_STARTED",
);
export const getProductRecommendationFailed = createAction<any>(
  "GET_PRODUCT_RECOMMENDATION_FAILED",
);
export const getProductRecommendationComplete = createAction<
  ProductRecommendation | undefined
>("GET_PRODUCT_RECOMMENDATION_COMPLETE");

export const getProductRecommendation =
  (_advisorAnswers: AnswersByQuestionnaireType, advisorAddons: Addon[]) =>
  async (dispatch: Dispatch): Promise<Addon[] | undefined> => {
    // No other API calls or computations, just the addons from the advisor questionnaire
    dispatch(
      getProductRecommendationComplete({
        addons: advisorAddons.map((addon) => addon.id),
      }),
    );
    return advisorAddons;
  };

export type StartProductAdvisorPayload = {
  workflowId: string;
  fromUrl: string;
  productId: ProductID;
  userId?: string;
};
export type StartPlanAdvisorPayload = {
  workflowId: string;
  fromUrl: string;
  planId: PlanID;
  userId?: string;
};
export type StartAdvisorPayload =
  | StartProductAdvisorPayload
  | StartPlanAdvisorPayload;
export const isPlanAdvisorPayload = (
  payload: StartAdvisorPayload,
): payload is StartPlanAdvisorPayload => payload.hasOwnProperty("planId");
export const startProductAdvisor = createAction<StartAdvisorPayload>(
  "START_PRODUCT_ADVISOR",
);

export const cancelAdvisor = createAction<{ previousUrl: string | undefined }>(
  "CANCEL_ADVISOR",
);
export const reviewAdvisorAnswers = createAction("REVIEW_ADVISOR_ANSWERS");

export type AdvisorQuestionnaireCompletePayload = {
  answers: AnswersByQuestionnaireType;
  addons: Addon[];
};
export const advisorQuestionnaireComplete =
  createAction<AdvisorQuestionnaireCompletePayload>(
    "ADVISOR_QUESTIONNAIRE_COMPLETE",
  );
