import { isEqual } from "lodash";
import { isAnswered } from "modules/workflow/helpers/getFilledQuestionsByQuestionnaire";
import { AnswersMap, AnswerValueType } from "modules/workflow/types/Answer";
import {
  QuestionDefinition,
  QuestionsMap,
} from "modules/workflow/types/Questionnaire";

const valueHasChanged = (
  value: AnswerValueType,
  originalValue: AnswerValueType | undefined,
  questionDefinition?: QuestionDefinition,
): boolean => {
  return (
    !isEqual(value, originalValue) &&
    (originalValue !== undefined || isAnswered(value, questionDefinition))
  );
};

export default (
  changedAnswers: AnswersMap,
  originalAnswers?: AnswersMap,
  questionDefinitions?: QuestionsMap,
) =>
  Object.entries(changedAnswers).reduce((acc, [key, value]) => {
    if (!originalAnswers) {
      acc[key] = value;
      return acc;
    }

    const originalAnswer: AnswerValueType | undefined = originalAnswers[key];
    if (
      valueHasChanged(
        value,
        originalAnswer,
        questionDefinitions && questionDefinitions[key]?.data.definition,
      )
    ) {
      acc[key] = value;
    }
    return acc;
  }, {} as AnswersMap);
