import { createReducer } from "@reduxjs/toolkit";
import {
  lawyerReviewStarted,
  startLawyerReviewFailed,
  startLawyerReviewPending,
} from "./actions";

export const isStartingLawyerReview = createReducer<boolean>(false, (builder) =>
  builder
    .addCase(startLawyerReviewPending, () => true)
    .addCase(lawyerReviewStarted, () => false)
    .addCase(startLawyerReviewFailed, () => false),
);
