import { mapValues } from "lodash";
import { DefinitionsProductsAndPlansPayload } from "modules/api/responseTypes/ProductsResponseType";
import DefinitionWithProductsPlansAndAddons, {
  PlanDefinition,
  ProductDefinition,
} from "modules/workflow/types/DefinitionWithProductsPlansAndAddons";
import {
  parseQuestionnairesFromPayload,
  pickQuestionnaires,
} from "modules/workflow/helpers/parseQuestionnaire";
import Questionnaire, {
  QuestionnaireID,
} from "modules/workflow/types/Questionnaire";

export const assignAdvisorQuestionnairesToProduct = (
  questionnairesMap: Record<QuestionnaireID, Questionnaire>,
  product: ProductDefinition,
): ProductDefinition => {
  if (product.advisors) {
    product.advisorQuestionnaires = pickQuestionnaires(
      questionnairesMap,
      product.advisors,
    );
  }
  return product;
};

export const assignAdvisorQuestionnairesToPlan = (
  questionnairesMap: Record<QuestionnaireID, Questionnaire>,
  products: Record<string, ProductDefinition>,
  plan: PlanDefinition,
): PlanDefinition => {
  const subQuestionnairesIDs = plan.products
    .map((productId) => products[productId] && products[productId].advisors)
    .filter((advisorIDs) => advisorIDs?.length)
    .flat() as string[];

  if (subQuestionnairesIDs.length) {
    plan.advisorQuestionnaires = pickQuestionnaires(
      questionnairesMap,
      subQuestionnairesIDs,
    );
  }
  return plan;
};

const parseProducts = (
  productsPayload: DefinitionsProductsAndPlansPayload,
): Record<string, DefinitionWithProductsPlansAndAddons> => {
  const { definitions, products, plans, questionnaires, addons } =
    productsPayload;
  const parsedQuestionnairesMap =
    parseQuestionnairesFromPayload(questionnaires);

  const productsWithAdvisors = mapValues(products, (product) =>
    assignAdvisorQuestionnairesToProduct(parsedQuestionnairesMap, product),
  );

  const plansWithAdvisors = mapValues(plans, (plan) =>
    assignAdvisorQuestionnairesToPlan(parsedQuestionnairesMap, products, plan),
  );

  return mapValues(definitions, (definition) => ({
    ...definition,
    data: {
      ...definition.data,
      availableProducts: Object.keys(definition.data.products)
        .filter((product) => definition.data.products[product].enabled)
        .map((productId: string): ProductDefinition | undefined =>
          productsWithAdvisors[productId]
            ? {
                ...productsWithAdvisors[productId],
              }
            : undefined,
        )
        .filter(Boolean) as ProductDefinition[],
      availablePlans: Object.keys(definition.data.plans)
        .filter((plan) => definition.data.plans[plan].enabled)
        .map((planId) => plansWithAdvisors[planId])
        .filter(Boolean),
      availableAddons: Object.keys(definition.data.addons)
        .map((addonId) => addons[addonId])
        .filter(Boolean),
    },
  }));
};

export default parseProducts;
