import { NotificationType } from "components/Notification";
import logger from "logger";
import { ErrorType } from "modules/api/types/ErrorResponsePayload";
import { AnswersMap } from "../types/Answer";

export default (errors: object, answers?: AnswersMap, errorCode?: string) => {
  logger.notify(
    NotificationType.ERROR,
    errorCode === ErrorType.ACTION_NOT_ALLOWED
      ? "Questionnaire has been finalized and can't be changed."
      : "The form is invalid. Please, review and correct your answers.",
  );
  logger.warn("Validation errors:", errors, "answers:", answers);
};
