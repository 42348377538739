import firebase from "firebase/compat/app";
import React from "react";
import ForgotPassword from "./components/ForgotPassword";

interface ForgotPasswordScreenProps {
  authProvider: firebase.auth.Auth;
  userJurisdiction?: string;
}

const ForgotPasswordScreen = ({
  authProvider,
  userJurisdiction,
}: ForgotPasswordScreenProps) => (
  <ForgotPassword
    authProvider={authProvider}
    userJurisdiction={userJurisdiction}
  />
);

export default ForgotPasswordScreen;
