import Gender from "./Gender";
import Address from "./Address";
import ContactInformation from "./ContactInformation";
import Client, { isClient } from "./Client";
import { User } from "modules/auth/User";

export enum PartyType {
  PERSON = "Person",
  ENTITY = "Entity",
}

export enum RelationshipType {
  CHILD = "Child",
  GRANDCHILD = "Grandchild",
  OTHER = "Other",
}

export enum WhoseChild {
  OURS = "Ours",
  MINE = "Mine",
  MY_PARTNERS = "Partners",
}

export type RelatedPersonRelationship = {
  type: RelationshipType.OTHER;
  value: string;
};

export type ChildRelationship = {
  type: RelationshipType.CHILD;
  disinherited: boolean;
  treatAsOwn: boolean;
  treatGrandchildrenAsOwn: boolean;
  whoseChild: WhoseChild;
};

export type GrandchildRelationship = {
  type: RelationshipType.GRANDCHILD;
};

export type RelationshipToClient =
  | RelatedPersonRelationship
  | ChildRelationship
  | GrandchildRelationship;

export type Relationship = {
  clientId: string;
  partyId: string;
  relationship: RelationshipToClient;
  created?: string;
  userId?: string;
  workflowId?: string;
};

export type ClientParties = {
  parties: Party[];
  children: Party[];
};

export type PartyID = string;

export type PartyToSave = Party & { relationshipToSave?: RelationshipToClient };

type Party = {
  address?: Address;
  children?: Party[];
  contact?: ContactInformation;
  created?: string;
  dateOfBirth?: string;
  deceased?: boolean;
  firstName: string;
  gender: Gender;
  lastName: string;
  middleName?: string;
  parentId?: string;
  partyId: PartyID;
  relationships?: Relationship[];
  suffix?: string;
  type: PartyType;
  updated?: string;
};

export default Party;

export const getFullName = (party: GenericParty | User) =>
  [
    party.firstName,
    party.middleName,
    party.lastName,
    "suffix" in party ? party.suffix : undefined,
  ]
    .filter(Boolean)
    .join(" ");

export const getFirstNamePossessive = (
  party: GenericParty | User | undefined,
  defaultName: string,
) => {
  const name = party?.firstName || defaultName;
  return name.endsWith("s") ? `${name}'` : `${name}'s`;
};

export const getGrandchildren = (parties: Party[]) =>
  parties.map((party) => party.children || []).flat();

export type GenericParty = Party | Client;

export const getGenericPartyId = (partyOrClient: GenericParty): string =>
  isClient(partyOrClient) ? partyOrClient.clientId : partyOrClient.partyId;
