import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import { useField } from "formik";
import classNames from "classnames/bind";

import {
  Sanitisers,
  setSelectionRange,
} from "components/Forms/Inputs/Input/helpers";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type TextareaProps = {
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  maxRows?: number;
  name: string;
  placeholder?: string;
  resize?: boolean;
  rows?: number;
  tabIndex?: number;
};

const Textarea: React.FunctionComponent<TextareaProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [field, meta] = useField(props.name);
  const resize = useCallback(() => {
    if (ref.current) {
      const computedStyle = getComputedStyle(ref.current);
      const lineHeight = parseInt(
        computedStyle.getPropertyValue("line-height"),
      );
      const padding =
        (parseInt(computedStyle.paddingTop) || 0) +
        (parseInt(computedStyle.paddingBottom) || 0);
      const border =
        (parseInt(computedStyle.borderTopWidth) | 0) +
        (parseInt(computedStyle.borderBottomWidth) || 0);
      ref.current.style.height = "auto";
      ref.current.style.maxHeight = "auto";
      ref.current.style.height = `${ref.current.scrollHeight + border}px`;
      if (
        props.maxRows &&
        props.rows &&
        props.maxRows >= props.rows &&
        lineHeight
      ) {
        ref.current.style.maxHeight = `${
          props.maxRows * lineHeight + padding + border
        }px`;
      }
    }
  }, [props.maxRows, props.rows, ref]);
  useEffect(() => {
    props.resize !== false && resize();
  }, [props.resize, resize]);
  let className = cx("textarea", {
    "textarea--disabled": props.disabled,
    "textarea--invalid": !props.disabled && meta.touched && meta.error,
    "textarea--valid":
      !props.disabled && meta.touched && !meta.error && meta.value,
  });
  return (
    <div className={className}>
      <textarea
        {...field}
        autoComplete="chrome-off"
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          field.onChange(event);
          props.resize !== false && resize();
        }}
        onInput={(event: React.FormEvent<HTMLTextAreaElement>) => {
          let value = event.currentTarget.value.replace(
            Sanitisers.LEADING_WHITESPACE,
            "",
          );
          if (event.currentTarget.value !== value) {
            typeof event.currentTarget.selectionEnd === "number"
              ? setSelectionRange(event, value)
              : (event.currentTarget.value = value);
          }
        }}
        ref={ref}
        rows={props.rows ? props.rows : 3}
        tabIndex={props.disabled ? -1 : props.tabIndex ? props.tabIndex : 0}
        value={field.value || ""}
      />
    </div>
  );
};
export default Textarea;
