import React from "react";
import classNames from "classnames/bind";

import Addon from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";

import PlanSelectorAddonsItem from "./PlanSelectorAddonsItem";

import styles from "./styles.module.scss";
import Product from "modules/product/types/Product";
const cx = classNames.bind(styles);

type PlanSelectorAddonsProps = {
  addons: Addon[];
  onAddonToggled: (addon: Addon) => void;
  selectedAddons: Addon[];
  selectedProduct?: Product | Plan;
};

const PlanSelectorAddons = ({
  addons,
  onAddonToggled,
  selectedAddons,
  selectedProduct,
}: PlanSelectorAddonsProps) => {
  let classNames = cx("plan-selector-addons", {
    "plan-selector-addons--disabled": !selectedProduct,
  });
  return (
    <div className={classNames}>
      <h3 className={styles["plan-selector-addons__title"]}>
        <span>Tailored Options</span>
      </h3>
      <div className={styles["plan-selector-addons__wrapper"]}>
        <ul className={styles["plan-selector-addons__list"]}>
          <li className={styles["plan-selector-addons__item-heading"]}>
            <div className={styles["plan-selector-addons__item-heading-addon"]}>
              Option
            </div>
            <div className={styles["plan-selector-addons__item-heading-add"]}>
              Add to plan
            </div>
          </li>
          {addons.map((addon: Addon) => (
            <PlanSelectorAddonsItem
              key={addon.id}
              addon={addon}
              disabled={!selectedProduct}
              onAddonToggled={onAddonToggled}
              selected={
                selectedAddons.find((a) => a.id === addon?.id) ? true : false
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PlanSelectorAddons;
