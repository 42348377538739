import { AddonID } from "modules/product/types/Addon";
import { ProductFeatures } from "modules/product/types/Product";

export const ChangeWorkflowAction = {
  InitializeCoupled: "InitializeCoupled",
  ConfirmAgreement: "ConfirmAgreement",
  ConfirmSummary: "ConfirmSummary",
  SetSupervisedSigning: "SetSupervisedSigning",
  SetAddons: "SetAddons",

  ReinitiateReview: "StartReview",

  // Admin
  StartReview: "StartReview",
  FinishReview: "FinishReview",
  CustomerContacted: "CustomerContacted",

  AcceptEngagementAgreement: "AcceptEngagementAgreement",
  RejectEngagementAgreement: "RejectEngagementAgreement",

  ConfirmReview: "ConfirmReview",
  RejectReview: "RejectReview",
};
export type ChangeWorkflowActionType =
  (typeof ChangeWorkflowAction)[keyof typeof ChangeWorkflowAction];

export const WorkflowAction = {
  ...ChangeWorkflowAction,
  Initialize: "Initialize",
  CreateClient: "CreateClient",
  UpdateClient: "UpdateClient",
  DeleteClient: "DeleteClient",
  CreateSpouse: "CreateSpouse",
  UpdateSpouse: "UpdateSpouse",
  DeleteSpouse: "DeleteSpouse",
  CreateChild: "CreateChild",
  UpdateChild: "UpdateChild",
  CreateParty: "CreateParty",
  UpdateParty: "UpdateParty",
  DeleteRelationship: "DeleteRelationship",
  SubmitAnswers: "SubmitAnswers",
  InitialisePayment: "InitialisePayment",
  SubmitPayment: "SubmitPayment",
  FailPayment: "FailPayment",
  CancelPayment: "CancelPayment",
  ConfirmPayment: "ConfirmPayment",
  SkipPayment: "SkipPayment",

  RenderDocuments: "RenderDocuments",
  FailRenderDocuments: "FailRenderDocuments",
  ConfirmRenderDocuments: "ConfirmRenderDocuments",
  DownloadDocuments: "DownloadDocuments",
  Finalize: "Finalize",
};
export type WorkflowActionType =
  (typeof WorkflowAction)[keyof typeof WorkflowAction];

export enum Step {
  Initialized = "Initialized",
  ClientDefined = "ClientDefined",
  AgreementConfirmed = "AgreementConfirmed",
  SpouseDefined = "SpouseDefined",
  ChildrenDefined = "ChildrenDefined",
  QuestionnaireInProgress = "QuestionnaireInProgress",
  WaitingForCoupledConfirmSummary = "WaitingForCoupledConfirmSummary",
  SummaryConfirmed = "SummaryConfirmed",
  PaymentRequired = "PaymentRequired",
  PaymentInitialised = "PaymentInitialised",
  PaymentSubmitted = "PaymentSubmitted",
  PaymentFailed = "PaymentFailed",
  AwaitingEngagementCheck = "AwaitingEngagementCheck",
  AwaitingPaymentCapture = "AwaitingPaymentCapture",
  AwaitingLawyerReview = "AwaitingLawyerReview",
  ReviewInProgress = "ReviewInProgress",
  AwaitingCoupledReviewFinished = "AwaitingCoupledReviewFinished",
  CustomerChangesDuringReview = "CustomerChangesDuringReview",
  AwaitingConfirmCoupledCustomerChangesDuringReview = "AwaitingConfirmCoupledCustomerChangesDuringReview",
  AwaitingCustomerConfirmReview = "AwaitingCustomerConfirmReview",
  AwaitingCoupledCustomerConfirmReview = "AwaitingCoupledCustomerConfirmReview",
  RepeatedPaymentRequired = "RepeatedPaymentRequired",
  RepeatedPaymentInitialised = "RepeatedPaymentInitialised",
  RepeatedPaymentSubmitted = "RepeatedPaymentSubmitted",
  RepeatedPaymentFailed = "RepeatedPaymentFailed",
  RepeatedPaymentConfirmed = "RepeatedPaymentConfirmed",
  DocumentsReady = "DocumentsReady",
  ContactCustomer = "ContactCustomer",
  AssetProtectionRequested = "AssetProtectionRequested",

  // Deprecated
  DocumentsRequested = "DocumentsRequested",
  DocumentRenderFailed = "DocumentRenderFailed",
  DocumentsRendered = "DocumentsRendered",
  DocumentsDownloaded = "DocumentsDownloaded",

  Finalized = "Finalized",
  EngagementRejected = "EngagementRejected",
}

export enum WorkflowStatus {
  OPEN = "Open",
  INTERVIEW_FINISHED = "Interview finished",
  AWAITING_SPOUSE_INTERVIEW = "Finish spouse interview",
  PAYMENT_REQUIRED = "Payment required",
  ADDITIONAL_PAYMENT_REQUIRED = "Additional payment required",
  PAYMENT_PROCESSING = "Processing payment",
  AWAITING_LAWYER_REVIEW = "Awaiting Attorney review",
  LAWYER_REVIEW_IN_PROGRESS = "Attorney review in progress",
  AWAITING_INPUT = "Awaiting your input",
  AWAITING_COUPLED_INPUT = "Awaiting spouse's interview completion",
  AWAITING_ENGAGEMENT_CHECK = "Waiting for the Attorney to accept the Engagement Agreement",
  AWAITING_REVIEW = "Awaiting your review",
  AWAITING_COUPLED_REVIEW = "Reviewed, awaiting your review of spouse's interview",
  DOCUMENTS_READY = "Documents ready",
  PURCHASED = "Product purchased",
  ENGAGEMENT_REJECTED = "Engagement agreement rejected",
}

export const workflowStepToStatusMap: Record<Step, WorkflowStatus> = {
  [Step.Initialized]: WorkflowStatus.OPEN,
  [Step.ClientDefined]: WorkflowStatus.OPEN,
  [Step.AgreementConfirmed]: WorkflowStatus.OPEN,
  [Step.SpouseDefined]: WorkflowStatus.OPEN,
  [Step.ChildrenDefined]: WorkflowStatus.OPEN,
  [Step.QuestionnaireInProgress]: WorkflowStatus.OPEN,
  [Step.WaitingForCoupledConfirmSummary]:
    WorkflowStatus.AWAITING_SPOUSE_INTERVIEW,
  [Step.SummaryConfirmed]: WorkflowStatus.INTERVIEW_FINISHED,
  [Step.PaymentRequired]: WorkflowStatus.PAYMENT_REQUIRED,
  [Step.PaymentInitialised]: WorkflowStatus.PAYMENT_REQUIRED,
  [Step.PaymentSubmitted]: WorkflowStatus.PAYMENT_PROCESSING,
  [Step.PaymentFailed]: WorkflowStatus.PAYMENT_REQUIRED,

  [Step.AwaitingEngagementCheck]: WorkflowStatus.AWAITING_ENGAGEMENT_CHECK,
  [Step.AwaitingPaymentCapture]: WorkflowStatus.PAYMENT_PROCESSING,
  [Step.AwaitingLawyerReview]: WorkflowStatus.AWAITING_LAWYER_REVIEW,
  [Step.ReviewInProgress]: WorkflowStatus.LAWYER_REVIEW_IN_PROGRESS,
  [Step.AwaitingCoupledReviewFinished]: WorkflowStatus.AWAITING_LAWYER_REVIEW,
  [Step.CustomerChangesDuringReview]: WorkflowStatus.AWAITING_INPUT,
  [Step.AwaitingConfirmCoupledCustomerChangesDuringReview]:
    WorkflowStatus.AWAITING_COUPLED_INPUT,
  [Step.AwaitingCustomerConfirmReview]: WorkflowStatus.AWAITING_REVIEW,
  [Step.AwaitingCoupledCustomerConfirmReview]:
    WorkflowStatus.AWAITING_COUPLED_REVIEW,

  [Step.RepeatedPaymentRequired]: WorkflowStatus.ADDITIONAL_PAYMENT_REQUIRED,
  [Step.RepeatedPaymentInitialised]: WorkflowStatus.ADDITIONAL_PAYMENT_REQUIRED,
  [Step.RepeatedPaymentSubmitted]: WorkflowStatus.PAYMENT_PROCESSING,
  [Step.RepeatedPaymentFailed]: WorkflowStatus.ADDITIONAL_PAYMENT_REQUIRED,
  [Step.RepeatedPaymentConfirmed]: WorkflowStatus.PAYMENT_PROCESSING,

  [Step.ContactCustomer]: WorkflowStatus.DOCUMENTS_READY,
  [Step.AssetProtectionRequested]: WorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentsReady]: WorkflowStatus.DOCUMENTS_READY,

  // Deprecated
  [Step.DocumentsRequested]: WorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentRenderFailed]: WorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentsRendered]: WorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentsDownloaded]: WorkflowStatus.DOCUMENTS_READY,

  [Step.Finalized]: WorkflowStatus.PURCHASED,
  [Step.EngagementRejected]: WorkflowStatus.ENGAGEMENT_REJECTED,
};

export enum AdminWorkflowStatus {
  OPEN = "Open",
  INTERVIEW_FINISHED = "Interview Finished",
  PAYMENT_REQUIRED = "Payment Required",
  AWAITING_ENGAGEMENT_CHECK = "Awaiting Engagement Agreement Review",
  AWAITING_LAWYER_REVIEW = "Awaiting Attorney Review",
  LAWYER_REVIEW_IN_PROGRESS = "Attorney Review in Progress",
  REVIEWED_BY_LAWYER = "Reviewed by Attorney",
  AWAITING_CLIENT_REVIEW = "Awaiting Client Review",
  DOCUMENTS_READY = "Documents Ready",
  ADMIN_CONTACT_CUSTOMER = "Client Follow-up Required",
  ENGAGEMENT_REJECTED = "Engagement Agreement Rejected",
}

export const workflowStepToAdminStatusMap: Record<Step, AdminWorkflowStatus> = {
  [Step.Initialized]: AdminWorkflowStatus.OPEN,
  [Step.ClientDefined]: AdminWorkflowStatus.OPEN,
  [Step.AgreementConfirmed]: AdminWorkflowStatus.OPEN,
  [Step.SpouseDefined]: AdminWorkflowStatus.OPEN,
  [Step.ChildrenDefined]: AdminWorkflowStatus.OPEN,
  [Step.QuestionnaireInProgress]: AdminWorkflowStatus.OPEN,
  [Step.SummaryConfirmed]: AdminWorkflowStatus.INTERVIEW_FINISHED,
  [Step.WaitingForCoupledConfirmSummary]: AdminWorkflowStatus.OPEN,
  [Step.PaymentRequired]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.PaymentInitialised]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.PaymentSubmitted]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.PaymentFailed]: AdminWorkflowStatus.PAYMENT_REQUIRED,

  [Step.AwaitingEngagementCheck]: AdminWorkflowStatus.AWAITING_ENGAGEMENT_CHECK,
  [Step.AwaitingPaymentCapture]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.AwaitingLawyerReview]: AdminWorkflowStatus.AWAITING_LAWYER_REVIEW,
  [Step.ReviewInProgress]: AdminWorkflowStatus.LAWYER_REVIEW_IN_PROGRESS,
  [Step.AwaitingCoupledReviewFinished]: AdminWorkflowStatus.REVIEWED_BY_LAWYER,
  [Step.CustomerChangesDuringReview]:
    AdminWorkflowStatus.AWAITING_CLIENT_REVIEW,
  [Step.AwaitingConfirmCoupledCustomerChangesDuringReview]:
    AdminWorkflowStatus.AWAITING_CLIENT_REVIEW,
  [Step.AwaitingCustomerConfirmReview]:
    AdminWorkflowStatus.AWAITING_CLIENT_REVIEW,
  [Step.AwaitingCoupledCustomerConfirmReview]:
    AdminWorkflowStatus.AWAITING_CLIENT_REVIEW,

  [Step.RepeatedPaymentRequired]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.RepeatedPaymentInitialised]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.RepeatedPaymentSubmitted]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.RepeatedPaymentFailed]: AdminWorkflowStatus.PAYMENT_REQUIRED,
  [Step.RepeatedPaymentConfirmed]: AdminWorkflowStatus.PAYMENT_REQUIRED,

  [Step.ContactCustomer]: AdminWorkflowStatus.ADMIN_CONTACT_CUSTOMER,
  [Step.AssetProtectionRequested]: AdminWorkflowStatus.ADMIN_CONTACT_CUSTOMER,
  [Step.DocumentsReady]: AdminWorkflowStatus.DOCUMENTS_READY,

  // Deprecated
  [Step.DocumentsRequested]: AdminWorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentRenderFailed]: AdminWorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentsRendered]: AdminWorkflowStatus.DOCUMENTS_READY,
  [Step.DocumentsDownloaded]: AdminWorkflowStatus.DOCUMENTS_READY,

  [Step.Finalized]: AdminWorkflowStatus.DOCUMENTS_READY,
  [Step.EngagementRejected]: AdminWorkflowStatus.ENGAGEMENT_REJECTED,
};

export type Progress = {
  total: number;
  completed: number;
};

export type PageProgress = {
  pageIndex: number;
  title: string;
  summary: Progress;
};

export type QuestionnaireProgress = {
  questionnaireId: string;
  summary: Progress;
  pageProgresses: PageProgress[];
};

export type ClientProgress = {
  credentials: boolean;
  contact: boolean;
  address: boolean;
};

export type WorkflowProgress = {
  summary: Progress;
  questionnairesProgresses: QuestionnaireProgress[];
  mainClient?: ClientProgress;
  spouseClient?: ClientProgress;
};

export type WorkflowStep = {
  step: Step;
  action: WorkflowActionType;
  timestamp: string;
  metadata: Record<string, string>;
};

export type WorkflowStepDetails = {
  current: WorkflowStep;
  history: WorkflowStep[];
};

export type LifecycleUpdate = Record<
  string,
  {
    availableActions: WorkflowActionType[];
    step: Step;
  }
>;

type PayloadWithLifecycleUpdate = any & { lifecycle: LifecycleUpdate };
export const hasLifecycleUpdate = (
  payload: any,
): payload is PayloadWithLifecycleUpdate => {
  if (
    typeof payload !== "object" ||
    !payload.hasOwnProperty("lifecycle") ||
    !payload.lifecycle
  )
    return false;

  const lifecycleChanges: any[] = Object.values(payload.lifecycle);
  for (const lifecycleChange of lifecycleChanges) {
    if (!lifecycleChange?.step) return false;
  }
  return true;
};

export type ProductDefinitionSelector = {
  productId: string;
  productDefinitionId: string;
};

export type WorkflowPayloadState = {
  clientFullName?: string;
  coupledWorkflowId?: string;
  enabledAddons: AddonID[];
  features?: ProductFeatures;
  fromPlan?: string;
  label: string;
  labelPrefix?: string;
  maybeUserState?: string;
  noInterview?: boolean;
  onlineProduct?: boolean;
  primary: boolean;
  products: ProductDefinitionSelector[];
  progress?: WorkflowProgress;
  steps: WorkflowStepDetails;
  supervisedSigning?: boolean;
  hasExternalProducts?: boolean;
};

type WorkflowPayload = {
  created: string;
  definitionId: string;
  id: string;
  lifecycle?: LifecycleUpdate;
  partnerId: string;
  state: WorkflowPayloadState;
  updated: string;
  userId: string;
  archived: boolean;
  creator: string;
};

export default WorkflowPayload;
