import React, { useMemo, useContext, createContext } from "react";
import { isAdmin as isAdminFunc } from "modules/auth/helper";
import { useLocation, useParams } from "react-router-dom";
import { RootState, useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";
import Workflow from "modules/workflow/types/Workflow";
import parseParams, { parseInteger } from "utils/parseUrlParams";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";

type WorkflowDetailsContextType = {
  asUser?: string;
  coupledWorkflow?: Workflow;
  isAdmin: boolean;
  requestedPageIdx: number;
  workflow: Workflow;
};

const WorkflowDetailsContext = createContext<WorkflowDetailsContextType>(
  {} as WorkflowDetailsContextType,
);

const WorkflowDetailsProvider: React.FC = (props) => {
  const location = useLocation();

  const { user, workflows } = useSelector(
    (state: RootState) => state,
    shallowEqual,
  );
  const partnerId = getFormattedPartnerId();
  const { id } = useParams() as Record<string, string>;

  const workflow = workflows[id];

  const isAdmin = partnerId ? isAdminFunc(partnerId, user) : false;
  const asUser = isAdmin ? workflow?.userId : undefined;
  const coupledWorkflow = workflow?.state.coupledWorkflowId
    ? workflows[workflow?.state.coupledWorkflowId]
    : undefined;

  const urlParams = parseParams(location.hash);
  const pageParam = parseInteger(urlParams.page);
  const requestedPageIdx = pageParam === null ? 0 : pageParam - 1;

  const value = useMemo(
    () => ({ asUser, coupledWorkflow, isAdmin, requestedPageIdx, workflow }),
    [asUser, coupledWorkflow, isAdmin, requestedPageIdx, workflow],
  );

  if (
    (!workflow ||
      workflow.isLoading ||
      !workflow.questionnaires ||
      !workflow.contacts) &&
    !workflow
  ) {
    return <p>Loading workflow...</p>;
  }

  return <WorkflowDetailsContext.Provider value={value} {...props} />;
};

const useWorkflowDetails = () => useContext(WorkflowDetailsContext);

export { WorkflowDetailsProvider, useWorkflowDetails };
