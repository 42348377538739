import BackLink from "components/BackLink";
import InformationBoxes from "components/InformationBoxes";
import InformationBox from "components/InformationBoxes/InformationBox";
import PageHeader from "components/PageHeader";
import PageSubHeader from "components/PageSubHeader";
import useFinancialProfile from "modules/globalAdmin/hooks/useFinancialProfile";
import { go } from "modules/navigation/helpers/navigator";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import { PartnerID } from "modules/parties/types/Partner";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import currencyFormatter from "utils/currencyFormatter";
import Ledger from "../Ledger";

const PartnerFinancialProfile = () => {
  const { partnerId } = useParams<{ partnerId: PartnerID }>();
  const { financialProfile, error } = useFinancialProfile(partnerId);
  const dispatch = useDispatch();

  if (!financialProfile || error) return null;

  const { displayName, billingEmail, totals, ledger } = financialProfile;

  return (
    <div>
      <BackLink
        onClick={() => {
          return go(dispatch, ROUTE_TEMPLATE.ADMIN_FINANCE);
        }}
        text="Back to Financial Dashboard"
      />
      <PageHeader title={displayName} />
      {billingEmail && <PageSubHeader title={`Billing: ${billingEmail}`} />}
      <InformationBoxes>
        <InformationBox
          title={"Total Sales"}
          info={currencyFormatter.format(totals.totalSales)}
        />
        <InformationBox
          title={"Total Partner Revenue"}
          info={currencyFormatter.format(totals.totalPartnerRevenue)}
        />
        <InformationBox
          title={"Total Aturna Revenue"}
          info={currencyFormatter.format(totals.totalGuidrRevenue)}
        />
      </InformationBoxes>
      <Ledger ledger={ledger} />
    </div>
  );
};

export default PartnerFinancialProfile;
