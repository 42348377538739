import * as React from "react";
import { ReactComponent as SupervisedSigningSVG } from "./svg/supervised-signing.svg";
import { ReactComponent as SelfSigningSVG } from "./svg/self-signing.svg";
import AddonWithChoices, {
  AddonWithChoicesProps,
} from "components/Forms/Questions/QuestionAddon/AddonWithChoices";
import { useState } from "react";
import SelfSigningDisclaimer from "./SelfSigningDisclaimer";

type SigningOptionsProps = {
  invalid?: boolean;
  isLoadingInstructions?: boolean;
  hasExternalProducts: boolean;
  onDownloadInstructions?: () => void;
  onSelfSigningSelected: () => void;
  onSupervisedSigningSelected: () => void;
  selectedOption?: boolean;
  supervisedPrice: number;
  isSubmitting: boolean;
};

const ICONS: Record<string, JSX.Element> = {
  supervisedSigning: (
    <SupervisedSigningSVG aria-label="Attorney Review and Supervised Signing" />
  ),
  selfSigning: <SelfSigningSVG aria-label="Self-Signing" />,
};

const SigningOptions = ({
  invalid,
  isLoadingInstructions,
  hasExternalProducts,
  onDownloadInstructions,
  onSelfSigningSelected,
  onSupervisedSigningSelected,
  selectedOption,
  supervisedPrice,
  isSubmitting,
}: SigningOptionsProps) => {
  const [selfSigningDisclaimerChecked, setSelfSigningDisclaimerChecked] =
    useState(selectedOption === false || false);

  const props: AddonWithChoicesProps = {
    choiceOn: {
      icon: ICONS.supervisedSigning,
      title: "Law Firm Review & Supervised Signing",
      description:
        "Get the benefit of a review with an attorney or paralegal at our law firm, to review your plan and ensure you have met all of your goals.",
      recommended: true,
      highlights: [
        "Scheduled phone call with us to review your selections and documents",
        "Come into one of our offices to sign your documents",
        "Notary and witnesses provided as needed",
      ],
      warning:
        "In-office signings will be scheduled based on law firm availability. If this is a rush, please select self-signing or reach out to the law firm directly to discuss scheduling.",
    },
    choiceOff: {
      title: "Self Signing",
      description:
        "You can print your documents and get them executed by following the instructions we will provide. You may need a notary and witnesses.",
      note: hasExternalProducts
        ? 'If you have chosen a plan with an upgrade, you will be contacted to make an appointment with the law firm. Consider choosing "Supervised Signing" to have your documents executed with the firm at the same time.'
        : undefined,
      icon: ICONS.selfSigning,
      highlights: [
        "Obtain free signing instructions",
        "Download your documents without going to the office",
      ],
      additionalContent: (
        <SelfSigningDisclaimer
          checked={selfSigningDisclaimerChecked}
          setChecked={setSelfSigningDisclaimerChecked}
        />
      ),
      disabled: !selfSigningDisclaimerChecked,
      ...(onDownloadInstructions
        ? {
            secondaryAction: {
              label: "View instructions",
              onAction: onDownloadInstructions,
              isLoading: isLoadingInstructions,
            },
          }
        : {}),
    },
    handleOn: onSupervisedSigningSelected,
    handleOff: onSelfSigningSelected,
    price: supervisedPrice,
    invalid,
    value: selectedOption,
    disabled: isSubmitting,
    buttonLabel: isSubmitting ? "Loading..." : "Select",
  };

  return <AddonWithChoices {...props} />;
};

export default SigningOptions;
