import * as React from "react";
import ReactCrop, { Crop } from "react-image-crop";
import Dropzone, { FileRejection, DropEvent } from "react-dropzone";
import { uniq } from "lodash";
import logger from "logger";
import { NotificationType } from "components/Notification";

import "react-image-crop/dist/ReactCrop.css";
import styles from "./styles.module.scss";
import Link from "components/Link";
import { formatBytes } from "./helpers";

const DEFAULT_MIN_WIDTH = 130;
const DEFAULT_MIN_HEIGHT = 130;
const DEFAULT_MAX_FILE_SIZE = 5242880;

export type ImageUploadProps = {
  alt?: string;
  circular?: boolean;
  defaultAspectRatio?: number;
  onChange?: (data: ImageData | null) => void;
  preview?: boolean;
  ruleOfThirds?: boolean;
  selectedImage?: string;
  minWidth?: number;
  minHeight?: number;
  maxFileSize?: number;
};

export type ImageUploadState = {
  initial?: string;
  src?: string | ArrayBuffer;
  crop?: Crop;
  file?: File;
};

export type ImageData = {
  blob: Blob;
  base64: string;
  downloadUrl?: string;
  original?: string;
  extension: string;
};

enum MimeType {
  JPEG = "image/jpeg",
  PNG = "image/png",
}

const acceptedMimeTypes: string[] = Object.values(MimeType);
const extensionForMimeType: Record<MimeType, string> = {
  [MimeType.JPEG]: "jpg",
  [MimeType.PNG]: "png",
};

const hasCrop = (crop?: Crop) =>
  crop && (crop.width || crop.height || crop.x || crop.y);

const isPortrait = (image: HTMLImageElement) => image.width < image.height;

const getCroppedImg = (
  image: HTMLImageElement,
  crop: Crop,
  mimeType: MimeType,
  fileName?: string,
): Promise<ImageData | null> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  if (crop.width && crop.height) {
    const previewWidth = Math.ceil(crop.width! * scaleX);
    const previewHeight = Math.ceil(crop.height! * scaleY);
    canvas.width = previewWidth;
    canvas.height = previewHeight;
    ctx?.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      previewWidth,
      previewHeight,
      0,
      0,
      previewWidth,
      previewHeight,
    );
  } else {
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    ctx?.drawImage(image, 0, 0);
  }

  const extension = extensionForMimeType[mimeType];
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) return null;
      if (fileName)
        (blob as Blob & { name?: string }).name = `${fileName}.${extension}`;
      resolve({ blob, base64: canvas.toDataURL(mimeType), extension });
    }, mimeType);
  });
};

const ImageUpload: React.FC<ImageUploadProps> = ({
  alt,
  circular,
  defaultAspectRatio,
  onChange,
  preview = true,
  ruleOfThirds,
  selectedImage,
  minHeight = DEFAULT_MIN_HEIGHT,
  minWidth = DEFAULT_MIN_WIDTH,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
}) => {
  const [image, setImage] = React.useState<ImageUploadState | null>(
    selectedImage ? { initial: selectedImage } : null,
  );
  const [cropPreview, setCropPreview] = React.useState<string | null>(null);

  const imageRef = React.useRef<HTMLImageElement>();
  const initialCropDone = React.useRef(false);

  const onCropChange = React.useCallback(
    (crop: Crop) => image && setImage({ ...image, crop }),
    [image],
  );

  const onCropComplete = React.useCallback(
    async (crop: Crop) => {
      cropPreview && window.URL.revokeObjectURL(cropPreview);
      const mimeType = image?.file?.type;

      if (!imageRef.current || !mimeType) return;

      // react-image-crop makes an initial crop which causes a race condition
      if (!initialCropDone.current) {
        initialCropDone.current = true;
        return;
      }

      const croppedImageDetails = await getCroppedImg(
        imageRef.current,
        crop,
        mimeType as MimeType,
        "preview",
      );
      if (croppedImageDetails) {
        window.URL.createObjectURL(croppedImageDetails.blob);
        setCropPreview(window.URL.createObjectURL(croppedImageDetails.blob));
        onChange && onChange(croppedImageDetails);
      }
    },
    [cropPreview, onChange, image],
  );

  const onImageLoaded = React.useCallback((image: HTMLImageElement) => {
    imageRef.current = image;
  }, []);

  const removeImage = React.useCallback(() => {
    setImage(null);
    setCropPreview(null);
    onChange && onChange(null);
  }, [onChange]);

  const onFilesDropped = React.useCallback(
    (acceptedFiles: File[], rejections: FileRejection[], event: DropEvent) => {
      if (rejections.length) {
        const errors = uniq(
          rejections.map((r) => r.errors.map((e) => e.message)).flat(),
        );
        logger.notify(NotificationType.ERROR, errors.join(", "));
      }

      if (acceptedFiles?.length) {
        const img = new Image();
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          if (reader.result) {
            img.src = reader.result as string;
          }
        });

        img.onload = () => {
          if (!reader.result) return;
          if (img.width < minWidth || img.height < minHeight) {
            logger.notify(
              NotificationType.ERROR,
              `Image must have minimum width of ${minWidth}px and height of ${minHeight}px.`,
            );
            return;
          }
          if (file.size > maxFileSize) {
            logger.notify(
              NotificationType.ERROR,
              `Image must have maximum size of ${formatBytes(maxFileSize)}.`,
            );
            return;
          }

          initialCropDone.current = false;
          setImage({ src: reader.result, file });
        };

        reader.readAsDataURL(file);
      }
    },
    [maxFileSize, minWidth, minHeight],
  );

  // Set default aspect ratio
  React.useEffect(() => {
    if (
      defaultAspectRatio &&
      image &&
      imageRef.current &&
      !hasCrop(image?.crop)
    ) {
      const crop: Crop = {
        aspect: defaultAspectRatio,
        unit: "%",
        ...(isPortrait(imageRef.current) ? { width: 100 } : { height: 100 }),
      };
      setImage({ ...image, crop });
    }
  }, [defaultAspectRatio, image]);

  return (
    <>
      {image ? (
        <div className={styles.CropWrapper}>
          {image.src ? (
            <>
              <p>Select an area to crop</p>
              <ReactCrop
                src={String(image.src)}
                crop={image.crop || {}}
                ruleOfThirds={ruleOfThirds}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
                circularCrop={circular}
                imageAlt={alt}
              />
            </>
          ) : image.initial ? (
            <img alt={alt} style={{ maxWidth: "100%" }} src={selectedImage} />
          ) : null}
          <div className={styles.CropWrapper__actions}>
            <Link onClick={removeImage}>Remove image</Link>
          </div>
        </div>
      ) : (
        <Dropzone
          onDrop={onFilesDropped}
          accept={acceptedMimeTypes}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div className={styles.DropArea} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Click or drag your image here</p>
              </div>
              <div className={styles.explainer}>
                Minimum image dimensions are {minWidth}x{minHeight}px. <br />
                Maximum file size is {formatBytes(maxFileSize)}.
              </div>
            </section>
          )}
        </Dropzone>
      )}
      {cropPreview && preview && (
        <img
          alt="Crop preview"
          style={{ maxWidth: "100%" }}
          src={cropPreview}
        />
      )}
    </>
  );
};

export default ImageUpload;
