import React from "react";
import TabbedButton from "components/TabbedButton";
import Partner from "modules/parties/types/Partner";
import logger from "logger";

type ActionStepLoginProps = {
  partner: Partner;
};

const ActionStepLogin: React.FunctionComponent<ActionStepLoginProps> = ({
  partner,
}) => {
  const handleActionStepLogin = () => {
    try {
      window.open(
        partner.integrations?.ActionStep || "",
        "_blank",
        "noreferrer",
      );
    } catch (e) {
      logger.error(e);
    }
  };

  return (
    <TabbedButton onClick={handleActionStepLogin}>
      Login with ActionStep
    </TabbedButton>
  );
};

export default ActionStepLogin;
