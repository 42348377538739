import Location from "modules/address/types/Location";
import { UserID, UserRole } from "modules/auth/User";
import { AnswerValueType } from "modules/workflow/types/Answer";
import Questionnaire from "modules/workflow/types/Questionnaire";
import Address from "./Address";
import Contact from "./Contact";

export type ActionStepConfig = {
  clientId: string;
  secretId: string;
  url: string;
  tokenUrl: string;
  refreshKey: string;
};

type DefaultValuesConfig = {
  values: Record<string, AnswerValueType>;
};

type PartnerPricingConfig = {
  support: number;
};

export type PromoCodesConfig = Record<string, number>;

type BasicPartnerData = {
  address: Address;
  attorney?: string;
  contact: Contact;
  displayName: string;
  enabled: boolean;
  drafting: boolean;
  medicaid: boolean;
  jurisdiction: string[];
  openingHours?: string;
  groupPicture?: string;
  attorneyPicture?: string;
  timezone?: string;
  location?: Location;
};

type AdminPartnerData = BasicPartnerData & {
  actionStep: ActionStepConfig;
  defaults: DefaultValuesConfig;
  pricing: PartnerPricingConfig;
  promoCodes: PromoCodesConfig;
  roles: Record<UserRole, UserID[]>;
};

export type PartnerData = BasicPartnerData & Partial<AdminPartnerData>;

export type PartnerID = string;

type Partner = {
  id: PartnerID;
  data: PartnerData;
  distance?: number;
  onboardingQuestionnaire?: Questionnaire;
  integrations?: {
    [key: string]: string;
  };
  created: string;
  updated: string;
  archived: boolean;
};

export default Partner;

export enum RegistrationState {
  PENDING = "Pending",
  REJECTED = "Rejected",
}

export type PartnerRegistrationState = {
  type: RegistrationState;
  message?: string;
};

export type PartnerRegistrationRequest = {
  address: Address;
  attorneyName: string;
  displayName: string;
  email: string;
  billingEmail: string;
  jurisdiction: string[];
  partnerId: PartnerID;
  phone: string;
  timezone: string;
};

export type PartnerRegistration = {
  id: string;
  partnerId: string;
  request: PartnerRegistrationRequest;
  status: PartnerRegistrationState;
  userId: UserID;
  updated: string;
  created: string;
};

export type PartnerAudit = {
  id: string;
  partnerId: string;
  userId: UserID;
  action: string;
  created: string;
  reason: string;
};

export type PartnerAudits = {
  records: PartnerAudit[];
};
