import PageHeader from "components/PageHeader";
import { keyBy } from "lodash";
import {
  DefinitionCategory,
  DefinitionData,
  DefinitionDataPlan,
  DefinitionDataProduct,
  DefinitionDescriptions,
} from "modules/api/responseTypes/DefinitionResponseType";
import { go } from "modules/navigation/helpers/navigator";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { QuestionID } from "modules/workflow/types/Questionnaire";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  generateProductFormQuestions,
  generateProductFormSections,
  generateProductFormValues,
  getDefinitionFromProductForm,
} from "./helpers";

import LoadingOverlay from "components/LoadingOverlay";
import Partner from "modules/parties/types/Partner";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { saveDefinition } from "modules/admin/actions";
import BackLink from "components/BackLink";

export const PRODUCT_FORM_ID = "productConfigForm";

type Props = {
  backLink: string;
  data: DefinitionData;
  definitionId: string;
  descriptions: DefinitionDescriptions;
  afterSave?: () => void;
  partner: Partner;
};

const ProductForm: React.FC<Props> = ({
  backLink,
  data,
  definitionId,
  descriptions,
  afterSave,
  partner,
}) => {
  const { categoryKey, productKey } = useParams<{
    categoryKey: DefinitionCategory;
    productKey: string;
  }>();
  const dispatch = useDispatch();

  const product = data[categoryKey][productKey];
  const description = descriptions[categoryKey][productKey];

  const handleSaveDefinition = useCallback(
    async (updatedPart: Partial<DefinitionData>) => {
      try {
        await saveDefinition(definitionId, updatedPart)(dispatch);
        afterSave && (await afterSave());
        logger.notify(NotificationType.SUCCESS, "Your data was saved");
      } catch (e) {
        logger.notify(
          NotificationType.ERROR,
          "Saving data failed. Please, review your information and try again later.",
          e,
        );
      }
    },
    [definitionId, dispatch, afterSave],
  );

  const handleFormSubmission = (values: Record<QuestionID, any>) => {
    const result = getDefinitionFromProductForm(values, product);
    handleSaveDefinition({
      [categoryKey]: {
        ...data[categoryKey],
        [productKey]: result,
      },
    });
  };

  const isProductOrPlanDefinition = (
    def: any,
  ): def is DefinitionDataProduct | DefinitionDataPlan =>
    def.hasOwnProperty("price");

  if (!product || !description) return <LoadingOverlay />;

  const questions = isProductOrPlanDefinition(product)
    ? generateProductFormQuestions(product, description, partner)
    : undefined;
  const formSections = isProductOrPlanDefinition(product)
    ? generateProductFormSections(
        partner.data.jurisdiction,
        product,
        description,
      )
    : [];
  const formValues = isProductOrPlanDefinition(product)
    ? generateProductFormValues(product, description)
    : undefined;

  return (
    <div>
      <BackLink
        onClick={() => go(dispatch, backLink)}
        text="Back to Product Setup"
      />
      <PageHeader title={description.name} />
      <QuestionnaireForm
        answers={formValues}
        onSavePageAnswers={(_, values) => handleFormSubmission(values)}
        customFormProps={{ saveButtonLabel: "Save Products" }}
        questionsBySubquestionnaire={{
          [PRODUCT_FORM_ID]: keyBy(questions, "id"),
        }}
        questionnairePages={[
          {
            id: `${PRODUCT_FORM_ID}_page`,
            questionnaireId: PRODUCT_FORM_ID,
            caption: "",
            sections: formSections,
          },
        ]}
      />
    </div>
  );
};

export default ProductForm;
