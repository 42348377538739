export const tableColumns = [
  {
    label: "Date paid",
    accessor: "dateCompleted",
    sortable: true,
    reset: false,
  },
  {
    label: "Client name",
    accessor: "displayName",
    sortable: true,
    reset: false,
  },
  {
    label: "Plan",
    accessor: "plan",
    sortable: true,
    reset: false,
  },
  {
    label: "Amount collected ($)",
    accessor: "amountCollected",
    sortable: true,
    reset: false,
  },
  {
    label: "Partner revenue ($)",
    accessor: "partnerRevenue",
    sortable: true,
    reset: false,
  },
  {
    label: "Aturna revenue ($)",
    accessor: "guidrRevenue",
    sortable: true,
    reset: false,
  },
  {
    label: "",
    accessor: "empty",
    sortable: false,
    reset: true,
  },
];
