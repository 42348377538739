import { createAction, Dispatch } from "@reduxjs/toolkit";
import { NotificationType } from "components/Notification";
import logger from "logger";
import { API, post } from "modules/api";
import { User } from "modules/auth/User";
import { showContactFormModal } from "./components/ContactFormModal";

const callContactAPI = async (
  workflowId: string,
  subject: string,
  body: string,
) => post(API.CONTACT_LAWYER({ workflowId }), { subject, body });

export const contactLawyerStarted = createAction("CONTACT_LAWYER_STARTED");
export const contactingLawyerCompleted = createAction(
  "CONTACTING_LAWYER_COMPLETED",
);
export const contactingLawyerFailed = createAction<any>(
  "CONTACTING_LAWYER_FAILED",
);
export const cancelContactLawyer = createAction("CANCEL_CONTACT_LAWYER");
export const triggerContactLawyer =
  (user: User | null, workflowId: string) =>
  (dispatch: Dispatch): Promise<void> =>
    new Promise((resolve) => {
      if (user?.email) {
        dispatch(contactLawyerStarted());
        showContactFormModal(
          async (body: string): Promise<any> => {
            try {
              const subject = "New message from Aturna&#8480;";
              const result = await callContactAPI(workflowId, subject, body);
              dispatch(contactingLawyerCompleted());
              logger.notify(
                NotificationType.SUCCESS,
                "Thank you! Your lawyer will contact you shortly.",
              );
              resolve();
              return result; // Notify the modal about success
            } catch (e) {
              logger.notify(
                NotificationType.ERROR,
                "Contacting your lawyer failed. Please, try again later.",
                e,
              );
              dispatch(contactingLawyerFailed(e));
            }
          },
          () => dispatch(cancelContactLawyer()),
        );
      }
    });
