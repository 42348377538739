import { Dispatch } from "@reduxjs/toolkit";
import { push, replace } from "connected-react-router";
import { matchPath } from "react-router-dom";
import { getRoute, ROUTE_TEMPLATE } from "../routes";
import { Location } from "history";
import { partnerSelected } from "modules/partner/actions";

export const isPath = (
  routeTemplate: ROUTE_TEMPLATE,
  location?: Location | string,
  exact = true,
) => {
  const pathname =
    typeof location === "string"
      ? location
      : location !== undefined
        ? location.pathname
        : window.location.pathname;
  return Boolean(
    matchPath(pathname, {
      exact,
      path: routeTemplate,
    }),
  );
};

export const matchPathTemplate = (
  routeTemplate: ROUTE_TEMPLATE,
  location?: Location,
  exact = true,
) =>
  matchPath((location || window.location).pathname, {
    exact,
    path: routeTemplate,
  });

export const isTemplate = (
  route: ROUTE_TEMPLATE | string,
): route is ROUTE_TEMPLATE => String(route).includes(":");

export const go = (
  dispatch: Dispatch,
  route: ROUTE_TEMPLATE | string,
  state?: {} | null,
) => {
  isTemplate(route)
    ? dispatch(
        push(getRoute(route), { from: window.location.pathname, ...state }),
      )
    : dispatch(push(route, { from: window.location.pathname, ...state }));
};

export const redirect = (
  dispatch: Dispatch,
  route: ROUTE_TEMPLATE | string,
  state?: {} | null,
) =>
  isTemplate(route)
    ? dispatch(replace(getRoute(route), state))
    : dispatch(replace(route, state));

export const getPartnerIdFromUrl = (
  location?: Location,
  exact?: boolean,
): string | undefined => {
  const matchedRoutes = Object.values(ROUTE_TEMPLATE)
    .map((route) => matchPathTemplate(route, location, exact))
    .filter(Boolean);

  const simpleRoute = matchedRoutes.find(
    (match) => (match?.params as any)?.partnerId === undefined,
  );
  if (simpleRoute) return undefined;

  return (matchedRoutes[0]?.params as any)?.partnerId;
};

export const getFirstSubpath = () => {
  const parsedPath = window.location.pathname.match(/^\/([^/]+)+/);
  return parsedPath && parsedPath[1];
};

export const externalRedirect = (href: string) => {
  window?.location?.assign(href);
};

export const openInNewTab = (href: string) => {
  return window.open(href, "_blank");
};

export const navigateToGlobalLanding = (dispatch: Dispatch) => {
  go(dispatch, ROUTE_TEMPLATE.ROOT);
};

export const navigateToPartnerLanding = (
  dispatch: Dispatch,
  partnerId: string,
) => {
  dispatch(partnerSelected(partnerId));
  go(dispatch, ROUTE_TEMPLATE.PARTNER_ROOT, { partnerId });
};

export const navigateToPartnerPricing = (
  dispatch: Dispatch,
  partnerId: string,
) => {
  dispatch(partnerSelected(partnerId));
  go(dispatch, ROUTE_TEMPLATE.PRODUCTS, { partnerId });
};

export const navigateToSearchScreen = (dispatch: Dispatch) => {
  go(dispatch, ROUTE_TEMPLATE.SEARCH);
};
