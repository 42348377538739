import React from "react";
import TabbedButton from "components/TabbedButton";
import LoadingSpinner, { SpinnerSize } from "components/LoadingSpinner";
import { Grid } from "@mui/material";
import { IconType } from "components/Icons";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import Link from "components/Link";
const cx = classNames.bind(styles);

export enum BannerType {
  SUCCESS = "success",
  INFO = "info",
}

type BannerProps = {
  buttonIcon?: IconType;
  className?: string;
  description: string | JSX.Element;
  isLoading?: boolean;
  label?: string;
  onClick?: () => any;
  secondaryAction?: { label: string; onClick: () => any };
  title: string;
  type?: BannerType;
};

const Banner: React.FunctionComponent<BannerProps> = ({
  buttonIcon,
  className,
  description,
  isLoading,
  label,
  onClick,
  secondaryAction,
  title,
  type = BannerType.SUCCESS,
}) => (
  <div className={cx("banner", `banner--${type}`, className)}>
    <Grid container spacing={2} alignItems="center">
      <Grid item style={{ flexGrow: 1 }}>
        <h4 className={cx("banner__title")}>{title}</h4>
        <p className={cx("banner__description")}>{description}</p>
      </Grid>
      <Grid item className={cx("banner__action")}>
        {isLoading ? (
          <div className={cx("banner__action__spinner")}>
            <LoadingSpinner size={SpinnerSize.SMALL} />
          </div>
        ) : null}
        {!isLoading && label && onClick && (
          <TabbedButton
            label={label}
            onClick={onClick}
            icon={buttonIcon}
            disabled={isLoading}
          />
        )}
        {!isLoading && secondaryAction && (
          <Link onClick={secondaryAction.onClick}>{secondaryAction.label}</Link>
        )}
      </Grid>
    </Grid>
  </div>
);

export default Banner;
