import Plan from "modules/product/types/Plan";
import Product from "modules/product/types/Product";
import { AnswersMap } from "modules/workflow/types/Answer";

export enum AuthenticationTrigger {
  START_WORKFLOW_PLAN = "startWorkflowWithPlan",
  START_WORKFLOW_PRODUCT = "startWorkflowWithProduct",
}

type GenericCallbackMeta = {
  action: AuthenticationTrigger;
  preventLoginRedirect?: boolean;
};

type StartWorkflowMeta = GenericCallbackMeta & {
  definitionId: string;
  advisorAnswers?: AnswersMap;
  customAddons?: string[];
  state: string;
};

export type StartWorkflowWithProductMeta = StartWorkflowMeta & {
  action: AuthenticationTrigger.START_WORKFLOW_PRODUCT;
  product: Product;
};

export type StartWorkflowWithPlanMeta = StartWorkflowMeta & {
  action: AuthenticationTrigger.START_WORKFLOW_PLAN;
  plan: Plan;
};

type LoginCallbackMeta =
  | StartWorkflowWithProductMeta
  | StartWorkflowWithPlanMeta;

export const isWorkflowCreationMeta = (
  loginCallbackMeta?: LoginCallbackMeta,
): loginCallbackMeta is
  | StartWorkflowWithProductMeta
  | StartWorkflowWithPlanMeta =>
  loginCallbackMeta
    ? [
        AuthenticationTrigger.START_WORKFLOW_PRODUCT,
        AuthenticationTrigger.START_WORKFLOW_PLAN,
      ].includes(loginCallbackMeta.action)
    : false;

export default LoginCallbackMeta;
