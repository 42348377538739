import React from "react";
import { UnqualifiedForMedicaidMessageProps } from "./types";

const UnqualifiedForMedicaidMessage = ({
  calculation,
}: UnqualifiedForMedicaidMessageProps) => {
  return (
    <>
      <h2>Sorry, you do not qualify for Medicaid</h2>
      <p>
        Based upon the information entered, your income exceeds the cost of
        care. As a result you will not be "otherwise eligible" and will not
        qualify for Medicaid.
      </p>
    </>
  );
};

export default UnqualifiedForMedicaidMessage;
