import * as React from "react";

import styles from "./styles.module.scss";

type ClientDashboardProps = {
  children: [React.ReactNode, React.ReactNode];
};

const ClientDashboard: React.FunctionComponent<ClientDashboardProps> = (
  props,
) => {
  return <div className={styles["client-dashboard"]}>{props.children}</div>;
};

export default ClientDashboard;
