import { AlertType } from "components/Alert";
import Gender from "modules/parties/types/Gender";
import { ValueConstraintType } from "modules/workflow/types/Condition/ValueCondition";
import DatediffConstraint, {
  DiffType,
  DateDiffUnit,
} from "modules/workflow/types/Condition/ValueCondition/DatediffConstraint";
import { WorkflowDetailsConstraintType } from "modules/workflow/types/Condition/WorkflowDetailsCondition";
import {
  Question,
  QuestionType,
  TextFieldType,
  QuestionnairePage,
} from "modules/workflow/types/Questionnaire";
import moment from "moment";
import suffixes from "../suffixes";

export const CLIENT_FORM_ID = "clientForm";
export const CLIENT_PAGE_ID = "client_details";

export const clientFields = {
  firstName: "client_firstName",
  middleName: "client_middleName",
  lastName: "client_lastName",

  suffix: "client_suffix",
  alias: "client_alias",
  gender: "client_gender",

  dateOfBirth: "client_birthdate",
  dateOfBirthAlert: "client_birthdate_alert",
  isMarried: "client_marital_status",
  clientAndSpouseUSCitizens: "client_couple_citizens",
  usCitizensAlert: "client_couple_citizens_alert",
  addSpouseDoc: "add_spouse_doc",

  address: "client_home_address",
  homeAddressIsMailing: "client_home_address_is_mailing",
  mailingAddress: "client_mailing_address",

  // alias: "",
  contact: {
    cellPhone: "client_contact_cell",
    homePhone: "client_contact_home",
    workPhone: "client_contact_work",
    email: "client_contact_email",
  },
};

export const basicQuestions: Question[] = [
  {
    id: clientFields.firstName,
    data: {
      descriptions: [{ label: "First name" }],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: clientFields.middleName,
    data: {
      descriptions: [{ label: "Middle name (optional)" }],
      definition: { type: QuestionType.TEXT },
      required: false,
    },
  },

  {
    id: clientFields.lastName,
    data: {
      descriptions: [{ label: "Last name" }],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: clientFields.suffix,
    data: {
      descriptions: [
        {
          label: "Suffix (optional)",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: suffixes.map((value) => ({ value })),
        radio: true,
        allowOther: true,
        otherLabel: "Please enter your suffix",
      },
      required: false,
    },
  },

  {
    id: clientFields.alias,
    data: {
      descriptions: [
        {
          label: "Also known as (optional)",
          description:
            "If your current name or preferred name is not the legal name you were born with or if you have other names you have been identified by on assets you own (bank accounts, real estate, life insurance, etc.) please identify the other names (separated by a comma with an “and” prior to the last identified name, if more than one). You do not need to include maiden names, unless you still have assets in that name.",
        },
      ],
      definition: { type: QuestionType.TEXT },
      required: false,
    },
  },

  {
    id: clientFields.gender,
    data: {
      descriptions: [
        {
          label: "What are your personal pronouns?",
          description:
            "This helps us fill in the correct pronouns throughout your legal documents.",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: [
          { display: "he/him", value: Gender.MALE },
          { display: "she/her", value: Gender.FEMALE },
          { display: "they/them", value: Gender.OTHER },
        ],
        radio: true,
      },
      required: true,
    },
  },

  {
    id: clientFields.dateOfBirth,
    data: {
      descriptions: [{ label: "Date of birth" }],
      definition: {
        type: QuestionType.DATE,
        limits: {
          max: new Date().toISOString(),
          min: moment().subtract(99, "years").toISOString(),
        },
      },
      required: true,
    },
  },

  {
    id: clientFields.dateOfBirthAlert,
    data: {
      descriptions: [],
      definition: {
        type: QuestionType.ALERT,
        level: AlertType.WARNING,
        message:
          "Individuals under the age of 18 cannot legally execute estate planning documents.",
      },
      condition: {
        [CLIENT_FORM_ID]: {
          [clientFields.dateOfBirth]: {
            type: ValueConstraintType.DateDiff,
            value: 18,
            op: DiffType.LOWER,
            unit: DateDiffUnit.YEARS,
          } as DatediffConstraint,
        },
      },
    },
  },

  {
    id: clientFields.isMarried,
    data: {
      descriptions: [
        {
          label: "Are you married?",
        },
      ],
      definition: { type: QuestionType.FLAG },
      required: true,
      condition: {
        workflow: {
          type: WorkflowDetailsConstraintType.IsCoupled,
          value: false,
        },
      },
    },
  },

  {
    id: clientFields.clientAndSpouseUSCitizens,
    data: {
      descriptions: [
        {
          label: "Are both you and your spouse US citizens?",
        },
      ],
      definition: { type: QuestionType.FLAG },
      condition: {
        [CLIENT_FORM_ID]: {
          [clientFields.isMarried]: true,
        },
      },
    },
  },

  {
    id: clientFields.usCitizensAlert,
    data: {
      descriptions: [],
      definition: {
        type: QuestionType.ALERT,
        level: AlertType.WARNING,
        message:
          "If you and your spouse are not both U.S. citizens, you should strongly consider contacting the law firm to discuss more advanced planning options and legal consequences that come along with one or both of you being non-citizens.",
      },
      condition: {
        [CLIENT_FORM_ID]: {
          [clientFields.clientAndSpouseUSCitizens]: false,
        },
      },
    },
  },

  {
    id: clientFields.addSpouseDoc,
    skipSummary: true,
    data: {
      descriptions: [
        {
          label: "Add a document for your spouse",
        },
      ],
      condition: {
        all: [
          { [CLIENT_FORM_ID]: { [clientFields.isMarried]: true } },
          {
            workflow: {
              type: WorkflowDetailsConstraintType.IsCoupled,
              value: false,
            },
          },
        ],
      },
      definition: { type: QuestionType.CLONE_BANNER },
    },
  },
];

export const contactQuestions: Question[] = [
  {
    id: clientFields.address,
    data: {
      descriptions: [{ label: "Home address" }],
      definition: { type: QuestionType.ADDRESS },
      required: true,
    },
  },

  {
    id: clientFields.homeAddressIsMailing,
    data: {
      descriptions: [
        { label: "Is your mailing address the same as your home address?" },
      ],
      definition: { type: QuestionType.FLAG },
      required: true,
      defaultValue: true,
    },
  },

  {
    id: clientFields.mailingAddress,
    data: {
      descriptions: [
        {
          label: "Mailing address",
        },
      ],
      definition: { type: QuestionType.ADDRESS },
      required: true,
      condition: {
        [CLIENT_FORM_ID]: { [clientFields.homeAddressIsMailing]: false },
      },
    },
  },

  {
    id: clientFields.contact.cellPhone,
    data: {
      descriptions: [{ label: "Cell" }],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
      required: true,
    },
  },

  {
    id: clientFields.contact.homePhone,
    data: {
      descriptions: [{ label: "Home (optional)" }],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },

  {
    id: clientFields.contact.workPhone,
    data: {
      descriptions: [{ label: "Work (optional)" }],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },

  {
    id: clientFields.contact.email,
    data: {
      descriptions: [{ label: "Email address" }],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.EMAIL },
      required: true,
    },
  },
];

export const clientQuestions = [...basicQuestions, ...contactQuestions];

export const clientDetailsPageDefinition: QuestionnairePage = {
  id: CLIENT_PAGE_ID,
  questionnaireId: CLIENT_FORM_ID,
  caption: "Your Information",
  description: "",
  isStatic: true,
  sections: [
    {
      caption: "Your details",
      description:
        "Please use your legal name as it appears on your driver’s license or government-issued ID.",
      questions: [
        [
          clientFields.firstName,
          clientFields.middleName,
          clientFields.lastName,
        ],
        clientFields.suffix,
        clientFields.alias,
        clientFields.dateOfBirth,
        clientFields.dateOfBirthAlert,
        clientFields.gender,
      ],
    },
    {
      caption: "Your marital status",
      questions: [
        clientFields.isMarried,
        clientFields.clientAndSpouseUSCitizens,
        clientFields.usCitizensAlert,
        clientFields.addSpouseDoc,
      ],
    },
  ],
};

export const clientContactDetailsPageDefinition: QuestionnairePage = {
  id: "client_contact",
  caption: "Your Contact Details",
  questionnaireId: CLIENT_FORM_ID,
  description: "",
  isStatic: true,
  sections: [
    {
      caption: "Your address",
      questions: [clientFields.address, clientFields.homeAddressIsMailing],
    },
    {
      caption: "Mailing address",
      condition: {
        [CLIENT_FORM_ID]: { [clientFields.homeAddressIsMailing]: false },
      },
      questions: [clientFields.mailingAddress],
    },
    {
      caption: "Your contact details",
      questions: [
        [
          clientFields.contact.cellPhone,
          clientFields.contact.homePhone,
          clientFields.contact.workPhone,
        ],
        clientFields.contact.email,
      ],
    },
  ],
};
