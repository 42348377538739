import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "redux/store";
import * as Sentry from "@sentry/react";

import LoginScreenContainer from "./screens/login/LoginScreenContainer";
import ResetPasswordScreenContainer from "./screens/resetPassword/ResetPasswordScreenContainer";
import SignupScreenContainer from "./screens/signup/SignupScreenContainer";
import ForgotPasswordScreenContainer from "./screens/forgotPassword/ForgotPasswordScreenContainer";
import ProtectedRoute from "./modules/auth/components/ProtectedRoute";

import { appLoaded } from "./modules/navigation/actions";
import { useSelector } from "redux/reducer";
import WorkflowScreenContainer from "./screens/workflow/WorkflowScreenContainer";
import ProfileScreenContainer from "./screens/profile/ProfileScreenContainer";
import AppEnvelope from "screens/core/AppEnvelope";

import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import PartnerSetupScreen from "screens/admin/PartnerSetupScreen";
import { UserRole } from "modules/auth/User";
import LoadingOverlay from "components/LoadingOverlay";
import ProductSelectorScreenContainer from "screens/productSelector/ProductSelectorScreenContainer";
import ProductTailoringScreenContainer from "screens/productTailoring/ProductTailoringScreenContainer";
import SearchScreenContainer from "screens/core/SearchScreen/SearchScreenContainer";
import WorkflowDetailNavigator from "screens/workflowDetailNavigator/WorkflowDetailNavigator";
import ProductSetupScreen from "screens/admin/ProductSetupScreen";
import TermsPage from "screens/pages/TermsPage";
import PrivacyPage from "screens/pages/PrivacyPage";
import ActionStepCallback from "modules/partner/settings/components/ActionStepCallback";
import ActionStepOauth from "modules/partner/settings/components/ActionStepOauth";
import PermissionsScreen from "screens/admin/PermissionsScreen";
import DraftingScreen from "screens/admin/DraftingScreen";
import AdvisorScreen from "screens/advisor/AdvisorScreen";
import ProductRecommendationScreen from "screens/productRecommendation/ProductRecommendationScreen";
import NotFound from "components/NotFound";
import PartnersScreen from "screens/partners";
import { isAdmin, isGlobalAdmin } from "modules/auth/helper";
import { isPath } from "modules/navigation/helpers/navigator";
import PartnersAdminNavigator from "screens/globalAdmin/PartnersAdminNavigator";
import PartnerRegistrationScreen from "screens/partners/PartnerRegistrationScreen";
import PartnerRequestsScreen from "screens/globalAdmin/PartnerRequestsScreen";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import FinanceScreen from "screens/finance/FinanceScreen";
import PartnerFinancialProfile from "modules/finance/PartnerFinancialProfile";
import MedicaidVariablesScreen from "screens/medicaidAnalysis/MedicaidVariablesScreen";
import { stateChanged } from "./modules/auth/actions";
import LandingScreen from "modules/landing/screens/LandingScreen";
import { MedicaidLeadContextProvider } from "contexts/MedicaidLeadContext";
import MedicaidLeadFinished from "screens/medicaidAnalysis/MedicaidAnalysis/MedicicaidLeadFinished";
import MedicaidAnalysisContainer from "screens/medicaidAnalysis/MedicaidAnalysisContainer";
import MedicaidLeadContainer from "screens/medicaidAnalysis/MedicaidLeadContainer";
import MedicaidLeadMapContainer from "screens/medicaidAnalysis/MedicaidLeadMapContainer";
import MedicaidLeadPreview from "screens/medicaidAnalysis/admin/MedicaidLeadPreview";
import MedicaidLeads from "screens/medicaidAnalysis/admin/MedicaidLeads";
import ConsumerMedicaidLeads from "screens/medicaidAnalysis/ConsumerMedicaidLeads";
import CookiesPolicyPage from "screens/pages/CookiesPolicyPage";
import AboutUsPage from "screens/pages/AboutUsPage";
import FAQsPage from "screens/pages/FAQsPage";
import LearningCenterPage from "screens/pages/LearningCenterPage";

const Navigator: React.FunctionComponent = () => {
  const { user, isSessionRehydrated } = useSelector(
    (state) => state,
    shallowEqual,
  );
  const partnerId = getFormattedPartnerId();
  const isLoadingUserDetails = useSelector(
    ({ isLoadingUserDetails }) => isLoadingUserDetails,
  );
  const dispatch = useDispatch();

  const admin = isAdmin(partnerId, user);
  const globalAdmin = isGlobalAdmin(user);

  // Initial load and log-out
  useEffect(() => {
    !user && dispatch(appLoaded());
  }, [dispatch, user]);

  const isPartnerRoot =
    isPath(ROUTE_TEMPLATE.PARTNER_ROOT) &&
    !(
      isPath(ROUTE_TEMPLATE.SEARCH) ||
      isPath(ROUTE_TEMPLATE.MY_PARTNERS) ||
      isPath(ROUTE_TEMPLATE.OAUTH_CALLBACK) ||
      isPath(ROUTE_TEMPLATE.PROFILE)
    );

  const isFullScreenPage =
    !isLoadingUserDetails &&
    (isPath(ROUTE_TEMPLATE.SEARCH) ||
      isPath(ROUTE_TEMPLATE.ROOT) ||
      isPath(ROUTE_TEMPLATE.MEDICAID_LEAD_MAP) ||
      isPath(ROUTE_TEMPLATE.ABOUT_US) ||
      isPath(ROUTE_TEMPLATE.LEARNING_CENTER) ||
      isPath(ROUTE_TEMPLATE.FAQS) ||
      isPartnerRoot);

  const isLandingPage = isPath(ROUTE_TEMPLATE.ROOT) || isPartnerRoot;
  const handleChangeState = (state: string) => {
    dispatch(stateChanged(state));
  };

  return (
    <ConnectedRouter history={history}>
      <AppEnvelope
        reducedHeightFooter={isFullScreenPage}
        fullWidth={isFullScreenPage}
        landingFooter={isLandingPage}
      >
        {(!user && isLoadingUserDetails) || !isSessionRehydrated ? (
          <LoadingOverlay />
        ) : (
          <Switch>
            <Route
              path={ROUTE_TEMPLATE.OAUTH_CALLBACK}
              component={ActionStepCallback}
            />

            <Route
              path={ROUTE_TEMPLATE.OAUTH_PARTNER}
              component={ActionStepOauth}
            />

            <Route path={ROUTE_TEMPLATE.LOGIN} exact>
              <LoginScreenContainer />
            </Route>

            <Route path={ROUTE_TEMPLATE.SIGNUP} exact>
              <SignupScreenContainer />
            </Route>

            <Route path={ROUTE_TEMPLATE.RESET_PASSWORD} exact>
              <ResetPasswordScreenContainer />
            </Route>

            <Route path={ROUTE_TEMPLATE.FORGOTTEN_PASSWORD} exact>
              <ForgotPasswordScreenContainer />
            </Route>

            <Route path={ROUTE_TEMPLATE.TERMS_AND_CONDITIONS} exact>
              <TermsPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.PRIVACY} exact>
              <PrivacyPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.COOKIES_POLICY} exact>
              <CookiesPolicyPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.ABOUT_US} exact>
              <AboutUsPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.LEARNING_CENTER} exact>
              <LearningCenterPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.FAQS} exact>
              <FAQsPage />
            </Route>

            <Route path={ROUTE_TEMPLATE.SEARCH} exact>
              {globalAdmin ? (
                <Redirect
                  to={{ pathname: getRoute(ROUTE_TEMPLATE.ADMIN_PARTNERS) }}
                />
              ) : (
                <SearchScreenContainer />
              )}
            </Route>

            <Route path={ROUTE_TEMPLATE.MY_MEDICAID_WORKFLOWS} exact>
              <MedicaidLeadContextProvider>
                <ConsumerMedicaidLeads />
              </MedicaidLeadContextProvider>
            </Route>

            <Route path={ROUTE_TEMPLATE.MEDICAID_ANALYSIS} exact>
              <MedicaidLeadContextProvider>
                <MedicaidAnalysisContainer />
              </MedicaidLeadContextProvider>
            </Route>

            <Route path={ROUTE_TEMPLATE.MEDICAID_LEAD_MAP} exact>
              <MedicaidLeadContextProvider>
                <MedicaidLeadMapContainer />
              </MedicaidLeadContextProvider>
            </Route>

            <Route path={ROUTE_TEMPLATE.MEDICAID_LEAD_FINISHED} exact>
              <MedicaidLeadContextProvider>
                <MedicaidLeadFinished />
              </MedicaidLeadContextProvider>
            </Route>

            <Route path={ROUTE_TEMPLATE.MEDICAID_LEAD} exact>
              <MedicaidLeadContextProvider>
                <MedicaidLeadContainer />
              </MedicaidLeadContextProvider>
            </Route>

            <ProtectedRoute path={ROUTE_TEMPLATE.PROFILE}>
              <ProfileScreenContainer />
            </ProtectedRoute>

            <ProtectedRoute path={ROUTE_TEMPLATE.MY_PARTNERS} exact>
              <PartnersScreen />
            </ProtectedRoute>

            <ProtectedRoute
              rejectedRoles={[UserRole.GLOBAL_ADMIN]}
              path={ROUTE_TEMPLATE.PARTNER_REGISTRATION}
              exact
            >
              <PartnerRegistrationScreen />
            </ProtectedRoute>

            <ProtectedRoute path={ROUTE_TEMPLATE.PRODUCTS}>
              <ProductSelectorScreenContainer />
            </ProtectedRoute>

            <Route path={ROUTE_TEMPLATE.ADVISOR}>
              <AdvisorScreen />
            </Route>

            <Route path={ROUTE_TEMPLATE.PRODUCT_RECOMMENDATION}>
              <ProductRecommendationScreen />
            </Route>

            <Route path={ROUTE_TEMPLATE.PRODUCT_TAILORING}>
              <ProductTailoringScreenContainer />
            </Route>

            <Route path={ROUTE_TEMPLATE.PLAN_TAILORING}>
              <ProductTailoringScreenContainer />
            </Route>

            <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW_DETAILS}>
              <WorkflowDetailNavigator />
            </ProtectedRoute>

            <ProtectedRoute path={ROUTE_TEMPLATE.WORKFLOW}>
              <WorkflowScreenContainer />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.ADMIN_PARTNERS}
              roles={[UserRole.GLOBAL_ADMIN]}
            >
              <PartnersAdminNavigator />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.ADMIN_REGISTRATIONS}
              roles={[UserRole.GLOBAL_ADMIN]}
            >
              <PartnerRequestsScreen />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.SITE_CONFIGURATION}
              roles={[UserRole.ADMIN]}
            >
              <PartnerSetupScreen />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PRODUCT_CONFIGURATION}
              roles={[UserRole.ADMIN]}
            >
              <ProductSetupScreen />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PERMISSION_CONFIGURATION}
              roles={[UserRole.ADMIN]}
            >
              <PermissionsScreen />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PARTNER_MEDICAID_LEADS}
              roles={[UserRole.ADMIN]}
              exact
            >
              <MedicaidLeads />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PARTNER_MEDICAID_LEAD_PREVIEW}
              roles={[UserRole.ADMIN]}
              exact
            >
              <MedicaidLeadPreview />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PARTNER_MEDICAID_ANALYSIS}
              roles={[UserRole.ADMIN]}
              exact
            >
              <MedicaidLeadContextProvider>
                <MedicaidAnalysisContainer />
              </MedicaidLeadContextProvider>
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.PARTNER_MEDICAID_LEAD}
              roles={[UserRole.ADMIN]}
            >
              <MedicaidLeadContextProvider>
                <MedicaidLeadContainer />
              </MedicaidLeadContextProvider>
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.DRAFTING}
              roles={[UserRole.ADMIN]}
            >
              <DraftingScreen onStateChange={handleChangeState} />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.ADMIN_FINANCIAL_PROFILE}
              roles={[UserRole.GLOBAL_ADMIN]}
            >
              <PartnerFinancialProfile />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.ADMIN_FINANCE}
              roles={[UserRole.GLOBAL_ADMIN]}
              exact
            >
              <FinanceScreen />
            </ProtectedRoute>

            <ProtectedRoute
              path={ROUTE_TEMPLATE.ADMIN_MEDICAID_VARIABLES}
              roles={[UserRole.GLOBAL_ADMIN]}
              exact
            >
              <MedicaidVariablesScreen />
            </ProtectedRoute>

            <Route path={ROUTE_TEMPLATE.PARTNER_ROOT} exact>
              {!isSessionRehydrated ? (
                <LoadingOverlay />
              ) : globalAdmin ? (
                <Redirect
                  to={{ pathname: getRoute(ROUTE_TEMPLATE.ADMIN_PARTNERS) }}
                />
              ) : admin ? (
                <Redirect
                  to={{ pathname: getRoute(ROUTE_TEMPLATE.WORKFLOW) }}
                />
              ) : (
                <LandingScreen />
              )}
            </Route>

            <Route path={ROUTE_TEMPLATE.ROOT} exact>
              {globalAdmin ? (
                <Redirect
                  to={{ pathname: getRoute(ROUTE_TEMPLATE.ADMIN_PARTNERS) }}
                />
              ) : (
                <LandingScreen />
              )}
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        )}
      </AppEnvelope>
    </ConnectedRouter>
  );
};

export default Sentry.withProfiler(Navigator);
