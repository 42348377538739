import { mapValues } from "lodash";
import Partner from "modules/parties/types/Partner";
import { AnswerValueType } from "../types/Answer";
import DefinitionWithProductsPlansAndAddons from "../types/DefinitionWithProductsPlansAndAddons";
import Questionnaire, {
  QuestionAnnotation,
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";
import Workflow from "../types/Workflow";

const getPlanAdvisorQuestionnaires = (
  workflow: Workflow,
  definition: DefinitionWithProductsPlansAndAddons,
) => {
  const planDefinition = definition.data?.availablePlans.find(
    (planDefinition) => planDefinition.id === workflow.plan!.id,
  );

  return planDefinition?.advisorQuestionnaires || [];
};

const getProductAdvisorQuestionnaires = (
  workflow: Workflow,
  definition: DefinitionWithProductsPlansAndAddons,
) => {
  return (workflow.products || [])
    .map(({ id }) => {
      const productDefinition = definition.data?.availableProducts.find(
        (p) => p.id === id,
      );
      return productDefinition?.advisorQuestionnaires;
    })
    .flat()
    .filter(Boolean) as Questionnaire[];
};

const collectAdvisorQuestionnaires = (
  workflow: Workflow,
  workflowDefinitions: Record<string, DefinitionWithProductsPlansAndAddons>,
  partner?: Partner,
): Questionnaire[] => {
  const onbaordingQuestionnaires = partner?.onboardingQuestionnaire
    ? [partner?.onboardingQuestionnaire]
    : [];

  const definition = workflowDefinitions[workflow.definitionId];
  if (!definition) return onbaordingQuestionnaires;

  const advisorQuestionnaires = workflow.plan
    ? getPlanAdvisorQuestionnaires(workflow, definition)
    : getProductAdvisorQuestionnaires(workflow, definition);

  return [...onbaordingQuestionnaires, ...advisorQuestionnaires];
};

export const getAnnotatedAdvisorQuestions = (
  workflow: Workflow,
  workflowDefinitions: Record<string, DefinitionWithProductsPlansAndAddons>,
  partner?: Partner,
): Record<QuestionAnnotation, QuestionsMapByQuestionnaire> => {
  const advisorQuestionnaires = collectAdvisorQuestionnaires(
    workflow,
    workflowDefinitions,
    partner,
  );

  return advisorQuestionnaires.reduce(
    (result, { id: questionnaireId, questions }) => {
      const annotatedQuestions = Object.values(questions).filter((q) =>
        Boolean(q.data.annotations?.length),
      );

      annotatedQuestions.forEach((question) => {
        (question.data.annotations || []).forEach((annotation) => {
          const questionsMap = Object.assign(
            { [question.id]: question },
            (result[annotation] && result[annotation][questionnaireId]) || {},
          );

          result[annotation] = {
            ...(result[annotation] || {}),
            [questionnaireId]: questionsMap,
          };
        });
      });

      return result;
    },
    {} as Record<QuestionAnnotation, QuestionsMapByQuestionnaire>,
  );
};

export const getAnnotatedAdvisorAnswers = (
  workflow: Workflow,
  workflowDefinitions: Record<string, DefinitionWithProductsPlansAndAddons>,
  partner?: Partner,
): Record<QuestionAnnotation, AnswerValueType> => {
  const allAnswers = workflow.answers || {};
  const annotatedQuestions = getAnnotatedAdvisorQuestions(
    workflow,
    workflowDefinitions,
    partner,
  );

  return mapValues(annotatedQuestions, (mapByQuestionnaire) => {
    for (const [questionnaireId, questions] of Object.entries(
      mapByQuestionnaire,
    )) {
      const answeredQuestion = Object.values(questions).find(
        (question) =>
          allAnswers[questionnaireId] &&
          allAnswers[questionnaireId][question.id] !== undefined,
      );
      if (answeredQuestion)
        return allAnswers[questionnaireId][answeredQuestion.id];
    }
    return undefined;
  });
};
