import { groupBy } from "lodash";
import {
  ProductDefinitionSelector,
  Step,
} from "modules/api/types/WorkflowPayload";
import { isRelatedAs } from "modules/parties/helpers/getRelationshipToClient";
import Client from "modules/parties/types/Client";
import Party, {
  ClientParties,
  RelationshipType,
} from "modules/parties/types/Party";
import Addon, { AddonID } from "modules/product/types/Addon";
import Product, { ProductID } from "modules/product/types/Product";
import Workflow from "../types/Workflow";
import { getPartiesWithChildren } from "./parseWorkflow";

export const getWorkflowRelatedParties = (
  workflow: Workflow,
  client: Client | undefined,
): ClientParties => {
  const { children, otherRelatedParties } = groupBy(
    Object.values(getPartiesWithChildren(workflow?.contacts?.parties || {})),
    (party) =>
      isRelatedAs(party, client, RelationshipType.CHILD)
        ? "children"
        : "otherRelatedParties",
  ) as { children?: Party[]; otherRelatedParties?: Party[] };
  return {
    parties: otherRelatedParties || [],
    children: children || [],
  };
};

export const getSpouseRelatedParties = (workflow: Workflow): ClientParties => ({
  parties: Object.values(workflow.availableParties?.otherParties || {}),
  children: Object.values(workflow.availableParties?.children || {}),
});

export const getWorkflowProductDefinitionSelectorsIDs = (
  workflow: Workflow,
): ProductDefinitionSelector[] => workflow.state.products;

export const getWorkflowProducts = (workflow: Workflow): Product[] => {
  const productsMap = (workflow.products || []).reduce(
    (acc, p) => ({ ...acc, [p.id]: p }),
    {} as Record<ProductID, Product>,
  );
  return getWorkflowProductDefinitionSelectorsIDs(workflow)
    .map(({ productId }) => productsMap[productId])
    .filter(Boolean);
};

export const getAvailableAddonIds = (workflow: Workflow): AddonID[] =>
  getWorkflowProducts(workflow).flatMap(({ addons = [] }) => addons);

export const getPreselectedAddons = (workflow: Workflow): Addon[] =>
  (workflow.state.enabledAddons || [])
    .map((addonId) => workflow.addons && workflow.addons[addonId])
    .filter(Boolean) as Addon[];

export const isUnderReview = (workflow: Workflow): boolean =>
  workflow.state.steps.current.step === Step.ReviewInProgress;
