import Input from "components/Forms/Inputs/Input";
import { InputType } from "components/Forms/Inputs/Input/types";
import React from "react";
import { useId } from "react-id-generator";
import QuestionWrapper, { QuestionSizes } from "../QuestionWrapper";
import { QuestionAssetSplitProps } from "./types";
import styles from "./styles.module.scss";
import interpolate from "utils/interpolate";

const QuestionAssetSplit = ({
  label,
  name,
  disabled,
  help,
  isMarried,
  mainOwnedLabel,
  jointlyOwnedLabel,
  spouseOwnedLabel,
  client,
  spouse,
}: QuestionAssetSplitProps) => {
  const [questionId1, questionId2, questionId3] = useId(2, "question-");

  const interpolatedMainLabel = client
    ? interpolate(mainOwnedLabel, client)
    : mainOwnedLabel;

  const interpolatedJointLabel =
    isMarried && client && spouse
      ? interpolate(jointlyOwnedLabel, client, spouse)
      : jointlyOwnedLabel;

  const interpolatedSpouseOwnedLabel =
    isMarried && client && spouse
      ? interpolate(spouseOwnedLabel, client, spouse)
      : spouseOwnedLabel;
  return (
    <QuestionWrapper
      disabled={disabled}
      help={help}
      label={label}
      id={name}
      name={name}
      size={QuestionSizes.FULLWIDTH}
    >
      <div className={styles["asset-split-inputs"]}>
        <div className={styles["main-or-jointly-owned"]}>
          <p className={styles["asset-value-label"]}>{interpolatedMainLabel}</p>
          <Input
            disabled={disabled}
            id={questionId1}
            name={`${name}.mainOwned`}
            min={0}
            type={InputType.CURRENCY}
          />
        </div>
        {isMarried && (
          <>
            <div className={styles["jointly-owned"]}>
              <p className={styles["asset-value-label"]}>
                {interpolatedJointLabel}
              </p>
              <Input
                disabled={disabled}
                id={questionId2}
                name={`${name}.jointlyOwned`}
                min={0}
                type={InputType.CURRENCY}
              />
            </div>
            <div className={styles["spouse-owned"]}>
              <p className={styles["asset-value-label"]}>
                {interpolatedSpouseOwnedLabel}
              </p>
              <Input
                disabled={disabled}
                id={questionId3}
                name={`${name}.spouseOwned`}
                min={0}
                type={InputType.CURRENCY}
              />
            </div>
          </>
        )}
      </div>
    </QuestionWrapper>
  );
};

export default QuestionAssetSplit;
