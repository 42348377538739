import { difference, groupBy, keyBy, mapValues } from "lodash";
import Workflow, { ImportableParties } from "../types/Workflow";
import WorkflowPayload, {
  WorkflowPayloadState,
} from "../../api/types/WorkflowPayload";
import Party, { PartyID, RelationshipType } from "modules/parties/types/Party";
import Questionnaire, {
  Question,
  QuestionID,
  QuestionnaireID,
  QuestionType,
} from "../types/Questionnaire";
import WorkflowDetailsPayload, {
  PartyWithRelationships,
} from "modules/api/types/WorkflowDetailsPayload";
import Answer, {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "../types/Answer";
import WorkflowAdminPayload from "modules/api/types/WorkflowAdminPayload";
import { parseStoredValue } from "./validation";
import {
  basicQuestions as clientBasicQuestions,
  CLIENT_FORM_ID,
  clientContactDetailsPageDefinition,
  clientDetailsPageDefinition,
  contactQuestions as clientContactQuestions,
} from "modules/parties/helpers/getClientQuestionnaire/constants";
import {
  SPOUSE_FORM_ID,
  spouseBasicInfoQuestionDefinitions,
  spouseContactInfoQuestionDefinitions,
  spouseDetailsPage,
} from "modules/parties/helpers/getSpouseQuestionnaire";
import assignChildrenToParents from "modules/parties/helpers/assignChildrenToParents";
import {
  parseQuestionnairesFromPayload,
  pickQuestionnaires,
} from "./parseQuestionnaire";

export const parseAnswerValues = (
  questionnairesMap: Record<QuestionnaireID, Questionnaire>,
  answersData?: Record<QuestionnaireID, Record<QuestionID, Answer>>,
): AnswersByQuestionnaireType =>
  Object.entries(answersData || {}).reduce(
    (result, [questionnaireId, questionnaireAnswers]) => {
      result[questionnaireId] = Object.entries(
        questionnaireAnswers || {},
      ).reduce((answers, [questionId, answer]) => {
        const question: Question =
          questionnairesMap[questionnaireId] &&
          questionnairesMap[questionnaireId].questions[questionId];
        answers[questionId] = parseStoredValue(
          question?.data?.definition.type,
          answer.value,
        );
        return answers;
      }, {} as AnswersMap);
      return result;
    },
    {} as AnswersByQuestionnaireType,
  );

export const getPartiesWithChildren = (
  partiesMap: Record<PartyID, Party>,
): Record<PartyID, Party> =>
  Object.values(partiesMap).reduce(
    (acc, party) => {
      const parent = party.parentId && partiesMap[party.parentId];
      if (parent) {
        acc[party.parentId!] = {
          ...(acc[party.parentId!] || {}),
          children: [...(acc[party.parentId!]?.children || []), party],
        };
      } else {
        acc[party.partyId] = {
          ...(acc[party.partyId] || {}),
          ...party,
        };
      }
      return acc;
    },
    {} as Record<PartyID, Party>,
  );

export const parseRelatedParties = (
  parties: Record<PartyID, PartyWithRelationships>,
): Record<PartyID, Party> =>
  mapValues(parties, ({ party, relationships }) => ({
    ...party,
    relationships,
  }));

const parseWorkflowState = (
  payloadState: WorkflowPayloadState,
): WorkflowPayloadState => {
  const { progress } = payloadState;
  if (!progress) return payloadState;

  const { mainClient, spouseClient, questionnairesProgresses, summary } =
    progress;

  // Client basic info
  const clientBasicRequiredCnt = clientBasicQuestions.filter(
    (q) => q.data.required && !q.data.condition,
  ).length;
  const clientBasicDefaultCnt = clientBasicQuestions.filter(
    (q) =>
      q.data.required && !q.data.condition && q.data.defaultValue !== undefined,
  ).length;
  const clientBasicFilledCnt = mainClient?.credentials
    ? clientBasicRequiredCnt
    : clientBasicDefaultCnt;

  // Client contact info
  const clientContactRequiredCnt = clientContactQuestions.filter(
    (q) => q.data.required && !q.data.condition,
  ).length;
  const clientContactDefaultCnt = clientContactQuestions.filter(
    (q) =>
      q.data.required && !q.data.condition && q.data.defaultValue !== undefined,
  ).length;
  const clientContactFilledCnt =
    (mainClient?.address ? 1 : 0) +
    (mainClient?.contact ? clientContactRequiredCnt : clientContactDefaultCnt) -
    1;

  // Client total
  const clientTotalCnt = clientBasicRequiredCnt + clientContactRequiredCnt;
  const clientTotalFilledCnt = clientBasicFilledCnt + clientContactFilledCnt;

  // Spouse basic info
  const spouseBasicRequiredCnt = spouseBasicInfoQuestionDefinitions.filter(
    (q) => q.data.required && !q.data.condition,
  ).length;
  const spouseBasicDefaultCnt = spouseBasicInfoQuestionDefinitions.filter(
    (q) =>
      q.data.required && !q.data.condition && q.data.defaultValue !== undefined,
  ).length;
  const spouseBasicFilledCnt = spouseClient?.credentials
    ? spouseBasicRequiredCnt
    : spouseBasicDefaultCnt;

  // Spouse contact info
  const spouseContactRequiredCnt = spouseContactInfoQuestionDefinitions.filter(
    (q) => q.data.required && !q.data.condition,
  ).length;
  const spouseContactDefaultCnt = spouseContactInfoQuestionDefinitions.filter(
    (q) =>
      q.data.required && !q.data.condition && q.data.defaultValue !== undefined,
  ).length;
  const spouseContactFilledCnt =
    (spouseClient?.address ? 1 : 0) +
    (spouseClient?.contact
      ? spouseContactRequiredCnt
      : spouseContactDefaultCnt) -
    1;

  // Spouse total
  const spouseTotalCnt = spouseBasicRequiredCnt + spouseContactRequiredCnt;
  const spouseTotalFilledCnt = spouseBasicFilledCnt + spouseContactFilledCnt;

  // Static sections - totals
  const totalStaticQuestionsCount =
    clientTotalCnt + (spouseClient === undefined ? 0 : spouseTotalCnt);
  const totalFilledStaticQuestionsCount =
    clientTotalFilledCnt +
    (spouseClient === undefined ? 0 : spouseTotalFilledCnt);

  const includeChildrenSection = Boolean(payloadState.features?.children);

  return payloadState.progress
    ? {
        ...payloadState,
        progress: {
          ...progress,
          summary: {
            completed: summary.completed + totalFilledStaticQuestionsCount,
            total: summary.total + totalStaticQuestionsCount,
          },
          questionnairesProgresses: [
            {
              questionnaireId: CLIENT_FORM_ID,
              summary: {
                completed: clientTotalFilledCnt,
                total: clientTotalCnt,
              },
              pageProgresses: [
                {
                  pageIndex: 0,
                  title: clientDetailsPageDefinition.caption!,
                  summary: {
                    completed: clientBasicFilledCnt,
                    total: clientBasicRequiredCnt,
                  },
                },
                {
                  pageIndex: 1,
                  title: clientContactDetailsPageDefinition.caption!,
                  summary: {
                    completed: clientContactFilledCnt,
                    total: clientContactRequiredCnt,
                  },
                },
              ],
            },
            ...(spouseClient === undefined
              ? []
              : [
                  {
                    questionnaireId: SPOUSE_FORM_ID,
                    summary: {
                      completed: spouseTotalFilledCnt,
                      total: spouseTotalCnt,
                    },
                    pageProgresses: [
                      {
                        pageIndex: 0,
                        title: spouseDetailsPage.caption!,
                        summary: {
                          completed: spouseTotalFilledCnt,
                          total: spouseTotalCnt,
                        },
                      },
                    ],
                  },
                ]),
            ...(includeChildrenSection
              ? [
                  {
                    questionnaireId: "children",
                    summary: {
                      completed: 0,
                      total: 0,
                    },
                    pageProgresses: [
                      {
                        pageIndex: 0,
                        title: "Children",
                        summary: {
                          completed: 0,
                          total: 0,
                        },
                      },
                    ],
                  },
                ]
              : []),
            ...questionnairesProgresses,
            {
              questionnaireId: "",
              summary: {
                completed: 0,
                total: 0,
              },
              pageProgresses: [
                {
                  pageIndex: 0,
                  title: "Summary",
                  summary: {
                    completed: 0,
                    total: 0,
                  },
                },
              ],
            },
          ],
        },
      }
    : payloadState;
};

export const parseWorkflow = (data: WorkflowPayload): Workflow => ({
  id: data.id,
  definitionId: data.definitionId,
  partnerId: data.partnerId,
  state: parseWorkflowState(data.state),
  userId: data.userId,
  isOverview: true,
  created: data.created,
  updated: data.updated,
  archived: data.archived,
  creator: data.creator,
});

export const parseAdminWorkflow = (data: WorkflowAdminPayload): Workflow => ({
  id: data.workflowId,
  definitionId: data.definitionId,
  partnerId: data.partnerId,
  state: parseWorkflowState(data.state),
  userId: data.userId,
  isOverview: true,
  created: data.created,
  updated: data.updated,
  userName: data.userName,
  archived: data.archived,
  creator: data.creator,
  creatorName: data.creatorName,
  updaterName: data.updaterName,
});

const parseImportableParties = (
  availableParties: Record<PartyID, PartyWithRelationships>,
  coupledWorkflowId?: string,
): ImportableParties => {
  const importableParties = assignChildrenToParents(
    Object.values(parseRelatedParties(availableParties)),
  );
  const groupedParties = groupBy(importableParties, (party) =>
    coupledWorkflowId &&
    party.relationships &&
    party.relationships.find(
      (rel) =>
        rel.workflowId === coupledWorkflowId &&
        (rel.relationship.type === RelationshipType.CHILD ||
          rel.relationship.type === RelationshipType.GRANDCHILD),
    )
      ? "children"
      : "otherParties",
  );

  return mapValues(groupedParties, (group) => keyBy(group, "partyId"));
};

const getSubquestionnairesIDs = (questionnaires: Questionnaire[]): string[] =>
  questionnaires
    .map(({ questions }) =>
      Object.values(questions).map((q) =>
        q.data.definition.type === QuestionType.SUBQUESTIONNAIRE
          ? q.data.definition.questionnaireId
          : undefined,
      ),
    )
    .flat()
    .filter(Boolean) as string[];

export const parseWorkflowDetails = (
  data: WorkflowDetailsPayload,
): Workflow => {
  const {
    answers,
    addons,
    products,
    clients,
    workflow,
    availableParties,
    plan,
    documents,
    user,
  } = data;
  const orderedWorkflowProducts = workflow.state.products.map(
    (product) => products[product.productId],
  );
  const parsedWorkflowQuestionnaires = parseQuestionnairesFromPayload(
    data.questionnaires,
  );

  const productQuestionnairesIDs = orderedWorkflowProducts
    .map((product) => product.questionnaires)
    .flat();
  const productQuestionnaires: Questionnaire[] = pickQuestionnaires(
    parsedWorkflowQuestionnaires,
    productQuestionnairesIDs,
  ).map((questionnaire) => ({
    ...questionnaire,
    productQuestionnaire: true,
  }));

  const subquestionnairesIDs = difference(
    getSubquestionnairesIDs(productQuestionnaires),
    productQuestionnairesIDs,
  );
  const subquestionnaires = pickQuestionnaires(
    parsedWorkflowQuestionnaires,
    subquestionnairesIDs,
  );

  const allQuestionnaires = [...productQuestionnaires, ...subquestionnaires];

  return {
    id: workflow.id,
    partnerId: workflow.partnerId,
    definitionId: workflow.definitionId,
    addons,
    availableParties: parseImportableParties(
      availableParties,
      workflow.state.coupledWorkflowId,
    ),
    answers: parseAnswerValues(keyBy(allQuestionnaires, "id"), answers),
    contacts: {
      clients,
      parties: parseRelatedParties(data.parties),
    },
    state: parseWorkflowState(workflow.state),
    products: orderedWorkflowProducts,
    plan,
    questionnaires: allQuestionnaires,
    documents,
    userId: workflow.userId,
    created: workflow.created,
    updated: workflow.updated,
    archived: workflow.archived,
    creator: workflow.creator,
    user: user,
  };
};
