import { Dispatch, Middleware, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "redux/reducer";
import firebase from "firebase/compat/app";
import {
  handleLoginResult,
  loginFailed,
  logOut,
  loginRequired,
  userProfileLoaded,
  stateNotSelected,
  stateChanged,
  stateLoaded,
  changeState,
  authenticationFailed,
  sessionRehydrated,
  loadUserProfile,
  signupRequired,
} from "./actions";
import authProvider from "modules/auth/authProvider";
import { appLoaded } from "../navigation/actions";
import {
  profileUpdated,
  registrationCompleted,
  saveUserState,
} from "modules/userProfile/actions";
import Notification from "components/Notification";
import { LOCATION_CHANGE } from "connected-react-router";
import { PUBLIC_PAGES, ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  getFirstSubpath,
  go,
  isPath,
} from "modules/navigation/helpers/navigator";
import LoginCallbackMeta, {
  AuthenticationTrigger,
} from "./loginCallback/LoginCallbackMeta";
import {
  createNewWorkflowWithPlan,
  createNewWorkflowWithProduct,
  newWorkflowCreated,
} from "modules/workflow/actions";
import { showStateEntryModal } from "./components/StateEntryModal";
import persistentStore from "modules/localStore";
import Workflow from "modules/workflow/types/Workflow";
import ApiError from "modules/api/types/ApiError";
import parseParams from "utils/parseUrlParams";
import { STATES_MAP } from "resources/options/states";

let unsubscribeObserver: firebase.Unsubscribe;

const LOCAL_STORE_STATE = "state";

const loadStoredState = async (dispatch: Dispatch) => {
  const state = persistentStore.get(LOCAL_STORE_STATE);
  dispatch(stateLoaded(state || null));
};

const checkWorkflowCreation = (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { router }: RootState = getState();
  const loginCallbackMeta: LoginCallbackMeta | undefined = (
    router.location.state || ({} as any)
  ).meta;

  if (loginCallbackMeta) {
    switch (loginCallbackMeta.action) {
      case AuthenticationTrigger.START_WORKFLOW_PLAN: {
        const { definitionId, plan, advisorAnswers, customAddons, state } =
          loginCallbackMeta;
        setTimeout(() => {
          const { user }: RootState = getState();
          createNewWorkflowWithPlan(
            user,
            definitionId,
            plan,
            state,
            advisorAnswers,
            customAddons,
            user?.idToken,
          )(dispatch);
        }, 0);
        break;
      }

      case AuthenticationTrigger.START_WORKFLOW_PRODUCT: {
        const { definitionId, product, advisorAnswers, customAddons, state } =
          loginCallbackMeta;
        setTimeout(() => {
          const { user }: RootState = getState();
          createNewWorkflowWithProduct(
            user,
            definitionId,
            product,
            state,
            advisorAnswers,
            customAddons,
            user?.idToken,
          )(dispatch);
        }, 0);
        break;
      }
    }
  }
};

const createAuthMiddleware: () => Middleware =
  () =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case appLoaded.type:
        if (unsubscribeObserver) {
          unsubscribeObserver();
        }
        unsubscribeObserver = authProvider.onAuthStateChanged(
          (loginResult: firebase.User | null) => {
            handleLoginResult(loginResult)(dispatch);
          },
        );

        loadStoredState(dispatch);
        break;

      case authenticationFailed.type:
      case loginFailed.type: {
        const error: ApiError | any = action.payload;
        const message =
          error?.response?.status === 403
            ? "You were automatically logged out due to inactivity. Please log back in."
            : error?.userMessage || "Login failed";
        logOut(message)(dispatch);
        break;
      }

      case profileUpdated.type: {
        Notification.success("Changes saved");
        break;
      }

      case loginRequired.type: {
        go(dispatch, ROUTE_TEMPLATE.LOGIN, {
          from: window.location.pathname,
          meta: action.payload,
        });
        break;
      }

      case signupRequired.type: {
        go(dispatch, ROUTE_TEMPLATE.SIGNUP, {
          from: window.location.pathname,
          meta: action.payload,
        });
        break;
      }

      case userProfileLoaded.type: {
        const { state: persistentStorageState }: RootState = getState();
        const apiState = action.payload.user?.state;

        if (persistentStorageState && persistentStorageState !== apiState) {
          setTimeout(() => dispatch(stateChanged(persistentStorageState)), 0);
        }

        checkWorkflowCreation(dispatch, getState);
        break;
      }

      case registrationCompleted.type: {
        checkWorkflowCreation(dispatch, getState);
        break;
      }

      // Initial load
      case stateLoaded.type:
      case sessionRehydrated.type: {
        setTimeout(() => {
          const {
            state,
            stateRehydrated,
            isSessionRehydrated,
            user,
          }: RootState = getState();
          if (
            stateRehydrated &&
            isSessionRehydrated &&
            !PUBLIC_PAGES.includes(getFirstSubpath()) &&
            state === null
          ) {
            dispatch(
              user?.state ? stateChanged(user.state) : stateNotSelected(),
            );
          }
        }, 0);
        break;
      }

      // Update user & local store
      case stateChanged.type: {
        const { user }: RootState = getState();
        persistentStore.set(LOCAL_STORE_STATE, action.payload);

        if (user && user.state && action.payload !== user.state) {
          await saveUserState(user, action.payload)(dispatch);
        }
        break;
      }

      case LOCATION_CHANGE: {
        const { isFirstRendering } = action.payload;

        setTimeout(() => {
          // Wait for the reload & store updates (e.g. after store reset)
          const { router, user, state }: RootState = getState();
          const { jurisdiction } = parseParams(router.location.search);

          if (
            jurisdiction !== undefined &&
            state === null &&
            STATES_MAP[jurisdiction] !== undefined
          ) {
            dispatch(stateChanged(jurisdiction));
          } else if (
            !isFirstRendering &&
            !user &&
            !state &&
            !PUBLIC_PAGES.includes(getFirstSubpath())
          ) {
            dispatch(stateNotSelected());
          }
        }, 0);
        break;
      }

      case stateNotSelected.type: {
        const { router, state }: RootState = getState();

        if (
          isPath(ROUTE_TEMPLATE.PROFILE) ||
          (!isPath(ROUTE_TEMPLATE.LOGIN, router.location) &&
            !isPath(ROUTE_TEMPLATE.SIGNUP, router.location) &&
            !isPath(ROUTE_TEMPLATE.SEARCH, router.location) &&
            !isPath(ROUTE_TEMPLATE.ROOT, router.location))
        ) {
          showStateEntryModal(
            (state) => dispatch(stateChanged(state)),
            state || undefined,
          );
        }

        break;
      }

      case changeState.type: {
        const { state }: RootState = getState();
        showStateEntryModal(
          (state) => dispatch(stateChanged(state)),
          state || undefined,
        );
        break;
      }

      // When a user has some workflows under a law firm and creates their first workflow under a second law firm,
      // the list of assigned law firms in the User entity is not updated - it contains the first law firm only.
      // As long as there's just one law firm assigned, the user is not provided the option to select a partner.
      // A workaround is to reload the page.
      // This MW enforces profile update when a new WF is created and the partner not yet assigned.
      case newWorkflowCreated.type: {
        const { user }: RootState = getState();
        const partnerId = (action as PayloadAction<Workflow>).payload.partnerId;
        if (
          !Object.keys(user?.activePartners || {}).find(
            (id) => id === partnerId,
          )
        ) {
          loadUserProfile()(dispatch);
        }
        break;
      }
    }
    next(action);
  };

export default createAuthMiddleware;
