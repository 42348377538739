import * as React from "react";
import { useId } from "react-id-generator";
import { CardNumberElement } from "@stripe/react-stripe-js";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

import styles from "./styles.module.scss";

type QuestionCardNumberProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  maxLength?: number;
  maxRows?: number;
  name: string;
  placeholder?: string;
  resize?: boolean;
  rows?: number;
  size?: QuestionSizes;
  tabIndex?: number;
};

const QuestionCardNumber: React.FunctionComponent<QuestionCardNumberProps> = (
  props,
) => {
  const [questionId] = useId(1, "question-");
  return (
    <QuestionWrapper
      className="question--card-number"
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={props.size ? props.size : QuestionSizes.MEDIUM}
    >
      <CardNumberElement
        className={styles["input-stripe"]}
        id={questionId}
        options={{
          style: {
            base: {
              fontSize: "18px",
              lineHeight: "50px",
              color: "#090927",
              fontWeight: "400",
              fontSmoothing: "antialiased",
              fontFamily: "'F37Sonic Light', sans-serif",
              "::placeholder": {
                color: "#c6c6c6",
              },
              "::-ms-clear": {
                display: "none",
              },
            },
            invalid: {
              "::placeholder": {
                color: "rgba(235, 87, 87, 0.5)",
              },
              color: "#eb5757",
              ":hover": {
                color: "#bf3232",
              },
            },
          },
        }}
      />
    </QuestionWrapper>
  );
};

export default QuestionCardNumber;
