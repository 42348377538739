import defaultTheme from "../defaultTheme";
import Theme from "../types/Theme";

export default (themeValues: Record<string, string>): Theme => {
  const updatedTheme: Theme = { ...defaultTheme };
  Object.entries(themeValues).forEach(([key, value]) => {
    if (defaultTheme[key]) {
      updatedTheme[key] = { ...defaultTheme[key], value };
    }
  });
  return updatedTheme;
};
