import { createBrowserHistory } from "history";
import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import * as analyticsReducer from "../modules/analytics/reducer";
import * as themeReducers from "../modules/theme/reducer";
import * as authReducers from "../modules/auth/reducer";
import * as workflowReducers from "../modules/workflow/reducer";
import * as productReducers from "../modules/product/reducer";
import * as partnerReducers from "../modules/partner/reducer";
import * as adminReducers from "../modules/admin/reducer";
import * as paymentReducers from "../modules/payment/reducer";
import * as agreementReducers from "../modules/workflow/agreement/reducer";
import * as medicaidReducers from "../modules/medicaid/reducer";
import * as lawyerReviewReducers from "../modules/workflow/review/reducer";
import { connectRouter } from "connected-react-router";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import { userLoggedOut } from "modules/auth/actions";
import { destroyModals } from "components/Modal";

export const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  ...analyticsReducer,
  ...themeReducers,
  ...authReducers,
  ...workflowReducers,
  ...agreementReducers,
  ...productReducers,
  ...partnerReducers,
  ...adminReducers,
  ...paymentReducers,
  ...medicaidReducers,
  ...lawyerReviewReducers,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: typeof appReducer = (
  state: CombinedState<RootState> | undefined,
  action: AnyAction,
) => {
  // Drop state after logging-out
  if (action.type === userLoggedOut.type) {
    // Destroy after the state gets reset
    setTimeout(() => destroyModals(), 0);

    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
