import * as React from "react";
import { useState } from "react";
import classNames from "classnames/bind";

export type ButtonProps = {
  className?: string | string[];
  disabled?: boolean;
  icon?: React.ReactComponentElement<any>;
  id?: string;
  invalid?: boolean;
  label: string;
  selected?: boolean;
  styles: { readonly [key: string]: string };
  onClick?: Function;
  tabIndex?: number;
};

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const [hover, setHover] = useState(false);
  const cx = classNames.bind(props.styles);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    if (!props.disabled && props.onClick) {
      props.onClick();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!props.disabled && event.key === "Enter") {
      handleClick(event);
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    // commented out as a fix for GDR-1299
    // event.preventDefault();
  };

  let primaryClassName = Array.isArray(props.className)
    ? props.className[0]
    : props.className;

  let className = cx(props.className, {
    [primaryClassName + "--disabled"]: props.disabled,
    [primaryClassName + "--invalid"]: !props.disabled && props.invalid,
    [primaryClassName + "--selected"]: props.selected,
  });

  return (
    <div
      className={className}
      id={props.id}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      onMouseDown={handleMouseDown}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      role="button"
      tabIndex={props.disabled ? -1 : props.tabIndex ? props.tabIndex : 0}
    >
      {props.icon &&
        React.cloneElement(props.icon, {
          disabled: props.disabled,
          invalid: props.invalid,
          hover: hover,
          selected: props.selected,
        })}
      <span className={props.styles[primaryClassName + "__text"]}>
        {props.label}
      </span>
    </div>
  );
};

export default Button;
