import * as React from "react";
import moment from "moment";

import styles from "./styles.module.scss";

type ClientDashboardProductsItemHistoryItemProps = {
  timestamp: string;
  action: string;
};

const DATE_TIME_FORMAT = "YYYY/MM/DD hh:mm A";

const ClientDashboardProductsItemHistoryItem: React.FunctionComponent<
  ClientDashboardProductsItemHistoryItemProps
> = ({ timestamp, action }) => {
  return (
    <li className={styles["client-dashboard-products-item-history-item"]}>
      <div
        className={styles["client-dashboard-products-item-history-item__col"]}
      >
        <div
          className={
            styles["client-dashboard-products-item-history-item__date"]
          }
        >
          {moment(timestamp).format(DATE_TIME_FORMAT)}
        </div>
      </div>
      <div
        className={styles["client-dashboard-products-item-history-item__col"]}
      >
        <div
          className={
            styles["client-dashboard-products-item-history-item__action"]
          }
        >
          {action}
        </div>
      </div>
    </li>
  );
};

export default ClientDashboardProductsItemHistoryItem;
