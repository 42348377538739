import * as React from "react";
import { useField } from "formik";

import valueToFieldState from "./valueToFieldState";
import OtherOptionAdditionalInput from "./OtherOptionAdditionalInput";
import CheckboxGroup from "../CheckboxGroup";
import Dropdown from "../Dropdown";
import RadioGroup from "../RadioGroup";
import { QuestionSizes } from "components/Forms/Questions/QuestionWrapper";
import {
  OTHER_OPTION,
  SelectMultipleOrOtherState,
  SelectOneOrOtherState,
} from "./FieldState";
import fieldStateToValue from "./fieldStateToValue";
import getOptions from "./getOptions";
import hasOtherOptionSelected from "./hasOtherOptionSelected";

export enum SelectMode {
  DROPDOWN,
  RADIO,
  MULTIPLE,
}

type SelectProps = {
  allowOther?: boolean;
  disabled?: boolean;
  id?: string;
  mode: SelectMode;
  name: string;
  options?: Array<{ label: string; value: any }>;
  otherLabel?: string;
  placeholder?: string;
  tabIndex?: number;
  wrappingQuestionSize?: QuestionSizes;
};

const Select: React.FunctionComponent<SelectProps> = ({
  allowOther,
  disabled,
  id,
  name,
  placeholder,
  options = [],
  otherLabel,
  tabIndex,
  mode = SelectMode.DROPDOWN,
  wrappingQuestionSize,
}) => {
  const [field, meta, helpers] = useField(name);
  const [otherFieldToggled, toggleOther] = React.useState<boolean>(false);

  const dropdownOptions = getOptions(options, allowOther);
  const optionValues = dropdownOptions.map((o) => o.value);
  const fieldState = valueToFieldState(
    mode,
    otherFieldToggled,
    optionValues,
    meta.value,
    Boolean(allowOther),
  );

  // Verify assigned value against possible values
  React.useEffect(() => {
    if (mode === SelectMode.MULTIPLE) {
      if (meta.value) {
        const validValues = meta.value.filter((value: any) =>
          optionValues.includes(value),
        );
        if (!allowOther && validValues.length !== meta.value.length) {
          helpers.setValue(validValues);
        }
      }
    } else {
      if (meta.value && !optionValues.includes(meta.value) && !allowOther) {
        helpers.setValue("");
      }
    }
  }, [helpers, meta.value, optionValues, mode, fieldState, allowOther]);

  // Correct other field's state if needed
  React.useEffect(() => {
    const enterOtherValue = hasOtherOptionSelected(fieldState.value);
    if (enterOtherValue !== otherFieldToggled) toggleOther(enterOtherValue);
  }, [fieldState.value, otherFieldToggled]);

  // Manage Other field error
  React.useEffect(() => {
    const ERROR = "Please, specify";
    if (
      fieldState.value === OTHER_OPTION.value &&
      !fieldState.other &&
      !meta.error
    ) {
      helpers.setError(ERROR);
      return;
    }

    if (
      meta.error === ERROR &&
      (fieldState.other || fieldState.value !== OTHER_OPTION.value)
    ) {
      helpers.setError(undefined);
    }
  }, [
    fieldState.other,
    fieldState.value,
    helpers,
    meta.error,
    meta.touched,
    mode,
  ]);

  const handleOtherValueChange = (otherFieldValue: string) => {
    const nextValue = fieldStateToValue(
      { ...fieldState, other: otherFieldValue },
      mode,
    );
    helpers.setValue(nextValue);
  };

  const handleMultiValueChange = (selectedValues: string[]) => {
    const isOther = hasOtherOptionSelected(selectedValues);
    if (isOther !== otherFieldToggled) toggleOther(isOther);

    const nextValue = fieldStateToValue(
      {
        value: selectedValues,
        other: isOther ? fieldState.other || "" : undefined,
      },
      mode,
    );
    helpers.setValue(nextValue);
  };

  const handleSingleValueChange = (selectedValue: string | null) => {
    const isOther = hasOtherOptionSelected(selectedValue);
    if (isOther !== otherFieldToggled) toggleOther(isOther);

    const nextValue =
      selectedValue === null
        ? null
        : fieldStateToValue(
            { value: selectedValue, other: isOther ? "" : undefined },
            mode,
          );
    helpers.setValue(nextValue);
  };

  return (
    <>
      {mode === SelectMode.MULTIPLE ? (
        <CheckboxGroup
          id={id}
          onChange={handleMultiValueChange}
          options={dropdownOptions}
          selectedValues={
            (fieldState as SelectMultipleOrOtherState | undefined)?.value || []
          }
          disabled={disabled}
          tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
          isInvalid={Boolean(!disabled && meta.touched && meta.error)}
        />
      ) : mode === SelectMode.RADIO ? (
        <RadioGroup
          id={id}
          onChange={handleSingleValueChange}
          options={dropdownOptions}
          selectedValue={
            (fieldState as SelectOneOrOtherState | undefined)?.value
          }
          disabled={disabled}
          tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
          isInvalid={Boolean(!disabled && meta.touched && meta.error)}
        />
      ) : (
        <Dropdown
          {...field}
          onChange={handleSingleValueChange}
          value={(fieldState as SelectOneOrOtherState | undefined)?.value}
          disabled={disabled}
          id={id}
          tabIndex={disabled ? -1 : tabIndex ? tabIndex : 0}
          placeholder={placeholder}
          options={dropdownOptions}
          isValid={Boolean(meta.touched && !meta.error)}
          isInvalid={Boolean(meta.touched && meta.error)}
        />
      )}
      {otherFieldToggled && (
        <OtherOptionAdditionalInput
          isValid={Boolean(meta.touched && !meta.error && fieldState.other)}
          isInvalid={Boolean(meta.touched && (meta.error || !fieldState.other))}
          onValueChange={handleOtherValueChange}
          label={otherLabel}
          id={`${id}Other`}
          value={fieldState.other || ""}
          wrappingQuestionSize={wrappingQuestionSize}
        />
      )}
    </>
  );
};

export default Select;
