import { keyBy, pick } from "lodash";
import { AddressSearchResultsType } from "modules/address/actions";
import { processClientQuestions } from "modules/workflow/helpers/processQuestions";
import { getInitialValues } from "modules/workflow/helpers/validation";
import { AnswerValueType } from "modules/workflow/types/Answer";
import { ClientDetailsConstraintType } from "modules/workflow/types/Condition/ClientDetailsCondition";
import { WorkflowDetailsConstraintType } from "modules/workflow/types/Condition/WorkflowDetailsCondition";
import {
  QuestionnairePage,
  Question,
  QuestionType,
  TextFieldType,
  QuestionID,
  QuestionAnnotation,
} from "modules/workflow/types/Questionnaire";
import StaticSubquestionnaireDefinition from "modules/workflow/types/StaticSubquestionnaireDefinition";
import moment from "moment";
import Address from "../types/Address";
import Client from "../types/Client";
import Gender from "../types/Gender";
import { CLIENT_FORM_ID } from "./getClientQuestionnaire/constants";
import getValue from "./getValue";
import suffixes from "./suffixes";

export const SPOUSE_FORM_ID = "spouseForm";

export const spouseBasicInfoQuestionDefinitions: Question[] = [
  {
    id: "spouse_firstName",
    data: {
      descriptions: [
        {
          label: "First name",
        },
      ],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: "spouse_middleName",
    data: {
      descriptions: [
        {
          label: "Middle name (optional)",
        },
      ],
      definition: { type: QuestionType.TEXT },
      required: false,
    },
  },

  {
    id: "spouse_lastName",
    data: {
      descriptions: [
        {
          label: "Last name",
        },
      ],
      definition: { type: QuestionType.TEXT },
      required: true,
    },
  },

  {
    id: "spouse_suffix",
    data: {
      descriptions: [
        {
          label: "Suffix (Optional)",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: suffixes.map((value) => ({ value })),
        radio: true,
        limits: { max: 1 },
        allowOther: true,
        otherLabel: "Please enter your spouse's suffix",
      },
      required: false,
    },
  },

  {
    id: "spouse_alias",
    data: {
      descriptions: [
        {
          label: "Also known as (optional)",
          description:
            "If your spouse's current name or preferred name is not the legal name they were born with or if they have other names they have been identified by on assets they own (bank accounts, real estate, life insurance, etc.) please identify the other names (separated by a comma with an “and” prior to the last identified name, if more than one). You do not need to include maiden names, unless they still have assets in that name.",
        },
      ],
      definition: { type: QuestionType.TEXT },
      required: false,
    },
  },
  {
    id: "spouse_birthdate",
    data: {
      descriptions: [
        {
          label: "Date of birth",
        },
      ],
      definition: {
        type: QuestionType.DATE,
        limits: {
          max: new Date().toISOString(),
          min: moment().subtract(99, "years").toISOString(),
        },
      },
      required: true,
    },
  },

  {
    id: "spouse_gender",
    data: {
      descriptions: [
        {
          label: "What are your spouse's pronouns?",
          description:
            "This helps us fill in the correct pronouns throughout your legal documents.",
        },
      ],
      definition: {
        type: QuestionType.SELECT,
        values: [
          { display: "he/him", value: Gender.MALE },
          { display: "she/her", value: Gender.FEMALE },
          { display: "they/them", value: Gender.OTHER },
        ],
        radio: true,
      },
      required: true,
    },
  },
];

export const spouseContactInfoQuestionDefinitions: Question[] = [
  {
    id: "spouse_home_address_is_mailing",
    data: {
      descriptions: [
        {
          label:
            "Is your spouse's mailing address the same as their home address?",
        },
      ],
      definition: { type: QuestionType.FLAG },
      required: true,
      defaultValue: false,
    },
  },

  {
    id: "spouse_contact_cell",
    data: {
      descriptions: [
        {
          label: "Cell",
        },
      ],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
      required: true,
    },
  },

  {
    id: "spouse_contact_home",
    data: {
      descriptions: [
        {
          label: "Home (optional)",
        },
      ],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },

  {
    id: "spouse_contact_work",
    data: {
      descriptions: [
        {
          label: "Work (optional)",
        },
      ],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.TEL },
    },
  },

  {
    id: "spouse_contact_email",
    data: {
      descriptions: [
        {
          label: "Email address",
        },
      ],
      definition: { type: QuestionType.TEXT, subtype: TextFieldType.EMAIL },
      required: true,
    },
  },

  {
    id: "spouse_home_address",
    data: {
      descriptions: [
        {
          label: "Home address",
        },
      ],
      definition: { type: QuestionType.ADDRESS },
      required: true,
    },
  },

  {
    id: "spouse_mailing_address",
    data: {
      descriptions: [
        {
          label: "Mailing address",
        },
      ],
      definition: { type: QuestionType.ADDRESS },
      required: true,
      condition: {
        [SPOUSE_FORM_ID]: { spouse_home_address_is_mailing: false },
      },
    },
  },
];

const spouseQuestionDefinitions = [
  ...spouseBasicInfoQuestionDefinitions,
  ...spouseContactInfoQuestionDefinitions,
];

export const spouseDetailsPage: QuestionnairePage = {
  id: "spouse_details",
  questionnaireId: SPOUSE_FORM_ID,
  caption: "Your Spouse Information",
  description: "",
  isStatic: true,
  condition: {
    any: [
      { [CLIENT_FORM_ID]: { client_marital_status: true } },
      { client: { type: ClientDetailsConstraintType.IsMarried } },
      {
        workflow: {
          type: WorkflowDetailsConstraintType.IsCoupled,
          value: true,
        },
      },
    ],
  },
  sections: [
    {
      caption: "Your spouse's details",
      description:
        "Please use your spouse's legal name as it appears on their driver’s license or government-issued ID.",
      questions: [
        ["spouse_firstName", "spouse_middleName", "spouse_lastName"],
        "spouse_suffix",
        "spouse_alias",
        "spouse_birthdate",
        "spouse_gender",
      ],
    },
    {
      caption: "Address",
      questions: ["spouse_home_address", "spouse_home_address_is_mailing"],
    },
    {
      caption: "Mailing address",
      condition: {
        [SPOUSE_FORM_ID]: { spouse_home_address_is_mailing: false },
      },
      questions: ["spouse_mailing_address"],
    },
    {
      caption: "Contact details",
      questions: [
        ["spouse_contact_cell", "spouse_contact_home", "spouse_contact_work"],
        "spouse_contact_email",
      ],
    },
  ],
};

const minimalSpouseProperties = [
  "spouse_firstName",
  "spouse_lastName",
  "spouse_gender",
  "spouse_birthdate",
];

export default (
  onSave: (spouseId: string | undefined, spouseDetails: Partial<Client>) => any,
  onAddressLookup: (addressId: string) => Promise<Address | undefined>,
  onAddressSearch: (
    containerId?: string,
    searchTerm?: string,
  ) => Promise<AddressSearchResultsType[] | undefined>,
  availableAddresses: Address[],
  spouse?: Client,
  annotatedAdvisorAnswers?: Record<QuestionAnnotation, AnswerValueType>,
  readOnly?: boolean,
  supportedStates?: string[],
): StaticSubquestionnaireDefinition => {
  const processedQuestions = processClientQuestions(
    spouseQuestionDefinitions,
    onAddressLookup,
    onAddressSearch,
    availableAddresses,
    false,
    undefined,
    supportedStates,
  ).map((q) => {
    q.data.readOnly = readOnly;
    return q;
  });

  const spousePages: QuestionnairePage[] = [
    {
      ...spouseDetailsPage,
      onSave: (
        touchedValues: Record<QuestionID, any>,
        allValues: Record<QuestionID, any>,
        isDelta?: boolean,
      ) => {
        if (isDelta && Object.keys(touchedValues).length === 0) return;

        const spouseData: Partial<Client> = {
          firstName:
            touchedValues.spouse_firstName ||
            annotatedAdvisorAnswers?.["spouse.first_name"],
          lastName:
            touchedValues.spouse_lastName ||
            annotatedAdvisorAnswers?.["spouse.last_name"],
          middleName: touchedValues.spouse_middleName,
          suffix: touchedValues.spouse_suffix,
          alias: touchedValues.spouse_alias,
          gender: touchedValues.spouse_gender,
          dateOfBirth: touchedValues.spouse_birthdate,
          isMarried: true,
          address: touchedValues.spouse_home_address
            ? {
                id: "home",
                ...touchedValues.spouse_home_address.address, // TODO hotfix
              }
            : undefined,
          mailingAddress:
            touchedValues.spouse_home_address_is_mailing === false
              ? {
                  id: "mailing",
                  ...touchedValues.spouse_mailing_address.address, // TODO hotfix
                }
              : null,
          contact: {
            cellPhone: getValue(
              touchedValues.spouse_contact_cell,
              spouse?.contact?.cellPhone,
            ),
            email: getValue(
              touchedValues.spouse_contact_email,
              spouse?.contact?.email,
            ),
            homePhone: getValue(
              touchedValues.spouse_contact_home,
              spouse?.contact?.homePhone,
            ),
            workPhone: getValue(
              touchedValues.spouse_contact_work,
              spouse?.contact?.workPhone,
            ),
          },
        };

        return onSave(spouse?.clientId, spouseData);
      },

      onFilterValidationErrors: (
        allErrors,
        unsavedAndChangedQuestionsKeys,
        isDelta,
      ) => {
        if (
          isDelta &&
          !spouse?.clientId &&
          unsavedAndChangedQuestionsKeys.length > 0
        ) {
          return pick(allErrors, minimalSpouseProperties);
        }
        return {};
      },
    },
  ];

  const defaultValues = {
    spouse_firstName:
      spouse?.firstName || annotatedAdvisorAnswers?.["spouse.first_name"] || "",
    spouse_lastName:
      spouse?.lastName || annotatedAdvisorAnswers?.["spouse.last_name"] || "",
    spouse_middleName: spouse?.middleName || "",
    spouse_suffix: spouse?.suffix || "",
    spouse_gender: spouse?.gender || "",
    spouse_alias: spouse?.alias || "",

    spouse_birthdate: spouse?.dateOfBirth || "",

    spouse_home_address: spouse?.address,

    spouse_home_address_is_mailing: !Boolean(spouse?.mailingAddress?.line1),
    spouse_mailing_address: spouse?.mailingAddress,

    spouse_contact_cell: spouse?.contact?.cellPhone,
    spouse_contact_home: spouse?.contact?.homePhone,
    spouse_contact_work: spouse?.contact?.workPhone,
    spouse_contact_email: spouse?.contact?.email,
  };

  return {
    questionnairePages: spousePages,

    initialValues: {
      [SPOUSE_FORM_ID]: getInitialValues(
        spouseQuestionDefinitions,
        defaultValues,
      ),
    },

    questions: { [SPOUSE_FORM_ID]: keyBy(processedQuestions, "id") },
  };
};
