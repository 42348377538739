import {
  QuestionID,
  QuestionnaireID,
  QuestionnairePage,
  QuestionnaireSection,
  QuestionsMap,
  QuestionsMapByQuestionnaire,
} from "../types/Questionnaire";
import { isRequired } from "./validation";

export enum SectionState {
  PENDING = "pending",
  STARTED = "started",
  COMPLETE = "complete",
}

export type SectionProgress = {
  label: string;
  state: SectionState;
  pageId?: string;
  accessible?: boolean;
  subsections?: SectionProgress[];
};

const parsePage = (
  {
    caption: label,
    sections,
    getState,
    getProgress,
    questionnaireId,
    id: pageId,
  }: QuestionnairePage,
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
  answeredQuestionsIDsByQuestionnaire: Record<QuestionnaireID, QuestionID[]>,
  isVisited: boolean,
): SectionProgress | null => {
  if (getProgress) {
    const pageProgress = getProgress(isVisited);
    if (pageProgress === null) return null;
    pageProgress.pageId = pageProgress.pageId || pageId;
    return pageProgress;
  }

  const subsections = sections.map((section) =>
    parseSection(
      section,
      questionsByQuestionnaire[questionnaireId],
      answeredQuestionsIDsByQuestionnaire[questionnaireId],
    ),
  );
  const completeSubsectionsCnt = subsections.filter(
    (subsection) => subsection.state === SectionState.COMPLETE,
  ).length;
  const pendingSubsectionsCnt = subsections.filter(
    (subsection) => subsection.state === SectionState.PENDING,
  ).length;
  const progress = {
    pageId,
    label: label || "",
    subsections: subsections.length > 1 ? subsections : [],
  };

  if (getState) return { ...progress, state: getState() };

  if (subsections.length === 0) {
    return {
      ...progress,
      state: isVisited ? SectionState.COMPLETE : SectionState.PENDING,
    };
  }

  if (pendingSubsectionsCnt === subsections.length)
    return { ...progress, state: SectionState.PENDING };

  if (completeSubsectionsCnt === subsections.length)
    return {
      ...progress,
      state: isVisited === false ? SectionState.PENDING : SectionState.COMPLETE,
    };

  return {
    ...progress,
    state: isVisited === false ? SectionState.PENDING : SectionState.STARTED,
  };
};

const parseSection = (
  { questions, caption: label, getState }: QuestionnaireSection,
  questionDefinitions: QuestionsMap,
  answeredQuestionsIDs: string[] = [],
): SectionProgress => {
  const flattenedQuestions = (questions || [])
    .flat()
    .filter((questionId) =>
      isRequired(
        questionDefinitions[questionId]?.data.definition,
        questionDefinitions[questionId]?.data.required,
      ),
    );
  const answeredQuestionsCount = flattenedQuestions.filter((questionId) =>
    answeredQuestionsIDs.includes(questionId),
  ).length;

  let state;
  if (getState) {
    state = getState();
  } else if (answeredQuestionsCount === flattenedQuestions.length) {
    state = SectionState.COMPLETE;
  } else if (answeredQuestionsCount === 0) {
    state = SectionState.PENDING;
  } else {
    state = SectionState.STARTED;
  }
  return { label, state };
};

const isSectionAccessible = (previousSection?: SectionProgress): boolean =>
  Boolean(
    !previousSection ||
      (previousSection.accessible &&
        previousSection.state === SectionState.COMPLETE),
  );

const getLastVisitedPageIdx = (
  pages: QuestionnairePage[],
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
  answeredQuestionsIDsByQuestionnaire: Record<QuestionnaireID, QuestionID[]>,
): number => {
  const initialPageProgresses = pages.map((page) =>
    parsePage(
      page,
      questionsByQuestionnaire,
      answeredQuestionsIDsByQuestionnaire,
      true,
    ),
  );

  for (let idx = 0; idx < initialPageProgresses.length; idx++) {
    const progress = initialPageProgresses[idx];

    // If "STARTED" looked different to "PENDING", this could check for progress.state === PENDING instead
    if (progress && progress.state !== SectionState.COMPLETE) {
      return idx - 1;
    }
  }
  return initialPageProgresses.length - 1;
};

export default (
  pages: QuestionnairePage[],
  questionsByQuestionnaire: QuestionsMapByQuestionnaire,
  answeredQuestionsIDsByQuestionnaire: Record<QuestionnaireID, QuestionID[]>,
  allowIncompletePages?: boolean,
): SectionProgress[] => {
  const lastVisitedPageIdx = getLastVisitedPageIdx(
    pages,
    questionsByQuestionnaire,
    answeredQuestionsIDsByQuestionnaire,
  );

  return (
    pages.map((page, idx) =>
      parsePage(
        page,
        questionsByQuestionnaire,
        answeredQuestionsIDsByQuestionnaire,
        Boolean(allowIncompletePages) || idx === 0 || idx <= lastVisitedPageIdx,
      ),
    ) as SectionProgress[]
  ).reduce((allPages, page) => {
    const previousPage =
      allPages.length > 0 ? allPages[allPages.length - 1] : undefined;
    allPages.push({
      ...page,
      accessible:
        Boolean(allowIncompletePages) || isSectionAccessible(previousPage),
    });
    return allPages;
  }, [] as SectionProgress[]);
};
