import Address from "./Address";
import ContactInformation from "./ContactInformation";
import Gender from "./Gender";

export enum ClientType {
  MAIN = "main",
  SPOUSE = "spouse",
}

export enum ClientTypeUpperCase {
  MAIN = "Main",
  SPOUSE = "Spouse",
}

type Client = {
  address?: Address;
  alias?: string;
  clientId: string;
  contact?: ContactInformation;
  created: string;
  dateOfBirth: string;
  firstName: string;
  gender: Gender;
  lastName: string;
  isMarried?: boolean;
  clientAndSpouseUSCitizens?: boolean;
  mailingAddress?: Address | null;
  middleName?: string | null;
  suffix?: string;
  updated: string;
};

export default Client;

export const isClient = (person: any): person is Client =>
  person.hasOwnProperty("clientId");
