import { ApiResponse } from "../apiHelper";
import Payment, { PaymentOrderItem } from "../types/Payment";
import Overwrite from "./Overwrite";

export enum PaymentResponseStatus {
  Placed = "placed",
  Skipped = "skipped",
}

type InitPaymentResponseType = Overwrite<
  ApiResponse,
  {
    data:
      | {
          payment: Omit<Payment, "workflowId" | "secret">;
          secret: string;
          allOrderItems: PaymentOrderItem[];
          responseStatus: PaymentResponseStatus.Placed;
        }
      | {
          payment: {
            responseStatus: PaymentResponseStatus.Skipped;
          };
          workflowId: string;
          promoCode: string;
          responseStatus: PaymentResponseStatus.Skipped;
        };
  }
>;

export default InitPaymentResponseType;
