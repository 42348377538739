import { difference, sortBy } from "lodash";
import {
  getAdministeredPartnerIDs,
  getAllPartnerIDs,
  hasActiveRegistrations,
} from "modules/auth/helper";
import { go, redirect } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  PartnerID,
  PartnerRegistration,
  RegistrationState,
} from "modules/parties/types/Partner";
import {
  deletePartnerRegistration,
  partnerSelected,
} from "modules/partner/actions";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { IconType } from "components/Icons";
import { ModalType, showModalConfirm } from "components/Modal";
import logger from "logger";
import { NotificationType } from "components/Notification";

const PartnersScreen: React.FC = () => {
  const { user, isLoadingUserDetails } = useSelector(
    (state) => state,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const allPartners = user?.activePartners || {};
  const userPartners = getAllPartnerIDs(user);
  const adminIDs = getAdministeredPartnerIDs(user);
  const clientIDs = difference(Array.from(userPartners), adminIDs);
  const hasRegistrations = hasActiveRegistrations(user);

  const cx = classNames.bind(styles);

  useEffect(() => {
    if (hasRegistrations) return;

    if (!userPartners.size && !hasRegistrations) {
      redirect(dispatch, ROUTE_TEMPLATE.SEARCH);
      return;
    }

    if (userPartners.size === 1) {
      dispatch(partnerSelected(Array.from(userPartners)[0]));
      return;
    }
  }, [dispatch, hasRegistrations, isLoadingUserDetails, user, userPartners]);

  const handlePartnerSelected = useCallback(
    (partnerId: PartnerID) => {
      dispatch(partnerSelected(partnerId));
    },
    [dispatch],
  );

  const handleViewRegistration = useCallback(
    (registration: PartnerRegistration) => {
      go(
        dispatch,
        getRoute(ROUTE_TEMPLATE.PARTNER_REGISTRATION, {
          registrationId: registration.id,
        }),
      );
    },
    [dispatch],
  );

  const handleCancelRegistration = useCallback(
    (registration: PartnerRegistration) => {
      showModalConfirm({
        labelConfirm: "Proceed",
        onConfirm: async () => {
          try {
            await deletePartnerRegistration(registration.id)(dispatch);
            logger.notify(
              NotificationType.SUCCESS,
              `Your registration request for ${registration.request.displayName} was cancelled.`,
            );
          } catch (e) {
            logger.notify(
              NotificationType.ERROR,
              `Registration request couldn't be cancelled. Please, try again later.`,
            );
          }
        },
        title: `Are you sure you want to cancel your registration request for '${registration.request.displayName}'?`,
        text: "This action cannot be undone.",
        type: ModalType.WARNING,
      });
    },
    [dispatch],
  );

  const handleFindLawFirm = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.SEARCH),
    [dispatch],
  );

  const renderPartnerTile = (partnerId: string) => (
    <div
      key={partnerId}
      onClick={() => handlePartnerSelected(partnerId)}
      className={styles.partners__list__card}
    >
      {allPartners[partnerId].name}
    </div>
  );

  const renderRegistrationTile = (
    registration: PartnerRegistration,
    idx: number,
  ) => (
    <div
      key={`${idx}_${registration.partnerId}`}
      className={cx("registration")}
    >
      <div className={cx("registration__status")}>
        <div
          className={cx("registration-state", String(registration.status.type))}
        >
          {registration.status.type}
        </div>
      </div>
      <div className={cx("registration__label")}>
        {registration.request.displayName}
        {registration.partnerId ? ` (${registration.partnerId})` : ""}
      </div>
      <div className={cx("registration__actions")}>
        {registration.status.type === RegistrationState.REJECTED ? (
          <>
            <TabbedButton
              onClick={() => handleViewRegistration(registration)}
              style={TabbedButtonType.SECONDARY}
            >
              Edit
            </TabbedButton>
            <TabbedButton
              ariaLabel="Remove"
              icon={IconType.CROSS}
              mood={TabbedButtonMood.NEGATIVE}
              onClick={() => handleCancelRegistration(registration)}
              style={TabbedButtonType.SECONDARY}
            />
          </>
        ) : null}
        {registration.status.type === RegistrationState.PENDING ? (
          <>
            <TabbedButton
              onClick={() => handleViewRegistration(registration)}
              style={TabbedButtonType.SECONDARY}
            >
              View
            </TabbedButton>
            <TabbedButton
              ariaLabel="Remove"
              icon={IconType.CROSS}
              mood={TabbedButtonMood.NEGATIVE}
              onClick={() => handleCancelRegistration(registration)}
              style={TabbedButtonType.SECONDARY}
            />
          </>
        ) : null}
      </div>
    </div>
  );

  if (userPartners.size <= 1 && !hasRegistrations) return null;

  const registrations = sortBy(
    Object.values(user?.partnerRegistrations || {}),
    (reg) => (reg.status.type === RegistrationState.REJECTED ? 0 : 1),
  );

  return (
    <div className={cx("partners")}>
      {registrations.length > 0 ? (
        <>
          <h1>Pending Registrations</h1>
          <div className={cx("partners__registrations")}>
            {registrations.map(renderRegistrationTile)}
          </div>
        </>
      ) : null}
      {adminIDs.length ? (
        <>
          <h1>Administered Law Firms</h1>
          <div className={cx("partners__list")}>
            {adminIDs.map(renderPartnerTile)}
          </div>
        </>
      ) : null}
      <>
        <h1>My Law Firms</h1>
        <div className={cx("partners__list")}>
          {clientIDs.length ? (
            clientIDs.map(renderPartnerTile)
          ) : (
            <TabbedButton onClick={handleFindLawFirm}>
              Find the nearest law firm
            </TabbedButton>
          )}
        </div>
      </>
    </div>
  );
};

export default PartnersScreen;
