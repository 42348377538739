import React, { useCallback } from "react";
import Alert, { AlertType } from "components/Alert";
import PageHeader from "components/PageHeader";
import { go } from "modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  PartnerRegistration,
  PartnerRegistrationRequest,
  RegistrationState,
} from "modules/parties/types/Partner";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";
import BackLink from "components/BackLink";

import styles from "./styles.module.scss";
import {
  registerPartner,
  resubmitPartnerRegistration,
} from "modules/partner/actions";
import logger, { TYPE } from "logger";

import PartnerRegistrationForm from "modules/partner/settings/components/PartnerRegistrationForm";
import { showModalConfirm } from "components/Modal";

const getAlertMessage = (registration: PartnerRegistration) => {
  const {
    status: { type, message },
  } = registration;

  switch (type) {
    case RegistrationState.REJECTED:
      return `Registration request was rejected${
        message ? ": " + message : "."
      }`;

    case RegistrationState.PENDING:
      return message || "Your request is under review by our administrators.";
  }
};

const RegistrationAlert: React.FC<{ registration?: PartnerRegistration }> = ({
  registration,
}) => {
  if (!registration) return null;

  const alertType =
    registration.status.type === RegistrationState.REJECTED
      ? AlertType.ERROR
      : AlertType.INFO;
  const message = getAlertMessage(registration);

  return (
    <div className={styles["registration-form__alert"]}>
      <Alert type={alertType} message={message} />
    </div>
  );
};

const PartnerRegistrationScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state, shallowEqual);
  const { registrationId } = useParams<{ registrationId?: string }>();

  const registration = registrationId
    ? Object.values(user?.partnerRegistrations || {}).find(
        (reg) => reg.id === registrationId,
      )
    : undefined;
  const readOnly = registration?.status.type === RegistrationState.PENDING;

  const handleExitForm = useCallback(
    () => go(dispatch, ROUTE_TEMPLATE.MY_PARTNERS),
    [dispatch],
  );

  const handleSubmit = useCallback(
    async (requestData: PartnerRegistrationRequest) => {
      const modalText =
        "Please, make sure the information you entered is accurate. Once submitted, you won't be able to make further changes until the request is reviewed by our administrators.";

      const modalProps = registrationId
        ? {
            title: "Re-submit registration",
            text: modalText,
          }
        : {
            title: "Submit registration",
            text: modalText,
          };

      try {
        await new Promise<void>((resolve, reject) => {
          showModalConfirm({
            ...modalProps,
            onConfirm: async () => {
              try {
                await (registrationId
                  ? resubmitPartnerRegistration(
                      registrationId,
                      requestData,
                    )(dispatch)
                  : registerPartner(requestData)(dispatch));
                resolve();
              } catch (e) {
                reject(e);
              }
            },
            onCancel: reject,
          });
        });

        go(dispatch, ROUTE_TEMPLATE.MY_PARTNERS);
        logger.notify(
          TYPE.SUCCESS,
          "Your registration was successfully submitted and will be reviewed by our administrators shortly.",
        );
      } catch (e) {
        if (e) throw e;
      }
    },
    [dispatch, registrationId],
  );

  const submitButtonLabel = registrationId ? "Re-submit" : "Register";

  return registrationId && !registration ? (
    <Redirect to={getRoute(ROUTE_TEMPLATE.MY_PARTNERS)} />
  ) : (
    <>
      {registration && (
        <BackLink text="My Law Firms" onClick={handleExitForm} />
      )}

      <RegistrationAlert registration={registration} />

      <PageHeader title="Partner Registration" />
      <PartnerRegistrationForm
        onExit={handleExitForm}
        onSubmit={handleSubmit}
        submitButtonLabel={submitButtonLabel}
        {...{ user, readOnly, registration, registrationId }}
      />
    </>
  );
};

export default PartnerRegistrationScreen;
