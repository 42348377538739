import { Limits } from "modules/workflow/types/Questionnaire";
import textMap from "../textMap";

const getLimitsErrorMessage = (defaultMessage: string, limits?: Limits) => {
  return limits?.errorMessageKey
    ? textMap[limits.errorMessageKey] || defaultMessage
    : defaultMessage;
};

export default getLimitsErrorMessage;
