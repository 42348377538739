import React from "react";
import usePagination from "@mui/material/usePagination";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import { IconType } from "components/Icons";
import { PaginatedListColour } from "..";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type PaginationControlProps = {
  currentPage: number;
  itemCount: number;
  itemLimit: number;
  onPageChange: (pageIdx: number) => void;
  totalPages: number;
  colour?: PaginatedListColour;
};

const PaginationControl = ({
  currentPage,
  itemCount,
  itemLimit,
  onPageChange,
  totalPages,
  colour = PaginatedListColour.DEFAULT,
}: PaginationControlProps) => {
  const { items } = usePagination({
    count: totalPages,
    onChange: (_: object, page: number) => onPageChange(page),
  });
  return (
    <div className={cx("pagination-control")}>
      <nav className={cx("pagination-control__nav")}>
        <ul className={cx("pagination-control__pages")}>
          {items.map(({ page, type, selected, ...item }, index) => {
            switch (type) {
              case "start-ellipsis":
              case "end-ellipsis":
                return (
                  <li
                    key={index}
                    className={cx("pagination-control__ellipses")}
                  >
                    ...
                  </li>
                );
              case "next":
                return (
                  <li
                    key={index}
                    className={cx("pagination-control__next", {
                      "pagination-control__next--white":
                        colour === PaginatedListColour.WHITE,
                      "pagination-control__next--disabled": item.disabled,
                    })}
                  >
                    <TabbedButton
                      {...item}
                      icon={IconType.RIGHT_CHEVRON}
                      style={TabbedButtonType.SECONDARY}
                    />
                  </li>
                );
              case "page":
                return (
                  <li
                    key={index}
                    className={cx({
                      "pagination-control__page--active": selected,
                      "pagination-control__page": !selected,
                      "pagination-control__page--white":
                        colour === PaginatedListColour.WHITE,
                    })}
                  >
                    <TabbedButton
                      {...item}
                      label={`${page}`}
                      style={TabbedButtonType.SECONDARY}
                    />
                  </li>
                );
              case "previous":
                return (
                  <li
                    key={index}
                    className={cx("pagination-control__previous", {
                      "pagination-control__previous--white":
                        colour === PaginatedListColour.WHITE,
                      "pagination-control__previous--disabled": item.disabled,
                    })}
                  >
                    <TabbedButton
                      {...item}
                      icon={IconType.LEFT_CHEVRON}
                      style={TabbedButtonType.SECONDARY}
                    />
                  </li>
                );
              default:
                return null;
            }
          })}
        </ul>
      </nav>
      <div
        className={cx("pagination-control__text", {
          "pagination-control__text--white":
            colour === PaginatedListColour.WHITE,
        })}
      >
        Showing {(currentPage - 1) * itemLimit + 1} -{" "}
        {currentPage * itemLimit > itemCount
          ? itemCount
          : currentPage * itemLimit}{" "}
        of {itemCount} items
      </div>
    </div>
  );
};

export default PaginationControl;
