import React from "react";
import Plan from "modules/product/types/Plan";
import Product from "modules/product/types/Product";

import PlanSelectorPlansItemFeatures from "./PlanSelectorPlansItemFeatures";
import TabbedButton from "components/TabbedButton";

import styles from "./styles.module.scss";
import isPlan from "modules/product/helpers/isPlan";
import Addon from "modules/product/types/Addon";
import currencyFormatter from "utils/currencyFormatter";
import SelectorOption from "components/Selector/SelectorOption";
import { SelectorOptionMood } from "components/Selector/constants";
import classNames from "classnames/bind";
import PriceDetail from "./PriceDetail";
const cx = classNames.bind(styles);

type PlanSelectorPlansItemProps = {
  activeAddons?: Addon[];
  allProducts: (Plan | Product)[];
  disabled?: boolean;
  isTailored?: boolean;
  onSelect: () => void;
  productOrPlan: Plan | Product;
  selected: boolean;
  title: string;
  isPricingShown: boolean;
  recommended?: boolean;
};

const PlanSelectorPlansItem = ({
  activeAddons,
  allProducts,
  disabled,
  isTailored,
  onSelect,
  productOrPlan,
  selected,
  title,
  isPricingShown,
  recommended,
}: PlanSelectorPlansItemProps) => {
  const classNames = cx("plan-selector-plans-item", {
    "plan-selector-plans-item--disabled": disabled,
    "plan-selector-plans-item--selected": selected,
  });

  const { description } = productOrPlan;
  const totalPrice = productOrPlan.price.regular;
  const externalPrice = productOrPlan.price.external;
  // const saving = isPlan(productOrPlan) ? sumBy(productOrPlan.products, (p) => ) : 0;
  const saving = 0;

  const includedProducts: Product[] = (
    isPlan(productOrPlan) ? productOrPlan.products : [productOrPlan.id]
  )
    .map((productId) => allProducts.find((p) => p.id === productId))
    .filter(Boolean) as Product[];

  const optionDescription = [
    description,
    !isTailored
      ? null
      : isPlan(productOrPlan)
        ? "Products are tailored to meet your specific needs and include extra provisions based on your previous answers."
        : "Product is tailored to meet your specific needs and include extra provisions based on your previous answers.",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <SelectorOption
      description={optionDescription}
      showDescriptionPopup
      selected={selected}
      title={title}
      optionValue={currencyFormatter.format(totalPrice + (externalPrice || 0))}
      optionValueMood={SelectorOptionMood.POSITIVE}
      saving={saving}
      addClassNames={classNames}
      isPricingShown={isPricingShown}
      recommended={recommended}
    >
      {isPricingShown && externalPrice && (
        <PriceDetail onlinePrice={totalPrice} offlinePrice={externalPrice} />
      )}
      <PlanSelectorPlansItemFeatures
        disabled={disabled}
        selected={selected}
        includedProducts={includedProducts}
        activeAddons={activeAddons}
        recommended={recommended}
      />
      <TabbedButton
        className={styles["plan-selector-plans-item__button"]}
        label="Select Plan"
        onClick={() => !disabled && onSelect()}
      />
    </SelectorOption>
  );
};

export default PlanSelectorPlansItem;
