import * as React from "react";
import { useId } from "react-id-generator";
import Input from "components/Forms/Inputs/Input";
import { InputType } from "components/Forms/Inputs/Input/types";
import QuestionWrapper, {
  QuestionSizes,
} from "components/Forms/Questions/QuestionWrapper";
import { QuestionHelp } from "modules/workflow/types/Questionnaire";

type QuestionInputProps = {
  disabled?: boolean;
  help?: QuestionHelp;
  info?: QuestionHelp;
  label?: string;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  name: string;
  placeholder?: string;
  size?: QuestionSizes;
  tabIndex?: number;
  type: InputType;
  prefix?: string;
  explainer?: string;
};

const QuestionInput: React.FunctionComponent<QuestionInputProps> = (props) => {
  const [questionId] = useId(1, "question-");
  return (
    <QuestionWrapper
      className={`question--${props.type}`}
      disabled={props.disabled}
      help={props.help}
      id={questionId}
      info={props.info}
      label={props.label}
      name={props.name}
      size={
        props.size
          ? props.size
          : props.type === InputType.EMAIL
            ? QuestionSizes.LARGE
            : QuestionSizes.MEDIUM
      }
    >
      <Input
        disabled={props.disabled}
        id={questionId}
        max={props.max}
        maxLength={props.maxLength}
        min={props.min}
        name={props.name}
        placeholder={props.placeholder}
        tabIndex={props.tabIndex}
        type={props.type}
        prefix={props.prefix}
        explainer={props.explainer}
      />
    </QuestionWrapper>
  );
};

export default QuestionInput;
