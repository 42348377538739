import React from "react";
import currencyFormatter from "utils/currencyFormatter";
import styles from "./styles.module.scss";

type Props = {
  onlinePrice: number;
  offlinePrice: number;
};

const PriceDetail: React.FunctionComponent<Props> = ({
  onlinePrice,
  offlinePrice,
}) => (
  <div className={styles["price-detail"]}>
    <p className={styles["price-detail__line"]}>
      {currencyFormatter.format(onlinePrice)} collected online
    </p>
    <p className={styles["price-detail__line"]}>
      {currencyFormatter.format(offlinePrice)} collected at your attorney
      meeting
    </p>
  </div>
);

export default PriceDetail;
