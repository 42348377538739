import React from "react";
import classNames from "classnames/bind";
import TabbedButton from "components/TabbedButton";

import Addon from "modules/product/types/Addon";

import { ReactComponent as InfoSVG } from "components/Forms/Questions/QuestionWrapper/info.svg";
import { ModalType, showModalConfirm } from "components/Modal";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type PlanSelectorAddonsItemProps = {
  addon: Addon;
  disabled?: boolean;
  onAddonToggled: (addon: Addon) => void;
  selected: boolean;
};

const PlanSelectorAddonsItem: React.FunctionComponent<
  PlanSelectorAddonsItemProps
> = ({ addon, disabled, onAddonToggled, selected }) => {
  let classNames = cx("plan-selector-addons-item", {
    "plan-selector-addons-item--disabled": disabled,
    "plan-selector-addons-item--selected": selected,
  });
  return (
    <li className={classNames}>
      {addon.description && (
        <InfoSVG
          aria-label={`Addon '${addon.name}' Tooltip`}
          className={styles["plan-selector-addons-item-tooltip"]}
          onClick={() =>
            !disabled &&
            showModalConfirm({
              labelConfirm: "Close",
              text: addon.description,
              title: addon.name,
              type: ModalType.NOTIFICATION,
            })
          }
        />
      )}
      <div className={styles["plan-selector-addons-item-addon"]}>
        <h3 className={styles["plan-selector-addons-item-name"]}>
          {addon.name}
        </h3>
      </div>
      <div className={styles["plan-selector-addons-item-add"]}>
        <TabbedButton
          className={styles["plan-selector-addons-item-toggle"]}
          onClick={() => !disabled && onAddonToggled(addon)}
        />
      </div>
    </li>
  );
};

export default PlanSelectorAddonsItem;
