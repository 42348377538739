import { ProductSelector } from "modules/product/types/ProductSelector";
import { AnswersMap } from "modules/workflow/types/Answer";
import { PaymentResponseStatus } from "../responseTypes/InitPaymentResponseType";

export enum OrderItemType {
  PLAN = "Plan",
  PRODUCT = "Product",
  ADDON = "Addon",
  SUPPORT = "Support",
  DISCOUNT = "Discount",
  ADJUSTMENT = "Adjustment",
  SUPERVISED_SIGNING = "SupervisedSigning",
}

export enum PaymentStatus {
  Initialised = "Initialised",
  Submitted = "Submitted",
  ProcessingAction = "ProcessingAction",
  Success = "Success",
  Failed = "Failed",
  Cancelled = "Cancelled",
}

export enum UpdatePaymentAction {
  Submit = "Submit",
}

export enum PaymentActionType {
  OnlineProductPayment = "OnlineProductPayment",
  PaymentBeforeInitialization = "PaymentBeforeInitialization",
  PaymentBeforeRender = "PaymentBeforeRender",
  PaymentSupport = "PaymentSupport",
  RepeatedPayment = "RepeatedPayment",
  SkippedPayment = "SkippedPayment",
}

export type PaymentOrderItem = {
  amount: number;
  itemId?: string;
  name: string;
  type: OrderItemType;
  workflowId?: string;
};

export type UpdatePaymentRequest = {
  action: PaymentAction;
  paymentId: string;
  workflowId?: string;
  promoCode?: string;
};

export type PaymentActionPaymentBeforeInitialization = {
  type: PaymentActionType.PaymentBeforeInitialization;
  createWorkflow: {
    definitionId: string;
    selector: ProductSelector;
    onboardingAnswers: AnswersMap;
    addons?: string[];
    userId?: string;
  };
};
export type PaymentActionPaymentBeforeRender = {
  type: PaymentActionType.PaymentBeforeRender;
  workflowId: string;
};

export type PaymentActionPaymentSupport = {
  type: PaymentActionType.PaymentSupport;
  supportId: string;
};

export type PaymentActionRepeatedPayment = {
  type: PaymentActionType.RepeatedPayment;
  workflowId: string;
};

export type PaymentActionOnlineProductPayment = {
  type: PaymentActionType.OnlineProductPayment;
  workflowId: string;
};

export type PaymentActionSkippedPayment = {
  type: PaymentActionType.SkippedPayment;
};

export type PaymentAction =
  | PaymentActionPaymentBeforeInitialization
  | PaymentActionPaymentBeforeRender
  | PaymentActionPaymentSupport
  | PaymentActionRepeatedPayment
  | PaymentActionOnlineProductPayment
  | PaymentActionSkippedPayment;

export type SkippedPayment = {
  responseStatus: PaymentResponseStatus.Skipped;
  workflowId: string;
  promoCode: string;
};

type Payment = {
  allOrderItems?: PaymentOrderItem[];
  amount: number;
  clientSecret: string;
  created: string;
  currency: string;
  externalId: string;
  id: string;
  orderItems?: PaymentOrderItem[];
  partnerId: string;
  paymentAction: PaymentAction;
  status: PaymentStatus;
  updated: string;
  userId: string;
  workflowError?: boolean;
  workflowId: string | undefined;
  responseStatus: PaymentResponseStatus.Placed;
};

export default Payment;
