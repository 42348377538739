import { BadRequestResponsePayload } from "./ErrorResponsePayload";

type ErrorResponse = { status: number; data: any };

const getMessage = (
  defaultMessage: string,
  response: ErrorResponse,
): string => {
  return response.status === 400
    ? (response.data as BadRequestResponsePayload | undefined)?.detail ||
        defaultMessage
    : defaultMessage;
};

class ApiError extends Error {
  constructor(
    public defaultMessage: string,
    public response: ErrorResponse,
  ) {
    super(getMessage(defaultMessage, response));
  }
}

export default ApiError;
