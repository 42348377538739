import React from "react";
import { ConsumerMedicaidLeadItemStatusProps } from "./types";
import { MedicaidLeadStatus } from "modules/medicaid/types";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const ConsumerMedicaidLeadItemStatus = ({
  status,
}: ConsumerMedicaidLeadItemStatusProps) => {
  switch (status) {
    case MedicaidLeadStatus.InterviewInProgress:
      return (
        <span
          className={cx(
            "consumer-medicaid-lead-item-status",
            "interview-in-progress",
          )}
        >
          Interview In Progress
        </span>
      );
    case MedicaidLeadStatus.ReadyForPartner:
      return (
        <span
          className={cx(
            "consumer-medicaid-lead-item-status",
            "ready-for-partner",
          )}
        >
          Partner Review
        </span>
      );
    case MedicaidLeadStatus.LeadRejected:
      return (
        <span
          className={cx("consumer-medicaid-lead-item-status", "lead-rejected")}
        >
          Rejected
        </span>
      );
    case MedicaidLeadStatus.LeadAccepted:
      return (
        <span
          className={cx("consumer-medicaid-lead-item-status", "lead-accepted")}
        >
          Accepted
        </span>
      );
  }
};

export default ConsumerMedicaidLeadItemStatus;
