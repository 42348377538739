import React, { useContext, useEffect, useMemo } from "react";

import PageHeader from "components/PageHeader";
import SigningOptions from "modules/workflow/components/SigningOptions";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EcommerceItem,
  EcommerceItemID,
  EventName,
  ItemListID,
} from "contexts/DataLayerContext/types";
import {
  getEcommerceItemCategory,
  getEcommerceItemName,
  getItemListName,
} from "contexts/DataLayerContext/helpers";
import { PartnerID } from "modules/parties/types/Partner";

type SigningOptionsScreenProps = {
  isLoadingInstructions: boolean;
  onDownloadInstructions?: () => void;
  onSelfSigningSelected: () => void;
  onSupervisedSigningSelected: () => void;
  price: number;
  selectedOption?: boolean;
  isSubmitting: boolean;
  hasExternalProducts: boolean;
  partnerId: PartnerID;
};

const SigningOptionsScreen = ({
  isLoadingInstructions,
  onDownloadInstructions,
  onSelfSigningSelected,
  onSupervisedSigningSelected,
  price,
  selectedOption,
  isSubmitting,
  hasExternalProducts,
  partnerId,
}: SigningOptionsScreenProps) => {
  const { sendEvent } = useContext(DataLayerContext);

  const supervisedSigningEcommerceItem: EcommerceItem = useMemo(
    () => ({
      item_id: EcommerceItemID.SUPERVISED_SIGNING,
      item_name: getEcommerceItemName(EcommerceItemID.SUPERVISED_SIGNING),
      index: 0,
      item_brand: partnerId,
      item_category: getEcommerceItemCategory(
        EcommerceItemID.SUPERVISED_SIGNING,
      ),
      item_list_id: ItemListID.Signing,
      item_list_name: getItemListName(ItemListID.Signing),
      price,
      quantity: 1,
    }),
    [partnerId, price],
  );

  const selfSigningEcommerceItem: EcommerceItem = useMemo(
    () => ({
      item_id: EcommerceItemID.SELF_SIGNING,
      item_name: getEcommerceItemName(EcommerceItemID.SELF_SIGNING),
      index: 1,
      item_brand: partnerId,
      item_category: getEcommerceItemCategory(EcommerceItemID.SELF_SIGNING),
      item_list_id: ItemListID.Signing,
      item_list_name: getItemListName(ItemListID.Signing),
      price: 0,
      quantity: 1,
    }),
    [partnerId],
  );

  useEffect(() => {
    sendEvent?.({
      event: EventName.ViewItemList,
      item_list_id: ItemListID.Signing,
      item_list_name: getItemListName(ItemListID.Signing),
      items: [supervisedSigningEcommerceItem, selfSigningEcommerceItem],
    });
  }, [
    partnerId,
    price,
    selfSigningEcommerceItem,
    sendEvent,
    supervisedSigningEcommerceItem,
  ]);

  const handleSelfSigningSelected = () => {
    sendEvent?.({
      event: EventName.SelectItem,
      item_list_id: ItemListID.Signing,
      item_list_name: getItemListName(ItemListID.Signing),
      items: [selfSigningEcommerceItem],
    });
    onSelfSigningSelected();
  };

  const handleSupervisedSigningSelected = () => {
    sendEvent?.({
      event: EventName.SelectItem,
      item_list_id: ItemListID.Signing,
      item_list_name: getItemListName(ItemListID.Signing),
      items: [supervisedSigningEcommerceItem],
    });
    onSupervisedSigningSelected();
  };

  return (
    <>
      <PageHeader
        title="Executing your documents"
        text="Your plan is not complete until you sign your documents. Choose one of the following options to execute your plan."
      />
      <SigningOptions
        onSelfSigningSelected={handleSelfSigningSelected}
        onSupervisedSigningSelected={handleSupervisedSigningSelected}
        supervisedPrice={price}
        isLoadingInstructions={isLoadingInstructions}
        onDownloadInstructions={onDownloadInstructions}
        selectedOption={selectedOption}
        isSubmitting={isSubmitting}
        hasExternalProducts={hasExternalProducts}
      />
    </>
  );
};

export default SigningOptionsScreen;
