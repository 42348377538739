import {
  QuestionID,
  QuestionnaireID,
  QuestionsMapByQuestionnaire,
  QuestionType,
} from "../types/Questionnaire";

export default (
  allQuestions: QuestionsMapByQuestionnaire,
  questionIDs: Record<QuestionnaireID, QuestionID[]>,
): QuestionsMapByQuestionnaire =>
  Object.entries(questionIDs).reduce((res, [questionnaireId, questionIDs]) => {
    questionIDs.forEach((questionId) => {
      const question =
        allQuestions[questionnaireId] &&
        allQuestions[questionnaireId][questionId];
      if (question) {
        res[questionnaireId] = {
          ...res[questionnaireId],
          [questionId]: question,
        };

        if (question.data.definition.type === QuestionType.SUBQUESTIONNAIRE) {
          const subquestionnaireId = question.data.definition.questionnaireId;
          res[subquestionnaireId] = allQuestions[subquestionnaireId];
        }
      }
    });
    return res;
  }, {} as QuestionsMapByQuestionnaire);
