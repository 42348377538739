import { Middleware, PayloadAction } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import { WorkflowDocumentType } from "modules/api/types/WorkflowDetailsPayload";
import { isAdmin } from "modules/auth/helper";
import type { RootState } from "redux/reducer";
import { downloadRenderedDocument } from "../actions";
import {
  cancelDocumentsLoading,
  documentsDownloadDetailsLoaded,
  loadDocuments,
  loadDocumentsDownloadDetails,
  loadDocumentsFailed,
  LoadDocumentsFailedActionPayload,
  LoadDownloadDetailsPayload,
} from "./actions";
import {
  showDocumentsRenderStatusModal,
  showDownloadDocumentsModal,
} from "./components/DownloadDocumentsModal";

const createSigningInstructionsMiddleware: () => Middleware =
  () =>
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    switch (action.type) {
      case LOCATION_CHANGE: {
        return cancelDocumentsLoading();
      }

      case documentsDownloadDetailsLoaded.type: {
        const {
          payload: { downloadDetails, documentType, workflowId, asUser },
        } = action as PayloadAction<LoadDownloadDetailsPayload>;
        const isSigningInstructions =
          documentType === WorkflowDocumentType.SIGNING;

        const onDownload = (doc: DownloadableDocumentDetails) =>
          downloadRenderedDocument(doc)(dispatch);

        const onExpire = () =>
          loadDocumentsDownloadDetails(
            workflowId,
            documentType,
            asUser,
          )(dispatch);

        return showDownloadDocumentsModal(
          isSigningInstructions ? "Signing Instructions" : "Your documents",
          downloadDetails,
          onDownload,
          onExpire,
        );
      }

      case loadDocumentsFailed.type: {
        const { user, partner, workflows }: RootState = getState();
        const {
          payload: { documents, workflowId },
        } = action as PayloadAction<LoadDocumentsFailedActionPayload>;

        const admin = isAdmin(partner?.id, user);
        return (
          documents &&
          admin &&
          showDocumentsRenderStatusModal(documents, async () => {
            const workflow = workflows[workflowId];
            workflow?.userId &&
              loadDocuments(workflowId, workflow.userId)(dispatch);
          })
        );
      }
    }
  };

export default createSigningInstructionsMiddleware;
