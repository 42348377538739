import React, { useContext, useEffect } from "react";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { OptionsProps } from "./types";
import { useSelector } from "redux/reducer";
import { shallowEqual } from "react-redux";
import { ROUTE_TEMPLATE } from "modules/navigation/routes";
import {
  buildEcommerceItemsArray,
  getDefinition,
  shouldShowPlansHeading,
  shouldShowProductsHeading,
} from "modules/landing/helpers";
import { useLocation } from "react-router-dom";
import DataLayerContext from "contexts/DataLayerContext";
import {
  EcommerceItemCategory,
  EcommerceItemID,
  EventName,
  ItemListID,
} from "contexts/DataLayerContext/types";
import {
  getEcommerceItemName,
  getItemListName,
} from "contexts/DataLayerContext/helpers";
import Products from "./Products";
import Packages from "./Packages";
import { Typography } from "@mui/material";

const cx = classNames.bind(styles);

const Options = ({ partnerId, scrollToOptions }: OptionsProps) => {
  const location = useLocation();
  const { availableWorkflows, isLoadingProducts } = useSelector(
    (state) => ({
      availableWorkflows: state.availableWorkflows,
      isLoadingProducts: state.isLoadingProducts,
    }),
    shallowEqual,
  );
  const { sendEvent } = useContext(DataLayerContext);

  const definition = getDefinition(availableWorkflows, partnerId);

  useEffect(() => {
    if (
      partnerId !== undefined &&
      !isLoadingProducts &&
      (location?.hash === "#essentialDocuments" ||
        (location.state as Record<string, any> | undefined)?.from ===
          ROUTE_TEMPLATE.SEARCH)
    ) {
      scrollToOptions();
    }
  }, [
    isLoadingProducts,
    location?.hash,
    location.state,
    partnerId,
    scrollToOptions,
  ]);

  useEffect(() => {
    // Appends the view item list event to the data layer.
    if (partnerId === undefined) {
      // National Page
      sendEvent?.({
        event: EventName.ViewItemList,
        item_list_id: ItemListID.NationalLanding,
        item_list_name: getItemListName(ItemListID.NationalLanding),
        items: [
          {
            item_id: EcommerceItemID.WILL,
            item_name: getEcommerceItemName(EcommerceItemID.WILL),
            index: 0,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
          {
            item_id: EcommerceItemID.HCP,
            item_name: getEcommerceItemName(EcommerceItemID.HCP),
            index: 1,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
          {
            item_id: EcommerceItemID.POA,
            item_name: getEcommerceItemName(EcommerceItemID.POA),
            index: 2,
            item_category: EcommerceItemCategory.PRODUCTS,
            item_list_id: ItemListID.NationalLanding,
            item_list_name: getItemListName(ItemListID.NationalLanding),
            quantity: 1,
          },
        ],
      });
    } else if (definition !== undefined) {
      sendEvent?.({
        event: EventName.ViewItemList,
        item_list_id: ItemListID.PartnerLanding,
        item_list_name: getItemListName(ItemListID.PartnerLanding),
        items: buildEcommerceItemsArray(
          definition,
          ItemListID.PartnerLanding,
          partnerId,
        ),
      });
    }
  }, [definition, partnerId, sendEvent]);

  const showProductsHeading = shouldShowProductsHeading(definition);
  const showPackages = shouldShowPlansHeading(definition);

  if (!showProductsHeading && !showPackages) return null;

  return (
    <div className={cx("options")}>
      <div className={cx("landing__container-100")}>
        <Typography variant="h2">
          Your Estate Planning Essentials All In One Place
        </Typography>
        <hr />
        <Products partnerId={partnerId} scrollToOptions={scrollToOptions} />
        <Packages partnerId={partnerId} />
      </div>
    </div>
  );
};

export default Options;
