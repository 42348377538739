import LoadingOverlay from "components/LoadingOverlay";
import { goBack } from "connected-react-router";
import Addon from "modules/product/types/Addon";
import {
  ADVISOR_ANSWERS_KEY,
  onboardingCompleted,
} from "modules/advisor/actions";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { mergePagesToSinglePage } from "modules/workflow/helpers/mergeQuestionnairePages";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { QuestionnaireID } from "modules/workflow/types/Questionnaire";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "redux/reducer";
import persistentStore from "modules/localStore";

import styles from "./styles.module.scss";

const AdvisorScreen: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { user, partner } = useSelector((state) => state, shallowEqual);
  const advisorQuestionnaire = partner?.onboardingQuestionnaire;

  const advisorAnswers: AnswersByQuestionnaireType | undefined =
    persistentStore.get(ADVISOR_ANSWERS_KEY);

  const questionsBySubquestionnaire = advisorQuestionnaire && {
    [advisorQuestionnaire.id]: advisorQuestionnaire.questions,
  };
  const advisorPage =
    advisorQuestionnaire &&
    mergePagesToSinglePage(
      `${advisorQuestionnaire.id}_1`,
      advisorQuestionnaire.id,
      advisorQuestionnaire.pages,
      false,
    );

  useEffect(() => {
    if (partner && !advisorQuestionnaire) {
      dispatch(goBack());
    }
  }, [dispatch, advisorQuestionnaire, partner]);

  const handleFormSave = useCallback(
    (
      questionnaireId: QuestionnaireID,
      answers: AnswersMap,
      _sourcePageId?: string,
      selectedAddons?: Addon[],
    ) => {
      onboardingCompleted(
        user,
        questionnaireId,
        advisorQuestionnaire,
        answers,
        selectedAddons,
      )(dispatch);
    },
    [dispatch, user, advisorQuestionnaire],
  );

  return advisorQuestionnaire ? (
    <div className={styles["advisor"]}>
      <h1>Tell us a little about yourself...</h1>
      <h2>
        Please answer as accurately as possible, to ensure our plan
        recommendation addresses your needs, and covers what is important for
        you.
      </h2>
      <h3>
        All information collected here is confidential and protected by
        attorney-client privilege.
      </h3>
      <QuestionnaireForm
        answers={advisorAnswers}
        questionnairePages={[advisorPage!]}
        onSavePageAnswers={handleFormSave}
        questionsBySubquestionnaire={questionsBySubquestionnaire!}
        customFormProps={{
          showSubmitButton: true,
          submitButtonLabel: "Submit",
        }}
      />
    </div>
  ) : (
    <LoadingOverlay />
  );
};

export default AdvisorScreen;
