import Party, { PartyID } from "modules/parties/types/Party";
import Product from "modules/product/types/Product";
import Questionnaire from "./Questionnaire";
import { AnswersByQuestionnaireType } from "./Answer";
import { WorkflowPayloadState } from "modules/api/types/WorkflowPayload";
import {
  ClientsMap,
  WorkflowDocumentDetails,
} from "modules/api/types/WorkflowDetailsPayload";
import { DownloadableDocumentDetails } from "modules/api/responseTypes/LoadRenderedDocumentsResponseType";
import Plan from "modules/product/types/Plan";
import Addon, { AddonID } from "modules/product/types/Addon";
import { User } from "../../auth/User";

export type WorkflowContacts = {
  clients?: ClientsMap;
  parties?: Record<PartyID, Party>;
};

export type ImportableParties = {
  children?: Record<PartyID, Party>;
  otherParties?: Record<PartyID, Party>;
};

export type WorkflowID = string;

export const LAWYER_INITIATED_WORKFLOW = "LAWYER";
export const CLIENT_INITIATED_WORKFLOW = "CLIENT";

type Workflow = {
  id: WorkflowID;
  availableParties?: ImportableParties;
  addons?: Record<AddonID, Addon>;
  state: WorkflowPayloadState;
  userId?: string;
  userName?: string;
  partnerId: string;
  definitionId: string;
  products?: Product[];
  plan?: Plan;
  answers?: AnswersByQuestionnaireType;
  contacts?: WorkflowContacts;
  questionnaires?: Questionnaire[];
  documents?: WorkflowDocumentDetails[];
  created: string;
  updated: string;
  isLoading?: boolean;
  isOverview?: boolean;
  documentsToDownload?: DownloadableDocumentDetails[];
  isSkippingPayment?: boolean;
  archived: boolean;
  creator: string;
  creatorName?: string;
  updaterName?: string;
  user?: User;
};

export default Workflow;
