import React, { useState } from "react";
import { useDispatch, shallowEqual } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { useSelector, RootState } from "redux/reducer";
import {
  createNewWorkflowWithPlan,
  createNewWorkflowWithProduct,
} from "modules/workflow/actions";
import { getRoute, ROUTE_TEMPLATE } from "modules/navigation/routes";
import LoadingOverlay from "components/LoadingOverlay";
import { isAdmin } from "../../modules/auth/helper";
import { AuthenticationTrigger } from "modules/auth/loginCallback/LoginCallbackMeta";

const ProductTailoringScreenContainer: React.FunctionComponent = () => {
  const { partnerId, workflowId, productId, planId } = useParams() as any;
  const dispatch = useDispatch();
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);

  const {
    user,
    isLoadingProducts,
    availableWorkflows,
    productsRehydrated,
    state,
    incompleteProfile,
  } = useSelector((state: RootState) => state, shallowEqual);

  if (!availableWorkflows) return <LoadingOverlay />;
  if (isAdmin(partnerId, user))
    return <Redirect to={getRoute(ROUTE_TEMPLATE.PARTNER_ROOT)} />;

  const workflow = (availableWorkflows || {})[workflowId as string];

  const tailoredProduct = productId
    ? workflow?.data.availableProducts.find((prod) => prod.id === productId)
    : undefined;
  const tailoredPlan = planId
    ? workflow?.data.availablePlans.find((plan) => plan.id === planId)
    : undefined;
  const selectedProductOrPlan = tailoredProduct || tailoredPlan;

  if (state && tailoredProduct && !isCreatingWorkflow) {
    if (!user || incompleteProfile) {
      return (
        <Redirect
          to={{
            pathname: getRoute(ROUTE_TEMPLATE.SIGNUP),
            state: {
              action: AuthenticationTrigger.START_WORKFLOW_PRODUCT,
              preventLoginRedirect: true,
              definitionId: workflow.id,
              product: tailoredProduct,
              state: state,
              advisorAnswers: {},
              customAddons: [],
              meta: {
                action: AuthenticationTrigger.START_WORKFLOW_PRODUCT,
                preventLoginRedirect: true,
                definitionId: workflow.id,
                product: tailoredProduct,
                state: state,
                advisorAnswers: {},
                customAddons: [],
              },
            },
          }}
          from={window.location.pathname}
        />
      );
    } else {
      setIsCreatingWorkflow(true);
      createNewWorkflowWithProduct(
        user,
        workflowId,
        tailoredProduct,
        state,
        undefined,
        undefined,
        user?.uid,
      )(dispatch);
      return <LoadingOverlay />;
    }
  } else if (state && tailoredPlan && !isCreatingWorkflow) {
    if (!user || incompleteProfile) {
      return (
        <Redirect
          to={{
            pathname: getRoute(ROUTE_TEMPLATE.SIGNUP),
            state: {
              action: AuthenticationTrigger.START_WORKFLOW_PLAN,
              preventLoginRedirect: true,
              definitionId: workflow.id,
              plan: tailoredPlan,
              state: state,
              advisorAnswers: {},
              customAddons: [],
              meta: {
                action: AuthenticationTrigger.START_WORKFLOW_PLAN,
                preventLoginRedirect: true,
                definitionId: workflow.id,
                plan: tailoredPlan,
                state: state,
                advisorAnswers: {},
                customAddons: [],
              },
            },
          }}
          from={window.location.pathname}
        />
      );
    } else {
      setIsCreatingWorkflow(true);
      createNewWorkflowWithPlan(
        user,
        workflowId,
        tailoredPlan,
        state,
        undefined,
        undefined,
        user?.uid,
      )(dispatch);
      return <LoadingOverlay />;
    }
  }

  const renderInvalidWorkflowScreen = () => (
    <Redirect to={getRoute(ROUTE_TEMPLATE.PARTNER_ROOT)} />
  );

  if (!selectedProductOrPlan)
    return isLoadingProducts || !productsRehydrated || !state ? (
      <LoadingOverlay />
    ) : (
      renderInvalidWorkflowScreen()
    );

  return <LoadingOverlay />;
};

export default ProductTailoringScreenContainer;
