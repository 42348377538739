export const tableColumns = [
  {
    label: "Partner name",
    accessor: "displayName",
    sortable: true,
    reset: false,
  },
  {
    label: "Partner ID",
    accessor: "partnerId",
    sortable: true,
    reset: false,
  },
  {
    label: "Total payment collected ($)",
    accessor: "totalPaymentsCollected",
    sortable: true,
    reset: false,
  },
  {
    label: "",
    accessor: "downloadReceipt",
    sortable: false,
    reset: false,
  },
  {
    label: "",
    accessor: "empty",
    sortable: false,
    reset: true,
  },
];

export const DEFAULT_SORT_FIELD = "displayName";
export const DEFAULT_ORDER = "desc";
