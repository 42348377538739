import TabbedButton, { TabbedButtonType } from "components/TabbedButton";
import * as React from "react";

import styles from "./styles.module.scss";

type ClickThroughCardType = {
  actionLabel: string;
  description: string;
  onAction: () => void;
  title: string;
};

const ClickThroughCard: React.FC<ClickThroughCardType> = ({
  actionLabel,
  description,
  onAction,
  title,
}) => (
  <div className={styles["card"]}>
    <h3 className={styles["card__title"]}>{title}</h3>
    {description && <p>{description}</p>}
    <div className={styles["card__actions"]}>
      <TabbedButton
        className={styles["card__button"]}
        style={TabbedButtonType.SECONDARY}
        onClick={onAction}
      >
        {actionLabel}
      </TabbedButton>
    </div>
  </div>
);

export default ClickThroughCard;
