import { Dispatch, Middleware } from "@reduxjs/toolkit";
import { loadUserProfile, userProfileLoaded } from "modules/auth/actions";
import { isAdmin, isGlobalAdmin } from "modules/auth/helper";
import { User } from "modules/auth/User";
import { UserProfile } from "modules/auth/UserProfile";
import { appLoaded } from "modules/navigation/actions";
import { getFormattedPartnerId } from "modules/navigation/helpers/getFormattedPartnerId";
import { go, isPath, redirect } from "modules/navigation/helpers/navigator";
import {
  getPartnerRoute,
  getRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import Partner from "modules/parties/types/Partner";
import type { RootState } from "redux/reducer";
import {
  loadPartnerDetails,
  partnerIdInvalid,
  partnerLoaded,
  partnerRegistrationDeleted,
  partnerRegistrationSubmitted,
  partnerSelected,
} from "./actions";

const validatePartnerState = (
  dispatch: Dispatch,
  partner?: Partner | null,
  selectedState?: string | null,
  user?: User | null,
) => {
  if (!partner || !selectedState || !user) return;
  if (isGlobalAdmin(user)) return;

  if (
    partner.data.jurisdiction &&
    !partner.data.jurisdiction.includes(selectedState)
  ) {
    redirect(dispatch, ROUTE_TEMPLATE.PARTNER_ROOT);
  }
};

const createPartnerMiddleware: () => Middleware =
  () =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case appLoaded.type: {
        const partnerId = getFormattedPartnerId();
        if (partnerId) loadPartnerDetails()(dispatch);
        break;
      }

      case partnerSelected.type:
        if (action.payload === null) {
          go(dispatch, getRoute(ROUTE_TEMPLATE.SEARCH));
        } else {
          if (!isPath(ROUTE_TEMPLATE.LOGIN) && !isPath(ROUTE_TEMPLATE.SIGNUP)) {
            go(
              dispatch,
              getPartnerRoute(action.payload, ROUTE_TEMPLATE.PARTNER_ROOT),
            );
          }
          loadPartnerDetails(action.payload)(dispatch);
        }
        break;

      case partnerIdInvalid.type: {
        if (getFormattedPartnerId() !== "EPLC") {
          // Prevent redirect loop
          redirect(dispatch, ROUTE_TEMPLATE.ROOT);
        }
        break;
      }

      // Validate selected state matches partner jurisdiction
      case partnerLoaded.type: {
        const { state, user }: RootState = getState();
        validatePartnerState(dispatch, action.payload, state, user);
        break;
      }

      case userProfileLoaded.type: {
        const partnerId = getFormattedPartnerId();
        const profile: UserProfile = action.payload;
        if (partnerId && isAdmin(partnerId, profile)) {
          setTimeout(() => loadPartnerDetails()(dispatch), 0);
        }
        break;
      }

      // Reload active registrations
      case partnerRegistrationDeleted.type:
      case partnerRegistrationSubmitted.type: {
        loadUserProfile()(dispatch);
        break;
      }
    }

    next(action);
  };

export default createPartnerMiddleware;
