import Partner from "modules/parties/types/Partner";

export const sortPartners = (
  partners: Partner[],
  sortField: string,
  sortOrder: string,
) => {
  if (sortField) {
    const sorted = [...partners].sort((a, b) => {
      var aSortValue: string | boolean | undefined = "";
      var bSortValue: string | boolean | undefined = "";
      var numericField: boolean = false;

      switch (sortField) {
        case "name":
          aSortValue = a.data.displayName || "";
          bSortValue = b.data.displayName || "";
          break;
        case "id":
          aSortValue = a.id || "";
          bSortValue = b.id || "";
          break;
        case "contact":
          aSortValue = a.data.contact.email;
          bSortValue = b.data.contact.email;
          break;
        case "created":
          aSortValue = a.created;
          bSortValue = b.created;
          break;
        case "archived":
          aSortValue = a.archived ? "Archived" : "Valid";
          bSortValue = b.archived ? "Archived" : "Valid";
          break;
      }

      if (aSortValue === undefined) return 1;
      if (bSortValue === undefined) return -1;

      return (
        aSortValue.localeCompare(bSortValue, "en", {
          numeric: numericField,
        }) * (sortOrder === "asc" ? 1 : -1)
      );
    });
    return sorted;
  }
  return partners;
};
