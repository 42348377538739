import Client, { isClient } from "../types/Client";
import Party, {
  GenericParty,
  RelationshipToClient,
  RelationshipType,
  WhoseChild,
} from "../types/Party";
import startCase from "lodash/startCase";
import uniq from "lodash/uniq";

export const isRelatedAs = (
  party: Party,
  client: Client | undefined,
  relationshipType: RelationshipType,
): boolean => {
  if (!client || !party.relationships) {
    return false;
  }
  return Boolean(
    party.relationships.find(
      (rel) =>
        rel.clientId === client.clientId &&
        rel.relationship.type === relationshipType,
    ),
  );
};

const relationToString = (
  relationToClient: RelationshipToClient,
): string | undefined => {
  switch (relationToClient.type) {
    case RelationshipType.CHILD:
      return relationToClient.whoseChild === WhoseChild.MY_PARTNERS
        ? "Step-child"
        : "Child";
    case RelationshipType.GRANDCHILD:
      return "Grandchild";
    case RelationshipType.OTHER:
      return startCase(relationToClient.value);
  }
};

export const findRelationship = (
  party: Party,
  client: Client | undefined,
  relationshipType: RelationshipType,
): RelationshipToClient | undefined =>
  (party.relationships || []).find(
    (relationship) =>
      relationship.clientId === client?.clientId &&
      relationship.relationship.type === relationshipType,
  )?.relationship;

export default (party: GenericParty, client?: Client): string | undefined => {
  if (isClient(party)) {
    return party.clientId === client?.clientId ? "Client" : "Spouse";
  }

  return (
    uniq(
      (party.relationships || [])
        .filter((relationship) => relationship.clientId === client?.clientId)
        .map(({ relationship }) => relationToString(relationship)),
    ).join(", ") || undefined
  );
};
