import * as React from "react";
import classNames from "classnames/bind";

import getRelationshipToClient from "modules/parties/helpers/getRelationshipToClient";
import Party, {
  getFullName,
  RelationshipType,
  ClientParties,
  GenericParty,
} from "modules/parties/types/Party";
import Client, { isClient } from "modules/parties/types/Client";

import TabbedButton, {
  TabbedButtonMood,
  TabbedButtonType,
} from "components/TabbedButton";
import { ModalType, showModalConfirm } from "components/Modal";
import { IconType } from "components/Icons";

import styles from "./styles.module.scss";
const cx = classNames.bind(styles);

type PartyListItemProps = {
  client?: Client;
  disabled?: boolean;
  onEdit?: (
    party: Party,
    relationship?: RelationshipType,
    title?: string,
  ) => any;
  onRemove?: (party: GenericParty) => any;
  partiesToImport?: ClientParties;
  party: GenericParty;
  requireEmail?: boolean;
  requirePhone?: boolean;
};

const PartyListItem: React.FunctionComponent<PartyListItemProps> = ({
  client,
  disabled,
  onEdit,
  onRemove,
  party,
  requireEmail,
  requirePhone,
}) => {
  const relationship = getRelationshipToClient(party, client);
  const isMissingEmail = requireEmail && !party.contact?.email;
  const isMissingPhone = requirePhone && !party.contact?.cellPhone;
  let className = cx("party-list-item", {
    "party-list-item--disabled": disabled,
    "party-list-item--incomplete":
      !relationship || isMissingPhone || isMissingEmail,
  });

  const renderEditBtn = () => {
    if (!onEdit || isClient(party)) return null;

    if (!relationship)
      return (
        <TabbedButton
          disabled={disabled}
          label={party.parentId ? "Import" : "Enter missing details"}
          style={TabbedButtonType.PRIMARY}
          mood={TabbedButtonMood.NEGATIVE}
          onClick={() => onEdit(party)}
        />
      );

    if (isMissingPhone)
      return (
        <TabbedButton
          disabled={disabled}
          label={"Enter phone number"}
          style={TabbedButtonType.PRIMARY}
          mood={TabbedButtonMood.NEGATIVE}
          onClick={() => onEdit(party)}
        />
      );

    if (isMissingEmail)
      return (
        <TabbedButton
          disabled={disabled}
          label={"Enter email address"}
          style={TabbedButtonType.PRIMARY}
          mood={TabbedButtonMood.NEGATIVE}
          onClick={() => onEdit(party)}
        />
      );

    return (
      <TabbedButton
        style={TabbedButtonType.SECONDARY}
        disabled={disabled}
        label="Edit"
        onClick={() => onEdit(party)}
      />
    );
  };

  return (
    <li className={className}>
      <div className={styles["party-list-item__row"]}>
        <div className={styles["party-list-item__row"]}>
          <div className={styles["party-list-item__handle"]} />
          <div className={styles["party-list-item__col"]}>
            <div
              className={styles["party-list-item__name"]}
              data-clarity-mask="true"
            >
              {getFullName(party)}
            </div>
            {relationship ? (
              <div className={styles["party-list-item__info"]}>
                <span className={styles["party-list-item__label"]}>
                  Relationship:
                </span>{" "}
                {relationship}
              </div>
            ) : null}
            {/* Roles overview not yet supported: https://trello.com/c/YgBlbRyC/360-collect-render-all-roles-of-a-related-party */}
            {/* {party.roles.length && party.roles.length ? (
              <div className={styles["party-list-item__info"]}>
                <span className={styles["party-list-item__label"]}>Role:</span> {party.roles?.join(", ")}
              </div>
            ) : null} */}
            {!isClient(party) && party.deceased && (
              <div className={styles["party-list-item__info"]}>
                <span className={styles["party-list-item__label"]}>State:</span>{" "}
                (Deceased)
              </div>
            )}
          </div>
        </div>
        <div className={styles["party-list-item__buttons"]}>
          {renderEditBtn()}
          {onRemove && (
            <TabbedButton
              ariaLabel="Remove"
              disabled={disabled}
              icon={IconType.CROSS}
              mood={TabbedButtonMood.NEGATIVE}
              onClick={() =>
                showModalConfirm({
                  labelConfirm: "Remove",
                  onConfirm: () => onRemove(party),
                  text: "Please confirm that you are happy to proceed with this action.",
                  title: `Are you sure you want to remove ${getFullName(
                    party,
                  )}?`,
                  maskTitle: true,
                  type: ModalType.WARNING,
                })
              }
              style={TabbedButtonType.SECONDARY}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default PartyListItem;
