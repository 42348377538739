import { TabbedButtonStatus } from "components/TabbedButton";
import {
  Step,
  workflowStepToAdminStatusMap,
} from "modules/api/types/WorkflowPayload";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";
import Workflow, {
  LAWYER_INITIATED_WORKFLOW,
} from "modules/workflow/types/Workflow";

export const getBtnLabel = (currentStep: Step) =>
  currentStep === Step.AwaitingLawyerReview
    ? "Start review"
    : currentStep === Step.ReviewInProgress
      ? "Continue review"
      : currentStep === Step.AssetProtectionRequested
        ? "Medicaid Qualification"
        : currentStep === Step.AwaitingEngagementCheck
          ? "Review Engagement"
          : currentStep === Step.ContactCustomer ||
              currentStep === Step.PaymentRequired ||
              currentStep === Step.RepeatedPaymentRequired ||
              currentStep === Step.PaymentFailed ||
              currentStep === Step.RepeatedPaymentFailed ||
              currentStep === Step.PaymentInitialised
            ? "Contact Client"
            : "View";

export const getBtnLabelLawyerInitiatedWorkflow = (currentStep: Step) =>
  currentStep === Step.AwaitingLawyerReview
    ? "Start review"
    : currentStep === Step.ReviewInProgress
      ? "Continue review"
      : currentStep === Step.AssetProtectionRequested
        ? "Medicaid Qualification"
        : currentStep === Step.AwaitingEngagementCheck
          ? "Review Engagement"
          : currentStep === Step.ContactCustomer ||
              currentStep === Step.PaymentRequired ||
              currentStep === Step.RepeatedPaymentRequired ||
              currentStep === Step.PaymentFailed ||
              currentStep === Step.RepeatedPaymentFailed ||
              currentStep === Step.PaymentInitialised
            ? "Contact Client"
            : currentStep === Step.DocumentsReady
              ? "View"
              : "Continue Workflow";

export const getStatus = (currentStep: Step) =>
  currentStep === Step.AwaitingLawyerReview
    ? TabbedButtonStatus.LAWYER_ACTION_REQUIRED
    : currentStep === Step.ContactCustomer
      ? TabbedButtonStatus.CLIENT_ACTION_REQUIRED
      : currentStep === Step.AwaitingEngagementCheck
        ? TabbedButtonStatus.LAWYER_ACTION_REQUIRED
        : currentStep === Step.PaymentRequired ||
            currentStep === Step.RepeatedPaymentRequired ||
            currentStep === Step.PaymentFailed ||
            currentStep === Step.RepeatedPaymentFailed ||
            currentStep === Step.PaymentInitialised ||
            currentStep === Step.RepeatedPaymentInitialised
          ? TabbedButtonStatus.CLIENT_ACTION_REQUIRED
          : currentStep === Step.ReviewInProgress
            ? TabbedButtonStatus.IN_PROGRESS
            : currentStep === Step.DocumentsReady ||
                currentStep === Step.DocumentsRendered ||
                currentStep === Step.DocumentsDownloaded
              ? TabbedButtonStatus.COMPLETED
              : currentStep === Step.EngagementRejected
                ? TabbedButtonStatus.REJECTED
                : TabbedButtonStatus.NO_STATUS;

export const getPageId = (currentStep: Step) =>
  currentStep === Step.AwaitingEngagementCheck ? SUMMARY_PAGE_ID : undefined;

export const sortWorkflows = (
  workflows: Workflow[],
  sortField: string,
  sortOrder: string,
) => {
  if (sortField) {
    return [...workflows].sort((a, b) => {
      var aSortValue: string | boolean | undefined = "";
      var bSortValue: string | boolean | undefined = "";
      var numericField: boolean = false;

      switch (sortField) {
        case "client_name":
          aSortValue = a.state.clientFullName || "";
          bSortValue = b.state.clientFullName || "";
          break;
        case "status":
          aSortValue =
            workflowStepToAdminStatusMap[a.state.steps.current.step] || "N/A";
          bSortValue =
            workflowStepToAdminStatusMap[b.state.steps.current.step] || "N/A";
          break;
        case "products":
          aSortValue =
            a.state.products === undefined
              ? ""
              : a.state.products
                  .map((p) => p.productId)
                  .reduce((prev, curr) => prev + "" + curr);
          bSortValue =
            b.state.products === undefined
              ? ""
              : b.state.products
                  .map((p) => p.productId)
                  .reduce((prev, curr) => prev + "" + curr);
          break;
        case "state":
          aSortValue =
            a.state.maybeUserState === undefined ? "" : a.state.maybeUserState;
          bSortValue =
            b.state.maybeUserState === undefined ? "" : b.state.maybeUserState;
          break;
        case "created":
          aSortValue = a.created;
          bSortValue = b.created;
          break;
        case "last_edited":
          aSortValue = a.updated;
          bSortValue = b.updated;
          break;
        case "signing_type":
          aSortValue =
            a.creator === LAWYER_INITIATED_WORKFLOW
              ? LAWYER_INITIATED_WORKFLOW
              : a.state.supervisedSigning
                ? "Supervised"
                : "Self";
          bSortValue =
            b.creator === LAWYER_INITIATED_WORKFLOW
              ? LAWYER_INITIATED_WORKFLOW
              : b.state.supervisedSigning
                ? "Supervised"
                : "Self";
          break;
      }

      if (aSortValue === undefined) return 1;
      if (bSortValue === undefined) return -1;

      return (
        aSortValue.localeCompare(bSortValue, "en", {
          numeric: numericField,
        }) * (sortOrder === "asc" ? 1 : -1)
      );
    });
  }
  return workflows;
};
