import React from "react";
import classnames from "classnames/bind";

import styles from "./styles.module.scss";
const cx = classnames.bind(styles);

export enum PlaceholderScreenMood {
  SUCCESS = "success",
  NEUTRAL = "neutral",
}

type PlaceholderScreenProps = {
  message?: string;
  mood?: PlaceholderScreenMood;
  renderAction?: () => JSX.Element | null;
  subtitle?: string;
  title: string;
};

const PlaceholderScreen: React.FunctionComponent<PlaceholderScreenProps> = ({
  message,
  mood = PlaceholderScreenMood.NEUTRAL,
  renderAction,
  subtitle,
  title,
}) => (
  <div className={cx(styles.placeholder, styles[`mood__${mood}`])}>
    <h1>{title}</h1>
    {subtitle ? <h2>{subtitle}</h2> : null}
    <div className={styles["placeholder__next-action"]}>
      {message ? (
        <p className={styles["placeholder__next-action__message"]}>{message}</p>
      ) : null}
      {renderAction ? renderAction() : null}
    </div>
  </div>
);

export default PlaceholderScreen;
