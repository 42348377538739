import { createAction, Dispatch } from "@reduxjs/toolkit";
import { get, API } from "modules/api";
import logger from "../../logger";

import {
  AddressLookupPayload,
  AddressLookupResponseType,
  AddressSearchPayload,
  AddressSearchResponseType,
} from "modules/api/responseTypes/AddressResponseType";
import AddressType from "modules/parties/types/Address";
import { NotificationType } from "components/Notification";

export const addressLookupStarted = createAction("ADDRESS_LOOKUP_STARTED");
export const addressLookupComplete = createAction<AddressType>(
  "ADDRESS_LOOKUP_COMPLETE",
);
export const addressLookupFailed = createAction<any>("ADDRESS_LOOKUP_FAILED");

export const addressSearchStarted = createAction("ADDRESS_SEARCH_STARTED");
export const addressSearchComplete = createAction<AddressSearchResultsType[]>(
  "ADDRESS_SEARCH_COMPLETE",
);
export const addressSearchFailed = createAction<any>("ADDRESS_SEARCH_FAILED");

export type AddressSearchResultsType = {
  label: string;
  type: string;
  value: string;
};

const parseAddressLookupPayload = (
  address: AddressLookupPayload,
): AddressType => {
  return {
    id: address.id,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.provinceCode,
    adminAreaName: address.adminAreaName,
    zip: address.zip,
  };
};

const callAddressLookupAPI = async (
  addressId: string,
): Promise<AddressType> => {
  const response: AddressLookupResponseType = await get(API.ADDRESS_LOOKUP(), {
    addressId,
  });
  return parseAddressLookupPayload(response.data);
};

export const addressLookup =
  (addressId: string) =>
  async (dispatch: Dispatch): Promise<AddressType | undefined> => {
    dispatch(addressLookupStarted());
    try {
      const result = await callAddressLookupAPI(addressId);
      dispatch(addressLookupComplete(result));
      return result;
    } catch (error) {
      logger.notify(
        NotificationType.ERROR,
        "Address Lookup Failed",
        "Please, try again later",
        error,
      );
      dispatch(addressLookupFailed(error));
    }
  };

const parseAddressSearchPayload = (
  addresses: AddressSearchPayload[],
): AddressSearchResultsType[] => {
  return addresses.map((address): AddressSearchResultsType => {
    return {
      label: address.label,
      type: address.type,
      value: address.id,
    };
  });
};

const callAddressSearchAPI = async (
  containerId?: string,
  searchTerm?: string,
): Promise<AddressSearchResultsType[]> => {
  const response: AddressSearchResponseType = await get(API.ADDRESS_LOOKUP(), {
    containerId,
    searchTerm,
  });
  return parseAddressSearchPayload(response.data);
};

export const addressSearch =
  (containerId?: string, searchTerm?: string) =>
  async (
    dispatch: Dispatch,
  ): Promise<AddressSearchResultsType[] | undefined> => {
    dispatch(addressSearchStarted());
    try {
      const result = await callAddressSearchAPI(containerId, searchTerm);
      dispatch(addressSearchComplete(result));
      return result;
    } catch (error) {
      logger.notify(
        NotificationType.ERROR,
        "Address Search Failed",
        "Please, try again later",
        error,
      );
      dispatch(addressSearchFailed(error));
    }
  };
