import {
  ModalControls,
  ModalType,
  showModal,
} from "../../../../components/Modal";
import { NotificationType } from "../../../../components/Notification";
import TabbedButton, {
  TabbedButtonType,
} from "../../../../components/TabbedButton";
import { keyBy } from "lodash";
import logger from "../../../../logger";
import { API, post } from "../../../api";
import QuestionnaireForm from "../../../workflow/components/QuestionnaireForm";
import { AnswersMap } from "../../types/Answer";
import { Question, QuestionType } from "../../types/Questionnaire";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import Workflow, { WorkflowID } from "../../types/Workflow";
import { PartnerID } from "../../../parties/types/Partner";
import {
  WorkflowAuditRequest,
  WorkflowAuditResponse,
  WorkflowAudit,
} from "../../types/WorkflowAudit";

const QUESTIONNAIRE_ID = "archive-workflow";

type ModalProps = {
  archive: boolean;
  controls: ModalControls;
  getSubmitHandle?: (submitHandle: () => any) => void;
  partnerId: PartnerID;
  workflowId: WorkflowID;
  previousAuditData?: WorkflowAudit;
};

const questions: Question[] = [
  {
    id: "reason",
    data: {
      definition: { type: QuestionType.TEXT },
      descriptions: [{ label: "Reason" }],
    },
  },
];

type Props = { className?: string; workflow: Workflow; afterSave?: () => void };

const ArchiveWorkflow: React.FC<Props> = ({
  className,
  workflow,
  afterSave,
}) => {
  let submitHandle: () => Promise<void>;
  const getSubmitHandle = (handle: () => Promise<void>) => {
    submitHandle = handle;
  };

  const getAuditData = async (
    workflow: Workflow,
  ): Promise<WorkflowAudit | undefined> => {
    if (workflow.archived) {
      const payload: WorkflowAuditRequest = {
        partnerId: workflow.partnerId,
        workflowId: workflow.id,
        latestOnly: true,
      };

      const response: WorkflowAuditResponse = await post(
        API.WORKFLOW_AUDIT(),
        payload,
      );
      if (response.status === 200) return response.data;
      else return undefined;
    }
    return undefined;
  };

  const handleFormSubmit = async () => {
    if (!submitHandle) return undefined;
    await submitHandle();
    afterSave && afterSave();
  };

  const handleOpen = async () => {
    // retrieve previous archive reason
    const auditDataOption = await getAuditData(workflow);

    showModal({
      type: ModalType.CONFIRM,
      cancellable: true,
      labelCancel: "Close",
      title: workflow.archived
        ? "Unarchive this workflow"
        : "Archive this workflow",
      onConfirm: handleFormSubmit,
      labelConfirm: workflow.archived ? "Unarchive" : "Archive",
      renderContent: (controls: ModalControls) => (
        <ArchiveWorkflowModalContent
          archive={!workflow.archived}
          partnerId={workflow.partnerId}
          workflowId={workflow.id}
          getSubmitHandle={getSubmitHandle}
          controls={controls}
          previousAuditData={auditDataOption}
        />
      ),
    });
  };

  return (
    <TabbedButton
      className={className}
      style={TabbedButtonType.SECONDARY}
      onClick={handleOpen}
    >
      <div
        data-tip=""
        data-for={workflow.archived ? "reinstateTooltip" : "archiveTooltip"}
      >
        {workflow.archived ? (
          <FontAwesomeIcon icon={faBoxOpen} />
        ) : (
          <FontAwesomeIcon icon={faBoxArchive} />
        )}
      </div>
    </TabbedButton>
  );
};

const ArchiveWorkflowModalContent: React.FC<ModalProps> = ({
  archive,
  partnerId,
  workflowId,
  getSubmitHandle,
  controls,
  previousAuditData,
}) => {
  const handleArchive = async (_: any, values: AnswersMap) => {
    controls.busy(true);

    const { reason } = values;

    const payload = {
      partnerId,
      workflowId,
      archive,
      reason,
    } as AnswersMap;

    try {
      logger.notify(
        NotificationType.INFO,
        archive ? "Archiving workflow" : "Unarchiving workflow",
      );
      const response = await post(API.WORKFLOW_ARCHIVE(), payload);
      controls.close();
      return response.data;
    } catch (error) {
      controls.busy(false);
      logger.notify(NotificationType.ERROR, "Archive workflow failed", error);
    }
  };

  const getArchiveInfo = (auditData: WorkflowAudit | undefined) => {
    if (auditData === undefined) {
      return "";
    } else {
      const archiveDate = new Date(auditData.created).toLocaleDateString();
      if (auditData.reason === undefined || auditData.reason === "") {
        return (
          " This workflow was archived by " +
          auditData.displayName +
          " on " +
          archiveDate
        );
      } else {
        return (
          " This workflow was archived by " +
          auditData.displayName +
          " on " +
          archiveDate +
          " because: '" +
          previousAuditData?.reason +
          "'."
        );
      }
    }
  };

  return (
    <QuestionnaireForm
      getSubmitHandle={getSubmitHandle}
      onSavePageAnswers={handleArchive}
      customFormProps={{ showSaveAndExitButton: false }}
      questionsBySubquestionnaire={{
        [QUESTIONNAIRE_ID]: keyBy(questions, "id"),
      }}
      questionnairePages={[
        {
          id: "default_page",
          questionnaireId: QUESTIONNAIRE_ID,
          caption: "",
          sections: [
            {
              caption: "",
              description: archive
                ? "Archiving a workflow removes it from the list of workflows displayed. You will be able to unarchive it later if needed. Please provide the reason for archiving."
                : "Unarchiving a workflow allows you to view documents or continue the workflow if it hasn't expired." +
                  getArchiveInfo(previousAuditData),
              questions: questions.map((question) => question.id),
            },
          ],
        },
      ]}
    />
  );
};

export default ArchiveWorkflow;
