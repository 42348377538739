import Partner, { PartnerID } from "modules/parties/types/Partner";
import { User, UserRole } from "./User";
import { UserProfile } from "./UserProfile";

export const isAdmin = (
  partnerId: PartnerID | undefined | null,
  user: User | UserProfile | null,
): boolean =>
  user?.globalRoles?.includes(UserRole.ADMIN) ||
  Boolean(
    partnerId &&
      user?.activePartners?.[partnerId]?.roles?.includes(UserRole.ADMIN),
  );

export const isDraftingEnabled = (
  partner: Partner | undefined | null,
): boolean => Boolean(partner && partner.data.drafting);

export const isGlobalAdmin = (user: User | UserProfile | null): boolean =>
  !!user?.globalRoles?.includes(UserRole.GLOBAL_ADMIN);

export const getAdministeredPartnerIDs = (
  user: User | UserProfile | null,
): PartnerID[] =>
  Object.keys(user?.activePartners || {}).filter((partnerId) =>
    isAdmin(partnerId, user),
  );

export const getDefaultPartnerID = (
  user: User,
): PartnerID | Set<PartnerID> | null => {
  const adminPartnerIDs = getAdministeredPartnerIDs(user);
  if (adminPartnerIDs.length > 1) return new Set(adminPartnerIDs);
  if (adminPartnerIDs.length === 1) return adminPartnerIDs[0];

  const userPartners = getAllPartnerIDs(user);

  if (!userPartners.size) return null;
  if (userPartners.size === 1) return Array.from(userPartners)[0];
  return userPartners;
};

export const getAllPartnerIDs = (user: User | null): Set<PartnerID> =>
  new Set(Object.keys(user?.activePartners || {}));

export const hasActiveRegistrations = (user: User | null): boolean =>
  Object.keys(user?.partnerRegistrations || {}).length > 0;

export const getUserRoles = (
  partnerId: PartnerID | undefined,
  user: User | null,
): UserRole[] => [
  ...((partnerId && user?.activePartners?.[partnerId]?.roles) || []),
  ...(user?.globalRoles || []),
];
