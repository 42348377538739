import React from "react";
import classNames from "classnames/bind";
import TabbedButton, { TabbedButtonMood } from "components/TabbedButton";

import Addon from "modules/product/types/Addon";
import Plan from "modules/product/types/Plan";
import Product from "modules/product/types/Product";

import styles from "./styles.module.scss";
import currencyFormatter from "utils/currencyFormatter";
const cx = classNames.bind(styles);

type PlanSelectorSummaryProps = {
  availableProducts: (Plan | Product)[];
  onProductSelected: (product: Plan | Product) => any;
  selectedAddons?: Addon[];
  selectedProduct?: Plan | Product;
};

const PlanSelectorSummary: React.FunctionComponent<
  PlanSelectorSummaryProps
> = ({
  availableProducts,
  onProductSelected,
  selectedAddons,
  selectedProduct,
}) => {
  let classNames = cx("plan-selector-summary", {
    "plan-selector-summary--disabled": !selectedProduct,
  });

  const externalPrice = selectedProduct?.price.external;
  const planProducts =
    selectedProduct && "products" in selectedProduct
      ? selectedProduct.products
      : [];

  const availableProductsMap = availableProducts.reduce(
    (acc, i) => ({ ...acc, [i.id]: i }),
    {} as { [key: string]: Product | Plan },
  );

  const externalProducts = planProducts
    .map((productId) => {
      const product = availableProductsMap[productId] || {};
      return product && "external" in product && product.external
        ? product.name
        : undefined;
    })
    .filter(Boolean);

  return (
    <div className={classNames}>
      <div className={styles["plan-selector-summary__wrapper"]}>
        {selectedProduct && (
          <div className={styles["plan-selector-summary__row"]}>
            <div className={styles["plan-selector-summary__breakdown"]}>
              {selectedProduct.name}
            </div>
            <div className={styles["plan-selector-summary__breakdown-cost"]}>
              {currencyFormatter.format(
                selectedProduct.price.regular + (externalPrice || 0),
              )}
            </div>
          </div>
        )}
        {externalPrice && (
          <div className={styles["plan-selector-summary__row"]}>
            <div className={styles["plan-selector-summary__breakdown"]}>
              {externalProducts.join(", ")} <i>(collected by attorney)</i>
            </div>
            <div className={styles["plan-selector-summary__breakdown-cost"]}>
              {currencyFormatter.format(-externalPrice)}
            </div>
          </div>
        )}
        <div className={styles["plan-selector-summary__row"]}>
          <div className={styles["plan-selector-summary__total"]}>Total</div>
          <div className={styles["plan-selector-summary__total-cost"]}>
            {currencyFormatter.format(
              selectedProduct ? selectedProduct.price.regular : 0,
            )}
          </div>
        </div>
        <TabbedButton
          disabled={!selectedProduct}
          label="Continue"
          mood={TabbedButtonMood.POSITIVE}
          onClick={() => {
            selectedProduct && onProductSelected(selectedProduct);
          }}
        />
      </div>
    </div>
  );
};

export default PlanSelectorSummary;
