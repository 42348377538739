import { useLocation } from "react-router-dom";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import { ReactComponent as LogoSVG } from "resources/logos/Aturna_Brandmark_RGB_Purple.svg";
import { ReactComponent as Logo2SVG } from "static/logos/logo-main.svg";

import styles from "./styles.module.scss";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import validatePasswordStrength from "../../../utils/password";
import { useDispatch } from "react-redux";
import { redirect } from "../../../modules/navigation/helpers/navigator";
import { getRoute, ROUTE_TEMPLATE } from "../../../modules/navigation/routes";

type ResetPasswordWrapperProps = {
  authProvider: firebase.auth.Auth;
};

const ResetPasswordWrapper = ({ authProvider }: ResetPasswordWrapperProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordInstructionsVisible, setPasswordInstructionsVisible] =
    useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [success, setSuccess] = useState(false);

  const NO_ERROR_MESSAGE = "";
  const PLEASE_TRY_AGAIN =
    "There was an error processing your request; if you received more than one reset password email, use the link in the most recent email.";
  const SUCCESS_MESSAGE = "Success!";

  const isErrorState = useCallback((): boolean => {
    return errorMessage !== "";
  }, [errorMessage]);

  const useQuery = (): URLSearchParams => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const dispatch = useDispatch();

  const query = useQuery();
  const mode = query.get("mode");
  const actionCode = query.get("oobCode");

  const handlePasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const password = e.target.value;
    const isValid = validatePasswordStrength(password);
    setPasswordError(!isValid);
    setPasswordInstructionsVisible(!isValid);
    setPassword(password);
    setErrorMessage(NO_ERROR_MESSAGE);
  };

  const handleSuccessClick = (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    redirect(dispatch, getRoute(ROUTE_TEMPLATE.LOGIN));
  };

  const handleForgottenPasswordRedirect = (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    redirect(dispatch, getRoute(ROUTE_TEMPLATE.FORGOTTEN_PASSWORD));
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    // Localize the UI to the selected language as determined by the lang
    // parameter.

    if (mode !== "resetPassword" || actionCode === null) {
      setErrorMessage(PLEASE_TRY_AGAIN);
    } else {
      // Verify the password reset code is valid.
      authProvider
        .verifyPasswordResetCode(actionCode)
        .then((_email) => {
          // Save the new password.
          authProvider
            .confirmPasswordReset(actionCode, password)
            .then((_resp) => {
              setSuccess(true);
            })
            .catch((_error) => {
              setSuccess(false);
              setErrorMessage(PLEASE_TRY_AGAIN);
              // Error occurred during confirmation. The code might have expired or the
              // password is too weak.
            });
        })
        .catch((_error) => {
          setSuccess(false);
          setErrorMessage(PLEASE_TRY_AGAIN);
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmPasswordChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
    if (confirmPassword !== password) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
    setErrorMessage(NO_ERROR_MESSAGE);
  };

  useEffect(() => {
    setSubmitButtonEnabled(
      password.length !== 0 &&
        confirmPassword.length !== 0 &&
        !passwordError &&
        confirmPassword === password,
    );
  }, [password, confirmPassword, passwordError]);

  return (
    <Box component="div" className={styles.login}>
      <Box component="div" className={styles.login__image}>
        <LogoSVG aria-label="Logo" className={styles["login__image__logo-1"]} />
        <h2>Reset Your Password</h2>
        <h3>Enter a new password below.</h3>
        <Logo2SVG
          aria-label="Logo"
          className={styles["login__image__logo-2"]}
        />
        <form>
          <FormGroup className={styles.login__form}>
            <FormControl
              className={styles.login__input_container}
              variant="outlined"
            >
              <InputLabel htmlFor="ui-register-password-input">
                Password
              </InputLabel>
              <OutlinedInput
                className={styles.login__input_input}
                id="ui-register-password-input"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => handlePasswordChange(e)}
                error={passwordError}
              />
            </FormControl>
            <FormControl
              className={styles.login__input_container}
              variant="outlined"
            >
              <InputLabel htmlFor="ui-register-confirm-password-input">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                className={styles.login__input_input}
                id="ui-register-confirm-password-input"
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
                onChange={(e) => handleConfirmPasswordChange(e)}
                placeholder="Re-enter password"
                error={confirmPasswordError}
              />
            </FormControl>
            <Box
              component="div"
              className={styles.login__error_message_container}
              sx={{
                display: isErrorState() ? "block" : "none",
              }}
            >
              <Typography className={styles.login__error_message}>
                {errorMessage}
              </Typography>
              <Typography className={styles.login__error_message}>
                <Link onClick={handleForgottenPasswordRedirect}>
                  Click here to send another email to reset your password.
                </Link>
              </Typography>
            </Box>
            <Box
              component="div"
              className={styles.login__password_instructions_div}
              id="ui-register-password-instructions"
              sx={{
                display: passwordInstructionsVisible ? "block" : "none",
              }}
            >
              <Typography className={styles.login__password_instructions}>
                Your password should:
              </Typography>
              <Typography className={styles.login__password_requirements}>
                • be at least 8 characters long
              </Typography>
              <Typography className={styles.login__password_requirements}>
                • contain at least one uppercase and lowercase letter
              </Typography>
              <Typography className={styles.login__password_requirements}>
                • contain at least one number
              </Typography>
              <Typography className={styles.login__password_requirements}>
                • contain one of the following symbols: !@#$&*
              </Typography>
            </Box>
            <Box
              component="div"
              className={styles.login__input_button_container}
            >
              <Button
                variant="contained"
                className={styles.login__submit_button}
                type="submit"
                disabled={!submitButtonEnabled}
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
            </Box>
            <Box
              component="div"
              id="ui-login-forgotten-password-confirmation-message"
              className={styles.login__success_div}
            >
              {success ? (
                <>
                  <Typography className={styles.login__success_message}>
                    {SUCCESS_MESSAGE}{" "}
                    <Link onClick={handleSuccessClick}>Sign in</Link>
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          </FormGroup>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPasswordWrapper;
