enum ErrorType {
  ACTION_NOT_ALLOWED = "action-not-allowed",
  INVALID_PROMO_CODE = "invalid-promo-code",
  PARTNER_ID_EXISTS = "partner-id-already-exists",
  PARTNER_ID_REQUESTED = "partner-id-already-requested",
  PAYMENT_ILLEGAL_STATUS = "payment-illegal-status",
  ZIP_NOT_FOUND = "zip-not-found",
}

export default ErrorType;
