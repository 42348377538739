import { generatePath } from "react-router-dom";
import { getPartnerIdForRoute } from "./helpers/getFormattedPartnerId";
import { ProductType } from "modules/workflow/types/Product";

export enum ROUTE_TEMPLATE {
  ABOUT_US = "/pages/aboutus",
  ADMIN_PARTNERS = "/admin/partners",
  ADMIN_PARTNERS_PARTNER = "/admin/partners/:partnerId",
  ADMIN_PARTNERS_SCREEN = "/admin/partners/:partnerId/:screen",
  ADMIN_PARTNERS_CATEGORY = "/admin/partners/:partnerId/:screen/:categoryKey",
  ADMIN_PARTNERS_CATEGORY_ENTITY = "/admin/partners/:partnerId/:screen/:categoryKey/:productKey",
  ADMIN_PARTNERS_ARCHIVE = "/admin/partner/archive/:partnerId",
  ADMIN_PARTNERS_UNARCHIVE = "/admin/partner/unarchive/:partnerId",
  ADMIN_REGISTRATIONS = "/admin/registrations/:registrationId?",
  ADMIN_FINANCE = "/admin/finance",
  ADMIN_FINANCIAL_PROFILE = "/admin/finance/:partnerId",
  ADMIN_MEDICAID_VARIABLES = "/admin/medicaid/variables",
  ADVISOR = "/:partnerId/advisor",
  COOKIES_POLICY = "/pages/cookies",
  FAQS = "/pages/faqs",
  SEARCH = "/search",
  SITE_CONFIGURATION = "/:partnerId/site-setup",
  PRODUCT_CONFIGURATION = "/:partnerId/product-setup",
  PRODUCT_CONFIGURATION_CATEGORY = "/:partnerId/product-setup/:categoryKey",
  PRODUCT_CONFIGURATION_PRODUCT = "/:partnerId/product-setup/:categoryKey/:productKey",
  PERMISSION_CONFIGURATION = "/:partnerId/permissions",
  LEARNING_CENTER = "/pages/learningcenter",
  LOGIN = "/:partnerId?/login",
  RESET_PASSWORD = "/:partnerId?/reset-password",
  FORGOTTEN_PASSWORD = "/:partnerId?/forgotten-password",
  SIGNUP = "/:partnerId?/signup",
  ROOT = "/",
  MY_PARTNERS = "/partners",
  PARTNER_ROOT = "/:partnerId/",
  OAUTH_PARTNER = "/:partnerId/oauth",
  OAUTH_CALLBACK = "/oauth_callback",
  PLAN_TAILORING = "/:partnerId/workflow/:workflowId/plan/:planId/tailoring/:userId?",
  PRODUCT_TAILORING = "/:partnerId/workflow/:workflowId/product/:productId/tailoring/:userId?",
  PRODUCTS = "/:partnerId/products",
  PRODUCT_RECOMMENDATION = "/:partnerId/recommendation",
  PROFILE = "/profile",
  PARTNER_REGISTRATION = "/partner/register/:registrationId?",
  TERMS_AND_CONDITIONS = "/pages/terms",
  PRIVACY = "/pages/privacy",
  WORKFLOW = "/:partnerId/workflow",
  WORKFLOW_DETAILS = "/:partnerId/workflow/:id/:page?",
  WORKFLOW_PARTIES = "/:partnerId/workflow/:id/parties",
  WORKFLOW_SIGNING = "/:partnerId/workflow/:id/signing",
  WORKFLOW_PAYMENT = "/:partnerId/workflow/:id/checkout",
  WORKFLOW_PAYMENT_PROCESSING = "/:partnerId/workflow/:id/checkout/processing",
  DRAFTING = "/:partnerId/drafting",
  MEDICAID_LEAD = "/medicaid/:medicaidLeadId?",
  MEDICAID_ANALYSIS = "/medicaid/analysis/:medicaidLeadId",
  MEDICAID_LEAD_FINISHED = "/medicaid/finished/:medicaidLeadId",
  MEDICAID_LEAD_MAP = "/medicaid/map/:medicaidLeadId",
  PARTNER_MEDICAID_LEAD = "/:partnerId/medicaid/lead/:medicaidLeadId",
  PARTNER_MEDICAID_LEADS = "/:partnerId/medicaid/leads",
  PARTNER_MEDICAID_LEAD_PREVIEW = "/:partnerId/medicaid/leads/preview/:medicaidLeadId",
  PARTNER_MEDICAID_ANALYSIS = "/:partnerId/medicaid/analysis/:medicaidLeadId",
  MY_MEDICAID_WORKFLOWS = "/medicaid/leads",
}

export const PUBLIC_PAGES = ["pages", "oauth_callback", null];

const getHashParamsString = (
  params: Record<string, number | string | undefined>,
) => {
  const paramsString = Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .map((param) => param.join("="))
    .join("&");
  return paramsString.length > 0 ? `#${paramsString}` : "";
};

export const getRoute = (
  route: ROUTE_TEMPLATE,
  params?: Record<string, any>,
  hashParams?: Record<string, any>,
) => {
  const partnerId = getPartnerIdForRoute();
  return getPartnerRoute(partnerId, route, params, hashParams);
};

export const getPartnerRoute = (
  partnerId: string | null,
  route: ROUTE_TEMPLATE,
  params?: Record<string, any>,
  hashParams?: Record<string, any>,
) => {
  const hashString = hashParams ? getHashParamsString(hashParams) : "";
  const pathParams = Object.assign(
    partnerId ? { partnerId } : {},
    params || {},
  );

  try {
    return `${generatePath(route, pathParams)}${hashString}`;
  } catch (e) {
    return ROUTE_TEMPLATE.SEARCH;
  }
};

export const getWorkflowDetailsRoute = (
  id: string,
  pageIdx?: number,
  pageId?: string,
): string => {
  const hashParams = {
    page:
      pageIdx !== undefined && Number.isInteger(pageIdx)
        ? pageIdx! + 1
        : undefined,
    pageId,
  };
  return `${getRoute(ROUTE_TEMPLATE.WORKFLOW_DETAILS, { id }, hashParams)}`;
};

export const getPlanTailoringRoute = (
  workflowId: string,
  planId: string,
  userId?: string,
): string =>
  getRoute(ROUTE_TEMPLATE.PLAN_TAILORING, { workflowId, planId, userId });

export const getProductTailoringRoute = (
  workflowId: string,
  productId: string,
  userId?: string,
): string =>
  getRoute(ROUTE_TEMPLATE.PRODUCT_TAILORING, { workflowId, productId, userId });

export const buildProductTailoringRoute = (
  definitionId: string,
  productType: ProductType,
  userId?: string,
): string =>
  getRoute(ROUTE_TEMPLATE.PRODUCT_TAILORING, {
    workflowId: definitionId,
    productId: productType,
    userId,
  });

export const getWorkflowRoute = (workflowId: string, route: ROUTE_TEMPLATE) =>
  getRoute(route, { id: workflowId });
