import React from "react";
import { MedicaidLeadStatus } from "modules/medicaid/types";
import type {
  MedicaidLeadSummaryActionProps,
  MedicaidLeadSummaryBackButtonProps,
  MedicaidLeadSummaryMainActionProps,
} from "./types";
import TabbedButton, { TabbedButtonType } from "components/TabbedButton";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const MedicaidLeadSummaryBackButton = ({
  onBack,
}: MedicaidLeadSummaryBackButtonProps) => (
  <div className={cx("medicaid-lead-summary-back-button")}>
    <TabbedButton
      onClick={onBack}
      label="Back to interview"
      style={TabbedButtonType.SECONDARY}
    />
  </div>
);

const MedicaidLeadSummaryMainAction = ({
  medicaidLeadStatus,
  onConfirmSummary,
}: MedicaidLeadSummaryMainActionProps) => {
  switch (medicaidLeadStatus) {
    case MedicaidLeadStatus.LeadAccepted:
    case MedicaidLeadStatus.LeadRejected:
    case MedicaidLeadStatus.ReadyForPartner: {
      return (
        <div className={cx("medicaid-lead-summary-main-action")}>
          <TabbedButton
            onClick={onConfirmSummary}
            label="Asset protection analysis"
          />
        </div>
      );
    }
    case MedicaidLeadStatus.InterviewInProgress: {
      return (
        <div className={cx("medicaid-lead-summary-main-action")}>
          <TabbedButton
            onClick={onConfirmSummary}
            label="Get your asset protection analysis!"
          />
        </div>
      );
    }
  }
};

const MedicaidLeadSummaryActions = ({
  medicaidLeadStatus,
  onBack,
  onConfirmSummary,
}: MedicaidLeadSummaryActionProps) => (
  <div className={cx("medicaid-lead-summary-actions")}>
    {onBack && <MedicaidLeadSummaryBackButton onBack={onBack} />}
    <MedicaidLeadSummaryMainAction
      medicaidLeadStatus={medicaidLeadStatus}
      onConfirmSummary={onConfirmSummary}
    />
  </div>
);

export default MedicaidLeadSummaryActions;
