import { keyBy } from "lodash";
import QuestionnaireForm from "modules/workflow/components/QuestionnaireForm";
import { Question, QuestionType } from "modules/workflow/types/Questionnaire";
import React from "react";
import { StatePickerProps } from "./types";

const QUESTIONNAIRE_ID = "state-picker";
const QUESTION_ID = "state";

const stateEntryQuestions: Question[] = [
  {
    id: QUESTION_ID,
    data: {
      descriptions: [{ label: "" }],
      required: true,
      definition: { type: QuestionType.STATE, maxSuggestedItems: 51 },
    },
  },
];

const StatePicker = ({ onChangeState, currentState }: StatePickerProps) => {
  return (
    <QuestionnaireForm
      onChange={(change) => {
        const {
          [QUESTIONNAIRE_ID]: { state },
        } = change;
        state && onChangeState(state.toString());
      }}
      onSavePageAnswers={() => {}}
      customFormProps={{ showSaveAndExitButton: false }}
      questionsBySubquestionnaire={{
        [QUESTIONNAIRE_ID]: keyBy(stateEntryQuestions, "id"),
      }}
      answers={{
        [QUESTIONNAIRE_ID]: { [QUESTION_ID]: currentState },
      }}
      questionnairePages={[
        {
          id: "state-picker",
          questionnaireId: QUESTIONNAIRE_ID,
          caption: "",
          sections: [
            {
              caption: "Select Jurisdiction",
              questions: [QUESTION_ID],
            },
          ],
        },
      ]}
      fullWidth={true}
    />
  );
};

export default StatePicker;
