import {
  HTMLInputMode,
  HTMLInputType,
  InputType,
  Mask,
  MaskProps,
} from "../types";
import {
  getInputPattern,
  getMaxLength,
  handleBlurNumber,
  handleFocusNumber,
  handleInput,
  handleKeyDown,
  RegularExpressions,
  Sanitisers,
} from "../helpers";

export default (props: MaskProps): Mask => {
  return (
    (MASK[props.type] && MASK[props.type](props)) || {
      type: HTMLInputType.TEXT,
    }
  );
};

export const MASK: { [key: string]: (props: MaskProps) => Mask } = {
  [InputType.CURRENCY]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(2, props.min, props.max);
    return props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.DECIMAL,
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              )
              .replace(Sanitisers.DECIMAL_PLACES(2), "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL),
          placeholder: "0.00",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "currency",
            allowMinus: false,
            autoUnmask: true,
            clearIncomplete: true,
            digitsOptional: false,
            enforceDigitsOnBlur: false,
            max: props.max,
            min: props.min,
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.CURRENCY_ALLOW_NEGATIVE]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(2, props.min, props.max);
    return props.platform.isMobile
      ? {
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.LEADING_NEGATIVE_PERIOD, "-0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              )
              .replace(Sanitisers.DECIMAL_PLACES(2), "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL_ALLOW_NEGATIVE),
          placeholder: "0.00",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "currency",
            allowMinus: true,
            autoUnmask: true,
            clearIncomplete: true,
            digitsOptional: false,
            enforceDigitsOnBlur: false,
            max: props.max,
            min: props.min,
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.DECIMAL]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(4, props.min, props.max);
    return props.platform.isMobile
      ? {
          maxLength: maxLength,
          inputMode: HTMLInputMode.DECIMAL,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              )
              .replace(Sanitisers.DECIMAL_PLACES(4), "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL),
          placeholder: "0.0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "numeric",
            allowMinus: false,
            autoUnmask: true,
            clearIncomplete: true,
            digits: 4,
            max: props.max,
            min: props.min,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.DECIMAL_ALLOW_NEGATIVE]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(4, props.min, props.max);
    return props.platform.isMobile
      ? {
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.LEADING_NEGATIVE_PERIOD, "-0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              )
              .replace(Sanitisers.DECIMAL_PLACES(4), "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL_ALLOW_NEGATIVE),
          placeholder: "0.0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "numeric",
            allowMinus: true,
            autoUnmask: true,
            clearIncomplete: true,
            digits: 4,
            max: props.max,
            min: props.min,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.INTEGER]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(0, props.min, props.max);
    return props.platform.isMobile
      ? {
          maxLength: maxLength,
          inputMode: HTMLInputMode.NUMERIC,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.INTEGER, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER),
          pattern: getInputPattern(props.platform),
          placeholder: "0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "integer",
            allowMinus: false,
            autoUnmask: true,
            clearIncomplete: true,
            max: props.max,
            min: props.min,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.INTEGER_ALLOW_NEGATIVE]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(0, props.min, props.max);
    return props.platform.isMobile
      ? {
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.INTEGER_ALLOW_NEGATIVE, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER_ALLOW_NEGATIVE),
          placeholder: "0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "integer",
            allowMinus: true,
            autoUnmask: true,
            clearIncomplete: true,
            max: props.max,
            min: props.min,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.NUMBER]: (props: MaskProps): Mask => {
    return props.platform.isMobile
      ? {
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL_ALLOW_NEGATIVE, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.LEADING_NEGATIVE_PERIOD, "-0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              );
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL_ALLOW_NEGATIVE),
          placeholder: "0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "numeric",
            allowMinus: true,
            autoUnmask: true,
            clearIncomplete: true,
            max: props.max,
            min: props.min,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.PERCENTAGE]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(
      2,
      props.min ? props.min : 0,
      props.max ? props.max : 100,
    );
    return props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.DECIMAL,
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.DECIMAL, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1")
              .replace(Sanitisers.LEADING_PERIOD, "0.")
              .replace(Sanitisers.PERIOD, (char, index, string) =>
                string.indexOf(char) === index ? char : "",
              )
              .replace(Sanitisers.DECIMAL_PLACES(2), "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.DECIMAL),
          placeholder: "0.0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "numeric",
            allowMinus: false,
            autoUnmask: true,
            clearIncomplete: true,
            digits: 2,
            max: props.max ? props.max : 100,
            min: props.min ? props.min : 0,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },

  [InputType.PERCENTAGE_INTEGER]: (props: MaskProps): Mask => {
    const maxLength = getMaxLength(
      0,
      props.min ? props.min : 0,
      props.max ? props.max : 100,
    );
    return props.platform.isMobile
      ? {
          inputMode: HTMLInputMode.NUMERIC,
          maxLength: maxLength,
          onBlur: handleBlurNumber,
          onFocus: handleFocusNumber,
          onInput: handleInput((value: string): string => {
            return value
              .replace(Sanitisers.INTEGER, "")
              .replace(Sanitisers.LEADING_ZEROS, "$1");
          }),
          onKeyDown: handleKeyDown(RegularExpressions.INTEGER),
          pattern: getInputPattern(props.platform),
          placeholder: "0",
          type: HTMLInputType.NUMBER,
        }
      : {
          inputMask: {
            alias: "integer",
            allowMinus: false,
            autoUnmask: true,
            clearIncomplete: true,
            max: props.max ? props.max : 100,
            min: props.min ? props.min : 0,
            placeholder: "",
            rightAlign: false,
            showMaskOnHover: false,
          },
          type: HTMLInputType.TEXT,
        };
  },
};
