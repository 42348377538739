import React, { useState, useCallback, useEffect } from "react";
import LoadingOverlay from "components/LoadingOverlay";
import { WorkflowDocumentType } from "modules/api/types/WorkflowDetailsPayload";
import { Step, WorkflowAction } from "modules/api/types/WorkflowPayload";
import { isAdmin } from "modules/auth/helper";
import { go, isPath } from "modules/navigation/helpers/navigator";
import {
  getWorkflowDetailsRoute,
  getWorkflowRoute,
  ROUTE_TEMPLATE,
} from "modules/navigation/routes";
import { initPayment } from "modules/payment/actions";
import getSupervisedSigningPrice from "modules/product/helpers/getSupervisedSigningPrice";
import { setSigningOption } from "modules/workflow/actions";
import { loadDocuments } from "modules/workflow/documents/actions";
import {
  hasExecutedAction,
  isAtLeast,
} from "modules/workflow/helpers/lifecycle";
import { SUMMARY_PAGE_ID } from "modules/workflow/staticPages/pageIDs";
import Workflow from "modules/workflow/types/Workflow";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "redux/reducer";
import PlaceholderScreen from "screens/core/PlaceholderScreen";
import AwaitingCoupledScreen from "screens/payment/AwaitingCoupledScreen";
import AwaitingInterviewCompletionScreen from "screens/payment/AwaitingInterviewCompletionScreen";
import SigningOptionsScreen from "screens/payment/SigningOptionsScreen";
import AnimatedLoadingOverlay from "components/AnimatedLoadingOverlay";
import { AnimatedLoadingOverlaySize } from "components/AnimatedLoadingOverlay/types";

const SigningScreenContainer: React.FC = () => {
  const { id: workflowId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    workflows,
    isLoadingRenderedDocuments,
    isLoadingWorkflowDetails,
    user,
    partner,
  } = useSelector((state) => state, shallowEqual);

  const workflow: Workflow | undefined = workflowId
    ? workflows[workflowId]
    : undefined;
  const workflowOwnerId =
    workflow?.userId !== user?.uid ? workflow?.userId : undefined;
  const isWaitingForCoupled =
    workflow?.state.steps.current.step === Step.WaitingForCoupledConfirmSummary;
  const isInterviewCompleted =
    workflow &&
    isAtLeast(workflow.state.steps, Step.WaitingForCoupledConfirmSummary);
  const selectedSigningOption =
    workflow &&
    hasExecutedAction(workflow.state.steps, WorkflowAction.SetSupervisedSigning)
      ? workflow.state.supervisedSigning
      : true;

  const handleDownloadSigningInstructions = () =>
    workflow &&
    dispatch(
      loadDocuments(workflow.id, workflowOwnerId, WorkflowDocumentType.SIGNING),
    );

  const handleOpenInterview = useCallback(() => {
    workflow &&
      go(
        workflow && dispatch,
        getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID),
      );
  }, [dispatch, workflow]);

  const initializePayment = useCallback(async () => {
    workflow && (await initPayment(workflow)(dispatch));
    workflowId &&
      go(
        dispatch,
        getWorkflowRoute(workflowId, ROUTE_TEMPLATE.WORKFLOW_PAYMENT),
      );
  }, [dispatch, workflow, workflowId]);

  useEffect(() => {
    if (workflow?.state.onlineProduct) {
      const previousPage = (location.state as any)?.from;
      isPath(ROUTE_TEMPLATE.WORKFLOW_PAYMENT, previousPage)
        ? handleOpenInterview()
        : initializePayment();
    }
  }, [handleOpenInterview, initializePayment, location.state, workflow]);

  const signingOptionSelected = async (supervised: boolean) => {
    try {
      setIsSubmitting(true);
      workflow &&
        (await setSigningOption(
          workflow.id,
          supervised,
          workflowOwnerId,
        )(dispatch));
      workflow && (await initPayment(workflow)(dispatch));
      workflowId &&
        go(
          dispatch,
          getWorkflowRoute(workflowId, ROUTE_TEMPLATE.WORKFLOW_PAYMENT),
        );
    } catch (e) {}
    setIsSubmitting(false);
  };

  const handleSelfSigningSelected = () => signingOptionSelected(false);
  const handleSupervisedSigningSelected = () => signingOptionSelected(true);

  const handleOpenSpouseWorkflow = () => {
    const spouseWorkflowId = workflow?.state.coupledWorkflowId;
    spouseWorkflowId &&
      go(
        dispatch,
        getWorkflowDetailsRoute(spouseWorkflowId, undefined, SUMMARY_PAGE_ID),
      );
  };

  const admin = isAdmin(partner?.id, user);

  if (workflow && isAtLeast(workflow.state.steps, Step.PaymentSubmitted))
    return (
      <Redirect
        to={getWorkflowDetailsRoute(workflow.id, undefined, SUMMARY_PAGE_ID)}
      />
    );

  if (isLoadingWorkflowDetails || !workflow) return <LoadingOverlay />;
  if (!isInterviewCompleted)
    return (
      <AwaitingInterviewCompletionScreen
        onOpenInterview={handleOpenInterview}
      />
    );
  if (isWaitingForCoupled)
    return (
      <AwaitingCoupledScreen
        onOpenSpouseWorkflow={handleOpenSpouseWorkflow}
        isAdmin={admin}
      />
    );
  if (admin)
    return (
      <PlaceholderScreen
        title="Awaiting payment"
        message="The documents will be available once the client pays for their plan."
      />
    );

  const signingInstructionsIDs: string[] = (
    workflow.products?.map((p) => p.documents.signing) || []
  ).flat();

  if (workflow.state.onlineProduct || partner?.id === undefined) {
    return <AnimatedLoadingOverlay size={AnimatedLoadingOverlaySize.LARGE} />;
  }

  return (
    <SigningOptionsScreen
      isLoadingInstructions={isLoadingRenderedDocuments}
      onSelfSigningSelected={handleSelfSigningSelected}
      onSupervisedSigningSelected={handleSupervisedSigningSelected}
      price={getSupervisedSigningPrice(
        workflow.plan || workflow.products,
        Boolean(workflow.state.coupledWorkflowId),
      )}
      onDownloadInstructions={
        signingInstructionsIDs.length > 0
          ? handleDownloadSigningInstructions
          : undefined
      }
      selectedOption={selectedSigningOption}
      isSubmitting={isSubmitting}
      hasExternalProducts={Boolean(workflow?.state.hasExternalProducts)}
      partnerId={partner?.id}
    />
  );
};

export default SigningScreenContainer;
