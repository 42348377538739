import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEqual, mapValues } from "lodash";
import { Formik, FormikProps } from "formik";
import Fieldset, { FieldsetRow } from "components/Forms/Fieldset";
import { FormWrapper } from "components/Forms/Form";
import { ClientsMap } from "modules/api/types/WorkflowDetailsPayload";
import Party, {
  GenericParty,
  getGenericPartyId,
} from "modules/parties/types/Party";
import GenericQuestion, {
  QuestionActions,
} from "modules/workflow/components/GenericQuestion";
import { getQuestionnaireDefinition } from "modules/workflow/helpers/questionnaireSchemaParser";
import {
  getInitialValues,
  getValidationSchema,
} from "modules/workflow/helpers/validation";
import Questionnaire, {
  QuestionsMapByQuestionnaire,
} from "modules/workflow/types/Questionnaire";
import QuestionPerPartyItem from "../QuestionPerParty/QuestionPerPartyItem";
import { QuestionSizes } from "../QuestionWrapper";
import usePrevious from "utils/usePrevious";
import {
  AnswersByQuestionnaireType,
  AnswersMap,
} from "modules/workflow/types/Answer";
import { unassignContact } from "modules/workflow/helpers/processQuestions";
import { mergePagesToSinglePage } from "modules/workflow/helpers/mergeQuestionnairePages";
import Workflow from "modules/workflow/types/Workflow";

type PartySubquestionnaireProps = {
  allAnswers: AnswersByQuestionnaireType;
  allChildren: Party[] | undefined;
  allQuestions: QuestionsMapByQuestionnaire;
  clients: ClientsMap | undefined;
  disabled: boolean | undefined;
  imported?: boolean;
  onChange: (partyId: string, values: AnswersMap | undefined) => void;
  party: GenericParty;
  questionActions: QuestionActions | undefined;
  subquestionnaire: Questionnaire;
  validate?: boolean;
  workflow?: Workflow;
};

const PartySubquestionnaire: React.FC<PartySubquestionnaireProps> = ({
  allAnswers,
  allChildren,
  allQuestions,
  clients,
  disabled,
  imported,
  onChange,
  party,
  questionActions,
  subquestionnaire,
  validate,
}) => {
  const partyId = getGenericPartyId(party);
  const subquestionnaireAnswers = allAnswers[subquestionnaire.id];
  const [formState, setFormState] = useState<AnswersMap>(
    subquestionnaireAnswers,
  );
  const previousFormState = usePrevious(formState);
  const formRef = useRef<FormikProps<AnswersMap>>();

  const page = mergePagesToSinglePage(
    `${partyId}_${subquestionnaire.id}`,
    subquestionnaire.id,
    subquestionnaire.pages,
  );
  const processedQuestions = {
    ...allQuestions,
    [subquestionnaire.id]: unassignContact(
      allQuestions[subquestionnaire.id],
      party,
    ),
  };

  const { pages, questions } = getQuestionnaireDefinition(
    [page],
    processedQuestions,
    {
      ...allAnswers,
      [subquestionnaire.id]: imported ? subquestionnaireAnswers : formState,
    },
    clients,
    undefined, // workflowState conditions,
    undefined, // addons conditions,
    allChildren,
  );

  const questionsList = Object.values(questions)
    .map((questionsMap) => Object.values(questionsMap))
    .flat();

  const initialValues = getInitialValues(
    questionsList,
    subquestionnaireAnswers,
  );
  const schema = getValidationSchema(questionsList);

  const handleFormChange = useCallback((values: AnswersMap) => {
    formRef.current?.validateForm();
    setFormState(values);
  }, []);

  useEffect(() => {
    if (
      imported &&
      !isEqual(subquestionnaireAnswers, formRef.current?.values)
    ) {
      formRef.current?.setValues(initialValues);
      return;
    }

    if (formState !== previousFormState) {
      formRef.current?.validateForm().then((validationErrors) => {
        const hasValidationErrors = Object.keys(validationErrors).length > 0;
        onChange(partyId, hasValidationErrors ? undefined : formState);
      });
    }
  }, [
    formState,
    imported,
    initialValues,
    onChange,
    partyId,
    previousFormState,
    subquestionnaireAnswers,
  ]);

  return (
    <QuestionPerPartyItem
      key={partyId}
      party={party}
      name={partyId}
      disabled={disabled}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnChange
        onSubmit={(_values, _helpers) => {
          /* No submit */
        }}
      >
        {(subForm) => {
          formRef.current = subForm;
          if (validate && !subForm.isValidating) {
            const hasUntouchedField =
              Object.values(subForm.touched).filter(Boolean).length <
              Object.keys(schema.fields || {}).length;

            if (hasUntouchedField) {
              subForm.setTouched(mapValues(schema.fields || {}, () => true));
              subForm.validateForm();
            }
          }
          return (
            <FormWrapper
              values={subForm.values}
              onFormChanged={handleFormChange}
            >
              <Fieldset>
                {pages[0].sections[0].questions?.map((row, idx) => {
                  const rowQuestions = typeof row === "string" ? [row] : row;

                  return (
                    <FieldsetRow key={`row${idx}`}>
                      {rowQuestions.map((questionID) => (
                        <GenericQuestion
                          key={questionID}
                          question={questions[subquestionnaire.id][questionID]}
                          size={
                            rowQuestions.length > 1
                              ? QuestionSizes.SMALL
                              : undefined
                          }
                          questionActions={questionActions}
                          client={clients?.main}
                          disabled={disabled}
                        />
                      ))}
                    </FieldsetRow>
                  );
                })}
              </Fieldset>
            </FormWrapper>
          );
        }}
      </Formik>
    </QuestionPerPartyItem>
  );
};

export default PartySubquestionnaire;
